import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Rating
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComponentLoader from '../../../Components/ComponentLoader'
import firebase from 'firebase/app'
import './EvaluationHistory.scss'
import Loader from '../../../Components/Loader'

const db = firebase.firestore()

interface Props {
  profileId: string
  agencyId: string
  agentRole: UserRole
}

const EvaluationHistory = ({ profileId, agencyId, agentRole }: Props) => {
  const { t } = useTranslation()

  const [loadedContent, setLoadedContent] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [refreshData, setRefreshData] = useState<boolean>(false)

  const [positionsList, setPositionsList] = useState<RecruitmentCandidate[]>([])

  const [selectedEvaluation, setSelectedEvaluation] =
    useState<RecruitmentCandidate | null>(null)
  const [showEvaluationDiaog, setShowEvaluationDiaog] = useState<boolean>(false)

  const [selectedEvaluationAgentId, setSelectedEvaluationAgentId] =
    useState<string>('')

  const [showDeleteEvaluationWarning, setShowDeleteEvaluationWarning] =
    useState<boolean>(false)

  useEffect(() => {
    let shouldUpdate: boolean = true

    /**
     * Fetch list of applied jobs
     */
    db.collection('agencies')
      .doc(agencyId)
      .collection('jobs_candidates')
      .where('profileId', '==', profileId)
      .where('closedEvaluation', '==', true)
      .orderBy('closeEvaluationDate', 'desc')
      .get()
      .then((snap) => {
        let array: RecruitmentCandidate[] = []
        snap.forEach((doc) => {
          let data: any = JSON.parse(JSON.stringify(doc.data()))

          array = [...array, data]
        })

        if (shouldUpdate) {
          setPositionsList(array)
          setLoadedContent(true)
        }
      })
      .catch((error) => console.error(error))

    return () => {
      shouldUpdate = false
    }
  }, [profileId, agencyId, refreshData])

  const getContractPeriodName = (
    duration: number | undefined,
    period: ContractPeriodTypes | undefined
  ) => {
    let durationNumber: number = 0
    let periodName: string = ''

    if (period !== undefined && duration !== undefined) {
      durationNumber = duration

      if (period === 'days') {
        periodName = t('day')
        if (duration > 1) {
          periodName = t('days')
        }
      }

      if (period === 'week') {
        periodName = t('week')

        if (duration > 1) {
          periodName = t('weeks')
        }
      }

      if (period === 'month') {
        periodName = t('month')

        if (duration > 1) {
          periodName = t('months')
        }
      }

      periodName = periodName.toLocaleLowerCase()
    }

    return `${durationNumber} ${periodName}`
  }

  const openEvaluationPreviewDialog = (evaluation: RecruitmentCandidate) => {
    setSelectedEvaluation(evaluation)
    setSelectedEvaluationAgentId(evaluation.evaluations[0].agentUid)
    setShowEvaluationDiaog(true)
  }

  const closeEvaluationPreviewDialog = () => {
    setShowEvaluationDiaog(false)

    setSelectedEvaluation(null)
    setSelectedEvaluationAgentId('')
  }

  const isSelectedAgent = (id: string) => {
    if (selectedEvaluationAgentId === id) {
      return true
    }

    return false
  }

  const deleteEvaluation = () => {
    if (selectedEvaluation !== null) {
      setLoading(true)
      db.collection('agencies')
        .doc(agencyId)
        .collection('jobs_candidates')
        .doc(selectedEvaluation._id)
        .delete()
        .then(() => {
          setRefreshData(!refreshData)
          setShowDeleteEvaluationWarning(false)
          closeEvaluationPreviewDialog()
          setLoading(false)
        })
        .catch((error) => console.error(error))
    }
  }

  return (
    <>
      {loading && <Loader />}

      {/*
       * Evaluation preview dialog
       */}
      <Dialog
        fullWidth
        maxWidth='md'
        open={showEvaluationDiaog}
        onClose={() => closeEvaluationPreviewDialog()}
      >
        {selectedEvaluation !== null && (
          <DialogContent className='evaluationPreview'>
            {/*
             * Job title
             */}
            <h3>{selectedEvaluation.jobTitle}</h3>

            {/*
             * Details
             */}
            <div className='evaluationPreview_details'>
              {/*
               *  Job ID
               */}
              <div>ID: {selectedEvaluation.jobIdInAgency}</div>

              {/*
               *  Published
               */}
              <div>
                {t('published')}: {selectedEvaluation.jobPublishFrom}
              </div>

              {/*
               *  Rating
               */}
              <div>
                <Rating
                  size='small'
                  readOnly
                  value={selectedEvaluation.rating}
                />
              </div>
            </div>

            {/*
             * All agents evaluations
             */}
            <div className='evaluationPreview_evaluation'>
              {/*
               * Agent
               */}
              <div>
                {selectedEvaluation.evaluations.map((agent) => (
                  <div key={agent.agentUid}>
                    <div
                      className={
                        isSelectedAgent(agent.agentUid)
                          ? 'agentBlock__active'
                          : 'agentBlock'
                      }
                      onClick={() =>
                        setSelectedEvaluationAgentId(agent.agentUid)
                      }
                    >
                      {agent.agentName}

                      {/*
                       * Last edit date
                       */}
                      <div>{agent.lastEdit}</div>
                    </div>
                  </div>
                ))}
              </div>

              {/*
               * Evaluation
               */}
              {selectedEvaluation.evaluations.map((agent) => (
                <div
                  key={agent.agentUid}
                  className={
                    isSelectedAgent(agent.agentUid)
                      ? 'evaluationBlock__active'
                      : 'evaluationBlock'
                  }
                >
                  {/*
                   * Criteria
                   */}
                  {agent.evaluation.map((evaluation) => (
                    <div key={evaluation._id} className='evaluationContainer'>
                      {/*
                       * Title and rating
                       */}
                      <div className='evaluationHead'>
                        {/*
                         * Title
                         */}
                        <div>{evaluation.title}</div>

                        {/*
                         * Rating
                         */}
                        <Rating
                          size='small'
                          readOnly
                          value={evaluation.rating}
                        />
                      </div>

                      {/*
                       * Evaluation
                       */}
                      <div>{evaluation.evaluation}</div>
                    </div>
                  ))}

                  {/* Comment */}
                  <div className='evaluationContainer'>
                    {/* Title */}
                    <div className='evaluationHead'>
                      <div>{t('comment')}</div>
                    </div>

                    {/* Input */}
                    <div>{agent.comment}</div>
                  </div>
                </div>
              ))}
            </div>
          </DialogContent>
        )}

        <DialogActions>
          {/*
           * Close button
           */}
          <Button size='small' onClick={() => closeEvaluationPreviewDialog()}>
            {t('close')}
          </Button>

          {/*
           * Delete button
           */}
          <Button
            disabled={
              agentRole === 'agency_admin' || agentRole === 'super_admin'
                ? false
                : true
            }
            variant='contained'
            color='error'
            size='small'
            onClick={() => setShowDeleteEvaluationWarning(true)}
          >
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>

      {/*
       * Evaluation Delete warning dialog
       */}
      <Dialog
        open={showDeleteEvaluationWarning}
        onClose={() => setShowDeleteEvaluationWarning(false)}
      >
        <DialogContent>
          {t('are_you_sure_whant_to_delete_history_asd55')}
        </DialogContent>

        <DialogActions>
          {/*
           * Close button
           */}
          <Button
            size='small'
            onClick={() => setShowDeleteEvaluationWarning(false)}
          >
            {t('no')}
          </Button>

          {/*
           * Delete button
           */}
          <Button
            disabled={
              agentRole === 'agency_admin' || agentRole === 'super_admin'
                ? false
                : true
            }
            variant='contained'
            color='error'
            size='small'
            onClick={() => deleteEvaluation()}
          >
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <main>
        <Card style={{ minWidth: 300, margin: 20, position: 'relative' }}>
          {!loadedContent && <ComponentLoader />}
          <CardHeader
            sx={[{ '& .MuiCardHeader-title': { fontSize: 15 } }]}
            title={t('history')}
          />

          <CardContent>
            {/*
             * No entries message
             */}
            {positionsList.length === 0 && (
              <div style={{ fontSize: 14 }}>
                {t('this_profile_wasnt_applied_to_any_positions')}
              </div>
            )}

            {/*
             * Entries list
             */}
            {positionsList.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>{t('job_title')}</b>
                    </TableCell>

                    <TableCell>
                      <b>{t('evaluation_date')}</b>
                    </TableCell>

                    <TableCell>
                      <b>{t('contract_duration')}</b>
                    </TableCell>

                    <TableCell>
                      <b>{t('salary')}</b>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {positionsList.map((position) => (
                    <TableRow
                      key={position._id}
                      hover
                      style={{ cursor: 'pointer' }}
                      onClick={() => openEvaluationPreviewDialog(position)}
                    >
                      {/*
                       * Job title
                       */}
                      <TableCell>{position.jobTitle}</TableCell>

                      {/*
                       * Evaluation date
                       */}
                      <TableCell>{position.closeEvaluationDate}</TableCell>

                      {/*
                       * Contract duration
                       */}
                      <TableCell>
                        {getContractPeriodName(
                          position.jobContractDuration,
                          position.jobContractPeriod
                        )}
                      </TableCell>

                      {/*
                       * Salary
                       */}
                      <TableCell>
                        {position.jobSalary?.salaryMin > 0 && (
                          <div>
                            <span style={{ textTransform: 'uppercase' }}>
                              {position.jobSalary.currency}
                            </span>{' '}
                            {position.jobSalary.salaryMin} -{' '}
                            {position.jobSalary.salaryMax}{' '}
                            {t(
                              position.jobSalary.salaryRate === 'per-hour'
                                ? 'per_hour'
                                : position.jobSalary.salaryRate === 'per-month'
                                ? 'per_month'
                                : ''
                            )}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </CardContent>
        </Card>
      </main>
    </>
  )
}

export default EvaluationHistory
