import { useState } from 'react'
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Alert,
  Snackbar,
  Tooltip
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { systemColors } from '../../../../globalVariables'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import { refreshData } from '../../../../redux/actions/refreshData'

const useStyles = makeStyles({
  card: {
    width: 'fit-content',
    height: '100%'
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: systemColors.darkBlue
  },
  cardHeader: {
    backgroundColor: systemColors.background,
    border: '1px solid #d6d6d6',
    borderBottom: `2px solid ${systemColors.orange}`
  },
  removeCardIcon: {
    padding: '10px 6px 0 50px',
    cursor: 'pointer',
    color: systemColors.grey,
    '&:hover': {
      color: systemColors.darkGrey
    }
  },
  listPrimaryText: {
    fontSize: 14
  }
})

const LastViewedCompanies = ({
  companiesList,
  agencyData,
  userUid,
  refreshData,
  setRefreshData
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const db = firebase.firestore()

  const [message, setMessage] = useState({
    status: false,
    duration: 3000,
    severity: 'success',
    message: ''
  })

  const removeBlockFromDashboard = async () => {
    await db
      .collection('users')
      .doc(userUid)
      .update({
        'data.settings.userSettings.dashboard.allowLastViewedCompaniesKEY': false
      })
      .then(() => {
        setRefreshData(!refreshData)
      })
      .catch((error) => {
        console.log("Can't update users serrtings", error)
        setMessage({
          status: true,
          duration: 5000,
          severity: 'warning',
          message: t('thereIsProblemWithRemoving1644648')
        })
      })
  }

  return (
    <div style={{ height: '100%' }}>
      {/* warnings */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      <Card classes={{ root: classes.card }}>
        <CardHeader
          classes={{ root: classes.cardHeader, title: classes.title }}
          title={t('lastViewedCompanies')}
          action={
            <Tooltip title={t('removeItemFromDashboard1354')} placement='top'>
              <div
                className={classes.removeCardIcon}
                onClick={() => removeBlockFromDashboard()}
              >
                <FontAwesomeIcon icon={faMinusSquare} />
              </div>
            </Tooltip>
          }
        />
        <CardContent>
          <List>
            {companiesList.map((item) => (
              <ListItem
                key={item.companyId}
                button
                onClick={() => {
                  history.push(
                    `/${agencyData.id}/companies/company/${item.companyId}`,
                    { data: item.companyName }
                  )
                }}
              >
                <ListItemText
                  classes={{ primary: classes.listPrimaryText }}
                  primary={item.companyName}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  refreshData: state.refreshDataRed.bool
})

const dispatchStateToProps = (dispatch) => ({
  setRefreshData: (bool) => dispatch(refreshData(bool))
})

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(LastViewedCompanies)
