import React from 'react';
import { useTranslation } from 'react-i18next';
import './button.scss'

const Button = (props) => {
    const { t } = useTranslation()

    const clickHandler = () => {
        props.handleClick()
    }

    return (
        <div 
            className={props.disable ? 'button-disable' : 'button'}
            onClick={() => clickHandler()}
        >
            {t(props.text)}
        </div>
    );
}
 
export default Button;