import crewEmployeeData from '../classes/crewEmployeeData'
// Convert data to firestore data
export const crewEmployeeConverter = {
  toFirestore: (user) => {
    return {
      formType: user.formType,
      docId: user.docId,
      userId: user.userId,
      positionId: user.positionId,
      positionTwoId: user.positionTwoId,
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      birthDate: user.birthDate,
      homeAddress: user.homeAddress,
      phoneMobile: user.phoneMobile,
      nextToKinPhone: user.nextToKinPhone,
      email: user.email,
      maritalStatus: user.maritalStatus,
      children: user.children,
      nationality: user.nationality,
      birthPlace: user.birthPlace,
      nearestAirport: user.nearestAirport,
      englishLevel: user.englishLevel,
      nextToKin: user.nextToKin,
      nextToKinAddress: user.nextToKinAddress,
      nextToKinEmail: user.nextToKinEmail,
      minSalary: user.minSalary,
      expectedSalary: user.expectedSalary,
      lastSalary: user.lastSalary,
      educationLicence: user.educationLicence,
      educationNumber: user.educationNumber,
      educationIssued: user.educationIssued,
      educationExpires: user.educationExpires,
      educationCountry: user.educationCountry,
      documentsArray: user.documentsArray,
      experienceArray: user.experienceArray,
      crewAgenciesArray: user.crewAgenciesArray,
      height: user.height,
      weight: user.weight,
      shoes: user.shoes,
      clothes: user.clothes,
      hairColor: user.hairColor,
      eyeColor: user.eyeColor,
      profilePhoto: user.profilePhoto,
      contractDuration: user.contractDuration,
      comment: user.comment,
      date: user.date,
      notes: user.notes,
      availableFrom: user.availableFrom,
      profileStatus: user.profileStatus,
      rotationLog: user.rotationLog,
      cityCountry: user.cityCountry,
      whatsappViber: user.whatsappViber,
      createdAt: user.createdAt,
      desiredVesselType: user.desiredVesselType,
      bankData: user.bankData,
      rotationLogStatus: user.rotationLogStatus,
      plannedOnProjects: user.plannedOnProjects,
      addType: user.addType,
      log: user.log
    }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return new crewEmployeeData(
      data.formType,
      data.docId,
      data.userId,
      data.positionId,
      data.positionTwoId,
      data.firstName,
      data.lastName,
      data.middleName,
      data.birthDate,
      data.homeAddress,
      data.phoneMobile,
      data.nextToKinPhone,
      data.email,
      data.maritalStatus,
      data.children,
      data.nationality,
      data.birthPlace,
      data.nearestAirport,
      data.englishLevel,
      data.nextToKin,
      data.nextToKinAddress,
      data.nextToKinEmail,
      data.minSalary,
      data.expectedSalary,
      data.lastSalary,
      data.educationLicence,
      data.educationNumber,
      data.educationIssued,
      data.educationExpires,
      data.educationCountry,
      data.documentsArray,
      data.experienceArray,
      data.crewAgenciesArray,
      data.height,
      data.weight,
      data.shoes,
      data.clothes,
      data.hairColor,
      data.eyeColor,
      data.profilePhoto,
      data.contractDuration,
      data.comment,
      data.date,
      data.notes,
      data.availableFrom,
      data.profileStatus,
      data.rotationLog,
      data.cityCountry,
      data.whatsappViber,
      data.createdAt,
      data.desiredVesselType,
      data.bankData,
      data.rotationLogStatus,
      data.plannedOnProjects,
      data.addType,
      data.log
    )
  }
}
