const initialState = {
    obj: {}
}

const agencyUserDataRed = (state = initialState, action) => {
    switch(action.type){
        case 'AGENCY_USER_DATA' : {
            return {
                ...state,
                obj: action.obj
            }
        }
        default: {
            return state
        }
    }
}

export default agencyUserDataRed