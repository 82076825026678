import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font
} from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { convertDate, secondsToDate } from '../globalFunctions'
import RobotoNormal from '../media/fonts/Roboto-Regular.ttf'
import RobotoBold from '../media/fonts/Roboto-Bold.ttf'
import { systemColors } from '../globalVariables'
import logo from '../media/logo/seanor_logo_335x82.png'
import placeholder from '../media/pdf/user-placeholder.png'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoBold,
      fontWeight: 'bold'
    },
    {
      src: RobotoNormal
    }
  ]
})

// Colors
const darkBlue = '#215384'
const lightElectric = '#43688e'
const orange = '#e03b22'
const lightGrey = '#737372'

const styles = StyleSheet.create({
  userCv: {
    fontFamily: 'Roboto',
    marginTop: 25,
    fontSize: 10
  },
  userCvMainInfo: {
    display: 'flex',
    // flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginLeft: 15,
    marginRight: 15
  },
  basicInfoName: {
    fontWeight: 'bold',
    marginBottom: 10,
    color: darkBlue
  },
  userCvMainInfoElem: {
    margin: 10,
    width: 150
  },
  profilePhoto: {
    width: 150
  },
  basicInfoData: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 5,
    fontSize: 10
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    color: lightElectric,
    fontWeight: 'bold',
    height: 12
  },
  headLine: {
    flex: 1,
    fontSize: 13,
    color: orange,
    marginTop: 30,
    marginBottom: 5,
    marginLeft: 25
  },
  headLineBorder: {
    width: '90%',
    borderBottom: `1 solid ${lightGrey}`,
    paddingBottom: 5
  },
  section: {
    flex: 0,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  sectionElem: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  salaryElem: {
    marginHorizontal: 10
  },
  documents: {
    marginLeft: 10,
    textAlign: 'left',
    marginVertical: 6,
    paddingBottom: 5,
    borderBottom: `1 thin ${lightGrey}`
  },
  experience: {
    fontSize: 10,
    marginVertical: 7,
    paddingBottom: 5,
    borderBottom: `1 thin ${lightGrey}`
  },
  comment: {
    marginHorizontal: 25
  },
  firstLetter: {
    textTransform: 'capitalize'
  },
  formHeadline: {
    textTransform: 'uppercase',
    fontSize: 14,
    marginLeft: 25,
    color: systemColors.electric,
    fontWeight: 'bold'
  },
  documentsHeader: {
    backgroundColor: systemColors.electric,
    color: '#ffffff',
    padding: '5 10'
  },
  documentData: {
    padding: '5 10'
  },
  experienceHeader: {
    color: '#ffffff'
  },
  contactHeader: {
    backgroundColor: systemColors.electric,
    color: '#ffffff',
    padding: '5 10'
  },
  physicalHeader: {
    backgroundColor: systemColors.electric,
    color: '#ffffff',
    padding: '5 10',
    width: 91
  },
  errorColor: {
    color: systemColors.errorColor
  },
  outputText: {
    display: 'flex',
    flexDirection: 'column',
    width: 150
  },
  documentsSection: {
    padding: '5 0',
    marginLeft: 22,
    width: 551,
    backgroundColor: systemColors.inputColor,
    textAlign: 'center'
  }
})

export const CrewCvPdf = ({
  userArr,
  profilePhotoUrl,
  certificatesArr,
  ranksArr,
  engineTypeArr,
  vesselTypeArr,
  companyLogoURL
}) => {
  const { t } = useTranslation()

  // Get document name
  const getDocumentName = (id) => {
    let name = ''
    certificatesArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  // Get rank name
  const getRankName = (id) => {
    let name = ''
    ranksArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  // Get vessel type name
  const getVesselTypeName = (id) => {
    let name = ''
    vesselTypeArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  // Get engine type name
  const getEngineTypeName = (id) => {
    let name = ''
    engineTypeArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  const breakLines = (text, breakLength, place) => {
    if (text !== null) {
      let textInput = text.split(' ')
      textInput.splice(breakLength, 0, '\n')
      textInput = textInput.join(' ')
      if (place === 'table') {
        return <Text>{textInput}</Text>
      } else {
        return (
          <Text style={[styles.firstLetter, { height: 24 }]}>{textInput}</Text>
        )
      }
    } else {
    }
  }

  const renderVesselTypeList = (array) => {
    if (array !== undefined) {
      const sorted = array.sort((a, b) => a.id - b.id)
      return sorted.map((type, index) => (
        <View
          key={index}
          style={{
            fontWeight: type.type === 'group' && 'bold'
          }}
        >
          <Text style={{ height: 15 }}>{t(type.name)}</Text>
        </View>
      ))
    }
    return null
  }

  return (
    <Document>
      <Page size='A4'>
        <View style={styles.userCv}>
          {/* LOGO SECTION */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginBottom: 20
            }}
          >
            <Image
              style={{ maxWidth: 100, maxHeight: 100, marginRight: 25 }}
              src={companyLogoURL !== '' ? companyLogoURL : logo}
            />
          </View>

          {/* Headline */}
          <View style={styles.formHeadline}>
            <Text>{t('seafarersApplicationForm')}</Text>
          </View>

          {/* Basic info section */}
          <View style={styles.userCvMainInfo}>
            {/* First column */}
            <View style={styles.userCvMainInfoElem}>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('availableFrom')}: </Text>
                <Text>{userArr.availableFrom}</Text>
              </View>
              <View style={[styles.basicInfoData, styles.outputText]}>
                <Text style={styles.title}>{t('mainPosition')}: </Text>
                <Text style={{ height: 12 }}>
                  {getRankName(userArr.positionId)}
                </Text>
              </View>
              {userArr.positionTwoId !== null &&
                userArr.positionTwoId !== undefined &&
                userArr.positionTwoId !== '' && (
                  <View style={[styles.basicInfoData, styles.outputText]}>
                    <Text style={styles.title}>{t('secondPosition')}: </Text>
                    <Text style={{ height: 12 }}>
                      {getRankName(userArr.positionTwoId)}
                    </Text>
                  </View>
                )}
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('firstName')}: </Text>
                <Text style={styles.firstLetter}>{userArr.firstName}</Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('lastName')}: </Text>
                <Text style={styles.firstLetter}>{userArr.lastName}</Text>
              </View>
              {userArr.middleName !== '' ? (
                <View style={styles.basicInfoData}>
                  <Text style={styles.title}>{t('middleName')}: </Text>
                  <Text style={styles.firstLetter}>{userArr.middleName}</Text>
                </View>
              ) : null}
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('birthDate')}: </Text>
                <Text>{userArr.birthDate}</Text>
              </View>
              <View style={[styles.basicInfoData, styles.outputText]}>
                <Text style={styles.title}>{t('homeAddress')}: </Text>
                <Text style={styles.outputText}>{userArr.homeAddress}</Text>
              </View>
              <View style={[styles.basicInfoData, styles.outputText]}>
                <View>
                  <Text style={styles.title}>{t('phone')}: </Text>
                </View>
                <View>{breakLines(userArr.phoneMobile, 2)}</View>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('email')}: </Text>
                <Text>{userArr.email}</Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('whatsappOrViberNo')}: </Text>
                <Text>{userArr.whatsappViber}</Text>
              </View>
              {userArr.citizenship !== '' ? (
                <View style={styles.basicInfoData}>
                  <Text style={styles.title}>{t('cityCountry')}: </Text>
                  <Text style={styles.firstLetter}>{userArr.cityCountry}</Text>
                </View>
              ) : null}

              <View style={styles.basicInfoData}>
                <Text style={styles.title}>
                  {t('desiredContractDuration')}:{' '}
                </Text>
                <Text>{userArr.contractDuration}</Text>
              </View>

              {/* Desired Vessel Type */}
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('desiredVesselType')}: </Text>
                <View style={{ display: 'block', height: '100%' }}>
                  {renderVesselTypeList(userArr.desiredVesselType)}
                </View>
              </View>
            </View>

            {/* Second column */}
            <View style={styles.userCvMainInfoElem}>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('maritalStatus')}: </Text>
                <Text>{userArr.maritalStatus}</Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('children')}: </Text>
                <Text>{userArr.children}</Text>
              </View>
              {userArr.nationality !== '' ? (
                <View style={styles.basicInfoData}>
                  <Text style={styles.title}>{t('nationality')}: </Text>
                  <Text style={styles.firstLetter}>{userArr.nationality}</Text>
                </View>
              ) : null}
              {userArr.birthPlace !== '' ? (
                <View style={styles.basicInfoData}>
                  <Text style={styles.title}>{t('birthPlace')}: </Text>
                  <Text style={{ ...styles.firstLetter, width: 100 }}>
                    {userArr.birthPlace}
                  </Text>
                </View>
              ) : null}
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('englishLevel')}: </Text>
                <Text>{userArr.englishLevel}</Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('nextToKin')}: </Text>
                <Text style={styles.outputText}>{userArr.nextToKin}</Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('nextToKinPhone')}: </Text>
                <Text style={styles.outputText}>{userArr.nextToKinPhone}</Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('nextToKinEmail')}: </Text>
                <Text style={styles.outputText}>{userArr.nextToKinEmail}</Text>
              </View>
              <View style={[styles.basicInfoData, styles.outputText]}>
                <Text style={styles.title}>{t('nextToKinAddress')}: </Text>
                <Text style={styles.outputText}>
                  {userArr.nextToKinAddress}
                </Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('nearestAirpot')}: </Text>
                <Text style={styles.firstLetter}>{userArr.nearestAirport}</Text>
              </View>
            </View>

            {/* Profile photo */}
            <View style={styles.userCvMainInfoElem}>
              {profilePhotoUrl !== '' ? (
                <Image
                  style={styles.profilePhoto}
                  src={profilePhotoUrl}
                  alt='profile'
                />
              ) : (
                <Image
                  style={styles.profilePhoto}
                  src={placeholder}
                  alt='profile'
                />
              )}
            </View>
          </View>

          {/* Salary section */}
          <View
            style={{
              flex: 0,
              flexDirection: 'row',
              marginTop: 20,
              marginLeft: 25
            }}
          >
            <View
              style={{
                color: '#ffffff',
                backgroundColor: systemColors.electric,
                padding: '5 10',
                width: 85
              }}
            >
              <Text>{t('salary')}</Text>
            </View>
            <View
              style={{
                flex: 0,
                flexDirection: 'row',
                padding: '5 10',
                width: 155,
                border: `1 solid ${systemColors.electric}`,
                borderLeft: 'none'
              }}
            >
              <Text style={styles.title}>{t('minimum')}: </Text>
              <Text> {userArr.minSalary} </Text>
              <Text>$/{t('month')}</Text>
            </View>
            <View
              style={{
                flex: 0,
                flexDirection: 'row',
                padding: '5 10',
                width: 155,
                border: `1 solid ${systemColors.electric}`,
                borderLeft: 'none'
              }}
            >
              <Text style={styles.title}>{t('expected')}: </Text>
              <Text> {userArr.expectedSalary} </Text>
              <Text>$/{t('month')}</Text>
            </View>
            <View
              style={{
                flex: 0,
                flexDirection: 'row',
                padding: '5 10',
                width: 155,
                border: `1 solid ${systemColors.electric}`,
                borderLeft: 'none'
              }}
            >
              <Text style={styles.title}>{t('lastSalary')}: </Text>
              <Text> {userArr.lastSalary} </Text>
              <Text>$/{t('month')}</Text>
            </View>
          </View>

          {/* Education section */}
          <View style={styles.headLine}>
            <Text>{t('education')}</Text>
          </View>
          <View style={[styles.section]}>
            <View>
              <View
                style={{
                  backgroundColor: systemColors.electric,
                  color: '#ffffff',
                  padding: '5 10',
                  width: 190
                }}
              >
                <Text>{t('licence')}:</Text>
              </View>
              <View>
                <Text
                  style={{
                    border: `1 solid ${systemColors.electric}`,
                    borderTop: 'none',
                    padding: 5,
                    width: 190,
                    minHeight: 25
                  }}
                >
                  {userArr.educationLicence}
                </Text>
              </View>
            </View>
            <View>
              <View
                style={{
                  backgroundColor: systemColors.electric,
                  color: '#ffffff',
                  padding: '5 10',
                  width: 110
                }}
              >
                <Text>{t('number')}:</Text>
              </View>
              <View>
                <Text
                  style={{
                    border: `1 solid ${systemColors.electric}`,
                    borderTop: 'none',
                    borderLeft: 'none',
                    padding: 5,
                    width: 110,
                    minHeight: 25
                  }}
                >
                  {userArr.educationNumber}
                </Text>
              </View>
            </View>
            <View>
              <View
                style={{
                  backgroundColor: systemColors.electric,
                  color: '#ffffff',
                  padding: '5 10',
                  width: 80
                }}
              >
                <Text>{t('issued')}:</Text>
              </View>
              <View>
                <Text
                  style={{
                    border: `1 solid ${systemColors.electric}`,
                    borderTop: 'none',
                    borderLeft: 'none',
                    padding: 5,
                    width: 80,
                    minHeight: 25
                  }}
                >
                  {userArr.educationIssued}
                </Text>
              </View>
            </View>
            <View>
              <View
                style={{
                  backgroundColor: systemColors.electric,
                  color: '#ffffff',
                  padding: '5 10',
                  width: 80
                }}
              >
                <Text>{t('expires')}:</Text>
              </View>
              <View>
                <Text
                  style={{
                    border: `1 solid ${systemColors.electric}`,
                    borderTop: 'none',
                    borderLeft: 'none',
                    padding: 5,
                    width: 80,
                    minHeight: 25
                  }}
                >
                  {userArr.educationExpires}
                </Text>
              </View>
            </View>
            <View>
              <View
                style={{
                  backgroundColor: systemColors.electric,
                  color: '#ffffff',
                  padding: '5 10',
                  width: 90
                }}
              >
                <Text>{t('country')}:</Text>
              </View>
              <View>
                <Text
                  style={{
                    border: `1 solid ${systemColors.electric}`,
                    borderTop: 'none',
                    borderLeft: 'none',
                    padding: 5,
                    width: 90,
                    minHeight: 25
                  }}
                >
                  {userArr.educationCountry}
                </Text>
              </View>
            </View>
          </View>

          {/* Documents section */}
          <View style={styles.headLine}>
            <Text>{t('passportsVisa')}</Text>
          </View>
          <View style={styles.section}>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 150
                }
              ]}
            >
              <Text>{t('document')}</Text>
            </View>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 120
                }
              ]}
            >
              <Text>{t('number')}</Text>
            </View>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 120
                }
              ]}
            >
              <Text>{t('placeOfIssue')}</Text>
            </View>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 80
                }
              ]}
            >
              <Text>{t('issued')}</Text>
            </View>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 80
                }
              ]}
            >
              <Text>{t('expires')}</Text>
            </View>
          </View>
          <View style={styles.documentsSection}>
            <Text>{t('passports&Visas')}</Text>
          </View>
          {userArr.documentsArray.map((doc) => {
            if (doc.docId.id < 40 || doc.docId.id >= 250) {
              // eslint-disable-next-line
              return (
                <View
                  key={doc.id}
                  style={[
                    styles.section,
                    {
                      borderBottom: `1 solid ${systemColors.lighGrey}`,
                      marginLeft: 25,
                      marginRight: 25
                    }
                  ]}
                >
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 150
                      }
                    ]}
                  >
                    <Text>
                      {certificatesArr && getDocumentName(doc.docId.id)}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 120
                      }
                    ]}
                  >
                    <Text>{doc.number}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 120
                      }
                    ]}
                  >
                    <Text>{doc.placeIssue}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 80
                      }
                    ]}
                  >
                    <Text>{doc.issued}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 80
                      }
                    ]}
                  >
                    <Text
                      style={
                        doc.expires === ''
                          ? null
                          : doc.expires <= convertDate(new Date())
                          ? styles.errorColor
                          : null
                      }
                    >
                      {doc.expires !== '' ? doc.expires : t('unlimited')}
                    </Text>
                  </View>
                </View>
              )
            } else {
              // eslint-disable-next-line
              return
            }
          })}

          <View style={[styles.documentsSection, { marginTop: 10 }]}>
            <Text>{t('diplomas&Endorsments')}</Text>
          </View>
          {userArr.documentsArray.map((doc) => {
            if (doc.docId.id >= 40 && doc.docId.id < 90) {
              // eslint-disable-next-line
              return (
                <View
                  key={doc.id}
                  style={[
                    styles.section,
                    {
                      borderBottom: `1 solid ${systemColors.lighGrey}`,
                      marginLeft: 25,
                      marginRight: 25
                    }
                  ]}
                >
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 150
                      }
                    ]}
                  >
                    <Text>
                      {certificatesArr && getDocumentName(doc.docId.id)}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 120
                      }
                    ]}
                  >
                    <Text>{doc.number}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 120
                      }
                    ]}
                  >
                    <Text>{doc.placeIssue}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 80
                      }
                    ]}
                  >
                    <Text>{doc.issued}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 80
                      }
                    ]}
                  >
                    <Text
                      style={
                        doc.expires === ''
                          ? null
                          : doc.expires <= convertDate(new Date())
                          ? styles.errorColor
                          : null
                      }
                    >
                      {doc.expires !== '' ? doc.expires : t('unlimited')}
                    </Text>
                  </View>
                </View>
              )
            } else {
              // eslint-disable-next-line
              return
            }
          })}

          <View style={[styles.documentsSection, { marginTop: 10 }]}>
            <Text>{t('certificates&Licenses')}</Text>
          </View>
          {userArr.documentsArray.map((doc) => {
            if (doc.docId.id >= 120 && doc.docId.id < 250) {
              // eslint-disable-next-line
              return (
                <View
                  key={doc.id}
                  style={[
                    styles.section,
                    {
                      borderBottom: `1 solid ${systemColors.lighGrey}`,
                      marginLeft: 25,
                      marginRight: 25
                    }
                  ]}
                >
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 150
                      }
                    ]}
                  >
                    <Text>
                      {certificatesArr && getDocumentName(doc.docId.id)}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 120
                      }
                    ]}
                  >
                    <Text>{doc.number}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 120
                      }
                    ]}
                  >
                    <Text>{doc.placeIssue}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 80
                      }
                    ]}
                  >
                    <Text>{doc.issued}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 80
                      }
                    ]}
                  >
                    <Text
                      style={
                        doc.expires === ''
                          ? null
                          : doc.expires <= convertDate(new Date())
                          ? styles.errorColor
                          : null
                      }
                    >
                      {doc.expires !== '' ? doc.expires : t('unlimited')}
                    </Text>
                  </View>
                </View>
              )
            } else {
              // eslint-disable-next-line
              return
            }
          })}
          {userArr.documentsArray.map((doc) => {
            if (doc.docId.id >= 110 && doc.docId.id < 120) {
              // eslint-disable-next-line
              return (
                <View
                  key={doc.id}
                  style={[
                    styles.section,
                    {
                      borderBottom: `1 solid ${systemColors.lighGrey}`,
                      marginLeft: 25,
                      marginRight: 25
                    }
                  ]}
                >
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 150
                      }
                    ]}
                  >
                    <Text>
                      {certificatesArr && getDocumentName(doc.docId.id)}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 120
                      }
                    ]}
                  >
                    <Text>{doc.number}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 120
                      }
                    ]}
                  >
                    <Text>{doc.placeIssue}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 80
                      }
                    ]}
                  >
                    <Text>{doc.issued}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 80
                      }
                    ]}
                  >
                    <Text
                      style={
                        doc.expires === ''
                          ? null
                          : doc.expires <= convertDate(new Date())
                          ? styles.errorColor
                          : null
                      }
                    >
                      {doc.expires !== '' ? doc.expires : t('unlimited')}
                    </Text>
                  </View>
                </View>
              )
            } else {
              // eslint-disable-next-line
              return
            }
          })}

          <View style={[styles.documentsSection, { marginTop: 10 }]}>
            <Text>{t('medicalCertificates')}</Text>
          </View>
          {userArr.documentsArray.map((doc) => {
            if (doc.docId.id >= 90 && doc.docId.id < 110) {
              // eslint-disable-next-line
              return (
                <View
                  key={doc.id}
                  style={[
                    styles.section,
                    {
                      borderBottom: `1 solid ${systemColors.lighGrey}`,
                      marginLeft: 25,
                      marginRight: 25
                    }
                  ]}
                >
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 150
                      }
                    ]}
                  >
                    <Text>
                      {certificatesArr && getDocumentName(doc.docId.id)}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 120
                      }
                    ]}
                  >
                    <Text>{doc.number}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 120
                      }
                    ]}
                  >
                    <Text>{doc.placeIssue}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 80
                      }
                    ]}
                  >
                    <Text>{doc.issued}</Text>
                  </View>
                  <View
                    style={[
                      styles.documentData,
                      {
                        width: 80
                      }
                    ]}
                  >
                    <Text
                      style={
                        doc.expires === ''
                          ? null
                          : doc.expires <= convertDate(new Date())
                          ? styles.errorColor
                          : null
                      }
                    >
                      {doc.expires !== '' ? doc.expires : t('unlimited')}
                    </Text>
                  </View>
                </View>
              )
            } else {
              // eslint-disable-next-line
              return
            }
          })}
        </View>
      </Page>

      <Page size='A4'>
        <View style={styles.userCv}>
          {/* Sea going experience section */}
          <View style={styles.headLine}>
            <Text>{t('seaGoingExperience')}</Text>
          </View>
          <View style={[styles.section]}>
            <View
              style={[
                styles.experienceHeader,
                {
                  flex: 0,
                  justifyContent: 'center',
                  backgroundColor: systemColors.electric,
                  padding: '5 10',
                  width: 80
                }
              ]}
            >
              <Text>{t('vesselName')}</Text>
            </View>
            <View
              style={[
                styles.experienceHeader,
                {
                  width: 65
                }
              ]}
            >
              <View
                style={{
                  flex: 0,
                  justifyContent: 'center',
                  backgroundColor: systemColors.electric,
                  padding: '5 10',
                  height: 33.5
                }}
              >
                <Text>{t('flag')}</Text>
              </View>
              <View
                style={{
                  backgroundColor: systemColors.lightElectric,
                  padding: '5 10',
                  height: 22
                }}
              >
                <Text>{t('year')}</Text>
              </View>
            </View>
            <View
              style={[
                styles.experienceHeader,
                {
                  width: 70
                }
              ]}
            >
              <View
                style={{
                  backgroundColor: systemColors.electric,
                  padding: '5 10'
                }}
              >
                <Text>{t('vesselType')}</Text>
              </View>
              <View
                style={{
                  backgroundColor: systemColors.lightElectric,
                  padding: '5 10'
                }}
              >
                <Text>{t('dwt')}</Text>
              </View>
            </View>
            <View
              style={[
                styles.experienceHeader,
                {
                  width: 60
                }
              ]}
            >
              <View
                style={{
                  backgroundColor: systemColors.electric,
                  padding: '5 10'
                }}
              >
                <Text>{t('engineType')}</Text>
              </View>
              <View
                style={{
                  backgroundColor: systemColors.lightElectric,
                  padding: '5 10'
                }}
              >
                <Text>{t('hp')}</Text>
              </View>
            </View>
            <View
              style={[
                styles.experienceHeader,
                {
                  flex: 0,
                  alignContent: 'center',
                  justifyContent: 'center',
                  backgroundColor: systemColors.electric,
                  padding: '5 10',
                  width: 95
                }
              ]}
            >
              <Text>{t('rank')}</Text>
            </View>
            <View
              style={[
                styles.experienceHeader,
                {
                  width: 80
                }
              ]}
            >
              <View
                style={{
                  flex: 0,
                  justifyContent: 'center',
                  backgroundColor: systemColors.electric,
                  padding: '5 10',
                  height: 33.5
                }}
              >
                <Text>{t('from')}</Text>
              </View>
              <View
                style={{
                  backgroundColor: systemColors.lightElectric,
                  padding: '5 10',
                  height: 22
                }}
              >
                <Text>{t('till')}</Text>
              </View>
            </View>
            <View
              style={[
                styles.experienceHeader,
                {
                  width: 95
                }
              ]}
            >
              <View
                style={{
                  flex: 0,
                  justifyContent: 'center',
                  backgroundColor: systemColors.electric,
                  padding: '5 10',
                  height: 33.5
                }}
              >
                <Text>{t('shipOwner')}</Text>
              </View>
              <View
                style={{
                  backgroundColor: systemColors.lightElectric,
                  padding: '5 10',
                  height: 22
                }}
              >
                <Text>{t('crewAgency')}</Text>
              </View>
            </View>
          </View>
          {userArr.experienceArray.map((el) => {
            return (
              <View
                key={el.id}
                style={[
                  styles.section,
                  {
                    borderBottom: `1 solid ${systemColors.lighGrey}`,
                    marginRight: 25,
                    marginLeft: 25
                  }
                ]}
              >
                <View
                  style={[
                    {
                      flex: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '5 10',
                      width: 80,
                      borderLeft: `1 solid ${systemColors.lighGrey}`,
                      borderRight: `1 solid ${systemColors.lighGrey}`
                    }
                  ]}
                >
                  <Text>{el.vesselName}</Text>
                </View>
                <View
                  style={[
                    {
                      width: 65,
                      borderRight: `1 solid ${systemColors.lighGrey}`
                    }
                  ]}
                >
                  <View
                    style={{
                      padding: '5 10',
                      borderBottom: `1 solid ${systemColors.lighGrey}`
                    }}
                  >
                    <Text>{el.flag}</Text>
                  </View>
                  <View
                    style={{
                      padding: '5 10'
                    }}
                  >
                    <Text>{el.year}</Text>
                  </View>
                </View>
                <View
                  style={[
                    {
                      width: 70,
                      borderRight: `1 solid ${systemColors.lighGrey}`
                    }
                  ]}
                >
                  <View
                    style={{
                      padding: '5 10',
                      borderBottom: `1 solid ${systemColors.lighGrey}`
                    }}
                  >
                    <Text style={{ width: 60 }}>
                      {el.vesselType !== null &&
                        getVesselTypeName(el.vesselType.id)}
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: '5 10'
                    }}
                  >
                    <Text>{el.dwt}</Text>
                  </View>
                </View>
                <View
                  style={[
                    {
                      width: 60,
                      borderRight: `1 solid ${systemColors.lighGrey}`
                    }
                  ]}
                >
                  <View
                    style={{
                      padding: 5,
                      borderBottom: `1 solid ${systemColors.lighGrey}`
                    }}
                  >
                    <Text style={{ width: 50 }}>
                      {el.engineType !== null &&
                        getEngineTypeName(el.engineType.id)}
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: 5
                    }}
                  >
                    <Text style={{ width: 50 }}>{el.hp}</Text>
                  </View>
                </View>
                <View
                  style={[
                    {
                      flex: 0,
                      alignContent: 'center',
                      justifyContent: 'center',
                      padding: '5 10',
                      width: 95,
                      borderRight: `1 solid ${systemColors.lighGrey}`
                    }
                  ]}
                >
                  <Text>{el.rankId !== null && getRankName(el.rankId.id)}</Text>
                </View>
                <View
                  style={[
                    {
                      width: 80,
                      borderRight: `1 solid ${systemColors.lighGrey}`
                    }
                  ]}
                >
                  <View
                    style={{
                      padding: '5 10',
                      borderBottom: `1 solid ${systemColors.lighGrey}`
                    }}
                  >
                    <Text>{el.fromDate}</Text>
                  </View>
                  <View
                    style={{
                      padding: '5 10'
                    }}
                  >
                    <Text>{el.tillDate}</Text>
                  </View>
                </View>
                {/* Ship owner and Crew agency */}
                <View
                  style={[
                    {
                      width: 95,
                      borderRight: `1 solid ${systemColors.lighGrey}`
                    }
                  ]}
                >
                  <View
                    style={{
                      padding: '5 10',
                      borderBottom: `1 solid ${systemColors.lighGrey}`
                    }}
                  >
                    {/* <Text>{el.shipOwner}</Text> */}
                    {breakLines(el.shipOwner, 1, 'table')}
                  </View>
                  <View
                    style={{
                      padding: '5 10'
                    }}
                  >
                    <Text>{el.crewAgency}</Text>
                  </View>
                </View>
              </View>
            )
          })}

          {/* Crewing agencies section */}
          <View style={styles.headLine}>
            <Text>{t('crewAganecies')}</Text>
          </View>
          <View style={[styles.section]}>
            <View
              style={[
                styles.contactHeader,
                {
                  width: 140
                }
              ]}
            >
              <Text>{t('crewAgency')}</Text>
            </View>
            <View
              style={[
                styles.contactHeader,
                {
                  width: 60
                }
              ]}
            >
              <Text>{t('country')}</Text>
            </View>
            <View
              style={[
                styles.contactHeader,
                {
                  width: 120
                }
              ]}
            >
              <Text>{t('contactPerson')}</Text>
            </View>
            <View
              style={[
                styles.contactHeader,
                {
                  width: 120
                }
              ]}
            >
              <Text>{t('email')}</Text>
            </View>
            <View
              style={[
                styles.contactHeader,
                {
                  width: 110
                }
              ]}
            >
              <Text>{t('phone')}</Text>
            </View>
          </View>
          {userArr.crewAgenciesArray.map((el) => {
            return (
              <View
                key={el.id}
                style={[
                  styles.section,
                  {
                    borderBottom: `1 solid ${systemColors.lighGrey}`,
                    margin: '0 25'
                  }
                ]}
              >
                <View style={{ width: 140, padding: '5' }}>
                  <Text style={{ width: 130 }}>{el.crewAgency}</Text>
                </View>
                <View style={{ width: 60, padding: '5' }}>
                  <Text style={{ width: 50 }}>{el.country}</Text>
                </View>
                <View style={{ width: 120, padding: '5' }}>
                  <Text style={{ width: 110 }}>{el.contactPerson}</Text>
                </View>
                <View style={{ width: 120, padding: '5' }}>
                  <Text style={{ width: 110 }}>{el.email}</Text>
                </View>
                <View style={{ width: 110, padding: '5' }}>
                  <Text style={{ width: 100 }}>{el.phone}</Text>
                </View>
              </View>
            )
          })}

          {/* General physical state section */}
          <View style={styles.headLine}>
            <Text>{t('physicalState')}</Text>
          </View>

          <View style={[styles.section]}>
            <View style={[styles.physicalHeader]}>
              <Text>{t('heightCm')}:</Text>
            </View>
            <View style={[styles.physicalHeader]}>
              <Text>{t('weightKg')}:</Text>
            </View>
            <View style={[styles.physicalHeader]}>
              <Text>{t('shoes')}:</Text>
            </View>
            <View style={[styles.physicalHeader]}>
              <Text>{t('clothes')}:</Text>
            </View>
            <View style={[styles.physicalHeader]}>
              <Text>{t('hairColor')}:</Text>
            </View>
            <View style={[styles.physicalHeader]}>
              <Text>{t('eyeColor')}:</Text>
            </View>
          </View>
          <View
            style={[
              styles.section,
              {
                borderBottom: `1 solid ${systemColors.lighGrey}`,
                margin: '0 25'
              }
            ]}
          >
            <View
              style={{
                width: 91,
                borderRight: `1 solid ${systemColors.lighGrey}`,
                borderLeft: `1 solid ${systemColors.lighGrey}`,
                padding: '5 10'
              }}
            >
              <Text>{userArr.height}</Text>
            </View>
            <View
              style={{
                width: 91,
                borderRight: `1 solid ${systemColors.lighGrey}`,
                padding: '5 10'
              }}
            >
              <Text>{userArr.weight}</Text>
            </View>
            <View
              style={{
                width: 91,
                borderRight: `1 solid ${systemColors.lighGrey}`,
                padding: '5 10'
              }}
            >
              <Text>{userArr.shoes}</Text>
            </View>
            <View
              style={{
                width: 91,
                borderRight: `1 solid ${systemColors.lighGrey}`,
                padding: '5 10'
              }}
            >
              <Text>{userArr.clothes}</Text>
            </View>
            <View
              style={{
                width: 91,
                borderRight: `1 solid ${systemColors.lighGrey}`,
                padding: '5 10'
              }}
            >
              <Text>{userArr.hairColor}</Text>
            </View>
            <View
              style={{
                width: 91,
                borderRight: `1 solid ${systemColors.lighGrey}`,
                padding: '5 10'
              }}
            >
              <Text>{userArr.eyeColor}</Text>
            </View>
          </View>

          {/* Comment section */}
          <View style={styles.headLine}>
            <Text>{t('myComment')}</Text>
          </View>
          <View style={styles.comment}>
            <Text>{userArr.comment}</Text>
          </View>

          {/* Updated */}
          <View
            style={{
              textAlign: 'center',
              width: '100%',
              color: systemColors.lightElectric,
              marginTop: 50
            }}
          >
            <Text>
              {t('updated')} {secondsToDate(userArr.date.seconds)}
            </Text>
          </View>

          {/* USER ID */}
          <View
            style={{
              textAlign: 'center',
              width: '100%',
              color: systemColors.lightBlue,
              marginTop: 5
            }}
          >
            <Text>
              {t('userId')}: {userArr.userId}
            </Text>
          </View>

          {/* Seanor logo */}
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 20
            }}
          >
            <Image style={{ width: 50 }} src={logo} />
          </View>
        </View>
      </Page>
    </Document>
  )
}
