import { Chip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const StatusChip = ({ status }) => {
  const { t } = useTranslation()

  let statusLabel = status

  /**
   * Cahnge planned status label name
   */
  if (status === 'planned') {
    statusLabel = 'atHome_planned'
  }

  return (
    <div
      className={
        status === 'planned'
          ? 'plannedStyles'
          : status === 'available'
          ? 'availableStyles'
          : status === 'atHome'
          ? 'atHomeStyles'
          : status === 'atWork'
          ? 'atWorkStyles'
          : status === 'medicine'
          ? 'medicineStyles'
          : status === 'courses'
          ? 'coursesStyles'
          : 'null'
      }
    >
      <Chip label={t(statusLabel)} size='small' variant='outlined' />
    </div>
  )
}

export default StatusChip
