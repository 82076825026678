import {
  TextField,
  MenuItem,
  Alert,
  Autocomplete,
  Button,
  Snackbar,
  Paper
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import AgencyMenu from '../../../Components/AgencyMenu'
import Headline from '../../../Components/Headline'
import Loader from '../../../Components/Loader'
import firebase from 'firebase/app'
import axios from 'axios'

const db = firebase.firestore()
const storage = firebase.storage()

const ClientReportCrew = ({ agencyData }) => {
  const { t, i18n } = useTranslation()

  // Change document title
  document.title = `${t('clientReport')} - Seanor`

  const [loadedData, setLoadedData] = useState(false)
  const [loading, setLoading] = useState(false)

  const [companiesList, setCompaniesList] = useState([])
  const [projectsList, setProjectsList] = useState([])
  const [positionsList, setPositionsList] = useState([])
  const [agencyLogoUrl, setAgencyLogoUrl] = useState('')

  const [selectedCompany, setSelectedCompany] = useState(null)
  const [selectedProject, setSelectedProject] = useState('')
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')

  const [companyError, setCompanyError] = useState(false)
  const [dateError, setDateError] = useState(false)

  const [message, setMessage] = useState({
    status: false,
    duration: 3000,
    severity: 'success',
    message: ''
  })

  useEffect(() => {
    let shouldUpdate = true

    ;(() => {
      const getCompaniesList = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .orderBy('createdTime', 'asc')
          .get()
          .then((response) => {
            let array = []
            const customerModeList = []
            const negotiationModeList = []
            const targetModeList = []
            const lostModeList = []

            response.forEach((el) => {
              let data = JSON.stringify(el.data())
              data = JSON.parse(data)
              if (data.companyMode === 'customer') {
                customerModeList.push(data)
              } else if (data.companyMode === 'negotiation') {
                negotiationModeList.push(data)
              } else if (data.companyMode === 'target') {
                targetModeList.push(data)
              } else if (data.companyMode === 'lost') {
                lostModeList.push(data)
              }
            })
            array = [
              ...customerModeList,
              ...negotiationModeList,
              ...targetModeList,
              ...lostModeList
            ]

            if (shouldUpdate) {
              setCompaniesList(array)
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      const getPositionsList = new Promise((resolve) => {
        db.collection('lists')
          .doc('positions')
          .get()
          .then((response) => {
            let data = JSON.stringify(response.data())
            data = JSON.parse(data)

            if (shouldUpdate) {
              setPositionsList(data.list)
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      const getAgencyLogoUrl = new Promise((resolve) => {
        storage
          .ref()
          .child(
            `/public/agencies/${agencyData.id}/logo/${agencyData.logoFileName}`
          )
          .getDownloadURL()
          .then((url) => {
            if (shouldUpdate) {
              setAgencyLogoUrl(url)
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      Promise.all([getCompaniesList, getPositionsList, getAgencyLogoUrl]).then(
        () => {
          if (shouldUpdate) {
            setLoadedData(true)
          }
        }
      )
    })()

    return () => {
      shouldUpdate = false
    }
  }, [agencyData])

  const getCompanyProjects = async (id) => {
    setLoading(true)
    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('companies')
      .doc(id)
      .collection('projects')
      .get()
      .then((response) => {
        let array = []
        response.forEach((el) => {
          let data = JSON.stringify(el.data())
          data = JSON.parse(data)
          array = [...array, data]
        })

        setProjectsList(array)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const generateReport = () => {
    if (new Date(dateFrom) > new Date(dateTo)) {
      setDateError(true)

      setMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('pleaseCheckYourSelectedDateRange')
      })
    } else if (selectedCompany !== null && dateFrom !== '' && dateTo !== '') {
      setLoading(true)
      let systemLanguage = i18n.language
      if (systemLanguage === 'en-BG') {
        systemLanguage = 'en'
      }
      if (systemLanguage === 'en-US') {
        systemLanguage = 'en'
      }
      if (systemLanguage === 'lt-LT') {
        systemLanguage = 'lt'
      }
      if (systemLanguage !== 'en' || systemLanguage !== 'lt') {
        systemLanguage = 'en'
      }

      axios({
        method: 'POST',
        url: 'https://europe-west1-norspeda.cloudfunctions.net/generateCrewClientReport',
        data: {
          agencyId: agencyData.id,
          language: systemLanguage,
          companyId: selectedCompany.companyId,
          companyName: selectedCompany.companyName,
          projectId: selectedProject !== '' ? selectedProject.projectId : '',
          projectName:
            selectedProject !== '' ? selectedProject.projectName : '',
          dateFrom,
          dateTo,
          positionsList,
          agencyLogoUrl
        }
      })
        .then((response) => {
          if (
            navigator.userAgent.indexOf('Safari') > -1 &&
            navigator.userAgent.indexOf('Chrome') === -1
          ) {
            window.location.href = response.data.url
          } else {
            window.open(response.data.url, '_blank')
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })

      // Resset error states
      setCompanyError(false)
      setDateError(false)
    } else {
      if (selectedCompany === null || dateFrom === '' || dateTo === '') {
        if (selectedCompany === null) {
          setCompanyError(true)
        } else {
          setCompanyError(false)
        }
        if (dateFrom === '' || dateTo === '') {
          setDateError(true)
        } else {
          setDateError(false)
        }

        setMessage({
          status: true,
          duration: 5000,
          severity: 'warning',
          message: t('pleaseFillAllRequiredFields')
        })
      }
    }
  }

  const resetFields = () => {
    setSelectedCompany(null)
    setSelectedProject('')
    setDateFrom('')
    setDateTo('')
    setCompanyError(false)
    setDateError(false)
  }

  if (!loadedData) return <Loader />

  return (
    <>
      {loading && <Loader />}
      <Headline text={t('clientReport')} />
      <AgencyMenu type='crew' />

      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      <div
        style={{
          marginTop: 50,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around'
        }}
      >
        <Paper
          style={{
            padding: 30,
            width: 'fit-content',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {/* Company */}
          <div style={{ marginBottom: 20 }}>
            <Autocomplete
              style={{ padding: 0 }}
              options={companiesList}
              value={selectedCompany}
              getOptionLabel={(option) => option.companyName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  fullWidth
                  label={t('company')}
                  variant='outlined'
                  error={companyError}
                />
              )}
              onChange={(e, val) => {
                if (val) {
                  setSelectedCompany(val)
                  getCompanyProjects(val.companyId)
                }
              }}
            />
          </div>

          {/* Project */}
          <div style={{ marginBottom: 20 }}>
            <TextField
              select
              disabled={!(projectsList.length > 0)}
              variant='outlined'
              fullWidth
              label={t('project')}
              size='small'
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
            >
              {projectsList.map((el) => (
                <MenuItem key={el.projectId} value={el}>
                  {el.projectName}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* Date from */}
          <div style={{ marginBottom: 20 }}>
            <TextField
              required
              size='small'
              variant='outlined'
              type='date'
              label={t('dateFrom')}
              InputLabelProps={{
                shrink: true
              }}
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
              fullWidth
              error={dateError}
              helperText={dateError && t('dateRangeIsIncorrect')}
            />
          </div>

          {/* Date to */}
          <div style={{ marginBottom: 20 }}>
            <TextField
              required
              size='small'
              variant='outlined'
              type='date'
              label={t('dateTo')}
              InputLabelProps={{
                shrink: true
              }}
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
              fullWidth
              error={dateError}
              helperText={dateError && t('dateRangeIsIncorrect')}
            />
          </div>

          {/* Buttons */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Generate */}
            <Button
              variant='contained'
              size='small'
              color='orange'
              onClick={() => generateReport()}
            >
              {t('generateReport')}
            </Button>

            {/* Reset */}
            <Button
              className='editEmployee__buttons-button'
              style={{ marginLeft: 20 }}
              onClick={() => resetFields()}
            >
              {t('reset')}
            </Button>
          </div>
        </Paper>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj
  }
}

export default connect(mapStateToProps)(ClientReportCrew)
