import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Loader from '../../../Components/Loader'
import firebase from 'firebase/app'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid,
  TextField,
  MenuItem,
  Snackbar
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getDayAndHours } from '../../../globalFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { systemColors, systemStylingSettings } from '../../../globalVariables'
import StatusChip from '../../../Components/StatusChip'

const db = firebase.firestore()

const useStyles = makeStyles({
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonRoot: {
    color: '#ffffff'
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: systemColors.inputColor,
      borderRadius: systemStylingSettings.borderRadius,
      '&.Mui-focused fieldset': {
        color: systemColors.darkBlue,
        borderColor: systemColors.darkBlue,
        borderWidth: systemStylingSettings.borderWidth
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 8
      }
  },
  header: {
    fontWeight: 'bold',
    color: systemColors.lightBlue
  }
})

const CrewRotationLog = ({ docId, agencyData, userData, positionsList }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [refreshData, setRefreshData] = useState(false)

  // Data lists
  const [rotationLogList, setRotationLogList] = useState([])

  const [showEditLogBlock, setShowEditLogBlock] = useState(false)

  const [message, setMessage] = useState({
    status: false,
    duration: 1500,
    severity: 'success',
    message: ''
  })

  // Edit log states
  const [logId, setLogId] = useState('')
  const [projectName, setProjectName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [signOnDate, setSignOnDate] = useState('')
  const [signOnTime, setSignOnTime] = useState('')
  const [signOffDate, setSignOffDate] = useState('')
  const [signOffTime, setSignOffTime] = useState('')
  const [position, setPosition] = useState('')
  const [status, setStatus] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [projectId, setProjectId] = useState('')
  const [recordIdInProject, setRecordIdInProject] = useState('')
  const [embarkation, setEmbarkation] = useState('')
  const [embarkationCity, setEmbarkationCity] = useState('')
  const [embarkationTime, setEmbarkationTime] = useState('')
  const [disembarkation, setDisembarkation] = useState('')
  const [disembarkationCity, setDisembarkationCity] = useState('')
  const [disembarkationTime, setDisembarkationTime] = useState('')
  const [signOffReason, setSignOffReason] = useState('')

  useEffect(() => {
    const getData = () => {
      const getRotationLogList = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(docId)
          .get()
          .then((snap) => {
            let data = JSON.stringify(snap.data())
            data = JSON.parse(data)
            const sortedRotationLog = data.rotationLog.sort(
              (a, b) => b.createdTimeStamp.seconds - a.createdTimeStamp.seconds
            )
            setRotationLogList(sortedRotationLog)
            resolve()
          })
          .catch((err) => {
            console.log("Can't get rotation log list", err)
            resolve()
          })
      })

      Promise.all([getRotationLogList]).then(() => {
        setDataLoaded(true)
      })
    }

    return getData()
  }, [docId, agencyData, refreshData, positionsList])

  const updateLog = () => {
    setLoading(true)
    const newInputs = rotationLogList.map((el) => {
      if (logId === el.id) {
        el.signOnDate = signOnDate
        el.signOnTime = signOnTime
        el.signOffDate = signOffDate
        el.signOffTime = signOffTime
        el.position = position.id
        el.timeStamp = new Date()
        el.editedBy = `${userData.name} ${userData.lastName}`
        el.status = status
      }
      return el
    })

    const updateRotationLog = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .update({
          rotationLog: newInputs
        })
        .then(() => {
          resolve()
        })
        .catch((error) => {
          console.log("Can't update log", error)
          resolve()
        })
    })

    const updateProjectEntry = new Promise((resolve) => {
      if (
        companyId !== undefined &&
        projectId !== undefined &&
        recordIdInProject !== undefined &&
        companyId !== '' &&
        projectId !== '' &&
        recordIdInProject !== ''
      ) {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .collection('projects')
          .doc(projectId)
          .collection('employees')
          .doc(recordIdInProject)
          .update({
            startOnContract: signOnDate,
            startOnContractTime: signOnTime,
            endOfContract: signOffDate,
            endOfContractTime: signOffTime,
            position: position.id,
            whoEdited: userData.uid
          })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            console.log("Can't update log", error)
            resolve()
          })
      } else {
        console.log('Company id, project id and record id in project not exist')
        resolve()
      }
    })

    Promise.all([updateRotationLog, updateProjectEntry]).then(() => {
      setProjectName('')
      setCompanyName('')
      setSignOnDate('')
      setSignOnTime('')
      setSignOffDate('')
      setSignOffTime('')
      setLogId('')
      setCompanyId('')
      setProjectId('')
      setRecordIdInProject('')
      setRefreshData(!refreshData)
      setShowEditLogBlock(false)
      setLoading(false)
      setMessage({
        status: true,
        duration: 1500,
        severity: 'success',
        message: t('logUpdated')
      })
    })
  }

  const getPositionName = (positionId) => {
    const item = positionsList.find((el) => el.id === positionId)
    return item.name
  }

  const setPositionState = (id) => {
    const item = positionsList.find((el) => el.id === id)
    setPosition(item)
  }

  if (!dataLoaded) {
    return <Loader />
  }

  return (
    <>
      {loading && <Loader />}

      {/* Snackbars */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      {/* Edit log block */}
      <Dialog
        open={showEditLogBlock}
        classes={{ paper: classes.dialogRoot }}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('editLog')}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} md={4}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                type='text'
                label={t('vesselName')}
                value={projectName}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                type='text'
                label={t('companyName')}
                value={companyName}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} md={3}>
              <TextField
                size='small'
                style={{
                  marginTop: 15
                }}
                variant='outlined'
                type='date'
                label={t('signOnDate')}
                value={signOnDate}
                onChange={(e) => setSignOnDate(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                size='small'
                style={{
                  marginTop: 15
                }}
                variant='outlined'
                type='time'
                label={t('signOnTime')}
                value={signOnTime}
                onChange={(e) => setSignOnTime(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size='small'
                style={{
                  marginTop: 15
                }}
                variant='outlined'
                type='date'
                label={t('signOffDate')}
                value={signOffDate}
                onChange={(e) => setSignOffDate(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                size='small'
                style={{
                  marginTop: 15
                }}
                variant='outlined'
                type='time'
                label={t('signOffTime')}
                value={signOffTime}
                onChange={(e) => setSignOffTime(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: 40 }}>
            {/* Embarkation city */}
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                type='text'
                label={t('embarkationCity')}
                value={embarkationCity}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            {/* Embarkation */}
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                type='date'
                label={t('embarkation')}
                value={embarkation}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            {/* Embarkation Time */}
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                type='time'
                label={t('time')}
                value={embarkationTime}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: 20 }}>
            {/* Disembarkation city */}
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                type='text'
                label={t('disembarkationCity')}
                value={disembarkationCity}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            {/* Disembarkation */}
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                type='date'
                label={t('disembarkation')}
                value={disembarkation}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            {/* Disembarkation Time */}
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                type='time'
                label={t('time')}
                value={disembarkationTime}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: 40 }}>
            {/* Position */}
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={positionsList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size='small'
                    fullWidth
                    label={t('position')}
                    variant='outlined'
                  />
                )}
                value={position}
                onChange={(e, val) => {
                  val && setPosition(val)
                }}
              />
            </Grid>

            {/* Status */}
            <Grid item xs={12} md={2}>
              <TextField
                select
                disabled={status === 'atWork'}
                variant='outlined'
                fullWidth
                label={t('status')}
                size='small'
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value)
                }}
              >
                <MenuItem value='planned'>{t('planned')}</MenuItem>
                <MenuItem value='atHome'>{t('atHome')}</MenuItem>
                <MenuItem value='medicine'>{t('medicine')}</MenuItem>
                <MenuItem value='courses'>{t('courses')}</MenuItem>
                <MenuItem value='atWork' disabled>
                  {t('atWork')}
                </MenuItem>
              </TextField>
            </Grid>

            {/* Sign off reason */}
            <Grid item xs={12} md={4}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                type='text'
                label={t('signOffReason')}
                value={t(signOffReason)}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Update */}
          <Button
            variant='contained'
            size='small'
            color='orange'
            onClick={() => updateLog()}
          >
            {t('update')}
          </Button>

          {/* Cancel */}
          <Button
            onClick={() => {
              setShowEditLogBlock(false)
              setProjectName('')
              setCompanyName('')
              setSignOnDate('')
              setSignOnTime('')
              setSignOffDate('')
              setSignOffTime('')
              setLogId('')
              setCompanyId('')
              setProjectId('')
              setRecordIdInProject('')
              setEmbarkation('')
              setEmbarkationCity('')
              setEmbarkationTime('')
              setDisembarkation('')
              setDisembarkationCity('')
              setDisembarkationTime('')
              setSignOffReason('')
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className={classes.header}>{t('status')}</TableCell>
            <TableCell className={classes.header}>{t('vesselName')}</TableCell>
            <TableCell className={classes.header}>{t('companyName')}</TableCell>
            <TableCell className={classes.header}>{t('from')}</TableCell>
            <TableCell className={classes.header}>{t('to')}</TableCell>
            <TableCell className={classes.header}>{t('position')}</TableCell>
            <TableCell className={classes.header}>{t('editedBy')}</TableCell>
            <TableCell className={classes.header}>{t('timeStamp')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rotationLogList.length > 0 ? (
            rotationLogList.map((el, i) => (
              <TableRow key={el.id} hover>
                <TableCell>{i + 1}</TableCell>

                {/* Status */}
                <TableCell>
                  <StatusChip status={el.status} />
                </TableCell>

                {/* Project name */}
                <TableCell>{el.projectName}</TableCell>

                {/* Company name */}
                <TableCell>{el.companyName}</TableCell>

                {/* From */}
                <TableCell>
                  {el.signOnDate} {el.signOnTime}
                </TableCell>

                {/* To */}
                <TableCell>
                  {el.status === 'atHome' ? (
                    <div>-</div>
                  ) : (
                    <div>
                      {el.signOffDate} {el.signOffTime}
                    </div>
                  )}
                </TableCell>

                {/* Position */}
                <TableCell>{getPositionName(el.position)}</TableCell>

                {/* Edited by */}
                <TableCell>{el.editedBy}</TableCell>

                {/* Time stamp */}
                <TableCell>{getDayAndHours(el.timeStamp.seconds)}</TableCell>

                {/* Edit button */}
                <TableCell>
                  <div
                    className='getProjectsEmployeesList__editButton'
                    onClick={() => {
                      setProjectName(el.projectName)
                      setCompanyName(el.companyName)
                      setSignOnDate(el.signOnDate)
                      setSignOnTime(el.signOnTime)
                      setSignOffDate(el.signOffDate)
                      setSignOffTime(el.signOffTime)
                      setPositionState(el.position)
                      setStatus(el.status)
                      setLogId(el.id)
                      setCompanyId(el.companyId)
                      setProjectId(el.projectId)
                      setRecordIdInProject(el.recordIdInProject)
                      setEmbarkation(el.embarkation)
                      setEmbarkationCity(el.embarkationCity)
                      setEmbarkationTime(el.embarkationTime)
                      setDisembarkation(el.disembarkation)
                      setDisembarkationCity(el.disembarkationCity)
                      setDisembarkationTime(el.disembarkationTime)
                      setSignOffReason(el.signOffReason)
                      setShowEditLogBlock(true)
                    }}
                  >
                    <FontAwesomeIcon style={{ fontSize: 12 }} icon={faEdit} />
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={10} style={{ textAlign: 'center' }}>
                {t('thereIsNoHistory')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    userData: state.userDataRed.obj
  }
}

export default connect(mapStateToProps)(CrewRotationLog)
