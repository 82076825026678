interface PageProps {
  content: string
  className?: string
}
const TextEditorReader = ({ content, className }: PageProps) => {
  return (
    <div
      className={className}
      style={{ display: 'inline' }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

export default TextEditorReader
