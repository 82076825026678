import React, { useState } from 'react'
import {
  Grid,
  Alert,
  TextField,
  MenuItem,
  Snackbar,
  Tooltip,
  Button
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next/'
import AgencyMenu from '../../Components/AgencyMenu'
import Headline from '../../Components/Headline'
import {
  systemStylingSettings,
  systemColors,
  acceptedFileSize,
  companyModes,
  sourceOptions
} from '../../globalVariables'
import { countries } from '../../countries'
import { randomNumber } from '../../globalFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import Loader from '../../Components/Loader'
import firebase from 'firebase/app'
import { connect } from 'react-redux'

const db = firebase.firestore()
const storage = firebase.storage()

const useStyle = makeStyles({
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonText: {
    color: '#ffffff !important'
  }
})

const AddNewVessel = ({ agencyData, userUID }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  // Change document title
  document.title = `${t('addNewCompany')} - Seanor`

  const [loading, setLoading] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [companyCountry, setCompanyCountry] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [selectedCompanyMode, setSelectedCompanyMode] = useState('')
  const [source, setSource] = useState('')
  const [internetSite, setInternetSite] = useState('')
  const [contactPosition, setContactPosition] = useState('')

  const [documentsArr, setDocumentsArr] = useState([
    {
      id: randomNumber(10, true, false),
      documentName: '',
      issued: '',
      expires: '',
      fileName: null
    }
  ])

  const [message, setMessage] = useState({
    status: false,
    duration: 1500,
    severity: 'success',
    message: ''
  })

  const [fileSizeErr, setFileSizeErr] = useState(false)
  const [companyNameErr, setCompanyNameErr] = useState(false)
  const [selectedCompanyModeErr, setSelectedCompanyModeErr] = useState(false)

  const [companyIdForUrl, setCompanyIdForUrl] = useState('')

  const addDocument = (id, val, name) => {
    const newInput = documentsArr.map((el) => {
      if (id === el.id) {
        el[name] = val
      }
      return el
    })
    setDocumentsArr(newInput)
  }

  const removeDocument = (index) => {
    if (documentsArr.length > 1) {
      const values = [...documentsArr]
      values.splice(index, 1)
      setDocumentsArr(values)
    }
  }

  const addDocumentInput = () => {
    const blockId = randomNumber(10, true, false)
    setDocumentsArr([
      ...documentsArr,
      {
        id: blockId,
        documentName: '',
        issued: '',
        expires: '',
        fileName: ''
      }
    ])
  }

  const createCompany = () => {
    if (companyName !== '' && selectedCompanyMode !== '') {
      setLoading(true)

      const companyId = randomNumber(10, true, false)

      // Create new documents array with file name
      const newDocumentsArr = []
      documentsArr.map((el) =>
        newDocumentsArr.push({
          id: el.id,
          documentName: el.documentName,
          issued: el.issued,
          expires: el.expires,
          fileName:
            el.file !== null && el.file !== '' && el.file !== undefined
              ? el.file[0].name
              : null
        })
      )

      // Create search terms array
      const array = companyName.split(' ')
      const companyNameArray = []
      array.forEach((el) => {
        companyNameArray.push(el.toLocaleLowerCase())
      })

      const addDataToDatabase = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .set({
            companyId,
            companyName,
            companyAddress,
            companyCountry,
            contactPerson: {
              firstName,
              lastName,
              companyEmail,
              companyPhone,
              contactPosition
            },
            documents: newDocumentsArr,
            timeStamp: new Date(),
            createCompanyUser: userUID,
            editCompanyUser: '',
            notes: [],
            companyMode: selectedCompanyMode,
            searchTerms: companyNameArray,
            source,
            internetSite,
            createdTime: new Date()
          })
          .then(() => {
            resolve()
          })
          .catch((err) => {
            console.log({
              message: "Can't to add data to database",
              error: err
            })
            resolve()
          })
      })

      const addFilesToStorage = new Promise((resolve) => {
        const promise = documentsArr.forEach((el) => {
          if (el.file !== null && el.file !== '' && el.file !== undefined) {
            storage
              .ref(
                `/agencies/${agencyData.id}/companies/${companyId}/documents/${el.file[0].name}`
              )
              .put(el.file[0])
              .catch((error) => console.log(error))
          }
        })

        Promise.all([promise])
          .then(() => resolve())
          .catch((err) => {
            console.log({
              message: "Can't add file to storage",
              error: err
            })
            resolve()
          })
      })

      Promise.all([addDataToDatabase, addFilesToStorage])
        .then(() => {
          setLoading(false)
          setMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('companyAdded')
          })

          setCompanyIdForUrl(companyId)

          // Restore input fields and errors
          setCompanyName('')
          setCompanyAddress('')
          setCompanyCountry('')
          setFirstName('')
          setLastName('')
          setCompanyEmail('')
          setCompanyPhone('')
          setSelectedCompanyMode('')
          setDocumentsArr([
            {
              id: randomNumber(10, true, false),
              documentName: '',
              issued: '',
              expires: '',
              file: null
            }
          ])
          setFileSizeErr(false)
          setCompanyNameErr(false)
          setSelectedCompanyModeErr(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err.message, err.error)
        })
    } else {
      if (companyName === '') {
        setCompanyNameErr(true)
        setMessage({
          status: true,
          duration: 5000,
          severity: 'warning',
          message: t('mustFillRequiredFields')
        })
      } else {
        setCompanyNameErr(false)
      }
      if (selectedCompanyMode === '') {
        setSelectedCompanyModeErr(true)
        setMessage({
          status: true,
          duration: 5000,
          severity: 'warning',
          message: t('mustFillRequiredFields')
        })
      } else {
        setSelectedCompanyModeErr(false)
      }
    }
  }

  return (
    <>
      {loading && <Loader />}

      <Headline text={t('addNewCompany')} />

      <AgencyMenu type='crew' />

      {/* Snackbars */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={2} style={{ marginTop: 30 }}>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            required
            size='small'
            variant='outlined'
            type='text'
            label={t('companyName')}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            fullWidth
            error={companyNameErr}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            size='small'
            variant='outlined'
            type='text'
            label={t('address')}
            value={companyAddress}
            onChange={(e) => setCompanyAddress(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            select
            variant='outlined'
            fullWidth
            label={t('country')}
            size='small'
            value={companyCountry}
            onChange={(e) => setCompanyCountry(e.target.value)}
          >
            {countries.map((el, i) => (
              <MenuItem key={i} value={el}>
                {el}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            select
            required
            variant='outlined'
            fullWidth
            label={t('mode')}
            size='small'
            value={selectedCompanyMode}
            onChange={(e) => setSelectedCompanyMode(e.target.value)}
            error={selectedCompanyModeErr}
          >
            {companyModes.map((el, i) => (
              <MenuItem key={i} value={el}>
                {t(el)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            select
            variant='outlined'
            fullWidth
            label={t('source')}
            size='small'
            value={source}
            onChange={(e) => setSource(e.target.value)}
          >
            {sourceOptions.map((el, i) => (
              <MenuItem key={i} value={el}>
                {t(el)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            size='small'
            variant='outlined'
            type='url'
            label={t('internetSite')}
            value={internetSite}
            onChange={(e) => setInternetSite(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>

      <div className='addNewCompany__title'>{t('contactPerson')}</div>

      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            size='small'
            variant='outlined'
            type='text'
            label={t('firstName')}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            size='small'
            variant='outlined'
            type='text'
            label={t('lastName')}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            size='small'
            variant='outlined'
            type='email'
            label={t('email')}
            value={companyEmail}
            onChange={(e) => setCompanyEmail(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            size='small'
            variant='outlined'
            type='tel'
            label={t('phone')}
            value={companyPhone}
            onChange={(e) => setCompanyPhone(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            size='small'
            variant='outlined'
            type='text'
            label={t('position')}
            value={contactPosition}
            onChange={(e) => setContactPosition(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>

      <div className='addNewCompany__title'>{t('documents')}</div>

      {documentsArr.map((el, i) => (
        <Grid
          container
          key={el.id}
          spacing={2}
          style={{
            marginTop: 10,
            marginBottom: 20
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              size='small'
              variant='outlined'
              type='text'
              label={t('documentName')}
              value={el.documentName}
              onChange={(e) =>
                addDocument(el.id, e.target.value, 'documentName')
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              size='small'
              variant='outlined'
              type='date'
              label={t('issueDate')}
              InputLabelProps={{
                shrink: true
              }}
              value={el.issued}
              onChange={(e) => addDocument(el.id, e.target.value, 'issued')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              size='small'
              variant='outlined'
              type='date'
              label={t('expireDate')}
              InputLabelProps={{
                shrink: true
              }}
              value={el.expires}
              onChange={(e) => addDocument(el.id, e.target.value, 'expires')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              size='small'
              variant='outlined'
              type='file'
              label={t('upload')}
              InputLabelProps={{
                shrink: true
              }}
              onChange={(e) => {
                const file = e.target.files
                if (file.length > 0) {
                  if (file[0].size > acceptedFileSize) {
                    setFileSizeErr(true)
                  } else {
                    addDocument(el.id, file, 'file')
                    setFileSizeErr(false)
                  }
                }
              }}
              fullWidth
              inputProps={{
                accept: '.png, .jpg, .jpeg, .pdf'
              }}
              helperText={fileSizeErr ? t('toBigFile') : t('acceptFormat2')}
              error={fileSizeErr}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Tooltip placement='right' title={t('removeDocument')}>
              <div
                style={{
                  width: 'fit-content',
                  marginTop: 8,
                  cursor: 'pointer'
                }}
                className='global__onHover'
                onClick={() => removeDocument(i)}
              >
                <FontAwesomeIcon
                  style={{ color: systemColors.lighGrey }}
                  icon={faTrash}
                />
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      ))}

      <div
        className='addNewEmployee__addRemove'
        onClick={() => addDocumentInput()}
      >
        <FontAwesomeIcon className='icon' icon={faPlus} />
        <span>{t('addDocument')}</span>
      </div>

      {/* Buttons */}
      <div className='addNewEmployee__addCompanyButton'>
        {/* Add company button */}
        <Button
          variant='contained'
          size='small'
          classes={{
            contained: classes.buttonContained,
            sizeSmall: classes.buttonText
          }}
          onClick={() => createCompany()}
        >
          {t('addCompany')}
        </Button>

        {/* Link to new company */}
        {companyIdForUrl !== '' && (
          <Button
            style={{ margin: '0 20px' }}
            onClick={() =>
              window.open(`/${agencyData.id}/vessels/vessel/${companyIdForUrl}`)
            }
          >
            {t('goToAddedCompanyPage')}
          </Button>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj
  }
}

export default connect(mapStateToProps)(AddNewVessel)
