import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import Loader from '../../../Components/Loader'
import {
  AccordionSummary,
  Accordion,
  Alert,
  AccordionDetails,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Snackbar,
  Grid,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { systemColors, systemStylingSettings } from '../../../globalVariables'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

const db = firebase.firestore()

const useStyles = makeStyles({
  accordionSummaryRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff',
    borderRadius: systemStylingSettings.borderRadius
  },
  expandIcon: {
    fontSize: 15,
    color: '#ffffff'
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius,
      backgroundColor: systemColors.inputColor
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonText: {
    color: '#ffffff'
  },
  buttonRoot: {
    color: '#ffffff'
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  progressGreen: {
    backgroundColor: systemColors.inputColor,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: systemColors.lightGreen
    }
  },
  progressOrange: {
    backgroundColor: systemColors.inputColor,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'orange'
    }
  },
  progressRed: {
    backgroundColor: systemColors.inputColor,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: systemColors.orange
    }
  }
})

const ExpenseTracking = ({ agencyData, companyId, userUID }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [loadedData, setLoadedData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [projectsList, setProjectsList] = useState([])
  const [refreshData, setRefreshData] = useState(false)

  const [showEditBudgetDialog, setShowEditBudgetDialog] = useState(false)
  const [message, setMessage] = useState({
    status: false,
    duration: 3000,
    severity: 'success',
    message: ''
  })

  const [currentProjectId, setCurrentProjectId] = useState('')
  const [totalBudget, setTotalBudget] = useState(0)
  const [addOrEditExpenseTitle, setAddOrEditExpenseTitle] = useState('')
  const [showAddOrEditExpenseDialog, setShowAddOrEditExpenseDialog] =
    useState(false)

  const [expenseDate, setExpenseDate] = useState('')
  const [expensesTypes, setExpensesTypes] = useState([])
  const [expenseType, setExpenseType] = useState('')
  const [showNewTypeDielog, setShowNewTypeDielog] = useState(false)
  const [newExpenseTypeName, setNewExpenseTypeName] = useState('')
  const [description, setDescription] = useState('')
  const [amount, setAmount] = useState(0)
  const [expenseId, setExpenseId] = useState('')
  const [allExpenses, setAllExpenses] = useState([])

  const [showDeleteExpensesWarningDialog, setShowDeleteExpensesWarningDialog] =
    useState(false)

  useEffect(() => {
    let shouldUpdate = true
    ;(() => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('companies')
        .doc(companyId)
        .collection('projects')
        .get()
        .then((response) => {
          let array = []
          let archiveArray = []
          response.forEach((el) => {
            let data = JSON.stringify(el.data())
            data = JSON.parse(data)
            if (data.status === 'active') {
              array = [...array, data]
            } else if (data.status === 'archive') {
              archiveArray = [...archiveArray, data]
            }
          })
          array = [...array, ...archiveArray]

          if (shouldUpdate) {
            setProjectsList(array)
            setLoadedData(true)
          }
        })
        .catch((error) => {
          console.log(error)
          if (shouldUpdate) {
            setLoadedData(true)
          }
        })
    })()

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, companyId, refreshData])

  const updateBudget = async () => {
    setLoading(true)
    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('companies')
      .doc(companyId)
      .collection('projects')
      .doc(currentProjectId)
      .update({
        'expenseTracking.totalBudget': totalBudget
      })
      .then(() => {
        setShowEditBudgetDialog(false)
        setTotalBudget(0)
        setCurrentProjectId('')
        setLoading(false)
        setMessage({
          status: false,
          duration: 3000,
          severity: 'success',
          message: 'budgetWasUpdated'
        })
        setRefreshData(!refreshData)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        setMessage({
          status: false,
          duration: 3000,
          severity: 'warning',
          message: 'unableToUpdateBudget'
        })
      })
  }

  const addOrEditExpense = async () => {
    if (addOrEditExpenseTitle === 'addNewExpense') {
      const newExpensesArray = [
        ...allExpenses,
        {
          id: uuid(),
          date: expenseDate,
          expenseType,
          description,
          amount,
          timeStamp: new Date(),
          createdBy: userUID,
          editedBy: '',
          editedDate: new Date()
        }
      ]

      // Get sum of all expenses
      let newTotalExpenses = 0
      newExpensesArray.forEach((el) => {
        newTotalExpenses = newTotalExpenses + Number(el.amount)
      })

      setLoading(true)
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('companies')
        .doc(companyId)
        .collection('projects')
        .doc(currentProjectId)
        .update({
          'expenseTracking.totalExpenses': newTotalExpenses,
          'expenseTracking.expenses': newExpensesArray
        })
        .then(() => {
          setRefreshData(!refreshData)
          setAddOrEditExpenseTitle('')
          setExpenseDate('')
          setExpensesTypes([])
          setExpenseType('')
          setDescription('')
          setAmount(0)
          setAllExpenses([])
          setShowAddOrEditExpenseDialog(false)
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    } else if (addOrEditExpenseTitle === 'editExpense') {
      // Edit expense
      const editedExpenses = allExpenses.map((el) => {
        if (expenseId === el.id) {
          el.date = expenseDate
          el.expenseType = expenseType
          el.description = description
          el.amount = amount
          el.editedBy = userUID
          el.editedDate = new Date()
        }
        return el
      })

      // Get sum of all expenses
      let newTotalExpenses = 0
      editedExpenses.forEach((el) => {
        newTotalExpenses = newTotalExpenses + Number(el.amount)
      })

      setLoading(true)
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('companies')
        .doc(companyId)
        .collection('projects')
        .doc(currentProjectId)
        .update({
          'expenseTracking.totalExpenses': newTotalExpenses,
          'expenseTracking.expenses': editedExpenses
        })
        .then(() => {
          setRefreshData(!refreshData)
          setAddOrEditExpenseTitle('')
          setExpenseDate('')
          setExpensesTypes([])
          setExpenseType('')
          setDescription('')
          setAmount(0)
          setAllExpenses([])
          setShowAddOrEditExpenseDialog(false)
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }
  }

  const addNewExpenseType = async () => {
    const newExpenseTypesArray = [...expensesTypes, newExpenseTypeName]
    setLoading(true)
    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('companies')
      .doc(companyId)
      .collection('projects')
      .doc(currentProjectId)
      .update({
        'expenseTracking.expensesTypes': newExpenseTypesArray
      })
      .then(async () => {
        await db
          .collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .collection('projects')
          .doc(currentProjectId)
          .get()
          .then((response) => {
            let data = JSON.stringify(response.data())
            data = JSON.parse(data)
            setExpensesTypes(data.expenseTracking.expensesTypes)
            setShowNewTypeDielog(false)
            setNewExpenseTypeName('')
            setLoading(false)
            setMessage({
              status: false,
              duration: 3000,
              severity: 'success',
              message: 'newExpenseTypeAdded'
            })
            setRefreshData(!refreshData)
          })
          .catch((error) => {
            setLoading(false)
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        setMessage({
          status: false,
          duration: 3000,
          severity: 'warning',
          message: 'unableToAddNewExpenseType'
        })
      })
  }

  const deleteExpense = async () => {
    // Remove expense from array
    const newExpensesArray = allExpenses.filter((obj) => obj.id !== expenseId)

    // Get sum of all expenses
    let newTotalExpenses = 0
    newExpensesArray.forEach((el) => {
      newTotalExpenses = newTotalExpenses + Number(el.amount)
    })

    setLoading(true)
    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('companies')
      .doc(companyId)
      .collection('projects')
      .doc(currentProjectId)
      .update({
        'expenseTracking.totalExpenses': newTotalExpenses,
        'expenseTracking.expenses': newExpensesArray
      })
      .then(() => {
        setRefreshData(!refreshData)
        setAddOrEditExpenseTitle('')
        setExpenseDate('')
        setExpensesTypes([])
        setExpenseType('')
        setDescription('')
        setAmount(0)
        setAllExpenses([])
        setShowDeleteExpensesWarningDialog(false)
        setShowAddOrEditExpenseDialog(false)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const getProgressValue = (object) => {
    let budget = 0
    let expenses = 0

    if (object.expenseTracking !== undefined) {
      if (object.expenseTracking.totalBudget !== undefined) {
        budget = object.expenseTracking.totalBudget
      }
      if (object.expenseTracking.totalExpenses !== undefined) {
        expenses = object.expenseTracking.totalExpenses
      }
    }

    // Calculate perscent of expenses from budget
    let percentNumber = Math.round((expenses * 100) / budget)

    if (isNaN(percentNumber)) {
      percentNumber = 0
    }

    return percentNumber
  }

  if (!loadedData) return <Loader />

  return (
    <>
      {loading && <Loader />}

      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      {/* Edit budget */}
      <Dialog
        open={showEditBudgetDialog}
        onClose={() => setShowEditBudgetDialog(false)}
        classes={{ paper: classes.dialogRoot }}
        maxWidth='sm'
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('editBudget')}
        </DialogTitle>

        <DialogContent>
          <TextField
            size='small'
            style={{ margin: '20px 0' }}
            variant='outlined'
            type='number'
            label={t('budget')}
            value={totalBudget}
            onChange={(e) => setTotalBudget(e.target.value)}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          {/* Save */}
          <Button
            variant='contained'
            size='small'
            color='orange'
            onClick={() => updateBudget()}
          >
            {t('save')}
          </Button>

          {/* Close */}
          <Button
            onClick={() => {
              setShowEditBudgetDialog(false)
              setTotalBudget(0)
              setCurrentProjectId('')
            }}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add new expense type */}
      <Dialog
        open={showNewTypeDielog}
        onClose={() => setShowNewTypeDielog(false)}
        classes={{ paper: classes.dialogRoot }}
        maxWidth='sm'
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('addNewExpenseType')}
        </DialogTitle>

        <DialogContent>
          <TextField
            size='small'
            style={{ margin: '20px 0' }}
            variant='outlined'
            type='text'
            label={t('expenseTypeName')}
            value={newExpenseTypeName}
            onChange={(e) => setNewExpenseTypeName(e.target.value)}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          {/* Save */}
          <Button
            variant='contained'
            size='small'
            color='orange'
            onClick={() => addNewExpenseType()}
          >
            {t('save')}
          </Button>

          {/* Close */}
          <Button
            onClick={() => {
              setShowNewTypeDielog(false)
              setNewExpenseTypeName('')
            }}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add new or edit expense */}
      <Dialog
        open={showAddOrEditExpenseDialog}
        onClose={() => setShowAddOrEditExpenseDialog(false)}
        classes={{ paper: classes.dialogRoot }}
        maxWidth='lg'
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t(addOrEditExpenseTitle)}
        </DialogTitle>

        <DialogContent>
          <Grid container style={{ margin: '20px 0' }} spacing={2}>
            {/* Date */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                size='small'
                variant='outlined'
                type='date'
                label={t('date')}
                InputLabelProps={{
                  shrink: true
                }}
                value={expenseDate}
                onChange={(e) => setExpenseDate(e.target.value)}
                fullWidth
              />
            </Grid>

            {/* Expense Type */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                variant='outlined'
                label={t('expenseType')}
                size='small'
                value={expenseType}
                onChange={(e) => {
                  if (e.target.value === 'newType') {
                    setShowNewTypeDielog(true)
                  } else {
                    setExpenseType(e.target.value)
                  }
                }}
                fullWidth
              >
                {expensesTypes.map((el, index) => (
                  <MenuItem key={index} value={el}>
                    {el}
                  </MenuItem>
                ))}
                <MenuItem value='newType'>{t('addNewExpenseType')}</MenuItem>
              </TextField>
            </Grid>

            {/* Description */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                size='small'
                variant='outlined'
                type='text'
                label={t('description')}
                value={description}
                multiline
                rows={3}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </Grid>

            {/* Amount */}
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                size='small'
                variant='outlined'
                type='number'
                label={t('amount')}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Delete expense */}
          {addOrEditExpenseTitle === 'editExpense' ? (
            <Button
              variant='contained'
              size='small'
              color='orange'
              onClick={() => setShowDeleteExpensesWarningDialog(true)}
            >
              {t('deleteExpense')}
            </Button>
          ) : null}

          {/* Save */}
          <Button
            variant='contained'
            size='small'
            color='orange'
            onClick={() => addOrEditExpense()}
          >
            {addOrEditExpenseTitle === 'addNewExpense'
              ? t('addExpense')
              : t('editExpense')}
          </Button>

          {/* Close */}
          <Button
            onClick={() => {
              setShowAddOrEditExpenseDialog(false)
              setAddOrEditExpenseTitle('')
              setExpenseDate('')
              setExpensesTypes([])
              setExpenseType('')
              setDescription('')
              setAmount(0)
              setAllExpenses([])
              setExpenseId('')
            }}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete expense message */}
      <Dialog
        open={showDeleteExpensesWarningDialog}
        onClose={() => setShowDeleteExpensesWarningDialog(false)}
        classes={{ paper: classes.dialogRoot }}
        maxWidth='sm'
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('editBudget')}
        </DialogTitle>

        <DialogContent>{t('doYouReallyWantToDeleteThisExpense')}</DialogContent>

        <DialogActions>
          {/* Delete expense */}
          <Button
            variant='contained'
            size='small'
            color='orange'
            onClick={() => deleteExpense()}
          >
            {t('deleteExpense')}
          </Button>

          {/* Close */}
          <Button
            onClick={() => {
              setShowDeleteExpensesWarningDialog(false)
            }}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* List of projects */}
      <div>
        {projectsList.map((el) => (
          <Accordion
            key={el.projectId}
            style={{
              margin: '5px 0'
            }}
          >
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
              classes={{
                root: classes.accordionSummaryRoot,
                expandIcon: classes.expandIcon
              }}
              style={{
                backgroundColor: el.status === 'archive' && systemColors.grey
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {el.projectName}
                <LinearProgress
                  className={
                    getProgressValue(el) <= 50
                      ? classes.progressGreen
                      : getProgressValue(el) < 85 && getProgressValue(el) > 50
                      ? classes.progressOrange
                      : classes.progressRed
                  }
                  style={{
                    width: 200,
                    marginLeft: 40,
                    height: 6
                  }}
                  variant='determinate'
                  value={getProgressValue(el)}
                />
                <div style={{ marginLeft: 20 }}>{getProgressValue(el)}%</div>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {/* Balance summary */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginTop: 20
                }}
              >
                {/* Budget */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {t('budget')}:
                  <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
                    {el.expenseTracking !== undefined
                      ? el.expenseTracking.totalBudget
                      : 0}
                  </span>
                  {/* Edit budget */}
                  <Tooltip title={t('editBudget')} placement='right'>
                    <div>
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{
                          marginLeft: 15,
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setShowEditBudgetDialog(!showEditBudgetDialog)
                          if (el.expenseTracking !== undefined) {
                            setTotalBudget(el.expenseTracking.totalBudget)
                          } else {
                            setTotalBudget(0)
                          }
                          setCurrentProjectId(el.projectId)
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>

                {/* Expenses */}
                <div>
                  {t('expenses')}:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {el.expenseTracking !== undefined &&
                    el.expenseTracking.totalExpenses !== undefined
                      ? el.expenseTracking.totalExpenses
                      : 0}
                  </span>
                </div>

                {/* Remaining */}
                <div>
                  {t('remaining')}:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {el.expenseTracking !== undefined &&
                    el.expenseTracking.totalExpenses !== undefined &&
                    el.expenseTracking.totalBudget !== undefined
                      ? el.expenseTracking.totalBudget -
                        el.expenseTracking.totalExpenses
                      : el.expenseTracking !== undefined &&
                        el.expenseTracking.totalExpenses === undefined &&
                        el.expenseTracking.totalBudget !== undefined
                      ? el.expenseTracking.totalBudget
                      : 0}
                  </span>
                </div>
              </div>

              {/* Expenses table */}
              <Table stickyHeader style={{ margin: '20px 0' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('date')}</TableCell>
                    <TableCell>{t('expenseType')}</TableCell>
                    <TableCell>{t('description')}</TableCell>
                    <TableCell>{t('amount')}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {el.expenseTracking !== undefined &&
                  el.expenseTracking.expenses !== undefined &&
                  el.expenseTracking.expenses.length > 0 ? (
                    el.expenseTracking.expenses.map((expense, index) => (
                      <TableRow key={expense.id}>
                        <TableCell>{expense.date}</TableCell>
                        <TableCell>{expense.expenseType}</TableCell>
                        <TableCell>{expense.description}</TableCell>
                        <TableCell>{expense.amount}</TableCell>
                        <TableCell>
                          <Tooltip title={t('edit')} placement='right'>
                            <div style={{ width: 'fit-content' }}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  setExpenseId(expense.id)
                                  setAddOrEditExpenseTitle('editExpense')
                                  setExpenseDate(expense.date)
                                  setExpensesTypes(
                                    el.expenseTracking.expensesTypes
                                  )
                                  setExpenseType(expense.expenseType)
                                  setDescription(expense.description)
                                  setAmount(expense.amount)
                                  setAllExpenses(el.expenseTracking.expenses)
                                  setCurrentProjectId(el.projectId)
                                  setShowAddOrEditExpenseDialog(true)
                                }}
                              />
                            </div>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow
                      style={{
                        marginTop: 20,
                        textAlign: 'center',
                        width: '100%'
                      }}
                    >
                      <TableCell align='center' colSpan={5}>
                        {t('thereIsNoAnyExpenses')}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell rowSpan={1} />
                    <TableCell
                      colSpan={2}
                      style={{ fontWeight: 'bold' }}
                      align='right'
                    >
                      {t('total')}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {el.expenseTracking !== undefined &&
                      el.expenseTracking.totalExpenses !== undefined
                        ? el.expenseTracking.totalExpenses
                        : 0}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableBody>
              </Table>

              {/* Add expense */}
              <div style={{ margin: '20px 0' }}>
                <Button
                  variant='contained'
                  size='small'
                  color='orange'
                  onClick={() => {
                    setShowAddOrEditExpenseDialog(true)
                    setAddOrEditExpenseTitle('addNewExpense')
                    setCurrentProjectId(el.projectId)
                    if (
                      el.expenseTracking !== undefined &&
                      el.expenseTracking.expensesTypes !== undefined
                    ) {
                      setExpensesTypes(el.expenseTracking.expensesTypes)
                    }
                    if (
                      el.expenseTracking !== undefined &&
                      el.expenseTracking.expenses !== undefined
                    ) {
                      setAllExpenses(el.expenseTracking.expenses)
                    }
                  }}
                >
                  {t('addNewExpense')}
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  agencyData: state.agencyDataRed.obj
})

export default connect(mapStateToProps)(ExpenseTracking)
