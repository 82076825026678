import { useTranslation } from 'react-i18next'
import './fileUploadLoader.scss'

const FileUploadLoader = () => {
  const { t } = useTranslation()
  return (
    <div className='fileUploadLoader'>
      <div className='loader'>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>

      <span>{t('uploading')}...</span>
    </div>
  )
}

export default FileUploadLoader
