import { useState } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

interface Props {
  /** Pdf document */
  document: any
  /** Employee first name */
  firstName: string
  /** Employee last name */
  lastName: string
}

const EvaluationPDFViewer = ({ document, firstName, lastName }: Props) => {
  const { t } = useTranslation()

  const [download, setDownload] = useState<boolean>(false)

  if (!download) {
    return <div onClick={() => setDownload(true)}>{t('preview')}</div>
  }

  if (download) {
    return (
      <PDFDownloadLink
        document={document}
        fileName={`${firstName} ${lastName} CV.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? `${t('generating')}...` : t('download')
        }
      </PDFDownloadLink>
    )
  }
}

export default EvaluationPDFViewer
