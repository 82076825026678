import React from 'react';
import { useTranslation } from 'react-i18next'

const FourZeroFour = () => {
    const { t } = useTranslation()
    return (
        <div>
            {t('fourZeroFour')}
        </div>
    );
}
 
export default FourZeroFour;