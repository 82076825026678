import firebase from 'firebase/app'
import { v4 } from 'uuid'
const db = firebase.firestore()

export const createNotification = async (
  agencyId: string,
  title: string,
  source: 'system' | 'tasks' | 'jobs_tasks' | 'recruitment',
  userUid: string
) => {
  const id: string = v4()

  /**
   * Notification data
   */
  const notification: SystemNotification = {
    _id: id,
    title,
    source,
    userUid,
    readed: false,
    timestamp: new Date()
  }

  await db
    .collection('agencies')
    .doc(agencyId)
    .collection('notifications')
    .doc(id)
    .set({ data: notification })
    .catch((error) => console.error(error))
}
