import React, { useState } from 'react'
import { TextField } from '@mui/material'
import DatePicker from 'react-date-picker'
import './SeanorDatePicker.scss'

interface Props {
  label?: string
  error?: boolean
  value: string
  onChange: (date: string) => void
}

const SeanorDatePicker = ({ error, label, value, onChange }: Props) => {
  const [date, setDate] = useState<Date | null>(
    value !== '' ? new Date(value) : null
  )

  const handleDateChange = (date: Date) => {
    setDate(date)
    if (date !== null) {
      const year: string = new Date(date).getFullYear().toString()
      let month: number | string = new Date(date).getMonth() + 1
      if (month.toString().length < 2) {
        month = `0${month}`
      }

      let day: number | string = new Date(date).getDate()
      if (day.toString().length < 2) {
        day = `0${day}`
      }

      onChange(`${year}-${month}-${day}`)
    }

    if (date === null) {
      onChange('')
    }
  }

  return (
    <TextField
      className='picker'
      required
      fullWidth
      size='small'
      label={label}
      InputLabelProps={{
        shrink: true
      }}
      error={error}
      InputProps={{
        endAdornment: (
          <DatePicker
            showWeekNumbers
            format='y-MM-dd'
            value={date}
            onChange={handleDateChange}
          />
        )
      }}
    />
  )
}

export default SeanorDatePicker
