import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import Loader from '../Components/Loader'


function AgencyRoute({component: Component, agencyMatch, loadAgencies, ...rest}){
    if (loadAgencies === false) {
        return <Loader />
    } 
        return (
            <Route 
                {...rest}
                render={(props) => {
                    if(agencyMatch){
                        return <Component {...props} />
                    } else {
                        return <Redirect to={{
                            pathname: '/404',
                            state: {from: props.location}
                        }}
                        />
                    }
                }}
            />
        )
    
}

const mapStateToProps = state => {
    return {
        agencyMatch: state.agencyMatchRed.bool,
        loadAgencies: state.loadAgenciesRed.bool
    }
}

export default connect(mapStateToProps)(AgencyRoute)