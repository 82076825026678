import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './i18n'
import { Provider } from 'react-redux'
import store from './redux/store'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@mui/material'
import theme from './styles/materialTheme'

ReactDOM.render(
  <Suspense fallback='Loading'>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <SnackbarProvider maxSnack={10}>
            <App />
          </SnackbarProvider>
        </Router>
      </ThemeProvider>
    </Provider>
  </Suspense>,
  document.getElementById('root')
)
serviceWorker.unregister()
