// Returns name from list by id, list must be array of objects
// objects must contain "id" and "name" properties
export const getName = (arr, id) => {
  const find = arr.find((obj) => obj.id === parseInt(id))
  if (id) {
    return find.name
  } else {
    return 'Not selectet'
  }
}

// Covert date to yyyy-mm-dd format
export const convertDate = (date) => {
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  let month = newDate.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  }
  let day = newDate.getDate()
  if (day < 10) {
    day = '0' + day
  }

  const dateString = `${year}-${month}-${day}`
  return dateString
}

// Add weeks to todays date
export const addWeeksToDate = (weeks) => {
  const date = new Date()
  date.setDate(date.getDate() + weeks * 7)
  return date
}

// Convert seconds to date yyyy-mm-dd format
export const secondsToDate = (sec) => {
  const myDate = new Date(sec * 1000)
  const newDate = new Date(myDate)
  const year = newDate.getFullYear()
  let month = newDate.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  }
  let day = newDate.getDate()
  if (day < 10) {
    day = '0' + day
  }

  const dateString = `${year}-${month}-${day}`
  return dateString
}

// Covert date to seconds
export const getSeconds = (date) => {
  return new Date(date).getTime() / 1000
}

// Convert date to date and time yyyy-mm-dd hh:mm:ss
export const getDayAndHours = (date) => {
  const newDate = new Date(date * 1000)
  const year = newDate.getFullYear()
  let month = newDate.getMonth() + 1

  if (month < 10) {
    month = '0' + month
  }
  let day = newDate.getDate()

  if (day < 10) {
    day = '0' + day
  }

  let hour = newDate.getHours()
  if (hour < 10) {
    hour = '0' + hour
  }

  let min = newDate.getMinutes()
  if (min < 10) {
    min = '0' + min
  }

  let sec = newDate.getSeconds()
  if (sec < 10) {
    sec = '0' + sec
  }

  return `${year}-${month}-${day} ${hour}:${min}:${sec}`
}

// Random number generator
export const randomNumber = (length, includeLetters, includeSymbols) => {
  let numberArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  const lettersArr = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]
  const symbolArr = ['!', '@', '#', '$', '%', '^', '&', '*']
  let result = ''

  if (includeLetters === true && includeSymbols === false) {
    numberArr = numberArr.concat(lettersArr)
  } else if (includeSymbols === false && includeSymbols === true) {
    numberArr = numberArr.concat(symbolArr)
  } else if (includeLetters === true && includeSymbols === true) {
    numberArr = numberArr.concat(lettersArr, symbolArr)
  }

  for (let i = 0; i < length; i++) {
    result += numberArr[Math.floor(Math.random() * numberArr.length)]
  }

  return result
}

// Make first letter of string to capitalize
export const capitalizeString = (string) => {
  if (string !== '') {
    const newString = string[0].toUpperCase() + string.slice(1)
    return newString
  } else {
    return ''
  }
}
