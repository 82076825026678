import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font
} from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { secondsToDate } from '../globalFunctions'
import RobotoNormal from '../media/fonts/Roboto-Regular.ttf'
import RobotoBold from '../media/fonts/Roboto-Bold.ttf'
import { systemColors } from '../globalVariables'
import logo from '../media/logo/seanor_logo_335x82.png'
import userPlaceholder from '../media/pdf/user-placeholder.png'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoBold,
      fontWeight: 'bold'
    },
    {
      src: RobotoNormal
    }
  ]
})

// Colors
const darkBlue = '#215384'
const lightBlue = '#43688e'
const orange = '#e03b22'
const lightGrey = '#737372'

const styles = StyleSheet.create({
  userCv: {
    fontFamily: 'Roboto',
    marginTop: 25,
    fontSize: 10
  },
  userCvMainInfo: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: '0 25px'
  },
  basicInfoName: {
    fontWeight: 'bold',
    marginBottom: 10,
    color: darkBlue
  },
  userCvMainInfoElem: {
    margin: 10
  },
  profilePhoto: {
    width: 150
  },
  basicInfoData: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 5,
    fontSize: 10
  },
  title: {
    color: lightBlue,
    fontWeight: 'bold'
  },
  headLine: {
    flex: 1,
    fontSize: 13,
    color: orange,
    marginTop: 30,
    marginBottom: 5,
    marginLeft: 25
  },
  headLineBorder: {
    width: '90%',
    borderBottom: `1 solid ${lightGrey}`,
    paddingBottom: 5
  },
  section: {
    flex: 0,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  sectionElem: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  salaryElem: {
    marginHorizontal: 10
  },
  documents: {
    marginLeft: 10,
    textAlign: 'left',
    marginVertical: 6,
    paddingBottom: 5,
    borderBottom: `1 thin ${lightGrey}`
  },
  experience: {
    fontSize: 10,
    marginVertical: 7,
    paddingBottom: 5,
    borderBottom: `1 thin ${lightGrey}`
  },
  comment: {
    marginHorizontal: 25
  },
  firstLetter: {
    textTransform: 'capitalize'
  },
  formHeadline: {
    textTransform: 'uppercase',
    fontSize: 14,
    marginLeft: 25,
    color: systemColors.darkBlue,
    fontWeight: 'bold'
  },
  documentsHeader: {
    backgroundColor: systemColors.electric,
    color: '#ffffff',
    padding: '5 10'
  },
  documentData: {
    padding: '5 10'
  },
  experienceHeader: {
    color: '#ffffff'
  },
  contactHeader: {
    backgroundColor: systemColors.darkBlue,
    color: '#ffffff',
    padding: '5 10'
  },
  physicalHeader: {
    backgroundColor: systemColors.darkBlue,
    color: '#ffffff',
    padding: '5 10',
    width: 91
  },
  errorColor: {
    color: systemColors.errorColor
  },
  outputText: {
    flex: 1,
    flexDirection: 'column'
  },
  documentsSection: {
    padding: '5 0',
    marginLeft: 22,
    width: 551,
    backgroundColor: systemColors.inputColor,
    textAlign: 'center'
  },
  personalSkills: {
    margin: 25
  }
})

export const KeyCvPdf = ({ userData, profilePhotoUrl, companyLogoURL }) => {
  const { t } = useTranslation()

  return (
    <Document>
      <Page size='A4' wrap>
        <View style={styles.userCv}>
          {/* LOGO SECTION */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginBottom: 20
            }}
          >
            <Image
              style={{ maxWidth: 100, maxHeight: 100, marginRight: 25 }}
              src={companyLogoURL !== '' ? companyLogoURL : logo}
            />
          </View>

          {/* PERSONAL DATA */}
          <View style={styles.userCvMainInfo}>
            <View style={styles.userCvMainInfoElem}>
              <View style={[styles.basicInfoData]}>
                <Text style={styles.title}>{t('mainPosition')}: </Text>
                <Text style={styles.firstLetter}>
                  {userData.personalData.mainPosition !== '' &&
                    t(userData.personalData.mainPosition)}
                </Text>
              </View>
              <View style={[styles.basicInfoData]}>
                <Text style={styles.title}>{t('secondPosition')}: </Text>
                <Text style={styles.firstLetter}>
                  {userData.personalData.secondPosition !== '' &&
                    t(userData.personalData.secondPosition)}
                </Text>
              </View>
              <View style={[styles.basicInfoData]}>
                <Text style={styles.title}>{t('firstName')}: </Text>
                <Text style={styles.firstLetter}>
                  {userData.personalData.firstName}
                </Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('lastName')}: </Text>
                <Text style={styles.firstLetter}>
                  {userData.personalData.lastName}
                </Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('address')}: </Text>
                <Text style={styles.firstLetter}>
                  {userData.personalData.address}
                </Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('phoneNumber')}: </Text>
                <Text>{userData.personalData.mobilePhone}</Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('email')}: </Text>
                <Text>{userData.personalData.email}</Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('familyStatus')}: </Text>
                <Text>{t(userData.personalData.familyStatus)}</Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('healthStatus')}: </Text>
                <Text>{t(userData.personalData.healthStatus)}</Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('nationality')}: </Text>
                <Text style={styles.firstLetter}>
                  {t(userData.personalData.nationality)}
                </Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('dateOfBirth')}: </Text>
                <Text>{t(userData.personalData.dateOfBirth)}</Text>
              </View>
              <View style={styles.basicInfoData}>
                <Text style={styles.title}>{t('linkedInProfile')}: </Text>
                <Text>{t(userData.personalData.linkedInLink)}</Text>
              </View>
            </View>

            {/* Profile photo */}
            <View style={styles.userCvMainInfoElem}>
              {profilePhotoUrl !== '' && profilePhotoUrl !== null ? (
                <Image
                  style={styles.profilePhoto}
                  src={profilePhotoUrl}
                  alt='profile'
                />
              ) : (
                <Image
                  style={{ height: 150, width: 150 }}
                  src={userPlaceholder}
                  alt='profile'
                />
              )}
            </View>
          </View>

          {/* WORK SKILLS */}
          <View style={styles.headLine}>
            <Text>{t('workSkills')}</Text>
          </View>
          <View style={styles.section}>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 225
                }
              ]}
            >
              <Text>{t('workSkills')}</Text>
            </View>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 325
                }
              ]}
            >
              <Text>{t('type')}</Text>
            </View>
          </View>
          {userData.workDiscipline.map((el) => (
            <View
              key={el.id}
              style={[
                styles.section,
                {
                  borderBottom: `1 solid ${systemColors.lighGrey}`,
                  marginLeft: 25,
                  marginRight: 25
                }
              ]}
            >
              <View
                style={[
                  styles.documentData,
                  {
                    width: 225
                  }
                ]}
              >
                <Text>{t(el.discipline)}</Text>
              </View>
              <View
                style={[
                  styles.documentData,
                  {
                    width: 325
                  }
                ]}
              >
                <Text>{t(el.type)}</Text>
              </View>
            </View>
          ))}

          <View style={{ marginTop: 20, marginLeft: 25, marginRight: 25 }}>
            {/* Interested services */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.title}>
                {t('selectWhatOurSevicesYouAreInterested')}:{' '}
              </Text>
              <Text>
                {t(userData.workDisciplineOtherData.servicesInterestedIn)}
              </Text>
            </View>

            {/* Education */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.title}>{t('education')}: </Text>
              <Text>
                {t(
                  userData.workDisciplineOtherData.additionalRequirements
                    .education
                )}
              </Text>
            </View>

            {/* Speciality */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.title}>{t('speciality')}: </Text>
              <Text>
                {t(
                  userData.workDisciplineOtherData.additionalRequirements
                    .speciality
                )}
              </Text>
            </View>

            {/* Certificates */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.title}>{t('certificates')}: </Text>
              <Text>
                {userData.workDisciplineOtherData.additionalRequirements
                  .certificates !== undefined
                  ? userData.workDisciplineOtherData.additionalRequirements.certificates.map(
                      (certificate, index, array) => (
                        <Text key={index}>
                          {t(certificate)}
                          {array.length - 1 !== index && <Text>, </Text>}
                        </Text>
                      )
                    )
                  : null}
              </Text>
            </View>
          </View>

          {/* PERSONAL SKILLS */}

          {/* Knowledge of language */}
          <View style={styles.headLine}>
            <Text>{t('knowledgeOflanguage')}</Text>
          </View>
          <View style={styles.section}>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 225
                }
              ]}
            >
              <Text>{t('language')}</Text>
            </View>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 325
                }
              ]}
            >
              <Text>{t('level')}</Text>
            </View>
          </View>
          {userData.personalSkills.languageKnowlegeArr.map((el) => (
            <View
              key={el.id}
              style={[
                styles.section,
                {
                  borderBottom: `1 solid ${systemColors.lighGrey}`,
                  marginLeft: 25,
                  marginRight: 25
                }
              ]}
            >
              <View
                style={[
                  styles.documentData,
                  {
                    width: 225
                  }
                ]}
              >
                <Text>{t(el.language)}</Text>
              </View>
              <View
                style={[
                  styles.documentData,
                  {
                    width: 325
                  }
                ]}
              >
                <Text>{t(el.level)}</Text>
              </View>
            </View>
          ))}

          <View style={{ marginTop: 20, marginLeft: 25, marginRight: 25 }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.title}>{t('driverLicence')}: </Text>
              <Text>{t(userData.personalSkills.driverLicence)}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.title}>{t('canYouDriveToWork')}: </Text>
              <Text>{t(userData.personalSkills.canDrive)}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.title}>{t('planToCintinueToLearn')}: </Text>
              <Text>{t(userData.personalSkills.continueLearn)}</Text>
            </View>
          </View>

          {/* EXPERIENCE */}
          <View style={styles.headLine}>
            <Text>{t('experience')}</Text>
          </View>
          <View style={styles.section}>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 100
                }
              ]}
            >
              <Text>{t('workplaceNameCity')}</Text>
            </View>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 100
                }
              ]}
            >
              <Text>{t('country')}</Text>
            </View>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 100
                }
              ]}
            >
              <Text>{t('position')}</Text>
            </View>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 70
                }
              ]}
            >
              <Text>{t('from')}</Text>
            </View>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 70
                }
              ]}
            >
              <Text>{t('till')}</Text>
            </View>
            <View
              style={[
                styles.documentsHeader,
                {
                  width: 110
                }
              ]}
            >
              <Text>{t('mainResponsibilities')}</Text>
            </View>
          </View>
          {userData.experienceArr.map((el) => (
            <View
              key={el.id}
              style={[
                styles.section,
                {
                  borderBottom: `1 solid ${systemColors.lighGrey}`,
                  marginLeft: 25,
                  marginRight: 25
                }
              ]}
            >
              <View
                style={[
                  styles.documentData,
                  {
                    width: 100
                  }
                ]}
              >
                <Text>{el.workplace}</Text>
              </View>
              <View
                style={[
                  styles.documentData,
                  {
                    width: 100
                  }
                ]}
              >
                <Text>{el.country}</Text>
              </View>
              <View
                style={[
                  styles.documentData,
                  {
                    width: 100
                  }
                ]}
              >
                <Text>{t(el.position)}</Text>
              </View>
              <View
                style={[
                  styles.documentData,
                  {
                    width: 70
                  }
                ]}
              >
                <Text>{el.dateFrom}</Text>
              </View>
              <View
                style={[
                  styles.documentData,
                  {
                    width: 70
                  }
                ]}
              >
                <Text>{el.dateTill}</Text>
              </View>
              <View
                style={[
                  styles.documentData,
                  {
                    width: 110
                  }
                ]}
              >
                <Text>{el.workOccupation}</Text>
              </View>
            </View>
          ))}

          {/* REMARKS */}
          <View
            style={{
              marginTop: 20,
              marginLeft: 25,
              marginRight: 25
            }}
          >
            <View style={[styles.basicInfoData]}>
              <Text style={styles.title}>{t('comment')}: </Text>
            </View>
            <View style={{ marginBottom: 5, marginTop: 5 }}>
              <Text>{t(userData.remarks.comment)}</Text>
            </View>
          </View>
          <View
            style={{
              marginTop: 10,
              marginLeft: 25,
              marginRight: 25
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.title}>{t('howFastYouCanStart')} </Text>
              <Text>{t(userData.remarks.whenCanStart)}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.title}>{t('howFindOutAboutUs')} </Text>
              <Text>{t(userData.remarks.howFindOut)}</Text>
            </View>
          </View>

          {/* LAST UPDATE */}
          <View
            style={{
              textAlign: 'center',
              width: '100%',
              color: systemColors.lightBlue,
              marginTop: 20
            }}
          >
            <Text>
              {t('updated')}: {secondsToDate(userData.timeStamp.seconds)}
            </Text>
          </View>

          {/* USER ID */}
          <View
            style={{
              textAlign: 'center',
              width: '100%',
              color: systemColors.lightBlue,
              marginTop: 5
            }}
          >
            <Text>
              {t('userId')}: {userData.userId}
            </Text>
          </View>

          {/* Seanor logo */}
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 20
            }}
          >
            <Image style={{ width: 50 }} src={logo} />
          </View>
        </View>
      </Page>
    </Document>
  )
}
