// Structure data which will be send to database
export default class crewEmployeeData {
  constructor (
    formType,
    docId,
    userId,
    positionId,
    positionTwoId,
    firstName,
    lastName,
    middleName,
    birthDate,
    homeAddress,
    phoneMobile,
    nextToKinPhone,
    email,
    maritalStatus,
    children,
    nationality,
    birthPlace,
    nearestAirport,
    englishLevel,
    nextToKin,
    nextToKinAddress,
    nextToKinEmail,
    minSalary,
    expectedSalary,
    lastSalary,
    educationLicence,
    educationNumber,
    educationIssued,
    educationExpires,
    educationCountry,
    documentsArray,
    experienceArray,
    crewAgenciesArray,
    height,
    weight,
    shoes,
    clothes,
    hairColor,
    eyeColor,
    profilePhoto,
    contractDuration,
    comment,
    date,
    notes,
    availableFrom,
    profileStatus,
    rotationLog,
    cityCountry,
    whatsappViber,
    createdAt,
    desiredVesselType,
    bankData,
    rotationLogStatus,
    plannedOnProjects,
    addType,
    log
  ) {
    this.formType = formType
    this.docId = docId
    this.userId = userId
    this.positionId = positionId
    this.positionTwoId = positionTwoId
    this.firstName = firstName
    this.lastName = lastName
    this.middleName = middleName
    this.birthDate = birthDate
    this.homeAddress = homeAddress
    this.phoneMobile = phoneMobile
    this.nextToKinPhone = nextToKinPhone
    this.email = email
    this.maritalStatus = maritalStatus
    this.children = children
    this.nationality = nationality
    this.birthPlace = birthPlace
    this.nearestAirport = nearestAirport
    this.englishLevel = englishLevel
    this.nextToKin = nextToKin
    this.nextToKinAddress = nextToKinAddress
    this.nextToKinEmail = nextToKinEmail
    this.minSalary = minSalary
    this.expectedSalary = expectedSalary
    this.lastSalary = lastSalary
    this.educationLicence = educationLicence
    this.educationNumber = educationNumber
    this.educationIssued = educationIssued
    this.educationExpires = educationExpires
    this.educationCountry = educationCountry
    this.documentsArray = documentsArray
    this.experienceArray = experienceArray
    this.crewAgenciesArray = crewAgenciesArray
    this.height = height
    this.weight = weight
    this.shoes = shoes
    this.clothes = clothes
    this.hairColor = hairColor
    this.eyeColor = eyeColor
    this.profilePhoto = profilePhoto
    this.contractDuration = contractDuration
    this.comment = comment
    this.date = date
    this.notes = notes
    this.availableFrom = availableFrom
    this.profileStatus = profileStatus
    this.rotationLog = rotationLog
    this.cityCountry = cityCountry
    this.whatsappViber = whatsappViber
    this.createdAt = createdAt
    this.desiredVesselType = desiredVesselType
    this.bankData = bankData
    this.rotationLogStatus = rotationLogStatus
    this.plannedOnProjects = plannedOnProjects
    this.addType = addType
    this.log = log
  }
}
