import React from 'react'
import './openMenu.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const OpenMenu = ({ click }) => {
  const { t } = useTranslation()

  const handleClick = () => {
    click()
  }

  return (
    <div
      className='openMenu'
      onClick={() => handleClick()}
    >
      <div>
        <FontAwesomeIcon className='openMenu__icon' icon={faAngleDoubleRight} />
        <span>{t('menu')}</span>
        <FontAwesomeIcon className='openMenu__icon' icon={faAngleDoubleRight} />
      </div>
    </div>
  )
}

export default OpenMenu
