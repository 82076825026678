import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next/'
import AgencyMenu from '../../Components/AgencyMenu'
import Headline from '../../Components/Headline'
import Loader from '../../Components/Loader'
import firebase from 'firebase/app'
import { useHistory, useParams } from 'react-router'
import { connect } from 'react-redux'
import {
  Chip,
  Alert,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ButtonGroup,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Tooltip,
  IconButton,
  Grid,
  MenuItem,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Divider,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStickyNote,
  faTrashAlt,
  faEdit,
  faPlus,
  faTasks,
  faChevronDown,
  faTrash,
  faBrain,
  faCoins,
  faMinus,
  faFolder,
  faFolderOpen
} from '@fortawesome/free-solid-svg-icons'
import {
  systemColors,
  systemStylingSettings,
  acceptedFileSize,
  companyModes,
  sourceOptions
} from '../../globalVariables'
import {
  getDayAndHours,
  randomNumber,
  capitalizeString
} from '../../globalFunctions'
import { countries } from '../../countries'
import { v4 as uuid } from 'uuid'
import GetProjectEmployeesList from './Modules/GetProjectEmployeesList'
import { refreshData } from '../../redux/actions/refreshData'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import SmartPlanning from './Modules/SmartPlanning'
import ExpenseTracking from './Modules/ExpenseTracking'
import moment from 'moment'
import './companyPage.scss'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

const useStyles = makeStyles({
  chipsDisplay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: 5
    },
    marginTop: 20
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonText: {
    color: '#ffffff'
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  noteRoot: {
    backgroundColor: systemColors.inputColor
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  buttonRoot: {
    color: '#ffffff'
  },
  cardRoot: {
    marginBottom: 10
  },
  cardActionRoot: {
    float: 'right'
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  accordionSummaryRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff',
    borderRadius: systemStylingSettings.borderRadius
  },
  expandIcon: {
    fontSize: 15,
    color: '#ffffff'
  },
  tableHeader: {
    fontWeight: 'bold'
  },
  projectTableHeader: {
    fontWeight: 'bold',
    fontSize: 12
  },
  projectTableCell: {
    fontSize: 12
  },
  checkbox: {
    color: systemColors.electric
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: systemColors.inputColor,
      borderRadius: systemStylingSettings.borderRadius,
      '&.Mui-focused fieldset': {
        color: systemColors.darkBlue,
        borderColor: systemColors.darkBlue,
        borderWidth: systemStylingSettings.borderWidth
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 8
      }
  }
})

const CompanyPage = ({
  agencyData,
  userUID,
  agencyUserData,
  refreshDataRedux,
  setRefreshDataRedux,
  userData
}) => {
  const { t } = useTranslation()
  const { companyId } = useParams()
  const classes = useStyles()
  const history = useHistory()
  const { location } = useHistory()
  const lastDayOfTheYear = moment().endOf('year').format('YYYY-MM-DD')

  const [refreshData, setRefreshData] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [loading, setLoading] = useState(false)

  const [companyDetails, setCompanyDetails] = useState({})
  const [notesList, setNotesList] = useState([])
  const [note, setNote] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [companyCountry, setCompanyCountry] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [documentsArr, setDocumentsArr] = useState([
    {
      id: randomNumber(10, true, false),
      documentName: '',
      issued: '',
      expires: '',
      fileName: null
    }
  ])
  const [documentsLinks, setDocumentsLinks] = useState([])
  const [newDocumentData, setNewDocumentData] = useState({})
  const [deleteDocumentIndex, setDeleteDocumentIndex] = useState(null)
  const [projectsList, setProjectsList] = useState([])
  const [projectId, setProjectId] = useState('')
  const [filterFirstName, setFilterFirstName] = useState('')
  const [filterLastName, setFilterLastName] = useState('')
  const [filterEmployeeResult, setfilterEmployeeResult] = useState([])
  const [addEmployeeData, setAddEmployeeData] = useState(null)
  const [employeeCounter, setEmployeeCounter] = useState([])
  const [filterPosition, setFilterPosition] = useState(null)
  const [selectedCompanyMode, setSelectedCompanyMode] = useState('')
  const [source, setSource] = useState('')
  const [internetSite, setInternetSite] = useState('')
  const [contactPosition, setContactPosition] = useState('')

  /**
   * Add employee to project states
   */
  const [startOnContract, setStartOnContract] = useState('')
  const [startOnContractTime, setstartOnContractTime] = useState('')
  const [embarkation, setEmbarkation] = useState('')
  const [embarkationTime, setEmbarkationTime] = useState('')
  const [embarkationCity, setEmbarkationCity] = useState('')
  const [disembarkation, setDisembarkation] = useState('')
  const [endOfContract, setEndOfContract] = useState(lastDayOfTheYear)
  const [endOfContractTime, setEndOfContractTime] = useState('')
  const [disembarkationCity, setDisembarkationCity] = useState('')
  const [signOffReason, setSignOffReason] = useState('')
  const [saveTemplate, setSaveTemplate] = useState(false)
  const [selectedProjectName, setSelectedProjectName] = useState('')

  const [fileSizeErr, setFileSizeErr] = useState(false)
  const [companyNameErr, setCompanyNameErr] = useState(false)

  const [showNotes, setShowNotes] = useState(false)
  const [showEditDetails, setshowEditDetails] = useState(false)
  const [showNewDocumentMsg, setShowNewDocumentMsg] = useState(false)
  const [showDeleteDocumentMsg, setShowDeleteDocumentMsg] = useState(false)
  const [showDeleteCompanyMsg, setShowDeleteCompanyMsg] = useState(false)
  const [showNewProjectMsg, setshowNewProjectMsg] = useState(false)
  const [showAddEmployeeMsg, setShowAddEmployeeMsg] = useState(false)
  const [message, setMessage] = useState({
    status: false,
    duration: 1500,
    severity: 'success',
    message: ''
  })
  const [deleteProjectId, setDeleteProjectId] = useState('')
  const [showAddEmployeeToProjectMsg, setShowAddEmployeeToProjectMsg] =
    useState(false)
  const [showDeleteProjectMsg, setShowDeleteProjectMsg] = useState(false)
  const [orderProjects, setOrderProjects] = useState(false)

  const [showSmartPlanning, setShowSmartPlanning] = useState(false)
  const [projectNameForSmartPlanning, setProjectNameForSmartPlanning] =
    useState('')

  const [showExpenseTracking, setShowExpenseTracking] = useState(false)

  const [mergetPositionsList, setMergetPositionsList] = useState([])

  const [newProjectsList, setNewProjectsList] = useState([{ id: 1, title: '' }])

  const [showArchivedProjects, setShowArchivedProjects] = useState(false)

  useEffect(() => {
    let shouldUpdate = true

    if (shouldUpdate) {
      setLoadingData(false)
    }

    let defaultPositions = []
    let agencyPositions = []

    const getCompanyData = async () => {
      const getData = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .get()
          .then((res) => {
            let data = JSON.stringify(res.data())
            data = JSON.parse(data)
            // Change document title
            document.title = `${data.companyName} - Seanor`
            if (shouldUpdate) {
              setCompanyDetails(data)
              setNotesList(data.notes)

              setCompanyName(data.companyName)
              setCompanyAddress(data.companyAddress)
              setCompanyCountry(data.companyCountry)
              setFirstName(data.contactPerson.firstName)
              setLastName(data.contactPerson.lastName)
              setCompanyEmail(data.contactPerson.companyEmail)
              setCompanyPhone(data.contactPerson.companyPhone)
              setDocumentsArr(data.documents)
              setSelectedCompanyMode(data.companyMode)
              setSource(data.source)
              setInternetSite(data.internetSite)
              setContactPosition(data.contactPerson.contactPosition)
            }

            const getLinks = data.documents.map((obj) => {
              if (obj.fileName !== null && obj.fileName !== '') {
                return new Promise((resolve) => {
                  storageRef
                    .child(
                      `agencies/${agencyData.id}/companies/${companyId}/documents/${obj.fileName}`
                    )
                    .getDownloadURL()
                    .then((url) => {
                      resolve({
                        id: obj.id,
                        url
                      })
                    })
                    .catch((error) => {
                      console.log(error)
                      resolve({
                        id: obj.id,
                        url: ''
                      })
                    })
                })
              } else {
                return resolve({
                  id: obj.id,
                  url: ''
                })
              }
            })

            Promise.all(getLinks).then((res) => {
              if (shouldUpdate) {
                setDocumentsLinks(res)
              }
              resolve()
            })
          })
          .catch((error) => {
            console.log({
              message: "Can't get data",
              error
            })
            resolve()
          })
      })

      const getProjectsList = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .collection('projects')
          .get()
          .then((res) => {
            const projectsArr = []
            res.forEach((el) => {
              let data = JSON.stringify(el.data())
              data = JSON.parse(data)
              projectsArr.push(data)

              /**
               * Get employees numbers for founters
               * */
              db.collection('agencies')
                .doc(agencyData.id)
                .collection('companies')
                .doc(companyId)
                .collection('projects')
                .doc(data.projectId)
                .collection('employees')
                // .where('status', '!=', 'archive')
                .get()
                .then((response) => {
                  let employedNumber = 0
                  let plannedNumber = 0

                  response.forEach((doc) => {
                    const data = JSON.parse(JSON.stringify(doc.data()))

                    if (data.status === 'active') {
                      employedNumber = employedNumber + 1
                    }

                    if (data.status === 'draft') {
                      plannedNumber = plannedNumber + 1
                    }
                  })

                  const value = {
                    id: data.projectId,
                    employedNumber,
                    plannedNumber
                  }

                  if (shouldUpdate) {
                    setEmployeeCounter((employeeCounter) => [
                      ...employeeCounter,
                      value
                    ])
                  }
                })
                .catch((error) => console.error(error))
            })

            // Sort by saved order in local storage
            let sortedList = window.localStorage.getItem(
              'allCompaniesProjectsSortings'
            )

            if (sortedList !== null) {
              sortedList = JSON.parse(sortedList)

              if (sortedList[companyId] !== undefined) {
                const sortedListArray = JSON.parse(sortedList[companyId])

                let sortedProjectsArray = []
                sortedListArray.map((el) => {
                  const item = projectsArr.find((elem) => elem.projectId === el)
                  return (sortedProjectsArray = [...sortedProjectsArray, item])
                })
                if (shouldUpdate) {
                  setProjectsList(sortedProjectsArray)
                }
              } else {
                if (shouldUpdate) {
                  setProjectsList(projectsArr)
                }
              }
            } else {
              if (shouldUpdate) {
                setProjectsList(projectsArr)
              }
            }
            resolve()
          })
          .catch((error) => {
            console.error(error)
            resolve()
          })
      })

      const getDefaultPositionsList = new Promise((resolve) => {
        db.collection('lists')
          .doc('key_positions')
          .get()
          .then((positions) => {
            let data = JSON.stringify(positions.data())
            data = JSON.parse(data)

            if (shouldUpdate) {
              defaultPositions = data.list
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      const getAgencyPositionsList = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('lists')
          .doc('positions')
          .get()
          .then((positions) => {
            if (positions.exists) {
              let data = JSON.stringify(positions.data())
              data = JSON.parse(data)

              if (shouldUpdate) {
                agencyPositions = data.list
              }
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      // Add company to users last viewed companies list
      const updateUserData = new Promise((resolve) => {
        let lastViewedCompaniesNewArr = []

        if (userData.lastViewedCompanies !== undefined) {
          lastViewedCompaniesNewArr = userData.lastViewedCompanies

          const visitedCompanyDataObject = {
            companyId,
            companyName: location.state.data,
            timestamp: new Date()
          }
          // Check is company alredy llisted in array
          const index = lastViewedCompaniesNewArr.findIndex(
            (el) => el.companyId === companyId
          )

          // Add company to list
          if (index < 0) {
            lastViewedCompaniesNewArr.unshift(visitedCompanyDataObject)
          } else if (index >= 0) {
            lastViewedCompaniesNewArr.splice(index, 1)
            lastViewedCompaniesNewArr.unshift(visitedCompanyDataObject)
          }

          // Remove last list item if list is longer then 10
          if (lastViewedCompaniesNewArr.length > 10) {
            lastViewedCompaniesNewArr.splice(10, 1)
          }
        }

        db.collection('users')
          .doc(userData.uid)
          .update({
            'data.lastViewedCompanies': lastViewedCompaniesNewArr
          })
          .then(() => resolve())
          .catch((error) => {
            console.log(
              "Can't add company to 'Last viewed companies' list",
              error
            )
            resolve()
          })
      })

      await Promise.all([
        getData,
        getProjectsList,
        getDefaultPositionsList,
        getAgencyPositionsList,
        updateUserData
      ])
        .then(() => {
          if (shouldUpdate) {
            setMergetPositionsList([...defaultPositions, ...agencyPositions])
            setLoadingData(false)
          }
        })
        .catch((err) => {
          if (shouldUpdate) {
            setLoadingData(false)
          }
          console.log(err.message, err.error)
        })
    }

    getCompanyData()

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, companyId, refreshData, userData, location])

  const addNote = () => {
    if (note !== '') {
      setLoading(true)

      // Update database field with new data
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('companies')
        .doc(companyId)
        .update({
          notes: [
            ...notesList,
            {
              note,
              timeStamp: new Date(),
              userName: agencyUserData.name,
              userLastName: agencyUserData.lastName
            }
          ]
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
        })
        .catch((err) => {
          console.log('There is problem to put notes to database', err)
          setLoading(false)
        })

      setNote('')
    }
  }

  const deleteNote = (index) => {
    if (notesList.length > 0) {
      setLoading(true)
      const values = [...notesList]
      values.splice(index, 1)

      // Update database field with new data
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('companies')
        .doc(companyId)
        .update({
          notes: values
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
        })
        .catch((err) => {
          console.log('There is problem to put notes to database', err)
          setLoading('false')
        })
    }
  }

  const addDocument = (id, val, name) => {
    const newInput = documentsArr.map((el) => {
      if (id === el.id) {
        el[name] = val
      }
      return el
    })
    setDocumentsArr(newInput)
  }

  const addDocumentInput = () => {
    const blockId = randomNumber(10, true, false)
    setDocumentsArr([
      ...documentsArr,
      {
        id: blockId,
        documentName: '',
        issued: '',
        expires: '',
        fileName: ''
      }
    ])
  }

  const addDocumentLink = (id) => {
    let link = ''
    documentsLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  const uploadNewDocument = () => {
    setLoading(true)
    const document = documentsArr.find((el) => el.id === newDocumentData.id)

    const addDocument = () => {
      const updateDatabase = new Promise((resolve) => {
        const newUpdatedCertificatesList = documentsArr.map((el) => {
          if (el.id === newDocumentData.id) {
            el.fileName = newDocumentData.file[0].name
          }

          return el
        })

        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .update({
            documents: newUpdatedCertificatesList,
            timeStamp: new Date(),
            editCompanyUser: userUID
          })
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't update document", error: err })
            resolve()
          })
      })

      const uploadFile = new Promise((resolve) => {
        storage
          .ref(
            `/agencies/${agencyData.id}/companies/${companyId}/documents/${newDocumentData.file[0].name}`
          )
          .put(newDocumentData.file[0])
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't upload file", error: err })
            resolve()
          })
      })

      Promise.all([updateDatabase, uploadFile])
        .then(() => {
          setShowNewDocumentMsg(false)
          setNewDocumentData({})
          setLoading(false)
          setRefreshData(!refreshData)
          setMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('documentUploadSuccess')
          })
        })
        .catch((err) => {
          setShowNewDocumentMsg(false)
          console.log(err.message, err.err)
          setNewDocumentData({})
          setLoading(false)
          setMessage({
            status: true,
            duration: 15000,
            severity: 'warning',
            message: t('cantUploadDocument')
          })
        })
    }

    if (
      document &&
      document.fileName !== '' &&
      document.fileName !== null &&
      document.fileName !== undefined
    ) {
      storageRef
        .child(
          `/agencies/${agencyData.id}/companies/${companyId}/documents/${document.fileName}`
        )
        .delete()
        .then(() => addDocument())
        .catch((err) => {
          console.log("Can't delete old document file", err)
          setNewDocumentData({})
          setShowNewDocumentMsg(false)
          setLoading(false)
        })
    } else {
      addDocument()
    }
  }

  const deleteDocument = () => {
    setLoading(true)
    setShowDeleteDocumentMsg(false)
    const document = documentsArr[deleteDocumentIndex]

    const updateDatabase = () => {
      if (documentsArr.length > 1) {
        const values = [...documentsArr]
        values.splice(deleteDocumentIndex, 1)

        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .update({
            documents: values,
            timeStamp: new Date(),
            editCompanyUser: userUID
          })
          .then(() => {
            setRefreshData(!refreshData)
            setLoading(false)
          })
          .catch((err) => {
            console.log("Can't update document", err)
          })
      } else if (documentsArr.length === 1) {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .update({
            documents: [
              {
                id: randomNumber(10, true, false),
                documentName: '',
                issued: '',
                expires: '',
                fileName: null
              }
            ],
            timeStamp: new Date(),
            editCompanyUser: userUID
          })
          .then(() => {
            setRefreshData(!refreshData)
            setLoading(false)
            setMessage({
              status: true,
              duration: 5000,
              severity: 'success',
              message: t('documentDeleted')
            })
          })
          .catch((err) => {
            console.log("Can't update document", err)
          })
      }
    }

    if (document.fileName !== '' && document.fileName !== null) {
      storageRef
        .child(
          `/agencies/${agencyData.id}/companies/${companyId}/documents/${document.fileName}`
        )
        .delete()
        .then(() => updateDatabase())
        .catch((error) => {
          if (error.code === 'storage/object-not-found') {
            updateDatabase()
          }
          console.log("Can't delete old document file", error)
          setNewDocumentData({})
          setLoading(false)
        })
    } else {
      updateDatabase()
    }
  }

  const updateCompanyDetails = () => {
    if (companyName !== '') {
      setLoading(true)

      // Create search terms array
      const array = companyName.split(' ')
      const companyNameArray = []
      array.forEach((el) => {
        companyNameArray.push(el.toLocaleLowerCase())
      })

      // Create new documents array with file name
      const newDocumentsArr = []

      documentsArr.map((el) => {
        return newDocumentsArr.push({
          id: el.id,
          documentName: el.documentName,
          issued: el.issued,
          expires: el.expires,
          fileName:
            el.fileName !== null &&
            el.fileName !== '' &&
            el.fileName !== undefined
              ? el.fileName
              : null
        })
      })

      const addDataToDatabase = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .update({
            companyName: companyName,
            companyAddress: companyAddress,
            companyCountry: companyCountry,
            contactPerson: {
              firstName,
              lastName,
              companyEmail,
              companyPhone,
              contactPosition
            },
            documents: newDocumentsArr,
            timeStamp: new Date(),
            editCompanyUser: userUID,
            companyMode: selectedCompanyMode,
            searchTerms: companyNameArray,
            source,
            internetSite
          })
          .then(() => {
            resolve()
          })
          .catch((err) => {
            console.log({
              message: "Can't to add data to database",
              error: err
            })
            resolve()
          })
      })

      Promise.all([addDataToDatabase])
        .then(() => {
          setLoading(false)
          setMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('companyUpdated')
          })
          setRefreshData(!refreshData)
          setFileSizeErr(false)
          setCompanyNameErr(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err.message, err.error)
        })
    } else {
      if (companyName === '') {
        setCompanyNameErr(true)
        setRefreshData(!refreshData)
        setMessage({
          status: true,
          duration: 5000,
          severity: 'warning',
          message: t('mustFillCompanyName')
        })
      } else {
        setCompanyNameErr(false)
      }
    }
  }

  const deleteCompany = () => {
    setLoading(true)

    const promise = documentsArr.forEach((el) => {
      if (el.fileName !== null && el.fileName !== '') {
        storage
          .ref(
            `/agencies/${agencyData.id}/companies/${companyId}/documents/${el.fileName}`
          )
          .delete()
      }
    })

    Promise.all([promise])
      .then(() => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .delete()
          .then(() => history.push(`/${agencyData.id}/companies/all-companies`))
          .catch((err) =>
            console.log("Can't delete company from database", err)
          )
      })
      .catch((err) => {
        console.log("Can't add file to storage", err)
        setMessage({
          status: true,
          duration: 15000,
          severity: 'warning',
          message: t('cantDeleteCompany')
        })
      })
  }

  const createNewProject = async () => {
    setLoading(true)

    await newProjectsList.forEach(async (project) => {
      const projectId = randomNumber(10, true, false)

      if (project.title !== '') {
        await db
          .collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .collection('projects')
          .doc(projectId)
          .set({
            projectId,
            projectName: project.title,
            timeStamp: new Date(),
            createProject: userUID,
            lastEditProject: '',
            status: 'active',
            expenseTracking: {
              totalBudget: 0,
              totalExpenses: 0,
              expensesTypes: [],
              expenses: []
            }
          })
          .then(() => {
            /**
             * Updating current sorting parameters from local storage
             */
            let allSortings = window.localStorage.getItem(
              'allCompaniesProjectsSortings'
            )

            if (allSortings !== null) {
              allSortings = JSON.parse(allSortings)

              if (allSortings[companyId] !== undefined) {
                const currentProjectsSorting = JSON.parse(
                  allSortings[companyId]
                )
                let newSortingWIthNewData = [
                  ...currentProjectsSorting,
                  projectId
                ]
                newSortingWIthNewData = JSON.stringify(newSortingWIthNewData)
                allSortings = {
                  ...allSortings,
                  [companyId]: newSortingWIthNewData
                }
                allSortings = JSON.stringify(allSortings)

                window.localStorage.setItem(
                  'allCompaniesProjectsSortings',
                  allSortings
                )
              }
            }

            setLoading(false)
            setshowNewProjectMsg(false)
            setRefreshData(!refreshData)
          })
          .catch((error) => {
            console.log("Can't add project to database", error)
            setLoading(false)
          })
      } else {
        return
      }
    })

    setRefreshData(!refreshData)
    setLoading(false)
    closeNewProjectDialog()
  }

  const searchEmployee = async () => {
    setLoading(true)

    const query = db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('employees')

    let filterQuery

    if (filterFirstName !== '' && filterLastName === '') {
      filterQuery = query
        .where(
          'personalData.firstName',
          '>=',
          filterFirstName.toLocaleLowerCase()
        )
        .where(
          'personalData.firstName',
          '<=',
          filterFirstName.toLocaleLowerCase() + '~'
        )
    } else if (filterFirstName !== '' && filterLastName !== '') {
      filterQuery = query
        .where(
          'personalData.firstName',
          '==',
          filterFirstName.toLocaleLowerCase()
        )
        .where(
          'personalData.lastName',
          '==',
          filterLastName.toLocaleLowerCase()
        )
    } else if (filterFirstName === '' && filterLastName !== '') {
      filterQuery = query
        .where(
          'personalData.lastName',
          '>=',
          filterLastName.toLocaleLowerCase()
        )
        .where(
          'personalData.lastName',
          '<=',
          filterLastName.toLocaleLowerCase() + '~'
        )
    } else if (filterFirstName === '' && filterLastName === '') {
      filterQuery = query
    }

    await filterQuery
      .get()
      .then((res) => {
        const arr = []
        res.forEach((el) => {
          let data = JSON.stringify(el.data())
          data = JSON.parse(data)
          arr.push(data)
        })
        setfilterEmployeeResult(arr)
        setFilterPosition(null)
        setLoading(false)
      })
      .catch((err) => {
        console.log("Can't filter results", err)
        setLoading(false)
      })
  }

  const searchEmployeeByPosition = async () => {
    setLoading(true)

    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .where('personalData.mainPosition', '==', filterPosition)
      .get()
      .then((res) => {
        const arr = []
        res.forEach((el) => {
          let data = JSON.stringify(el.data())
          data = JSON.parse(data)
          arr.push(data)
        })
        setfilterEmployeeResult(arr)
        setFilterFirstName('')
        setFilterLastName('')
        setLoading(false)
      })
      .catch((err) => {
        console.log("Can't filter results", err)
        setLoading(false)
      })
  }

  const sowAllEmployees = async () => {
    setLoading(true)

    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .get()
      .then((res) => {
        const arr = []
        res.forEach((el) => {
          let data = JSON.stringify(el.data())
          data = JSON.parse(data)
          arr.push(data)
        })
        setfilterEmployeeResult(arr)
        setFilterFirstName('')
        setFilterLastName('')
        setFilterPosition(null)
        setLoading(false)
      })
      .catch((err) => {
        console.log("Can't filter results", err)
        setLoading(false)
      })
  }

  // If employee added like template
  const addEmployeeToProject = () => {
    const id = uuid()

    const addEmployeeToProjectTable = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('companies')
        .doc(companyId)
        .collection('projects')
        .doc(projectId)
        .collection('employees')
        .doc(id)
        .set({
          id,
          documentId: addEmployeeData.docId,
          firstName: addEmployeeData.personalData.firstName,
          lastName: addEmployeeData.personalData.lastName,
          status: saveTemplate ? 'draft' : 'active',
          startOnContract,
          startOnContractTime,
          embarkation,
          embarkationTime,
          embarkationCity,
          disembarkation,
          disembarkationTime: '',
          endOfContract,
          endOfContractTime,
          disembarkationCity,
          signOffReason,
          timeStamp: new Date(),
          createdTimeStamp: new Date(),
          whoAdded: userUID,
          whoEdited: '',
          position: addEmployeeData.personalData.mainPosition,
          draft: saveTemplate,
          nationality: addEmployeeData.personalData.nationality
        })
        .then(() => {
          resolve()
        })
        .catch((err) =>
          console.log({
            message: "Can't add user to project",
            error: err
          })
        )
    })

    const updateEmployeeData = new Promise((resolve) => {
      // Add project id to plannedOnProjects array
      let newPlannedOnProjectArray = []
      if (addEmployeeData.plannedOnProjects !== undefined) {
        newPlannedOnProjectArray = [
          ...addEmployeeData.plannedOnProjects,
          projectId
        ]
      } else {
        newPlannedOnProjectArray = [projectId]
      }

      // Change rotationLogStatus if employee not have 'atWork' status
      const updateObject = {
        plannedOnProjects: newPlannedOnProjectArray
      }
      if (addEmployeeData.rotationLogStatus !== 'atWork') {
        updateObject.rotationLogStatus = 'planned'
      }

      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(addEmployeeData.docId)
        .update(updateObject)
        .then(() => resolve())
        .catch((error) => {
          console.log("Can't update rotation log status", error)
          resolve()
        })
    })

    Promise.all([addEmployeeToProjectTable, updateEmployeeData])
      .then(() => {
        setStartOnContract('')
        setstartOnContractTime('')
        setEmbarkation('')
        setEmbarkationTime('')
        setEmbarkationCity('')
        setDisembarkation('')
        setEndOfContract(lastDayOfTheYear)
        setEndOfContractTime('')
        setDisembarkationCity('')
        setSignOffReason('')
        setSaveTemplate(false)
        setRefreshData(!refreshData)
        setShowAddEmployeeToProjectMsg(false)
        setRefreshDataRedux(!refreshDataRedux)
      })
      .catch((err) => {
        console.log(err.message, err.error)
      })
  }

  const signOnEmployeeToProject = () => {
    const id = uuid()
    const rotationLogId = uuid()
    if (addEmployeeData.rotationLogStatus !== 'atWork') {
      const addEmployeeToProjectTable = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .collection('projects')
          .doc(projectId)
          .collection('employees')
          .doc(id)
          .set({
            id,
            documentId: addEmployeeData.docId,
            firstName: addEmployeeData.personalData.firstName,
            lastName: addEmployeeData.personalData.lastName,
            status: saveTemplate ? 'draft' : 'active',
            startOnContract,
            startOnContractTime,
            embarkation,
            embarkationTime,
            embarkationCity,
            disembarkation,
            disembarkationTime: '',
            endOfContract,
            endOfContractTime,
            disembarkationCity,
            signOffReason,
            timeStamp: new Date(),
            createdTimeStamp: new Date(),
            whoAdded: userUID,
            whoEdited: '',
            position: addEmployeeData.personalData.mainPosition,
            draft: saveTemplate,
            nationality: addEmployeeData.personalData.nationality,
            workStatus: 'atWork'
          })
          .then(() => {
            resolve()
          })
          .catch((err) =>
            console.log({
              message: "Can't add user to project",
              error: err
            })
          )
      })

      const updateRotationLog = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(addEmployeeData.docId)
          .update({
            rotationLogStatus: 'atWork',
            rotationLog: [
              ...addEmployeeData.rotationLog,
              {
                id: rotationLogId,
                timeStamp: new Date(),
                createdTimeStamp: new Date(),
                companyName,
                status: 'atWork',
                projectName: selectedProjectName,
                signOnDate: startOnContract,
                signOnTime: startOnContractTime,
                signOffDate: endOfContract,
                signOffTime: endOfContractTime,
                position: addEmployeeData.personalData.mainPosition,
                editedBy: `${userData.name} ${userData.lastName}`,
                companyId,
                projectId,
                recordIdInProject: id,
                embarkation,
                embarkationCity,
                embarkationTime,
                disembarkation,
                disembarkationTime: '',
                disembarkationCity,
                signOffReason
              }
            ]
          })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            console.log("Can't update employee rotationlog", error)
            resolve()
          })
      })

      Promise.all([addEmployeeToProjectTable, updateRotationLog])
        .then(() => {
          setStartOnContract('')
          setstartOnContractTime('')
          setEmbarkation('')
          setEmbarkationTime('')
          setEmbarkationCity('')
          setDisembarkation('')
          setEndOfContract(lastDayOfTheYear)
          setEndOfContractTime('')
          setDisembarkationCity('')
          setSignOffReason('')
          setSaveTemplate(false)
          setRefreshData(!refreshData)
          setShowAddEmployeeToProjectMsg(false)
          setRefreshDataRedux(!refreshDataRedux)
        })
        .catch((err) => {
          console.log(err.message, err.error)
        })
    } else {
      setMessage({
        status: true,
        duration: 3000,
        severity: 'warning',
        message: t('thisEmployeeAlreadyisEmployed')
      })
    }
  }

  const getEmployeeCount = (id) => {
    let number = 0
    if (employeeCounter.length > 0) {
      employeeCounter.forEach((el) => {
        if (id === el.id) {
          number = el.employedNumber
        }
      })
    }
    return number
  }

  const getPlannedEmployeeCount = (id) => {
    let number = 0
    if (employeeCounter.length > 0) {
      employeeCounter.forEach((el) => {
        if (id === el.id) {
          number = el.plannedNumber
        }
      })
    }
    return number
  }

  const deleteProject = async () => {
    const employedNumber = getEmployeeCount(deleteProjectId)
    const plannedNumber = getPlannedEmployeeCount(deleteProjectId)

    if (employedNumber > 0 || plannedNumber > 0) {
      setMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('project_still_contain_uyd583')
      })

      return
    }

    setLoading(true)
    setShowDeleteProjectMsg(false)
    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('companies')
      .doc(companyId)
      .collection('projects')
      .doc(deleteProjectId)
      .delete()
      .then(() => {
        removeProjectFromSortingList(deleteProjectId)

        setLoading(false)
        setRefreshData(!refreshData)
        setMessage({
          status: true,
          duration: 5000,
          severity: 'success',
          message: t('projectRemoved')
        })
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
      })
  }

  /**
   * Remove project from sort list in local storage
   */
  const removeProjectFromSortingList = (projectId) => {
    /**
     * Getting all companies sorting parameters from local storage
     */
    let allSortings = window.localStorage.getItem(
      'allCompaniesProjectsSortings'
    )
    allSortings = JSON.parse(allSortings)

    /**
     * Remove project id from company list
     */
    if (allSortings !== null && allSortings[companyId] !== undefined) {
      let newProjectsList = JSON.parse(allSortings[companyId]).filter(
        (el) => el !== projectId
      )
      newProjectsList = JSON.stringify(newProjectsList)

      /**
       * Update companies projects sorting list with new data
       */
      allSortings = { ...allSortings, [companyId]: newProjectsList }
      allSortings = JSON.stringify(allSortings)
      window.localStorage.setItem('allCompaniesProjectsSortings', allSortings)
    }
  }

  const changeProjectListOrder = (result) => {
    if (!result.destination) return

    // Change project list sorting
    const items = Array.from(projectsList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    // Getting current sorting parameters from local storage
    let allSortings = window.localStorage.getItem(
      'allCompaniesProjectsSortings'
    )
    allSortings = JSON.parse(allSortings)

    // Modify company's sorting parameters
    let currentCompanyArray = []
    items.map(
      (el) => (currentCompanyArray = [...currentCompanyArray, el.projectId])
    )
    currentCompanyArray = JSON.stringify(currentCompanyArray)

    // Set new sorting parameters to localStorage
    allSortings = { ...allSortings, [companyId]: currentCompanyArray }
    allSortings = JSON.stringify(allSortings)
    window.localStorage.setItem('allCompaniesProjectsSortings', allSortings)

    setProjectsList(items)
  }

  const handleNewProjectsNameChange = (value, id) => {
    const newInput = newProjectsList.map((project) => {
      if (id === project.id) {
        project.title = value
      }
      return project
    })

    setNewProjectsList(newInput)
  }

  const closeNewProjectDialog = () => {
    setshowNewProjectMsg(false)
    setNewProjectsList([{ id: 1, title: '' }])
  }

  const addNewprojectLine = () => {
    const newLine = {
      id: uuid(),
      title: ''
    }
    const newArray = [...newProjectsList, newLine]

    setNewProjectsList(newArray)
  }

  const removeProjectLine = (id) => {
    const newArray = newProjectsList.filter((el) => el.id !== id)

    setNewProjectsList(newArray)
  }

  const archiveProject = async (id, archive) => {
    let status = 'archive'
    if (!archive) {
      status = 'active'
    }

    const employedNumber = getEmployeeCount(id)
    const plannedNumber = getPlannedEmployeeCount(id)

    if ((employedNumber > 0 || plannedNumber > 0) && status !== 'active') {
      setMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('project_still_contain_uyd583')
      })

      return
    }

    setLoading(true)

    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('companies')
      .doc(companyId)
      .collection('projects')
      .doc(id)
      .update({ status })
      .then(() => {
        setLoading(false)
        setRefreshData(!refreshData)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  if (loadingData) {
    return <Loader />
  }

  return (
    <div>
      {loading && <Loader />}

      <Headline text={companyDetails.companyName} />

      <AgencyMenu type='key' />

      {/* Chips */}
      <div className={classes.chipsDisplay}>
        <Chip
          label={t('seeEditDetails')}
          clickable
          onClick={() => setshowEditDetails(true)}
          icon={<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faEdit} />}
        />

        {/* Stage */}
        <Chip
          label={t('stage')}
          clickable
          onClick={() => setShowNotes(true)}
          icon={
            <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faStickyNote} />
          }
        />

        {/* Expense tracking */}
        <Chip
          label={t('expenseTracking')}
          clickable
          onClick={() => setShowExpenseTracking(true)}
          icon={<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCoins} />}
        />

        {/* Add new project */}
        <Chip
          label={t('addNewProject')}
          clickable
          onClick={() => setshowNewProjectMsg(true)}
          icon={<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faTasks} />}
        />
      </div>

      {/* Expense tracking */}
      <Dialog
        open={showExpenseTracking}
        onClose={() => setShowExpenseTracking(false)}
        classes={{ paper: classes.dialogRoot }}
        maxWidth='md'
        fullScreen
        fullWidth
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('expenseTracking')}
        </DialogTitle>

        <DialogContent>
          <ExpenseTracking companyId={companyId} userUID={userUID} />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setShowExpenseTracking(false)}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notes pop up */}
      <Dialog
        open={showNotes}
        classes={{ paper: classes.dialogRoot }}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('notes')}
        </DialogTitle>

        <DialogContent>
          <div>
            {notesList.length > 0 ? (
              notesList.map((el, i) => {
                return (
                  <Card
                    key={i}
                    variant='outlined'
                    classes={{ root: classes.cardRoot }}
                  >
                    <CardHeader
                      subheader={`${t('author')}: ${el.userName} ${
                        el.userLastName
                      } (${t('created')}: ${getDayAndHours(
                        el.timeStamp.seconds
                      )})`}
                    />
                    <CardContent>{el.note}</CardContent>
                    <CardActions classes={{ root: classes.cardActionRoot }}>
                      <Tooltip title={`${t('deleteNote')}`}>
                        <IconButton size='small' onClick={() => deleteNote(i)}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </IconButton>
                      </Tooltip>
                    </CardActions>
                  </Card>
                )
              })
            ) : (
              <div className='global__emptyList'>{t('noNotes')}</div>
            )}
          </div>

          <TextField
            size='small'
            variant='outlined'
            value={note}
            type='text'
            label={t('addNewNote')}
            onChange={(e) => setNote(e.target.value)}
            fullWidth
            multiline
            rows={5}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant='contained'
            size='small'
            color='orange'
            onClick={() => addNote()}
          >
            {t('addNote')}
          </Button>
          <Button onClick={() => setShowNotes(false)}>{t('close')}</Button>
        </DialogActions>
      </Dialog>

      {/* See and edit company pop up */}
      <Dialog
        open={showEditDetails}
        classes={{ paper: classes.dialogRoot }}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('seeEditDetails')}
        </DialogTitle>

        <DialogContent>
          <div className='companyPage__deleteCompany'>
            <span onClick={() => setShowDeleteCompanyMsg(true)}>
              {t('deleteCompany')}
            </span>
          </div>

          <Grid container spacing={2} style={{ marginTop: 30 }}>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                required
                size='small'
                variant='outlined'
                type='text'
                label={t('companyName')}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                fullWidth
                error={companyNameErr}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                size='small'
                variant='outlined'
                type='text'
                label={t('address')}
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                select
                variant='outlined'
                fullWidth
                label={t('country')}
                size='small'
                value={companyCountry}
                onChange={(e) => setCompanyCountry(e.target.value)}
              >
                {countries.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                select
                required
                variant='outlined'
                fullWidth
                label={t('mode')}
                size='small'
                value={selectedCompanyMode}
                onChange={(e) => setSelectedCompanyMode(e.target.value)}
              >
                {companyModes.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {t(el)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                select
                variant='outlined'
                fullWidth
                label={t('source')}
                size='small'
                value={source}
                onChange={(e) => setSource(e.target.value)}
              >
                {sourceOptions.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {t(el)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                size='small'
                variant='outlined'
                type='url'
                label={t('internetSite')}
                value={internetSite}
                onChange={(e) => setInternetSite(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          <div className='addNewCompany__title'>{t('contactPerson')}</div>

          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                size='small'
                variant='outlined'
                type='text'
                label={t('firstName')}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                size='small'
                variant='outlined'
                type='text'
                label={t('lastName')}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                size='small'
                variant='outlined'
                type='email'
                label={t('email')}
                value={companyEmail}
                onChange={(e) => setCompanyEmail(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                size='small'
                variant='outlined'
                type='tel'
                label={t('phone')}
                value={companyPhone}
                onChange={(e) => setCompanyPhone(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                size='small'
                variant='outlined'
                type='text'
                label={t('position')}
                value={contactPosition}
                onChange={(e) => setContactPosition(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Documents section */}
          <div className='addNewCompany__title'>{t('documents')}</div>
          {documentsArr.map((el, i) => (
            <Grid
              container
              key={el.id}
              spacing={2}
              style={{
                marginTop: 10,
                marginBottom: 20
              }}
            >
              {/* Document name */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('documentName')}
                  value={el.documentName}
                  onChange={(e) =>
                    addDocument(el.id, e.target.value, 'documentName')
                  }
                  fullWidth
                />
              </Grid>

              {/* Issue date */}
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='date'
                  label={t('issueDate')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={el.issued}
                  onChange={(e) => addDocument(el.id, e.target.value, 'issued')}
                  fullWidth
                />
              </Grid>

              {/* Expire date */}
              <Grid item xs={12} sm={6} md={2}>
                {el.expires !== '' ? (
                  <TextField
                    size='small'
                    variant='outlined'
                    type='date'
                    label={t('expireDate')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={el.expires}
                    onChange={(e) =>
                      addDocument(el.id, e.target.value, 'expires')
                    }
                    fullWidth
                  />
                ) : !el.editExpireDate ? (
                  <div
                    style={{
                      marginTop: 8,
                      textAlign: 'center'
                    }}
                  >
                    {t('unlimited')}
                    <Tooltip
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('editExpireDate')}
                    >
                      <span
                        onClick={() =>
                          addDocument(
                            el.id,
                            !el.editExpireDate,
                            'editExpireDate'
                          )
                        }
                      >
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faEdit}
                        />
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  <TextField
                    size='small'
                    variant='outlined'
                    type='date'
                    label={t('expireDate')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={el.expires}
                    onChange={(e) =>
                      addDocument(el.id, e.target.value, 'expires')
                    }
                    fullWidth
                  />
                )}
              </Grid>

              {/* Upload field */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='file'
                  label={t('upload')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    const file = e.target.files
                    if (file.length > 0) {
                      if (file[0].size > acceptedFileSize) {
                        setFileSizeErr(true)
                      } else {
                        // addDocument(el.id, file, 'file')
                        setNewDocumentData({ id: el.id, file })
                        setShowNewDocumentMsg(true)
                        setFileSizeErr(false)
                      }
                    }
                  }}
                  fullWidth
                  inputProps={{
                    accept: '.png, .jpg, .jpeg, .pdf'
                  }}
                  helperText={fileSizeErr ? t('toBigFile') : t('acceptFormat2')}
                  error={fileSizeErr}
                />
              </Grid>

              {/* Download link */}
              <Grid item xs={12} sm={6} md={2} style={{ display: 'flex' }}>
                <div className='addNewEmployee__addRemove'>
                  {el.fileName !== null && el.fileName !== '' ? (
                    addDocumentLink(el.id) !== '' ? (
                      <a
                        href={addDocumentLink(el.id)}
                        target='_blank'
                        rel='noopener noreferrer'
                        download
                      >
                        {t('download')}
                      </a>
                    ) : (
                      t('noFileAttached')
                    )
                  ) : (
                    t('noFileAttached')
                  )}
                </div>

                {/* Remove document */}
                <Tooltip placement='right' title={t('removeDocument')}>
                  <div
                    style={{
                      width: 'fit-content',
                      marginTop: 8,
                      cursor: 'pointer',
                      marginLeft: 15
                    }}
                    className='global__onHover'
                    onClick={() => {
                      setDeleteDocumentIndex(i)
                      setShowDeleteDocumentMsg(true)
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: systemColors.lighGrey }}
                      icon={faTrash}
                    />
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          ))}

          <div
            className='addNewEmployee__addRemove'
            onClick={() => addDocumentInput()}
          >
            <FontAwesomeIcon className='icon' icon={faPlus} />
            <span>{t('addDocument')}</span>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            variant='contained'
            size='small'
            color='orange'
            onClick={() => updateCompanyDetails()}
          >
            {t('save')}
          </Button>
          <Button onClick={() => setshowEditDetails(false)}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* New document upload message */}
      <Dialog open={showNewDocumentMsg} classes={{ paper: classes.dialogRoot }}>
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>

        <DialogContent>{t('uploadNewDocumentPopupMessage')}</DialogContent>

        <DialogActions>
          <Button onClick={() => uploadNewDocument()}>{t('continue')}</Button>

          <Button
            onClick={() => {
              setShowNewDocumentMsg(false)
              setNewDocumentData({})
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete document message */}
      <Dialog
        open={showDeleteDocumentMsg}
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>{t('deleteDocuemtentFromDatabase')}</DialogContent>
        <DialogActions>
          <Button onClick={() => deleteDocument()}>{t('continue')}</Button>

          <Button
            onClick={() => {
              setShowDeleteDocumentMsg(false)
              setDeleteDocumentIndex(null)
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete company message */}
      <Dialog
        open={showDeleteCompanyMsg}
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>{t('deleteCompanyFromSystem')}</DialogContent>
        <DialogActions>
          <Button onClick={() => deleteCompany()}>{t('delete')}</Button>

          <Button
            onClick={() => {
              setShowDeleteCompanyMsg(false)
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* New project message */}
      <Dialog
        fullWidth
        maxWidth='sm'
        open={showNewProjectMsg}
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('addNewProject')}
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            style={{
              marginTop: 15
            }}
            spacing={2}
          >
            {newProjectsList.map((project, index) => (
              <Grid
                item
                md={12}
                key={project.id}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {/*
                 * Input
                 */}
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('projectName')}
                  value={project.title}
                  onChange={(event) =>
                    handleNewProjectsNameChange(event.target.value, project.id)
                  }
                  fullWidth
                />

                {/*
                 * Add new line button
                 */}
                {newProjectsList.length === index + 1 && (
                  <div
                    className='addProjects_icon'
                    onClick={() => addNewprojectLine()}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                )}

                {/*
                 * Remove line button
                 */}
                {newProjectsList.length !== index + 1 && (
                  <div
                    className='addProjects_icon'
                    onClick={() => removeProjectLine(project.id)}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
        </DialogContent>

        <DialogActions>
          {/*
           * Add projects button
           */}
          <Button onClick={() => createNewProject()}>{t('addProject')}</Button>

          {/*
           * Close dialog button
           */}
          <Button onClick={() => closeNewProjectDialog()}>{t('cancel')}</Button>
        </DialogActions>
      </Dialog>

      {/* Add Employee filtration pop up */}
      <Dialog open={showAddEmployeeMsg} classes={{ paper: classes.dialogRoot }}>
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('addEmployeeToList')}
        </DialogTitle>

        <DialogContent>
          {/*
           * Search by name
           */}
          <Grid container spacing={2}>
            {/*
             * First name
             */}
            <Grid item sm={12} md={4}>
              <TextField
                size='small'
                style={{
                  marginTop: 15
                }}
                variant='outlined'
                type='text'
                label={t('firstName')}
                value={filterFirstName}
                onChange={(e) => setFilterFirstName(e.target.value)}
                fullWidth
              />
            </Grid>

            {/*
             * Last name
             */}
            <Grid item sm={12} md={4}>
              <TextField
                size='small'
                style={{
                  marginTop: 15
                }}
                variant='outlined'
                type='text'
                label={t('lastName')}
                value={filterLastName}
                onChange={(e) => setFilterLastName(e.target.value)}
                fullWidth
              />
            </Grid>

            {/*
             * Search button
             */}
            <Grid item sm={12} md={4}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  marginTop: 8
                }}
              >
                <Button
                  variant='contained'
                  size='small'
                  color='orange'
                  onClick={() => searchEmployee()}
                >
                  {t('search')}
                </Button>
              </div>
            </Grid>
          </Grid>

          {/*
           * Search by position
           */}
          <Grid container spacing={2} style={{ marginTop: 5 }}>
            {/*
             * Position select
             */}
            <Grid item sm={12} md={6}>
              <Autocomplete
                style={{ padding: 0 }}
                options={mergetPositionsList}
                getOptionLabel={(option) => t(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size='small'
                    label={t('position')}
                    variant='outlined'
                  />
                )}
                value={filterPosition}
                onChange={(e, val) => {
                  val && setFilterPosition(val)
                }}
              />
            </Grid>

            {/*
             * Search button
             */}
            <Grid item sm={12} md={4}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  marginTop: 1
                }}
              >
                <Button
                  variant='contained'
                  size='small'
                  color='orange'
                  onClick={() => searchEmployeeByPosition()}
                >
                  {t('search')}
                </Button>
              </div>
            </Grid>
          </Grid>

          {/* Show all button */}
          <Grid container spacing={2}>
            <Grid item sm={12} md={4}>
              <Button onClick={() => sowAllEmployees()}>{t('showAll')}</Button>
            </Grid>
          </Grid>

          {/* Filter result */}
          {filterEmployeeResult.length > 0 && (
            <Table style={{ marginTop: 20 }}>
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableHeader }}>
                    {t('firstName')}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeader }}>
                    {t('lastName')}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeader }}>
                    {t('position')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterEmployeeResult.map((el, i) => (
                  <TableRow
                    key={el.docId}
                    style={{ cursor: 'pointer' }}
                    hover
                    onClick={() => {
                      setAddEmployeeData(el)
                      setShowAddEmployeeToProjectMsg(true)
                    }}
                  >
                    <TableCell>
                      {capitalizeString(el.personalData.firstName)}
                    </TableCell>

                    <TableCell>
                      {capitalizeString(el.personalData.lastName)}
                    </TableCell>

                    <TableCell>{t(el.personalData.mainPosition)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setShowAddEmployeeMsg(false)
              setProjectId('')
              setFilterFirstName('')
              setFilterLastName('')
              setfilterEmployeeResult([])
              setRefreshData(!refreshData)
              setFilterPosition(null)
              setSelectedProjectName('')
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Employee to project pop up */}
      {addEmployeeData !== null && (
        <Dialog
          open={showAddEmployeeToProjectMsg}
          classes={{ paper: classes.dialogRoot }}
          fullWidth
          maxWidth='md'
        >
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {capitalizeString(addEmployeeData.personalData.firstName)}{' '}
            {capitalizeString(addEmployeeData.personalData.lastName)}
            <div
              style={{
                fontSize: 14
              }}
            >
              {t(addEmployeeData.personalData.mainPosition)}
            </div>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item sm={12} md={3}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='date'
                  label={t('startOnContract')}
                  value={startOnContract}
                  onChange={(e) => setStartOnContract(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={t('departureFromHome')}
                />
              </Grid>

              <Grid item sm={12} md={2}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='time'
                  label={t('time')}
                  value={startOnContractTime}
                  onChange={(e) => setstartOnContractTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='date'
                  label={t('embarkation')}
                  value={embarkation}
                  onChange={(e) => setEmbarkation(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item sm={12} md={2}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='time'
                  label={t('time')}
                  value={embarkationTime}
                  onChange={(e) => setEmbarkationTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='text'
                  label={t('embarkationCity')}
                  value={embarkationCity}
                  onChange={(e) => setEmbarkationCity(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Divider style={{ margin: '20px 0' }} />

            <Grid container spacing={2}>
              {/*
               * End of contract
               */}
              <Grid item sm={12} md={3}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='date'
                  label={t('endOfTheContract')}
                  value={endOfContract}
                  onChange={(e) => setEndOfContract(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={t('arrivalToHome')}
                />
              </Grid>

              <Grid item sm={12} md={2}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='time'
                  label={t('time')}
                  value={endOfContractTime}
                  onChange={(e) => setEndOfContractTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='default'
                      classes={{ root: classes.checkbox }}
                      onChange={() => setSaveTemplate(!saveTemplate)}
                    />
                  }
                  label={t('planOn')}
                  style={{ marginTop: 10 }}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            {saveTemplate ? (
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => addEmployeeToProject()}
              >
                {t('addToProject')}
              </Button>
            ) : (
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => signOnEmployeeToProject()}
              >
                {t('signOn')}
              </Button>
            )}
            <Button
              onClick={() => {
                setShowAddEmployeeToProjectMsg(false)
                setAddEmployeeData(null)
                setStartOnContract('')
                setstartOnContractTime('')
                setEmbarkation('')
                setEmbarkationTime('')
                setEmbarkationCity('')
                setDisembarkation('')
                setEndOfContract(setEndOfContract)
                setEndOfContractTime('')
                setDisembarkationCity('')
                setSignOffReason('')
                setSaveTemplate(false)
              }}
            >
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete project message */}
      <Dialog
        open={showDeleteProjectMsg}
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>

        <DialogContent style={{ marginTop: 20 }}>
          {t('removeProjectFormSystem')}
        </DialogContent>

        <DialogActions>
          <Button
            size='small'
            color='orange'
            variant='contained'
            onClick={() => deleteProject()}
          >
            {t('removeProject')}
          </Button>

          <Button
            onClick={() => {
              setShowDeleteProjectMsg(false)
              setDeleteProjectId('')
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Smart planning */}
      <Dialog
        open={showSmartPlanning}
        classes={{ paper: classes.dialogRoot }}
        maxWidth='xl'
        fullScreen
        fullWidth
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('smartPlanning')}
        </DialogTitle>

        <DialogContent>
          <SmartPlanning
            companyId={companyId}
            projectId={projectId}
            projectName={projectNameForSmartPlanning}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setShowSmartPlanning(false)
              setProjectNameForSmartPlanning('')
            }}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbars */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      {/*
       * Buttons
       */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 20
        }}
      >
        <ButtonGroup variant='text' size='small'>
          {/*
           * Show archived
           */}
          <Button
            onClick={() => setShowArchivedProjects(!showArchivedProjects)}
          >
            {!showArchivedProjects
              ? t('show_archived_prodjects')
              : t('hide_archived_prodjects')}
          </Button>

          {/*
           * Sorting
           */}
          <Button onClick={() => setOrderProjects(!orderProjects)}>
            {orderProjects ? t('cancelOrder') : t('orderProjects')}
          </Button>
        </ButtonGroup>
      </div>

      {/* Projects list */}
      <DragDropContext onDragEnd={changeProjectListOrder}>
        <Droppable droppableId='projectsList'>
          {(provided) => (
            <Grid
              container
              spacing={2}
              style={{ marginTop: 15 }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {projectsList.map((el, i) => {
                if (
                  !showArchivedProjects
                    ? el.status === 'active'
                    : el.status === 'active' || el.status === 'archive'
                ) {
                  // eslint-disable-next-line
                  return (
                    <Draggable
                      key={el.projectId}
                      draggableId={el.projectId}
                      // draggableId={i.toString()}
                      index={i}
                    >
                      {(provided) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={orderProjects ? 12 : 6}
                          lg={orderProjects ? 12 : 6}
                          xl={orderProjects ? 12 : 4}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Accordion>
                            <AccordionSummary
                              expandIcon={
                                <FontAwesomeIcon icon={faChevronDown} />
                              }
                              style={{
                                backgroundColor: systemColors.electric,
                                color: '#ffffff',
                                borderRadius:
                                  systemStylingSettings.borderRadius,
                                opacity: el.status === 'archive' ? 0.6 : 1
                              }}
                            >
                              <div>
                                {el.projectName}

                                {/*
                                 * Counters
                                 */}
                                <div className='company_counters'>
                                  {/*
                                   * Employed counter
                                   */}
                                  {getEmployeeCount(el.projectId) > 0 && (
                                    <div className='count'>
                                      {t('employed')} -{' '}
                                      {getEmployeeCount(el.projectId)}
                                    </div>
                                  )}

                                  {/*
                                   * Planned counter
                                   */}
                                  {getPlannedEmployeeCount(el.projectId) >
                                    0 && (
                                    <div className='count'>
                                      {t('planned_2')} -{' '}
                                      {getPlannedEmployeeCount(el.projectId)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </AccordionSummary>

                            <AccordionDetails
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                {/*
                                 * Chips
                                 */}
                                <div>
                                  {/* Ad employee chip */}
                                  <Chip
                                    label={t('addEmployeeToList')}
                                    clickable
                                    onClick={() => {
                                      setShowAddEmployeeMsg(true)
                                      setProjectId(el.projectId)
                                      setSelectedProjectName(el.projectName)
                                    }}
                                    icon={
                                      <FontAwesomeIcon
                                        style={{ marginLeft: 10 }}
                                        icon={faPlus}
                                      />
                                    }
                                    disabled={
                                      el.status === 'archive' ? true : false
                                    }
                                  />

                                  {/* Smart planning chip */}
                                  <Chip
                                    label={t('smartPlanning')}
                                    style={{ marginLeft: 20 }}
                                    clickable
                                    onClick={() => {
                                      setShowSmartPlanning(true)
                                      setProjectId(el.projectId)
                                      setProjectNameForSmartPlanning(
                                        el.projectName
                                      )
                                    }}
                                    icon={
                                      <FontAwesomeIcon
                                        style={{ marginLeft: 10 }}
                                        icon={faBrain}
                                      />
                                    }
                                    disabled={
                                      el.status === 'archive' ? true : false
                                    }
                                  />
                                </div>

                                {/*
                                 * Buttons
                                 */}
                                <div className='conpanyPage_project_buttons'>
                                  {/*
                                   * Archive button
                                   */}
                                  <div className='icon_button'>
                                    {/*
                                     * If project not archived
                                     */}
                                    {el.status === 'active' && (
                                      <Tooltip title={t('archive')}>
                                        <div
                                          onClick={() =>
                                            archiveProject(el.projectId, true)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faFolder} />
                                        </div>
                                      </Tooltip>
                                    )}

                                    {/*
                                     * If project archived
                                     */}
                                    {el.status === 'archive' && (
                                      <Tooltip title={t('unarchive')}>
                                        <div
                                          onClick={() =>
                                            archiveProject(el.projectId, false)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faFolderOpen}
                                          />
                                        </div>
                                      </Tooltip>
                                    )}
                                  </div>

                                  {/*
                                   * Remove project button
                                   */}
                                  <div className='icon_button'>
                                    <Tooltip title={t('removeProject')}>
                                      <div
                                        onClick={() => {
                                          setDeleteProjectId(el.projectId)
                                          setShowDeleteProjectMsg(true)
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <GetProjectEmployeesList
                                  projectId={el.projectId}
                                  projectName={el.projectName}
                                  agencyData={agencyData}
                                  companyId={companyId}
                                  companyName={companyName}
                                  userUID={userUID}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      )}
                    </Draggable>
                  )
                } else {
                  // eslint-disable-next-line
                  return
                }
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    agencyUserData: state.agencyUserDataRed.obj,
    refreshDataRedux: state.refreshDataRed.bool,
    userData: state.userDataRed.obj
  }
}

const dispatchStateToProps = (dispatch) => ({
  setRefreshDataRedux: (bool) => dispatch(refreshData(bool))
})

export default connect(mapStateToProps, dispatchStateToProps)(CompanyPage)
