import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, CardContent, CardHeader } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  title: string
  /** FontAwesom icon */
  avatar?: IconProp | undefined
  children?: ReactNode
}

const BlockCard = ({ title, avatar, children }: Props) => {
  return (
    <Card>
      <CardHeader
        sx={[{ '& .MuiCardHeader-title': { fontSize: 15 } }]}
        title={title}
        avatar={
          avatar !== undefined ? (
            <FontAwesomeIcon icon={avatar as IconProp} />
          ) : null
        }
      />

      <CardContent>{children}</CardContent>
    </Card>
  )
}

export default BlockCard
