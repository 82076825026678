const initialState = {
    str: ''
}

const seaBookExpiresRed = (state = initialState, action) => {
    switch(action.type){
        case 'SEABOOK_EXPIRES' : {
            return {
                ...state,
                str: action.str
            }
        }
        default: {
            return state
        }
    }
}

export default seaBookExpiresRed