import React from 'react'
import './componentLoader.scss'

const ComponentLoader = () => {
  return (
    <div className='loader'>
      <div className='loader_spinner' />
    </div>
  )
}

export default ComponentLoader
