import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  FormControlLabel,
  Switch
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AgencyMenu from '../../../../Components/AgencyMenu'
import Headline from '../../../../Components/Headline'
import { systemColors } from '../../../../globalVariables'
import Loader from '../../../../Components/Loader'
import { connect } from 'react-redux'
import firebase from 'firebase/app'

const db = firebase.firestore()

const useStyles = makeStyles({
  switchBase: {
    '&.Mui-checked': {
      color: systemColors.orange
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.orange
    }
  },
  switchPrimary: {
    '&.MuiSwitch-switchBase.Mui-checked': {
      color: systemColors.orange
    },
    '&.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.orange
    }
  },
  labelSize: {
    fontSize: 14
  },
  cardSubheader: {
    fontSize: 15,
    color: systemColors.darkBlue
  }
})

const UserRolesCrew = ({ agencyData }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  const [allowToSeeSattistics, setAllowToSeeSattistics] = useState(
    agencyData.settings.globalPermitionsToAgents
      .allowAgentsToSeeCompanyStatistics
  )

  const changeStatusToSeeCompanyStatistics = async (status) => {
    setLoading(true)
    await db
      .collection('agencies')
      .doc(agencyData.id)
      .update({
        'data.settings.globalPermitionsToAgents.allowAgentsToSeeCompanyStatistics':
          status
      })
      .then(() => {
        setAllowToSeeSattistics(status)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  return (
    <div>
      {loading && <Loader />}

      <Headline text={t('userRoles')} />

      <AgencyMenu type='key' />

      {/* Content */}
      <div style={{ marginTop: 50 }}>
        <Card style={{ width: 'fit-content' }}>
          <CardHeader
            classes={{ subheader: classes.cardSubheader }}
            subheader={t('selectWhichDasboardBlocksToShow')}
          />
          <CardContent>
            <List>
              {/* Allow agents to see company statistiks */}
              <ListItem>
                <FormControlLabel
                  label={t('allowAgentsToSeeCompanyStatistics')}
                  labelPlacement='start'
                  classes={{ label: classes.labelSize }}
                  control={
                    <Switch
                      checked={allowToSeeSattistics}
                      onChange={() =>
                        changeStatusToSeeCompanyStatistics(
                          !allowToSeeSattistics
                        )
                      }
                      size='small'
                      classes={{
                        switchBase: classes.switchBase,
                        // checked: classes.switchPrimary
                        colorPrimary: classes.switchPrimary
                      }}
                      color='primary'
                    />
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  agencyData: state.agencyDataRed.obj
})

export default connect(mapStateToProps)(UserRolesCrew)
