const initialState = {
  bool: false
}

const dataFromSmartPlanningReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DATA_FROM_SMART_PLANNING' : {
      return {
        ...state,
        bool: action.bool
      }
    }
    default: {
      return state
    }
  }
}

export default dataFromSmartPlanningReducer

