import { makeStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next/'
import { connect } from 'react-redux'
import AgencyMenu from '../Components/AgencyMenu'
import Headline from '../Components/Headline'
import Header from '../Modules/Header'
import { systemColors } from '../globalVariables'

const useStyles = makeStyles({
  date: {
    color: systemColors.lighGrey,
    fontSize: 12
  }
})

const VersionLog = ({ agencyData, userData }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  document.title = `${t('versionsLog')} - Seanor`

  return (
    <>
      {userData !== null ? (
        <div>
          {userData.type !== 'employee' ? (
            <div>
              <AgencyMenu type={agencyData.type} />
              <Headline text={t('versionsLog')} />
            </div>
          ) : (
            <Header />
          )}
        </div>
      ) : (
        <Header />
      )}

      <div className='versionsLog'>
        {/* In production */}
        <h3>
          0.16.2 <span className={classes.date}>2023.09.11</span>
        </h3>
        <ul>
          <li>Fixed hide salary bug</li>
        </ul>
        {/* In production */}
        <h3>
          0.16.1 <span className={classes.date}>2023.03.07</span>
        </h3>
        <ul>
          <li>Fixed translations</li>
          <li>Cahnged company and project selection input</li>
        </ul>

        {/* In production */}
        <h3>
          0.16.0 <span className={classes.date}>2023.02.22</span>
        </h3>
        <ul>
          <li>Added aditional file formats to apply to position form</li>
          <li>Added company logo and heading to job ad</li>
          <li>
            Show planned and employeed workers numbers on each project in Key
            and Crew subsystems
          </li>
          <li>Updated workers status</li>
          <li>Added project archive feature to Key and Crew subsystems</li>
          <li>Updated documents upload in profiles</li>
          <li>Added copy experience feature in profiles</li>
          <li>Updated key workers filtration fields</li>
          <li>Fixed employee database loader bug</li>
          <li>Updated some translations</li>
        </ul>

        {/* In production */}
        <h3>
          0.15.2 <span className={classes.date}>2023.02.21</span>
        </h3>
        <ul>
          <li>Removed footer from jobs ads pages</li>
          <li>Updated some translations</li>
          <li>Added new translations</li>
        </ul>

        {/* In production */}
        <h3>
          0.15.1 <span className={classes.date}>2023.02.20</span>
        </h3>
        <ul>
          <li>Fixed login page loading bug</li>
        </ul>

        {/* In production */}
        <h3>
          0.15.0 <span className={classes.date}>2022.12.29</span>
        </h3>
        <ul>
          <li>Added jobs positions management</li>
          <li>Added agency open positions ads page</li>
          <li>Added candidates management</li>
          <li>Added tasks management</li>
          <li>Added notification center</li>
          <li>Added reminder feature</li>
          <li>Added pre-evaluation feature in candidate profile</li>
          <li>
            Added candidate evaluations of positions history in candidate
            profile
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.14.3 <span className={classes.date}>2022.12.01</span>
        </h3>
        <ul>
          <li>Fixed CV pdf download on mobile devices bug</li>
        </ul>

        {/* In production */}
        <h3>
          0.14.2 <span className={classes.date}>2022.11.30</span>
        </h3>
        <ul>
          <li>Fixed input fields and other design related bugs</li>
        </ul>

        {/* In production */}
        <h3>
          0.14.1 <span className={classes.date}>2022.11.28</span>
        </h3>
        <ul>
          <li>Fixed input fields bugs</li>
        </ul>

        {/* In production */}
        <h3>
          0.14.0 <span className={classes.date}>2022.08.25</span>
        </h3>
        <ul>
          <li>Added some new features to admin</li>
        </ul>

        {/* In production */}
        <h3>
          0.13.1 <span className={classes.date}>2022.06.14</span>
        </h3>
        <ul>
          <li>Fixed Client report bug when employee have "At home" status</li>
          <li>Fixed expire document marking bug</li>
        </ul>

        <h3>
          0.13.0 <span className={classes.date}>2022.04.11</span>
        </h3>
        <ul>
          <li>Added statistics print feature</li>
          <li>Added user roles settings to agency adminsitrator</li>
        </ul>
        {/* In production */}
        <h3>
          0.12.0 <span className={classes.date}>2022.03.01</span>
        </h3>
        <ul>
          <li>Added dashboard page in Crew subsystem</li>
          <li>Added "Last viewed companies" block to dashboard</li>
          <li>Added "Last viewed crew members" block to dashboard</li>
          <li>Added "Tagged crew members" block to dashboard</li>
          <li>Added "Last agency applicants" block to dashboard</li>
          <li>Added "Last online applicants" block to dashboard</li>
          <li>Added "Dashboard settings" page</li>
          <li>Fixed planned profile color issue in smart planning</li>
        </ul>

        {/* In production */}
        <h3>
          0.11.1 <span className={classes.date}>2022.02.25</span>
        </h3>
        <ul>
          <li>Fixed language issue in crew subsystem</li>
          <li>Fixed text aligment issue in profile pdf file</li>
        </ul>

        {/* In production */}
        <h3>
          0.11.0 <span className={classes.date}>2022.02.21</span>
        </h3>
        <ul>
          <li>Added Dark mode</li>
          <li>Added Company statistics in Key subsystem</li>
        </ul>

        {/* In production */}
        <h3>
          0.10.0 <span className={classes.date}>2022.02.09</span>
        </h3>
        <ul>
          <li>Added profile Verify feature in Key subsystem</li>
        </ul>

        {/* In production */}
        <h3>
          0.9.1 <span className={classes.date}>2022.02.08</span>
        </h3>
        <ul>
          <li>Fixed text wrapping issue in Smart planning</li>
        </ul>

        {/* In production */}
        <h3>
          0.9.0 <span className={classes.date}>2022.02.07</span>
        </h3>
        <ul>
          <li>Added dashboard page in Key subsystem</li>
          <li>Added "Last viewed companies" block to dashboard</li>
          <li>Added "Last viewed key employees" block to dashboard</li>
          <li>Added "Tagged employees" block to dashboard</li>
          <li>Added "Last agency applicants" block to dashboard</li>
          <li>Added "Last online applicants" block to dashboard</li>
          <li>Added "Dashboard settings" page</li>
        </ul>

        {/* In production */}
        <h3>
          0.8.13 <span className={classes.date}>2022.01.31</span>
        </h3>
        <ul>
          <li>Added status in Crew datatbase</li>
          <li>Added duration to rotation log in Key subsystem</li>
          <li>Fixed bug of nationality input in Crew subsystem new profile</li>
        </ul>

        {/* In production */}
        <h3>
          0.8.12 <span className={classes.date}>2022.01.28</span>
        </h3>
        <ul>
          <li>Added colors to employee rotation log status</li>
          <li>All new added profiles get status "Available"</li>
          <li>
            Added two new employee rotattion log statuses "Available" and
            "Planning"
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.8.11 <span className={classes.date}>2022.01.19</span>
        </h3>
        <ul>
          <li>Fixed PDF CV layout</li>
        </ul>

        {/* In production */}
        <h3>
          0.8.10 <span className={classes.date}>2021.10.17</span>
        </h3>
        <ul>
          <li>
            Added feature to show which document is about to expere in Crew
            subsystem
          </li>
          <li>Added additionla fields to profile pages in Crew subsystem</li>
        </ul>

        {/* In production */}
        <h3>
          0.8.9 <span className={classes.date}>2021.10.14</span>
        </h3>
        <ul>
          <li>
            Fixed profile pdf rendering bug when old profiles has no added
            certificates in Key subsystem
          </li>
          <li>
            Added remove buttons to added new certificates options in Key
            subsystem
          </li>
          <li>
            Added remove buttons to added new positions options in Key subsystem
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.8.8 <span className={classes.date}>2021.09.30</span>
        </h3>
        <ul>
          <li>Added hover feature on documents name in Crew subsystem</li>
          <li>Udated removal buttons in Key, Crew and Employee subsystems</li>
          <li>
            Fixed bug of document remove from listings in Key And Employee
            subsystems
          </li>
          <li>Improoved documents listings in Key and Employee subsystmes</li>
          <li>Fixed bug in header in Employee subsystem</li>
          <li>
            Added additional field in employee profile in Key and Employee
            subsystems
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.8.7 <span className={classes.date}>2021.09.28</span>
        </h3>
        <ul>
          <li>Fixed input clear bug in Key subsystem</li>
          <li>Changed some names in Key subsystem</li>
          <li>Added button to added company in Crew subsystem</li>
          <li>Added button to added company in Key subsystem</li>
        </ul>

        {/* In production */}
        <h3>
          0.8.6 <span className={classes.date}>2021.09.23</span>
        </h3>
        <ul>
          <li>
            Added feature that enable to agency to add their own positions in
            Key subsystem
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.8.5 <span className={classes.date}>2021.09.23</span>
        </h3>
        <ul>
          <li>Fixed temporary reports url bug in Crew and Key subsystems</li>
          <li>Fixed menu issue in Crew subsystem</li>
          <li>
            Changed "Work discipline" to "Work skills" names in Key and
            Employees subsystem
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.8.4 <span className={classes.date}>2021.09.01</span>
        </h3>
        <ul>
          <li>
            Order employees by start on contract date in Key and Crew subsystems
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.8.3 <span className={classes.date}>2021.08.26</span>
        </h3>
        <ul>
          <li>Added Payment type to expense tracking in Key subsystem</li>
        </ul>

        {/* In production */}
        <h3>
          0.8.2 <span className={classes.date}>2021.08.25</span>
        </h3>
        <ul>
          <li>Made new expense dialog wider in Company subsystem</li>
          <li>Updated Rotattion log design in Key and Crew subsystems</li>
          <li>
            Added "Unlimited" when document expire date not set in Companies and
            Vessels subsystems
          </li>
          <li>
            Added "Still working" checkbox to experience section in Key and
            Employee susbsystems
          </li>
          <li>Updated registration form design</li>
          <li>
            Added quick add document in profile view page in Crew subsystem
          </li>
          <li>Grouped documents in Crew and Employee profile view page</li>
          <li>Fixed bug of translate in pdf generations.</li>
        </ul>

        {/* In production */}
        <h3>
          0.8.1 <span className={classes.date}>2021.08.23</span>
        </h3>
        <ul>
          <li>
            Added additional fields in "Rotation log" edit in Key and Crew
            subsystems
          </li>
          <li>Added Client report in Crew subsystem</li>
        </ul>

        {/* In production */}
        <h3>
          0.8.0 <span className={classes.date}>2021.08.19</span>
        </h3>
        <ul>
          <li>
            Improved search by company in Companies list page in Vessels
            subsystem
          </li>
          <li>
            Improved search by company in Companies list page in Companies
            subsystem
          </li>
          <li>
            Fixed unlimited document bug in employee cv pdf file in Crew
            subsystems
          </li>
          <li>Created Client report</li>
        </ul>

        {/* In production */}
        <h3>
          0.7.1 <span className={classes.date}>2021.08.18</span>
        </h3>
        <ul>
          <li>
            Enable to update some entries in rotation log and see changes in
            projects and smart planning
          </li>
          <li>Fixed pre evaluation form english translate bug</li>
          <li>Fixed download pre evaluation form bug from Safary browser</li>
          <li>Made wider blocks in company page in Vessels subsystem</li>
          <li>Made wider blocks in company page in Companies subsystem</li>
          <li>Smart planning bug fixes in Vessels subsystem</li>
        </ul>

        {/* In production */}
        <h3>
          0.7.0 <span className={classes.date}>2021.08.13</span>
        </h3>
        <ul>
          <li>Added expenses tracking feature in Key subsystem</li>
          <li>Added view graphics by months to smart plannig</li>
        </ul>

        {/* In production */}
        <h3>
          0.6.1 <span className={classes.date}>2021.08.05</span>
        </h3>
        <ul>
          <li>Smart planning bug fixes</li>
        </ul>

        {/* In production */}
        <h3>
          0.6.0 <span className={classes.date}>2021.08.03</span>
        </h3>
        <ul>
          <li>Added Smart planning to Vessels subsystem</li>
          <li>Added Smart planning to Companies subsystem</li>
          <li>Fixed add new company bug in Vessels and Companies subsystems</li>
          <li>Fixed bad profile url bug in employees list in Crew subsystem</li>
          <li>
            Allow edit all documents in employee view page in Crew subsystem
          </li>
          <li>
            Added user profile photo placeholder to Profile PDF render in Crew
            and Key subsystems
          </li>
          <li>
            Added "unlimited" word if document expire date isn't ser in employee
            view page in Crew subsystem
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.5.3 <span className={classes.date}>2021.07.23</span>
        </h3>
        <ul>
          <li>
            Fixed document delete and download link bug in Vessels subsystem
          </li>
          <li>
            Fixed document delete and download link bug in Comanies subsystem
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.5.2 <span className={classes.date}>2021.07.22</span>
        </h3>
        <ul>
          <li>
            Fixed new document attach file bug in "New company" page in Vessels
            subsystem
          </li>
          <li>
            Fixed new document attach file bug in "Company" page in Vessels
            subsystem
          </li>
          <li>
            Fixed new document attach file bug in "New company" page in Company
            subsystem
          </li>
          <li>
            Fixed new document attach file bug in "Company" page in Company
            subsystem
          </li>
          <li>
            Added issue and expire dates to certificates and diplomas in "Create
            new profile" page in Employee subsystem
          </li>
          <li>
            Added issue and expire dates to certificates and diplomas in "Edit
            profile" page in Employee subsystem
          </li>
          <li>
            Added issue and expire dates to certificates and diplomas in "View
            profile" page in Employee subsystem
          </li>
          <li>
            Added issue and expire dates to certificates and diplomas in "View
            epmloyee profile" page in Key subsystem
          </li>
          <li>
            Added issue and expire dates to certificates and diplomas in "Edit
            profile" page in Key subsystem
          </li>
          <li>
            Added issue and expire dates to certificates and diplomas in
            "Register new profile" page in Key subsystem
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.5.1 <span className={classes.date}>2021.07.20</span>
        </h3>
        <ul>
          <li>Fixed document name load error in Crew subsystem</li>
          <li>Updated pre evaluation print form in Key subsystem</li>
          <li>
            Improved file upload in edit profile page in Employee subsystem
          </li>
          <li>
            Improved file upload in new profile page in Employee subsystem
          </li>
          <li>Improved file upload in edit profile page in Crew subsystem</li>
          <li>Improved file upload in new profile page in Crew subsystem</li>
        </ul>

        {/* In production */}
        <h3>
          0.5.0 <span className={classes.date}>2021.07.14</span>
        </h3>
        <ul>
          <li>Added pre evalauation printing feature in Key subsystem</li>
        </ul>

        {/* In production */}
        <h3>
          0.4.9 <span className={classes.date}>2021.06.29</span>
        </h3>
        <ul>
          <li>Improved password reset</li>
        </ul>

        {/* In production */}
        <h3>
          0.4.8 <span className={classes.date}>2021.06.29</span>
        </h3>
        <ul>
          <li>Improved email verification</li>
        </ul>

        {/* In production */}
        <h3>
          0.4.7 <span className={classes.date}>2021.06.28</span>
        </h3>
        <ul>
          <li>
            Fixed empty file url bug in profile edit page in Key and Employee
            subsystems
          </li>
          <li>
            Show meessage if file not attached to document in profile edit page
            in Key and Employee subsystems
          </li>
          <li>
            Added send login information to user functionality in Admin
            subsystem
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.4.6 <span className={classes.date}>2021.06.27</span>
        </h3>
        <ul>
          <li>Hided version log from not loged in users</li>
          <li>
            Added contract duration output in profile PDF file in Crew subsystem
          </li>
          <li>
            Improved documents upload in new profile page in Employee subsystem
          </li>
          <li>
            Improved documents upload in employee edit page in Employee
            subsystem
          </li>
          <li>
            Improved documents upload in new profile page in Key subsystem
          </li>
          <li>
            Improved documents upload in employee edit page in Key subsystem
          </li>
          <li>Change inactive menu list items color</li>
        </ul>

        {/* In production */}
        <h3>
          0.4.5 <span className={classes.date}>2021.06.21</span>
        </h3>
        <ul>
          <li>
            Fixed document upload issue in companies profile page in Vessels
            subsystem
          </li>
          <li>
            Fixed document upload issue in company profile page in Companies
            subsystem
          </li>
          <li>Updated rotation log logic in Key and Crew subsystems</li>
          <li>
            Changed some table names in rotation log in Crew and Key subsystems
          </li>
        </ul>

        {/* In production */}
        <h3>
          0.4.4 <span className={classes.date}>2021.06.15</span>
        </h3>
        <ul>
          <li>Added bigger company logo to employee profile PDF files</li>
          <li>Added employee id box to header in Employee subsystem</li>
          <li>Fixed empty cv file bug in Key subsystem</li>
          <li>
            Removed profile photo validation from new profile page in Cre
            subsystem
          </li>
        </ul>

        <h3>
          0.4.3 <span className={classes.date}>2021.06.15</span>
        </h3>
        <ul>
          <li>
            Added profile creation date to employee view page in Crew subsystem
          </li>
          <li>Changed menu order in Crew subsystem</li>
        </ul>

        <h3>
          0.4.2 <span className={classes.date}>2021.06.15</span>
        </h3>
        <ul>
          <li>
            Added profile photo placeholder to employee view page in Crew
            subsystem
          </li>
          <li>
            Divide chip menu in two rows in employee view page in Crew subsystem
          </li>
          <li>Added delete profile feature in Crew subsystem</li>
          <li>
            Added send email feature from employee view page in Crew subsystem
          </li>
          <li>Fixed date change bug during sign off in Companies subsystem</li>
          <li>Fixed date change bug during sign off in Vessels subsystem</li>
        </ul>

        <h3>
          0.4.1 <span className={classes.date}>2021.06.04</span>
        </h3>
        <ul>
          <li>
            Fixed filtered profiles photo display bug in Key and Crew subsystems
          </li>
          <li>
            Added some new input fields and removed some old input fields in
            edit profile page in Crew subsystem
          </li>
          <li>Added new outputs in profile PDF file in Crew subsystem</li>
          <li>
            Added some new outputs and removed some old outputs in employee view
            page in Crew subsystem
          </li>
          <li>
            Added some new input fields and removed some old input fields in new
            profile page in Crew subsystem
          </li>
          <li>Show only active profiles in employees list in Crew subsystem</li>
          <li>
            Added search by profile status feature in employees list page in
            Crew subsystem
          </li>
        </ul>

        <h3>
          0.4.0 <span className={classes.date}>2021.06.03</span>
        </h3>
        <ul>
          <li>Added company page in Vessels subsystem</li>
          <li>Fixed additional file ulpoad bug in Company subsystem</li>
          <li>Added All companies page in Vessels subsystem</li>
          <li>Added Add new company page in Vessels subsystem</li>
          <li>Added rotation log in employee view page in Crew subsystem</li>
        </ul>

        <h3>
          0.3.3 <span className={classes.date}>2021.05.31</span>
        </h3>
        <ul>
          <li>
            Improved file upload in edit profile page in Employee subsystem
          </li>
          <li>
            Improved file upload in new profile page in Employee subsystem
          </li>
        </ul>

        <h3>
          0.3.2 <span className={classes.date}>2021.05.28</span>
        </h3>
        <ul>
          <li>Improved file upload in edit profile page in Key subsystem</li>
          <li>Improved file upload in new profile page in Key subsystem</li>
          <li>
            Fixed bug with nationality selection in employee edit page in Key
            subsystem
          </li>
        </ul>
        <h3>
          0.3.1 <span className={classes.date}>2021.05.18</span>
        </h3>
        <ul>
          <li>Added additional data to evaluation PDF file in Key subsystem</li>
          <li>
            Automaticaly fill some fields in evaluation form in Key subsystem
          </li>
          <li>
            Added extra fields to employee edit profile page in Key subsystem
          </li>
          <li>Added some data to employee profile PDF file in Key subsystem</li>
          <li>
            Added some data to employee profile view page in Key subsystem
          </li>
          <li>
            Added note in experrience tab in new profile page in Employee
            subsystem
          </li>
          <li>
            Added some extra fields to epmloyee edit profile page in Employee
            subsystem
          </li>
          <li>
            Added some data to employee profile view page in Employee subsystem
          </li>
          <li>
            Added some extra inputs to new profile page in Employee subsystem
          </li>
          <li>Added some extra inputs to new profile page in Key subsystem</li>
          <li>
            Added empty profile photo placeholder in employee view page in Key
            subsystem
          </li>
          <li>
            Divide chips menu to two lines in employee view page in Key
            subsystem
          </li>
          <li>
            Hide "Re-employ in current position" and "Promote" if value not
            selected in evaluation PDF file in Key subsystem
          </li>
          <li>
            Added internet site column to companies list in Companies subsystem
          </li>
          <li>
            Added Seanor logo to employees profile PDF file in Key and Crew
            subsystems
          </li>
        </ul>

        <h3>
          0.3.0 <span className={classes.date}>2021.05.14</span>
        </h3>
        <ul>
          <li>Added company logo to evaluation form PDF ir Key subsystem</li>
          <li>Updated terms and conditions files</li>
          <li>
            Added company logo to employees PDF files in Key and Crew subsystems
          </li>
          <li>Added company logo to Login and Registration pages</li>
          <li>Added upload company logo feature in Settings subsystem</li>
          <li>Added Settings subsystem</li>
          <li>
            Added profile picture placeholder to employeese list in Key
            subsystem
          </li>
          <li>Arrange companies by creation date in Companies subsystem</li>
          <li>Changed user types in Admin subsystem</li>
          <li>Added sort projects with mouse feature in Companies subsystem</li>
          <li>
            Added additional fields to company details in Companies subsystem
          </li>
        </ul>

        <h3>
          0.2.17 <span className={classes.date}>2021.05.06</span>
        </h3>
        <ul>
          <li>
            Added new list view option with profile photos in Key subsystem
          </li>
          <li>
            Show profiles in list only with active status in Key subsystem
          </li>
          <li>Added filtration by profile status in Key subsystem</li>
          <li>
            Added delete profile button in employee profile page in Key
            subsystem
          </li>
          <li>Fixed not existing file replacement bug in Key subsystem</li>
          <li>First document upload bug fix in Key subsystem</li>
        </ul>

        <h3>
          0.2.16 <span className={classes.date}>2021.05.04</span>
        </h3>
        <ul>
          <li>Added profile status in Key subsystem</li>
          <li>
            Added user update field to employee edit page in Key subsystem
          </li>
          <li>Added user id to pdf in Key subsystem</li>
          <li>
            Added email call button in employee view page in Key subsystem
          </li>
        </ul>

        <h3>
          0.2.15 <span className={classes.date}>2021.04.28</span>
        </h3>
        <ul>
          <li>Fixed upload message bug in Key subsystem</li>
          <li>Changed registration form names</li>
        </ul>

        <h3>
          0.2.14 <span className={classes.date}>2021.04.27</span>
        </h3>
        <ul>
          <li>Fixed empty experience country bug in Key subsystem</li>
          <li>
            Added employee status after saving new profile in Key subsystem
          </li>
          <li>Added language swicher to menu in Key subsystem</li>
          <li>Added inputs validations to Employee subsystem</li>
        </ul>

        <h3>
          0.2.13 <span className={classes.date}>2021.04.16</span>
        </h3>
        <ul>
          <li>
            Added some warning messages in company page in Companies subsystem
          </li>
          <li>Added users filtration in Admin subsystem</li>
          <li>Added delete user functionality to Admin subsystem</li>
          <li>Updated inputs layouts in Crew subsystem</li>
          <li>Changed inputs fields names in Crew subsystem</li>
          <li>Added additional information to CV PDF in Crew subsystem</li>
          <li>Fixed CV PDF layout in Crew subsystem</li>
        </ul>

        <h3>
          0.2.12 <span className={classes.date}>2021.04.12</span>
        </h3>
        <ul>
          <li>Fixed documents list diplay bug in PDF file in Crew subsystem</li>
          <li>
            Fixed new profile photo upload bug in Crew end Employee subsystems
          </li>
          <li>Added users list in Admin subsystem</li>
          <li>Added agencies list in Admin subsystem</li>
          <li>User can work at the same time only from one device</li>
          <li>Show employee status in employee view page in Key subsystem</li>
          <li>Added search by country and status in Key subsystem</li>
          <li>Added employee status to rotationlog in Key subsystem</li>
          <li>Fixed add employee to project bug in Company subsystem</li>
        </ul>

        <h3>
          0.2.11 <span className={classes.date}>2021.04.09</span>
        </h3>
        <ul>
          <li>Fixed new note of company bug in Company subsystem</li>
          <li>Updated evaluation form PDF in Key subsystem</li>
          <li>
            Fixed blank page bug when searching employee for project in Company
            subsystem
          </li>
          <li>Fixed position diplay bug in employees list in Key subsystem</li>
          <li>Updated evaluation form validation in Key subsystem</li>
        </ul>

        <h3>
          0.2.10 <span className={classes.date}>2021.04.09</span>
        </h3>
        <ul>
          <li>
            Fixed position display bug in employee view page in Key subsystem
          </li>
          <li>
            Added filtration by id option to autocomplete input for positions
            and certificates in Crew subsystem
          </li>
          <li>
            Updated employee profile PDF document with new data in Key subsystem
          </li>
          <li>Fixed headline name in edit employee page in Key subsystem</li>
          <li>Updated edit employee form grid in Employee subsystem</li>
          <li>Updated new employee form grid in Employee subsystem</li>
        </ul>

        <h3>
          0.2.9 <span className={classes.date}>2021.04.07</span>
        </h3>
        <ul>
          <li>Updated new employee form grid in Crew subsystem</li>
          <li>Updated employees filtration in Crew subsystem</li>
          <li>Updated employees filtration in Key subsystem</li>
          <li>
            Add additional outputs to employee profile in Employee subsystem{' '}
          </li>
          <li>
            Add additional inputs to edit employee profile in Employee subsystem{' '}
          </li>
        </ul>

        <h3>
          0.2.8 <span className={classes.date}>2021.04.06</span>
        </h3>
        <ul>
          <li>
            Fixed loaders issue when loader turn off when files still uploading
            in Crew and Employee subsystems
          </li>
          <li>
            Fixed crash bug when not selecting nationality in new employee
            profile page in Crew subsystem
          </li>
          <li>
            Add additional inputs to new employee profile in Employee subsystem
          </li>
          <li>Add validation to new employee profile in Key subsystem</li>
          <li>Add some fields to new employee porfile in Key subsystem</li>
        </ul>

        <h3>
          0.2.7 <span className={classes.date}>2021.03.31</span>
        </h3>
        <ul>
          <li>Updated some stylings in evaluation from in Key subsystem</li>
          <li>Created employee rotation log in Key subsystem</li>
        </ul>

        <h3>
          0.2.6 <span className={classes.date}>2021.03.30</span>
        </h3>
        <ul>
          <li>Created evaluation PDF file in Key subsystem</li>
          <li>Created employee evaluation form and list in Key subsystem</li>
          <li>
            Changed remove project to archive project functionality in Companies
            subsystem
          </li>
          <li>
            Added remove employee from project list feature in Companies
            subsystem
          </li>
        </ul>

        <h3>
          0.2.5 <span className={classes.date}>2021.03.25</span>
        </h3>
        <ul>
          <li>
            Fixed empty field crash bug in pdf generation in Crew subsystem
          </li>
          <li>
            Updated add and edit company functionality in Companies subsystem
          </li>
          <li>Added filtration to companies list in Comanies subsystem</li>
        </ul>

        <h3>
          0.2.4 <span className={classes.date}>2021.03.24</span>
        </h3>
        <ul>
          <li>Changed buttons in edit profile page in Employee subsystem</li>
          <li>Changed buttons in create profile page in Employee subsystem</li>
          <li>
            Changed buttons in employee edit profile page in Crew subsystem
          </li>
          <li>
            Changed buttons in employee new profile page in Crew subsystem
          </li>
          <li>Fixed line break issue on pdf render</li>
          <li>
            Arrange companies list result by "customer", "negotiation" and
            "target" modes in Companies subsystem
          </li>
          <li>Add modes to companies in Companies subsystem</li>
          <li>Fixed time field issue in Companies subsystem</li>
          <li>fixed page load issue when profile picture not ploaded</li>
          <li>Add delete project functionality in Companies subsystem</li>
          <li>Changed some buttons names</li>
        </ul>

        <h3>0.2.3</h3>
        <ul>
          <li>
            Added popup messages if need attension to project ir Companies
            subsystem
          </li>
          <li>
            Highlight dates in employees list in project in Companies subsystem
          </li>
          <li>Added "Show all" employees filtration in Company subsystem</li>
          <li>Added employee filtration by position in Company subsystem</li>
          <li>Changed projects table width in Companies subsystem</li>
          <li>
            Fixed list refresh issue after adding new employee to project list
            in Companies subsystem
          </li>
          <li>
            Added highlight not signed on employees on project feature in
            Companies subsystem
          </li>
          <li>
            Changed employee sign off method on project in Companies subsystem
          </li>
          <li>
            Changed employee sign on method on project in Companies subsystem
          </li>
          <li>Created "Version log" page</li>
          <li>Changed "Add documents buttons" in Crew subsystem</li>
          <li>
            Changed "Remarks" and "Comment" names to "Cover letter - comments"
          </li>
        </ul>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  agencyData: state.agencyDataRed.obj
})

export default connect(mapStateToProps)(VersionLog)
