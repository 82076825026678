const initialState = {
    bool: false
}

const isAuthRed = (state = initialState, action) => {
    switch(action.type){
        case 'IS_AUTH' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export default isAuthRed