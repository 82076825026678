import React from 'react'
import AgencyMenu from '../../Components/AgencyMenu'
import KeyEmployeeList from './keyEmployeeList'

const KeyAgencyPage = () => {
    return (
        <div>
            <AgencyMenu type='key' />
            <KeyEmployeeList />
        </div>
    )
}
 
export default KeyAgencyPage;