import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import AgencyMenu from '../../../../Components/AgencyMenu'
import Headline from '../../../../Components/Headline'
import firebase from 'firebase/app'
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  List,
  ListItem,
  Switch
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { systemColors } from '../../../../globalVariables'

const db = firebase.firestore()

const useStyles = makeStyles({
  switchBase: {
    '&.Mui-checked': {
      color: systemColors.orange
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.orange
    }
  },
  switchPrimary: {
    '&.Mui-checked': {
      color: systemColors.orange
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.orange
    }
  },
  labelSize: {
    fontSize: 14
  },
  cardSubheader: {
    fontSize: 15,
    color: systemColors.darkBlue
  }
})

const CrewDashboardSettings = ({ userData }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { userRole, settings, uid } = userData

  const [lastViewedCompanies, setLastViewedCompanies] = useState(
    settings.userSettings.dashboard.allowLastViewedCompaniesCREW
  )
  const [lastViewedProfiles, setLastViewedProfiles] = useState(
    settings.userSettings.dashboard.allowLastViewedProfilesCREW
  )
  const [taggedProfiles, setTaggedProfiles] = useState(
    settings.userSettings.dashboard.allowTaggedProfilesCREW
  )
  const [lastAgencyApplicants, setLastAgencyApplicants] = useState(
    settings.userSettings.dashboard.allowLastAgencyApplicantsCREW
  )
  const [lastOnlineApplicants, setLastOnlineApplicants] = useState(
    settings.userSettings.dashboard.allowLastOnlineApplicantsCREW
  )

  const changeLastViewedCompaniesStatus = async () => {
    if (!lastViewedCompanies) {
      await db
        .collection('users')
        .doc(uid)
        .update({
          'data.settings.userSettings.dashboard.allowLastViewedCompaniesCREW': true
        })
        .then(() => setLastViewedCompanies(true))
        .catch((error) => console.log("Can't update dashboard setting", error))
    } else {
      await db
        .collection('users')
        .doc(uid)
        .update({
          'data.settings.userSettings.dashboard.allowLastViewedCompaniesCREW': false
        })
        .then(() => setLastViewedCompanies(false))
        .catch((error) => console.log("Can't update dashboard setting", error))
    }
  }

  const changeLastViewedProfileStatus = async () => {
    if (!lastViewedProfiles) {
      await db
        .collection('users')
        .doc(uid)
        .update({
          'data.settings.userSettings.dashboard.allowLastViewedProfilesCREW': true
        })
        .then(() => setLastViewedProfiles(true))
        .catch((error) => console.log("Can't update dashboard setting", error))
    } else {
      await db
        .collection('users')
        .doc(uid)
        .update({
          'data.settings.userSettings.dashboard.allowLastViewedProfilesCREW': false
        })
        .then(() => setLastViewedProfiles(false))
        .catch((error) => console.log("Can't update dashboard setting", error))
    }
  }

  const changeTaggedProfilesStatus = async () => {
    if (!taggedProfiles) {
      await db
        .collection('users')
        .doc(uid)
        .update({
          'data.settings.userSettings.dashboard.allowTaggedProfilesCREW': true
        })
        .then(() => setTaggedProfiles(true))
        .catch((error) => console.log("Can't update dashboard setting", error))
    } else {
      await db
        .collection('users')
        .doc(uid)
        .update({
          'data.settings.userSettings.dashboard.allowTaggedProfilesCREW': false
        })
        .then(() => setTaggedProfiles(false))
        .catch((error) => console.log("Can't update dashboard setting", error))
    }
  }

  const changeLastAgencyApplicantsStatus = async () => {
    if (!lastAgencyApplicants) {
      await db
        .collection('users')
        .doc(uid)
        .update({
          'data.settings.userSettings.dashboard.allowLastAgencyApplicantsCREW': true
        })
        .then(() => setLastAgencyApplicants(true))
        .catch((error) => console.log("Can't update dashboard setting", error))
    } else {
      await db
        .collection('users')
        .doc(uid)
        .update({
          'data.settings.userSettings.dashboard.allowLastAgencyApplicantsCREW': false
        })
        .then(() => setLastAgencyApplicants(false))
        .catch((error) => console.log("Can't update dashboard setting", error))
    }
  }

  const changeLastOnlineApplicantsStatus = async () => {
    if (!lastOnlineApplicants) {
      await db
        .collection('users')
        .doc(uid)
        .update({
          'data.settings.userSettings.dashboard.allowLastOnlineApplicantsCREW': true
        })
        .then(() => setLastOnlineApplicants(true))
        .catch((error) => console.log("Can't update dashboard setting", error))
    } else {
      await db
        .collection('users')
        .doc(uid)
        .update({
          'data.settings.userSettings.dashboard.allowLastOnlineApplicantsCREW': false
        })
        .then(() => setLastOnlineApplicants(false))
        .catch((error) => console.log("Can't update dashboard setting", error))
    }
  }

  return (
    <div>
      <Headline text={t('dashboardSettings')} />

      <AgencyMenu type='crew' />

      <div style={{ marginTop: 50 }}>
        <Card style={{ width: 'fit-content', margin: 'auto' }}>
          <CardHeader
            classes={{ subheader: classes.cardSubheader }}
            subheader={t('selectWhichDasboardBlocksToShow')}
          />
          <CardContent>
            <List>
              {/* Last visited companies */}
              {userRole === 'agency_admin' ||
              userRole === 'super_admin' ||
              settings.adminPermissions.dashboard
                .allowLastViewedCompaniesCREW === true ? (
                <ListItem>
                  <FormControlLabel
                    label={t('lastViewedCompanies')}
                    labelPlacement='start'
                    classes={{ label: classes.labelSize }}
                    control={
                      <Switch
                        checked={lastViewedCompanies}
                        onChange={() => changeLastViewedCompaniesStatus()}
                        size='small'
                        classes={{
                          switchBase: classes.switchBase,
                          colorPrimary: classes.switchPrimary
                        }}
                      />
                    }
                  />
                </ListItem>
              ) : null}

              {/* Last visited employees */}
              {userRole === 'agency_admin' ||
              userRole === 'super_admin' ||
              settings.adminPermissions.dashboard
                .allowLastViewedProfilesCREW === true ? (
                <ListItem>
                  <FormControlLabel
                    label={t('lastViewedCrewMembers')}
                    labelPlacement='start'
                    classes={{ label: classes.labelSize }}
                    control={
                      <Switch
                        checked={lastViewedProfiles}
                        onChange={() => changeLastViewedProfileStatus()}
                        size='small'
                        classes={{
                          switchBase: classes.switchBase,
                          colorPrimary: classes.switchPrimary
                        }}
                      />
                    }
                  />
                </ListItem>
              ) : null}

              {/* Tagged employees */}
              <ListItem>
                <FormControlLabel
                  label={t('taggedCrewMembers')}
                  labelPlacement='start'
                  classes={{ label: classes.labelSize }}
                  control={
                    <Switch
                      checked={taggedProfiles}
                      onChange={() => changeTaggedProfilesStatus()}
                      size='small'
                      classes={{
                        switchBase: classes.switchBase,
                        colorPrimary: classes.switchPrimary
                      }}
                    />
                  }
                />
              </ListItem>

              {/* Last agency applicants */}
              <ListItem>
                <FormControlLabel
                  label={t('lastAgencyApplicants')}
                  labelPlacement='start'
                  classes={{ label: classes.labelSize }}
                  control={
                    <Switch
                      checked={lastAgencyApplicants}
                      onChange={() => changeLastAgencyApplicantsStatus()}
                      size='small'
                      classes={{
                        switchBase: classes.switchBase,
                        colorPrimary: classes.switchPrimary
                      }}
                    />
                  }
                />
              </ListItem>

              {/* Last online applicants */}
              <ListItem>
                <FormControlLabel
                  label={t('lastOnlineApplicants')}
                  labelPlacement='start'
                  classes={{ label: classes.labelSize }}
                  control={
                    <Switch
                      checked={lastOnlineApplicants}
                      onChange={() => changeLastOnlineApplicantsStatus()}
                      size='small'
                      classes={{
                        switchBase: classes.switchBase,
                        colorPrimary: classes.switchPrimary
                      }}
                    />
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userData: state.agencyUserDataRed.obj
})

export default connect(mapStateToProps)(CrewDashboardSettings)
