const initialState = {
  bool: false
}

const loadingDataRed = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_DATA': {
      return {
        ...state,
        bool: action.bool
      }
    }
    default: {
      return state
    }
  }
}

export default loadingDataRed
