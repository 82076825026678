import React from 'react'
import './loader.scss'

const Loader = () => {
    return (
        <div className='loader'>
            <div className='loader_spinner'></div>
        </div>
    )
}
 
export default Loader