const initialState = {
  obj: {
    firstName: '',
    lastName: '',
    position: '',
    status: '',
    id: ''
  }
}

const selectedEmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECTED_EMPLOYEE' : {
      return {
        ...state,
        obj: action.obj
      }
    }
    default: {
      return state
    }
  }
}

export default selectedEmployeeReducer
