import { useTranslation } from 'react-i18next'
import BlockCard from './BlockCard'
import { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  TextField,
  Tooltip
} from '@mui/material'
import firebase from 'firebase/app'
import ComponentLoader from '../../../../../Components/ComponentLoader'
import { v4 as uuid } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTasks } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import './ApplicationStages.scss'

const db = firebase.firestore()

interface Props {
  agencyData: any
}

const ApplicationStatges = ({ agencyData }: Props) => {
  const { t } = useTranslation()

  const [stagesList, setStagesList] = useState<RecruitmentApplicationStages[]>(
    []
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [refreshData, setRefreshData] = useState<boolean>(false)

  const [editMode, setEditMode] = useState<boolean>(false)

  const [showNewEditStageDialog, setShowNewEditStageDialog] =
    useState<boolean>(false)

  const [title, setTitle] = useState<string>('')
  const [titleError, setTitleError] = useState<boolean>(false)
  const [color, setColor] = useState<string>('#215384')

  const [selectedStageId, setSelectedStageId] = useState<string>('')

  useEffect(() => {
    let shouldUpdate = true

    db.collection('agencies')
      .doc(agencyData.id)
      .get()
      .then((snap) => {
        let data: any = JSON.stringify(snap.data())
        data = JSON.parse(data)

        const settingsStagesList: RecruitmentApplicationStages[] | undefined =
          data.data.settings.companySettings?.applicationStages

        if (settingsStagesList !== undefined && shouldUpdate) {
          setStagesList(settingsStagesList)
        }
      })
      .catch((error) => console.error(error))

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, refreshData])

  const closeNewEditStageDialog = () => {
    setShowNewEditStageDialog(false)
    setEditMode(false)
    setTitle('')
    setTitleError(false)
    setColor('#215384')
    setSelectedStageId('')
  }

  const addStage = async () => {
    if (title !== '') {
      setTitleError(false)
      setLoading(true)

      const newStagesList: RecruitmentApplicationStages[] = [
        ...stagesList,
        {
          _id: uuid(),
          title,
          color,
          timestamp: new Date()
        }
      ]

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .update({
          'data.settings.companySettings.applicationStages': newStagesList
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
          closeNewEditStageDialog()
        })
        .catch((error) => console.error(error))
    } else {
      setTitleError(true)
    }
  }

  const openEditStage = (stage: RecruitmentApplicationStages) => {
    setTitle(stage.title)
    setColor(stage.color)
    setEditMode(true)
    setSelectedStageId(stage._id)
    setShowNewEditStageDialog(true)
  }

  const updateStage = async () => {
    if (title !== '') {
      setTitleError(false)
      setLoading(true)

      /** Create new stage list with updated data */
      let newStagesList: RecruitmentApplicationStages[] = stagesList
      /** Find selected stage index */
      const index = stagesList.findIndex((el) => el._id === selectedStageId)

      /** Update data */
      newStagesList[index].title = title
      newStagesList[index].color = color

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .update({
          'data.settings.companySettings.applicationStages': newStagesList
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
          closeNewEditStageDialog()
        })
        .catch((error) => console.error(error))
    } else {
      setTitleError(true)
    }
  }

  const deleteStage = async () => {
    if (title !== '') {
      setTitleError(false)
      setLoading(true)

      /** Create new stage list with removed stage */
      let newStagesList: RecruitmentApplicationStages[] = stagesList
      /** Find selected stage index */
      const index = stagesList.findIndex((el) => el._id === selectedStageId)

      if (index > -1) {
        newStagesList.splice(index, 1)
      }

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .update({
          'data.settings.companySettings.applicationStages': newStagesList
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
          closeNewEditStageDialog()
        })
        .catch((error) => console.error(error))
    } else {
      setTitleError(true)
    }
  }

  return (
    <>
      {/* New or Edit stage dialog */}
      <Dialog open={showNewEditStageDialog} fullWidth maxWidth='sm'>
        {loading && <ComponentLoader />}

        <DialogTitle>{editMode ? t('edit') : t('add')}</DialogTitle>

        <DialogContent>
          <Grid container style={{ marginTop: 0 }} spacing={2}>
            {/* Title */}
            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label={t('title')}
                type='text'
                variant='outlined'
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                error={titleError}
              />
            </Grid>

            {/* Color */}
            <Grid item xs={3}>
              <TextField
                size='small'
                fullWidth
                label={t('color')}
                type='color'
                variant='outlined'
                value={color}
                onChange={(event) => setColor(event.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Delete button */}
          {editMode && (
            <Button size='small' color='error' onClick={() => deleteStage()}>
              {t('delete')}
            </Button>
          )}

          {/* Update button */}
          {editMode && (
            <Button
              size='small'
              variant='contained'
              color='orange'
              onClick={() => updateStage()}
            >
              {t('update')}
            </Button>
          )}

          {/* Add button */}
          {!editMode && (
            <Button
              size='small'
              variant='contained'
              color='orange'
              onClick={() => addStage()}
            >
              {t('add')}
            </Button>
          )}

          {/* Cancel button */}
          <Button size='small' onClick={() => closeNewEditStageDialog()}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <BlockCard title={t('application_stages')} avatar={faTasks as IconProp}>
        {/* Show text when there is no applicationstages created */}
        {stagesList.length === 0 && (
          <div
            style={{
              textAlign: 'center',
              fontSize: 14,
              margin: '20px 0 50px',
              opacity: 0.8
            }}
          >
            {t('there_is_no_created_stages')}
          </div>
        )}

        {/* List of added stages */}
        {stagesList.length > 0 && (
          <List style={{ marginBottom: 20 }}>
            {stagesList.map((stage) => (
              <ListItem
                key={stage._id}
                style={{ justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* Color */}
                  <div
                    style={{
                      backgroundColor: stage.color,
                      width: 20,
                      height: 15,
                      marginRight: 10,
                      borderRadius: 5
                    }}
                  />

                  {/* Title */}
                  {stage.title}
                </div>

                {/* Edit button */}
                <Tooltip title={t('edit')} placement='top'>
                  <div className='icon' onClick={() => openEditStage(stage)}>
                    <FontAwesomeIcon icon={faEdit as IconProp} />
                  </div>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        )}

        {/* Add stage button */}
        <div
          style={{
            textAlign: 'center'
          }}
        >
          <Button
            color='orange'
            variant='contained'
            size='small'
            onClick={() => setShowNewEditStageDialog(true)}
          >
            {t('add')}
          </Button>
        </div>
      </BlockCard>
    </>
  )
}

export default ApplicationStatges
