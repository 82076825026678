// Handle email verification, password resset

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Headline from '../Components/Headline'
import Loader from '../Components/Loader'
import firebase from 'firebase/app'
import {
  Button,
  Alert,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Snackbar
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { systemColors, systemStylingSettings } from '../globalVariables'

const auth = firebase.auth()

const useStyles = makeStyles({
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonRoot: {
    color: '#ffffff'
  },
  buttonText: {
    color: '#ffffff'
  },
  cardTitle: {
    fontSize: 16,
    textAlign: 'center'
  }
})

const System = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [dataLoaded, setDataLoaded] = useState(true)
  const [verifyEmailMode, setVerifyEmailMode] = useState(false)
  const [resetPasswordMode, setResetPasswordMode] = useState(false)
  const [loading, setLoading] = useState(false)

  const [emailVerified, setEmailVerified] = useState(false)
  const [verificationError, setVerificationError] = useState(false)
  const [url, setUrl] = useState('')
  const [actionCodeGlobal, setActionCodeGlobal] = useState('')

  // Reset password inputs
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordRepeat, setnewPasswordRepeat] = useState('')
  const [newPasswordError, setNewPasswordError] = useState(false)

  const [message, setMessage] = useState({
    status: false,
    duration: 3000,
    severity: 'success',
    message: ''
  })

  const [updatedPassword, setUpdatedPassword] = useState(false)

  useEffect(() => {
    let shouldUpdate = true
    const verifyEmail = () => {
      const urlParams = window.location.search
      const params = new URLSearchParams(urlParams)
      const mode = params.get('mode')
      const actionCode = params.get('oobCode')
      const continueUrl = params.get('continueUrl')

      if (shouldUpdate) {
        setUrl(continueUrl)
        setActionCodeGlobal(actionCode)
      }

      if (mode === 'verifyEmail') {
        // Change document title
        document.title = `${t('verifyEmail')} - Seanor`
        if (shouldUpdate) {
          setVerifyEmailMode(true)
        }

        auth
          .applyActionCode(actionCode)
          .then((response) => {
            if (shouldUpdate) {
              setDataLoaded(false)
              setEmailVerified(true)
            }
          })
          .catch((error) => {
            console.log("Can't verify email", error)
            if (shouldUpdate) {
              setDataLoaded(false)
              setVerificationError(true)
            }
          })
      } else if (mode === 'resetPassword') {
        // Change document title
        document.title = `${t('resetPassword')} - Seanor`
        if (shouldUpdate) {
          setResetPasswordMode(true)
          setDataLoaded(false)
        }
      }
    }

    verifyEmail()
    return () => {
      shouldUpdate = false
    }
  }, [t])

  const resetPassword = () => {
    setLoading(true)
    if (newPassword === newPasswordRepeat) {
      auth
        .verifyPasswordResetCode(actionCodeGlobal)
        .then((email) => {
          auth
            .confirmPasswordReset(actionCodeGlobal, newPassword)
            .then(() => {
              setUpdatedPassword(true)
              setLoading(false)
            })
            .catch((error) => {
              setLoading(false)
              if (error.code === 'auth/weak-password') {
                setMessage({
                  status: true,
                  duration: 5000,
                  severity: 'warning',
                  message: t('toWeekPasswordMustBe6Characters')
                })
              }
            })
        })
        .catch((error) => {
          console.log("Can't reset password", error)
          setLoading(false)
          setMessage({
            status: true,
            duration: 5000,
            severity: 'warning',
            message: t(
              'yourLinkIsExpiredOrAlreadyUsedPleasebackToYourAccountAndResetPass'
            )
          })
        })

      setNewPasswordError(false)
    } else {
      setNewPasswordError(true)
      setLoading(false)
      setMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('passwordsDoesntMatch')
      })
    }
  }

  // Continue when password reseted
  const proceed = () => {
    if (window.locationindexedDB !== undefined) {
      const request = window.locationindexedDB.deleteDatabase(
        'firebaseLocalStorageDb'
      )
      request.onsuccess = () => {
        console.log('IndexDb cleared')
        window.open(url)
      }
      request.onerror = () => {
        console.log("Can't clear IndexDb")
        window.open(url)
      }
      request.onblocked = () => {
        console.log('IndexDb clearing operation was blocked')
        window.open(url)
      }
    } else {
      window.open(url)
    }
  }

  if (!dataLoaded) <Loader />

  return (
    <>
      {loading && <Loader />}
      <Headline
        text={
          verifyEmailMode
            ? t('verifyEmail')
            : resetPasswordMode
            ? t('resetPassword')
            : ''
        }
      />

      {/* Alert */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      {/* Email verify mode */}
      {verifyEmailMode && (
        <div style={{ margin: '50px 20px', textAlign: 'center' }}>
          {emailVerified && (
            <div>{t('emailWasSuccessfullyVerifiedYouCanContinue')}</div>
          )}

          {verificationError && (
            <div>
              {t('yourLinkIsExpiredOrAlreadyUsedPleasebackToYourAccountAnd')}
            </div>
          )}
          <Button
            variant='contained'
            size='small'
            style={{
              marginTop: 30
            }}
            color='orange'
            onClick={() => proceed()}
          >
            {t('continue')}
          </Button>
        </div>
      )}

      {/* Reset password mode */}
      {resetPasswordMode && (
        <div
          style={{ display: 'flex', marginTop: 50, justifyContent: 'center' }}
        >
          {updatedPassword ? (
            <div style={{ textAlign: 'center' }}>
              <div>
                {t('yourPasswordWasResetedNowYouCanLoginWithNewPassword')}
              </div>
              <Button
                variant='contained'
                size='small'
                style={{
                  marginTop: 30
                }}
                color='orange'
                onClick={() => window.open(url)}
              >
                {t('continue')}
              </Button>
            </div>
          ) : (
            <Card style={{ maxWidth: 300, textAlign: 'center' }}>
              <CardHeader
                classes={{ title: classes.cardTitle }}
                title={t('resetPassword')}
              />
              <CardContent>
                <TextField
                  size='small'
                  variant='outlined'
                  type='password'
                  label={t('newPassword')}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fullWidth
                  error={newPasswordError}
                />
                <TextField
                  size='small'
                  style={{ margin: '20px 0' }}
                  variant='outlined'
                  type='password'
                  label={t('repeatPassword')}
                  value={newPasswordRepeat}
                  onChange={(e) => setnewPasswordRepeat(e.target.value)}
                  fullWidth
                  error={newPasswordError}
                />

                <Button
                  className='editEmployee__buttons-button'
                  variant='contained'
                  size='small'
                  color='orange'
                  onClick={() => resetPassword()}
                >
                  {t('reset')}
                </Button>
              </CardContent>
            </Card>
          )}
        </div>
      )}
    </>
  )
}

export default System
