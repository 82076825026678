import { Card, CardContent, CardHeader } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import {
  systemColors,
  systemStylingSettings
} from '../../../../../globalVariables'

const useStyles = makeStyles({
  card: {
    width: 'fit-content',
    maxWidth: 500,
    height: '100%'
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: systemColors.darkBlue
  },
  cardHeader: {
    backgroundColor: systemColors.background,
    border: '1px solid #d6d6d6',
    borderBottom: `2px solid ${systemColors.orange}`
  },
  removeCardIcon: {
    padding: '10px 6px 0 0px',
    cursor: 'pointer',
    color: systemColors.grey,
    '&:hover': {
      color: systemColors.darkGrey
    }
  },
  tableHeader: {
    fontWeight: 600
  },
  selectRoot: {
    '& .MuiOutlinedInput-input': {
      padding: 8,
      backgroundColor: systemColors.inputColor
    }
  },
  formControlRoot: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    }
  }
})

const PrintAgentsAddedEmployees = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { search } = useLocation()
  const data = new URLSearchParams(search).get('data')
  const type = new URLSearchParams(search).get('type')
  const duration = new URLSearchParams(search).get('duration')

  const chartData = JSON.parse(data)

  setTimeout(() => {
    window.print()
  }, 1000)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <Card
        classes={{ root: classes.card }}
        style={{
          marginTop: 50,
          marginBottom: 50,
          maxHeight: '700px'
        }}
      >
        <CardHeader
          classes={{ root: classes.cardHeader, title: classes.title }}
          title={t('agentsAddedEmployees')}
        />
        <CardContent
          style={{
            position: 'relative',
            width: 'fit-content',
            marginRight: 20,
            marginBottom: 20,
            maxHeight: '600px',
            height: '100%'
          }}
        >
          {/* Chart selected options */}
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div>{t(type)}</div>
            <div>{t(duration)}</div>
          </div>

          {/* Chart */}
          <ResponsiveContainer
            width={450}
            height='100%'
            style={{ marginTop: 30 }}
          >
            <BarChart
              data={chartData}
              layout='vertical'
              margin={{ top: 20, right: 0, bottom: 0, left: 20 }}
            >
              <CartesianGrid strokeDasharray='1 1' />
              <XAxis type='number' />
              <YAxis
                type='category'
                dataKey='name'
                width={120}
                tick={{ dx: -10, fill: systemColors.darkBlue }}
              />
              <Tooltip />
              <Bar
                dataKey='number'
                name={t('profiles')}
                fill={systemColors.lightElectric}
                barSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </div>
  )
}

export default PrintAgentsAddedEmployees
