import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import Loader from '../../../Components/Loader'
import { MenuItem, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {
  MONTH_NAME,
  systemColors,
  systemStylingSettings
} from '../../../globalVariables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { showEditEmployeeMessage } from '../../../redux/actions/companies/showEditEmployeeMessage'
import { selectedEmployee } from '../../../redux/actions/companies/selectedEmployee'
import { dataFromSmartPlanning } from '../../../redux/actions/companies/dataFromSmartPlanning'
import { projectIdSmartPlanning } from '../../../redux/actions/companies/projectIdSmartPlanning'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

const SmartPlanning = ({
  companyId,
  projectId,
  agencyData,
  projectName,
  setOpenEmployeeEditBlock,
  setSelectedEmployeeData,
  setDataFromSmartPlanning,
  setProjectIdSmartPlanning,
  refreshDataRedux
}) => {
  const { t } = useTranslation()

  const [loadingData, setLoadingData] = useState(true)

  const [allEmployees, setAllEmployees] = useState({})
  const [allProjects, setAllProjects] = useState([])

  const [selectedProjectId, setSelectedProjectId] = useState(projectId)

  const [showCompactMode, setShowCompactMode] = useState(false)

  const [daysOfDateRange, setDaysOfDateRange] = useState([])

  const [profilePhotoUrlArray, setProfilePhotoUrlArray] = useState([])

  const [positionsArray, setPositionsArray] = useState([])

  let compactPosition = 2

  const [selectedDisplayRange, setSelectedDisplayRange] = useState('oneMonth')

  useEffect(() => {
    let shouldUpdate = true
    const getData = () => {
      const getAllEmployeese = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .collection('projects')
          .doc(selectedProjectId)
          .collection('employees')
          .get()
          .then((response) => {
            let array = []
            response.forEach((el) => {
              let data = JSON.stringify(el.data())
              data = JSON.parse(data)
              if (data.status !== 'archive') {
                array = [...array, data]
              }
            })
            if (shouldUpdate) {
              getDatesBetweenStartAndFinishDates(array)
            }

            // Get profile photos urls
            const urlList = array.map((obj) => {
              return db
                .collection('agencies')
                .doc(agencyData.id)
                .collection('employees')
                .doc(obj.documentId)
                .get()
                .then((response) => {
                  let data = JSON.stringify(response.data())
                  data = JSON.parse(data)

                  if (
                    data.profilePhoto !== undefined &&
                    data.profilePhoto !== '' &&
                    data.profilePhoto !== null
                  ) {
                    // eslint-disable-next-line
                    return new Promise((resolve) => {
                      storageRef
                        .child(
                          `agencies/${agencyData.id}/users-forms-uploads/${data.docId}/${data.profilePhoto}`
                        )
                        .getDownloadURL()
                        .then((url) => {
                          resolve({
                            id: data.docId,
                            url
                          })
                        })
                        .catch((error) => {
                          console.log(error)
                          resolve({
                            id: data.docId,
                            url: ''
                          })
                        })
                    })
                  } else {
                    resolve({
                      id: obj.docId,
                      url: ''
                    })
                  }
                })
                .catch((error) => {
                  console.log(error)
                  resolve({
                    id: obj.docId,
                    url: ''
                  })
                })
            })
            Promise.all(urlList)
              .then((res) => {
                if (shouldUpdate) {
                  setProfilePhotoUrlArray(res)
                }
              })
              .catch((error) => console.log(error))

            // Arrange employeese by position
            const employeesListsObject = array.reduce((accumulator, el) => {
              accumulator[el.position] = accumulator[el.position] || []
              accumulator[el.position].push(el)
              return accumulator
            }, Object.create(null))

            if (shouldUpdate) {
              setAllEmployees(employeesListsObject)
            }
            resolve()
          })
          .catch((error) => {
            console.log("Can't get all projects eployeese", error)
            resolve()
          })
      })

      const getAllProjects = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .collection('projects')
          .get()
          .then((response) => {
            let array = []
            response.forEach((el) => {
              let data = JSON.stringify(el.data())
              data = JSON.parse(data)

              if (data.status !== 'archive') {
                array = [...array, data]
              }
            })
            if (shouldUpdate) {
              setAllProjects(array)
            }
            resolve()
          })
          .catch((error) => {
            console.log("Can't get all projects", error)
            resolve()
          })
      })

      const getPositionsList = new Promise((resolve) => {
        db.collection('lists')
          .doc('positions')
          .get()
          .then((response) => {
            let data = JSON.stringify(response.data())
            data = JSON.parse(data)
            if (shouldUpdate) {
              setPositionsArray(data.list)
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      Promise.all([getAllEmployeese, getAllProjects, getPositionsList])
        .then(() => {
          if (shouldUpdate) {
            setLoadingData(false)
          }
        })
        .catch((error) => {
          console.log(error)
          if (shouldUpdate) {
            setLoadingData(false)
          }
        })
    }

    getData()

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, companyId, projectId, selectedProjectId, refreshDataRedux])

  const getDatesBetweenStartAndFinishDates = (array) => {
    let startDate = ''
    let finishDate = ''

    array.forEach((el) => {
      if (startDate === '') {
        startDate = el.startOnContract
      }

      if (finishDate === '') {
        finishDate = el.endOfContract
      }

      if (new Date(el.startOnContract) < new Date(startDate)) {
        startDate = el.startOnContract
      }

      if (new Date(el.endOfContract) > new Date(finishDate)) {
        finishDate = el.endOfContract
      }
    })

    const startingDate = moment(startDate)
    const endingDate = moment(finishDate)
    const day = startingDate.clone().subtract(1, 'day')
    const daysArray = []

    while (day.isBefore(endingDate, 'day')) {
      daysArray.push(day.add(1, 'day').clone())
    }

    // Add additional days to get six months
    if (daysArray.length < 186) {
      const daysDifference = 186 - daysArray.length
      for (let i = 0; i < daysDifference; i++) {
        daysArray.push(day.add(1, 'day').clone())
      }
    }

    // Get last month of list and fill with remaining days
    const lastArrayItemIndex = daysArray.length - 1
    const lastMonthDayInArray = daysArray[lastArrayItemIndex].format('D')
    const daysInMonth = moment(daysArray[lastArrayItemIndex]).daysInMonth()
    const monthDaysDiggerent = daysInMonth - lastMonthDayInArray

    if (monthDaysDiggerent > 0) {
      for (let i = 0; i < monthDaysDiggerent; i++) {
        daysArray.push(day.add(1, 'day').clone())
      }
    }

    // Group dates by year
    const employeesListsObject = daysArray.reduce((accumulator, day) => {
      accumulator[day.format('YYYY')] = accumulator[day.format('YYYY')] || []
      accumulator[day.format('YYYY')].push(day)
      return accumulator
    }, Object.create(null))

    // Group dates by month
    Object.keys(employeesListsObject).map((key) => {
      const months = employeesListsObject[key].reduce((accumulator, day) => {
        accumulator[day.format('M')] = accumulator[day.format('M')] || []
        accumulator[day.format('M')].push(day)

        return accumulator
      }, Object.create(null))
      employeesListsObject[key] = months

      return employeesListsObject
    })

    setDaysOfDateRange(employeesListsObject)
  }

  const getProfilePhotoUrl = (id) => {
    let link = ''

    profilePhotoUrlArray.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  const getEmployeeOnTheDate = (key, day) => {
    const array = allEmployees[key]
    const calendarDay = day.format('YYYY-MM-DD')
    const calendarDayPointed = day.format('YYYY.MM.DD')

    const machedDay = array.find(
      (el) =>
        el.startOnContract === calendarDay ||
        el.startOnContract === calendarDayPointed
    )

    if (machedDay !== undefined) {
      const startDate = moment(machedDay.startOnContract)
      const endDate = moment(machedDay.endOfContract)
      const daysInRange = endDate.diff(startDate, 'days') + 1
      let blockWidth = daysInRange * 40

      // Change block width by selected display range
      if (selectedDisplayRange === 'oneMonth') {
        blockWidth = daysInRange * 39
      } else if (selectedDisplayRange === 'threeMonths') {
        blockWidth = daysInRange * 14
      } else if (selectedDisplayRange === 'sixMonths') {
        blockWidth = daysInRange * 6
      }

      // check is mached day a template and is it first on list
      let firstContractDate = ''

      array.forEach((elem) => {
        if (elem.draft) {
          if (firstContractDate === '') {
            firstContractDate = elem.startOnContract
          }

          if (new Date(elem.startOnContract) < new Date(firstContractDate)) {
            firstContractDate = elem.startOnContract
          }
        }
      })
      if (compactPosition === 2) {
        compactPosition = 1
      } else if (compactPosition === 1) {
        compactPosition = 2
      }

      return (
        <div
          className={
            machedDay.startOnContract === firstContractDate && machedDay.draft
              ? 'smartPlanning__calendar-block--orange'
              : machedDay.draft
              ? 'smartPlanning__calendar-block--yellow'
              : 'smartPlanning__calendar-block--green'
          }
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            width: blockWidth,
            height: 59,
            color: '#ffffff',
            borderRadius: systemStylingSettings.borderRadius,
            marginTop: showCompactMode && compactPosition === 2 ? 60 : 0
          }}
          onClick={() => {
            setOpenEmployeeEditBlock(true)
            setSelectedEmployeeData(machedDay)
            setDataFromSmartPlanning(true)
            setProjectIdSmartPlanning(projectId)
          }}
        >
          {/* Profile photo */}
          {getProfilePhotoUrl(machedDay.documentId) !== '' ? (
            <img
              style={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                marginLeft: 15,
                marginRight: 15
              }}
              src={getProfilePhotoUrl(machedDay.documentId)}
              alt='profile'
            />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: systemColors.lightElectric,
                width: 40,
                height: 40,
                borderRadius: '50%',
                marginLeft: 15,
                marginRight: 15
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: 20,
                  color: systemColors.background
                }}
                icon={faUser}
              />
            </div>
          )}

          <div
            className={
              blockWidth < 240
                ? 'smartPlanning__calendar-block--hideContet'
                : ''
            }
            style={{ textAlign: 'left' }}
          >
            <div className='global__capitalizeFirstLetter'>
              {machedDay.firstName} {machedDay.lastName}
            </div>
            <div>
              ({machedDay.startOnContract} - {machedDay.endOfContract})
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const getProjectName = (id) => {
    let name = ''
    allProjects.find((el) => {
      if (el !== undefined && el.projectId === id) {
        name = el.projectName
      }
      return name
    })
    return name
  }

  const getPositionName = (id) => {
    let name = ''
    positionsArray.find((el) => {
      if (el !== undefined && el.id === Number(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  if (loadingData) return <Loader />

  return (
    <>
      {/* Project name */}
      <div
        style={{
          textAlign: 'center',
          margin: '20px 0',
          fontSize: 18,
          fontWeight: 'bold'
        }}
      >
        {getProjectName(selectedProjectId)}
      </div>

      {/* Select project, select display range and change mode */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minWidth: '600px'
        }}
      >
        {/* Select project */}
        <TextField
          select
          variant='outlined'
          label={t('changeProject')}
          size='small'
          style={{ width: 200 }}
          value={selectedProjectId}
          onChange={(e) => {
            setSelectedProjectId(e.target.value)
          }}
        >
          {allProjects.map((el) => (
            <MenuItem key={el.projectId} value={el.projectId}>
              {el.projectName}
            </MenuItem>
          ))}
        </TextField>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Select display range */}
          <TextField
            select
            variant='outlined'
            label={t('displayRange')}
            size='small'
            style={{ width: 200, marginRight: 20 }}
            value={selectedDisplayRange}
            onChange={(e) => {
              setSelectedDisplayRange(e.target.value)
            }}
          >
            <MenuItem value='oneMonth'>{t('oneMonth')}</MenuItem>
            <MenuItem value='threeMonths'>{t('threeMonths')}</MenuItem>
            <MenuItem value='sixMonths'>{t('sixMonths')}</MenuItem>
          </TextField>

          {/* Display mode */}
          <div
            onClick={() => setShowCompactMode(!showCompactMode)}
            style={{ cursor: 'pointer' }}
          >
            {showCompactMode ? t('colapseMode') : t('compactMode')}
          </div>
        </div>
      </div>

      {/* Calendar */}
      {Object.keys(allEmployees).length !== 0 ? (
        <div>
          <div
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'scroll',
              marginTop: 20
            }}
          >
            {/* Position header */}
            <div
              style={{
                display: 'inline-block',
                border: `1px solid ${systemColors.grey}`,
                height: selectedDisplayRange === 'oneMonth' ? 82 : 80,
                width: 250,
                verticalAlign: 'top',
                backgroundColor: systemColors.darkBlue,
                color: '#ffffff'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                {t('positions')}
              </div>
            </div>

            {/* Years, months and days display */}
            {Object.keys(daysOfDateRange).map((year, index) => (
              <div key={index} className='smartPlanning__calendar-block'>
                <div className='smartPlanning__calendar-year'>{year}</div>
                <div>
                  {Object.keys(daysOfDateRange[year]).map((month, index) => (
                    <div key={index} className='smartPlanning__calendar-month'>
                      <span style={{ display: 'block', margin: '5px 0' }}>
                        {t(MONTH_NAME[month])}
                      </span>

                      {daysOfDateRange[year][month].map((day, index) => (
                        <div
                          key={index}
                          className={
                            selectedDisplayRange === 'oneMonth'
                              ? 'smartPlanning__calendar-day--oneMonth'
                              : selectedDisplayRange === 'threeMonths'
                              ? 'smartPlanning__calendar-day--threeMonths'
                              : selectedDisplayRange === 'sixMonths'
                              ? 'smartPlanning__calendar-day--sixMonths'
                              : 'smartPlanning__calendar-day'
                          }
                        >
                          {selectedDisplayRange === 'oneMonth' &&
                            day.format('D')}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ))}

            {/* Rows */}
            {Object.keys(allEmployees).map((key, index) => (
              <div
                key={index}
                className='smartPlanning__calendar-row'
                style={{
                  height: showCompactMode ? 120 : 60
                }}
              >
                {/* Position name */}
                <div
                  style={{
                    display: 'inline-block',
                    width: 250,
                    height: '100%',
                    borderRight: `1px solid ${systemColors.grey}`,
                    borderLeft: `1px solid ${systemColors.grey}`,
                    marginTop: '-1px',
                    verticalAlign: 'top'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 10,
                      height: '100%',
                      whiteSpace: 'normal'
                    }}
                  >
                    {getPositionName(key)}
                  </div>
                </div>

                {/* Dates */}
                {Object.keys(daysOfDateRange).map((year, index) => (
                  <div
                    key={index}
                    className='smartPlanning__calendar-block'
                    style={{
                      height: showCompactMode ? 120 : 60
                    }}
                  >
                    {Object.keys(daysOfDateRange[year]).map((month) =>
                      daysOfDateRange[year][month].map((day, index) => (
                        <div
                          key={index}
                          style={{
                            marginTop: '-1px',
                            padding: 0
                          }}
                          className={
                            selectedDisplayRange === 'oneMonth'
                              ? 'smartPlanning__calendar-day--oneMonth'
                              : selectedDisplayRange === 'threeMonths'
                              ? 'smartPlanning__calendar-day--threeMonths'
                              : selectedDisplayRange === 'sixMonths'
                              ? 'smartPlanning__calendar-day--sixMonths'
                              : 'smartPlanning__calendar-day'
                          }
                        >
                          {getEmployeeOnTheDate(key, day)}
                        </div>
                      ))
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div style={{ textAlign: 'center', margin: '40px 0' }}>
          {t('toSeeSmartPlanningPleaseAddEmployeesToProject')}
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    refreshDataRedux: state.refreshDataRed.bool
  }
}

const dispatchStateToProps = (dispatch) => ({
  setOpenEmployeeEditBlock: (bool) => dispatch(showEditEmployeeMessage(bool)),
  setSelectedEmployeeData: (object) => dispatch(selectedEmployee(object)),
  setDataFromSmartPlanning: (bool) => dispatch(dataFromSmartPlanning(bool)),
  setProjectIdSmartPlanning: (string) =>
    dispatch(projectIdSmartPlanning(string))
})

export default connect(mapStateToProps, dispatchStateToProps)(SmartPlanning)
