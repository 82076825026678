import React, { useEffect, useState } from 'react'
import {
  Step,
  Stepper,
  StepLabel,
  Grid,
  TextField,
  Alert,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  Snackbar,
  Tooltip,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  OutlinedInput,
  Box,
  Chip,
  ListItemText
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import Headline from '../../Components/Headline'
import Loader from '../../Components/Loader'
import {
  systemColors,
  systemStylingSettings,
  acceptedFileSize,
  workDisciplineArrVar,
  workDisciplineTypeArr,
  languageKnowlegeLevelsArr,
  driverLicenceArr,
  whenCanStartArr,
  howFindOutAboutUsArr,
  servicesInteresteIn,
  educationVariants,
  specialityOptions,
  languagesOptions
} from '../../globalVariables'
import { randomNumber } from '../../globalFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faTrash,
  faSyncAlt,
  faTimes,
  faInfoCircle,
  faCopy
} from '@fortawesome/free-solid-svg-icons'
import keyEmployeeDataClass from '../../classes/keyEmployeeDataClass'
import { keyEmployeeDataConverter } from '../../converters/keyEmployeeDataConverter'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { countries } from '../../countries'
import FileUploadLoader from '../../Components/FileUploadLoader/FileUploadLoader'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

const useStyle = makeStyles({
  stepperRoot: {
    backgroundColor: systemColors.background,
    margin: '40px 0'
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius,
      backgroundColor: systemColors.inputColor
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  selectRoot: {
    '& .MuiOutlinedInput-input': {
      padding: 8,
      backgroundColor: systemColors.inputColor
    }
  },
  formControlRoot: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    }
  },
  profilePhotoMargin: {
    marginTop: 50
  },
  section: {
    marginTop: 30
  },
  gridContainer: {
    marginBottom: 20
  },
  commentRoot: {
    backgroundColor: systemColors.inputColor
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: systemColors.inputColor,
      width: '100%',
      borderRadius: systemStylingSettings.borderRadius,
      '&.Mui-focused fieldset': {
        color: systemColors.darkBlue,
        borderColor: systemColors.darkBlue,
        borderWidth: systemStylingSettings.borderWidth
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 8
      }
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonText: {
    color: '#ffffff !important'
  },
  selectLabelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius,
      backgroundColor: systemColors.inputColor
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    }
  }
})

const KeyEmployeeCreateCV = ({ agencyData, userUID, agencyUserData }) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const reader = new FileReader()
  const history = useHistory()

  // Change document title
  document.title = `${t('addNewEmployee')} - Seanor`

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [selectedStep, setSelectedStep] = useState(0)
  const [loadedData, setLoadedData] = useState(false)

  const [savedData, setSavedData] = useState(false)
  const [documentId, setDocumentId] = useState('')
  const [uploadedFile, setUploadedFile] = useState(false)

  const [nationalityArr, setNationalityArr] = useState([])
  const [mergetPositionsList, setMergetPositionsList] = useState([])

  // INPUT STATES
  // Personal data
  const [appliedForOne, setAppliedForOne] = useState(null)
  const [appliedForTwo, setAppliedForTwo] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState('')
  const [mobilePhone, setMobilePhone] = useState('')
  const [email, setEmail] = useState('')
  const [familyStatus, setFamilyStatus] = useState('')
  const [healthStatus, setHealthStatus] = useState('')
  const [profilePhoto, setProfilePhoto] = useState('')
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('')
  const [profilePhotoSizeErr, setProfilePhotoSizeErr] = useState(false)
  const [nationality, setNationality] = useState('')
  const [nationalityOther, setNationalityOther] = useState('')
  const [showOtherNationalityInput, setShowOtherNationalityInput] =
    useState(false)
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [linkedInLink, setLinkedInLink] = useState('')

  // Work discipline
  const [workDisciplineArr, setWorkDisciplineArr] = useState([
    { id: randomNumber(10, true, false), discipline: '', type: '' }
  ])
  const [servicesInterestedIn, setServicesInterestedIn] = useState('')
  const [education, setEducation] = useState('')
  const [speciality, setSpeciality] = useState('')

  // Personal skills
  const [languageKnowlegeArr, setLanguageKnowlegeArr] = useState([
    { id: randomNumber(10, true, false), language: '', level: '' }
  ])
  const [driverLicence, setDriverLicence] = useState('')
  const [canDrive, setCanDrive] = useState('')
  const [continueLearn, setContinueLearn] = useState('')

  // Experience
  const [experienceArr, setExperienceArr] = useState([
    {
      id: randomNumber(10, true, false),
      workplace: '',
      position: '',
      country: '',
      dateFrom: '',
      dateTill: '',
      workOccupation: '',
      stillWorking: false
    }
  ])

  // Documents
  const [cvFileSizeErr, setCvFileSizeErr] = useState(false)
  const [certificatesFilesArr, setCertificatesFilesArr] = useState([
    {
      id: randomNumber(10, true, false),
      fileName: '',
      file: '',
      issueDate: '',
      expireDate: '',
      replaceDocument: false,
      fileSizeError: false
    }
  ])
  const [referenciesFilesArr, setReferenciesFilesArr] = useState([
    {
      id: randomNumber(10, true, false),
      fileName: '',
      file: '',
      replaceReference: false,
      fileSizeError: false
    }
  ])
  const [cvFileURL, setCvFileURL] = useState('')
  const [cvFileName, setcvFileName] = useState('')
  const [certificatesLinks, setCertificatesLinks] = useState([])
  const [referenciesLinks, setReferenciesLinks] = useState([])

  // Remarks
  const [comment, setComment] = useState('')
  const [whenCanStart, setWhenCanStart] = useState('')
  const [howFindOut, setHowFindOut] = useState('')

  // Errors states
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [mainPositionError, setMainPositionError] = useState(false)
  const [addressError, setAddressError] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [profilePhotoError, setprofilePhotoError] = useState(false)
  const [servicesInterestedInError, setServicesInterestedInError] =
    useState(false)
  const [educationError, setEducationError] = useState(false)
  const [specialityError, setSpecialityError] = useState(false)

  const [message, setMessage] = useState({
    status: false,
    duration: 3000,
    severity: 'success',
    message: ''
  })

  const [newCertificateMsg, setNewCertificateMsg] = useState(false)
  const [newReferenceMsg, setNewReferenceMsg] = useState(false)
  const [newDocumentData, setNewDocumentData] = useState({})

  const [mergetAvailableCertificates, setMergetAvailableCertificates] =
    useState([])
  const [availableCertificates, setAvailableCertificates] = useState([])

  const [uploadingCvStatus, setUploadingCvStatus] = useState(false)
  const [uploadingFile, setUploadingFile] = useState(false)
  const [uploadingFileId, setUploadingFileId] = useState('')

  useEffect(() => {
    let shouldUpdate = true

    if (shouldUpdate) {
      setLoadedData(false)
    }
    const getData = () => {
      let defaultPositions = []
      let agencyPositions = []
      let defaultAvailableCertificates = []
      let agencyAvailableCertificates = []

      const getNationalityList = new Promise((resolve) => {
        db.collection('lists')
          .doc('nationality')
          .get()
          .then((snap) => {
            let data = JSON.stringify(snap.data())
            data = JSON.parse(data)
            if (shouldUpdate) {
              setNationalityArr(data.list)
            }
            resolve()
          })
          .catch((error) => {
            console.log("Can't get nationality list", error)
            resolve()
          })
      })

      const getDefaultPositionsList = new Promise((resolve) => {
        db.collection('lists')
          .doc('key_positions')
          .get()
          .then((positions) => {
            let data = JSON.stringify(positions.data())
            data = JSON.parse(data)

            if (shouldUpdate) {
              defaultPositions = data.list
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      const getAgencyPositionsList = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('lists')
          .doc('positions')
          .get()
          .then((positions) => {
            if (positions.exists) {
              let data = JSON.stringify(positions.data())
              data = JSON.parse(data)

              if (shouldUpdate) {
                agencyPositions = data.list
              }
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      const getDefaultAvailableCertificatesList = new Promise((resolve) => {
        db.collection('lists')
          .doc('available_certificates')
          .get()
          .then((certificates) => {
            let data = JSON.stringify(certificates.data())
            data = JSON.parse(data)

            if (shouldUpdate) {
              defaultAvailableCertificates = data.list
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      const getAgencyAvailableCertificatesList = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('lists')
          .doc('available_certificates')
          .get()
          .then((certificates) => {
            if (certificates.exists) {
              let data = JSON.stringify(certificates.data())
              data = JSON.parse(data)

              if (shouldUpdate) {
                agencyAvailableCertificates = data.list
              }
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      Promise.all([
        getNationalityList,
        getDefaultPositionsList,
        getAgencyPositionsList,
        getDefaultAvailableCertificatesList,
        getAgencyAvailableCertificatesList
      ]).then(() => {
        if (shouldUpdate) {
          setMergetPositionsList([...defaultPositions, ...agencyPositions])
          setMergetAvailableCertificates([
            ...defaultAvailableCertificates,
            ...agencyAvailableCertificates
          ])
          setLoadedData(true)
        }
      })
    }

    getData()
    return () => {
      shouldUpdate = false
    }
  }, [agencyData, uploadedFile])

  // Add discipline inputs
  const addDisciplineInputs = (id, val, name) => {
    const newInput = workDisciplineArr.map((el) => {
      if (id === el.id) {
        if (name === 'discipline') {
          el[name] = val
        } else if (name === 'type') {
          el[name] = val
        }
      }
      return el
    })
    setWorkDisciplineArr(newInput)
  }

  // Remove discipline
  const removeDiscipline = (index) => {
    if (workDisciplineArr.length > 1) {
      const values = [...workDisciplineArr]
      values.splice(index, 1)
      setWorkDisciplineArr(values)
    }
  }

  // Add discipline
  const addDiscipline = () => {
    const blockId = randomNumber(10, true, false)
    setWorkDisciplineArr([
      ...workDisciplineArr,
      { id: blockId, discipline: '', type: '' }
    ])
  }

  // Add language knowledge inputs
  const addLanguageKnowledgeInputs = (id, val, name) => {
    const newInput = languageKnowlegeArr.map((el) => {
      if (id === el.id) {
        if (name === 'language') {
          el[name] = val
        } else if (name === 'level') {
          el[name] = val
        }
      }
      return el
    })
    setLanguageKnowlegeArr(newInput)
  }

  // Remove discipline
  const removeLanguageKnowledge = (index) => {
    if (languageKnowlegeArr.length > 1) {
      const values = [...languageKnowlegeArr]
      values.splice(index, 1)
      setLanguageKnowlegeArr(values)
    }
  }

  // Add discipline
  const addLanguageKnowledge = () => {
    const blockId = randomNumber(10, true, false)
    setLanguageKnowlegeArr([
      ...languageKnowlegeArr,
      { id: blockId, language: '', level: '' }
    ])
  }

  // Add experience inputs
  const addExperienceInput = (id, val, name) => {
    const newInput = experienceArr.map((el) => {
      if (id === el.id) {
        el[name] = val
      }
      return el
    })
    setExperienceArr(newInput)
  }

  // Remove experience
  const removeExperience = (index) => {
    if (experienceArr.length > 1) {
      const values = [...experienceArr]
      values.splice(index, 1)
      setExperienceArr(values)
    }
  }

  // Add experience
  const addExperience = () => {
    const blockId = randomNumber(10, true, false)
    setExperienceArr([
      ...experienceArr,
      {
        id: blockId,
        workplace: '',
        position: '',
        country: '',
        dateFrom: '',
        dateTill: '',
        workOccupation: '',
        stillWorking: false
      }
    ])
  }

  // Remove certificates and documents input
  const removeCertificateInput = async (index, file) => {
    const values = [...certificatesFilesArr]
    values.splice(index, 1)

    if (file === '') {
      setCertificatesFilesArr(values)
      setLoading(false)
    } else {
      await storage
        .ref(
          `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${file}`
        )
        .delete()
        .then(() => {
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('employees')
            .doc(documentId)
            .update({
              'documents.newCertificatesFilesArr': values
            })
            .then(() => {
              const getLinks = values.map((obj) => {
                if (obj.file !== null) {
                  // eslint-disable-next-line
                  return new Promise((res) => {
                    storageRef
                      .child(
                        `agencies/${agencyData.id}/users-forms-uploads/${documentId}/${obj.file}`
                      )
                      .getDownloadURL()
                      .then((url) => {
                        res({
                          id: obj.id,
                          url
                        })
                      })
                  })
                } else {
                  // eslint-disable-next-line
                  return
                }
              })
              Promise.all(getLinks).then((res) => {
                if (certificatesFilesArr.length === 0) {
                  setCertificatesFilesArr([
                    {
                      id: randomNumber(10, true, false),
                      fileName: '',
                      file: ''
                    }
                  ])
                }
                setCertificatesFilesArr(values)
                setCertificatesLinks(res)
                setMessage({
                  status: true,
                  duration: 3000,
                  severity: 'success',
                  message: t('fileDeletedSuccessfully')
                })
              })
            })
        })
        .catch((error) => {
          console.log("Can't delete certificate file from storage", error)
        })
    }
  }

  // Add certificates and documents input
  const addCertificateInput = () => {
    const blockId = randomNumber(10, true, false)
    setCertificatesFilesArr([
      ...certificatesFilesArr,
      {
        id: blockId,
        fileName: '',
        file: '',
        issueDate: '',
        expireDate: '',
        replaceDocument: false,
        fileSizeError: false
      }
    ])
  }

  // Add certificates and documents
  const addCertificate = async (id, val, name) => {
    const newInput = certificatesFilesArr.map((el) => {
      if (id === el.id) {
        if (name === 'file') {
          el[name] = val[0].name
          el.replaceDocument = false
          el.fileSizeError = false
        } else {
          el[name] = val
        }
      }
      return el
    })
    setCertificatesFilesArr(newInput)

    // Upload file to storage and update database
    if (name === 'file') {
      setUploadingFile(true)
      setUploadingFileId(id)
      await storage
        .ref(
          `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${val[0].name}`
        )
        .put(val[0])
        .then(() => {
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('employees')
            .doc(documentId)
            .update({
              'documents.newCertificatesFilesArr': newInput
            })
            .then(() => {
              const getLinks = newInput.map((obj) => {
                if (obj.file !== null && obj.file !== '') {
                  // eslint-disable-next-line
                  return new Promise((res) => {
                    storageRef
                      .child(
                        `agencies/${agencyData.id}/users-forms-uploads/${documentId}/${obj.file}`
                      )
                      .getDownloadURL()
                      .then((url) => {
                        res({
                          id: obj.id,
                          url
                        })
                      })
                  })
                } else {
                  // eslint-disable-next-line
                  return
                }
              })
              Promise.all(getLinks).then((res) => {
                setCertificatesLinks(res)
                setMessage({
                  status: true,
                  duration: 3000,
                  severity: 'success',
                  message: t('fileUploadedSuccessfully')
                })
                setUploadingFile(false)
              })
            })
            .catch((error) => console.error(error))
        })
        .catch((err) => {
          console.log({
            message: "Can't add certificate file to storage",
            error: err
          })
          setMessage({
            status: true,
            duration: 3000,
            severity: 'warning',
            message: t('fileWasntUploaded')
          })
        })
    }
  }

  // Remove referencies input
  const removeReferencyInput = async (index, file) => {
    const values = [...referenciesFilesArr]
    values.splice(index, 1)

    if (file === '') {
      setReferenciesFilesArr(values)
      setLoading(false)
    } else {
      await storage
        .ref(
          `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${file}`
        )
        .delete()
        .then(() => {
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('employees')
            .doc(documentId)
            .update({
              'documents.newReferenciesFilesArr': values
            })
            .then(() => {
              const getLinks = values.map((obj) => {
                if (obj.file !== null) {
                  // eslint-disable-next-line
                  return new Promise((res) => {
                    storageRef
                      .child(
                        `agencies/${agencyData.id}/users-forms-uploads/${documentId}/${obj.file}`
                      )
                      .getDownloadURL()
                      .then((url) => {
                        res({
                          id: obj.id,
                          url
                        })
                      })
                  })
                } else {
                  // eslint-disable-next-line
                  return
                }
              })
              Promise.all(getLinks).then((res) => {
                if (referenciesFilesArr.length === 0) {
                  setReferenciesFilesArr([
                    {
                      id: randomNumber(10, true, false),
                      fileName: '',
                      file: ''
                    }
                  ])
                }
                setReferenciesFilesArr(values)
                setReferenciesLinks(res)
                setMessage({
                  status: true,
                  duration: 3000,
                  severity: 'success',
                  message: t('fileDeletedSuccessfully')
                })
              })
            })
        })
        .catch((error) => {
          console.log("Can't delete reference file from storage", error)
        })
      setReferenciesFilesArr(values)
    }
  }

  // Add referencies input
  const addReferencyInput = () => {
    const blockId = randomNumber(10, true, false)
    setReferenciesFilesArr([
      ...referenciesFilesArr,
      {
        id: blockId,
        fileName: '',
        file: '',
        replaceReference: false,
        fileSizeError: false
      }
    ])
  }

  // Add referencies
  const addReferency = async (id, val, name) => {
    const newInput = referenciesFilesArr.map((el) => {
      if (id === el.id) {
        if (name === 'file') {
          el[name] = val[0].name
          el.replaceReference = false
          el.fileSizeError = false
        } else {
          el[name] = val
        }
      }
      return el
    })
    setReferenciesFilesArr(newInput)

    // Upload file to storage and update database
    if (name === 'file') {
      setUploadingFile(true)
      setUploadingFileId(id)
      await storage
        .ref(
          `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${val[0].name}`
        )
        .put(val[0])
        .then(() => {
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('employees')
            .doc(documentId)
            .update({
              'documents.newReferenciesFilesArr': newInput
            })
            .then(() => {
              const getLinks = newInput.map((obj) => {
                if (obj.file !== null && obj.file !== '') {
                  // eslint-disable-next-line
                  return new Promise((res) => {
                    storageRef
                      .child(
                        `agencies/${agencyData.id}/users-forms-uploads/${documentId}/${obj.file}`
                      )
                      .getDownloadURL()
                      .then((url) => {
                        res({
                          id: obj.id,
                          url
                        })
                      })
                  })
                } else {
                  // eslint-disable-next-line
                  return
                }
              })
              Promise.all(getLinks).then((res) => {
                setReferenciesLinks(res)
                setMessage({
                  status: true,
                  duration: 3000,
                  severity: 'success',
                  message: t('fileUploadedSuccessfully')
                })
                setUploadingFile(false)
              })
            })
        })
        .catch((err) => {
          console.log({
            message: "Can't add referency document to storage",
            error: err
          })
          setMessage({
            status: true,
            duration: 3000,
            severity: 'warning',
            message: t('fileWasntUploaded')
          })
        })
    }
  }

  // Submit data
  const submit = async () => {
    if (whenCanStart !== '' && howFindOut !== '') {
      setLoading(true)
      // Get all languages for search queries
      let filterLanguages = []
      languageKnowlegeArr.map(
        (el) => (filterLanguages = [...filterLanguages, el.language])
      )

      // Get all countries of experiences for search quires
      let filterCountries = []
      experienceArr.map(
        (el) => (filterCountries = [...filterCountries, el.country])
      )

      // Add data to database
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(documentId)
        .update({
          'documents.newCertificatesFilesArr': certificatesFilesArr,
          'documents.newReferenciesFilesArr': referenciesFilesArr,
          experienceArr: experienceArr,
          'personalData.address': address,
          'personalData.dateOfBirth': dateOfBirth,
          'personalData.email': email,
          'personalData.familyStatus': familyStatus,
          'personalData.firstName': firstName.toLowerCase(),
          'personalData.lastName': lastName.toLowerCase(),
          'personalData.healthStatus': healthStatus,
          'personalData.linkedInLink': linkedInLink,
          'personalData.mainPosition': appliedForOne,
          'personalData.mobilePhone': mobilePhone,
          'personalData.nationality':
            nationalityOther !== ''
              ? nationalityOther.toLowerCase()
              : nationality.toLowerCase(),
          'personalData.profilePhoto':
            profilePhoto[0] !== undefined ? profilePhoto[0].name : null,
          'personalData.secondPosition': appliedForTwo,
          'personalSkills.canDrive': canDrive,
          'personalSkills.continueLearn': continueLearn,
          'personalSkills.driverLicence': driverLicence,
          'personalSkills.filterLanguages': filterLanguages,
          'personalSkills.languageKnowlegeArr': languageKnowlegeArr,
          'remarks.comment': comment,
          'remarks.whenCanStart': whenCanStart,
          'remarks.howFindOut': howFindOut,
          'searchTags.countries': filterCountries,
          workDiscipline: workDisciplineArr,
          'workDisciplineOtherData.additionalRequirements.education': education,
          'workDisciplineOtherData.additionalRequirements.speciality':
            speciality,
          'workDisciplineOtherData.additionalRequirements.certificates':
            availableCertificates,
          'workDisciplineOtherData.servicesInterestedIn': servicesInterestedIn
        })
        .then(() => {
          if (profilePhoto[0] !== undefined) {
            storage
              .ref(
                `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${profilePhoto[0].name}`
              )
              .put(profilePhoto[0])
              .then(() => {
                setLoading(false)
                history.push(`/${agencyData.id}/dashboard/${userUID}`)
              })
              .catch((err) => {
                console.log({
                  message: "Can't add profile photo to storage",
                  error: err
                })
                setLoading(false)
                history.push(`/${agencyData.id}/dashboard/${userUID}`)
              })
          } else {
            setLoading(false)
            history.push(`/${agencyData.id}/dashboard/${userUID}`)
          }
        })
        .catch((err) => {
          console.log({ message: 'Add data error', error: err })
          setMessage({
            status: true,
            duration: 3000,
            severity: 'warning',
            message: t('unableToUpdateProfilePleaseContactToSupport')
          })
        })
    } else {
      setMessage({
        status: true,
        duration: 3000,
        severity: 'warning',
        message: t('mustfillAllrequiredFiels')
      })
    }
  }

  const personalDataNextButton = () => {
    if (
      appliedForOne !== null &&
      firstName !== '' &&
      lastName !== '' &&
      address !== '' &&
      mobilePhone !== '' &&
      email !== '' &&
      familyStatus !== '' &&
      healthStatus !== '' &&
      profilePhoto !== ''
    ) {
      setSelectedStep(selectedStep + 1)
      setMainPositionError(false)
      setFirstNameError(false)
      setLastNameError(false)
      setAddressError(false)
      setPhoneNumberError(false)
      setEmailError(false)
      setprofilePhotoError(false)
    } else {
      setMessage({
        status: true,
        duration: 3000,
        severity: 'warning',
        message: t('mustfillAllrequiredFiels')
      })
      if (appliedForOne === null) {
        setMainPositionError(true)
      } else {
        setMainPositionError(false)
      }
      if (firstName === '') {
        setFirstNameError(true)
      } else {
        setFirstNameError(false)
      }
      if (lastName === '') {
        setLastNameError(true)
      } else {
        setLastNameError(false)
      }
      if (address === '') {
        setAddressError(true)
      } else {
        setAddressError(false)
      }
      if (mobilePhone === '') {
        setPhoneNumberError(true)
      } else {
        setPhoneNumberError(false)
      }
      if (email === '') {
        setEmailError(true)
      } else {
        setEmailError(false)
      }

      if (profilePhoto === '') {
        setprofilePhotoError(true)
      } else {
        setprofilePhotoError(false)
      }
    }
  }

  const workdisciplineNextButton = () => {
    if (servicesInterestedIn !== '' && education !== '' && speciality !== '') {
      setSelectedStep(selectedStep + 1)
      setServicesInterestedInError(false)
      setEducationError(false)
      setSpecialityError(false)
    } else {
      setMessage({
        status: true,
        duration: 3000,
        severity: 'warning',
        message: t('mustfillAllrequiredFiels')
      })
      if (servicesInterestedIn === '') {
        setServicesInterestedInError(true)
      } else {
        setServicesInterestedInError(false)
      }
      if (education === '') {
        setEducationError(true)
      } else {
        setEducationError(false)
      }
      if (speciality === '') {
        setSpecialityError(true)
      } else {
        setSpecialityError(false)
      }
    }
  }

  const personalSkillsNextButton = () => {
    if (driverLicence !== '' && canDrive !== '' && continueLearn !== '') {
      setSelectedStep(selectedStep + 1)
    } else {
      setMessage({
        status: true,
        duration: 3000,
        severity: 'warning',
        message: t('mustfillAllrequiredFiels')
      })
    }
  }

  // Saving submited data to database and storage
  const experienceNextStep = () => {
    setLoading(true)
    if (!savedData) {
      // Set document number
      const docId = randomNumber(20, true, false)
      setDocumentId(docId)

      // Get all languages for search queries
      let filterLanguages = []
      languageKnowlegeArr.map(
        (el) => (filterLanguages = [...filterLanguages, el.language])
      )

      // Get all countries of experiences for search quires
      let filterCountries = []
      experienceArr.map(
        (el) => (filterCountries = [...filterCountries, el.country])
      )

      // Add data to database
      const addDataToDatabase = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(docId)
          .withConverter(keyEmployeeDataConverter)
          .set(
            new keyEmployeeDataClass(
              docId,
              userUID,
              new Date(),
              {
                mainPosition: appliedForOne,
                secondPosition: appliedForTwo,
                firstName: firstName.toLowerCase(),
                lastName: lastName.toLowerCase(),
                address,
                mobilePhone,
                email,
                familyStatus,
                healthStatus,
                profilePhoto:
                  profilePhoto[0] !== undefined ? profilePhoto[0].name : null,
                nationality:
                  nationalityOther !== ''
                    ? nationalityOther.toLowerCase()
                    : nationality.toLowerCase(),
                dateOfBirth,
                linkedInLink
              },
              workDisciplineArr,
              {
                languageKnowlegeArr,
                filterLanguages: filterLanguages,
                driverLicence,
                canDrive,
                continueLearn
              },
              experienceArr,
              {
                cvFile: null,
                newCertificatesFilesArr: [],
                newReferenciesFilesArr: []
              },
              {
                comment,
                whenCanStart,
                howFindOut
              },
              [],
              [],
              {
                servicesInterestedIn,
                additionalRequirements: {
                  education,
                  speciality,
                  certificates: availableCertificates
                }
              },
              [],
              {
                countries: filterCountries
              },
              'available',
              'active',
              new Date(),
              [],
              'online',
              {
                uid: agencyUserData.uid,
                firstName: agencyUserData.name,
                lastName: agencyUserData.lastName
              },
              [],
              {
                firstName: '',
                lastName: '',
                status: false,
                timestamp: null,
                uid: ''
              }
            )
          )
          .then(() => resolve(true))
          .catch((err) => {
            console.log({ message: 'Add data error', error: err })
            resolve(false)
          })
      })

      Promise.all([addDataToDatabase])
        .then((response) => {
          if (response[0] === true) {
            setLoading(false)
            setSavedData(true)
            setSelectedStep(selectedStep + 1)
          } else {
            setLoading(false)
            setMessage({
              status: true,
              duration: 3000,
              severity: 'warning',
              message: t('thereIsProblemWithDataSavingPleaseTryAgain')
            })
          }
        })
        .catch((err) => {
          console.log(err.message, err.error)
          setLoading(false)
        })
    } else {
      setLoading(false)
      setSelectedStep(selectedStep + 1)
    }
  }

  const uploadNewCertificate = () => {
    setLoading(true)
    const document = certificatesFilesArr.find(
      (el) => el.id === newDocumentData.id
    )

    const addDocument = () => {
      const updateDatabase = new Promise((resolve) => {
        const newUpdatedCertificatesList = certificatesFilesArr.map((el) => {
          if (el.id === newDocumentData.id) {
            el.file = newDocumentData.file[0].name
            el.replaceDocument = false
            el.fileSizeError = false
          }
          return el
        })

        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(documentId)
          .update({
            'documents.newCertificatesFilesArr': newUpdatedCertificatesList,
            date: new Date()
          })
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't update certificates", error: err })
            resolve()
          })
      })

      const uploadFile = new Promise((resolve) => {
        storage
          .ref(
            `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${newDocumentData.file[0].name}`
          )
          .put(newDocumentData.file[0])
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't upload file", error: err })
            resolve()
          })
      })

      Promise.all([updateDatabase, uploadFile])
        .then(() => {
          setNewCertificateMsg(false)
          setNewDocumentData({})
          setLoading(false)
          setUploadedFile(!uploadedFile)
          setMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('documentUploadSuccess')
          })
        })
        .catch((err) => {
          setNewCertificateMsg(false)
          console.log(err.message, err.err)
          setNewDocumentData({})
          setLoading(false)
          setMessage({
            status: true,
            duration: 15000,
            severity: 'warning',
            message: t('cantUploadDocument')
          })
        })
    }

    if (document && document.file !== '' && document.file !== null) {
      storageRef
        .child(
          `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${document.file}`
        )
        .delete()
        .then(() => addDocument())
        .catch((error) => {
          console.log("Can't delete old certificate file", error)
          if (error.code === 'storage/object-not-found') {
            addDocument()
          }
          setNewDocumentData({})
          setNewCertificateMsg(false)
          setLoading(false)
        })
    } else {
      addDocument()
    }
  }

  const uploadNewReference = () => {
    setLoading(true)
    setNewReferenceMsg(false)
    const document = referenciesFilesArr.find(
      (el) => el.id === newDocumentData.id
    )

    const addDocument = () => {
      const updateDatabase = new Promise((resolve) => {
        const newUpdatedReferences = referenciesFilesArr.map((el) => {
          if (el.id === newDocumentData.id) {
            el.file = newDocumentData.file[0].name
            el.replaceReference = false
            el.fileSizeError = false
          }
          return el
        })

        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(documentId)
          .update({
            'documents.newReferenciesFilesArr': newUpdatedReferences,
            date: new Date()
          })
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't update references", error: err })
            resolve()
          })
      })

      const uploadFile = new Promise((resolve) => {
        storage
          .ref(
            `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${newDocumentData.file[0].name}`
          )
          .put(newDocumentData.file[0])
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't upload file", error: err })
            resolve()
          })
      })

      Promise.all([updateDatabase, uploadFile])
        .then(() => {
          setNewDocumentData({})
          setLoading(false)
          setUploadedFile(!uploadedFile)
          setMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('documentUploadSuccess')
          })
        })
        .catch((err) => {
          console.log(err.message, err.err)
          setNewDocumentData({})
          setLoading(false)
          setMessage({
            status: true,
            duration: 15000,
            severity: 'warning',
            message: t('cantUploadDocument')
          })
        })
    }

    if (document && document.file !== '' && document.file !== null) {
      storageRef
        .child(
          `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${document.file}`
        )
        .delete()
        .then(() => addDocument())
        .catch((error) => {
          console.log("Can't delete old reference file", error)
          if (error.code === 'storage/object-not-found') {
            addDocument()
          }
          setNewDocumentData({})
          setLoading(false)
        })
    } else {
      addDocument()
    }
  }

  const uploadCvFile = async (file) => {
    setUploadingCvStatus(true)
    await storage
      .ref(
        `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${file[0].name}`
      )
      .put(file[0])
      .then(() => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(documentId)
          .update({
            'documents.cvFile': file[0].name
          })
          .then(() => {
            setcvFileName(file[0].name)
            storageRef
              .child(
                `agencies/${agencyData.id}/users-forms-uploads/${documentId}/${file[0].name}`
              )
              .getDownloadURL()
              .then((url) => {
                setCvFileURL(url)
                setUploadingCvStatus(false)
              })
              .catch((error) => {
                console.log("Can't get cv file url after file upload", error)
                setCvFileURL('')
              })

            setMessage({
              status: true,
              duration: 3000,
              severity: 'success',
              message: t('fileUploadedSuccessfully')
            })
          })
      })
      .catch((err) => {
        console.log({ message: "Can't add CV to storage", error: err })
        setMessage({
          status: true,
          duration: 3000,
          severity: 'warning',
          message: t('fileWasntUploaded')
        })
      })
  }

  const deleteCVFile = async () => {
    await storage
      .ref(
        `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${cvFileName}`
      )
      .delete()
      .then(() => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(documentId)
          .update({
            'documents.cvFile': ''
          })
          .then(() => {
            setcvFileName('')
            setCvFileURL('')
            setMessage({
              status: true,
              duration: 3000,
              severity: 'success',
              message: t('fileDeletedSuccessfully')
            })
          })
      })
      .catch((error) => {
        console.log("Can't delete cv file from storage", error)
      })
  }

  const getCertificateFileURL = (id) => {
    let link = ''
    certificatesLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  const getReferenceFileURL = (id) => {
    let link = ''
    referenciesLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  // Update state to show new file upload input
  const replaceDocumentWithNew = (index) => {
    const newValue = certificatesFilesArr
    newValue[index].replaceDocument = true
    setCertificatesFilesArr([...newValue])
  }

  // Update state to hide new file upload input
  const cancelDocumentReplaceWithNew = (index) => {
    const newValue = certificatesFilesArr
    newValue[index].replaceDocument = false
    setCertificatesFilesArr([...newValue])
  }

  // Update state to set file size error
  const setFileSizeError = (index) => {
    const newValue = certificatesFilesArr
    newValue[index].fileSizeError = true
    setCertificatesFilesArr([...newValue])
  }

  // Update state to show new file upload input
  const replaceReferenceWithNew = (index) => {
    const newValue = referenciesFilesArr
    newValue[index].replaceReference = true
    setReferenciesFilesArr([...newValue])
  }

  // Update state to hide new file upload input
  const cancelReferenceReplaceWithNew = (index) => {
    const newValue = referenciesFilesArr
    newValue[index].replaceReference = false
    setReferenciesFilesArr([...newValue])
  }

  // Update state to set file size error
  const setReferenceFileSizeError = (index) => {
    const newValue = referenciesFilesArr
    newValue[index].fileSizeError = true
    setReferenciesFilesArr([...newValue])
  }

  const handleAvailableCertificateInputCahnges = (event) => {
    const { value } = event.target

    const index = value.indexOf('addNewCertificate')
    if (index > -1) {
      value.splice(index, 1)
    }
    setAvailableCertificates(value)
  }

  /**
   * Add new experience line with parent experience data
   */
  const copyExperience = (parent) => {
    const blockId = randomNumber(10, true, false)
    setExperienceArr([
      ...experienceArr,
      {
        id: blockId,
        workplace: parent.workplace,
        country: parent.country,
        position: parent.position,
        dateFrom: parent.dateFrom,
        dateTill: parent.dateTill,
        workOccupation: parent.workOccupation,
        stillWorking: parent.stillWorking
      }
    ])
  }

  if (!loadedData) return <Loader />

  return (
    <>
      {loading && <Loader />}

      <Headline text={t('addNewEmployee')} />

      {/* Documents pop up */}
      <Dialog open={newCertificateMsg} classes={{ paper: classes.dialogRoot }}>
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>{t('uploadNewDocumentPopupMessage')}</DialogContent>
        <DialogActions>
          <Button onClick={() => uploadNewCertificate()}>
            {t('continue')}
          </Button>
          <Button
            onClick={() => {
              setNewCertificateMsg(false)
              setNewDocumentData({})
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Referencies pop up */}
      <Dialog open={newReferenceMsg} classes={{ paper: classes.dialogRoot }}>
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>{t('uploadNewDocumentPopupMessage')}</DialogContent>
        <DialogActions>
          <Button onClick={() => uploadNewReference()}>{t('continue')}</Button>
          <Button
            onClick={() => {
              setNewReferenceMsg(false)
              setNewDocumentData({})
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbars */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      {/* Stepper */}
      <div>
        <Stepper
          activeStep={selectedStep}
          alternativeLabel
          style={{ margin: '50px 0' }}
        >
          <Step>
            <StepLabel>{t('basicInfo')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('workSkills')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('personalSkills')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('experience')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('documents')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('coverLetterComments')}</StepLabel>
          </Step>
        </Stepper>
      </div>

      {/* Stepper content */}
      <div>
        {/* Personal data */}
        {selectedStep === 0 && (
          <div>
            {/* Positions section */}
            <Grid container style={{ marginBottom: 30 }} spacing={2}>
              {/* First position */}
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  options={mergetPositionsList}
                  getOptionLabel={(option) => t(option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size='small'
                      fullWidth
                      label={t('mainPosition')}
                      variant='outlined'
                      error={mainPositionError}
                    />
                  )}
                  value={appliedForOne}
                  onChange={(e, val) => {
                    setAppliedForOne(val)
                  }}
                />
              </Grid>

              {/* Second position */}
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  options={mergetPositionsList}
                  getOptionLabel={(option) => t(option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      fullWidth
                      label={t('secondPosition')}
                      variant='outlined'
                    />
                  )}
                  value={appliedForTwo}
                  onChange={(e, val) => {
                    setAppliedForTwo(val)
                  }}
                />
              </Grid>
            </Grid>

            {/* Personal data section */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('firstName')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  error={firstNameError}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('lastName')}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  error={lastNameError}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('address')}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  fullWidth
                  error={addressError}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='tel'
                  label={t('phone')}
                  placeholder='+370 ...'
                  value={mobilePhone}
                  onChange={(e) => setMobilePhone(e.target.value)}
                  fullWidth
                  error={phoneNumberError}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='email'
                  label={t('email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  error={emailError}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  label={t('familyStatus')}
                  size='small'
                  fullWidth
                  variant='outlined'
                  select
                  value={familyStatus}
                  onChange={(e) => setFamilyStatus(e.target.value)}
                >
                  <MenuItem value='married'>{t('married')}</MenuItem>
                  <MenuItem value='divorced'>{t('divorced')}</MenuItem>
                  <MenuItem value='other'>{t('other')}</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  label={t('healthStatus')}
                  size='small'
                  fullWidth
                  variant='outlined'
                  select
                  value={healthStatus}
                  onChange={(e) => setHealthStatus(e.target.value)}
                >
                  <MenuItem value='healthy'>{t('healthy')}</MenuItem>
                  <MenuItem value='partialDisability'>
                    {t('partialDisability')}
                  </MenuItem>
                  <MenuItem value='specialNeeds'>{t('specialNeeds')}</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  label={t('nationality')}
                  size='small'
                  fullWidth
                  variant='outlined'
                  select
                  value={nationality}
                  onChange={(e) => {
                    if (e.target.value === 'Other') {
                      setShowOtherNationalityInput(true)
                      setNationality(e.target.value)
                    } else {
                      setShowOtherNationalityInput(false)
                      setNationality(e.target.value)
                      setNationalityOther('')
                    }
                  }}
                >
                  {nationalityArr.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {showOtherNationalityInput && (
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('otherNationality')}
                    value={nationalityOther}
                    onChange={(e) => setNationalityOther(e.target.value)}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='date'
                  label={t('dateOfBirth')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('linkedInProfile')}
                  value={linkedInLink}
                  onChange={(e) => setLinkedInLink(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>

            {/* Profile photo upload */}
            <Grid
              container
              spacing={2}
              alignItems='center'
              justifyContent='center'
              classes={{ root: classes.profilePhotoMargin }}
            >
              <Grid item>
                {profilePhoto !== '' ? (
                  <img
                    className='vesselCrewForm__upload-photo-img'
                    src={profilePhotoUrl}
                    alt='profile'
                  />
                ) : (
                  <div className='photo-box'>{t('photo')}</div>
                )}
              </Grid>
              <Grid item>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='file'
                  label={t('profilePhoto')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      if (e.target.files[0].size > acceptedFileSize) {
                        setProfilePhotoSizeErr(true)
                      } else {
                        reader.onload = (e) => {
                          setProfilePhotoUrl(e.target.result)
                        }
                        reader.readAsDataURL(e.target.files[0])
                        setProfilePhoto(e.target.files)
                        setProfilePhotoSizeErr(false)
                      }
                    }
                  }}
                  fullWidth
                  inputProps={{
                    accept: '.png, .jpg, .jpeg'
                  }}
                  helperText={
                    profilePhotoSizeErr ? t('toBigFile') : t('acceptFormat')
                  }
                  error={profilePhotoSizeErr || profilePhotoError}
                />
                {profilePhoto !== '' && (
                  <div className='addNewEmployee__upload-msg'>
                    {t('selectedFile')}: {profilePhoto[0].name}
                  </div>
                )}
              </Grid>
            </Grid>

            {/* Stepper buttons */}
            <div className='addNewEmployee__stepper-buttons'>
              <div>
                <span
                  onClick={() => {
                    selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                  }}
                >
                  {t('back')}
                </span>
              </div>

              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => personalDataNextButton()}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        )}

        {/* Work skills */}
        {selectedStep === 1 && (
          <div>
            {workDisciplineArr.map((el, i) => {
              return (
                <Grid
                  key={el.id}
                  container
                  spacing={2}
                  classes={{ root: classes.gridContainer }}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      label={t('workSkills')}
                      size='small'
                      fullWidth
                      variant='outlined'
                      select
                      value={el.discipline}
                      onChange={(e) =>
                        addDisciplineInputs(el.id, e.target.value, 'discipline')
                      }
                    >
                      {workDisciplineArrVar.map((el, i) => {
                        return (
                          <MenuItem key={i} value={el}>
                            {t(el)}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      label={t('type')}
                      size='small'
                      fullWidth
                      variant='outlined'
                      select
                      value={el.type}
                      onChange={(e) =>
                        addDisciplineInputs(el.id, e.target.value, 'type')
                      }
                    >
                      {workDisciplineTypeArr.map((el, i) => {
                        return (
                          <MenuItem key={i} value={el}>
                            {t(el)}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Grid>

                  {/* Remove work skill */}
                  <Grid item xs={12} sm={6} md={3}>
                    <Tooltip
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('removeWorkSkills')}
                    >
                      <div
                        className='addNewEmployee__addRemove'
                        onClick={() => removeDiscipline(i)}
                      >
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faTrash}
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            })}

            <div
              className='addNewEmployee__addRemove'
              onClick={() => addDiscipline()}
            >
              <FontAwesomeIcon className='icon' icon={faPlus} />
              <span>{t('addWorkSkills')}</span>
            </div>

            <Grid container spacing={2} style={{ marginTop: 40 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  required
                  variant='outlined'
                  fullWidth
                  label={t('selectWhatOurSevicesYouAreInterested')}
                  size='small'
                  value={servicesInterestedIn}
                  onChange={(e) => {
                    setServicesInterestedIn(e.target.value)
                  }}
                  error={servicesInterestedInError}
                >
                  {servicesInteresteIn.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {/* Additional requirements */}
            <div
              style={{
                marginTop: 40,
                color: systemColors.lighGrey,
                fontSize: 17
              }}
            >
              {t('additionalRequirements')}
            </div>
            <Divider />
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  select
                  variant='outlined'
                  fullWidth
                  label={t('education')}
                  size='small'
                  value={education}
                  onChange={(e) => {
                    setEducation(e.target.value)
                  }}
                  error={educationError}
                >
                  {educationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  select
                  variant='outlined'
                  fullWidth
                  label={t('speciality')}
                  size='small'
                  value={speciality}
                  onChange={(e) => {
                    setSpeciality(e.target.value)
                  }}
                  error={specialityError}
                >
                  {specialityOptions.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Available certificates */}
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size='small' variant='outlined'>
                  <InputLabel id='markAvailableCrertificates'>
                    {t('markAvailableCrertificates')}
                  </InputLabel>
                  <Select
                    labelId='markAvailableCrertificates'
                    multiple
                    fullWidth
                    value={availableCertificates}
                    onChange={(e) => handleAvailableCertificateInputCahnges(e)}
                    input={
                      <OutlinedInput label={t('markAvailableCrertificates')} />
                    }
                    renderValue={(selected) => {
                      return (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 2,
                            width: 300
                          }}
                        >
                          {selected.map((value) => {
                            if (value !== 'addNewCertificate') {
                              return <Chip key={value} label={t(value)} />
                            } else {
                              return null
                            }
                          })}
                        </Box>
                      )
                    }}
                  >
                    {mergetAvailableCertificates.map((el, i) => {
                      if (el !== 'addNewCertificate') {
                        return (
                          <MenuItem key={i} value={el}>
                            <Checkbox
                              color='default'
                              classes={{ root: classes.checkbox }}
                              checked={availableCertificates.indexOf(el) > -1}
                            />
                            <ListItemText primary={t(el)} />
                          </MenuItem>
                        )
                      } else {
                        return (
                          <MenuItem key={i} value={el}>
                            {t(el)}
                          </MenuItem>
                        )
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* Stepper buttons */}
            <div className='addNewEmployee__stepper-buttons'>
              <div>
                <span
                  onClick={() => {
                    selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                  }}
                >
                  {t('back')}
                </span>
              </div>
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => workdisciplineNextButton()}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        )}

        {/* Personal skills */}
        {selectedStep === 2 && (
          <div>
            {/* Language knowledge */}
            <div className='addNewKeyEmployee__list-title'>
              {t('knowledgeOflanguage')}
            </div>
            {languageKnowlegeArr.map((el, i) => (
              <Grid
                container
                key={el.id}
                spacing={2}
                classes={{ root: classes.gridContainer }}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    variant='outlined'
                    fullWidth
                    label={t('language')}
                    size='small'
                    value={el.language}
                    onChange={(e) => {
                      addLanguageKnowledgeInputs(
                        el.id,
                        e.target.value,
                        'language'
                      )
                    }}
                  >
                    {languagesOptions.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label={t('level')}
                    size='small'
                    fullWidth
                    variant='outlined'
                    select
                    value={el.level}
                    onChange={(e) =>
                      addLanguageKnowledgeInputs(el.id, e.target.value, 'level')
                    }
                  >
                    {languageKnowlegeLevelsArr.map((el, i) => {
                      return (
                        <MenuItem key={i} value={el}>
                          {t(el)}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>

                {/* Remove language */}
                <Grid item xs={12} sm={6} md={3}>
                  <Tooltip
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      marginTop: 8,
                      cursor: 'pointer'
                    }}
                    placement='right-end'
                    title={t('removeLanguage')}
                  >
                    <div
                      className='addNewEmployee__addRemove'
                      onClick={() => removeLanguageKnowledge(i)}
                    >
                      <FontAwesomeIcon
                        style={{ color: systemColors.lighGrey }}
                        icon={faTrash}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}

            <div
              className='addNewEmployee__addRemove'
              onClick={() => addLanguageKnowledge()}
            >
              <FontAwesomeIcon className='icon' icon={faPlus} />
              <span>{t('addLanguage')}</span>
            </div>

            {/* Driver license */}
            <Grid container spacing={2} className={classes.section}>
              <Grid item xs={12} sm={6} md={3}>
                <div className='addNewKeyEmployee__title'>
                  {t('driverLicence')}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label={t('choose')}
                  size='small'
                  fullWidth
                  variant='outlined'
                  select
                  value={driverLicence}
                  onChange={(e) => setDriverLicence(e.target.value)}
                >
                  {driverLicenceArr.map((el, i) => {
                    return (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            </Grid>

            {/* Can drive */}
            <Grid container spacing={2} className={classes.section}>
              <Grid item xs={12} sm={6} md={3}>
                <div className='addNewKeyEmployee__title'>
                  {t('canYouDriveToWork')}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label={t('choose')}
                  size='small'
                  fullWidth
                  variant='outlined'
                  select
                  value={canDrive}
                  onChange={(e) => setCanDrive(e.target.value)}
                >
                  <MenuItem value='yes'>{t('yes')}</MenuItem>
                  <MenuItem value='no'>{t('no')}</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            {/* Plan to continue to learn */}
            <Grid container spacing={2} className={classes.section}>
              <Grid item xs={12} sm={6} md={3}>
                <div className='addNewKeyEmployee__title'>
                  {t('planToCintinueToLearn')}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label={t('choose')}
                  size='small'
                  fullWidth
                  variant='outlined'
                  select
                  value={continueLearn}
                  onChange={(e) => setContinueLearn(e.target.value)}
                >
                  <MenuItem value='yes'>{t('yes')}</MenuItem>
                  <MenuItem value='no'>{t('no')}</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            {/* Stepper buttons */}
            <div className='addNewEmployee__stepper-buttons'>
              <div>
                <span
                  onClick={() => {
                    selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                  }}
                >
                  {t('back')}
                </span>
              </div>
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => personalSkillsNextButton()}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        )}

        {/* Experience */}
        {selectedStep === 3 && (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 30
              }}
            >
              {t('noteAddAtLeastTwoLastWorkplaces')}
            </div>

            {experienceArr.map((el, i) => (
              <Grid
                container
                key={el.id}
                spacing={2}
                classes={{ root: classes.gridContainer }}
              >
                {/* Workplace */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('workplaceNameCity')}
                    value={el.workplace}
                    onChange={(e) =>
                      addExperienceInput(el.id, e.target.value, 'workplace')
                    }
                    fullWidth
                  />
                </Grid>

                {/* Country */}
                <Grid item xs={12} sm={6} md={1}>
                  <TextField
                    select
                    variant='outlined'
                    fullWidth
                    label={t('country')}
                    size='small'
                    value={el.country}
                    onChange={(e) => {
                      addExperienceInput(el.id, e.target.value, 'country')
                    }}
                  >
                    {countries.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Position */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    select
                    variant='outlined'
                    fullWidth
                    label={t('position')}
                    size='small'
                    value={el.position}
                    onChange={(e) => {
                      addExperienceInput(el.id, e.target.value, 'position')
                    }}
                  >
                    {mergetPositionsList.map((el, i) => {
                      if (el !== 'addNewPosition') {
                        return (
                          <MenuItem key={i} value={el}>
                            {t(el)}
                          </MenuItem>
                        )
                      } else {
                        return null
                      }
                    })}
                  </TextField>
                </Grid>

                {/* Date from */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='date'
                    InputLabelProps={{
                      shrink: true
                    }}
                    label={t('from')}
                    value={el.dateFrom}
                    onChange={(e) =>
                      addExperienceInput(el.id, e.target.value, 'dateFrom')
                    }
                    fullWidth
                  />
                </Grid>

                {/* Date till */}
                <Grid item xs={12} sm={6} md={2}>
                  {!el.stillWorking && (
                    <TextField
                      size='small'
                      variant='outlined'
                      type='date'
                      InputLabelProps={{
                        shrink: true
                      }}
                      label={t('till')}
                      value={el.dateTill}
                      onChange={(e) =>
                        addExperienceInput(el.id, e.target.value, 'dateTill')
                      }
                      fullWidth
                    />
                  )}
                  <div>
                    <Checkbox
                      color='default'
                      checked={el.stillWorking}
                      classes={{ root: classes.checkbox }}
                      onChange={(e) => {
                        addExperienceInput(
                          el.id,
                          !el.stillWorking,
                          'stillWorking'
                        )
                      }}
                    />
                    <span>{t('stillWorking')}</span>

                    {/* Info */}
                    <Tooltip
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('markIfYouStillWorkingOnThisPosition')}
                    >
                      <span>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faInfoCircle}
                        />
                      </span>
                    </Tooltip>
                  </div>
                </Grid>

                {/* Main responsibilities */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('mainResponsibilities')}
                    value={el.workOccupation}
                    multiline
                    rows={2}
                    onChange={(e) =>
                      addExperienceInput(
                        el.id,
                        e.target.value,
                        'workOccupation'
                      )
                    }
                    fullWidth
                  />
                </Grid>

                {/* Buttons */}
                <Grid item xs={12} sm={6} md={1}>
                  <div style={{ display: 'flex' }}>
                    {/*
                     * Remove
                     */}
                    <Tooltip
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('removeExperience')}
                    >
                      <div
                        className='addNewEmployee__addRemove'
                        onClick={() => removeExperience(i)}
                      >
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faTrash}
                        />
                      </div>
                    </Tooltip>

                    {/*
                     * Copy
                     */}
                    <Tooltip
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('copy_experience')}
                    >
                      <div
                        className='addNewEmployee__addRemove'
                        onClick={() => copyExperience(el)}
                      >
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faCopy}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            ))}

            <div
              className='addNewEmployee__addRemove'
              onClick={() => addExperience()}
            >
              <FontAwesomeIcon className='icon' icon={faPlus} />
              <span>{t('addExperience')}</span>
            </div>

            {/* Stepper buttons */}
            <div className='addNewEmployee__stepper-buttons'>
              <div>
                <span
                  onClick={() => {
                    selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                  }}
                >
                  {t('back')}
                </span>
              </div>
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => experienceNextStep()}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        )}

        {/* Documents */}
        {selectedStep === 4 && (
          <div>
            {/* CV upload */}
            <Grid
              container
              spacing={2}
              classes={{ root: classes.gridContainer }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <div>{t('uploadCv')}</div>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {cvFileURL === '' ? (
                  <div>
                    {!uploadingCvStatus && (
                      <TextField
                        size='small'
                        variant='outlined'
                        type='file'
                        label={t('upload')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(e) => {
                          const file = e.target.files
                          if (file.length > 0) {
                            if (file[0].size > acceptedFileSize) {
                              setCvFileSizeErr(true)
                            } else {
                              uploadCvFile(file)
                              setCvFileSizeErr(false)
                            }
                          }
                        }}
                        fullWidth
                        inputProps={{
                          accept: '.png, .jpg, .jpeg, .pdf'
                        }}
                        helperText={
                          cvFileSizeErr ? t('toBigFile') : t('acceptFormat2')
                        }
                        error={!!cvFileSizeErr}
                      />
                    )}

                    {/*
                     * Upload file status
                     */}
                    {uploadingCvStatus && <FileUploadLoader />}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <a
                      href={cvFileURL}
                      target='_blank'
                      rel='noopener noreferrer'
                      download
                    >
                      {t('download')}
                    </a>
                    <Tooltip
                      style={{ marginLeft: 20, cursor: 'pointer' }}
                      placement='right-end'
                      title={t('deleteDocument')}
                    >
                      <div onClick={() => deleteCVFile()}>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faTrash}
                        />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </Grid>
            </Grid>

            {/* Cerificates and diplomas upload */}
            <div
              className='addNewKeyEmployee__list-title'
              style={{ marginTop: 80 }}
            >
              {t('uploadCertificatesDiplomas')}
            </div>

            {certificatesFilesArr.map((el, i) => (
              <Grid
                container
                key={el.id}
                spacing={2}
                classes={{ root: classes.gridContainer }}
              >
                {/* File name */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('fileName')}
                    value={el.fileName}
                    onChange={(e) =>
                      addCertificate(el.id, e.target.value, 'fileName')
                    }
                    fullWidth
                  />
                </Grid>

                {/* Issue date */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='date'
                    label={t('issueDate')}
                    value={el.issueDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) =>
                      addCertificate(el.id, e.target.value, 'issueDate')
                    }
                    fullWidth
                  />
                </Grid>

                {/* Expire date */}
                <Grid item xs={12} sm={6} md={2} style={{ display: 'flex' }}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='date'
                    label={t('expireDate')}
                    value={el.expireDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) =>
                      addCertificate(el.id, e.target.value, 'expireDate')
                    }
                    fullWidth
                  />

                  <Tooltip
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      marginTop: 8,
                      cursor: 'pointer'
                    }}
                    placement='right-end'
                    title={t('ifExpireDateEmptyThanUnlimited')}
                  >
                    <div>
                      <FontAwesomeIcon
                        style={{ color: systemColors.lighGrey }}
                        icon={faInfoCircle}
                      />
                    </div>
                  </Tooltip>
                </Grid>

                {/* File upload */}
                <Grid item xs={12} sm={6} md={3} style={{ display: 'flex' }}>
                  {el.fileName !== '' && el.file === '' ? (
                    <TextField
                      size='small'
                      variant='outlined'
                      type='file'
                      label={t('upload')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        const file = e.target.files
                        if (file.length > 0) {
                          if (file[0].size > acceptedFileSize) {
                            setFileSizeError(i)
                          } else {
                            addCertificate(el.id, file, 'file')
                          }
                        }
                      }}
                      fullWidth
                      inputProps={{
                        accept: '.png, .jpg, .jpeg, .pdf'
                      }}
                      helperText={
                        el.fileSizeError ? t('toBigFile') : t('acceptFormat2')
                      }
                      error={el.fileSizeError}
                    />
                  ) : el.fileName !== '' && el.replaceDocument !== true ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {uploadingFile && uploadingFileId === el.id ? (
                        <FileUploadLoader />
                      ) : (
                        <a
                          href={getCertificateFileURL(el.id)}
                          target='_blank'
                          rel='noopener noreferrer'
                          download
                        >
                          {t('download')}
                        </a>
                      )}
                    </div>
                  ) : el.replaceDocument === true ? (
                    <TextField
                      size='small'
                      variant='outlined'
                      type='file'
                      label={t('upload')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        const file = e.target.files
                        if (file.length > 0) {
                          if (file[0].size > acceptedFileSize) {
                            setFileSizeError(i)
                          } else {
                            setNewCertificateMsg(true)
                            setNewDocumentData({
                              id: el.id,
                              file
                            })
                          }
                        }
                      }}
                      fullWidth
                      inputProps={{
                        accept: '.png, .jpg, .jpeg, .pdf'
                      }}
                      helperText={
                        el.fileSizeError ? t('toBigFile') : t('acceptFormat2')
                      }
                      error={el.fileSizeError}
                    />
                  ) : (
                    <div style={{ marginTop: 8 }}>{t('addFileNameFirst')}</div>
                  )}

                  {/* Replace file */}
                  {el.file !== '' && el.replaceDocument !== true ? (
                    <Tooltip
                      style={{
                        marginLeft: 20,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('replaceFileWithAnother')}
                    >
                      <div onClick={() => replaceDocumentWithNew(i)}>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faSyncAlt}
                        />
                      </div>
                    </Tooltip>
                  ) : el.fileName !== '' && el.file !== '' ? (
                    <Tooltip
                      style={{
                        marginLeft: 20,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('cancelReplaceFileWithAnother')}
                    >
                      <div onClick={() => cancelDocumentReplaceWithNew(i)}>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faTimes}
                        />
                      </div>
                    </Tooltip>
                  ) : null}

                  {/* Delete document */}
                  <Tooltip
                    style={{ marginLeft: 20, marginTop: 8, cursor: 'pointer' }}
                    placement='right-end'
                    title={t('deleteDocument')}
                  >
                    <div onClick={() => removeCertificateInput(i, el.file)}>
                      <FontAwesomeIcon
                        style={{ color: systemColors.lighGrey }}
                        icon={faTrash}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}

            <div
              className='addNewEmployee__addRemove'
              onClick={() => addCertificateInput()}
            >
              <FontAwesomeIcon className='icon' icon={faPlus} />
              <span>{t('addDocument')}</span>
            </div>

            {/* References */}
            <div
              className='addNewKeyEmployee__list-title'
              style={{ marginTop: 100 }}
            >
              {t('uploadReferencies')}
            </div>

            {referenciesFilesArr.map((el, i) => (
              <Grid
                container
                key={el.id}
                spacing={2}
                classes={{ root: classes.gridContainer }}
              >
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('fileName')}
                    value={el.fileName}
                    onChange={(e) =>
                      addReferency(el.id, e.target.value, 'fileName')
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} style={{ display: 'flex' }}>
                  {el.fileName !== '' && el.file === '' ? (
                    <TextField
                      size='small'
                      variant='outlined'
                      type='file'
                      label={t('upload')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        const file = e.target.files
                        if (file.length > 0) {
                          if (file[0].size > acceptedFileSize) {
                            setReferenceFileSizeError(i)
                          } else {
                            addReferency(el.id, file, 'file')
                          }
                        }
                      }}
                      fullWidth
                      inputProps={{
                        accept: '.png, .jpg, .jpeg, .pdf'
                      }}
                      helperText={
                        el.fileSizeError ? t('toBigFile') : t('acceptFormat2')
                      }
                      error={el.fileSizeError}
                    />
                  ) : el.fileName !== '' && el.replaceReference !== true ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {uploadingFile && uploadingFileId === el.id ? (
                        <FileUploadLoader />
                      ) : (
                        <a
                          href={getReferenceFileURL(el.id)}
                          target='_blank'
                          rel='noopener noreferrer'
                          download
                        >
                          {t('download')}
                        </a>
                      )}
                    </div>
                  ) : el.replaceReference === true ? (
                    <TextField
                      size='small'
                      variant='outlined'
                      type='file'
                      label={t('upload')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        const file = e.target.files
                        if (file.length > 0) {
                          if (file[0].size > acceptedFileSize) {
                            setReferenceFileSizeError(i)
                          } else {
                            setNewReferenceMsg(true)
                            setNewDocumentData({
                              id: el.id,
                              file
                            })
                          }
                        }
                      }}
                      fullWidth
                      inputProps={{
                        accept: '.png, .jpg, .jpeg, .pdf'
                      }}
                      helperText={
                        el.fileSizeError ? t('toBigFile') : t('acceptFormat2')
                      }
                      error={el.fileSizeError}
                    />
                  ) : (
                    <div style={{ marginTop: 8 }}>{t('addFileNameFirst')}</div>
                  )}

                  {/* Replace file */}
                  {el.file !== '' && el.replaceReference !== true ? (
                    <Tooltip
                      style={{
                        marginLeft: 20,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('replaceFileWithAnother')}
                    >
                      <div onClick={() => replaceReferenceWithNew(i)}>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faSyncAlt}
                        />
                      </div>
                    </Tooltip>
                  ) : el.fileName !== '' && el.file !== '' ? (
                    <Tooltip
                      style={{
                        marginLeft: 20,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('cancelReplaceFileWithAnother')}
                    >
                      <div onClick={() => cancelReferenceReplaceWithNew(i)}>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faTimes}
                        />
                      </div>
                    </Tooltip>
                  ) : null}

                  {/* Delete reference */}
                  <Tooltip
                    style={{ marginLeft: 20, marginTop: 8, cursor: 'pointer' }}
                    placement='right-end'
                    title={t('deleteDocument')}
                  >
                    <div onClick={() => removeReferencyInput(i, el.file)}>
                      <FontAwesomeIcon
                        style={{ color: systemColors.lighGrey }}
                        icon={faTrash}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}

            <div
              className='addNewEmployee__addRemove'
              onClick={() => addReferencyInput()}
            >
              <FontAwesomeIcon className='icon' icon={faPlus} />
              <span>{t('addReference')}</span>
            </div>

            {/* Stepper buttons */}
            <div className='addNewEmployee__stepper-buttons'>
              <div>
                <span
                  onClick={() => {
                    selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                  }}
                >
                  {t('back')}
                </span>
              </div>
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => setSelectedStep(selectedStep + 1)}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        )}

        {/* Remarks */}
        {selectedStep === 5 && (
          <div>
            <Grid
              container
              spacing={2}
              classes={{ root: classes.gridContainer }}
            >
              <TextField
                size='small'
                variant='outlined'
                type='text'
                value={comment}
                label={t('comment')}
                onChange={(e) => setComment(e.target.value)}
                fullWidth
                multiline
                rows={5}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              classes={{ root: classes.gridContainer }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <div className='addNewKeyEmployee__title'>
                  {t('howFastYouCanStart')}?
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label={t('choose')}
                  size='small'
                  fullWidth
                  variant='outlined'
                  select
                  value={whenCanStart}
                  onChange={(e) => setWhenCanStart(e.target.value)}
                >
                  {whenCanStartArr.map((el, i) => {
                    return (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              classes={{ root: classes.gridContainer }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <div className='addNewKeyEmployee__title'>
                  {t('howFindOutAboutUs')}?
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label={t('choose')}
                  size='small'
                  fullWidth
                  variant='outlined'
                  select
                  value={howFindOut}
                  onChange={(e) => setHowFindOut(e.target.value)}
                >
                  {howFindOutAboutUsArr.map((el, i) => {
                    return (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            </Grid>

            {/* Stepper buttons */}
            <div className='addNewEmployee__stepper-buttons'>
              <div>
                <span
                  onClick={() => {
                    selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                  }}
                >
                  {t('back')}
                </span>
              </div>
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => submit()}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    agencyUserData: state.agencyUserDataRed.obj
  }
}

export default connect(mapStateToProps)(KeyEmployeeCreateCV)
