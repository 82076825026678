import React, { useEffect, useState, Fragment } from 'react'
import {
  Tabs,
  Button,
  Grid,
  TextField,
  Alert,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tab,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Tooltip,
  Checkbox,
  OutlinedInput,
  Box,
  Chip,
  ListItemText
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import AgencyMenu from '../../Components/AgencyMenu'
import Headline from '../../Components/Headline'
import Loader from '../../Components/Loader'
import {
  systemColors,
  systemStylingSettings,
  acceptedFileSize,
  workDisciplineArrVar,
  workDisciplineTypeArr,
  languageKnowlegeLevelsArr,
  driverLicenceArr,
  whenCanStartArr,
  howFindOutAboutUsArr,
  servicesInteresteIn,
  educationVariants,
  specialityOptions,
  languagesOptions,
  profileStatus as profileStatusArray
} from '../../globalVariables'
import { randomNumber, capitalizeString } from '../../globalFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faInfoCircle,
  faTrash,
  faSyncAlt,
  faTimes,
  faCopy
} from '@fortawesome/free-solid-svg-icons'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { countries } from '../../countries'
import FileUploadLoader from '../../Components/FileUploadLoader/FileUploadLoader'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

const useStyle = makeStyles({
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius,
      backgroundColor: systemColors.inputColor
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  selectRoot: {
    '& .MuiOutlinedInput-input': {
      padding: 8,
      backgroundColor: systemColors.inputColor
    }
  },
  formControlRoot: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    }
  },
  profilePhotoMargin: {
    marginTop: 50
  },
  section: {
    marginTop: 30
  },
  gridContainer: {
    marginBottom: 20
  },
  commentRoot: {
    backgroundColor: systemColors.inputColor
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: systemColors.inputColor,
      width: '100%',
      borderRadius: systemStylingSettings.borderRadius,
      '&.Mui-focused fieldset': {
        color: systemColors.darkBlue,
        borderColor: systemColors.darkBlue,
        borderWidth: systemStylingSettings.borderWidth
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 8
      }
  },
  tabsRoot: {
    marginTop: 30,
    marginBottom: 30,
    justifyContent: 'center'
  },
  tabsScroller: {
    flexGrow: '0'
  },
  tabRoot: {
    maxWidth: 150,
    padding: 0,
    color: systemColors.lightBlue,
    opacity: 1,
    borderTop: `1px solid ${systemColors.lighGrey}`,
    borderBottom: `1px solid ${systemColors.lighGrey}`
  },
  tabsIndicator: {
    backgroundColor: systemColors.orange
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonText: {
    color: '#ffffff'
  },
  buttonRoot: {
    color: '#ffffff'
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  checkbox: {
    color: systemColors.electric
  },
  selectLabelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius,
      backgroundColor: systemColors.inputColor
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    }
  }
})

const KeyEditEmployee = ({ agencyData }) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const history = useHistory()
  const { docId } = useParams()

  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [refreshData, setRefreshData] = useState(false)

  const [nationalityArr, setNationalityArr] = useState([])
  const [mergetPositionsList, setMergetPositionsList] = useState([])

  // INPUT STATES
  // Personal data
  const [userId, setUserId] = useState('')
  const [profileStatus, setProfileStatus] = useState('')
  const [appliedForOne, setAppliedForOne] = useState(null)
  const [appliedForTwo, setAppliedForTwo] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState('')
  const [mobilePhone, setMobilePhone] = useState('')
  const [email, setEmail] = useState('')
  const [familyStatus, setFamilyStatus] = useState('')
  const [healthStatus, setHealthStatus] = useState('')
  const [oldProfilePhoto, setOldProfilePhoto] = useState('')
  const [profilePhoto, setProfilePhoto] = useState('')
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('')
  const [profilePhotoSizeErr, setProfilePhotoSizeErr] = useState(false)
  const [nationality, setNationality] = useState('')
  const [nationalityOther, setNationalityOther] = useState('')
  const [showOtherNationalityInput, setShowOtherNationalityInput] =
    useState(false)
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [linkedInLink, setLinkedInLink] = useState('')

  // Work skills
  const [workDisciplineArr, setWorkDisciplineArr] = useState([
    { id: randomNumber(10, true, false), discipline: '', type: '' }
  ])
  const [servicesInterestedIn, setServicesInterestedIn] = useState('')
  const [education, setEducation] = useState('')
  const [speciality, setSpeciality] = useState('')

  // Personal skills
  const [languageKnowlegeArr, setLanguageKnowlegeArr] = useState([
    { id: randomNumber(10, true, false), language: '', level: '' }
  ])
  const [driverLicence, setDriverLicence] = useState('')
  const [canDrive, setCanDrive] = useState('')
  const [continueLearn, setContinueLearn] = useState('')

  // Experience
  const [experienceArr, setExperienceArr] = useState([
    {
      id: randomNumber(10, true, false),
      workplace: '',
      country: '',
      position: '',
      dateFrom: '',
      dateTill: '',
      workOccupation: '',
      stillWorking: false
    }
  ])

  // Documents
  const [cvFileSizeErr, setCvFileSizeErr] = useState(false)
  const [certificatesFilesArr, setCertificatesFilesArr] = useState([
    {
      id: randomNumber(10, true, false),
      fileName: '',
      file: '',
      issueDate: '',
      expireDate: '',
      replaceDocument: false,
      fileSizeError: false
    }
  ])
  const [referenciesFilesArr, setReferenciesFilesArr] = useState([
    {
      id: randomNumber(10, true, false),
      fileName: '',
      file: '',
      replaceReference: false,
      fileSizeError: false
    }
  ])

  const [cvFileURL, setCvFileURL] = useState('')
  const [cvFileName, setcvFileName] = useState('')
  const [certificatesLinks, setCertificatesLinks] = useState([])
  const [referenciesLinks, setReferenciesLinks] = useState([])

  // Remarks
  const [comment, setComment] = useState('')
  const [whenCanStart, setWhenCanStart] = useState('')
  const [howFindOut, setHowFindOut] = useState('')

  const [contentLoaded, setContentLoaded] = useState(false)
  const [message, setMessage] = useState({
    status: false,
    duration: 1500,
    severity: 'success',
    message: ''
  })
  const [newProfilePhotoMsg, setNewProfilePhotoMsg] = useState(false)
  const [uploadedFile, setUploadedFile] = useState(false)
  const [newCertificateMsg, setNewCertificateMsg] = useState(false)
  const [deleteCertificateDocumentMsg, setDeleteCertificateDocumentMsg] =
    useState(false)
  const [newReferenceMsg, setNewReferenceMsg] = useState(false)
  const [deleteReferenceDocumentMsg, setDeleteReferenceDocumentMsg] =
    useState(false)
  const [newDocumentData, setNewDocumentData] = useState({})

  const [openAddNewPositionDialog, setOpenAddNewPositionDialog] =
    useState(false)
  const [newPositionName, setNewPositionName] = useState('')
  const [newPositionNameError, setNewPositionNameError] = useState(false)

  const [currentAgencyPositions, setCurrentAgencyPositions] = useState([])

  const [
    currentAgencyAvailableCertificates,
    setCurrentAgencyAvailableCertificates
  ] = useState([])
  const [mergetAvailableCertificates, setMergetAvailableCertificates] =
    useState([])
  const [availableCertificates, setAvailableCertificates] = useState([])
  const [selectedCertificates, setSelectedCertificates] = useState([])
  const [openNewCertificateDialog, setOpenNewCertificateDialog] =
    useState(false)
  const [newCertificate, setNewCertificate] = useState('')
  const [newCertificateError, setNewCertificateError] = useState(false)

  const [showRemovePositionDialog, setShowRemovePositionDialog] =
    useState(false)
  const [positionForRemoving, setPositionForRemoving] = useState('')
  const [whichPosition, setWhichPosition] = useState('')

  const [showRemoveCertificateDialog, setShowRemoveCertificateDialog] =
    useState(false)
  const [certificateForRemoving, setCertificateForRemoving] = useState('')

  const [uploadingCvStatus, setUploadingCvStatus] = useState(false)
  const [uploadingFile, setUploadingFile] = useState(false)
  const [uploadingFileId, setUploadingFileId] = useState('')

  useEffect(() => {
    let shouldUpdate = true
    const getAllDataFromDatabase = () => {
      let defaultPositions = []
      let agencyPositions = []
      let defaultAvailableCertificates = []
      let agencyAvailableCertificates = []

      const getAndSetUserData = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(docId)
          .get()
          .then((res) => {
            let data = JSON.stringify(res.data())
            data = JSON.parse(data)
            // Change document title
            document.title = `${t('edit')} ${
              data.personalData.firstName.charAt(0).toUpperCase() +
              data.personalData.firstName.slice(1)
            } 
                ${
                  data.personalData.lastName.charAt(0).toUpperCase() +
                  data.personalData.lastName.slice(1)
                } - Seanor`

            if (shouldUpdate) {
              // Personal data
              setUserId(data.userId)
              setProfileStatus(data.profileStatus)
              setAppliedForOne(data.personalData.mainPosition)
              setAppliedForTwo(data.personalData.secondPosition)
              setFirstName(capitalizeString(data.personalData.firstName))
              setLastName(capitalizeString(data.personalData.lastName))
              setAddress(data.personalData.address)
              setMobilePhone(data.personalData.mobilePhone)
              setEmail(data.personalData.email)
              setFamilyStatus(data.personalData.familyStatus)
              setHealthStatus(data.personalData.healthStatus)
              setOldProfilePhoto(data.personalData.profilePhoto)
            }

            // Capitelize nationality string
            let nationalityString = data.personalData.nationality

            if (nationalityString !== undefined) {
              nationalityString =
                nationalityString.charAt(0).toUpperCase() +
                nationalityString.slice(1)
            } else {
              nationalityString = ''
            }
            if (shouldUpdate) {
              setNationality(nationalityString)
              setDateOfBirth(data.personalData.dateOfBirth)
              setLinkedInLink(data.personalData.linkedInLink)
            }

            const getProfilePhotoUrl = new Promise((resolve) => {
              if (
                data.personalData.profilePhoto !== '' &&
                data.personalData.profilePhoto !== null
              ) {
                storageRef
                  .child(
                    `agencies/${agencyData.id}/users-forms-uploads/${docId}/${data.personalData.profilePhoto}`
                  )
                  .getDownloadURL()
                  .then((url) => {
                    if (shouldUpdate) {
                      setProfilePhotoUrl(url)
                    }
                    resolve()
                  })
                  .catch(() => {
                    resolve()
                  })
              } else {
                resolve()
              }
            })

            if (shouldUpdate) {
              // Work skills
              setWorkDisciplineArr(data.workDiscipline)
              setServicesInterestedIn(
                data.workDisciplineOtherData.servicesInterestedIn
              )
              setEducation(
                data.workDisciplineOtherData.additionalRequirements.education
              )
              setSpeciality(
                data.workDisciplineOtherData.additionalRequirements.speciality
              )
              setAvailableCertificates(
                data.workDisciplineOtherData.additionalRequirements
                  .certificates !== undefined
                  ? data.workDisciplineOtherData.additionalRequirements
                      .certificates
                  : []
              )
              setSelectedCertificates(
                data.workDisciplineOtherData.additionalRequirements
                  .certificates !== undefined
                  ? data.workDisciplineOtherData.additionalRequirements
                      .certificates
                  : []
              )

              // Personal skills
              setLanguageKnowlegeArr(data.personalSkills.languageKnowlegeArr)
              setDriverLicence(data.personalSkills.driverLicence)
              setCanDrive(data.personalSkills.canDrive)
              setContinueLearn(data.personalSkills.continueLearn)

              // Experience
              setExperienceArr(data.experienceArr)

              // Documents
              setCertificatesFilesArr(data.documents.newCertificatesFilesArr)
              setReferenciesFilesArr(data.documents.newReferenciesFilesArr)
              setcvFileName(data.documents.cvFile)
            }

            const getCvFileUrl = new Promise((resolve) => {
              if (
                data.documents.cvFile !== null &&
                data.documents.cvFile !== ''
              ) {
                storageRef
                  .child(
                    `agencies/${agencyData.id}/users-forms-uploads/${docId}/${data.documents.cvFile}`
                  )
                  .getDownloadURL()
                  .then((url) => {
                    if (shouldUpdate) {
                      setCvFileURL(url)
                    }
                    resolve()
                  })
                  .catch(() => {
                    setCvFileURL('')
                    resolve()
                  })
              } else {
                resolve()
              }
            })

            const getCertificatesLinks = new Promise((resolve) => {
              const getLinks = data.documents.newCertificatesFilesArr.map(
                (el) => {
                  return new Promise((resolve) => {
                    storageRef
                      .child(
                        `agencies/${agencyData.id}/users-forms-uploads/${docId}/${el.file}`
                      )
                      .getDownloadURL()
                      .then((url) => {
                        resolve({
                          id: el.id,
                          url
                        })
                      })
                      .catch(() => {
                        resolve({
                          id: el.id,
                          url: ''
                        })
                      })
                  })
                }
              )
              Promise.all(getLinks).then((response) => {
                if (shouldUpdate) {
                  setCertificatesLinks(response)
                }
                resolve()
              })
            })

            const getReferancesLinks = new Promise((resolve) => {
              const getLinks = data.documents.newReferenciesFilesArr.map(
                (el) => {
                  return new Promise((resolve) => {
                    storageRef
                      .child(
                        `agencies/${agencyData.id}/users-forms-uploads/${docId}/${el.file}`
                      )
                      .getDownloadURL()
                      .then((url) => {
                        resolve({
                          id: el.id,
                          url
                        })
                      })
                      .catch(() => {
                        resolve({
                          id: el.id,
                          url: ''
                        })
                      })
                  })
                }
              )
              Promise.all(getLinks).then((response) => {
                if (shouldUpdate) {
                  setReferenciesLinks(response)
                }
                resolve()
              })
            })

            // Remarks
            if (shouldUpdate) {
              setComment(data.remarks.comment)
              setHowFindOut(data.remarks.howFindOut)
              setWhenCanStart(data.remarks.whenCanStart)
            }

            Promise.all([
              getProfilePhotoUrl,
              getCvFileUrl,
              getCertificatesLinks,
              getReferancesLinks
            ])
              .then(() => resolve(nationalityString))
              .catch((err) => console.log(err.message, err.error))
          })
      })

      const getNationalityList = new Promise((resolve) => {
        db.collection('lists')
          .doc('nationality')
          .get()
          .then((snap) => {
            let data = JSON.stringify(snap.data())
            data = JSON.parse(data)
            if (shouldUpdate) {
              setNationalityArr(data.list)
            }
            resolve(data.list)
          })
          .catch(() => {
            resolve()
          })
      })

      const getDefaultPositionsList = new Promise((resolve) => {
        db.collection('lists')
          .doc('key_positions')
          .get()
          .then((positions) => {
            let data = JSON.stringify(positions.data())
            data = JSON.parse(data)

            if (shouldUpdate) {
              defaultPositions = data.list
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      const getAgencyPositionsList = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('lists')
          .doc('positions')
          .get()
          .then((positions) => {
            if (positions.exists) {
              let data = JSON.stringify(positions.data())
              data = JSON.parse(data)

              if (shouldUpdate) {
                setCurrentAgencyPositions(data.list)
                agencyPositions = data.list
              }
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      const getDefaultAvailableCertificatesList = new Promise((resolve) => {
        db.collection('lists')
          .doc('available_certificates')
          .get()
          .then((certificates) => {
            let data = JSON.stringify(certificates.data())
            data = JSON.parse(data)

            if (shouldUpdate) {
              defaultAvailableCertificates = data.list
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      const getAgencyAvailableCertificatesList = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('lists')
          .doc('available_certificates')
          .get()
          .then((certificates) => {
            if (certificates.exists) {
              let data = JSON.stringify(certificates.data())
              data = JSON.parse(data)

              if (shouldUpdate) {
                setCurrentAgencyAvailableCertificates(data.list)
                agencyAvailableCertificates = data.list
              }
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      Promise.all([
        getAndSetUserData,
        getNationalityList,
        getDefaultPositionsList,
        getAgencyPositionsList,
        getDefaultAvailableCertificatesList,
        getAgencyAvailableCertificatesList
      ]).then((response) => {
        const nationalityFromDB = response[0]
        const nationalityList = response[1]
        const existNationalityArray =
          nationalityList.includes(nationalityFromDB)
        if (nationalityFromDB !== undefined && nationalityList !== undefined) {
          if (!existNationalityArray && nationalityFromDB !== '') {
            if (shouldUpdate) {
              setNationalityOther(nationalityFromDB)
              setShowOtherNationalityInput(true)
              setNationality('Other')
            }
          } else {
            if (shouldUpdate) {
              setNationalityOther('')
              setShowOtherNationalityInput(false)
            }
          }
        }
        if (shouldUpdate) {
          setMergetPositionsList([
            ...defaultPositions,
            ...agencyPositions,
            'addNewPosition'
          ])

          setMergetAvailableCertificates([
            ...defaultAvailableCertificates,
            ...agencyAvailableCertificates,
            'addNewCertificate'
          ])
          setContentLoaded(true)
        }
      })
    }

    getAllDataFromDatabase()

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, docId, uploadedFile, t, refreshData])

  // Add discipline inputs
  const addDisciplineInputs = (id, val, name) => {
    const newInput = workDisciplineArr.map((el) => {
      if (id === el.id) {
        if (name === 'discipline') {
          el[name] = val
        } else if (name === 'type') {
          el[name] = val
        }
      }
      return el
    })
    setWorkDisciplineArr(newInput)
  }

  // Remove discipline
  const removeDiscipline = (index) => {
    if (workDisciplineArr.length > 1) {
      const values = [...workDisciplineArr]
      values.splice(index, 1)
      setWorkDisciplineArr(values)
    }
  }

  // Add discipline
  const addDiscipline = () => {
    const blockId = randomNumber(10, true, false)
    setWorkDisciplineArr([
      ...workDisciplineArr,
      { id: blockId, discipline: '', type: '' }
    ])
  }

  // Add language knowledge inputs
  const addLanguageKnowledgeInputs = (id, val, name) => {
    const newInput = languageKnowlegeArr.map((el) => {
      if (id === el.id) {
        if (name === 'language') {
          el[name] = val
        } else if (name === 'level') {
          el[name] = val
        }
      }
      return el
    })
    setLanguageKnowlegeArr(newInput)
  }

  // Remove discipline
  const removeLanguageKnowledge = (index) => {
    if (languageKnowlegeArr.length > 1) {
      const values = [...languageKnowlegeArr]
      values.splice(index, 1)
      setLanguageKnowlegeArr(values)
    }
  }

  // Add discipline
  const addLanguageKnowledge = () => {
    const blockId = randomNumber(10, true, false)
    setLanguageKnowlegeArr([
      ...languageKnowlegeArr,
      { id: blockId, language: '', level: '' }
    ])
  }

  // Add experience inputs
  const addExperienceInput = (id, val, name) => {
    const newInput = experienceArr.map((el) => {
      if (id === el.id) {
        el[name] = val
      }
      return el
    })
    setExperienceArr(newInput)
  }

  // Remove experience
  const removeExperience = (index) => {
    if (experienceArr.length > 1) {
      const values = [...experienceArr]
      values.splice(index, 1)
      setExperienceArr(values)
    }
  }

  // Add experience
  const addExperience = () => {
    const blockId = randomNumber(10, true, false)
    setExperienceArr([
      ...experienceArr,
      {
        id: blockId,
        workplace: '',
        position: '',
        country: '',
        dateFrom: '',
        dateTill: '',
        workOccupation: '',
        stillWorking: false
      }
    ])
  }

  // Remove certificates and documents input
  const removeCertificateInput = async (index, file) => {
    setLoading(true)
    const values = [...certificatesFilesArr]
    values.splice(index, 1)

    if (file === '') {
      setCertificatesFilesArr(values)
      setLoading(false)
    } else {
      await storage
        .ref(`/agencies/${agencyData.id}/users-forms-uploads/${docId}/${file}`)
        .delete()
        .then(() => {
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('employees')
            .doc(docId)
            .update({
              'documents.newCertificatesFilesArr': values
            })
            .then(() => {
              const getLinks = values.map((obj) => {
                if (obj.file !== null) {
                  // eslint-disable-next-line
                  return new Promise((res) => {
                    storageRef
                      .child(
                        `agencies/${agencyData.id}/users-forms-uploads/${docId}/${obj.file}`
                      )
                      .getDownloadURL()
                      .then((url) => {
                        res({
                          id: obj.id,
                          url
                        })
                      })
                      .catch((error) => {
                        console.log("Can't download file", error)
                        res({
                          id: obj.id,
                          url: ''
                        })
                      })
                  })
                } else {
                  // eslint-disable-next-line
                  return
                }
              })
              Promise.all(getLinks).then((res) => {
                if (certificatesFilesArr.length === 0) {
                  setCertificatesFilesArr([
                    {
                      id: randomNumber(10, true, false),
                      fileName: '',
                      file: ''
                    }
                  ])
                }
                setCertificatesFilesArr(values)
                setCertificatesLinks(res)
                setMessage({
                  status: true,
                  duration: 3000,
                  severity: 'success',
                  message: t('fileDeletedSuccessfully')
                })
                setLoading(false)
              })
            })
        })
        .catch((error) => {
          console.log("Can't delete certificate file from storage", error)
          setLoading(false)
        })
    }
  }

  // Add certificates and documents input
  const addCertificateInput = () => {
    const blockId = randomNumber(10, true, false)
    setCertificatesFilesArr([
      ...certificatesFilesArr,
      {
        id: blockId,
        fileName: '',
        file: '',
        issueDate: '',
        expireDate: '',
        replaceDocument: false,
        fileSizeError: false
      }
    ])
  }

  // Add certificates and documents
  const addCertificate = async (id, val, name) => {
    const newInput = certificatesFilesArr.map((el) => {
      if (id === el.id) {
        if (name === 'file') {
          el[name] = val[0].name
          el.fileSizeError = false
          el.replaceDocument = false
        } else {
          el[name] = val
        }
      }
      return el
    })
    setCertificatesFilesArr(newInput)

    // Upload file to storage and update database
    if (name === 'file') {
      setUploadingFile(true)
      setUploadingFileId(id)

      await storage
        .ref(
          `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${val[0].name}`
        )
        .put(val[0])
        .then(() => {
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('employees')
            .doc(docId)
            .update({
              'documents.newCertificatesFilesArr': newInput
            })
            .then(() => {
              const getLinks = newInput.map((obj) => {
                if (obj.file !== null && obj.file !== '') {
                  // eslint-disable-next-line
                  return new Promise((res) => {
                    storageRef
                      .child(
                        `agencies/${agencyData.id}/users-forms-uploads/${docId}/${obj.file}`
                      )
                      .getDownloadURL()
                      .then((url) => {
                        res({
                          id: obj.id,
                          url
                        })
                      })
                      .catch((error) => {
                        console.log("Can't download file", error)
                        res({
                          id: obj.id,
                          url: ''
                        })
                      })
                  })
                } else {
                  // eslint-disable-next-line
                  return
                }
              })
              Promise.all(getLinks).then((res) => {
                setCertificatesLinks(res)
                setMessage({
                  status: true,
                  duration: 3000,
                  severity: 'success',
                  message: t('fileUploadedSuccessfully')
                })
                setUploadingFile(false)
              })
            })
            .catch((error) => console.error(error))
        })
        .catch((error) => {
          console.error(error)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'warning',
            message: t('fileWasntUploaded')
          })
          setUploadingFile(false)
        })
    }
  }

  // Remove referencies input
  const removeReferencyInput = async (index, file) => {
    setLoading(true)
    const values = [...referenciesFilesArr]
    values.splice(index, 1)

    if (file === '') {
      setReferenciesFilesArr(values)
      setLoading(false)
    } else {
      await storage
        .ref(`/agencies/${agencyData.id}/users-forms-uploads/${docId}/${file}`)
        .delete()
        .then(() => {
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('employees')
            .doc(docId)
            .update({
              'documents.newReferenciesFilesArr': values
            })
            .then(() => {
              setReferenciesFilesArr(values)
              setMessage({
                status: true,
                duration: 3000,
                severity: 'success',
                message: t('fileDeletedSuccessfully')
              })
              setLoading(false)
            })
        })
        .catch((error) => {
          console.log("Can't delete reference file from storage", error)
          setLoading(false)
        })
      setReferenciesFilesArr(values)
    }
  }

  // Add referencies input
  const addReferencyInput = () => {
    const blockId = randomNumber(10, true, false)
    setReferenciesFilesArr([
      ...referenciesFilesArr,
      {
        id: blockId,
        fileName: '',
        file: '',
        replaceReference: false,
        fileSizeError: false
      }
    ])
  }

  // Add referencies
  const addReferency = async (id, val, name) => {
    const newInput = referenciesFilesArr.map((el) => {
      if (id === el.id) {
        if (name === 'file') {
          el[name] = val[0].name
          el.fileSizeError = false
          el.replaceReference = false
        } else {
          el[name] = val
        }
      }
      return el
    })
    setReferenciesFilesArr(newInput)

    // Upload file to storage and update database
    if (name === 'file') {
      setUploadingFile(true)
      setUploadingFileId(id)

      await storage
        .ref(
          `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${val[0].name}`
        )
        .put(val[0])
        .then(() => {
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('employees')
            .doc(docId)
            .update({
              'documents.newReferenciesFilesArr': newInput
            })
            .then(() => {
              const getLinks = newInput.map((obj) => {
                if (obj.file !== null && obj.file !== '') {
                  // eslint-disable-next-line
                  return new Promise((res) => {
                    storageRef
                      .child(
                        `agencies/${agencyData.id}/users-forms-uploads/${docId}/${obj.file}`
                      )
                      .getDownloadURL()
                      .then((url) => {
                        res({
                          id: obj.id,
                          url
                        })
                      })
                  })
                } else {
                  // eslint-disable-next-line
                  return
                }
              })
              Promise.all(getLinks).then((res) => {
                setReferenciesLinks(res)
                setMessage({
                  status: true,
                  duration: 3000,
                  severity: 'success',
                  message: t('fileUploadedSuccessfully')
                })
                setUploadingFile(false)
              })
            })
            .catch((error) => console.error(error))
        })
        .catch((err) => {
          console.log({
            message: "Can't add referency document to storage",
            error: err
          })
          setMessage({
            status: true,
            duration: 3000,
            severity: 'warning',
            message: t('fileWasntUploaded')
          })
        })
    }
  }

  const handleTabsChange = (e, newValue) => {
    setSelectedTab(newValue)
  }

  // Submit data
  const submit = () => {
    setLoading(true)

    // Get all languages for search queries
    let filterLanguages = []
    languageKnowlegeArr.map(
      (el) => (filterLanguages = [...filterLanguages, el.language])
    )

    // Get all countries of experiences for search quires
    let filterCountries = []
    experienceArr.forEach((el) => {
      if (el.country !== undefined) {
        return (filterCountries = [...filterCountries, el.country])
      }
    })

    // Add data to database
    const addDataToDatabase = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .update({
          timeStamp: new Date(),
          userId,
          profileStatus,
          'personalData.mainPosition': appliedForOne,
          'personalData.secondPosition': appliedForTwo,
          'personalData.firstName': firstName.toLowerCase(),
          'personalData.lastName': lastName.toLowerCase(),
          'personalData.address': address,
          'personalData.mobilePhone': mobilePhone,
          'personalData.email': email,
          'personalData.familyStatus': familyStatus,
          'personalData.healthStatus': healthStatus,
          'personalData.nationality':
            nationalityOther !== ''
              ? nationalityOther.toLowerCase()
              : nationality.toLowerCase(),
          'personalData.dateOfBirth': dateOfBirth,
          'personalData.linkedInLink': linkedInLink,
          workDiscipline: workDisciplineArr,
          'personalSkills.languageKnowlegeArr': languageKnowlegeArr,
          'personalSkills.filterLanguages': filterLanguages,
          'personalSkills.driverLicence': driverLicence,
          'personalSkills.canDrive': canDrive,
          'personalSkills.continueLearn': continueLearn,
          experienceArr: experienceArr,
          'documents.newCertificatesFilesArr': certificatesFilesArr,
          'documents.newReferenciesFilesArr': referenciesFilesArr,
          'remarks.comment': comment,
          'remarks.whenCanStart': whenCanStart,
          'remarks.howFindOut': howFindOut,
          'workDisciplineOtherData.servicesInterestedIn': servicesInterestedIn,
          'workDisciplineOtherData.additionalRequirements.education': education,
          'workDisciplineOtherData.additionalRequirements.speciality':
            speciality,
          'workDisciplineOtherData.additionalRequirements.certificates':
            availableCertificates,
          'searchTags.countries': filterCountries
        })
        .then(() => resolve())
        .catch((err) => {
          console.log({ message: 'Add data error', error: err })
          resolve()
        })
    })

    Promise.all([addDataToDatabase])
      .then(() => {
        setLoading(false)
        setMessage({
          status: true,
          duration: 5000,
          severity: 'success',
          message: t('dataSaveSuccess')
        })
        setUploadedFile(!uploadedFile)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err.message, err.error)
      })
  }

  const uploadNewProfilePhoto = () => {
    setNewProfilePhotoMsg(false)
    setLoading(true)

    const addPhoto = () => {
      const updateDatabase = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(docId)
          .update({
            'personalData.profilePhoto': profilePhoto[0].name,
            date: new Date()
          })
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't update profile pgoto", error: err })
            resolve()
          })
      })
      const uploadProfilePhoto = new Promise((resolve) => {
        storage
          .ref(
            `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${profilePhoto[0].name}`
          )
          .put(profilePhoto[0])
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't upload file", error: err })
            resolve()
          })
      })

      Promise.all([updateDatabase, uploadProfilePhoto])
        .then(() => {
          setLoading(false)
          setUploadedFile(!uploadedFile)
        })
        .catch((err) => {
          console.log(err.message, err.err)
          setLoading(false)
        })
    }

    if (oldProfilePhoto !== '' && oldProfilePhoto !== null) {
      storageRef
        .child(
          `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${oldProfilePhoto}`
        )
        .getDownloadURL()
        .then((url) => {
          storageRef
            .child(
              `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${oldProfilePhoto}`
            )
            .delete()
            .then(() => addPhoto())
            .catch((err) => {
              console.log("Can't delete old profile photo", err)
              setProfilePhoto('')
              setLoading(false)
            })
        })
        .catch((error) => {
          console.log('Profile photo file not exists', error)
          addPhoto()
        })
    } else {
      addPhoto()
    }
  }

  const uploadNewCertificate = () => {
    setLoading(true)
    const document = certificatesFilesArr.find(
      (el) => el.id === newDocumentData.id
    )

    const addDocument = () => {
      const updateDatabase = new Promise((resolve) => {
        const newUpdatedCertificatesList = certificatesFilesArr.map((el) => {
          if (el.id === newDocumentData.id) {
            el.file = newDocumentData.file[0].name
            el.replaceDocument = false
            el.fileSizeError = false
          }
          return el
        })

        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(docId)
          .update({
            'documents.newCertificatesFilesArr': newUpdatedCertificatesList,
            date: new Date()
          })
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't update certificates", error: err })
            resolve()
          })
      })

      const uploadFile = new Promise((resolve) => {
        storage
          .ref(
            `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${newDocumentData.file[0].name}`
          )
          .put(newDocumentData.file[0])
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't upload file", error: err })
            resolve()
          })
      })

      Promise.all([updateDatabase, uploadFile])
        .then(() => {
          setNewCertificateMsg(false)
          setNewDocumentData({})
          setLoading(false)
          setUploadedFile(!uploadedFile)
          setMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('documentUploadSuccess')
          })
        })
        .catch((err) => {
          setNewCertificateMsg(false)
          console.log(err.message, err.err)
          setNewDocumentData({})
          setLoading(false)
          setMessage({
            status: true,
            duration: 15000,
            severity: 'warning',
            message: t('cantUploadDocument')
          })
        })
    }

    if (document && document.file !== '' && document.file !== null) {
      storageRef
        .child(
          `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${document.file}`
        )
        .delete()
        .then(() => addDocument())
        .catch((error) => {
          console.log("Can't delete old certificate file", error)
          if (error.code === 'storage/object-not-found') {
            addDocument()
          }
          setNewDocumentData({})
          setNewCertificateMsg(false)
          setLoading(false)
        })
    } else {
      addDocument()
    }
  }

  const uploadNewReference = () => {
    setLoading(true)
    setNewReferenceMsg(false)
    const document = referenciesFilesArr.find(
      (el) => el.id === newDocumentData.id
    )

    const addDocument = () => {
      const updateDatabase = new Promise((resolve) => {
        const newUpdatedReferences = referenciesFilesArr.map((el) => {
          if (el.id === newDocumentData.id) {
            el.file = newDocumentData.file[0].name
            el.replaceReference = false
            el.fileSizeError = false
          }
          return el
        })

        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(docId)
          .update({
            'documents.newReferenciesFilesArr': newUpdatedReferences,
            date: new Date()
          })
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't update references", error: err })
            resolve()
          })
      })

      const uploadFile = new Promise((resolve) => {
        storage
          .ref(
            `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${newDocumentData.file[0].name}`
          )
          .put(newDocumentData.file[0])
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't upload file", error: err })
            resolve()
          })
      })

      Promise.all([updateDatabase, uploadFile])
        .then(() => {
          setNewDocumentData({})
          setLoading(false)
          setUploadedFile(!uploadedFile)
          setMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('documentUploadSuccess')
          })
        })
        .catch((err) => {
          console.log(err.message, err.err)
          setNewDocumentData({})
          setLoading(false)
          setMessage({
            status: true,
            duration: 15000,
            severity: 'warning',
            message: t('cantUploadDocument')
          })
        })
    }

    if (document && document.file !== '' && document.file !== null) {
      storageRef
        .child(
          `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${document.file}`
        )
        .delete()
        .then(() => addDocument())
        .catch((error) => {
          console.log("Can't delete old reference file", error)
          if (error.code === 'storage/object-not-found') {
            addDocument()
          }
          setNewDocumentData({})
          setLoading(false)
        })
    } else {
      addDocument()
    }
  }

  const uploadCvFile = async (file) => {
    setUploadingCvStatus(true)
    await storage
      .ref(
        `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${file[0].name}`
      )
      .put(file[0])
      .then(() => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(docId)
          .update({
            'documents.cvFile': file[0].name
          })
          .then(() => {
            setcvFileName(file[0].name)
            storageRef
              .child(
                `agencies/${agencyData.id}/users-forms-uploads/${docId}/${file[0].name}`
              )
              .getDownloadURL()
              .then((url) => {
                setCvFileURL(url)
                setUploadingCvStatus(false)
              })
              .catch((error) => {
                console.error("Can't get cv file url after file upload", error)
                setCvFileURL('')
                setUploadingCvStatus(false)
              })

            setMessage({
              status: true,
              duration: 3000,
              severity: 'success',
              message: t('fileUploadedSuccessfully')
            })
          })
          .catch((error) => console.error(error))
      })
      .catch((error) => {
        console.error(error)
        setMessage({
          status: true,
          duration: 3000,
          severity: 'warning',
          message: t('fileWasntUploaded')
        })
        setUploadingCvStatus(false)
      })
  }

  const deleteCVFile = async () => {
    setLoading(true)
    await storage
      .ref(
        `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${cvFileName}`
      )
      .delete()
      .then(() => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(docId)
          .update({
            'documents.cvFile': ''
          })
          .then(() => {
            setcvFileName('')
            setCvFileURL('')
            setMessage({
              status: true,
              duration: 3000,
              severity: 'success',
              message: t('fileDeletedSuccessfully')
            })
            setLoading(false)
          })
      })
      .catch((error) => {
        console.log("Can't delete cv file from storage", error)
        setLoading(false)
      })
  }

  const getCertificateFileURL = (id) => {
    let link = ''
    certificatesLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  const getReferenceFileURL = (id) => {
    let link = ''
    referenciesLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  // Update state to show new file upload input
  const replaceDocumentWithNew = (index) => {
    const newValue = certificatesFilesArr
    newValue[index].replaceDocument = true
    setCertificatesFilesArr([...newValue])
  }

  // Update state to hide new file upload input
  const cancelDocumentReplaceWithNew = (index) => {
    const newValue = certificatesFilesArr
    newValue[index].replaceDocument = false
    setCertificatesFilesArr([...newValue])
  }

  // Update state to set file size error
  const setFileSizeError = (index) => {
    const newValue = certificatesFilesArr
    newValue[index].fileSizeError = true
    setCertificatesFilesArr([...newValue])
  }

  // Update state to show new file upload input
  const replaceReferenceWithNew = (index) => {
    const newValue = referenciesFilesArr
    newValue[index].replaceReference = true
    setReferenciesFilesArr([...newValue])
  }

  // Update state to hide new file upload input
  const cancelReferenceReplaceWithNew = (index) => {
    const newValue = referenciesFilesArr
    newValue[index].replaceReference = false
    setReferenciesFilesArr([...newValue])
  }

  // Update state to set file size error
  const setReferenceFileSizeError = (index) => {
    const newValue = referenciesFilesArr
    newValue[index].fileSizeError = true
    setReferenciesFilesArr([...newValue])
  }

  const addNewPositionToAgencyPositions = () => {
    if (newPositionName !== '') {
      setLoading(true)
      const newAgencyPOsitionsList = [
        ...currentAgencyPositions,
        newPositionName
      ]
      const listReference = db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('lists')
        .doc('positions')

      let reference = listReference

      if (listReference.exists) {
        reference = reference.update({
          list: newAgencyPOsitionsList
        })
      } else {
        reference = reference.set({
          list: newAgencyPOsitionsList
        })
      }

      reference
        .then(() => {
          setOpenAddNewPositionDialog(false)
          setRefreshData(!refreshData)
          setNewPositionName('')
          setLoading(false)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'success',
            message: t('newPositionAddedToListNowYouCanSelect')
          })
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })

      setNewPositionNameError(false)
    } else {
      if (newPositionName === '') {
        setMessage({
          status: true,
          duration: 2000,
          severity: 'warning',
          message: t('mustFillRequiredFields')
        })
        setNewPositionNameError(true)
      } else {
        setNewPositionNameError(false)
      }
    }
  }

  const addNewCertificateToAgencyPositions = () => {
    if (newCertificate !== '') {
      setLoading(true)

      const newAgencyCertificatesList = [
        ...currentAgencyAvailableCertificates,
        newCertificate
      ]

      const listReference = db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('lists')
        .doc('available_certificates')

      let reference = listReference

      if (listReference.exists) {
        reference = reference.update({
          list: newAgencyCertificatesList
        })
      } else {
        reference = reference.set({
          list: newAgencyCertificatesList
        })
      }

      reference
        .then(() => {
          setOpenNewCertificateDialog(false)
          setRefreshData(!refreshData)
          setNewCertificate('')
          setLoading(false)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'success',
            message: t('newCertificateAddedToListNowYouCanSelect')
          })
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })

      setNewCertificateError(false)
    } else {
      if (newPositionName === '') {
        setMessage({
          status: true,
          duration: 2000,
          severity: 'warning',
          message: t('mustFillRequiredFields')
        })
        setNewCertificateError(true)
      } else {
        setNewCertificateError(false)
      }
    }
  }

  const handleAvailableCertificateInputCahnges = (event) => {
    const { value } = event.target

    const index = value.indexOf('addNewCertificate')
    if (index > -1) {
      value.splice(index, 1)
    }
    setAvailableCertificates(value)
  }

  const removePositionFromAgencypositionsList = () => {
    setLoading(true)
    const positionsArray = currentAgencyPositions
    const index = positionsArray.indexOf(positionForRemoving)
    if (index > -1) {
      positionsArray.splice(index, 1)
    }

    db.collection('agencies')
      .doc(agencyData.id)
      .collection('lists')
      .doc('positions')
      .update({
        list: positionsArray
      })
      .then(() => {
        // Clear selected input from deleted option
        if (whichPosition === 'first') {
          setAppliedForOne(null)
        } else if (whichPosition === 'second') {
          setAppliedForTwo(null)
        }

        setShowRemovePositionDialog(false)
        setRefreshData(!refreshData)
        setPositionForRemoving('')
        setLoading(false)
        setMessage({
          status: true,
          duration: 3000,
          severity: 'success',
          message: t('positionWasRemoved')
        })
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const removeCertificateFromAgencyList = () => {
    setLoading(true)
    const certificatesArray = currentAgencyAvailableCertificates
    const index = certificatesArray.indexOf(certificateForRemoving)
    if (index > -1) {
      certificatesArray.splice(index, 1)
    }

    db.collection('agencies')
      .doc(agencyData.id)
      .collection('lists')
      .doc('available_certificates')
      .update({
        list: certificatesArray
      })
      .then(() => {
        // Check is certificate checked in users profile, if yes, delete it
        const certificates = selectedCertificates
        const certificateIndex = certificates.indexOf(certificateForRemoving)

        if (certificateIndex > -1) {
          certificates.splice(certificateIndex, 1)

          db.collection('agencies')
            .doc(agencyData.id)
            .collection('employees')
            .doc(docId)
            .update({
              'workDisciplineOtherData.additionalRequirements.certificates':
                certificates
            })
            .then(() => {
              setShowRemoveCertificateDialog(false)
              setRefreshData(!refreshData)
              setCertificateForRemoving('')
              setLoading(false)
              setMessage({
                status: true,
                duration: 3000,
                severity: 'success',
                message: t('certificateWasRemoved')
              })
            })
            .catch((error) => {
              console.log(error)
              setLoading(false)
            })
        } else {
          setShowRemoveCertificateDialog(false)
          setRefreshData(!refreshData)
          setCertificateForRemoving('')
          setLoading(false)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'success',
            message: t('certificateWasRemoved')
          })
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  /**
   * Add new experience line with parent experience data
   */
  const copyExperience = (parent) => {
    const blockId = randomNumber(10, true, false)
    setExperienceArr([
      ...experienceArr,
      {
        id: blockId,
        workplace: parent.workplace,
        country: parent.country,
        position: parent.position,
        dateFrom: parent.dateFrom,
        dateTill: parent.dateTill,
        workOccupation: parent.workOccupation,
        stillWorking: parent.stillWorking
      }
    ])
  }

  if (!contentLoaded) {
    return <Loader />
  }

  return (
    <>
      {loading && <Loader />}

      <AgencyMenu type='key' />

      <Headline
        text={`${t('edit')} ${
          firstName.charAt(0).toUpperCase() + firstName.slice(1)
        } 
            ${lastName.charAt(0).toUpperCase() + lastName.slice(1)}`}
      />

      {/* Snackbars */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      {/* Profile photo popup */}
      <Dialog open={newProfilePhotoMsg} classes={{ paper: classes.dialogRoot }}>
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>{t('profilePhotoPopupMessage')}</DialogContent>
        <DialogActions>
          <Button onClick={() => uploadNewProfilePhoto()}>
            {t('continue')}
          </Button>
          <Button
            onClick={() => {
              setNewProfilePhotoMsg(false)
              setProfilePhoto('')
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Documents pop up */}
      <Dialog open={newCertificateMsg} classes={{ paper: classes.dialogRoot }}>
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>{t('uploadNewDocumentPopupMessage')}</DialogContent>
        <DialogActions>
          <Button onClick={() => uploadNewCertificate()}>
            {t('continue')}
          </Button>
          <Button
            onClick={() => {
              setNewCertificateMsg(false)
              setNewDocumentData({})
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteCertificateDocumentMsg}
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>{t('deleteDocuemtentFromDatabase')}</DialogContent>
        <DialogActions>
          <Button onClick={() => removeCertificateInput()}>
            {t('continue')}
          </Button>
          <Button
            onClick={() => {
              setDeleteCertificateDocumentMsg(false)
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={newReferenceMsg} classes={{ paper: classes.dialogRoot }}>
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>{t('uploadNewDocumentPopupMessage')}</DialogContent>
        <DialogActions>
          <Button onClick={() => uploadNewReference()}>{t('continue')}</Button>
          <Button
            onClick={() => {
              setNewReferenceMsg(false)
              setNewDocumentData({})
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteReferenceDocumentMsg}
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>{t('deleteDocuemtentFromDatabase')}</DialogContent>
        <DialogActions>
          <Button onClick={() => removeReferencyInput()}>
            {t('continue')}
          </Button>
          <Button
            onClick={() => {
              setDeleteReferenceDocumentMsg(false)
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add new position dialog */}
      <Dialog
        open={openAddNewPositionDialog}
        classes={{ paper: classes.dialogRoot }}
        onClose={() => setOpenAddNewPositionDialog(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('addNewPosition')}
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 20 }}>
            <TextField
              className='inputPadding'
              required
              classes={{ root: classes.labelRoot }}
              variant='outlined'
              type='text'
              label={t('positionName')}
              value={newPositionName}
              onChange={(e) => setNewPositionName(e.target.value)}
              fullWidth
              error={newPositionNameError}
            />
          </div>
        </DialogContent>

        <DialogActions>
          {/* Add new position button */}
          <Button
            variant='contained'
            size='small'
            color='orange'
            onClick={() => addNewPositionToAgencyPositions()}
          >
            {t('addPosition')}
          </Button>
          <Button
            onClick={() => {
              setOpenAddNewPositionDialog(false)
              setNewPositionNameError(false)
              setNewPositionName('')
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add new certificate dialog */}
      <Dialog
        open={openNewCertificateDialog}
        classes={{ paper: classes.dialogRoot }}
        onClose={() => setOpenNewCertificateDialog(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('addNewCertificate')}
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 20 }}>
            <TextField
              className='inputPadding'
              required
              classes={{ root: classes.labelRoot }}
              variant='outlined'
              type='text'
              label={t('certificateName')}
              value={newCertificate}
              onChange={(e) => setNewCertificate(e.target.value)}
              fullWidth
              error={newCertificateError}
            />
          </div>
        </DialogContent>
        <DialogActions>
          {/* Add button */}
          <Button
            variant='contained'
            color='orange'
            size='small'
            classes={{
              root: classes.buttonRoot,
              contained: classes.buttonContained
            }}
            onClick={() => addNewCertificateToAgencyPositions()}
          >
            {t('addCertificate')}
          </Button>

          {/* Cancel button */}
          <Button
            onClick={() => {
              setOpenNewCertificateDialog(false)
              setNewCertificateError(false)
              setNewCertificate('')
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Remove position dialog */}
      <Dialog
        open={showRemovePositionDialog}
        classes={{ paper: classes.dialogRoot }}
        onClose={() => setOpenNewCertificateDialog(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>

        <DialogContent>
          <div style={{ margin: 20 }}>
            {t('areYouShureYouWantToRemove')} <b>{t(positionForRemoving)}</b>?
          </div>
        </DialogContent>

        <DialogActions>
          {/* Remove button */}
          <Button
            variant='contained'
            size='small'
            color='orange'
            onClick={() => removePositionFromAgencypositionsList()}
          >
            {t('remove')}
          </Button>

          {/* Cancel button */}
          <Button
            onClick={() => {
              setShowRemovePositionDialog(false)
              setPositionForRemoving('')
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Remove Certificate dialog */}
      <Dialog
        open={showRemoveCertificateDialog}
        classes={{ paper: classes.dialogRoot }}
        onClose={() => setOpenNewCertificateDialog(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: 20 }}>
            {t('areYouShureYouWantToRemove')} <b>{t(certificateForRemoving)}</b>
            ?
          </div>
        </DialogContent>
        <DialogActions>
          {/* Remove button */}
          <Button
            variant='contained'
            olor='orange'
            size='small'
            classes={{
              root: classes.buttonRoot,
              contained: classes.buttonContained
            }}
            onClick={() => removeCertificateFromAgencyList()}
          >
            {t('remove')}
          </Button>

          {/* Cancel button */}
          <Button
            onClick={() => {
              setShowRemoveCertificateDialog(false)
              setCertificateForRemoving('')
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Top buttons */}
      <div className='editEmployee__buttons'>
        {/* Save */}
        <Button
          variant='contained'
          color='orange'
          size='small'
          onClick={() => submit()}
        >
          {t('save')}
        </Button>

        <Button
          className='editEmployee__buttons-button'
          onClick={() =>
            history.push(
              `/${agencyData.id}/key-dashboard/employee-view/${docId}`
            )
          }
        >
          {t('cancel')}
        </Button>
      </div>

      {/* Tabs */}
      <Tabs
        value={selectedTab}
        onChange={handleTabsChange}
        scrollButtons='auto'
        style={{ margin: '50px auto' }}
        centered
      >
        <Tab label={t('basicInfo')} />
        <Tab label={t('workSkills')} />
        <Tab label={t('personalSkills')} />
        <Tab label={t('experience')} />
        <Tab label={t('documents')} />
        <Tab label={t('coverLetterComments')} />
      </Tabs>

      {/* Stepper content */}
      <div>
        {/* Personal data */}
        {selectedTab === 0 && (
          <div className='editUser__tabSection'>
            <Grid container style={{ marginBottom: 30 }} spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('userId')}
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  fullWidth
                />
              </Grid>

              {/* Family status */}
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <TextField
                  select
                  fullWidth
                  size='small'
                  label={t('familyStatus')}
                  className={classes.selectRoot}
                  value={profileStatus}
                  onChange={(e) => setProfileStatus(e.target.value)}
                >
                  {profileStatusArray.map((el, i) => (
                    <MenuItem value={el} key={i}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>

                <Tooltip
                  style={{ marginLeft: 10 }}
                  placement='right-end'
                  title={
                    <>
                      <div>{t('activeDescriptionInEmployeeEditPage')}</div>
                      <div>{t('notActiveDescriptionInEmployeeEditPage')}</div>
                    </>
                  }
                >
                  <div>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </div>
                </Tooltip>
              </Grid>
            </Grid>

            {/* Positions section */}
            <Grid container style={{ marginBottom: 30 }} spacing={2}>
              {/* First position */}
              <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                  size='small'
                  fullWidth
                  options={mergetPositionsList}
                  getOptionLabel={(option) => t(option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('mainPosition')}
                      variant='outlined'
                      // helperText={positionObjErr && t('required')}
                      // error={positionObjErr ? true : false}
                    />
                  )}
                  // getoptionselected={(opt, val) => opt === val}
                  value={appliedForOne}
                  onChange={(e, val) => {
                    if (val === 'addNewPosition') {
                      setOpenAddNewPositionDialog(true)
                    } else {
                      setAppliedForOne(val)
                    }
                  }}
                  autoHighlight
                  renderOption={(props, option) => (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                      key={option}
                      {...props}
                    >
                      <span>{t(option)}</span>

                      {option !== 'addNewPosition' && (
                        <Tooltip
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            cursor: 'pointer'
                          }}
                          placement='right-end'
                          title={t('remove')}
                        >
                          <div
                            onClick={() => {
                              setPositionForRemoving(props)
                              setShowRemovePositionDialog(true)
                              setWhichPosition('first')
                            }}
                          >
                            <FontAwesomeIcon
                              style={{ fontSize: 12 }}
                              icon={faTrash}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                />
              </Grid>

              {/* Second position */}
              <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                  size='small'
                  options={mergetPositionsList}
                  getOptionLabel={(option) => t(option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('secondPosition')}
                      variant='outlined'
                      // helperText={positionObjErr && t('required')}
                      // error={positionObjErr ? true : false}
                    />
                  )}
                  value={appliedForTwo}
                  onChange={(e, val) => {
                    if (val === 'addNewPosition') {
                      setOpenAddNewPositionDialog(true)
                    } else {
                      setAppliedForTwo(val)
                    }
                  }}
                  autoHighlight
                  renderOption={(props, option) => (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                      key={option}
                      {...props}
                    >
                      <span>{t(option)}</span>

                      {option !== 'addNewPosition' && (
                        <Tooltip
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            cursor: 'pointer'
                          }}
                          placement='right-end'
                          title={t('remove')}
                        >
                          <div
                            onClick={() => {
                              setPositionForRemoving(option)
                              setShowRemovePositionDialog(true)
                              setWhichPosition('second')
                            }}
                          >
                            <FontAwesomeIcon
                              style={{ fontSize: 12 }}
                              icon={faTrash}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                />
              </Grid>
            </Grid>

            {/* Personal data section */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('firstName')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('lastName')}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('address')}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='tel'
                  label={t('phone')}
                  placeholder='+370 ...'
                  value={mobilePhone}
                  onChange={(e) => setMobilePhone(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='email'
                  label={t('email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  select
                  label={t('familyStatus')}
                  value={familyStatus}
                  onChange={(e) => setFamilyStatus(e.target.value)}
                >
                  <MenuItem value='married'>{t('married')}</MenuItem>
                  <MenuItem value='divorced'>{t('divorced')}</MenuItem>
                  <MenuItem value='other'>{t('other')}</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  select
                  label={t('healthStatus')}
                  value={healthStatus}
                  onChange={(e) => setHealthStatus(e.target.value)}
                >
                  <MenuItem value='healthy'>{t('healthy')}</MenuItem>
                  <MenuItem value='partialDisability'>
                    {t('partialDisability')}
                  </MenuItem>
                  <MenuItem value='specialNeeds'>{t('specialNeeds')}</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  select
                  label={t('nationality')}
                  value={nationality}
                  onChange={(e) => {
                    if (e.target.value === 'Other') {
                      setShowOtherNationalityInput(true)
                      setNationality(e.target.value)
                    } else {
                      setShowOtherNationalityInput(false)
                      setNationality(e.target.value)
                      setNationalityOther('')
                    }
                  }}
                >
                  {nationalityArr.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {showOtherNationalityInput && (
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('otherNationality')}
                    value={nationalityOther}
                    onChange={(e) => setNationalityOther(e.target.value)}
                    fullWidth
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='date'
                  label={t('dateOfBirth')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('linkedInProfile')}
                  value={linkedInLink}
                  onChange={(e) => setLinkedInLink(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>

            {/* Profile photo upload */}
            <Grid
              container
              spacing={2}
              alignItems='center'
              justifyContent='center'
              style={{ marginTop: 50 }}
            >
              <Grid item>
                {profilePhotoUrl !== '' ? (
                  <img
                    className='vesselCrewForm__upload-photo-img'
                    src={profilePhotoUrl}
                    alt='profile'
                  />
                ) : (
                  <div className='photo-box'>{t('photo')}</div>
                )}
              </Grid>
              <Grid item>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='file'
                  label={t('profilePhoto')}
                  key={profilePhoto}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      if (e.target.files[0].size > acceptedFileSize) {
                        setProfilePhotoSizeErr(true)
                      } else {
                        setProfilePhoto(e.target.files)
                        setProfilePhotoSizeErr(false)
                        setNewProfilePhotoMsg(true)
                      }
                    }
                  }}
                  fullWidth
                  inputProps={{
                    accept: '.png, .jpg, .jpeg'
                  }}
                  helperText={
                    profilePhotoSizeErr ? t('toBigFile') : t('acceptFormat')
                  }
                  error={!!profilePhotoSizeErr}
                />
                {profilePhoto !== '' && (
                  <div className='addNewEmployee__upload-msg'>
                    {t('selectedFile')}: {profilePhoto[0].name}
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        )}

        {/* Work skills */}
        {selectedTab === 1 && (
          <div className='editUser__tabSection'>
            {workDisciplineArr.map((el, i) => {
              return (
                <Grid
                  key={el.id}
                  container
                  spacing={2}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      select
                      size='small'
                      variant='outlined'
                      fullWidth
                      label={t('workSkills')}
                      value={el.discipline}
                      onChange={(e) =>
                        addDisciplineInputs(el.id, e.target.value, 'discipline')
                      }
                    >
                      {workDisciplineArrVar.map((el, i) => {
                        return (
                          <MenuItem key={i} value={el}>
                            {t(el)}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      select
                      size='small'
                      variant='outlined'
                      fullWidth
                      label={t('type')}
                      value={el.type}
                      onChange={(e) =>
                        addDisciplineInputs(el.id, e.target.value, 'type')
                      }
                    >
                      {workDisciplineTypeArr.map((el, i) => {
                        return (
                          <MenuItem key={i} value={el}>
                            {t(el)}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Grid>

                  {/* Remove work skill */}
                  <Grid item xs={12} sm={6} md={3}>
                    <Tooltip
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('removeWorkSkills')}
                    >
                      <div
                        className='addNewEmployee__addRemove'
                        onClick={() => removeDiscipline(i)}
                      >
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faTrash}
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            })}

            <div
              className='addNewEmployee__addRemove'
              onClick={() => addDiscipline()}
            >
              <FontAwesomeIcon className='icon' icon={faPlus} />
              <span>{t('addWorkSkills')}</span>
            </div>

            <Grid container spacing={2} style={{ marginTop: 40 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  className={classes.selectStyle}
                  classes={{ root: classes.labelRoot }}
                  variant='outlined'
                  fullWidth
                  label={t('selectWhatOurSevicesYouAreInterested')}
                  size='small'
                  value={servicesInterestedIn}
                  onChange={(e) => {
                    setServicesInterestedIn(e.target.value)
                  }}
                >
                  {servicesInteresteIn.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {/* Additional requirements */}
            <div
              style={{
                marginTop: 40,
                color: systemColors.lighGrey,
                fontSize: 17
              }}
            >
              {t('additionalRequirements')}
            </div>
            <Divider />
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              {/* Education */}
              <Grid item xs={12} md={3}>
                <TextField
                  select
                  className={classes.selectStyle}
                  classes={{ root: classes.labelRoot }}
                  variant='outlined'
                  fullWidth
                  label={t('education')}
                  size='small'
                  value={education}
                  onChange={(e) => {
                    setEducation(e.target.value)
                  }}
                >
                  {educationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Speciality */}
              <Grid item xs={12} md={3}>
                <TextField
                  select
                  className={classes.selectStyle}
                  classes={{ root: classes.labelRoot }}
                  variant='outlined'
                  fullWidth
                  label={t('speciality')}
                  size='small'
                  value={speciality}
                  onChange={(e) => {
                    setSpeciality(e.target.value)
                  }}
                >
                  {specialityOptions.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Available certificates */}
              <Grid item xs={12} md={3}>
                <FormControl
                  fullWidth
                  className={classes.selectStyle}
                  classes={{ root: classes.selectLabelRoot }}
                  size='small'
                  variant='outlined'
                >
                  <InputLabel id='markAvailableCrertificates'>
                    {t('markAvailableCrertificates')}
                  </InputLabel>
                  <Select
                    labelId='markAvailableCrertificates'
                    multiple
                    fullWidth
                    value={availableCertificates}
                    onChange={(e) => handleAvailableCertificateInputCahnges(e)}
                    input={
                      <OutlinedInput label={t('markAvailableCrertificates')} />
                    }
                    renderValue={(selected) => {
                      return (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 2,
                            width: 300
                          }}
                        >
                          {selected.map((value) => {
                            if (value !== 'addNewCertificate') {
                              return <Chip key={value} label={t(value)} />
                            } else {
                              return null
                            }
                          })}
                        </Box>
                      )
                    }}
                  >
                    {mergetAvailableCertificates.map((el, i) => {
                      if (el !== 'addNewCertificate') {
                        return (
                          <MenuItem
                            key={i}
                            value={el}
                            style={{ display: 'flex', alignContent: 'center' }}
                          >
                            <Checkbox
                              color='default'
                              classes={{ root: classes.checkbox }}
                              checked={
                                availableCertificates !== undefined &&
                                availableCertificates.indexOf(el) > -1
                              }
                            />
                            <ListItemText primary={t(el)} />
                            {currentAgencyAvailableCertificates.includes(el) ? (
                              <Tooltip
                                style={{
                                  marginLeft: 10,
                                  marginRight: 10,
                                  cursor: 'pointer',
                                  zIndex: 1000
                                }}
                                placement='right-end'
                                title={t('remove')}
                              >
                                <div
                                  onClick={() => {
                                    setCertificateForRemoving(el)
                                    setShowRemoveCertificateDialog(true)
                                  }}
                                >
                                  <FontAwesomeIcon
                                    style={{ fontSize: 12 }}
                                    icon={faTrash}
                                  />
                                </div>
                              </Tooltip>
                            ) : null}
                          </MenuItem>
                        )
                      } else {
                        return (
                          <MenuItem
                            key={i}
                            value={el}
                            onClick={() =>
                              el === 'addNewCertificate' &&
                              setOpenNewCertificateDialog(true)
                            }
                          >
                            {t(el)}
                          </MenuItem>
                        )
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}

        {/* Personal skills */}
        {selectedTab === 2 && (
          <div className='editUser__tabSection'>
            {/* Language knowledge */}
            <div className='addNewKeyEmployee__list-title'>
              {t('knowledgeOflanguage')}
            </div>

            {languageKnowlegeArr.map((el, i) => (
              <Grid
                container
                key={el.id}
                spacing={2}
                style={{ marginBottom: 10 }}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    className={classes.selectStyle}
                    classes={{ root: classes.labelRoot }}
                    variant='outlined'
                    fullWidth
                    label={t('language')}
                    size='small'
                    value={el.language}
                    onChange={(e) => {
                      addLanguageKnowledgeInputs(
                        el.id,
                        e.target.value,
                        'language'
                      )
                    }}
                  >
                    {languagesOptions.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    select
                    size='small'
                    variant='outlined'
                    label={t('level')}
                    value={el.level}
                    onChange={(e) =>
                      addLanguageKnowledgeInputs(el.id, e.target.value, 'level')
                    }
                  >
                    {languageKnowlegeLevelsArr.map((el, i) => {
                      return (
                        <MenuItem key={i} value={el}>
                          {t(el)}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>

                {/* Remove language */}
                <Grid item xs={12} sm={6} md={3}>
                  <Tooltip
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      marginTop: 8,
                      cursor: 'pointer'
                    }}
                    placement='right-end'
                    title={t('removeLanguage')}
                  >
                    <div
                      className='addNewEmployee__addRemove'
                      onClick={() => removeLanguageKnowledge(i)}
                    >
                      <FontAwesomeIcon
                        style={{ color: systemColors.lighGrey }}
                        icon={faTrash}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}

            <div
              className='addNewEmployee__addRemove'
              onClick={() => addLanguageKnowledge()}
            >
              <FontAwesomeIcon className='icon' icon={faPlus} />
              <span>{t('addLanguage')}</span>
            </div>

            {/* Driver license */}
            <Grid container spacing={2} style={{ marginTop: 50 }}>
              <Grid item xs={12} sm={6} md={3}>
                <div className='addNewKeyEmployee__title'>
                  {t('driverLicence')}
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  select
                  size='small'
                  variant='outlined'
                  label={t('choose')}
                  value={driverLicence}
                  onChange={(e) => setDriverLicence(e.target.value)}
                >
                  {driverLicenceArr.map((el, i) => {
                    return (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            </Grid>

            {/* Can drive */}
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item xs={12} sm={6} md={3}>
                <div className='addNewKeyEmployee__title'>
                  {t('canYouDriveToWork')}
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  select
                  size='small'
                  variant='outlined'
                  label={t('choose')}
                  value={canDrive}
                  onChange={(e) => setCanDrive(e.target.value)}
                >
                  <MenuItem value='yes'>{t('yes')}</MenuItem>
                  <MenuItem value='no'>{t('no')}</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            {/* Plan to continue to learn */}
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item xs={12} sm={6} md={3}>
                <div className='addNewKeyEmployee__title'>
                  {t('planToCintinueToLearn')}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  select
                  size='small'
                  variant='outlined'
                  label={t('choose')}
                  value={continueLearn}
                  onChange={(e) => setContinueLearn(e.target.value)}
                >
                  <MenuItem value='yes'>{t('yes')}</MenuItem>
                  <MenuItem value='no'>{t('no')}</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </div>
        )}

        {/* Experience */}
        {selectedTab === 3 && (
          <div className='editUser__tabSection'>
            {experienceArr.map((el, i) => (
              <Grid
                container
                key={el.id}
                spacing={2}
                classes={{ root: classes.gridContainer }}
              >
                {/* Workplace */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('workplaceNameCity')}
                    value={el.workplace}
                    onChange={(e) =>
                      addExperienceInput(el.id, e.target.value, 'workplace')
                    }
                  />
                </Grid>

                {/* Country */}
                <Grid item xs={12} sm={6} md={1}>
                  <TextField
                    select
                    size='small'
                    variant='outlined'
                    fullWidth
                    label={t('country')}
                    value={el.country}
                    onChange={(e) => {
                      addExperienceInput(el.id, e.target.value, 'country')
                    }}
                  >
                    {countries.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Positions */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    select
                    size='small'
                    variant='outlined'
                    fullWidth
                    label={t('position')}
                    value={el.position}
                    onChange={(e) => {
                      addExperienceInput(el.id, e.target.value, 'position')
                    }}
                  >
                    {mergetPositionsList.map((el, i) => {
                      if (el !== 'addNewPosition') {
                        return (
                          <MenuItem key={i} value={el}>
                            {t(el)}
                          </MenuItem>
                        )
                      } else {
                        return null
                      }
                    })}
                  </TextField>
                </Grid>

                {/* Date from */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    type='date'
                    InputLabelProps={{
                      shrink: true
                    }}
                    label={t('from')}
                    value={el.dateFrom}
                    onChange={(e) =>
                      addExperienceInput(el.id, e.target.value, 'dateFrom')
                    }
                  />
                </Grid>

                {/* Date till */}
                <Grid item xs={12} sm={6} md={2}>
                  {!el.stillWorking && (
                    <TextField
                      fullWidth
                      size='small'
                      variant='outlined'
                      type='date'
                      InputLabelProps={{
                        shrink: true
                      }}
                      label={t('till')}
                      value={el.dateTill}
                      onChange={(e) =>
                        addExperienceInput(el.id, e.target.value, 'dateTill')
                      }
                    />
                  )}
                  <div>
                    <Checkbox
                      color='default'
                      checked={el.stillWorking}
                      classes={{ root: classes.checkbox }}
                      onChange={(e) => {
                        addExperienceInput(
                          el.id,
                          !el.stillWorking,
                          'stillWorking'
                        )
                      }}
                    />
                    <span>{t('stillWorking')}</span>

                    {/* Info */}
                    <Tooltip
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('markIfYouStillWorkingOnThisPosition')}
                    >
                      <span>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faInfoCircle}
                        />
                      </span>
                    </Tooltip>
                  </div>
                </Grid>

                {/* Main responsibilities */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    variant='outlined'
                    type='text'
                    label={t('mainResponsibilities')}
                    value={el.workOccupation}
                    multiline
                    rows={2}
                    onChange={(e) =>
                      addExperienceInput(
                        el.id,
                        e.target.value,
                        'workOccupation'
                      )
                    }
                    fullWidth
                  />
                </Grid>

                {/*
                 * Buttons
                 */}
                <Grid item xs={12} sm={6} md={1}>
                  <div style={{ display: 'flex' }}>
                    {/*
                     * Remove
                     */}
                    <Tooltip
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('removeExperience')}
                    >
                      <div
                        className='addNewEmployee__addRemove'
                        onClick={() => removeExperience(i)}
                      >
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faTrash}
                        />
                      </div>
                    </Tooltip>

                    {/*
                     * Copy
                     */}
                    <Tooltip
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('copy_experience')}
                    >
                      <div
                        className='addNewEmployee__addRemove'
                        onClick={() => copyExperience(el)}
                      >
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faCopy}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            ))}

            <div
              className='addNewEmployee__addRemove'
              onClick={() => addExperience()}
            >
              <FontAwesomeIcon className='icon' icon={faPlus} />
              <span>{t('addExperience')}</span>
            </div>
          </div>
        )}

        {/* Documents */}
        {selectedTab === 4 && (
          <div className='editUser__tabSection'>
            {/* CV upload */}
            <Grid
              container
              spacing={2}
              classes={{ root: classes.gridContainer }}
            >
              <Grid item xs={12} sm={6} md={2}>
                <div>{t('uploadCv')}</div>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {cvFileURL === '' ? (
                  <div>
                    {/*
                     * Upload file input
                     */}
                    {!uploadingCvStatus && (
                      <TextField
                        size='small'
                        variant='outlined'
                        type='file'
                        label={t('upload')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(e) => {
                          const file = e.target.files
                          if (file.length > 0) {
                            if (file[0].size > acceptedFileSize) {
                              setCvFileSizeErr(true)
                            } else {
                              uploadCvFile(file)
                              setCvFileSizeErr(false)
                            }
                          }
                        }}
                        fullWidth
                        inputProps={{
                          accept: '.png, .jpg, .jpeg, .pdf'
                        }}
                        helperText={
                          cvFileSizeErr ? t('toBigFile') : t('acceptFormat2')
                        }
                        error={!!cvFileSizeErr}
                      />
                    )}
                    {/*
                     * Upload file status
                     */}
                    {uploadingCvStatus && <FileUploadLoader />}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <a
                      href={cvFileURL}
                      target='_blank'
                      rel='noopener noreferrer'
                      download
                    >
                      {t('download')}
                    </a>
                    <Tooltip
                      style={{ marginLeft: 20, cursor: 'pointer' }}
                      placement='right-end'
                      title={t('deleteCvFile')}
                    >
                      <div onClick={() => deleteCVFile()}>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faTrash}
                        />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </Grid>
            </Grid>

            {/* Cerificates and diplomas upload */}
            <div
              className='addNewKeyEmployee__list-title'
              style={{ marginTop: 80 }}
            >
              {t('uploadCertificatesDiplomas')}
            </div>

            {certificatesFilesArr.map((el, i) => (
              <Grid
                container
                key={el.id}
                spacing={2}
                classes={{ root: classes.gridContainer }}
              >
                {/* File name */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('fileName')}
                    value={el.fileName}
                    onChange={(e) =>
                      addCertificate(el.id, e.target.value, 'fileName')
                    }
                  />
                </Grid>

                {/* Issue date */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    type='date'
                    label={t('issueDate')}
                    value={el.issueDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) =>
                      addCertificate(el.id, e.target.value, 'issueDate')
                    }
                  />
                </Grid>

                {/* Expire date */}
                <Grid item xs={12} sm={6} md={2} style={{ display: 'flex' }}>
                  <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    type='date'
                    label={t('expireDate')}
                    value={el.expireDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) =>
                      addCertificate(el.id, e.target.value, 'expireDate')
                    }
                  />

                  <Tooltip
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      marginTop: 8,
                      cursor: 'pointer'
                    }}
                    placement='right-end'
                    title={t('ifExpireDateEmptyThanUnlimited')}
                  >
                    <div>
                      <FontAwesomeIcon
                        style={{ color: systemColors.lighGrey }}
                        icon={faInfoCircle}
                      />
                    </div>
                  </Tooltip>
                </Grid>

                {/* File upload */}
                <Grid item xs={12} sm={6} md={3} style={{ display: 'flex' }}>
                  {el.fileName !== '' &&
                  (el.file === '' || el.file === null) ? (
                    <div>
                      <TextField
                        size='small'
                        variant='outlined'
                        type='file'
                        label={t('upload')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(e) => {
                          const file = e.target.files
                          if (file.length > 0) {
                            if (file[0].size > acceptedFileSize) {
                              setFileSizeError(i)
                            } else {
                              addCertificate(el.id, file, 'file')
                            }
                          }
                        }}
                        fullWidth
                        inputProps={{
                          accept: '.png, .jpg, .jpeg, .pdf'
                        }}
                        helperText={
                          el.fileSizeError ? t('toBigFile') : t('acceptFormat2')
                        }
                        error={el.fileSizeError}
                      />
                    </div>
                  ) : el.fileName !== '' && el.replaceDocument !== true ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {getCertificateFileURL(el.id) !== '' ? (
                        <a
                          href={getCertificateFileURL(el.id)}
                          target='_blank'
                          rel='noopener noreferrer'
                          download
                        >
                          {t('download')}
                        </a>
                      ) : (
                        <div>
                          {!uploadingFile && <span>{t('noFileAttached')}</span>}

                          {/*
                           * Loader
                           */}
                          {uploadingFile && uploadingFileId === el.id && (
                            <FileUploadLoader />
                          )}
                        </div>
                      )}
                    </div>
                  ) : el.replaceDocument === true ? (
                    <TextField
                      fullWidth
                      size='small'
                      variant='outlined'
                      type='file'
                      label={t('upload')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        const file = e.target.files
                        if (file.length > 0) {
                          if (file[0].size > acceptedFileSize) {
                            setFileSizeError(i)
                          } else {
                            setNewCertificateMsg(true)
                            setNewDocumentData({
                              id: el.id,
                              file
                            })
                          }
                        }
                      }}
                      inputProps={{
                        accept: '.png, .jpg, .jpeg, .pdf'
                      }}
                      helperText={
                        el.fileSizeError ? t('toBigFile') : t('acceptFormat2')
                      }
                      error={el.fileSizeError}
                    />
                  ) : (
                    <div style={{ marginTop: 8 }}>{t('addFileNameFirst')}</div>
                  )}

                  {/* Replace file */}
                  {el.file !== '' &&
                  el.file !== null &&
                  el.replaceDocument !== true ? (
                    <Tooltip
                      style={{
                        marginLeft: 20,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('replaceFileWithAnother')}
                    >
                      <div onClick={() => replaceDocumentWithNew(i)}>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faSyncAlt}
                        />
                      </div>
                    </Tooltip>
                  ) : el.fileName !== '' &&
                    el.file !== '' &&
                    el.file !== null ? (
                    <Tooltip
                      style={{
                        marginLeft: 20,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('cancelReplaceFileWithAnother')}
                    >
                      <div onClick={() => cancelDocumentReplaceWithNew(i)}>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faTimes}
                        />
                      </div>
                    </Tooltip>
                  ) : null}

                  {/* Delete document */}
                  <Tooltip
                    style={{ marginLeft: 20, marginTop: 8, cursor: 'pointer' }}
                    placement='right-end'
                    title={t('deleteDocument')}
                  >
                    <div onClick={() => removeCertificateInput(i, el.file)}>
                      <FontAwesomeIcon
                        style={{ color: systemColors.lighGrey }}
                        icon={faTrash}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}

            <div
              className='addNewEmployee__addRemove'
              onClick={() => addCertificateInput()}
            >
              <FontAwesomeIcon className='icon' icon={faPlus} />
              <span>{t('addDocument')}</span>
            </div>

            {/* References */}
            <div
              className='addNewKeyEmployee__list-title'
              style={{ marginTop: 100 }}
            >
              {t('uploadReferencies')}
            </div>

            {referenciesFilesArr.map((el, i) => (
              <Grid
                container
                key={el.id}
                spacing={2}
                classes={{ root: classes.gridContainer }}
              >
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('fileName')}
                    value={el.fileName}
                    onChange={(e) =>
                      addReferency(el.id, e.target.value, 'fileName')
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} style={{ display: 'flex' }}>
                  {el.fileName !== '' &&
                  (el.file === '' || el.file === null) ? (
                    <TextField
                      fullWidth
                      size='small'
                      variant='outlined'
                      type='file'
                      label={t('upload')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        const file = e.target.files
                        if (file.length > 0) {
                          if (file[0].size > acceptedFileSize) {
                            setReferenceFileSizeError(i)
                          } else {
                            addReferency(el.id, file, 'file')
                          }
                        }
                      }}
                      inputProps={{
                        accept: '.png, .jpg, .jpeg, .pdf'
                      }}
                      helperText={
                        el.fileSizeError ? t('toBigFile') : t('acceptFormat2')
                      }
                      error={el.fileSizeError}
                    />
                  ) : el.fileName !== '' && el.replaceReference !== true ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {getReferenceFileURL(el.id) !== '' ? (
                        <a
                          href={getReferenceFileURL(el.id)}
                          target='_blank'
                          rel='noopener noreferrer'
                          download
                        >
                          {t('download')}
                        </a>
                      ) : (
                        <div>
                          {!uploadingFile && <span>{t('noFileAttached')}</span>}

                          {/*
                           * Loader
                           */}
                          {uploadingFile && uploadingFileId === el.id && (
                            <FileUploadLoader />
                          )}
                        </div>
                      )}
                    </div>
                  ) : el.replaceReference === true ? (
                    <TextField
                      fullWidth
                      size='small'
                      variant='outlined'
                      type='file'
                      label={t('upload')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        const file = e.target.files
                        if (file.length > 0) {
                          if (file[0].size > acceptedFileSize) {
                            setReferenceFileSizeError(i)
                          } else {
                            setNewReferenceMsg(true)
                            setNewDocumentData({
                              id: el.id,
                              file
                            })
                          }
                        }
                      }}
                      inputProps={{
                        accept: '.png, .jpg, .jpeg, .pdf'
                      }}
                      helperText={
                        el.fileSizeError ? t('toBigFile') : t('acceptFormat2')
                      }
                      error={el.fileSizeError}
                    />
                  ) : (
                    <div style={{ marginTop: 8 }}>{t('addFileNameFirst')}</div>
                  )}

                  {/* Replace file */}
                  {el.file !== '' &&
                  el.file !== null &&
                  el.replaceReference !== true ? (
                    <Tooltip
                      style={{
                        marginLeft: 20,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('replaceFileWithAnother')}
                    >
                      <div onClick={() => replaceReferenceWithNew(i)}>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faSyncAlt}
                        />
                      </div>
                    </Tooltip>
                  ) : el.fileName !== '' &&
                    el.file !== '' &&
                    el.file !== null ? (
                    <Tooltip
                      style={{
                        marginLeft: 20,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('cancelReplaceFileWithAnother')}
                    >
                      <div onClick={() => cancelReferenceReplaceWithNew(i)}>
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faTimes}
                        />
                      </div>
                    </Tooltip>
                  ) : null}

                  {/* Delete reference */}
                  <Tooltip
                    style={{ marginLeft: 20, marginTop: 8, cursor: 'pointer' }}
                    placement='right-end'
                    title={t('deleteDocument')}
                  >
                    <div onClick={() => removeReferencyInput(i, el.file)}>
                      <FontAwesomeIcon
                        style={{ color: systemColors.lighGrey }}
                        icon={faTrash}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}

            <div
              className='addNewEmployee__addRemove'
              onClick={() => addReferencyInput()}
            >
              <FontAwesomeIcon className='icon' icon={faPlus} />
              <span>{t('addReference')}</span>
            </div>
          </div>
        )}

        {/* Remarks */}
        {selectedTab === 5 && (
          <div className='editUser__tabSection'>
            <Grid
              container
              spacing={2}
              classes={{ root: classes.gridContainer }}
            >
              <TextField
                fullWidth
                size='small'
                variant='outlined'
                type='text'
                value={comment}
                label={t('comment')}
                onChange={(e) => setComment(e.target.value)}
                multiline
                rows={5}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              classes={{ root: classes.gridContainer }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <div className='addNewKeyEmployee__title'>
                  {t('howFastYouCanStart')}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  select
                  label={t('choose')}
                  value={whenCanStart}
                  onChange={(e) => setWhenCanStart(e.target.value)}
                >
                  {whenCanStartArr.map((el, i) => {
                    return (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              classes={{ root: classes.gridContainer }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <div className='addNewKeyEmployee__title'>
                  {t('howFindOutAboutUs')}
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  select
                  label={t('choose')}
                  value={howFindOut}
                  onChange={(e) => setHowFindOut(e.target.value)}
                >
                  {howFindOutAboutUsArr.map((el, i) => {
                    return (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj
  }
}

export default connect(mapStateToProps)(KeyEditEmployee)
