// Height array
export const height = [
  '140-150',
  '150-160',
  '160-170',
  '170-180',
  '180-190',
  '200-210',
  '210-220'
]

// Weight array
export const weight = [
  '50-60',
  '60-70',
  '70-80',
  '80-90',
  '90-100',
  '100-110',
  '110-120',
  '120-130',
  '130 or more'
]

// Shoes size (EU) array
export const shoesSize = [
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50'
]

// Clothes size (EU) array
export const clothesSize = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '2XL',
  '3XL',
  '4XL',
  '5XL'
]

// Hair color array
export const hairColor = ['Black', 'Brown', 'Light Brown', 'Gray', 'Other']

// Eye color array
export const eyeColor = [
  'Blue',
  'Brown',
  'Brown - Grey',
  'Dark - Grey',
  'Grey',
  'Other'
]

// Stystem colors for Material-UI
export const systemColors = {
  white: '#ffffff',
  darkBlue: '#215384',
  lightBlue: '#43688e',
  orange: '#e03b22',
  orangeDark: '#d6371f',
  lighGrey: '#737372',
  darkGrey: '#333333',
  errorColor: '#f72525',
  inputColor: '#e6f4fd',
  green: '#78A10A',
  lightGreen: '#9ED30D',
  background: '#f0f0f0',
  electric: '#479dba',
  lightElectric: '#7ab8cd',
  grey: '#a8a8a8',
  veryLightGrey: '#e0e0e0',
  // Status colors
  statusPlanned: '#f6ae2d',
  statusAvailable: '#b95f89',
  statusAtHome: '#b85137',
  statusAtWork: '#618b25',
  statusMedicine: '#ee6c4d',
  statusCourses: '#2f4856'
}

export const systemColorsDark = {
  veryLightGrey: '#cccccc'
}

// System styling setting for Material-UI
export const systemStylingSettings = {
  borderRadius: 2,
  borderWidth: 1
}

// Maritial status options
export const maritalStatusArray = ['Married', 'Divorced', 'Single', 'Widowed']

// English level options
export const englishLevelArr = [
  'Very good',
  'Good',
  'Satisfactory',
  'Poor',
  'None'
]

// Accepted file size in bytes
export const acceptedFileSize = 4194304

// Work disciplines
export const workDisciplineArrVar = [
  'isolation',
  'wallPanelsInstallation',
  'ceilingPanelsInstallation',
  'doorsInstallation',
  'installationOfFurniture',
  'woodworks',
  'concreteWorks',
  'floorLevelingWithAbs',
  'tiling',
  'floorCovering',
  'metalWorks',
  'pipeInsulation',
  'tinningPipe',
  'pipeFitting',
  'ironWelding',
  'installationOfVentilationSystem',
  'coldStoreWorker',
  'coldStoreManager',
  'electricalEngineerAutomatization',
  'mechanicalTechnician',
  'others'
]

// Work discipline types
export const workDisciplineTypeArr = [
  'beginnerWorker',
  'helper',
  'IndependentWorker',
  'independentWorkerWithExperienceHead'
]

// Language knowledge levels
export const languageKnowlegeLevelsArr = [
  'non',
  'poorJustBasics',
  'understandAbleToAnswer',
  'ableToComunicate',
  'easyComunicateInGeneral'
]

// Driver Licence answers
export const driverLicenceArr = [
  'bCategory',
  'cCategory',
  'ceCategory',
  'dontHave'
]

// When can start answers
export const whenCanStartArr = ['oneTwoWeeks', 'twoFourWeeks', 'fiveEightWeeks']

// How to find out abaut us answers
export const howFindOutAboutUsArr = [
  'advertisement',
  'facebook',
  'newsletter',
  'recommendedByFriend',
  'other'
]

export const agencyTypes = ['crew', 'key']

export const userTypes = ['employee', 'crewagency', 'keyagency', 'partner']

export const userStatus = ['active', 'readOnly', 'disabled']

// Sign off reason for evaluation form
export const signOffReason = ['endOfTheContract', 'medical', 'disciplinary']

// Companies subsystem
export const companyModes = ['negotiation', 'target', 'lost', 'customer']

// Report reasons for evaluation form
export const reportReasons = [
  'chiefOfResponsiblePersonForWorkerSignOff',
  'keyLandWorkersSignsOff',
  'preEmploymentTesting'
]

// Evaluation form evaluation options
export const evaluationVariants = [
  'poor',
  'improvementNeeded',
  'satisfactory',
  'good',
  'exceptional'
]

// Key subsystem add or edit employee field options in Work skills
export const servicesInteresteIn = [
  'permanentJob',
  'jobsAbroad',
  'longTermJob',
  'seasonal',
  'temporary',
  'partTime'
]

// Key subsystem add or edit employee education field options
export const educationVariants = [
  'higherEducation',
  'unfinishedHigherEducation',
  'nonUniversityHigherEducation',
  'vocationalEducation',
  'secondaryEducation',
  'lowerSecondaryEducation',
  'studyingAtTheMoment'
]

// Key subsystem add or edit employee speciality options
export const specialityOptions = [
  'economicsBusinessManagement',
  'electronicsCommunications',
  'philology',
  'financeAccounting',
  'productionManufacturing',
  'informationTechnology',
  'engineering',
  'cultureArtHistory',
  'pedagogy',
  'tradeServiceIndustry',
  'socialSciences',
  'constructions',
  'heathCareMedicine',
  'lawProtectiveServiceMilitary',
  'transportation',
  'agricultural',
  'media',
  'other'
]

export const languagesOptions = [
  'Abkhazian',
  'Afar',
  'Afrikaans',
  'Akan',
  'Albanian',
  'Amharic',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Assamese',
  'Avaric',
  'Avestan',
  'Aymara',
  'Azerbaijani',
  'Bambara',
  'Bashkir',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bihari languages',
  'Bislama',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan, Valencian',
  'Central Khmer',
  'Chamorro',
  'Chechen',
  'Chichewa, Chewa, Nyanja',
  'Chinese',
  'Church Slavonic, Old Bulgarian, Old Church Slavonic',
  'Chuvash',
  'Cornish',
  'Corsican',
  'Cree',
  'Croatian',
  'Czech',
  'Danish',
  'Divehi, Dhivehi, Maldivian',
  'Dutch, Flemish',
  'Dzongkha',
  'English',
  'Esperanto',
  'Estonian',
  'Ewe',
  'Faroese',
  'Fijian',
  'Finnish',
  'French',
  'Fulah',
  'Gaelic, Scottish Gaelic',
  'Galician',
  'Ganda',
  'Georgian',
  'German',
  'Gikuyu, Kikuyu',
  'Greek (Modern)',
  'Greenlandic, Kalaallisut',
  'Guarani',
  'Gujarati',
  'Haitian, Haitian Creole',
  'Hausa',
  'Hebrew',
  'Herero',
  'Hindi',
  'Hiri Motu',
  'Hungarian',
  'Icelandic',
  'Ido',
  'Igbo',
  'Indonesian',
  'Interlingua (International Auxiliary Language Association)',
  'Interlingue',
  'Inuktitut',
  'Inupiaq',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kannada',
  'Kanuri',
  'Kashmiri',
  'Kazakh',
  'Kinyarwanda',
  'Komi',
  'Kongo',
  'Korean',
  'Kwanyama, Kuanyama',
  'Kurdish',
  'Kyrgyz',
  'Lao',
  'Latin',
  'Latvian',
  'Letzeburgesch, Luxembourgish',
  'Limburgish, Limburgan, Limburger',
  'Lingala',
  'Lithuanian',
  'Luba-Katanga',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Manx',
  'Maori',
  'Marathi',
  'Marshallese',
  'Moldovan, Moldavian, Romanian',
  'Mongolian',
  'Nauru',
  'Navajo, Navaho',
  'Northern Ndebele',
  'Ndonga',
  'Nepali',
  'Northern Sami',
  'Norwegian',
  'Norwegian Bokmål',
  'Norwegian Nynorsk',
  'Nuosu, Sichuan Yi',
  'Occitan (post 1500)',
  'Ojibwa',
  'Oriya',
  'Oromo',
  'Ossetian, Ossetic',
  'Pali',
  'Panjabi, Punjabi',
  'Pashto, Pushto',
  'Persian',
  'Polish',
  'Portuguese',
  'Quechua',
  'Romansh',
  'Rundi',
  'Russian',
  'Samoan',
  'Sango',
  'Sanskrit',
  'Sardinian',
  'Serbian',
  'Shona',
  'Sindhi',
  'Sinhala, Sinhalese',
  'Slovak',
  'Slovenian',
  'Somali',
  'Sotho, Southern',
  'South Ndebele',
  'Spanish, Castilian',
  'Sundanese',
  'Swahili',
  'Swati',
  'Swedish',
  'Tagalog',
  'Tahitian',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tigrinya',
  'Tonga (Tonga Islands)',
  'Tsonga',
  'Tswana',
  'Turkish',
  'Turkmen',
  'Twi',
  'Uighur, Uyghur',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Venda',
  'Vietnamese',
  'Volap_k',
  'Walloon',
  'Welsh',
  'Western Frisian',
  'Wolof',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zhuang, Chuang',
  'Zulu'
]

// Employee status for rotation log in Key subsystem
export const rotationLogStatus = [
  'planned',
  'available',
  'atHome',
  'atWork',
  'medicine',
  'courses'
]

export const profileStatus = ['active', 'notActive']

// Source options for adding new company to Companies subsystem
export const sourceOptions = [
  'google',
  'internetSite',
  'contactedHimself',
  'email',
  'presentation',
  'other'
]

// Months names
export const MONTH_NAME = {
  1: 'january',
  2: 'february',
  3: 'march',
  4: 'april',
  5: 'may',
  6: 'june',
  7: 'july',
  8: 'august',
  9: 'september',
  10: 'october',
  11: 'november',
  12: 'december'
}

// Payment type for expense tracking
export const paymentType = ['card', 'privateCard', 'cash', 'bankTransfer']
