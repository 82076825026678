import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDoubleLeft,
  faUserFriends,
  faChartBar,
  faShip,
  faCalendarAlt,
  faPowerOff,
  faBuilding,
  faProjectDiagram,
  faUserShield,
  faIdBadge,
  faUserTie,
  faUserPlus,
  faCity,
  faCogs,
  faAddressCard,
  faColumns,
  faUserCog,
  faUserLock,
  faBriefcase,
  faTasks,
  faBell
} from '@fortawesome/free-solid-svg-icons'
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Collapse
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import OpenMenu from './openMenu'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase/app'
import { isAuth } from '../redux/actions/session/isAuth'
import { systemColors } from '../globalVariables'
import logo from '../media/logo/seanor_logo.svg'
import './agencyMenu.scss'
import { languages } from '../languages'

const db = firebase.firestore()

const useStyles = makeStyles({
  drawerPaper: {
    width: 240,
    backgroundColor: systemColors.background,
    color: systemColors.darkGrey,
    fontSize: 14
  },
  icons: {
    color: systemColors.electric,
    minWidth: '30px !important'
  },
  nestedList: {
    paddingLeft: '30px !important'
  },
  nestedListThird: {
    paddingLeft: '60px !important'
  },
  nestedSubList: {
    paddingLeft: '50px !important'
  },
  boldText: {
    fontWeight: 'bold'
  },
  inactive: {
    opacity: 0.6
  }
})

const AdminMenu = ({ agencyType, agencyData }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const [openSubMenu, setOpenSubMenu] = useState(false)
  const [openUsersSubmenu, setOpenUsersSubmenu] = useState(false)

  return (
    <div>
      <ListItem button onClick={() => setOpenSubMenu(!openSubMenu)}>
        <ListItemIcon className={classes.icons}>
          <FontAwesomeIcon icon={faUserShield} />
        </ListItemIcon>
        <ListItemText primary={t('admin')} />
      </ListItem>
      <Collapse in={openSubMenu} timeout='auto'>
        <List component='div' disablePadding>
          <ListItem
            className={classes.nestedList}
            button
            onClick={() => setOpenUsersSubmenu(!openUsersSubmenu)}
          >
            <ListItemIcon className={classes.icons}>
              <FontAwesomeIcon icon={faIdBadge} />
            </ListItemIcon>
            <ListItemText primary={t('users')} />
          </ListItem>
          <Collapse in={openUsersSubmenu} timeout='auto'>
            <List component='div' disablePadding>
              <ListItem
                className={classes.nestedSubList}
                button
                onClick={() =>
                  history.push(`/${agencyData.id}/admin/user/add-agency`, {
                    menuType: agencyType
                  })
                }
              >
                <ListItemIcon className={classes.icons}>
                  <FontAwesomeIcon icon={faUserTie} />
                </ListItemIcon>
                <ListItemText primary={t('addAgency')} />
              </ListItem>
              <ListItem
                className={classes.nestedSubList}
                button
                onClick={() =>
                  history.push(`/${agencyData.id}/admin/user/add-user`, {
                    menuType: agencyType
                  })
                }
              >
                <ListItemIcon className={classes.icons}>
                  <FontAwesomeIcon icon={faUserPlus} />
                </ListItemIcon>
                <ListItemText primary={t('addUser')} />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Collapse>
    </div>
  )
}

const AgencyMenu = ({ agencyData, setIsAuth, type, agencyUserData }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const userUID = firebase.auth().currentUser.uid

  const [menuOpen, setMenuOpen] = useState(false)
  const [userRole, setUserRole] = useState(null)
  const [openCompaniesMenu, setOpenCompaniesMenu] = useState(false)
  const [openSettingsMenu, setopenSettingsMenu] = useState(false)
  const [openVesselsMenu, setOpenVesselsMenu] = useState(false)
  const [openReportsMenu, setOpenReportsMenu] = useState(false)
  const [openMySettingsMenu, setOpenMySettingsMenu] = useState(false)
  const [openRecruitmentMenu, setOpenRecruitmentMenu] = useState(false)

  useEffect(() => {
    let shouldUpdate = true
    const getUserData = () => {
      db.collection('users')
        .doc(userUID)
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          if (shouldUpdate) {
            setUserRole(data.data.userRole)
          }
        })
        .catch((error) => {
          console.log("Can't get user data to agency menu component", error)
        })
    }
    getUserData()

    return () => {
      shouldUpdate = false
    }
  }, [userUID])

  // Log out user and redirect to sign in page
  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setIsAuth(false)
        history.push(`/${agencyData.id}`)
      })
      .catch((err) => {
        console.log('Sign out error', err)
      })
  }

  // Change frontend language
  const selectLanguage = (val) => {
    i18n.changeLanguage(val)
  }

  return (
    <div>
      <OpenMenu click={() => setMenuOpen(true)} />

      <Drawer
        anchor='left'
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div
          className='crewAgencyPage__closeDrawer'
          onClick={() => setMenuOpen(false)}
        >
          <FontAwesomeIcon className='ixon' icon={faAngleDoubleLeft} />{' '}
          <span>{t('close')}</span>
        </div>
        <div className='agencyMenu__logo'>
          <img src={logo} alt='logo' />
        </div>
        <div className='crewAgencyPage__drawer-title'>
          {type === 'crew' ? (
            <div>{t('crewManager')}</div>
          ) : type === 'key' ? (
            <div>{t('keyManager')}</div>
          ) : null}
        </div>
        <Divider />

        {/* 
          CREW 
        */}
        {type === 'crew' ? (
          <List>
            {/* Dashboard */}
            <ListItem button>
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faColumns} />
              </ListItemIcon>
              <ListItemText
                primary={t('dashboard')}
                onClick={() => history.push(`/${agencyData.id}/crew-dashboard`)}
              />
            </ListItem>

            {/* Crew database */}
            <ListItem button>
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faUserFriends} />
              </ListItemIcon>
              <ListItemText
                primary={t('crewDatabase')}
                onClick={() => history.push(`/${agencyData.id}/crew-database`)}
              />
            </ListItem>

            {/* Vessels */}
            <ListItem
              button
              onClick={() => setOpenVesselsMenu(!openVesselsMenu)}
            >
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faShip} />
              </ListItemIcon>
              <ListItemText primary={t('companiesVessels')} />
            </ListItem>
            <Collapse in={openVesselsMenu} timeout='auto'>
              <List>
                <ListItem
                  className={classes.nestedList}
                  button
                  onClick={() =>
                    history.push(`/${agencyData.id}/vessels/add-new-vessel`)
                  }
                >
                  <ListItemIcon className={classes.icons}>
                    <FontAwesomeIcon icon={faBuilding} />
                  </ListItemIcon>
                  <ListItemText primary={t('addNewCompany')} />
                </ListItem>
                <ListItem
                  className={classes.nestedList}
                  button
                  onClick={() =>
                    history.push(`/${agencyData.id}/vessels/all-vessels`)
                  }
                >
                  <ListItemIcon className={classes.icons}>
                    <FontAwesomeIcon icon={faCity} />
                  </ListItemIcon>
                  <ListItemText primary={t('allCompanies')} />
                </ListItem>
              </List>
            </Collapse>

            {/* Payroll */}
            <ListItem className={classes.inactive} button>
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </ListItemIcon>
              <ListItemText primary={t('payroll')} />
            </ListItem>

            {/* Reports */}
            <ListItem
              button
              onClick={() => setOpenReportsMenu(!openReportsMenu)}
            >
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faChartBar} />
              </ListItemIcon>
              <ListItemText primary={t('reports')} />
            </ListItem>
            <Collapse in={openReportsMenu} timeout='auto'>
              <List component='div' disablePadding>
                {/* Client report */}
                <ListItem
                  className={classes.nestedList}
                  button
                  onClick={() =>
                    history.push(`/${agencyData.id}/reports/crew/client-report`)
                  }
                >
                  <ListItemIcon className={classes.icons}>
                    <FontAwesomeIcon icon={faAddressCard} />
                  </ListItemIcon>
                  <ListItemText primary={t('clientReport')} />
                </ListItem>
              </List>
            </Collapse>

            {/* Settings */}
            <ListItem
              button
              onClick={() => setopenSettingsMenu(!openSettingsMenu)}
            >
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faCogs} />
              </ListItemIcon>
              <ListItemText primary={t('settings')} />
            </ListItem>
            <Collapse in={openSettingsMenu} timeout='auto'>
              <List component='div' disablePadding>
                {/* Main control */}
                {(userRole === 'super_admin' ||
                  userRole === 'agency_admin') && (
                  <ListItem
                    className={classes.nestedList}
                    button
                    onClick={() =>
                      history.push(
                        `/${agencyData.id}/settings/crew/company-settings`
                      )
                    }
                  >
                    <ListItemIcon className={classes.icons}>
                      <FontAwesomeIcon icon={faBuilding} />
                    </ListItemIcon>
                    <ListItemText primary={t('mainControl')} />
                  </ListItem>
                )}

                {/* User roles */}
                {/* {(userRole === 'super_admin' ||
                  userRole === 'agency_admin') && (
                  <ListItem
                    className={classes.nestedList}
                    button
                    onClick={() =>
                      history.push(
                        `/${agencyData.id}/settings/company-settings/crew-user-roles`
                      )
                    }
                  >
                    <ListItemIcon className={classes.icons}>
                      <FontAwesomeIcon icon={faUserLock} />
                    </ListItemIcon>
                    <ListItemText primary={t('userRoles')} />
                  </ListItem>
                )} */}

                {/* My settings */}
                <ListItem
                  className={classes.nestedList}
                  button
                  onClick={() => setOpenMySettingsMenu(!openMySettingsMenu)}
                >
                  <ListItemIcon className={classes.icons}>
                    <FontAwesomeIcon icon={faUserCog} />
                  </ListItemIcon>
                  <ListItemText primary={t('mySettings')} />
                </ListItem>
                <Collapse in={openMySettingsMenu} timeout='auto'>
                  <List component='div' disablePadding>
                    <ListItem
                      className={classes.nestedListThird}
                      button
                      onClick={() =>
                        history.push(
                          `/${agencyData.id}/settings/crew-dashboard-settings`
                        )
                      }
                    >
                      <ListItemIcon className={classes.icons}>
                        <FontAwesomeIcon icon={faColumns} />
                      </ListItemIcon>
                      <ListItemText primary={t('dashboard')} />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Collapse>

            {/* Administration */}
            {userRole === 'super_admin' && (
              <AdminMenu agencyType={type} agencyData={agencyData} />
            )}

            {/* Log out button */}
            <ListItem button>
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faPowerOff} />
              </ListItemIcon>
              <ListItemText primary={t('logOut')} onClick={() => logOut()} />
            </ListItem>
          </List>
        ) : null}

        {/* 
          KEY 
        */}
        {type === 'key' ? (
          <List>
            {/* Dashboard */}
            <ListItem button>
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faColumns} />
              </ListItemIcon>
              <ListItemText
                primary={t('dashboard')}
                onClick={() => history.push(`/${agencyData.id}/key-dashboard`)}
              />
            </ListItem>

            {/* Employees */}
            <ListItem button>
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faUserFriends} />
              </ListItemIcon>
              <ListItemText
                primary={t('keyDatabase')}
                onClick={() => history.push(`/${agencyData.id}/key-database`)}
              />
            </ListItem>

            {/* Recruitment */}
            <ListItem
              button
              onClick={() => setOpenRecruitmentMenu(!openRecruitmentMenu)}
            >
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </ListItemIcon>
              <ListItemText primary={t('recruitment')} />
            </ListItem>
            <Collapse in={openRecruitmentMenu} timeout='auto'>
              {/* Jobs */}
              <List component='div' disablePadding>
                <ListItem
                  className={classes.nestedList}
                  button
                  onClick={() =>
                    history.push(`/${agencyData.id}/key/recruitment/jobs`)
                  }
                >
                  <ListItemIcon className={classes.icons}>
                    <FontAwesomeIcon icon={faBriefcase} />
                  </ListItemIcon>
                  <ListItemText primary={t('jobs')} />
                </ListItem>
              </List>

              {/* Tasks */}
              <List component='div' disablePadding>
                <ListItem
                  className={classes.nestedList}
                  button
                  onClick={() =>
                    history.push(`/${agencyData.id}/key/recruitment/tasks`)
                  }
                >
                  <ListItemIcon className={classes.icons}>
                    <FontAwesomeIcon icon={faTasks} />
                  </ListItemIcon>
                  <ListItemText primary={t('tasks')} />
                </ListItem>
              </List>
            </Collapse>

            {/* Companies */}
            <ListItem
              button
              onClick={() => setOpenCompaniesMenu(!openCompaniesMenu)}
            >
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faBuilding} />
              </ListItemIcon>
              <ListItemText primary={t('companiesProjects')} />
            </ListItem>
            <Collapse in={openCompaniesMenu} timeout='auto'>
              <List component='div' disablePadding>
                <ListItem
                  className={classes.nestedList}
                  button
                  onClick={() =>
                    history.push(`/${agencyData.id}/companies/add-new-company`)
                  }
                >
                  <ListItemIcon className={classes.icons}>
                    <FontAwesomeIcon icon={faBuilding} />
                  </ListItemIcon>
                  <ListItemText primary={t('addNewCompany')} />
                </ListItem>
                <ListItem
                  className={classes.nestedList}
                  button
                  onClick={() =>
                    history.push(`/${agencyData.id}/companies/all-companies`)
                  }
                >
                  <ListItemIcon className={classes.icons}>
                    <FontAwesomeIcon icon={faCity} />
                  </ListItemIcon>
                  <ListItemText primary={t('allCompanies')} />
                </ListItem>
              </List>
            </Collapse>

            {/* Reports */}
            <ListItem
              button
              onClick={() => setOpenReportsMenu(!openReportsMenu)}
            >
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faProjectDiagram} />
              </ListItemIcon>
              <ListItemText primary={t('reports')} />
            </ListItem>
            <Collapse in={openReportsMenu} timeout='auto'>
              {/* Client report */}
              <List component='div' disablePadding>
                <ListItem
                  className={classes.nestedList}
                  button
                  onClick={() =>
                    history.push(`/${agencyData.id}/reports/key/client-report`)
                  }
                >
                  <ListItemIcon className={classes.icons}>
                    <FontAwesomeIcon icon={faAddressCard} />
                  </ListItemIcon>
                  <ListItemText primary={t('clientReport')} />
                </ListItem>
              </List>

              {/* Company statistics */}
              {userRole === 'agency_admin' ||
              userRole === 'super_admin' ||
              agencyData.settings.globalPermitionsToAgents
                .allowAgentsToSeeCompanyStatistics === true ? (
                <List component='div' disablePadding>
                  <ListItem
                    className={classes.nestedList}
                    button
                    onClick={() =>
                      history.push(
                        `/${agencyData.id}/reports/key/company-statistics`
                      )
                    }
                  >
                    <ListItemIcon className={classes.icons}>
                      <FontAwesomeIcon icon={faChartBar} />
                    </ListItemIcon>
                    <ListItemText primary={t('companyStatistics')} />
                  </ListItem>
                </List>
              ) : null}
            </Collapse>

            {/* Settings */}
            <ListItem
              button
              onClick={() => setopenSettingsMenu(!openSettingsMenu)}
            >
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faCogs} />
              </ListItemIcon>
              <ListItemText primary={t('settings')} />
            </ListItem>
            <Collapse in={openSettingsMenu} timeout='auto'>
              <List component='div' disablePadding>
                {/* Main control */}
                {(userRole === 'super_admin' ||
                  userRole === 'agency_admin') && (
                  <ListItem
                    className={classes.nestedList}
                    button
                    onClick={() =>
                      history.push(
                        `/${agencyData.id}/settings/key/company-settings`
                      )
                    }
                  >
                    <ListItemIcon className={classes.icons}>
                      <FontAwesomeIcon icon={faBuilding} />
                    </ListItemIcon>
                    <ListItemText primary={t('mainControl')} />
                  </ListItem>
                )}

                {/* User roles */}
                {(userRole === 'super_admin' ||
                  userRole === 'agency_admin') && (
                  <ListItem
                    className={classes.nestedList}
                    button
                    onClick={() =>
                      history.push(
                        `/${agencyData.id}/settings/company-settings/key-user-roles`
                      )
                    }
                  >
                    <ListItemIcon className={classes.icons}>
                      <FontAwesomeIcon icon={faUserLock} />
                    </ListItemIcon>
                    <ListItemText primary={t('userRoles')} />
                  </ListItem>
                )}

                {/* My settings */}
                <ListItem
                  className={classes.nestedList}
                  button
                  onClick={() => setOpenMySettingsMenu(!openMySettingsMenu)}
                >
                  <ListItemIcon className={classes.icons}>
                    <FontAwesomeIcon icon={faUserCog} />
                  </ListItemIcon>
                  <ListItemText primary={t('mySettings')} />
                </ListItem>
                <Collapse in={openMySettingsMenu} timeout='auto'>
                  <List component='div' disablePadding>
                    <ListItem
                      className={classes.nestedListThird}
                      button
                      onClick={() =>
                        history.push(
                          `/${agencyData.id}/settings/dashboard-settings`
                        )
                      }
                    >
                      <ListItemIcon className={classes.icons}>
                        <FontAwesomeIcon icon={faColumns} />
                      </ListItemIcon>
                      <ListItemText primary={t('dashboard')} />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Collapse>

            {/* Notifications */}
            <ListItem button>
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faBell} />
              </ListItemIcon>
              <ListItemText
                primary={t('notifications')}
                onClick={() =>
                  history.push(`/${agencyData.id}/key/notifications`)
                }
              />
            </ListItem>

            {/* Admin */}
            {userRole === 'super_admin' && (
              <AdminMenu agencyType={type} agencyData={agencyData} />
            )}
            <ListItem button>
              <ListItemIcon className={classes.icons}>
                <FontAwesomeIcon icon={faPowerOff} />
              </ListItemIcon>
              <ListItemText primary={t('logOut')} onClick={() => logOut()} />
            </ListItem>
          </List>
        ) : null}

        {/* Select language */}
        {type === 'key' && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {languages.map((el, i) => (
              <div
                className={i18n.language === el ? classes.boldText : null}
                style={{
                  margin: '0 5px',
                  textTransform: 'uppercase',
                  cursor: 'pointer'
                }}
                key={i}
                onClick={() => selectLanguage(el)}
              >
                {el}
              </div>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    agencyUserData: state.agencyUserDataRed.obj
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsAuth: (bool) => dispatch(isAuth(bool))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyMenu)
