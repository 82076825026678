import React, { useState } from 'react'
import Header from '../Modules/Header'
import '../firebase'
import { useTranslation } from 'react-i18next'
import Loader from '../Components/Loader'
import { Alert, Snackbar, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { systemStylingSettings, systemColors } from '../globalVariables'
import Button from '../Components/Button'
import axios from 'axios'
import { connect } from 'react-redux'

const useStyles = makeStyles({
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  }
})

const ResetPassword = ({ agencyData }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  // Errors states
  const [emptyEmail, setEmptyEmail] = useState(false)

  const [message, setMessage] = useState({
    status: false,
    duration: 3000,
    severity: 'success',
    message: ''
  })

  const submit = () => {
    setLoading(true)
    if (email !== '') {
      axios({
        method: 'POST',
        url: 'https://europe-west1-norspeda.cloudfunctions.net/sendPasswordResetLink',
        data: {
          email,
          agencyId: agencyData.id
        }
      })
        .then((response) => {
          if (response.data.status === 'error') {
            setLoading(false)

            // If bad formated email
            if (response.data.error.code === 'auth/invalid-email') {
              setMessage({
                status: true,
                duration: 5000,
                severity: 'warning',
                message: t('badEmailFormat')
              })
            }

            // If user not exist
            if (response.data.error.code === 'auth/user-not-found') {
              setMessage({
                status: true,
                duration: 5000,
                severity: 'warning',
                message: t('noUserReset')
              })
            }

            // If email not exist
            if (response.data.error.code === 'auth/internal-error') {
              setMessage({
                status: true,
                duration: 5000,
                severity: 'warning',
                message: t('thisEmailNotExistInOurSystem')
              })
            }

            console.log(
              "Can't send email verification link",
              response.data.error
            )
          } else if (response.data.status === 'sended') {
            setLoading(false)
            setMessage({
              status: true,
              duration: 5000,
              severity: 'success',
              message: t('resetSendSuccess')
            })
          }
        })
        .catch((error) => {
          setLoading(false)
          console.log("Can't send email verification request", error)
        })

      setEmptyEmail(false)
    } else {
      setEmptyEmail(true)
      setLoading(false)
    }
  }
  return (
    <div>
      {loading && <Loader />}
      <Header />

      {/* Alerts */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          style={{ paddingTop: 21, paddingBottom: 21 }}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      <div className='resetPassword'>
        <div className='resetPassword__box'>
          <div>
            <h2>{t('resetPassword')}</h2>
          </div>
          <div>
            <TextField
              required
              type='email'
              label={t('enterYourEmail')}
              variant='outlined'
              size='small'
              value={email}
              classes={{ root: classes.labelRoot }}
              onChange={(e) => setEmail(e.target.value)}
              helperText={emptyEmail && t('mustFillEmail')}
              error={emptyEmail}
            />
          </div>
          <div className='resetPassword__button'>
            <Button text='resetPassword' handleClick={() => submit()} />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.agencyUserDataRed.obj,
  agencyData: state.agencyDataRed.obj
})
export default connect(mapStateToProps)(ResetPassword)
