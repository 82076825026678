import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Snackbar,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip
} from '@mui/material'
import React, { useEffect, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import AgencyMenu from '../../../Components/AgencyMenu'
import Headline from '../../../Components/Headline'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import Loader from '../../../Components/Loader'
import '../../../styles/routes/recruitment/jobs.scss'
import { v4 as uuid } from 'uuid'
import moment, { Moment } from 'moment'
import Navigation from '../../../Components/CalendarNavigation/Navigation'
import { Stack } from '@mui/system'
import ComponentLoader from '../../../Components/ComponentLoader'
import { createNotification } from '../../../GlobalFunctions/Notifications/Key/createNotification'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

const db = firebase.firestore()

interface Props {
  agencyData: any
  agencyUserData: any
}

type Direction = 'next' | 'prev'
type TasksFilter = 'myTasks' | 'agencyTasks'

const Tasks = ({ agencyData, agencyUserData }: Props) => {
  const { t, i18n } = useTranslation()

  const reminderList: TaskReminder[] = [
    'onTime',
    'before1hour',
    'before1day',
    'before2days',
    'before3days',
    'before4days',
    'before5days',
    'before1week',
    'before2weeks',
    'before1month'
  ]

  /**
   * Change document title
   */
  document.title = `${t('tasks')} - Seanor`

  const [loading, setLoading] = useState<boolean>(false)
  const [refreshData, setRefreshData] = useState<boolean>(false)
  const [loadedContent, setLoadedContent] = useState<boolean>(false)

  const [allTasks, setAllTasks] = useState<Task[]>([])

  const [agencyUsers, setAgencyUsers] = useState<AgencyUserData[]>([])

  const [editMode, setEditMode] = useState<boolean>(false)

  const [selectedPeriod, setSelectedPeriod] = useState<CalendarPeriod>('week')

  const [showNewEditTaskDialog, setShowNewEditTaskDialog] =
    useState<boolean>(false)

  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>({
    status: false,
    duration: 5000,
    severity: 'success',
    message: ''
  })

  const [calendar, setCalendar] = useState<TasksCalendar[]>([])
  const [dragTaskId, setDragTaskId] = useState<string>('')
  const [dragTaskOwnerUid, setDragTaskOwnerUid] = useState<string>('')
  const [currentStartPeriodDate, setCurrentStartPeriodDate] =
    useState<string>('')

  const [selectedFilter, setSelectedFilter] = useState<TasksFilter>('myTasks')

  const [totalTasksCount, setTotalTasksCount] = useState<number>(0)
  const [inProgressTasksCount, setInProgressTasksCount] = useState<number>(0)
  const [closedTasksCount, setClosedTasksCount] = useState<number>(0)

  const [title, setTitle] = useState<string>('')
  const [titleError, setTitleError] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const [date, setDate] = useState<string>('')
  const [dateError, setDateError] = useState<boolean>(false)
  const [time, setTime] = useState<Moment | null>(null)
  const [remind, setRemind] = useState<boolean>(false)
  const [done, setDone] = useState<boolean>(false)
  const [selectedReminder, setSelectedReminder] =
    useState<TaskReminder>('onTime')
  const [assigned, setAssigned] = useState<string>(agencyUserData.uid)

  const [selectedTaskId, setSelectedTaskId] = useState<string>('')
  const [selectedTaskOwnerUid, setSelectedTaskOwnerUid] = useState<string>('')

  /**
   * Count days in dates period, make calander days and fetch tasks
   */
  const updateCalandarView = async (
    value: CalendarPeriod,
    startPeriod: string,
    filter?: TasksFilter | undefined | null
  ) => {
    setCurrentStartPeriodDate(startPeriod)
    let startDate: string = startPeriod
    let endDate: string = ''
    const defaultFilter: TasksFilter =
      filter !== undefined && filter !== null ? filter : 'myTasks'

    if (value === 'week') {
      startDate = moment(startDate).startOf('isoWeek').format('YYYY-MM-DD')
      endDate = moment(startDate).endOf('isoWeek').format('YYYY-MM-DD')
    }

    if (value === 'month') {
      endDate = moment(startDate).endOf('month').format('YYYY-MM-DD')
    }

    if (value === '3months') {
      endDate = moment(startDate)
        .add(2, 'months')
        .endOf('month')
        .format('YYYY-MM-DD')
    }

    /**
     * Create new calander days of period
     */
    let currentDate = moment(startDate)
    let listOfDays: TasksCalendar[] = []

    while (moment(endDate).isSameOrAfter(currentDate)) {
      const firstDay: string = moment(currentDate)
        .startOf('month')
        .format('YYYY-MM-DD')
      const date: string = moment(currentDate).format('YYYY-MM-DD')
      const id: string = uuid()
      const weekNumber: number = moment(currentDate).isoWeek()
      const monthNumber: number = moment(currentDate).month() + 1
      const day: TasksCalendarDay = {
        id,
        date,
        week: weekNumber,
        tasks: []
      }

      if (listOfDays.length === 0) {
        listOfDays = [
          ...listOfDays,
          {
            firstMonthDay: firstDay,
            month: monthNumber,
            days: [day]
          }
        ]
      } else {
        const index = listOfDays.findIndex(
          (el) => el.firstMonthDay === firstDay
        )

        if (index > -1) {
          listOfDays[index].days = [...listOfDays[index].days, day]
        } else {
          listOfDays = [
            ...listOfDays,
            {
              firstMonthDay: firstDay,
              month: monthNumber,
              days: [day]
            }
          ]
        }
      }

      currentDate = currentDate.add(1, 'day')
    }

    /**
     * Get tasks during period
     */
    let query = db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('tasks')
      .where('date', '>=', startDate)
      .where('date', '<=', endDate)

    /**
     * Filter by current user
     */
    if (defaultFilter === 'myTasks') {
      query = query.where('agentUid', '==', agencyUserData.uid)
    }

    await query
      .get()
      .then((snap) => {
        let tasksArray: Task[] = []
        let totalTasks: number = 0
        let inProgressTasks: number = 0
        let closedTasks: number = 0

        snap.forEach((document) => {
          let data: any = JSON.stringify(document.data())
          data = JSON.parse(data)

          const task: Task = data

          tasksArray = [...tasksArray, task]

          totalTasks = totalTasks + 1

          if (task.done) {
            closedTasks = closedTasks + 1
          }
        })

        setAllTasks(tasksArray)

        inProgressTasks = totalTasks - closedTasks

        setTotalTasksCount(totalTasks)
        setInProgressTasksCount(inProgressTasks)
        setClosedTasksCount(closedTasks)

        /**
         * Add task to calander
         */
        tasksArray.forEach((task) => {
          const taskStartDate: string = task.date
          const month: number = moment(taskStartDate).month() + 1

          /**
           * Find month where task belongs
           */
          const monthIndex: number = listOfDays.findIndex(
            (el) => el.month === month
          )

          /**
           * Find day where task belongs
           */
          if (monthIndex > -1) {
            const dayIndex: number = listOfDays[monthIndex].days.findIndex(
              (el) => el.date === taskStartDate
            )

            if (dayIndex > -1) {
              listOfDays[monthIndex].days[dayIndex].tasks = [
                ...listOfDays[monthIndex].days[dayIndex].tasks,
                task
              ]
            }
          }
        })
      })
      .catch((error) => {
        console.error(error)
      })

    setCalendar(listOfDays)

    return
  }

  useEffect(() => {
    let shouldUpdate: boolean = true

    const getTasks = new Promise((resolve) => {
      const startDate = moment().startOf('isoWeek').format('YYYY-MM-DD')

      updateCalandarView('week', startDate).then(() => resolve(null))
    })

    const getAgencyUsers = new Promise((resolve) => {
      db.collection('users')
        .where('data.agencyId', '==', agencyData.id)
        .where('data.type', '==', 'keyagency')
        .get()
        .then((snap) => {
          let array: AgencyUserData[] = []
          snap.forEach((user) => {
            let data: any = JSON.stringify(user.data())
            data = JSON.parse(data)

            array = [...array, data.data]
          })

          if (shouldUpdate) {
            setAgencyUsers(array)
          }
          resolve(null)
        })
        .catch((error) => {
          console.error(error)
          resolve(null)
        })
    })

    Promise.all([getTasks, getAgencyUsers])
      .then(() => {
        if (shouldUpdate) {
          setLoadedContent(true)
          setLoading(false)
        }
      })
      .catch((error) => console.error(error))

    return () => {
      shouldUpdate = false
    }
  }, [refreshData, agencyData]) // eslint-disable-line react-hooks/exhaustive-deps

  const closeNewEditTasksDialog = () => {
    setShowNewEditTaskDialog(false)
    setEditMode(false)

    setTitle('')
    setTitleError(false)
    setDescription('')
    setDate('')
    setDateError(false)
    setTime(null)
    setRemind(false)
    setSelectedReminder('onTime')
    setSelectedTaskId('')
    setSelectedTaskOwnerUid('')
    setDone(false)
    setAssigned(agencyUserData.uid)
  }

  const saveTaskToDatabase = async () => {
    if (title !== '' && date !== '') {
      setTitleError(false)
      setDateError(false)
      setLoading(true)

      /**
       * If task assignet to other agent then send notification to him
       */
      const sendToAgentNotificationAboutAssigment = async (
        source: 'tasks' | 'system' | 'jobs_tasks' | 'recruitment'
      ) => {
        if (agencyUserData.uid !== assigned) {
          await createNotification(
            agencyData.id,
            `${agencyUserData.name} ${agencyUserData.lastName} assigned you a task`,
            source,
            assigned
          )
        }
      }

      /**
       * Set reminder date and time
       */
      let reminderDate: string = ''
      let reminderTime: string = ''

      if (remind) {
        if (selectedReminder === 'onTime') {
          reminderDate = date
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before1hour') {
          reminderDate = date
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .subtract(60, 'minutes')
            .format('HH:mm')

          if (
            moment(time, 'HH:mm') <= moment('00:59', 'HH:mm') &&
            moment(time, 'HH:mm') >= moment('00:01', 'HH:mm')
          ) {
            reminderDate = moment(date).subtract(1, 'day').format('YYYY-MM-DD')
          }
        }

        if (selectedReminder === 'before1day') {
          reminderDate = moment(date).subtract(1, 'day').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before2days') {
          reminderDate = moment(date).subtract(2, 'day').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before3days') {
          reminderDate = moment(date).subtract(3, 'day').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before4days') {
          reminderDate = moment(date).subtract(4, 'day').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before5days') {
          reminderDate = moment(date).subtract(5, 'day').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before1week') {
          reminderDate = moment(date).subtract(1, 'week').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before2weeks') {
          reminderDate = moment(date).subtract(2, 'week').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before1month') {
          reminderDate = moment(date).subtract(1, 'month').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }
      }

      /**
       * Find assigned agents's object
       */
      const assignedAgent: AgencyUserData = agencyUsers.filter(
        (el) => el.uid === assigned
      )[0]

      /**
       * Add new task
       */
      if (!editMode) {
        const taskId: string = uuid()

        const newTask: Task = {
          _id: taskId,
          timestamp: new Date(),
          title,
          description,
          date,
          time: moment(time).format('HH:mm'),
          remind,
          reminderDate,
          reminderTime,
          selectedReminder,
          candidateProfileId: '',
          agentUid: assigned,
          agentName: `${assignedAgent.name} ${assignedAgent.lastName}`,
          agentEmail: assignedAgent.email,
          jobId: '',
          done: false,
          taskSource: 'tasks'
        }

        await db
          .collection('agencies')
          .doc(agencyData.id)
          .collection('tasks')
          .doc(taskId)
          .set(newTask)
          .then(() => {
            sendToAgentNotificationAboutAssigment('tasks')
            setRefreshData(!refreshData)
            closeNewEditTasksDialog()
            setLoading(false)
          })
          .catch((error) => console.error(error))
      }

      /**
       * Update task
       */
      if (editMode) {
        const newTask = {
          title,
          description,
          date,
          time: moment(time).format('HH:mm'),
          remind,
          reminderDate,
          reminderTime,
          selectedReminder,
          agentUid: assigned,
          agentName: `${assignedAgent.name} ${assignedAgent.lastName}`,
          agentEmail: assignedAgent.email
        }

        /**
         * Get selected task object
         */
        const selectedTask: Task = allTasks.filter(
          (el) => el._id === selectedTaskId
        )[0]

        await db
          .collection('agencies')
          .doc(agencyData.id)
          .collection('tasks')
          .doc(selectedTaskId)
          .update(newTask)
          .then(() => {
            sendToAgentNotificationAboutAssigment(selectedTask.taskSource)
            setRefreshData(!refreshData)
            closeNewEditTasksDialog()
            setLoading(false)
          })
          .catch((error) => console.error(error))
      }
    } else {
      if (title === '') {
        setTitleError(true)
      } else {
        setTitleError(false)
      }

      if (date === '') {
        setDateError(true)
      } else {
        setDateError(false)
      }

      setSnackbarMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('pleaseFillAllRequiredFields')
      })
    }
  }

  const handleCalendarFilterChange = (event: MouseEvent, value: string) => {
    if (value !== null) {
      setSelectedFilter(value as TasksFilter)
      updateCalandarView(
        selectedPeriod,
        currentStartPeriodDate,
        value as TasksFilter
      )
    } else {
      setSelectedFilter('myTasks')
      updateCalandarView(selectedPeriod, currentStartPeriodDate, 'myTasks')
    }
  }

  const handleDrop = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const newTaskStartDate = event.target.getAttribute('data-date')

    /**
     * Update task date
     */
    if (newTaskStartDate !== null && agencyUserData.uid === dragTaskOwnerUid) {
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('tasks')
        .doc(dragTaskId)
        .update({
          date: newTaskStartDate
        })
        .then(() => {
          updateCalandarView(selectedPeriod, currentStartPeriodDate)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'error',
            message: t('system_error_try_again_asd54')
          })
        })
    }
  }

  const onDragOver = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const getWeekday = (date: string) => {
    const lng = i18n.language
    const weekDay: number = moment(date).day()
    const monthDay: string = moment(date).day(weekDay).date().toString()
    let result: string = ''

    if (weekDay === 1) {
      result = `${lng === 'lt' ? 'P' : 'M'} ${monthDay}`
    }
    if (weekDay === 2) {
      result = `${lng === 'lt' ? 'A' : 'T'} ${monthDay}`
    }
    if (weekDay === 3) {
      result = `${lng === 'lt' ? 'T' : 'W'} ${monthDay}`
    }
    if (weekDay === 4) {
      result = `${lng === 'lt' ? 'K' : 'T'} ${monthDay}`
    }
    if (weekDay === 5) {
      result = `${lng === 'lt' ? 'P' : 'F'} ${monthDay}`
    }
    if (weekDay === 6) {
      result = `${lng === 'lt' ? 'Š' : 'S'} ${monthDay}`
    }
    if (weekDay === 0 || weekDay === 7) {
      result = `${lng === 'lt' ? 'S' : 'S'} ${monthDay}`
    }

    return result
  }

  const handlePeriodSelection = (value: CalendarPeriod) => {
    let startPeriod: string = moment().startOf('month').format('YYYY-MM-DD')

    if (value === 'week') {
      startPeriod = moment().startOf('isoWeek').format('YYYY-MM-DD')
      updateCalandarView(value, startPeriod)
    }

    if (value === 'month' || value === '3months') {
      updateCalandarView(value, startPeriod)
    }

    setSelectedPeriod(value)
  }

  const getMonthName = (month: number) => {
    if (month === 1) {
      return t('january')
    }
    if (month === 2) {
      return t('february')
    }
    if (month === 3) {
      return t('march')
    }
    if (month === 4) {
      return t('april')
    }
    if (month === 5) {
      return t('may')
    }
    if (month === 6) {
      return t('jun')
    }
    if (month === 7) {
      return t('july')
    }
    if (month === 8) {
      return t('august')
    }
    if (month === 9) {
      return t('september')
    }
    if (month === 10) {
      return t('october')
    }
    if (month === 11) {
      return t('november')
    }
    if (month === 12) {
      return t('december')
    }
  }

  const checkIsWeekend = (date: string) => {
    const weekdayNumber: number = moment(date).isoWeekday()
    if (weekdayNumber > 5) {
      return true
    }
    return false
  }

  const showWeekNumber = (
    currentIndex: number,
    currentWeek: number,
    previousArray: TasksCalendarDay[],
    date: string
  ) => {
    const index: number = currentIndex - 1
    let prevWeek: number = 0
    const weekDay: number = moment(date).day()

    if (weekDay === 1) {
      if (index < 0) {
        return true
      }

      prevWeek = previousArray[index].week

      if (currentWeek > prevWeek) {
        return true
      }
    }

    return false
  }

  const handleYearMonthChange = (value: string) => {
    const selectedDate: string = moment(value).format('YYYY-MM-DD')
    updateCalandarView(selectedPeriod, selectedDate)
  }

  const resetCalenderToToday = () => {
    const startDate = moment().startOf('isoWeek').format('YYYY-MM-DD')
    setSelectedPeriod('week')
    updateCalandarView('week', startDate)
  }

  /**
   * Used to move next or previouse week, month
   */
  const navigate = (direction: Direction) => {
    let startPerdiod: string = currentStartPeriodDate

    if (direction === 'next') {
      if (selectedPeriod === 'week') {
        startPerdiod = moment(currentStartPeriodDate)
          .add(1, 'week')
          .format('YYYY-MM-DD')
      }
      if (selectedPeriod === 'month' || selectedPeriod === '3months') {
        startPerdiod = moment(currentStartPeriodDate)
          .add(1, 'month')
          .format('YYYY-MM-DD')
      }
    }

    if (direction === 'prev') {
      if (selectedPeriod === 'week') {
        startPerdiod = moment(currentStartPeriodDate)
          .subtract(1, 'week')
          .format('YYYY-MM-DD')
      }
      if (selectedPeriod === 'month' || selectedPeriod === '3months') {
        startPerdiod = moment(currentStartPeriodDate)
          .subtract(1, 'month')
          .format('YYYY-MM-DD')
      }
    }
    updateCalandarView(selectedPeriod, startPerdiod)
  }

  const editTask = (task: Task | null) => {
    if (task !== null) {
      setEditMode(true)
      setTitle(task.title)
      setDescription(task.description)
      setDate(task.date)
      setTime(moment(task.time, 'HH:mm'))
      setRemind(task.remind)
      setSelectedReminder(task.selectedReminder)
      setSelectedTaskId(task._id)
      setSelectedTaskOwnerUid(task.agentUid)
      setDone(task.done)
      setAssigned(task.agentUid)

      setShowNewEditTaskDialog(true)
    }
  }

  const isAgentATasksOwner = (uid: string) => {
    if (uid === agencyUserData.uid) {
      return true
    }

    return false
  }

  const disableInput = () => {
    if (editMode && !isAgentATasksOwner(selectedTaskOwnerUid)) {
      return true
    }
    return false
  }

  const makeTaskDoneOrActive = async () => {
    setLoading(true)

    let taskStatus: boolean = true

    if (done) {
      taskStatus = false
    }

    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('tasks')
      .doc(selectedTaskId)
      .update({ done: taskStatus })
      .then(() => {
        setRefreshData(!refreshData)
        closeNewEditTasksDialog()
        setLoading(false)
      })
      .catch((error) => console.error(error))
  }

  const orderTasksByTime = (tasks: Task[]) => {
    let finalList: Task[] = []

    /**
     * Separe tasks with time and without time
     */
    let withTimeTasks: Task[] = []
    let withoutTimeTasks: Task[] = []

    tasks.forEach((task) => {
      if (task.time !== '') {
        withTimeTasks = [...withTimeTasks, task]
      }
      if (task.time === '') {
        withoutTimeTasks = [...withoutTimeTasks, task]
      }
    })

    /**
     * Order tasks with time
     */
    withTimeTasks.sort((a, b) => (a.time < b.time ? -1 : 1))

    /**
     * Final list where tasks without time goes at the end of the list
     */
    finalList = [...withTimeTasks, ...withoutTimeTasks]

    return finalList
  }

  const getBorderColorByAgent = (uid: string | undefined) => {
    if (uid !== undefined) {
      const agentColor: string = agencyUsers.filter((el) => el.uid === uid)[0]
        .color

      return agentColor
    }
  }

  if (!loadedContent) return <Loader />

  return (
    <div>
      {loading && <Loader />}

      <Headline text={t('Tasks')} />

      <AgencyMenu type='key' />

      {/* Messeges */}
      <Snackbar
        open={snackbarMessage.status}
        autoHideDuration={snackbarMessage.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() =>
          setSnackbarMessage({ ...snackbarMessage, status: false })
        }
      >
        <Alert
          severity={snackbarMessage.severity}
          onClose={() =>
            setSnackbarMessage({ ...snackbarMessage, status: false })
          }
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>

      {/* New or Edit task dialog */}
      <Dialog open={showNewEditTaskDialog} maxWidth='sm' fullWidth>
        <DialogTitle>{editMode ? t('edit_task') : t('new_task')}</DialogTitle>

        <DialogContent>
          {loading && <ComponentLoader />}

          <Grid container spacing={2} style={{ marginTop: 20 }}>
            {/* Title */}
            <Grid item xs={12}>
              <TextField
                disabled={disableInput() || done}
                fullWidth
                size='small'
                required
                label={t('title')}
                value={title}
                type='text'
                onChange={(e) => setTitle(e.target.value)}
                error={titleError}
              />
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <TextField
                disabled={disableInput() || done}
                fullWidth
                size='small'
                label={t('description')}
                value={description}
                type='text'
                multiline
                rows={4}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>

            {/* Assign to */}
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                disabled={disableInput() || done}
                size='small'
                label={t('assign_to_agent')}
                value={assigned}
                onChange={(e) => setAssigned(e.target.value)}
              >
                {agencyUsers.map((user) => (
                  <MenuItem key={user.uid} value={user.uid}>
                    {user.name} {user.lastName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Date */}
            <Grid item xs={6}>
              <TextField
                disabled={disableInput() || done}
                fullWidth
                required
                size='small'
                label={t('date')}
                InputLabelProps={{
                  shrink: true
                }}
                value={date}
                type='date'
                onChange={(e) => setDate(e.target.value)}
                error={dateError}
              />
            </Grid>

            {/* Time */}
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  label={t('time')}
                  inputFormat='hh:mm'
                  value={time}
                  onChange={(newTime) => setTime(newTime)}
                  ampm={false}
                  renderInput={(params) => (
                    <TextField
                      disabled={disableInput() || done}
                      fullWidth
                      size='small'
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            {/* Remind */}
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={disableInput() || done}
                    checked={remind}
                    color='electric'
                    onChange={() => setRemind(!remind)}
                  />
                }
                label={t('remind')}
              />
            </Grid>

            {/* Reminder selection */}
            <Grid item xs={6}>
              <TextField
                select
                disabled={!remind || disableInput() || done}
                fullWidth
                size='small'
                label={t('title')}
                value={selectedReminder}
                onChange={(e) =>
                  setSelectedReminder(e.target.value as TaskReminder)
                }
              >
                {reminderList.map((reminder) => (
                  <MenuItem key={reminder} value={reminder}>
                    {t(reminder)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Close Button */}

          <Button onClick={() => closeNewEditTasksDialog()}>
            {t('cancel')}
          </Button>

          {/* Done Button */}
          {!disableInput() && editMode && (
            <Button
              variant='contained'
              color='orange'
              onClick={() => makeTaskDoneOrActive()}
            >
              {done ? t('activate') : t('done')}
            </Button>
          )}

          {/* Save Button */}
          {!disableInput() && !done && (
            <Button
              variant='contained'
              color='orange'
              onClick={() => saveTaskToDatabase()}
            >
              {editMode ? t('update') : t('save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <main>
        {/* New task button */}
        <div style={{ margin: 20 }}>
          <Button
            variant='contained'
            color='orange'
            size='small'
            onClick={() => setShowNewEditTaskDialog(true)}
          >
            {t('new_task')}
          </Button>
        </div>

        {/* Calendar */}
        <div className='calendar'>
          {/* Calendar options */}
          <div
            style={{
              marginBottom: 20,
              marginTop: 40,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
              flexWrap: 'wrap'
            }}
          >
            {/* Filtration */}
            <ToggleButtonGroup
              size='small'
              value={selectedFilter}
              exclusive
              onChange={handleCalendarFilterChange}
            >
              <ToggleButton value='myTasks'>{t('my_tasks')}</ToggleButton>
              <ToggleButton value='agencyTasks'>
                {t('agency_tasks')}
              </ToggleButton>
            </ToggleButtonGroup>

            {/* Counters */}
            <Stack direction='row' spacing={1} style={{ marginTop: 10 }}>
              {/* Total Tasks */}
              <Tooltip title={t('total')} placement='top'>
                <div className='counter'>{totalTasksCount}</div>
              </Tooltip>

              {/* Active */}
              <Tooltip title={t('active')} placement='top'>
                <div className='counter__progress'>{inProgressTasksCount}</div>
              </Tooltip>

              {/* Done */}
              <Tooltip title={t('done')} placement='top'>
                <div className='counter__closed'>{closedTasksCount} </div>
              </Tooltip>
            </Stack>
          </div>

          {/* Calender container */}
          <div className='calendar_container'>
            {calendar.map((month: TasksCalendar) => (
              <div
                key={month.firstMonthDay}
                className={
                  selectedPeriod !== 'week'
                    ? 'calendar_month'
                    : 'calendar_month__week'
                }
              >
                {/* Month name */}
                <h5>{getMonthName(month.month)}</h5>

                {/* Days */}
                <div className='calendar_days'>
                  {month.days.map(
                    (
                      day: TasksCalendarDay,
                      index: number,
                      prevArray: TasksCalendarDay[]
                    ) => (
                      <div
                        className={
                          checkIsWeekend(day.date)
                            ? 'calendar_day__weekend'
                            : showWeekNumber(
                                index,
                                day.week,
                                prevArray,
                                day.date
                              ) === true
                            ? 'calendar_day__monday'
                            : 'calendar_day'
                        }
                        id={day.id}
                        key={day.id}
                        data-date={day.date}
                        onDragOver={onDragOver}
                        onDrop={handleDrop}
                      >
                        {/* Week number */}
                        {showWeekNumber(
                          index,
                          day.week,
                          prevArray,
                          day.date
                        ) && <div className='week_number'>{day.week}</div>}

                        {/* Header */}
                        <div className='calendar_header'>
                          {getWeekday(day.date)}
                        </div>

                        {/* Tasks list */}
                        {orderTasksByTime(day.tasks).map((task: Task) => (
                          <div
                            className={
                              task.done
                                ? 'calendar_job__closed'
                                : 'calendar_job'
                            }
                            key={task._id}
                            draggable
                            onDragStart={() => {
                              setDragTaskId(task._id)
                              setDragTaskOwnerUid(task.agentUid)
                            }}
                            onClick={() => editTask(task)}
                            style={{
                              borderBottom: `4px solid ${getBorderColorByAgent(
                                task.agentUid
                              )}`
                            }}
                          >
                            {/* Title */}
                            <div className='title'>{task.title}</div>

                            <div className='info'>
                              {task.time !== ''
                                ? `${t('time')}: ${task.time}`
                                : ''}
                            </div>

                            {/* Assigned agent */}
                            <div className='assigned'>
                              {t('agent')}: {task.agentName}
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>

          <Navigation
            nextHandler={() => navigate('next')}
            backHandler={() => navigate('prev')}
            period={(value) => handlePeriodSelection(value)}
            dateHandler={handleYearMonthChange}
            currentDate={currentStartPeriodDate}
            resetHandler={() => resetCalenderToToday()}
          />
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  agencyData: state.agencyDataRed.obj,
  agencyUserData: state.agencyUserDataRed.obj
})

export default connect(mapStateToProps)(Tasks)
