import React from 'react'

const StartOage = () => {
  return (
    <div className='firstPage'>
      <img src='/assets/logo_white.svg' alt='logo' />
      <h2>Crew and land workers planning system</h2>

      <h3>Contact sales</h3>
      <div>
        <a href='tel:+370 610 22996'>+370 610 22996</a>
      </div>
      <div>
        <a href='mailto:sales@seanor.eu'>sales@seanor.eu</a>
      </div>
    </div>
  )
}

export default StartOage
