import React, { useEffect, useState } from 'react'
import { Route, Switch, useHistory, withRouter } from 'react-router-dom'
import firebase from 'firebase/app'
import './firebase'
import ProtectedRoute from './routes/protectedRoute'
import { user } from './redux/actions/user'
import { connect } from 'react-redux'
import Login from './routes/login'
import FourZeroFour from './routes/fourZeroFour'
import { isAuth } from './redux/actions/session/isAuth'
import StartPage from './routes/startPage'
import AgencyRoute from './routes/agencyRoute'
import { agencyMatch } from './redux/actions/agencyMatch'
import { loadingData } from './redux/actions/session/loadingData'
import { loadAgencies } from './redux/actions/session/loadAgencies'
import EmployeePage from './routes/employeePage'
import CrewAgencyPage from './routes/crewAgency/crewAgencyPage'
import KeyAgencyPage from './routes/keyAgency/keyAgencyPage'
import Registration from './routes/registration'
import CrewEmployeeView from './routes/crewAgency/crewEmployeeView'
import { agencyData } from './redux/actions/session/agencyData'
import AddNewEmployee from './routes/crewAgency/addNewEmployee'
import { agencyUserData } from './redux/actions/session/agencyUserData'
import CrewEmployeeCreateCV from './routes/crewAgency/crewEmployeeCreateCV'
import MainTerm from './routes/terms/mainTerm'
import ResetPassword from './routes/resetPassword'
import AddNewKeyEmployee from './routes/keyAgency/addNewKeyEmployee'
import KeyEmployeeView from './routes/keyAgency/keyEmployeeView'
import KeyEmployeeCreateCV from './routes/keyAgency/keyEmployeeCreateCV'
import SeanorTerm from './routes/terms/seanorTerm'
import AddAgency from './routes/admin/addAgency'
import CrewEditEmployee from './routes/crewAgency/crewEditEmployee'
import CrewEmployeeEditProfile from './Modules/employees/crewEmployeeEditProfile'
import KeyEditEmployee from './routes/keyAgency/keyEditEmployee'
import KeyEmployeeEditProfile from './Modules/employees/keyEmployeeEditProfile'
import AddUser from './routes/admin/addUser'
import Loader from './Components/Loader'
import Footer from './Components/Footer'
import AddNewCompany from './routes/companies/addNewCompany'
import AllCompanies from './routes/companies/allCompanies'
import CompanyPage from './routes/companies/companyPage'
import VersionLog from './routes/versionLog'
import { userData } from './redux/actions/session/userData'
import CompanySettingsCrew from './routes/agency/settings/crew/companySettings'
import CompanySettingsKey from './routes/agency/settings/key/companySettings'
import AddNewVessel from './routes/vessels/addNewVessel'
import AllVessels from './routes/vessels/allVessels'
import VesselPage from './routes/vessels/vesselPage'
import System from './routes/system'
import ClientReport from './routes/reports/key/clientReport'
import ClientReportCrew from './routes/reports/crew/clientReport'
import Dashboard from './routes/keyAgency/dashboard/dashboard'
import DashboardCrew from './routes/crewAgency/dashboard/dashboard'
import DashboardSettings from './routes/agency/settings/key/dashboardSettings'
import CompanyStatistics from './routes/reports/key/companyStatistics/companyStatistics'
import CrewDashboardSettings from './routes/agency/settings/crew/crewDashboardSettings'
import { useTranslation } from 'react-i18next'
import PrintAgentsAddedEmployees from './routes/reports/key/companyStatistics/print/agentsAddedEmployees'
import UserRoles from './routes/agency/settings/key/userRoles'
import UserRolesCrew from './routes/agency/settings/crew/userRoles'
import Jobs from './routes/keyAgency/recruitment/jobs.tsx'
import JobsAds from './routes/keyAgency/recruitment/jobsAds'
import JobAd from './routes/keyAgency/recruitment/jobAd'
import Tasks from './routes/keyAgency/recruitment/tasks'
import Notifications from './routes/keyAgency/notifications/notifications'

const db = firebase.firestore()

const App = ({
  user,
  setIsAuth,
  location,
  setAgencyMatch,
  setLoadingData,
  setLoadAgencies,
  setAgencyData,
  setAgencyUserData,
  setUserDataToRedux
}) => {
  const history = useHistory()
  const { i18n } = useTranslation()

  const [userData, setUserData] = useState(null)
  const [userUID, setUserUID] = useState(null)
  const [agenciesArr, setAgenciesArr] = useState([])
  const [dataLoading, setDataLoading] = useState(true)
  const [urlAGency, setUrlAGency] = useState('')

  /**
   * Hide footer from unwanted pages
   */
  let showFooter = true
  const path = history.location.pathname.split('/')
  if (path[5] === 'print-agents-added-employees' || path[3] === 'jobs') {
    showFooter = false
  }

  useEffect(() => {
    const unsubscribe = () => {
      setLoadingData(false)
      setLoadAgencies(false)

      const arr = location.pathname.split('/')
      setUrlAGency(arr[1])

      const isUserAuthenticated = async () => {
        await firebase.auth().onAuthStateChanged((userData) => {
          if (userData) {
            let data = JSON.stringify(userData)
            data = JSON.parse(data)
            user(data.providerData[0])
            setUserUID(data.uid)
            setIsAuth(true)

            // Check users agency
            db.collection('users')
              .doc(data.uid)
              .get()
              .then((res) => {
                let data = JSON.stringify(res.data())
                data = JSON.parse(data)

                const userDataFromServer = data.data
                setUserDataToRedux(userDataFromServer)

                // Check if user use same device when logged in
                if (
                  userDataFromServer.loggedDeviceId ===
                  window.localStorage.getItem('loggedDeviceId')
                ) {
                  if (userDataFromServer.agencyId === arr[1]) {
                    setUserData(userDataFromServer)
                    setAgencyUserData(userDataFromServer)
                    setLoadingData(true)
                    setDataLoading(false)
                  } else {
                    if (
                      arr[1] === '404' ||
                      arr[1] === '' ||
                      arr[1] === 'system'
                    ) {
                      setDataLoading(false)
                    } else {
                      /**
                       * Check is page related to agency jobs ads page
                       */
                      if (arr[3] !== 'jobs') {
                        firebase.auth().signOut()
                        history.push('/404')
                      } else {
                        setDataLoading(false)
                      }
                    }
                  }
                } else {
                  setUserData(null)
                  user({})
                  setIsAuth(false)
                  firebase.auth().signOut()
                  history.push(`/${arr[1]}`)
                }
              })
          } else {
            setDataLoading(false)
          }
        })
      }
      isUserAuthenticated()

      // Get agencies array from database
      const getAgenciesArray = () => {
        return new Promise((resolve, reject) => {
          db.collection('agencies')
            .get()
            .then((resData) => {
              const arr = []
              resData.forEach((el) => {
                let data = JSON.stringify(el.data())
                data = JSON.parse(data)
                arr.push(data.data)
              })

              resolve(arr)
            })
            .catch((err) => reject(err))
        })
      }

      const getAgenciesList = async () => {
        return await getAgenciesArray()
          .then((res) => {
            setAgenciesArr(res)

            // Check if url match agency
            if (arr[1] !== '' && arr[1] !== 'system') {
              const findAgency = res.find(({ id }) => id === arr[1])
              if (findAgency !== undefined) {
                const index = res.findIndex(({ id }) => id === arr[1])
                setAgencyMatch(true)
                setAgencyData(res[index])
                setLoadAgencies(true)

                // Set system language
                if (res[index].type === 'crew') {
                  i18n.changeLanguage('en')
                }
              } else {
                setAgencyMatch(false)
                setLoadAgencies(true)
                history.push('/404')
              }
            }
          })
          .catch((err) => console.error(err))
      }
      getAgenciesList()
    }

    return unsubscribe()
  }, [
    setIsAuth,
    user,
    location.pathname,
    setAgencyMatch,
    setLoadingData,
    setLoadAgencies,
    setAgencyData,
    setAgencyUserData,
    history,
    setUserDataToRedux,
    i18n
  ])

  if (dataLoading) {
    return <Loader />
  }

  return (
    <div className='app'>
      <div className='container wrapper'>
        <Switch>
          <Route path='/' exact component={StartPage} />
          <Route path='/404' exact component={FourZeroFour} />
          {urlAGency !== 'system' && (
            <Route
              path='/:agency'
              exact
              component={() => (
                <Login
                  agencyData={agencyData}
                  agenciesArr={agenciesArr}
                  userData={userData}
                  userUID={userUID}
                />
              )}
            />
          )}
          <AgencyRoute
            path='/:agency/registration'
            exact
            component={Registration}
          />
          <Route path='/system' exact component={System} />
          <Route
            path='/:agency/general-terms-condition'
            exact
            component={MainTerm}
          />
          <Route path='/:agency/seanor-term' exact component={SeanorTerm} />
          <Route
            path='/:agency/reset-password'
            exact
            component={ResetPassword}
          />
          <Route
            path='/:agency/versions-log'
            exact
            component={() => <VersionLog userData={userData} />}
          />

          {/*
           * EMPLOYEE
           */}

          {/*
           * Create profile page CREW
           */}
          <ProtectedRoute
            path='/:agency/dashboard/create-crew'
            exact
            component={() => <CrewEmployeeCreateCV userUID={userUID} />}
          />

          {/*
           * Create profile page KEY
           */}
          <ProtectedRoute
            path='/:agency/dashboard/create-key'
            exact
            component={() => <KeyEmployeeCreateCV userUID={userUID} />}
          />
          <ProtectedRoute
            path='/:agency/dashboard/:userId'
            exact
            component={() => <EmployeePage userUID={userUID} />}
          />
          <ProtectedRoute
            path='/:agency/dashboard/edit-crew/:docId'
            exact
            component={() => <CrewEmployeeEditProfile userUID={userUID} />}
          />
          <ProtectedRoute
            path='/:agency/dashboard/edit-key/:docId'
            exact
            component={() => <KeyEmployeeEditProfile userUID={userUID} />}
          />

          {/* Crew agency */}
          <ProtectedRoute
            path='/:agency/crew-dashboard/'
            exact
            component={() => <DashboardCrew userUID={userUID} />}
          />
          <ProtectedRoute
            path='/:agency/crew-database/'
            exact
            component={() => <CrewAgencyPage />}
          />
          <ProtectedRoute
            path='/:agency/crew-dashboard/employee-view/:docId/'
            exact
            component={() => <CrewEmployeeView />}
          />
          <ProtectedRoute
            path='/:agency/crew-dashboard/add-new-employee'
            exact
            component={AddNewEmployee}
          />
          <ProtectedRoute
            path='/:agency/crew-dashboard/edit-employee/:docId'
            exact
            component={CrewEditEmployee}
          />
          <ProtectedRoute
            path='/:agency/vessels/add-new-vessel'
            exact
            component={() => <AddNewVessel userUID={userUID} />}
          />
          <ProtectedRoute
            path='/:agency/vessels/all-vessels'
            exact
            component={() => <AllVessels />}
          />
          <ProtectedRoute
            path='/:agency/vessels/vessel/:companyId'
            exact
            component={() => <VesselPage userUID={userUID} />}
          />

          <ProtectedRoute
            path='/:agency/reports/crew/client-report'
            exact
            component={() => <ClientReportCrew />}
          />

          {/* Key agency */}
          <ProtectedRoute
            path='/:agency/key-dashboard/'
            exact
            component={() => <Dashboard userUID={userUID} />}
          />
          <ProtectedRoute
            path='/:agency/key-database/'
            exact
            component={KeyAgencyPage}
          />
          <ProtectedRoute
            path='/:agency/key-dashboard/employee-view/:docId/'
            exact
            component={() => <KeyEmployeeView />}
          />
          <ProtectedRoute
            path='/:agency/key-dashboard/add-new-employee'
            exact
            component={AddNewKeyEmployee}
          />
          <ProtectedRoute
            path='/:agency/key-dashboard/edit-employee/:docId'
            exact
            component={KeyEditEmployee}
          />
          <ProtectedRoute
            path='/:agency/companies/add-new-company'
            exact
            component={() => <AddNewCompany userUID={userUID} />}
          />
          <ProtectedRoute
            path='/:agency/companies/all-companies'
            exact
            component={() => <AllCompanies />}
          />
          <ProtectedRoute
            path='/:agency/companies/company/:companyId'
            exact
            component={() => <CompanyPage userUID={userUID} />}
          />

          <ProtectedRoute
            path='/:agency/reports/key/client-report'
            exact
            component={() => <ClientReport />}
          />

          <ProtectedRoute
            path='/:agency/reports/key/company-statistics'
            exact
            component={() => <CompanyStatistics />}
          />

          <ProtectedRoute
            path='/:agency/key/recruitment/jobs'
            exact
            component={() => <Jobs />}
          />

          <ProtectedRoute
            path='/:agency/key/recruitment/tasks'
            exact
            component={() => <Tasks />}
          />

          <ProtectedRoute
            path='/:agency/reports/key/company-statistics/print-agents-added-employees'
            exact
            component={() => <PrintAgentsAddedEmployees />}
          />

          <ProtectedRoute
            path='/:agency/key/notifications'
            exact
            component={() => <Notifications />}
          />

          <Route path='/:agency/key/jobs' exact component={JobsAds} />

          <Route
            path='/:agency/key/jobs/:jobDocumentId'
            exact
            component={JobAd}
          />

          {/* Agency Settings */}
          <ProtectedRoute
            path='/:agency/settings/key/company-settings'
            exact
            component={CompanySettingsKey}
          />

          <ProtectedRoute
            path='/:agency/settings/crew/company-settings'
            exact
            component={CompanySettingsCrew}
          />

          <ProtectedRoute
            path='/:agency/settings/company-settings/key-user-roles'
            exact
            component={UserRoles}
          />

          <ProtectedRoute
            path='/:agency/settings/company-settings/crew-user-roles'
            exact
            component={UserRolesCrew}
          />

          {/* Agent settings */}
          <ProtectedRoute
            path='/:agency/settings/dashboard-settings'
            exact
            component={() => <DashboardSettings />}
          />

          <ProtectedRoute
            path='/:agency/settings/crew-dashboard-settings'
            exact
            component={() => <CrewDashboardSettings />}
          />

          {/* Admin */}
          <ProtectedRoute
            path='/:agency/admin/user/add-agency'
            exact
            component={AddAgency}
          />
          <ProtectedRoute
            path='/:agency/admin/user/add-user'
            exact
            component={AddUser}
          />

          <Route path='*' exact component={FourZeroFour} />
        </Switch>
      </div>
      {showFooter && <Footer userData={userData} />}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    user: (data) => dispatch(user(data)),
    setIsAuth: (bool) => dispatch(isAuth(bool)),
    setAgencyMatch: (bool) => dispatch(agencyMatch(bool)),
    setLoadingData: (bool) => dispatch(loadingData(bool)),
    setLoadAgencies: (bool) => dispatch(loadAgencies(bool)),
    setAgencyData: (obj) => dispatch(agencyData(obj)),
    setAgencyUserData: (obj) => dispatch(agencyUserData(obj)),
    setUserDataToRedux: (obj) => dispatch(userData(obj))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(App))
