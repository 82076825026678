import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import firebase from 'firebase/app'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  MenuItem
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Loader from '../../Components/Loader'
import Button from '../../Components/Button'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import {
  systemColors,
  systemStylingSettings,
  profileStatus
} from '../../globalVariables'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faChevronDown,
  faBars,
  faList,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import Headline from '../../Components/Headline'
import { vesselsList } from '../../variables/vesselsTypes'
import StatusChip from '../../Components/StatusChip'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

const useStyles = makeStyles({
  tableHeader: {
    color: systemColors.darkBlue,
    fontSize: 16
  },
  tableRow: {
    cursor: 'pointer'
  },
  root: {
    width: 150,
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: systemColors.inputColor,
      borderRadius: systemStylingSettings.borderRadius,
      '&.Mui-focused fieldset': {
        color: systemColors.darkBlue,
        borderColor: systemColors.darkBlue,
        borderWidth: systemStylingSettings.borderWidth
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 8
      }
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    }
  },
  expandIcon: {
    fontSize: 15
  },
  accordionRoot: {
    boxShadow: 'none',
    border: `1px solid ${systemColors.lighGrey}`,
    borderRadius: systemStylingSettings.borderRadius,
    marginTop: 50,
    backgroundColor: systemColors.background
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  listIconSelected: {
    color: systemColors.orange
  }
})

const CrewEmployessList = ({ agencyData }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  // Filter result limit
  const resultLimit = 30

  // Change document title
  document.title = `${t('crewDatabase')} - Seanor`

  const [loading, setLoading] = useState(false)
  const [loadedData, setLoadedData] = useState(false)
  // Lists from database
  const [positionsList, setPositionsList] = useState([])
  // eslint-disable-next-line
  const [lastItemOnList, setLastItemOnList] = useState(null)
  const [nationalityList, setNationalityList] = useState([])

  // Filter inputs
  const [filterFirstName, setFilterFirstName] = useState('')
  const [filterLastName, setFilterLastName] = useState('')
  const [filterRankId, setFilterRankId] = useState(null)
  const [filterNationality, setFilterNationality] = useState('')

  const [element, setElement] = useState(null)
  const [filtering, setFiltering] = useState(false)

  // References
  const employeeList = useRef([])
  const lastItem = useRef(null)

  const [listViewSelected, setListViewSelected] = useState(true)
  const [pictureListViewSelected, setPictureListViewSelected] = useState(false)
  const [profilePhotoUrlList, setProfilePhotoUrlList] = useState([])

  const [filterProfileStatus, setfilterProfileStatus] = useState('active')
  const [filterDesiredVesselType, setFilterDesiredVesselType] = useState('')

  const getRankName = (id) => {
    let name = ''
    positionsList.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  const getProfilePhotoUrl = useCallback(() => {
    const urlList = employeeList.current.map((obj) => {
      if (obj.profilePhoto !== null && obj.profilePhoto !== '') {
        // eslint-disable-next-line
        return new Promise((res) => {
          storageRef
            .child(
              `agencies/${agencyData.id}/users-forms-uploads/${obj.docId}/${obj.profilePhoto}`
            )
            .getDownloadURL()
            .then((url) => {
              res({
                id: obj.docId,
                url
              })
            })
            .catch((error) => {
              console.log(error)
              res({
                id: obj.docId,
                url: ''
              })
            })
        })
      } else {
        // eslint-disable-next-line
        return
      }
    })
    Promise.all(urlList).then((res) => {
      setProfilePhotoUrlList(res)
      setLoading(false)
    })
  }, [agencyData])

  // Get employees list
  const getAllEmployees = useCallback(() => {
    setLoading(true)
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .orderBy('date', 'desc')
      .limit(resultLimit)
      .get()
      .then((res) => {
        const arr = []
        res.forEach((el) => {
          let data = JSON.stringify(el.data())
          data = JSON.parse(data)
          if (data.profileStatus === 'active') {
            arr.push(data)
          }
        })

        // setEmployeesList(arr)
        employeeList.current = arr
        lastItem.current = res.docs[res.docs.length - 1]
        getProfilePhotoUrl()
        setLoading(false)
      })
      .catch((err) => console.log('Get user error', err))
  }, [agencyData, getProfilePhotoUrl])

  useEffect(() => {
    let shouldUpdate = true
    const getData = async () => {
      const getPositionsList = new Promise((resolve) => {
        db.collection('lists')
          .doc('positions')
          .get()
          .then((res) => {
            let data = JSON.stringify(res.data())
            data = JSON.parse(data)
            if (shouldUpdate) {
              setPositionsList(data.list)
            }
            resolve()
          })
          .catch((error) => {
            console.log("Cat't get positions list", error)
            resolve()
          })
      })

      const getNationalityList = new Promise((resolve) => {
        db.collection('lists')
          .doc('nationality')
          .get()
          .then((res) => {
            let data = JSON.stringify(res.data())
            data = JSON.parse(data)
            if (shouldUpdate) {
              setNationalityList(data.list)
            }
            resolve()
          })
          .catch((error) => {
            console.log("Cat't get nationality list", error)
            resolve()
          })
      })

      await Promise.all([getPositionsList, getNationalityList]).then(() => {
        if (shouldUpdate) {
          setLoadedData(true)
        }
      })
    }
    getData()
    return () => {
      shouldUpdate = false
    }
  }, [])

  const resetFilter = () => {
    setLoading(true)
    // Reset states
    setFilterFirstName('')
    setFilterLastName('')
    setFilterRankId(null)
    setFilterNationality('')
    setFiltering(false)
    setfilterProfileStatus('active')
    employeeList.current = []

    // Get new eployees list
    getAllEmployees()
  }

  // Filter and show result
  const filterResult = async (type) => {
    setLoading(true)

    let query = db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('employees')

    if (type === 'name') {
      if (filterFirstName !== '') {
        query = query
          .where('firstName', '>=', filterFirstName)
          .where('firstName', '<=', filterFirstName + '~')
      }
      if (filterLastName !== '') {
        query = query
          .where('lastName', '>=', filterLastName)
          .where('lastName', '<=', filterLastName + '~')
      }
    } else if (type === 'options') {
      if (filterProfileStatus !== '') {
        query = query.where('profileStatus', '==', filterProfileStatus)
      }
      if (filterRankId !== null && filterRankId !== '') {
        query = query.where('positionId', '==', filterRankId.id)
      }
      if (filterNationality !== '') {
        query = query
          .where('nationality', '>=', filterNationality.toLowerCase())
          .where('nationality', '<=', filterNationality.toLowerCase() + '~')
      }
      if (filterDesiredVesselType !== '') {
        query = query.where(
          'desiredVesselType',
          'array-contains',
          filterDesiredVesselType
        )
      }
    }

    const res = await query.get()
    const arr = []
    res.forEach((el) => {
      let data = JSON.stringify(el.data())
      data = JSON.parse(data)
      arr.push(data)
    })

    employeeList.current = arr
    setFiltering(true)
    setLoading(false)
    getProfilePhotoUrl()
  }

  // Load more employees
  const loadMore = useCallback(
    (firstLoad) => {
      setLoading(true)
      if (lastItem.current !== undefined) {
        const query = db
          .collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .orderBy('date', 'desc')
        let adaptedQuery
        if (firstLoad === true) {
          adaptedQuery = query.limit(resultLimit).get()
        } else {
          adaptedQuery = query
            .startAfter(lastItem.current)
            .limit(resultLimit)
            .get()
        }
        const getEmployeesList = () => {
          return new Promise((resolve) => {
            adaptedQuery.then((resp) => {
              const arr = []
              resp.forEach((el) => {
                let data = JSON.stringify(el.data())
                data = JSON.parse(data)
                if (data.profileStatus === 'active') {
                  if (
                    employeeList.current.findIndex(
                      (el) => el.docId === data.docId
                    ) === -1
                  ) {
                    arr.push(data)
                  }
                }
              })
              resolve([arr, resp])
            })
          })
        }

        getEmployeesList().then((res) => {
          employeeList.current = employeeList.current.concat(res[0])
          setLastItemOnList(res[1].docs[res[1].docs.length - 1])
          lastItem.current = res[1].docs[res[1].docs.length - 1]
          getProfilePhotoUrl()
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    },
    [agencyData, getProfilePhotoUrl]
  )

  // Get first employees list
  useEffect(() => {
    let shouldUpdate = true
    if (window.localStorage.getItem('pictureList') === 'true') {
      if (shouldUpdate) {
        setListViewSelected(false)
        setPictureListViewSelected(true)
      }
    }
    loadMore(true)
    return () => {
      shouldUpdate = false
    }
  }, [loadMore])

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0]
        if (first.isIntersecting) {
          loadMore()
        }
      },
      { threshold: 0.1 }
    )
  )

  useEffect(() => {
    if (!filtering) {
      const currentElement = element
      const currentObserver = observer.current

      if (currentElement) {
        currentObserver.observe(currentElement)
      }

      return () => {
        if (currentElement) {
          currentObserver.unobserve(currentElement)
        }
      }
    }
  }, [element, filtering])

  // Let filter position by ID and by name
  const _positionsOptions = createFilterOptions()
  const positionsOptions = (positionsList, state) => {
    const result = _positionsOptions(positionsList, state)

    if (result.length === 0) {
      return _positionsOptions(positionsList, {
        ...state,
        getOptionLabel: (o) => o.id.toString()
      })
    }

    return result
  }

  const changeListView = (type) => {
    if (type === 'list') {
      setListViewSelected(true)
      setPictureListViewSelected(false)
      window.localStorage.setItem('pictureList', 'false')
    } else if (type === 'pictureList') {
      setListViewSelected(false)
      setPictureListViewSelected(true)
      window.localStorage.setItem('pictureList', 'true')
    }
  }

  const getprofilePictureUrl = (id) => {
    let link = ''
    profilePhotoUrlList.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  const renderVesselTypeList = (array) => {
    if (array !== undefined) {
      const sorted = array.sort((a, b) => a.typeId < b.typeId)
      return sorted.map((type, index) => (
        <div
          key={index}
          style={{
            fontWeight: type.type === 'group' && 'bold'
          }}
        >
          {t(type.name)}
        </div>
      ))
    }
    return null
  }

  if (!loadedData) {
    return <Loader />
  }

  return (
    <div>
      {loading && <Loader />}

      <Headline text={t('crewDatabase')} />

      {/* Filtering */}
      <Accordion classes={{ root: classes.accordionRoot }}>
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
          classes={{ expandIcon: classes.expandIcon }}
        >
          {t('filter')}
        </AccordionSummary>
        <AccordionDetails>
          <div className='crewEmployeesList__filter'>
            {/* Filter by name */}
            <div className='crewEmployeesList__filter-el'>
              <div className='crewEmployeesList__filter-inputBox'>
                <TextField
                  size='small'
                  fullWidth
                  variant='outlined'
                  type='text'
                  label={t('firstName')}
                  value={filterFirstName}
                  onChange={(e) =>
                    setFilterFirstName(e.target.value.toLowerCase())
                  }
                />
              </div>
              <div className='crewEmployeesList__filter-inputBox'>
                <TextField
                  size='small'
                  fullWidth
                  variant='outlined'
                  type='text'
                  label={t('lastName')}
                  value={filterLastName}
                  onChange={(e) =>
                    setFilterLastName(e.target.value.toLowerCase())
                  }
                />
              </div>
              <div className='crewEmployeesList__filter-inputBox'>
                <Button
                  text={t('filter')}
                  handleClick={() => filterResult('name')}
                />
              </div>
              <div className='crewEmployeesList__filter-inputBox'>
                <span
                  className='crewEmployeesList__filter-reset'
                  onClick={() => resetFilter()}
                >
                  {t('reset')}
                </span>
              </div>
            </div>

            {/* Other filter */}
            <div className='crewEmployeesList__filter-el'>
              <div className='crewEmployeesList__filter-el'>
                <div className='crewEmployeesList__filter-inputBox'>
                  <TextField
                    select
                    style={{ width: 150 }}
                    variant='outlined'
                    fullWidth
                    label={t('profileStatus')}
                    size='small'
                    value={filterProfileStatus}
                    onChange={(e) => {
                      setfilterProfileStatus(e.target.value)
                    }}
                  >
                    {profileStatus.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <div className='crewEmployeesList__filter-inputBox'>
                <Autocomplete
                  filterOptions={positionsOptions}
                  options={positionsList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      fullWidth
                      label={t('mainPosition')}
                      variant='outlined'
                      style={{ minWidth: 200 }}
                    />
                  )}
                  value={filterRankId}
                  onChange={(e, val) => {
                    val && setFilterRankId(val)
                  }}
                />
              </div>

              {/* Nationality */}
              <div className='crewEmployeesList__filter-inputBox'>
                {/* <TextField
                  size='small'
                  fullWidth
                  variant='outlined'
                  type='text'
                  label={t('nationality')}
                  value={filterNationality}
                  onChange={(e) =>
                    setFilterNationality(e.target.value.toLowerCase())}
                /> */}
                <TextField
                  select
                  style={{ width: 120 }}
                  variant='outlined'
                  fullWidth
                  label={t('nationality')}
                  size='small'
                  value={filterNationality}
                  onChange={(e) => {
                    setFilterNationality(e.target.value)
                  }}
                >
                  {nationalityList.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              {/* Desired vessel type */}
              <div className='crewEmployeesList__filter-inputBox'>
                <TextField
                  select
                  style={{ width: 200 }}
                  variant='outlined'
                  fullWidth
                  label={t('desiredVesselType')}
                  size='small'
                  value={filterDesiredVesselType}
                  onChange={(e) => {
                    setFilterDesiredVesselType(e.target.value)
                  }}
                >
                  {vesselsList.map((el, i) => (
                    <MenuItem
                      key={i}
                      value={el}
                      style={{
                        fontWeight: el.type === 'group' && 'bold',
                        marginLeft: el.type !== 'group' && 10
                      }}
                    >
                      {t(el.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              {/* Filter button */}
              <div className='crewEmployeesList__filter-inputBox'>
                <Button
                  text={t('filter')}
                  handleClick={() => filterResult('options')}
                />
              </div>

              {/* Reset button */}
              <div className='crewEmployeesList__filter-inputBox'>
                <span
                  className='crewEmployeesList__filter-reset'
                  onClick={() => resetFilter()}
                >
                  {t('reset')}
                </span>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <div className='crewEmployeesList__filter-el'>
        <div
          className='crewEmployeesList__addNew'
          onClick={() =>
            history.push(`/${agencyData.id}/crew-dashboard/add-new-employee`)
          }
        >
          <FontAwesomeIcon
            className='crewEmployeesList__addNew-icon'
            icon={faPlus}
          />{' '}
          <span>{t('addNewEmployee')}</span>
        </div>
      </div>

      {/* Change view icons */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ display: 'flex' }}>
          <div
            style={{ margin: 5, cursor: 'pointer' }}
            className={listViewSelected ? classes.listIconSelected : null}
            onClick={() => changeListView('list')}
          >
            <FontAwesomeIcon icon={faBars} />
          </div>
          <div
            style={{ margin: 5, cursor: 'pointer' }}
            className={
              pictureListViewSelected ? classes.listIconSelected : null
            }
            onClick={() => changeListView('pictureList')}
          >
            <FontAwesomeIcon icon={faList} />
          </div>
        </div>
      </div>

      {/* Display data table */}
      <div>
        {employeeList.current.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} />
                {pictureListViewSelected && (
                  <TableCell className={classes.tableHeader}>
                    {t('profilePhoto')}
                  </TableCell>
                )}

                <TableCell className={classes.tableHeader}>
                  {t('status')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('firstName')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('lastName')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('mainPosition')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('availableFrom')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('nationality')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('desiredVesselType')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {employeeList.current.map((el, i) => (
                <TableRow
                  ref={
                    employeeList.current.length === i + 1 ? setElement : null
                  }
                  hover
                  key={el.docId}
                  classes={{ hover: classes.tableRow }}
                  onClick={() =>
                    window.open(
                      `/${agencyData.id}/crew-dashboard/employee-view/${el.docId}`
                    )
                  }
                >
                  {/*
                   * No
                   */}
                  <TableCell className='global__capitalizeFirstLetter'>
                    {i + 1}
                  </TableCell>

                  {/*
                   * Profile photo
                   */}
                  {pictureListViewSelected && (
                    <TableCell>
                      {getprofilePictureUrl(el.docId) !== '' ? (
                        <img
                          src={getprofilePictureUrl(el.docId)}
                          alt={t('profilePhoto')}
                          style={{
                            maxWidth: 50
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: systemColors.lightElectric,
                            width: 50,
                            height: 50,
                            borderRadius: systemStylingSettings.borderRadius
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              fontSize: 30,
                              color: systemColors.background
                            }}
                            icon={faUser}
                          />
                        </div>
                      )}
                    </TableCell>
                  )}

                  {/*
                   * Status
                   */}
                  <TableCell>
                    <StatusChip status={el.rotationLogStatus} />
                  </TableCell>

                  <TableCell className='global__capitalizeFirstLetter'>
                    {el.firstName}
                  </TableCell>
                  <TableCell className='global__capitalizeFirstLetter'>
                    {el.lastName}
                  </TableCell>
                  <TableCell className='global__capitalizeFirstLetter'>
                    {getRankName(el.positionId)}
                    {el.positionTwoId !== null &&
                      el.positionTwoId !== undefined &&
                      el.positionTwoId !== '' && (
                        <div>{getRankName(el.positionTwoId)}</div>
                      )}
                  </TableCell>
                  <TableCell className='global__capitalizeFirstLetter'>
                    {el.availableFrom}
                  </TableCell>
                  <TableCell className='global__capitalizeFirstLetter'>
                    {el.nationality}
                  </TableCell>
                  <TableCell className='global__capitalizeFirstLetter'>
                    {renderVesselTypeList(el.desiredVesselType)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className='crewEmployeesList__noResult'>
            {t('emptyEmployeeList')}
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj
  }
}

export default connect(mapStateToProps)(CrewEmployessList)
