import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import firebase from 'firebase/app'
import '../firebase'
import Input from '../Components/Input'
import Button from '../Components/Button'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { isAuth } from '../redux/actions/session/isAuth'
import Header from '../Modules/Header'
import Loader from '../Components/Loader'
import logo from '../media/logo/seanor_logo.svg'
import { v4 as uuid } from 'uuid'

const db = firebase.firestore()
const storage = firebase.storage()

const Login = ({ setIsAuth, agencyData, userData, userUID, isAuth }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { agency } = useParams()

  // Change document title
  document.title = `${t('login')} - Seanor`

  const [loading, setLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  // Input states
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const [agencyErr, setAgencyErr] = useState(false)
  const [companyLogoUrl, setcompanyLogoUrl] = useState('')
  const [disabledUserError, setDisabledUserError] = useState(false)

  useEffect(() => {
    setDataLoading(true)

    const checkUserType = (userData, isAuth) => {
      /**
       * Check is user logged in and redirects to dashboard
       */
      if (userData !== null && isAuth) {
        if (userData.type === 'employee') {
          history.push(`/${agency}/dashboard/${userUID}`)
        } else if (userData.type === 'crewagency') {
          history.push(`/${agency}/crew-dashboard/`)
        } else if (userData.type === 'keyagency') {
          history.push(`/${agency}/key-dashboard/`)
        }
      } else {
        if (
          agencyData.logoFileName !== undefined &&
          agencyData.logoFileName !== ''
        ) {
          storage
            .ref()
            .child(
              `/public/agencies/${agencyData.id}/logo/${agencyData.logoFileName}`
            )
            .getDownloadURL()
            .then((url) => {
              setcompanyLogoUrl(url)
              setDataLoading(false)
            })
            .catch((error) => {
              console.error(error)
              setDataLoading(false)
            })
        } else {
          setDataLoading(false)
        }
      }
    }

    return checkUserType(userData, isAuth)
  }, [agency, history, userData, userUID, isAuth, agencyData])

  const updateDeviceId = async (uid) => {
    const deviceId = uuid()
    // Add logged device id to local storage
    window.localStorage.setItem('loggedDeviceId', deviceId)

    // Update create sesion only on one device
    db.collection('users')
      .doc(uid)
      .update({
        'data.loggedDeviceId': deviceId
      })
      .catch((error) => {
        console.error("Can't update user data", error)
      })
  }

  const submit = () => {
    setAgencyErr(false)
    setLoading(true)

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (res) => {
        let resData = JSON.stringify(res.user)
        resData = JSON.parse(resData)
        const uid = resData.uid
        updateDeviceId(uid)

        // Check is user belongs to agency
        db.collection('users')
          .doc(uid)
          .get()
          .then((res) => {
            setIsAuth(true)
            let resData = JSON.stringify(res.data())
            resData = JSON.parse(resData)
            if (resData.data.agencyId === agency) {
              // Check user type and redirect to dashboar by type
              if (resData.data.type === 'employee') {
                history.push(`/${agency}/dashboard/${uid}`)
              } else if (resData.data.type === 'crewagency') {
                history.push(`/${agency}/crew-dashboard`)
              } else if (resData.data.type === 'keyagency') {
                history.push(`/${agency}/key-dashboard`)
              }
            } else {
              setAgencyErr(true)
              setLoading(false)
              firebase.auth().signOut()
            }
          })
          .catch((err) => console.error('User info error', err))
      })
      .catch((err) => {
        console.error('Login error: ', err.message, err.code)

        if (err.code === 'auth/user-disabled') {
          setDisabledUserError(true)
        } else {
          setError(true)
        }
        setLoading(false)
      })
  }

  if (dataLoading) {
    return <Loader />
  }

  return (
    <div>
      {loading && <Loader />}
      <Header />
      <div className='login'>
        <div className='login__box'>
          <div className='login__logo'>
            <img
              src={companyLogoUrl !== '' ? companyLogoUrl : logo}
              alt='logo'
            />
          </div>
          <div className='login__box-title'>
            <h2>{t('loginToCrewManager')}</h2>
          </div>
          <div className='login__input'>
            <div className='login__input-label'>{t('email')}</div>
            <Input type='email' handleInput={(value) => setEmail(value)} />
          </div>
          <div className='login__input'>
            <div className='login__input-label'>{t('password')}</div>
            <Input
              type='password'
              handleInput={(value) => setPassword(value)}
            />
          </div>

          {error && <div className='error'>{t('loginError')}</div>}

          {agencyErr && <div className='error'>{t('agencyErrorLogin')}</div>}

          {disabledUserError && (
            <div className='error'>{t('youHaveNoPermissionToLogin')}</div>
          )}

          <div className='login__submit'>
            <Button text='login' handleClick={() => submit()} />
          </div>
          <div className='login__registration'>
            <a href={`/${agency}/reset-password`}>{t('resetPasswordLogin')}</a>
          </div>
          <div className='login__registration'>
            <a href={`/${agency}/registration`}>{t('newUserRegistration')}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    isAuth: state.isAuthRed.bool
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsAuth: (bool) => dispatch(isAuth(bool))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
