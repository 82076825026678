// Structure data which will be send to database
export default class keyEmployeeDataClass {
  constructor (
    docId,
    userId,
    timeStamp,
    personalData, // obj
    workDiscipline,
    personalSkills, // obj
    experienceArr,
    documents, // obj
    remarks, // obj
    notes, // arr
    evaluations, // arr
    workDisciplineOtherData, // arr
    rotationLog, // arr
    searchTags, // obj
    rotationLogStatus, // string
    profileStatus, // string
    createdDate, // timestamp
    plannedOnProjects, // array
    addType, // string
    createdBy, // object
    log, // array
    verified // object
  ) {
    this.docId = docId
    this.userId = userId
    this.timeStamp = timeStamp
    this.personalData = personalData
    this.workDiscipline = workDiscipline
    this.personalSkills = personalSkills
    this.experienceArr = experienceArr
    this.documents = documents
    this.remarks = remarks
    this.notes = notes
    this.evaluations = evaluations
    this.workDisciplineOtherData = workDisciplineOtherData
    this.rotationLog = rotationLog
    this.searchTags = searchTags
    this.rotationLogStatus = rotationLogStatus
    this.profileStatus = profileStatus
    this.createdDate = createdDate
    this.plannedOnProjects = plannedOnProjects
    this.addType = addType
    this.createdBy = createdBy
    this.log = log
    this.verified = verified
  }
}
