import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Select,
  MenuItem,
  Tooltip
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  systemColors,
  systemStylingSettings
} from '../../../../../globalVariables'
import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { useTranslation } from 'react-i18next'
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Tooltip as ChartTooltip
} from 'recharts'
import ComponentLoader from '../../../../../Components/ComponentLoader'
import { connect } from 'react-redux'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

const db = firebase.firestore()

const useStyles = makeStyles({
  card: {
    width: 'fit-content',
    maxWidth: 500,
    height: '100%'
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: systemColors.darkBlue
  },
  cardHeader: {
    backgroundColor: systemColors.background,
    border: '1px solid #d6d6d6',
    borderBottom: `2px solid ${systemColors.orange}`
  },
  removeCardIcon: {
    padding: '10px 6px 0 0px',
    cursor: 'pointer',
    color: systemColors.grey,
    '&:hover': {
      color: systemColors.darkGrey
    }
  },
  tableHeader: {
    fontWeight: 600
  },
  selectRoot: {
    '& .MuiOutlinedInput-input': {
      padding: 8,
      backgroundColor: systemColors.inputColor
    }
  },
  formControlRoot: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    }
  }
})

const AgentsAddedProfiles = ({ agencyData, agencyUserData }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const [chartData, setChartData] = useState([])
  const [selectedDuration, setSelectedDuration] = useState('thisMonth')
  const [selectedType, setSelectedType] = useState('addedProfiles')

  useEffect(() => {
    let shouldUpdate = true

    ;(async () => {
      db.collection('users')
        .where('data.agencyId', '==', agencyUserData.agencyId)
        .where('data.userRole', 'in', ['super_admin', 'agency_admin', 'agent'])
        .get()
        .then(async (users) => {
          let chartArray = []

          users.forEach(async (user) => {
            let data = JSON.stringify(user.data())
            data = JSON.parse(data)

            const userData = data.data

            let query = db
              .collection('agencies')
              .doc(agencyData.id)
              .collection('employees')
              .where('createdBy.uid', '==', userData.uid)

            if (selectedDuration === 'thisMonth') {
              const thisMonthStartDate = moment()
                .startOf('month')
                .format('YYYY-MM-DD')

              query = query.where(
                'timeStamp',
                '>=',
                new Date(thisMonthStartDate)
              )
            } else if (selectedDuration === 'lastMonth') {
              const lastMonth = moment().subtract(1, 'M')
              const lastMonthStartDate = moment(lastMonth)
                .startOf('month')
                .format('YYYY-MM-DD')
              const lastMonthEndDate = moment(lastMonth)
                .endOf('month')
                .format('YYYY-MM-DD')

              query = query
                .where('timeStamp', '>=', new Date(lastMonthStartDate))
                .where('timeStamp', '<=', new Date(lastMonthEndDate))
            }

            if (selectedType === 'addedProfiles') {
              query = query.where('addType', '==', 'agency')
            } else if (selectedType === 'verifiedProfiles') {
              query = query
                .where('addType', '==', 'online')
                .where('verified.uid', '==', userData.uid)
            }

            await query
              .get()
              .then((respond) => {
                chartArray = [
                  ...chartArray,
                  {
                    name: `${userData.name} ${userData.lastName}`,
                    number: respond.size
                  }
                ]
                if (shouldUpdate) {
                  setLoading(false)
                }
              })
              .catch((error) => {
                console.log(error)

                if (shouldUpdate) {
                  setLoading(false)
                }
              })
            if (shouldUpdate) {
              setChartData(chartArray)
            }
          })
        })

        .catch((error) => {
          console.error(error)
        })
    })()

    return () => {
      shouldUpdate = false
    }
  }, [selectedDuration, selectedType, agencyData, agencyUserData])

  const redirectToPrintPage = () => {
    const sendData = JSON.stringify(chartData)

    window.open(
      `/${agencyData.id}/reports/key/company-statistics/print-agents-added-employees?data=${sendData}&type=${selectedType}&duration=${selectedDuration}`,
      '_blank',
      'noopener,noreferrer'
    )
  }

  return (
    <div style={{ height: '100%' }}>
      <Card classes={{ root: classes.card }}>
        <CardHeader
          classes={{ root: classes.cardHeader, title: classes.title }}
          title={t('agentsAddedEmployees')}
          action={
            <Tooltip title={t('print')} placement='top'>
              <div
                className={classes.removeCardIcon}
                onClick={() => redirectToPrintPage()}
              >
                <FontAwesomeIcon icon={faPrint} />
              </div>
            </Tooltip>
          }
        />
        <CardContent
          style={{
            position: 'relative',
            width: 'fit-content',
            height: '100%',
            marginRight: 20,
            marginBottom: 20
          }}
        >
          {loading && <ComponentLoader />}

          <div style={{ height: 50 }}>
            {/* Select Type */}
            <FormControl
              variant='outlined'
              className='inputPadding'
              classes={{ root: classes.formControlRoot }}
              style={{
                minWidth: 200,
                float: 'left'
              }}
            >
              <Select
                className={classes.selectRoot}
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <MenuItem value='addedProfiles'>{t('addedProfiles')}</MenuItem>
                <MenuItem value='verifiedProfiles'>
                  {t('verifiedProfiles')}
                </MenuItem>
              </Select>
            </FormControl>

            {/* Duration selection */}
            <FormControl
              variant='outlined'
              className='inputPadding'
              classes={{ root: classes.formControlRoot }}
              style={{
                minWidth: 200,
                float: 'right'
              }}
            >
              <Select
                className={classes.selectRoot}
                value={selectedDuration}
                onChange={(e) => setSelectedDuration(e.target.value)}
              >
                <MenuItem value='thisMonth'>{t('thisMonth')}</MenuItem>
                <MenuItem value='lastMonth'>{t('lastMonth')}</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* Chart */}
          <ResponsiveContainer
            width={450}
            height='80%'
            style={{ marginTop: 30 }}
          >
            <BarChart
              data={chartData}
              layout='vertical'
              margin={{ top: 20, right: 0, bottom: 0, left: 20 }}
            >
              <CartesianGrid strokeDasharray='1 1' />
              <XAxis type='number' />
              <YAxis
                type='category'
                dataKey='name'
                width={120}
                tick={{ dx: -10, fill: systemColors.darkBlue }}
              />
              <ChartTooltip />
              <Bar
                dataKey='number'
                name={t('profiles')}
                fill={systemColors.lightElectric}
                barSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  agencyData: state.agencyDataRed.obj,
  agencyUserData: state.agencyUserDataRed.obj
})

export default connect(mapStateToProps)(AgentsAddedProfiles)
