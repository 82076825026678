import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Snackbar,
  Alert,
  Autocomplete,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip
} from '@mui/material'
import React, { useEffect, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import AgencyMenu from '../../../Components/AgencyMenu'
import Headline from '../../../Components/Headline'
import TextEditor from '../../../Components/TextFormatter/TextEditor'
import { countries } from '../../../countries'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import Loader from '../../../Components/Loader'
import TextEditorReader from '../../../Components/TextFormatter/TextEditorReader'
import '../../../styles/routes/recruitment/jobs.scss'
import SeanorDatePicker from '../../../Components/SeanorDatePicker'
import { v4 as uuid } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import moment from 'moment'
import Navigation from '../../../Components/CalendarNavigation/Navigation'
import Preview from './Components/Preview'
import { Stack } from '@mui/system'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

const db = firebase.firestore()

interface JobsProps {
  agencyData: AgencyData
  agencyUserData: AgencyUserData
}

type Direction = 'next' | 'prev'
type JobsFilter = 'myJobs' | 'agencyJobs'
type PrintTypeSelector = 'calendar' | 'custom'

const Jobs = ({ agencyData, agencyUserData }: JobsProps) => {
  const { t, i18n } = useTranslation()
  const jobsLink: string = `e.seanor.eu/${agencyData.id}/key/jobs`
  const contractPeriodValues: ContractPeriodTypes[] = ['month', 'week', 'days']

  // Change document title
  document.title = `${t('jobs')} - Seanor`

  const [loading, setLoading] = useState<boolean>(false)
  const [refreshData, setRefreshData] = useState<boolean>(false)
  const [loadedContent, setLoadedContent] = useState<boolean>(false)

  const [previewMode, setPreviewMode] = useState<boolean>(false)
  const [editJobMode, setEditJobMode] = useState<boolean>(false)

  const [selectedPeriod, setSelectedPeriod] = useState<CalendarPeriod>('week')

  const [dbProjectTypes, setDbProjectTypes] = useState<string[] | undefined>([])
  const [dbAgencyUsers, setDbAgencyUsers] = useState<SelectedAgencyUser[]>([])
  const [agencyAgents, setAgencyAgents] = useState<AgencyUserData[]>([])

  const [showNewEditJobDialog, setShowNewEditJobDialog] =
    useState<boolean>(false)

  const [title, setTitle] = useState<string>('')
  const [titleError, setTitleError] = useState<boolean>(false)
  const [deadline, setDeadline] = useState<string>('')
  const [deadlineError, setDeadlineError] = useState<boolean>(false)
  const [jobRequirements, setJobRequirements] = useState<string>('')
  const [jobDescription, setJobDescription] = useState<string>('')
  const [jobDescriptionError, setJobDescriptionError] = useState<boolean>(false)
  const [country, setCountry] = useState<string>('')
  const [countryError, setCountryError] = useState<boolean>(false)
  const [remoteJob, setRemoteJob] = useState<boolean>(false)
  const [employmentTerm, setEmploymentTerm] =
    useState<EmploymentTermTypes>('permanent')
  const [employmentType, setEmploymentType] =
    useState<EmploymentTypeTypes>('full-time')
  const [contractDuration, setContractDuration] = useState<number>(0)
  const [contractPeriod, setContractPeriod] =
    useState<ContractPeriodTypes>('month')
  const [salary, setSalary] = useState<boolean>(false)
  const [currency, setCurrency] = useState<string>('eur')
  const [salaryMin, setSalaryMin] = useState<number>(0)
  const [salaryMax, setSalaryMax] = useState<number>(0)
  const [salaryRate, setSalaryRate] = useState<SalaryRateTypes>('per-month')
  const [publishFrom, setPublishFrom] = useState<string>('')
  const [publishFromError, setPublishFromError] = useState<boolean>(false)
  const [publishUntil, setPublishUntil] = useState<string>('')
  const [publishUntilError, setPublishUntilError] = useState<boolean>(false)
  const [candidateQuantity, setCandidateQuantity] = useState<number>(1)
  const [projectType, setProjectType] = useState<string>('')
  const [projectManager, setProjectManager] =
    useState<SelectedAgencyUser | null>(null)
  const [projectManagerError, setProjectManagerError] = useState<boolean>(false)
  const [adminsOnPanel, setAdminsOnPanel] = useState<SelectedAgencyUser[]>([])
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<boolean>(false)
  const [activeJob, setActiveJob] = useState<boolean>(false)
  const [publicJob, setPublicJob] = useState<boolean>(false)
  const [allowApplyRegisteredUsers, setAllowApplyRegisteredUsers] =
    useState<boolean>(false)
  const [allowUploadFiles, setAllowUploadFiles] = useState<boolean>(false)
  const [positionStartDate, setPositionStartDate] = useState<string>('')
  const [positionStartDateError, setPositionStartDateError] =
    useState<boolean>(false)

  const [openNewProjectTypeDialog, setOpenNewProjectTypeDialog] =
    useState<boolean>(false)
  const [newTypeName, setNewTypeName] = useState<string>('')
  const [newTypeNameError, setNewTypeNameError] = useState<boolean>(false)

  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>({
    status: false,
    duration: 5000,
    severity: 'success',
    message: ''
  })

  const [showPreviewJobDialog, setShowPreviewJobDialog] =
    useState<boolean>(false)

  const [
    showCloseAddEditJobWarningDialog,
    setShowCloseAddEditJobWarningDialog
  ] = useState<boolean>(false)

  const [calendar, setCalendar] = useState<RecruitmentCalendar[]>([])
  const [dragJobId, setDragJobId] = useState<string>('')
  const [currentStartPeriodDate, setCurrentStartPeriodDate] =
    useState<string>('')

  const [showJobPreview, setShowJobPreview] = useState<boolean>(false)
  const [selectedPreviewJob, setSelectedPreviewJob] = useState<Job | null>(null)
  const [selectedJobId, setSelectedJobId] = useState<string>('')

  const [selectedFilter, setSelectedFilter] = useState<JobsFilter>('myJobs')

  const [totalJobsCount, setTotalJobsCount] = useState<number>(0)
  const [inProgressJobsCount, setInProgressJobsCount] = useState<number>(0)
  const [closedJobsCount, setClosedJobsCount] = useState<number>(0)

  const [showPrintDialog, setShowPrintDialog] = useState<boolean>(false)
  const [enableCustomPrintDates, setEnableCustomPrintDates] =
    useState<boolean>(false)
  const [selectedPrintType, setSelectedPrintType] =
    useState<PrintTypeSelector>('calendar')
  const [printCustomDateFrom, setPrintCustomDateFrom] = useState<string>('')
  const [printCustomDateFromError, setPrintCustomDateFromError] =
    useState<boolean>(false)
  const [printCustomDateTo, setPrintCustomDateTo] = useState<string>('')
  const [printCustomDateToError, setPrintCustomDateToError] =
    useState<boolean>(false)
  const [calendarStartDate, setCalendarStartDate] = useState<string>('')
  const [calendarEndDate, setCalendarEndDate] = useState<string>('')

  /** Count days in dates period, make calander days and fecth jobs */
  const updateCalandarView = async (
    value: CalendarPeriod,
    startPeriod: string,
    filter?: JobsFilter | undefined | null
  ) => {
    setCurrentStartPeriodDate(startPeriod)
    let startDate: string = startPeriod
    let endDate: string = ''
    const currentUserRole: UserRole = agencyUserData.userRole

    if (value === 'week') {
      startDate = moment(startDate).startOf('isoWeek').format('YYYY-MM-DD')
      endDate = moment(startDate).endOf('isoWeek').format('YYYY-MM-DD')
    }

    if (value === 'month') {
      endDate = moment(startDate).endOf('month').format('YYYY-MM-DD')
    }

    if (value === '3months') {
      endDate = moment(startDate)
        .add(2, 'months')
        .endOf('month')
        .format('YYYY-MM-DD')
    }

    setCalendarStartDate(startDate)
    setCalendarEndDate(endDate)

    /** Create new calander days of period */
    let currentDate = moment(startDate)
    let listOfDays: RecruitmentCalendar[] = []

    while (moment(endDate).isSameOrAfter(currentDate)) {
      const firstDay: string = moment(currentDate)
        .startOf('month')
        .format('YYYY-MM-DD')
      const date: string = moment(currentDate).format('YYYY-MM-DD')
      const id: string = uuid()
      const weekNumber: number = moment(currentDate).isoWeek()
      const monthNumber: number = moment(currentDate).month() + 1
      const day: RecruitmentCalendarDay = {
        id,
        date,
        week: weekNumber,
        jobs: []
      }

      if (listOfDays.length === 0) {
        listOfDays = [
          ...listOfDays,
          {
            firstMonthDay: firstDay,
            month: monthNumber,
            days: [day]
          }
        ]
      } else {
        const index = listOfDays.findIndex(
          (el) => el.firstMonthDay === firstDay
        )

        if (index > -1) {
          listOfDays[index].days = [...listOfDays[index].days, day]
        } else {
          listOfDays = [
            ...listOfDays,
            {
              firstMonthDay: firstDay,
              month: monthNumber,
              days: [day]
            }
          ]
        }
      }

      currentDate = currentDate.add(1, 'day')
    }

    /** Get jobs during period */
    let query = db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('jobs')
      .where('data.positionStartDate', '>=', startDate)
      .where('data.positionStartDate', '<=', endDate)

    /** If not admin don show non activated jobs */
    if (
      currentUserRole !== 'agency_admin' &&
      currentUserRole !== 'super_admin'
    ) {
      query = query.where('data.options.activeJob', '==', true)
    }

    /** Filter by current user */
    const currnetUser: SelectedAgencyUser = {
      uid: agencyUserData.uid,
      firstName: agencyUserData.name,
      lastName: agencyUserData.lastName,
      email: agencyUserData.email,
      userRole: agencyUserData.userRole,
      color: agencyUserData.color
    }

    if (filter === 'myJobs') {
      query = query.where('data.adminsOnPanel', 'array-contains', currnetUser)
    } else {
      if (selectedFilter === 'myJobs' && filter !== 'agencyJobs') {
        query = query.where('data.adminsOnPanel', 'array-contains', currnetUser)
      }
    }

    await query
      .get()
      .then((snap) => {
        let jobsArray: Job[] = []
        let totalJobs: number = 0
        let inProgressJobs: number = 0
        let closedJobs: number = 0

        snap.forEach((document) => {
          let data: any = JSON.stringify(document.data())
          data = JSON.parse(data)

          const job: Job = data.data

          jobsArray = [...jobsArray, job]

          totalJobs = totalJobs + 1

          if (job.closedJob.closed) {
            closedJobs = closedJobs + 1
          }
        })

        inProgressJobs = totalJobs - closedJobs

        setTotalJobsCount(totalJobs)
        setInProgressJobsCount(inProgressJobs)
        setClosedJobsCount(closedJobs)

        /** Add jobs to calander */
        jobsArray.forEach((job) => {
          const positionStartDate: string = job.positionStartDate
          const month: number = moment(positionStartDate).month() + 1

          /** Find month where job belongs */
          const monthIndex: number = listOfDays.findIndex(
            (el) => el.month === month
          )

          /** Find day where job belongs */
          if (monthIndex > -1) {
            const dayIndex: number = listOfDays[monthIndex].days.findIndex(
              (el) => el.date === positionStartDate
            )

            if (dayIndex > -1) {
              listOfDays[monthIndex].days[dayIndex].jobs = [
                ...listOfDays[monthIndex].days[dayIndex].jobs,
                job
              ]
            }
          }
        })
      })
      .catch((error) => {
        console.error(error)
      })

    setCalendar(listOfDays)

    return
  }

  useEffect(() => {
    let shouldUpdate: boolean = true

    const getProjectsTypes = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('lists')
        .doc('project_types')
        .get()
        .then((snap: any) => {
          if (shouldUpdate && snap.data() !== undefined) {
            setDbProjectTypes(snap.data().data)
          }
          resolve(null)
        })
        .catch((error) => {
          console.error(error)
          resolve(null)
        })
    })

    const getAgencyUsers = new Promise((resolve) => {
      db.collection('users')
        .where('data.agencyId', '==', agencyData.id)
        .where('data.type', '==', 'keyagency')
        .get()
        .then((snap) => {
          let array: SelectedAgencyUser[] = []
          let agentsArray: AgencyUserData[] = []

          snap.forEach((user) => {
            let data: any = JSON.stringify(user.data())
            data = JSON.parse(data)

            const userData: SelectedAgencyUser = {
              uid: data.data.uid,
              firstName: data.data.name,
              lastName: data.data.lastName,
              email: data.data.email,
              userRole: data.data.userRole,
              color: data.data.color
            }

            array = [...array, userData]
            agentsArray = [...agentsArray, data.data]
          })

          if (shouldUpdate) {
            setDbAgencyUsers(array)
            setAgencyAgents(agentsArray)
          }
          resolve(null)
        })
        .catch((error) => {
          console.error(error)
          resolve(null)
        })
    })

    const getJobs = new Promise((resolve) => {
      const startDate = moment().startOf('isoWeek').format('YYYY-MM-DD')

      updateCalandarView('week', startDate).then(() => resolve(null))
    })

    Promise.all([getProjectsTypes, getAgencyUsers, getJobs])
      .then(() => {
        const defaultPositionStartDate: string = moment().format('YYYY-MM-DD')

        if (shouldUpdate) {
          setPositionStartDate(defaultPositionStartDate)
          setLoadedContent(true)
          setLoading(false)
        }
      })
      .catch((error) => console.error(error))

    return () => {
      shouldUpdate = false
    }
  }, [refreshData, agencyData]) // eslint-disable-line react-hooks/exhaustive-deps

  const closeNewEditJobDialog = () => {
    setShowNewEditJobDialog(false)
    setEditJobMode(false)
    setTitle('')
    setTitleError(false)
    setDeadline('')
    setDeadlineError(false)
    setJobRequirements('')
    setJobDescription('')
    setJobDescriptionError(false)
    setCountry('')
    setCountryError(false)
    setRemoteJob(false)
    setEmploymentTerm('permanent')
    setEmploymentType('full-time')
    setContractDuration(0)
    setContractPeriod('month')
    setSalary(false)
    setCurrency('eur')
    setSalaryMin(0)
    setSalaryMax(0)
    setSalaryRate('per-month')
    setPublishFrom('')
    setPublishFromError(false)
    setPublishUntil('')
    setPublishUntilError(false)
    setCandidateQuantity(1)
    setProjectManager(null)
    setProjectManagerError(false)
    setAdminsOnPanel([])
    setPhoneNumber('')
    setEmail('')
    setEmailError(false)
    setActiveJob(false)
    setPublicJob(false)
    setAllowApplyRegisteredUsers(false)
    setAllowUploadFiles(false)
    setShowCloseAddEditJobWarningDialog(false)
    setSelectedJobId('')
    setPositionStartDateError(false)
    const defaultPositionStartDate: string = moment().format('YYYY-MM-DD')
    setPositionStartDate(defaultPositionStartDate)
  }

  const closeAddNewTypeDialog = () => {
    setOpenNewProjectTypeDialog(false)
    setNewTypeName('')
    setNewTypeNameError(false)
  }

  const addNewProjectTypeToDb = async () => {
    if (newTypeName !== '') {
      setNewTypeNameError(false)
      setLoading(true)

      const newProjectTypesArray = [
        ...(dbProjectTypes as string[]),
        newTypeName
      ]

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('lists')
        .doc('project_types')
        .set({
          data: newProjectTypesArray
        })
        .then(() => {
          setRefreshData(!refreshData)
          closeAddNewTypeDialog()
          setLoading(false)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'error',
            message: t('system_error_try_again_asd54')
          })
        })
    } else {
      setNewTypeNameError(true)
      setSnackbarMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('please_fill_all_required_fields')
      })
    }
  }

  const previewJob = () => {
    if (
      title !== '' &&
      jobDescription !== '' &&
      jobDescription !== '<p><br></p>' &&
      deadline !== '' &&
      country !== '' &&
      publishFrom !== '' &&
      publishUntil !== '' &&
      email !== '' &&
      projectManager !== null &&
      positionStartDate !== ''
    ) {
      setTitleError(false)
      setJobDescriptionError(false)
      setDeadlineError(false)
      setCountryError(false)
      setPublishFromError(false)
      setPublishUntilError(false)
      setProjectManagerError(false)
      setEmailError(false)
      setPositionStartDateError(false)

      setShowPreviewJobDialog(true)
    } else {
      if (title === '') {
        setTitleError(true)
      } else {
        setTitleError(false)
      }

      if (jobDescription === '' || jobDescription === '<p><br></p>') {
        setJobDescriptionError(true)
      } else {
        setJobDescriptionError(false)
      }

      if (deadline === '') {
        setDeadlineError(true)
      } else {
        setDeadlineError(false)
      }

      if (country === '') {
        setCountryError(true)
      } else {
        setCountryError(false)
      }

      if (publishFrom === '') {
        setPublishFromError(true)
      } else {
        setPublishFromError(false)
      }

      if (publishUntil === '') {
        setPublishUntilError(true)
      } else {
        setPublishUntilError(false)
      }

      if (email === '') {
        setEmailError(true)
      } else {
        setEmailError(false)
      }

      if (projectManager === null) {
        setProjectManagerError(true)
      } else {
        setProjectManagerError(false)
      }

      if (positionStartDate === '') {
        setPositionStartDateError(true)
      } else {
        setPositionStartDateError(false)
      }

      setSnackbarMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('please_fill_all_required_fields')
      })
    }
  }

  const saveJobToDatabase = async () => {
    setLoading(true)

    if (!editJobMode) {
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .get()
        .then(async (snap) => {
          const lastJobId = snap.data()?.data.lastJobId
          const jobId = lastJobId !== undefined ? lastJobId + 1 : 1
          const id: string = uuid()
          const jobData: Job = {
            _id: id,
            jobId,
            candidates: 0,
            title,
            deadline,
            jobRequirements,
            jobDescription,
            country,
            remoteJob,
            employmentTerm,
            employmentType,
            contractDuration,
            contractPeriod,
            salary: {
              showSalary: salary,
              currency,
              salaryMin,
              salaryMax,
              salaryRate
            },
            publishFrom,
            publishUntil,
            candidateQuantity,
            projectType,
            projectManager,
            adminsOnPanel,
            phoneNumber,
            email,
            options: {
              activeJob,
              publicJob,
              allowApplyRegisteredUsers,
              allowUploadFiles
            },
            timestamp: new Date(),
            seenAd: 0,
            closedJob: {
              closed: false,
              date: ''
            },
            positionStartDate
          }

          await db
            .collection('agencies')
            .doc(agencyData.id)
            .collection('jobs')
            .doc(id)
            .set({
              data: jobData
            })
            .then(() => {
              /** Update jobs ID number */
              db.collection('agencies')
                .doc(agencyData.id)
                .update({ 'data.lastJobId': jobId })
                .catch((error) => console.error(error))

              updateCalandarView(selectedPeriod, currentStartPeriodDate)
              setShowPreviewJobDialog(false)
              closeNewEditJobDialog()
              setLoading(false)
              setSnackbarMessage({
                status: true,
                duration: 5000,
                severity: 'success',
                message: t('successfully_added')
              })
            })
            .catch((error) => {
              console.error(error)
              setSnackbarMessage({
                status: true,
                duration: 5000,
                severity: 'error',
                message: t('system_error_try_again_asd54')
              })
            })
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'error',
            message: t('system_error_try_again_asd54')
          })
        })
    }

    if (editJobMode) {
      const jobData: object = {
        'data.title': title,
        'data.deadline': deadline,
        'data.jobRequirements': jobRequirements,
        'data.jobDescription': jobDescription,
        'data.country': country,
        'data.remoteJob': remoteJob,
        'data.employmentTerm': employmentTerm,
        'data.employmentType': employmentType,
        'data.contractDuration': contractDuration,
        'data.contractPeriod': contractPeriod,
        'data.salary.currency': currency,
        'data.salary.salaryMin': salaryMin,
        'data.salary.salaryMax': salaryMax,
        'data.salary.salaryRate': salaryRate,
        'data.salary.showSalary': salary,
        'data.publishFrom': publishFrom,
        'data.publishUntil': publishUntil,
        'data.candidateQuantity': candidateQuantity,
        'data.projectType': projectType,
        'data.projectManager': projectManager,
        'data.adminsOnPanel': adminsOnPanel,
        'data.phoneNumber': phoneNumber,
        'data.email': email,
        'data.options.activeJob': activeJob,
        'data.options.publicJob': publicJob,
        'data.options.allowApplyRegisteredUsers': allowApplyRegisteredUsers,
        'data.options.allowUploadFiles': allowUploadFiles,
        'data.positionStartDate': positionStartDate
      }

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('jobs')
        .doc(selectedJobId)
        .update(jobData)
        .then(() => {
          updateCalandarView(selectedPeriod, currentStartPeriodDate)
          setShowPreviewJobDialog(false)
          closeNewEditJobDialog()
          setShowJobPreview(false)
          setLoading(false)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('successfully_updated')
          })
        })
        .catch((error: any) => {
          console.error(error)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'error',
            message: t('system_error_try_again_asd54')
          })
        })
    }
  }

  const copyLink = () => {
    navigator.clipboard.writeText(jobsLink)

    setSnackbarMessage({
      status: true,
      duration: 2000,
      severity: 'success',
      message: t('copied_to_clipboard')
    })
  }

  const handleDrop = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const userRole: UserRole = agencyUserData.userRole
    const newPositionStartDate = event.target.getAttribute('data-date')

    if (
      newPositionStartDate !== null &&
      (userRole === 'agency_admin' || userRole === 'super_admin')
    ) {
      /** Update job's deadline in db */
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('jobs')
        .doc(dragJobId)
        .update({
          'data.positionStartDate': newPositionStartDate
        })
        .then(() => {
          updateCalandarView(selectedPeriod, currentStartPeriodDate)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'error',
            message: t('system_error_try_again_asd54')
          })
        })
    }
  }

  const onDragOver = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const getWeekday = (date: string) => {
    const lng = i18n.language
    const weekDay: number = moment(date).day()
    const monthDay: string = moment(date).day(weekDay).date().toString()
    let result: string = ''

    if (weekDay === 1) {
      result = `${lng === 'lt' ? 'P' : 'M'} ${monthDay}`
    }
    if (weekDay === 2) {
      result = `${lng === 'lt' ? 'A' : 'T'} ${monthDay}`
    }
    if (weekDay === 3) {
      result = `${lng === 'lt' ? 'T' : 'W'} ${monthDay}`
    }
    if (weekDay === 4) {
      result = `${lng === 'lt' ? 'K' : 'T'} ${monthDay}`
    }
    if (weekDay === 5) {
      result = `${lng === 'lt' ? 'P' : 'F'} ${monthDay}`
    }
    if (weekDay === 6) {
      result = `${lng === 'lt' ? 'Š' : 'S'} ${monthDay}`
    }
    if (weekDay === 0 || weekDay === 7) {
      result = `${lng === 'lt' ? 'S' : 'S'} ${monthDay}`
    }

    return result
  }

  const handlePeriodSelection = (value: CalendarPeriod) => {
    let startPeriod: string = moment().startOf('month').format('YYYY-MM-DD')

    if (value === 'week') {
      startPeriod = moment().startOf('isoWeek').format('YYYY-MM-DD')
      updateCalandarView(value, startPeriod)
    }

    if (value === 'month' || value === '3months') {
      updateCalandarView(value, startPeriod)
    }

    setSelectedPeriod(value)
  }

  const getMonthName = (month: number) => {
    if (month === 1) {
      return t('january')
    }
    if (month === 2) {
      return t('february')
    }
    if (month === 3) {
      return t('march')
    }
    if (month === 4) {
      return t('april')
    }
    if (month === 5) {
      return t('may')
    }
    if (month === 6) {
      return t('jun')
    }
    if (month === 7) {
      return t('july')
    }
    if (month === 8) {
      return t('august')
    }
    if (month === 9) {
      return t('september')
    }
    if (month === 10) {
      return t('october')
    }
    if (month === 11) {
      return t('november')
    }
    if (month === 12) {
      return t('december')
    }
  }

  const checkIsWeekend = (date: string) => {
    const weekdayNumber: number = moment(date).isoWeekday()
    if (weekdayNumber > 5) {
      return true
    }
    return false
  }

  const showWeekNumber = (
    currentIndex: number,
    currentWeek: number,
    previousArray: RecruitmentCalendarDay[],
    date: string
  ) => {
    const index: number = currentIndex - 1
    let prevWeek: number = 0
    const weekDay: number = moment(date).day()

    if (weekDay === 1) {
      if (index < 0) {
        return true
      }

      prevWeek = previousArray[index].week

      if (currentWeek > prevWeek) {
        return true
      }
    }

    return false
  }

  const handleYearMonthChange = (value: string) => {
    const selectedDate: string = moment(value).format('YYYY-MM-DD')
    updateCalandarView(selectedPeriod, selectedDate)
  }

  const resetCalenderToToday = () => {
    const startDate = moment().startOf('isoWeek').format('YYYY-MM-DD')
    setSelectedPeriod('week')
    updateCalandarView('week', startDate)
  }

  /** Used to move next or previouse week, month */
  const navigate = (direction: Direction) => {
    let startPerdiod: string = currentStartPeriodDate

    if (direction === 'next') {
      if (selectedPeriod === 'week') {
        startPerdiod = moment(currentStartPeriodDate)
          .add(1, 'week')
          .format('YYYY-MM-DD')
      }
      if (selectedPeriod === 'month' || selectedPeriod === '3months') {
        startPerdiod = moment(currentStartPeriodDate)
          .add(1, 'month')
          .format('YYYY-MM-DD')
      }
    }

    if (direction === 'prev') {
      if (selectedPeriod === 'week') {
        startPerdiod = moment(currentStartPeriodDate)
          .subtract(1, 'week')
          .format('YYYY-MM-DD')
      }
      if (selectedPeriod === 'month' || selectedPeriod === '3months') {
        startPerdiod = moment(currentStartPeriodDate)
          .subtract(1, 'month')
          .format('YYYY-MM-DD')
      }
    }
    updateCalandarView(selectedPeriod, startPerdiod)
  }

  const openPreview = (job: Job) => {
    setShowJobPreview(true)
    setSelectedPreviewJob(job)
  }

  const closePreview = () => {
    setShowJobPreview(false)
    setSelectedPreviewJob(null)
  }

  const openPreviewJobDialog = (job: Job | null) => {
    if (job !== null) {
      setTitle(job.title)
      setJobRequirements(job.jobRequirements)
      setJobDescription(job.jobDescription)
      setDeadline(job.deadline)
      setCountry(job.country)
      setRemoteJob(job.remoteJob)
      setEmploymentTerm(job.employmentTerm)
      setEmploymentType(job.employmentType)
      setContractDuration(job.contractDuration)
      setContractPeriod(job.contractPeriod)
      setSalary(job.salary.showSalary)
      setCurrency(job.salary.currency)
      setSalaryMin(job.salary.salaryMin)
      setSalaryMax(job.salary.salaryMax)
      setSalaryRate(job.salary.salaryRate)
      setPublishFrom(job.publishFrom)
      setPublishUntil(job.publishUntil)
      setCandidateQuantity(job.candidateQuantity)
      setContractPeriod(job.contractPeriod)
      setProjectType(job.projectType)
      setProjectManager(job.projectManager)
      setAdminsOnPanel(job.adminsOnPanel)
      setPhoneNumber(job.phoneNumber)
      setEmail(job.email)
      setPositionStartDate(job.positionStartDate)

      setPreviewMode(true)
      setShowPreviewJobDialog(true)
    }
  }

  const closeJob = async (id: string) => {
    setLoading(true)
    const currentDate: string = moment().format('YYYY-MM-DD')

    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('jobs')
      .doc(id)
      .update({
        'data.closedJob.closed': true,
        'data.closedJob.date': currentDate
      })
      .then(() => {
        updateCalandarView(selectedPeriod, currentStartPeriodDate)
        setSelectedPreviewJob(null)
        setShowJobPreview(false)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
        setSnackbarMessage({
          status: true,
          duration: 5000,
          severity: 'error',
          message: t('system_error_try_again_asd54')
        })
      })
  }

  const openJob = async (id: string) => {
    setLoading(true)

    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('jobs')
      .doc(id)
      .update({
        'data.closedJob.closed': false
      })
      .then(() => {
        updateCalandarView(selectedPeriod, currentStartPeriodDate)
        setSelectedPreviewJob(null)
        setShowJobPreview(false)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
        setSnackbarMessage({
          status: true,
          duration: 5000,
          severity: 'error',
          message: t('system_error_try_again_asd54')
        })
      })
  }

  const editJob = (job: Job | null) => {
    if (job !== null) {
      setEditJobMode(true)
      setSelectedJobId(job._id)
      setTitle(job.title)
      setJobRequirements(job.jobRequirements)
      setJobDescription(job.jobDescription)
      setDeadline(job.deadline)
      setCountry(job.country)
      setRemoteJob(job.remoteJob)
      setEmploymentTerm(job.employmentTerm)
      setEmploymentType(job.employmentType)
      setContractDuration(job.contractDuration)
      setContractPeriod(job.contractPeriod)
      setSalary(job.salary.showSalary)
      setCurrency(job.salary.currency)
      setSalaryMin(job.salary.salaryMin)
      setSalaryMax(job.salary.salaryMax)
      setSalaryRate(job.salary.salaryRate)
      setPublishFrom(job.publishFrom)
      setPublishUntil(job.publishUntil)
      setCandidateQuantity(job.candidateQuantity)
      setContractPeriod(job.contractPeriod)
      setProjectType(job.projectType)
      setProjectManager(job.projectManager)
      setAdminsOnPanel(job.adminsOnPanel)
      setPhoneNumber(job.phoneNumber)
      setEmail(job.email)
      setActiveJob(job.options.activeJob)
      setPublicJob(job.options.publicJob)
      setAllowApplyRegisteredUsers(job.options.allowApplyRegisteredUsers)
      setAllowUploadFiles(job.options.allowUploadFiles)
      setPositionStartDate(job.positionStartDate)

      setShowNewEditJobDialog(true)
    }
  }

  const closeJobPreviewDialog = () => {
    setShowPreviewJobDialog(false)
    setPreviewMode(false)
  }

  const handleCalendarFilterChange = (event: MouseEvent, value: string) => {
    if (value !== null) {
      setSelectedFilter(value as JobsFilter)
      updateCalandarView(
        selectedPeriod,
        currentStartPeriodDate,
        value as JobsFilter
      )
    } else {
      setSelectedFilter('myJobs')
      updateCalandarView(selectedPeriod, currentStartPeriodDate, 'myJobs')
    }
  }

  const closePrintDialog = () => {
    setShowPrintDialog(false)
    setEnableCustomPrintDates(false)
    setSelectedPrintType('calendar')
    setPrintCustomDateFrom('')
    setPrintCustomDateTo('')
    setPrintCustomDateFromError(false)
    setPrintCustomDateToError(false)
  }

  const handlePrintTypeSelection = (type: PrintTypeSelector) => {
    setSelectedPrintType(type)

    /** Enable inputs */
    if (type === 'custom') {
      setEnableCustomPrintDates(true)
    } else {
      setEnableCustomPrintDates(false)
    }
  }

  const printJobsReport = () => {
    let dateFrom: string = ''
    let dateTo: string = ''

    if (selectedPrintType === 'calendar') {
      dateFrom = calendarStartDate
      dateTo = calendarEndDate
    }

    if (selectedPrintType === 'custom') {
      dateFrom = printCustomDateFrom
      dateTo = printCustomDateTo
    }

    /** Check if dates are selected */
    if (selectedPrintType === 'custom' && (dateFrom === '' || dateTo === '')) {
      setSnackbarMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('please_select_correct_dates')
      })
      return
    }

    /** Check do from date not bigger then to date */
    if (selectedPrintType === 'custom' && dateFrom > dateTo) {
      setSnackbarMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('please_select_correct_dates')
      })
      return
    }

    setLoading(true)
    let systemLanguage = i18n.language
    if (systemLanguage === 'en-BG') {
      systemLanguage = 'en'
    }
    if (systemLanguage === 'en-US') {
      systemLanguage = 'en'
    }
    if (systemLanguage === 'lt-LT') {
      systemLanguage = 'lt'
    }
    if (systemLanguage !== 'en' && systemLanguage !== 'lt') {
      systemLanguage = 'en'
    }

    axios({
      method: 'POST',
      url: 'https://europe-west1-norspeda.cloudfunctions.net/calendarJobsReport',
      data: {
        agencyId: agencyData.id,
        language: systemLanguage,
        dateFrom,
        dateTo
      }
    })
      .then((response) => {
        if (
          navigator.userAgent.indexOf('Safari') > -1 &&
          navigator.userAgent.indexOf('Chrome') === -1
        ) {
          window.location.href = response.data.url
        } else {
          window.open(response.data.url, '_blank')
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const getBorderColorByAgent = (uid: string | undefined) => {
    if (uid !== undefined) {
      const agentColor: string = dbAgencyUsers.filter((el) => el.uid === uid)[0]
        .color

      return agentColor
    }
  }

  if (!loadedContent) return <Loader />

  return (
    <div>
      {loading && <Loader />}

      <Headline text={t('jobs')} />
      <AgencyMenu type='key' />

      {/* Messeges */}
      <Snackbar
        open={snackbarMessage.status}
        autoHideDuration={snackbarMessage.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() =>
          setSnackbarMessage({ ...snackbarMessage, status: false })
        }
      >
        <Alert
          severity={snackbarMessage.severity}
          onClose={() =>
            setSnackbarMessage({ ...snackbarMessage, status: false })
          }
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>

      {/* New or Edit job dialog */}
      <Dialog open={showNewEditJobDialog} maxWidth='md' fullWidth>
        <DialogTitle>
          {!editJobMode ? t('create_job') : t('edit_job')}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <TextField
                required
                fullWidth
                size='small'
                type='text'
                label={t('job_title')}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                error={titleError}
              />
            </Grid>

            {/* Job requirements */}
            <Grid item xs={12}>
              <Typography variant='h6'>{t('job_requirements')}</Typography>
              <TextEditor
                defaultValue={jobRequirements}
                onChange={(value) => setJobRequirements(value)}
              />
            </Grid>

            {/* Job description */}
            <Grid item xs={12}>
              <Typography
                variant='h6'
                style={{ color: jobDescriptionError ? 'red' : '' }}
              >
                {t('job_description')}*
              </Typography>

              <TextEditor
                defaultValue={jobDescription}
                onChange={(value) => setJobDescription(value)}
                error={jobDescriptionError}
              />
            </Grid>

            {/* Deadline */}
            <Grid item xs={12} sm={6} md={3}>
              <SeanorDatePicker
                error={deadlineError}
                label={t('deadline')}
                value={deadline}
                onChange={(date) => setDeadline(date)}
              />
            </Grid>

            {/* Countntry */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                required
                fullWidth
                size='small'
                value={country}
                label={t('country')}
                onChange={(e) => setCountry(e.target.value)}
                error={countryError}
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Remote job */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                control={
                  <Switch
                    color='electric'
                    checked={remoteJob}
                    onChange={() => setRemoteJob(!remoteJob)}
                  />
                }
                label={t('remote_job')}
              />
            </Grid>

            {/* Employment term */}
            <Grid item xs={12}>
              <FormControl>
                <Typography variant='subtitle1'>
                  {t('employment_term')}
                </Typography>

                <RadioGroup
                  row
                  value={employmentTerm}
                  onChange={(e) =>
                    setEmploymentTerm(e.target.value as EmploymentTermTypes)
                  }
                >
                  {/* Permanent */}
                  <FormControlLabel
                    value='permanent'
                    control={<Radio color='electric' />}
                    label={t('permanent')}
                  />

                  {/* Contract */}
                  <FormControlLabel
                    value='contract'
                    control={<Radio color='electric' />}
                    label={t('contract')}
                  />

                  {/* Temporary */}
                  <FormControlLabel
                    value='temporary'
                    control={<Radio color='electric' />}
                    label={t('temporary')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Employment type */}
            <Grid item xs={12}>
              <FormControl>
                <Typography variant='subtitle1'>
                  {t('employment_type')}
                </Typography>

                <RadioGroup
                  row
                  value={employmentType}
                  onChange={(e) =>
                    setEmploymentType(e.target.value as EmploymentTypeTypes)
                  }
                >
                  {/* Full time */}
                  <FormControlLabel
                    value='full-time'
                    control={<Radio color='electric' />}
                    label={t('full_time')}
                  />

                  {/* Part time */}
                  <FormControlLabel
                    value='part-time'
                    control={<Radio color='electric' />}
                    label={t('part_time')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Contract duration */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size='small'
                type='number'
                label={t('contract_duration')}
                value={contractDuration}
                onChange={(e) => setContractDuration(Number(e.target.value))}
              />
            </Grid>

            {/* Contract period */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                fullWidth
                size='small'
                label={t('contract_period')}
                value={contractPeriod}
                style={{ textTransform: 'capitalize' }}
                onChange={(e) =>
                  setContractPeriod(e.target.value as ContractPeriodTypes)
                }
              >
                {contractPeriodValues.map((period) => (
                  <MenuItem
                    key={period}
                    value={period}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {t(period)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Salary */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    color='electric'
                    checked={salary}
                    onChange={() => setSalary(!salary)}
                  />
                }
                label={t('salary')}
              />
            </Grid>

            {/* Pay rate */}
            {salary && (
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  select
                  fullWidth
                  size='small'
                  label={t('pay_rate')}
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  {agencyData.settings.companySettings.currencies.map(
                    (currency) => (
                      <MenuItem
                        key={currency}
                        value={currency}
                        style={{ textTransform: 'uppercase' }}
                      >
                        {currency}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
            )}

            {/* Salary min */}
            {salary && (
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  size='small'
                  type='number'
                  label={t('min')}
                  value={salaryMin}
                  onChange={(e) => setSalaryMin(Number(e.target.value))}
                />
              </Grid>
            )}

            {/* Salary max */}
            {salary && (
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  size='small'
                  type='number'
                  label={t('max')}
                  value={salaryMax}
                  onChange={(e) => setSalaryMax(Number(e.target.value))}
                />
              </Grid>
            )}

            {/* Rate */}
            {salary && (
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  select
                  fullWidth
                  size='small'
                  label={t('rate')}
                  value={salaryRate}
                  onChange={(e) =>
                    setSalaryRate(e.target.value as SalaryRateTypes)
                  }
                >
                  <MenuItem value='per-month'>{t('per_month')}</MenuItem>
                  <MenuItem value='per-hour'>{t('per_hour')}</MenuItem>
                </TextField>
              </Grid>
            )}

            {/* Position start date */}
            <Grid item xs={12} sm={6} md={3}>
              <SeanorDatePicker
                error={positionStartDateError}
                label={t('position_start_date')}
                value={positionStartDate}
                onChange={(date) => setPositionStartDate(date)}
              />
            </Grid>

            {/* Publish on */}
            <Grid item xs={12} sm={6} md={3}>
              <SeanorDatePicker
                error={publishFromError}
                label={t('publish_on')}
                value={publishFrom}
                onChange={(date) => setPublishFrom(date)}
              />
            </Grid>

            {/* Until */}
            <Grid item xs={12} sm={6} md={3}>
              <SeanorDatePicker
                error={publishUntilError}
                label={t('until')}
                value={publishUntil}
                onChange={(date) => setPublishUntil(date)}
              />
            </Grid>

            {/* Candidate quantity */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size='small'
                type='number'
                InputProps={{ inputProps: { min: 1 } }}
                label={t('candidate_quantity')}
                value={candidateQuantity}
                onChange={(e) => setCandidateQuantity(Number(e.target.value))}
              />
            </Grid>

            {/* Project type */}
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size='small'
                label={t('project_type')}
                value={projectType}
                onChange={(e) =>
                  setProjectType(e.target.value as ContractPeriodTypes)
                }
              >
                {dbProjectTypes?.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}

                <MenuItem onClick={() => setOpenNewProjectTypeDialog(true)}>
                  + {t('add_new_type')}
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>

          {/* Asign agents */}
          <Grid container spacing={3} style={{ marginTop: 100 }}>
            <Grid item xs={12}>
              <Typography variant='h5'>{t('company_panel')}</Typography>
            </Grid>

            {/* Project manager */}
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                size='small'
                options={dbAgencyUsers}
                getOptionLabel={(user: any) =>
                  `${user.firstName} ${user.lastName}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={t('project_manager')}
                    error={projectManagerError}
                  />
                )}
                value={projectManager}
                onChange={(event: any, newValue: SelectedAgencyUser | null) => {
                  setProjectManager(newValue)
                }}
              />
            </Grid>

            {/* Asign to agents */}
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                size='small'
                options={dbAgencyUsers}
                getOptionLabel={(user: any) =>
                  `${user.firstName} ${user.lastName}`
                }
                renderInput={(params) => (
                  <TextField {...params} label={t('asign_to_agents')} />
                )}
                value={adminsOnPanel}
                onChange={(event: any, newValue: SelectedAgencyUser[]) => {
                  setAdminsOnPanel(newValue)
                }}
              />
            </Grid>

            {/* Contact information label */}
            <Grid item xs={12}>
              <Typography variant='h6'>{t('contact_information')}</Typography>
            </Grid>

            {/* Phone */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size='small'
                type='tel'
                label={t('phone')}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                required
                size='small'
                type='email'
                label={t('email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
              />
            </Grid>

            {/* Options label */}
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Typography variant='h6'>{t('options')}</Typography>
            </Grid>

            {/* Job Listing is active */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    color='electric'
                    checked={activeJob}
                    onChange={() => setActiveJob(!activeJob)}
                  />
                }
                label={t('job_listing_is_active_asd5d')}
              />
            </Grid>

            {/* Job Listing is published online */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!activeJob}
                    color='electric'
                    checked={publicJob}
                    onChange={() => setPublicJob(!publicJob)}
                  />
                }
                label={t('publish_this_job_online_portal_asd5d')}
              />
            </Grid>

            {/* Allow candidate apply */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    color='electric'
                    checked={allowApplyRegisteredUsers}
                    onChange={() =>
                      setAllowApplyRegisteredUsers(!allowApplyRegisteredUsers)
                    }
                  />
                }
                label={t('allow_candidate_apply_via_job_portal_asd5d')}
              />
            </Grid>

            {/* Allow upload files*/}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    color='electric'
                    checked={allowUploadFiles}
                    onChange={() => setAllowUploadFiles(!allowUploadFiles)}
                  />
                }
                label={t('allow_candidate_upload_files_asd5d')}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Close Button */}
          <Button onClick={() => setShowCloseAddEditJobWarningDialog(true)}>
            {t('cancel')}
          </Button>

          {/* Save Button */}
          <Button variant='containedRed' onClick={() => previewJob()}>
            {editJobMode ? t('update') : t('save')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add new project type dialog */}
      <Dialog open={openNewProjectTypeDialog} fullWidth maxWidth='sm'>
        <DialogTitle>{t('add_new_type')}</DialogTitle>

        <DialogContent>
          <TextField
            fullWidth
            required
            size='small'
            label={t('type_name')}
            type='text'
            style={{ marginTop: 20 }}
            value={newTypeName}
            onChange={(e) => setNewTypeName(e.target.value)}
            error={newTypeNameError}
          />
        </DialogContent>

        <DialogActions>
          {/* Cancel button */}
          <Button size='small' onClick={() => closeAddNewTypeDialog()}>
            {t('cancel')}
          </Button>

          {/* Create button */}
          <Button
            variant='containedRed'
            size='small'
            onClick={() => addNewProjectTypeToDb()}
          >
            {t('add')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Close new Edit job Warning dialog */}
      <Dialog
        open={showCloseAddEditJobWarningDialog}
        onClose={() => setShowCloseAddEditJobWarningDialog(false)}
      >
        <DialogContent>
          {t('shure_you_eant_tocancel_fulfill_process_165456')}
        </DialogContent>

        <DialogActions>
          {/* Cancel button */}
          <Button onClick={() => setShowCloseAddEditJobWarningDialog(false)}>
            {t('cancel')}
          </Button>

          {/* Proceed button */}
          <Button
            size='small'
            variant='containedRed'
            onClick={() => closeNewEditJobDialog()}
          >
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview job dialog */}
      <Dialog open={showPreviewJobDialog} maxWidth='md' fullWidth>
        <DialogContent>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <h2>{title}</h2>
            </Grid>

            {/* Job requirements */}
            <Grid item xs={12}>
              <Typography variant='h6'>{t('job_requirements')}</Typography>
              <TextEditorReader
                className='job__textReader'
                content={jobRequirements}
              />
            </Grid>

            {/* Job description */}
            <Grid item xs={12}>
              <Typography variant='h6'>{t('job_description')}</Typography>

              <TextEditorReader
                className='job__textReader'
                content={jobDescription}
              />
            </Grid>

            {/* Deadline */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h6'>{t('deadline')}</Typography>
              <div>{deadline}</div>
            </Grid>

            {/* Country */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h6'>{t('country')}</Typography>
              <div>{country}</div>
            </Grid>

            {/* Remote job */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                control={<Switch color='electric' checked={remoteJob} />}
                label={t('remote_job')}
              />
            </Grid>

            {/* Employment term */}
            <Grid item xs={12}>
              <Typography variant='h6'>{t('employment_term')}</Typography>
              <div>{t(employmentTerm)}</div>
            </Grid>

            {/* Employment type */}
            <Grid item xs={12}>
              <Typography variant='h6'>{t('employment_type')}</Typography>
              <div>
                {t(
                  employmentType === 'full-time'
                    ? 'full_time'
                    : employmentType === 'part-time'
                    ? 'part_time'
                    : ''
                )}
              </div>
            </Grid>

            {/* Contract duration */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h6'>{t('contract_duration')}</Typography>
              <div>{contractDuration}</div>
            </Grid>

            {/* Contract period */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h6'>{t('contract_period')}</Typography>
              <div style={{ textTransform: 'capitalize' }}>
                {t(contractPeriod)}
              </div>
            </Grid>

            {/* Salary */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControlLabel
                control={<Switch color='electric' checked={salary} />}
                label={t('salary')}
              />
            </Grid>

            {/* Pay rate */}
            {salary && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant='h6'>{t('pay_rate')}</Typography>
                <div style={{ textTransform: 'uppercase' }}>{currency}</div>
              </Grid>
            )}

            {/* Salary min */}
            {salary && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant='h6'>{t('min')}</Typography>
                <div>{salaryMin}</div>
              </Grid>
            )}

            {/* Salary max */}
            {salary && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant='h6'>{t('max')}</Typography>
                <div>{salaryMax}</div>
              </Grid>
            )}

            {/* Rate */}
            {salary && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant='h6'>{t('rate')}</Typography>
                <div>
                  {t(
                    salaryRate === 'per-hour'
                      ? 'per_hour'
                      : salaryRate === 'per-month'
                      ? 'per_month'
                      : ''
                  )}
                </div>
              </Grid>
            )}

            {/* Position start date  */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h6'>{t('position_start_date')}</Typography>
              <div>{positionStartDate}</div>
            </Grid>

            {/* Publish on */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h6'>{t('publish_on')}</Typography>
              <div>{publishFrom}</div>
            </Grid>

            {/* Until */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h6'>{t('until')}</Typography>
              <div>{publishUntil}</div>
            </Grid>

            {/* Candidate quantity */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h6'>{t('candidate_quantity')}</Typography>
              <div>{candidateQuantity}</div>
            </Grid>

            {/* Project type */}
            <Grid item xs={12} sm={6}>
              <Typography variant='h6'>{t('project_type')}</Typography>
              <div style={{ textTransform: 'capitalize' }}>{projectType}</div>
            </Grid>
          </Grid>

          {/* Asign agents */}
          <Grid container spacing={3} style={{ marginTop: 100 }}>
            <Grid item xs={12}>
              <Typography variant='h5'>{t('company_panel')}</Typography>
            </Grid>

            {/* Project manager */}
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant='h6'>{t('project_manager')}</Typography>
              <div>
                {projectManager?.firstName} {projectManager?.lastName}
              </div>
            </Grid>

            {/* Asign to agents */}
            <Grid item xs={12} md={6}>
              <Typography variant='h6'>{t('asign_to_agents')}</Typography>
              <div>
                {adminsOnPanel.map((agent) => (
                  <div key={agent.uid}>
                    {agent?.firstName} {agent?.lastName}
                  </div>
                ))}
              </div>
            </Grid>

            {/* Contact information label */}
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Typography variant='h6'>{t('contact_information')}</Typography>
            </Grid>

            {/* Phone */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h6'>{t('phone')}</Typography>
              <div>{phoneNumber}</div>
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant='h6'>{t('email')}</Typography>
              <div>{email}</div>
            </Grid>
          </Grid>

          {/* Options label */}
          {!previewMode && (
            <Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Typography variant='h6'>{t('options')}</Typography>
              </Grid>

              {/* Job Listing is active */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch color='electric' checked={activeJob} />}
                  label={t('job_listing_is_active_asd5d')}
                />
              </Grid>

              {/* Job Listing is published online */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!activeJob}
                      color='electric'
                      checked={publicJob}
                    />
                  }
                  label={t('publish_this_job_online_portal_asd5d')}
                />
              </Grid>

              {/* Allow candidate apply */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      color='electric'
                      checked={allowApplyRegisteredUsers}
                    />
                  }
                  label={t('allow_candidate_apply_via_job_portal_asd5d')}
                />
              </Grid>

              {/* Allow upload files*/}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch color='electric' checked={allowUploadFiles} />
                  }
                  label={t('allow_candidate_upload_files_asd5d')}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          {/* Cancel button */}
          <Button onClick={() => closeJobPreviewDialog()}>{t('cancel')}</Button>

          {/* Proceed button */}
          {!previewMode && (
            <Button
              size='small'
              variant='containedRed'
              onClick={() => saveJobToDatabase()}
            >
              {editJobMode ? t('update') : t('save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Preview
        open={showJobPreview}
        onClose={() => closePreview()}
        job={selectedPreviewJob}
        userRole={agencyUserData.userRole}
        openPrieviewJobDialog={(job) => openPreviewJobDialog(job)}
        closeJob={(id) => closeJob(id)}
        openJob={(id) => openJob(id)}
        editJob={(job) => editJob(job)}
        onRefresh={() =>
          updateCalandarView(selectedPeriod, currentStartPeriodDate)
        }
        agencyAgents={agencyAgents}
      />

      {/* Print dialog */}
      <Dialog
        open={showPrintDialog}
        onClose={() => closePrintDialog()}
        fullWidth
        maxWidth='xs'
      >
        <DialogContent>
          {/* Print dates selector */}
          <RadioGroup
            value={selectedPrintType}
            onChange={(event) =>
              handlePrintTypeSelection(event.target.value as PrintTypeSelector)
            }
          >
            <FormControlLabel
              value='calendar'
              control={<Radio color='electric' size='small' />}
              label={t('print_by_calender_dates')}
            />
            <FormControlLabel
              value='custom'
              control={<Radio color='electric' size='small' />}
              label={t('print_by_custom_dates')}
            />
          </RadioGroup>

          {/* Inputs */}
          <Grid container spacing={2} style={{ marginTop: 0 }}>
            {/* Date from */}
            <Grid item md={6}>
              <TextField
                type='date'
                disabled={!enableCustomPrintDates}
                fullWidth
                size='small'
                label={t('from')}
                InputLabelProps={{
                  shrink: true
                }}
                value={printCustomDateFrom}
                onChange={(event) => setPrintCustomDateFrom(event.target.value)}
                error={printCustomDateFromError}
              />
            </Grid>

            {/* Date to */}
            <Grid item md={6}>
              <TextField
                type='date'
                disabled={!enableCustomPrintDates}
                fullWidth
                size='small'
                label={t('to')}
                InputLabelProps={{
                  shrink: true
                }}
                value={printCustomDateTo}
                onChange={(event) => setPrintCustomDateTo(event.target.value)}
                error={printCustomDateToError}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Print button */}
          <Button
            size='small'
            color='orange'
            variant='contained'
            onClick={() => printJobsReport()}
          >
            {t('print')}
          </Button>

          {/* Cancel button */}
          <Button size='small' onClick={() => closePrintDialog()}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <main>
        {/* Jobs page link */}
        <div className='link'>
          <a
            href={`/${agencyData.id}/key/jobs`}
            target='_blank'
            rel='noreferrer'
          >
            {jobsLink}
          </a>

          <Tooltip title={t('copy')}>
            <div style={{ display: 'inline-block' }} onClick={() => copyLink()}>
              <FontAwesomeIcon className='copy' icon={faCopy as IconProp} />
            </div>
          </Tooltip>
        </div>

        {/* New job position button */}
        <div style={{ margin: 20 }}>
          <Button
            variant='containedRed'
            size='small'
            onClick={() => setShowNewEditJobDialog(true)}
          >
            {t('create_job')}
          </Button>
        </div>

        {/* Calendar */}
        <div className='calendar'>
          {/* Calendar options */}
          <div
            style={{
              marginBottom: 20,
              marginTop: 40,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
              flexWrap: 'wrap'
            }}
          >
            {/* Filtration */}
            <ToggleButtonGroup
              size='small'
              value={selectedFilter}
              exclusive
              onChange={handleCalendarFilterChange}
            >
              <ToggleButton value='myJobs'>{t('my_jobs')}</ToggleButton>
              <ToggleButton value='agencyJobs'>{t('agency_jobs')}</ToggleButton>
            </ToggleButtonGroup>

            {/* Counters */}
            <Stack direction='row' spacing={1} style={{ marginTop: 10 }}>
              {/* Total jobs */}
              <Tooltip title={t('total')} placement='top'>
                <div className='counter'>{totalJobsCount}</div>
              </Tooltip>

              {/* In progress */}
              <Tooltip title={t('in_progress')} placement='top'>
                <div className='counter__progress'>{inProgressJobsCount}</div>
              </Tooltip>

              {/* Closed */}
              <Tooltip title={t('closed')} placement='top'>
                <div className='counter__closed'>{closedJobsCount} </div>
              </Tooltip>
            </Stack>

            {/* Print */}
            <div className='print'>
              <Tooltip title={t('print_report')} placement='top'>
                <div onClick={() => setShowPrintDialog(true)}>
                  <FontAwesomeIcon icon={faPrint as IconProp} />
                </div>
              </Tooltip>
            </div>
          </div>

          {/* Calender container */}
          <div className='calendar_container'>
            {calendar.map((month: RecruitmentCalendar) => (
              <div
                key={month.firstMonthDay}
                className={
                  selectedPeriod !== 'week'
                    ? 'calendar_month'
                    : 'calendar_month__week'
                }
              >
                {/* Month name */}
                <h5>{getMonthName(month.month)}</h5>

                {/* Days */}
                <div className='calendar_days'>
                  {month.days.map(
                    (
                      day: RecruitmentCalendarDay,
                      index: number,
                      prevArray: RecruitmentCalendarDay[]
                    ) => (
                      <div
                        className={
                          checkIsWeekend(day.date)
                            ? 'calendar_day__weekend'
                            : showWeekNumber(
                                index,
                                day.week,
                                prevArray,
                                day.date
                              ) === true
                            ? 'calendar_day__monday'
                            : 'calendar_day'
                        }
                        id={day.id}
                        key={day.id}
                        data-date={day.date}
                        onDragOver={onDragOver}
                        onDrop={handleDrop}
                      >
                        {/* Week number */}
                        {showWeekNumber(
                          index,
                          day.week,
                          prevArray,
                          day.date
                        ) && <div className='week_number'>{day.week}</div>}

                        {/* Header */}
                        <div className='calendar_header'>
                          {getWeekday(day.date)}
                        </div>

                        {/* Jobs list */}
                        {day.jobs.map((job: Job) => (
                          <div
                            className={
                              job.closedJob.closed
                                ? 'calendar_job__closed'
                                : 'calendar_job'
                            }
                            key={job._id}
                            draggable
                            onDragStart={() => setDragJobId(job._id)}
                            onClick={() => openPreview(job)}
                            style={{
                              borderBottom: `4px solid ${getBorderColorByAgent(
                                job.projectManager?.uid
                              )}`
                            }}
                          >
                            {/* Title */}
                            <div className='title'>{job.title}</div>

                            <div className='info'>
                              <div>ID {job.jobId}</div>

                              <div>
                                {job.options.publicJob
                                  ? t('published')
                                  : t('not_published')}
                              </div>

                              <div>
                                {t('applicators')} {job.candidates}
                              </div>
                            </div>

                            {/* Assigned agent */}
                            <div className='assigned'>
                              {job.adminsOnPanel.map(
                                (admin: SelectedAgencyUser) => (
                                  <span key={admin.uid}>
                                    {admin.firstName} {admin.lastName}
                                  </span>
                                )
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>

          <Navigation
            nextHandler={() => navigate('next')}
            backHandler={() => navigate('prev')}
            period={(value) => handlePeriodSelection(value)}
            dateHandler={handleYearMonthChange}
            currentDate={currentStartPeriodDate}
            resetHandler={() => resetCalenderToToday()}
          />
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  agencyData: state.agencyDataRed.obj,
  agencyUserData: state.agencyUserDataRed.obj
})

export default connect(mapStateToProps)(Jobs)
