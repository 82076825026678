import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  Button,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Snackbar,
  Alert
} from '@mui/material'
import { useEffect, useState } from 'react'
import './Preview.scss'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Management from './Management'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import Loader from '../../../../Components/Loader'

const db = firebase.firestore()

interface Props {
  open: boolean
  onClose: (value: boolean) => void
  job: Job | null
  userRole: UserRole
  openPrieviewJobDialog: (job: Job | null) => void
  closeJob: (id: string) => void
  openJob: (id: string) => void
  editJob: (job: Job | null) => void
  agencyData: any
  agencyUserData: any
  onRefresh: () => void
  agencyAgents: AgencyUserData[]
}

const Preview = ({
  open,
  onClose,
  job,
  userRole,
  openPrieviewJobDialog,
  closeJob,
  editJob,
  agencyData,
  agencyUserData,
  onRefresh,
  openJob,
  agencyAgents
}: Props) => {
  const { t } = useTranslation()

  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [openManegement, setOpenManegement] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>({
    status: false,
    duration: 5000,
    severity: 'success',
    message: ''
  })

  useEffect(() => {
    let shouldUpdate = true

    if (shouldUpdate) {
      setOpenDrawer(open)
    }

    return () => {
      shouldUpdate = false
    }
  }, [open])

  const closePreview = () => {
    setOpenDrawer(false)
    onClose(false)
  }

  const getContractPeriodName = (
    duration: number | undefined,
    period: ContractPeriodTypes | undefined
  ) => {
    let durationNumber: number = 0
    let periodName: string = ''

    if (period !== undefined && duration !== undefined) {
      durationNumber = duration

      if (period === 'days') {
        periodName = t('day')
        if (duration > 1) {
          periodName = t('days')
        }
      }

      if (period === 'week') {
        periodName = t('week')

        if (duration > 1) {
          periodName = t('weeks')
        }
      }

      if (period === 'month') {
        periodName = t('month')

        if (duration > 1) {
          periodName = t('months')
        }
      }

      periodName = periodName.toLocaleLowerCase()
    }

    return `${durationNumber} ${periodName}`
  }

  const getJobCreateDate = (timestamp: any) => {
    if (job !== null) {
      const newDate: Date = new Date(timestamp.seconds * 1000)

      const convertedDate: string = moment(newDate).format('YYYY-MM-DD')

      return convertedDate
    }
    return ''
  }

  const dublicateJob = async (job: Job | null) => {
    if (job !== null) {
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .get()
        .then(async (snap) => {
          const lastJobId = snap.data()?.data.lastJobId
          const jobId = lastJobId !== undefined ? lastJobId + 1 : 1
          const id: string = uuid()
          const positionStartDate = moment().format('YYYY-MM-DD')

          const jobData: Job = {
            _id: id,
            jobId,
            candidates: 0,
            title: `${job.title} - COPY`,
            deadline: job.deadline,
            jobRequirements: job.jobRequirements,
            jobDescription: job.jobDescription,
            country: job.country,
            remoteJob: job.remoteJob,
            employmentTerm: job.employmentTerm,
            employmentType: job.employmentType,
            contractDuration: job.contractDuration,
            contractPeriod: job.contractPeriod,
            salary: {
              showSalary: job.salary.showSalary,
              currency: job.salary.currency,
              salaryMin: job.salary.salaryMin,
              salaryMax: job.salary.salaryMax,
              salaryRate: job.salary.salaryRate
            },
            publishFrom: job.publishFrom,
            publishUntil: job.publishUntil,
            candidateQuantity: job.candidateQuantity,
            projectType: job.projectType,
            projectManager: job.projectManager,
            adminsOnPanel: job.adminsOnPanel,
            phoneNumber: job.phoneNumber,
            email: job.email,
            options: {
              activeJob: false,
              publicJob: job.options.publicJob,
              allowApplyRegisteredUsers: job.options.allowApplyRegisteredUsers,
              allowUploadFiles: job.options.allowUploadFiles
            },
            timestamp: new Date(),
            seenAd: 0,
            closedJob: {
              closed: false,
              date: ''
            },
            positionStartDate
          }

          await db
            .collection('agencies')
            .doc(agencyData.id)
            .collection('jobs')
            .doc(id)
            .set({
              data: jobData
            })
            .then(() => {
              /** Update jobs ID number */
              db.collection('agencies')
                .doc(agencyData.id)
                .update({ 'data.lastJobId': jobId })
                .catch((error) => console.error(error))

              onRefresh()
              closePreview()
              setLoading(false)
              setSnackbarMessage({
                status: true,
                duration: 5000,
                severity: 'success',
                message: t('successfully_added')
              })
            })
            .catch((error) => {
              console.error(error)
              setSnackbarMessage({
                status: true,
                duration: 5000,
                severity: 'error',
                message: t('system_error_try_again_asd54')
              })
            })
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'error',
            message: t('system_error_try_again_asd54')
          })
        })
    }
  }

  return (
    <>
      {loading && <Loader />}

      {/* Messeges */}
      <Snackbar
        open={snackbarMessage.status}
        autoHideDuration={snackbarMessage.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() =>
          setSnackbarMessage({ ...snackbarMessage, status: false })
        }
      >
        <Alert
          severity={snackbarMessage.severity}
          onClose={() =>
            setSnackbarMessage({ ...snackbarMessage, status: false })
          }
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>

      <Management
        open={openManegement}
        job={job}
        onClose={() => setOpenManegement(false)}
        agencyAgentsList={agencyAgents}
      />

      <Drawer anchor='right' open={openDrawer} onClose={() => closePreview()}>
        {/* Job content */}
        <div className='preview'>
          {/* Close */}
          <div>
            <div className='close' onClick={() => closePreview()}>
              <FontAwesomeIcon icon={faTimes as IconProp} />
            </div>
          </div>
          <h2>{job?.title}</h2>

          {/* Ad statuses */}
          <div className='status'>
            {/* ID */}
            <div>
              ID
              <span>{job?.jobId}</span>
            </div>

            {/* Status */}
            <div>
              {job?.options.publicJob ? t('published') : t('not_published')}
            </div>

            {/* Seen */}
            <div>
              {t('seen')}
              <span>{job?.seenAd}</span>
            </div>

            {/* Applicators */}
            <div>
              {t('applicators')} <span>{job?.candidates}</span>
            </div>
          </div>

          <Table size='small' style={{ margin: '20px 0' }}>
            <TableBody>
              {/* Contract duration */}
              <TableRow>
                <TableCell>{t('contract_duration')}</TableCell>
                <TableCell>
                  {getContractPeriodName(
                    job?.contractDuration,
                    job?.contractPeriod
                  )}
                </TableCell>
              </TableRow>

              {/* Candidate quantity */}
              <TableRow>
                <TableCell>{t('candidate_quantity')}</TableCell>
                <TableCell>{job?.candidateQuantity}</TableCell>
              </TableRow>

              {/* Job created */}
              <TableRow>
                <TableCell>{t('job_created')}</TableCell>
                <TableCell>{getJobCreateDate(job?.timestamp)}</TableCell>
              </TableRow>

              {/* Closed job */}
              {job !== null && job.closedJob.closed && (
                <TableRow>
                  <TableCell>{t('job_closed')}</TableCell>
                  <TableCell>{job.closedJob.date}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          {/* Buttons */}
          <div className='buttons'>
            {/* Preview job */}
            <Button
              style={{ margin: '10px' }}
              size='small'
              variant='outlined'
              onClick={() => openPrieviewJobDialog(job)}
            >
              {t('preview_1235')}
            </Button>

            {/* Manage candidates */}
            <Button
              style={{ margin: '10px' }}
              size='small'
              variant='outlined'
              onClick={() => setOpenManegement(true)}
            >
              {t('manage_1234')}
            </Button>

            {/* Admin edit job button */}
            {(userRole === 'agency_admin' || userRole === 'super_admin') && (
              <Button
                style={{ margin: '10px' }}
                size='small'
                variant='outlined'
                onClick={() => editJob(job)}
              >
                {t('edit')}
              </Button>
            )}

            {/* Admin dublicate job button */}
            {(userRole === 'agency_admin' || userRole === 'super_admin') && (
              <Button
                style={{ margin: '10px' }}
                size='small'
                variant='outlined'
                onClick={() => dublicateJob(job)}
              >
                {t('dublicate')}
              </Button>
            )}

            {/* Admin close and open job buttons */}
            {(userRole === 'agency_admin' || userRole === 'super_admin') && (
              <div>
                {/* Close job */}
                {!job?.closedJob.closed && (
                  <Button
                    style={{ margin: '10px' }}
                    size='small'
                    variant='outlined'
                    color='error'
                    onClick={() => closeJob(job?._id as string)}
                  >
                    {t('close')}
                  </Button>
                )}

                {/* Open job */}
                {job?.closedJob.closed && (
                  <Button
                    style={{ margin: '10px' }}
                    size='small'
                    variant='outlined'
                    color='error'
                    onClick={() => openJob(job?._id as string)}
                  >
                    {t('open')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  agencyData: state.agencyDataRed.obj,
  agencyUserData: state.agencyUserDataRed.obj
})

export default connect(mapStateToProps)(Preview)
