import { useEffect, useState } from 'react'
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Card,
  CardContent,
  CardHeader,
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { systemColors } from '../../../../globalVariables'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import { refreshData } from '../../../../redux/actions/refreshData'
import ComponentLoader from '../../../../Components/ComponentLoader'

const db = firebase.firestore()

const useStyles = makeStyles({
  card: {
    width: 'fit-content',
    maxWidth: 500,
    height: '100%'
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: systemColors.darkBlue
  },
  cardHeader: {
    backgroundColor: systemColors.background,
    border: '1px solid #d6d6d6',
    borderBottom: `2px solid ${systemColors.orange}`
  },
  removeCardIcon: {
    padding: '10px 6px 0 50px',
    cursor: 'pointer',
    color: systemColors.grey,
    '&:hover': {
      color: systemColors.darkGrey
    }
  },
  tableHeader: {
    fontWeight: 600
  }
})

const TaggedProfiles = ({
  taggedProfiles,
  agencyData,
  userUid,
  refreshData,
  setRefreshData
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  // Sort tagged employees by adding time
  taggedProfiles.sort((a, b) => {
    return b.timestamp.seconds - a.timestamp.seconds
  })

  const [loading, setLoading] = useState(true)

  const [message, setMessage] = useState({
    status: false,
    duration: 3000,
    severity: 'success',
    message: ''
  })

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const [positionsList, setPositionsList] = useState([])

  useEffect(() => {
    let shouldUpdate = true

    ;(async () => {
      await db
        .collection('lists')
        .doc('positions')
        .get()
        .then((response) => {
          let data = JSON.stringify(response.data())
          data = JSON.parse(data)

          if (shouldUpdate) {
            setPositionsList(data.list)
            setLoading(false)
          }
        })
        .catch((error) => console.log(error))
    })()

    return () => {
      shouldUpdate = false
    }
  }, [])

  const removeBlockFromDashboard = async () => {
    await db
      .collection('users')
      .doc(userUid)
      .update({
        'data.settings.userSettings.dashboard.allowTaggedProfilesCREW': false
      })
      .then(() => {
        setRefreshData(!refreshData)
      })
      .catch((error) => {
        console.log("Can't update users settings", error)
        setMessage({
          status: true,
          duration: 5000,
          severity: 'warning',
          message: t('thereIsProblemWithRemoving1644648')
        })
      })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getPositionName = (id) => {
    const position = positionsList.find((el) => el.id === id)

    return position.name
  }

  return (
    <div style={{ height: '100%' }}>
      {/* warnings */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      <Card classes={{ root: classes.card }}>
        <CardHeader
          classes={{ root: classes.cardHeader, title: classes.title }}
          title={t('taggedCrewMembers')}
          action={
            <Tooltip title={t('removeItemFromDashboard1354')} placement='top'>
              <div
                className={classes.removeCardIcon}
                onClick={() => removeBlockFromDashboard()}
              >
                <FontAwesomeIcon icon={faMinusSquare} />
              </div>
            </Tooltip>
          }
        />
        <CardContent style={{ position: 'relative', height: '100%' }}>
          {loading ? (
            <ComponentLoader />
          ) : (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      {t('name')}
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      {t('nationality')}
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      {t('mainPosition')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {taggedProfiles.length > 0 ? (
                    taggedProfiles
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <TableRow
                          key={item.docId}
                          hover
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            history.push(
                              `/${agencyData.id}/crew-dashboard/employee-view/${item.docId}`
                            )
                          }}
                        >
                          {/* Name */}
                          <TableCell className='global__capitalizeFirstLetter'>
                            {item.firstName} {item.lastName}
                          </TableCell>

                          {/* Nationality */}
                          <TableCell className='global__capitalizeFirstLetter'>
                            {item.nationality}
                          </TableCell>

                          {/* Main position */}
                          <TableCell>
                            {getPositionName(item.mainPosition)}
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        style={{
                          textAlign: 'center',
                          color: systemColors.lighGrey
                        }}
                      >
                        {t('thereIsNoTaggedEmployees')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component='div'
                count={taggedProfiles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('rowsPerPage')}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${t('of')} ${
                    count !== -1 ? count : t('moreThanTo') + to
                  }`
                }
                nextIconButtonProps={{
                  'aria-label': t('next'),
                  title: t('next')
                }}
                backIconButtonProps={{
                  'aria-label': t('back'),
                  title: t('back')
                }}
              />
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  refreshData: state.refreshDataRed.bool
})

const dispatchStateToProps = (dispatch) => ({
  setRefreshData: (bool) => dispatch(refreshData(bool))
})

export default connect(mapStateToProps, dispatchStateToProps)(TaggedProfiles)
