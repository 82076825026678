import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'
import AgencyMenu from '../../Components/AgencyMenu'
import Headline from '../../Components/Headline'
import Loader from '../../Components/Loader'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import {
  Grid,
  MenuItem,
  TextField,
  Snackbar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  systemColors,
  systemStylingSettings,
  agencyTypes
} from '../../globalVariables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

const db = firebase.firestore()

const useStyle = makeStyles({
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  selectLabel: {
    marginTop: '-2px'
  },
  tableHeader: {
    color: systemColors.darkBlue,
    fontSize: 16
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  buttonText: {
    color: '#ffffff !important'
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonRoot: {
    color: '#ffffff !important'
  }
})

const AddAgency = ({ agencyData }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const userUID = firebase.auth().currentUser.uid
  const classes = useStyle()

  // Change document title
  document.title = `${t('addAgency')} - Seanor`

  const [loading, setLoading] = useState(false)
  const [refreshData, setRefreshData] = useState(false)
  const [loadedData, setLoadedData] = useState(false)

  const [agenciesList, setAgenciesList] = useState([])

  const [userRole, setUserRole] = useState(null)
  const [agencyName, setAgencyName] = useState('')
  const [agencyAddress, setAgencyAddress] = useState('')
  const [agencyType, setAgencyType] = useState('')
  const [addAgencySuccess, setAddAgencySuccess] = useState(false)

  // ERROR STATES
  const [mustFillFields, setMustFillFields] = useState(false)
  const [agencyNameError, setAgencyNameError] = useState(false)
  const [agencyAddressError, setAgencyAddressError] = useState(false)
  const [agencyTypeError, setAgencyTypeError] = useState(false)

  const [message, setMessage] = useState({
    status: false,
    duration: 1500,
    severity: 'success',
    message: ''
  })

  const [showEditCompanyBlock, setShowEditCompanyBlock] = useState(false)

  const [editAgencyNameTitle, setEditAgencyNameTitle] = useState('')
  const [editAgencyName, setEditAgencyName] = useState('')
  const [editAgencyAddress, setEditAgencyAddress] = useState('')
  const [editAgencyType, setEditAgencyType] = useState('')
  const [editAgencyId, setEditAgencyId] = useState('')

  // Error states
  const [editAgencyNameError, setEditAgencyNameError] = useState(false)
  const [editAgencyAddressError, setEditAgencyAddressError] = useState(false)

  const [showDeleteAgencyWarningBlock, setShowDeleteAgencyWarningBlock] =
    useState(false)

  useEffect(() => {
    const getUserData = () => {
      const getUserRole = new Promise((resolve) => {
        db.collection('users')
          .doc(userUID)
          .get()
          .then((res) => {
            let data = JSON.stringify(res.data())
            data = JSON.parse(data)
            setUserRole(data.data.userRole)
            resolve()
          })
          .catch((error) => {
            console.log("Can't get user role", error)
            resolve()
          })
      })

      const getAGenciesList = new Promise((resolve) => {
        db.collection('agencies')
          .get()
          .then((snap) => {
            let agenciesArray = []

            snap.forEach((el) => {
              let data = JSON.stringify(el.data())
              data = JSON.parse(data)
              agenciesArray = [...agenciesArray, data.data]
            })
            setAgenciesList(agenciesArray)
            resolve()
          })
          .catch((error) => {
            console.log("Can't get agencies list", error)
            resolve()
          })
      })

      Promise.all([getUserRole, getAGenciesList]).then(() => {
        setLoadedData(true)
      })
    }
    return getUserData()
  }, [userUID, refreshData])

  const addAgencyToDatabase = () => {
    if (agencyName !== '' && agencyAddress !== '' && agencyType !== '') {
      setLoading(true)
      let agencyId = agencyName.replace(/ /g, '')
      agencyId = agencyId.toLowerCase()
      agencyId = agencyId.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

      db.collection('agencies')
        .doc(agencyId)
        .set({
          data: {
            address: agencyAddress,
            id: agencyId,
            name: agencyName,
            lastJobId: 0,
            settings: {
              companySettings: {
                applicationStages: [],
                candidateCriteria: [],
                currencies: ['eur', 'usd']
              },
              globalPermitionsToAgents: {
                allowAgentsToSeeCompanyStatistics: false
              }
            },
            type: agencyType
          }
        })
        .then(() => {
          setLoading(false)
          setAddAgencySuccess(true)
          setAgencyName('')
          setAgencyAddress('')
          setAgencyType('')
          setMustFillFields(false)
          setAgencyNameError(false)
          setAgencyAddressError(false)
          setAgencyTypeError(false)
          setRefreshData(!refreshData)
          setMessage({
            status: true,
            duration: 1500,
            severity: 'success',
            message: t('newAgencyAdded')
          })
        })
        .catch((err) => {
          setLoading(false)
          console.log("Can't save agency", err)
        })
    } else {
      setMustFillFields(true)
      if (agencyName === '') {
        setAgencyNameError(true)
      } else {
        setAgencyNameError(false)
      }
      if (agencyAddress === '') {
        setAgencyAddressError(true)
      } else {
        setAgencyAddressError(false)
      }
      if (agencyType === '') {
        setAgencyTypeError(true)
      } else {
        setAgencyTypeError(false)
      }
    }
  }

  const updateAgency = async () => {
    if (editAgencyName !== '' && editAgencyAddress !== '') {
      setLoading(true)
      await db
        .collection('agencies')
        .doc(editAgencyId)
        .update({
          'data.name': editAgencyName,
          'data.address': editAgencyAddress
        })
        .then(() => {
          setEditAgencyNameError(false)
          setEditAgencyAddressError(false)
          setShowEditCompanyBlock(false)
          setEditAgencyNameTitle('')
          setEditAgencyName('')
          setEditAgencyAddress('')
          setEditAgencyType('')
          setEditAgencyId('')
          setRefreshData(!refreshData)
          setLoading(false)
          setMessage({
            status: true,
            duration: 1500,
            severity: 'success',
            message: t('agencyUpdated')
          })
        })
        .catch((error) => {
          console.log("Can't update agency data", error)
          setLoading(false)
        })
    } else {
      if (editAgencyName === '') {
        setEditAgencyNameError(true)
      } else {
        setEditAgencyNameError(false)
      }
      if (editAgencyAddress === '') {
        setEditAgencyAddressError(true)
      } else {
        setEditAgencyAddressError(false)
      }
    }
  }

  const removeAgency = async () => {
    setLoading(true)
    setShowDeleteAgencyWarningBlock(false)
    await db
      .collection('agencies')
      .doc(editAgencyId)
      .delete()
      .then(() => {
        setEditAgencyNameError(false)
        setEditAgencyAddressError(false)
        setShowEditCompanyBlock(false)
        setEditAgencyNameTitle('')
        setEditAgencyName('')
        setEditAgencyAddress('')
        setEditAgencyType('')
        setEditAgencyId('')
        setRefreshData(!refreshData)
        setLoading(false)
        setMessage({
          status: true,
          duration: 1500,
          severity: 'success',
          message: t('agencyremoved')
        })
      })
      .catch((error) => {
        console.log("Can't remove agency", error)
        setLoading(false)
      })
  }

  if (!loadedData) {
    return <Loader />
  }

  if (userRole === 'super_admin') {
    return (
      <>
        {loading && <Loader />}

        <AgencyMenu type={location.state.menuType} />

        <Headline text={t('addAgency')} />

        {/* Empty fields error */}
        <Snackbar
          open={mustFillFields}
          autoHideDuration={2000}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={() => setMustFillFields(false)}
        >
          <Alert severity='warning' onClose={() => setMustFillFields(false)}>
            {t('mustFillAllFields')}
          </Alert>
        </Snackbar>

        <Snackbar
          open={message.status}
          autoHideDuration={message.duration}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={() => setMessage({ ...message, status: false })}
        >
          <Alert
            severity={message.severity}
            onClose={() => setMessage({ ...message, status: false })}
          >
            {message.message}
          </Alert>
        </Snackbar>

        {/* Succes of agency adding */}
        <Snackbar
          open={addAgencySuccess}
          autoHideDuration={2000}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={() => setAddAgencySuccess(false)}
        >
          <Alert severity='success' onClose={() => setAddAgencySuccess(false)}>
            {t('agencyAddedSuccessfully')}
          </Alert>
        </Snackbar>

        {/* Edit agency */}
        <Dialog
          open={showEditCompanyBlock}
          classes={{ paper: classes.dialogRoot }}
          fullWidth
          maxWidth='md'
        >
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {t('edit')} {editAgencyNameTitle}
          </DialogTitle>

          <DialogContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 10
              }}
            >
              <div
                style={{
                  color: systemColors.errorColor,
                  fontSize: 12,
                  cursor: 'pointer'
                }}
                onClick={() => setShowDeleteAgencyWarningBlock(true)}
              >
                {t('removeAgency')}
              </div>
            </div>

            <Grid container spacing={2} style={{ marginTop: 25 }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('agencyName')}
                  value={editAgencyName}
                  onChange={(e) => setEditAgencyName(e.target.value)}
                  fullWidth
                  error={editAgencyNameError}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('agencyAddress')}
                  value={editAgencyAddress}
                  onChange={(e) => setEditAgencyAddress(e.target.value)}
                  fullWidth
                  error={editAgencyAddressError}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  select
                  disabled
                  variant='outlined'
                  fullWidth
                  label={t('agencyType')}
                  size='small'
                  value={editAgencyType}
                  onChange={(e) => setEditAgencyType(e.target.value)}
                >
                  {agencyTypes.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  disabled
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('url')}
                  value={`https://e.seanor.eu/${editAgencyId}`}
                  fullWidth
                />
              </Grid>
            </Grid>

            <div
              style={{
                marginTop: 25,
                color: systemColors.lighGrey,
                fontSize: 14
              }}
            >
              {t('noteIfYouChangeAgencyNameAgencyUrlWontChange')}
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              variant='contained'
              size='small'
              classes={{
                endIcon: classes.buttonEndIcon,
                root: classes.buttonRoot,
                contained: classes.buttonContained
              }}
              onClick={() => updateAgency()}
            >
              {t('update')}
            </Button>
            <Button
              onClick={() => {
                setShowEditCompanyBlock(false)
                setEditAgencyNameTitle('')
                setEditAgencyName('')
                setEditAgencyAddress('')
                setEditAgencyType('')
                setEditAgencyId('')
              }}
            >
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete agency warning message */}
        <Dialog
          open={showDeleteAgencyWarningBlock}
          classes={{ paper: classes.dialogRoot }}
        >
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {t('areYouShure')}
          </DialogTitle>
          <DialogContent>
            <div style={{ margin: '20px 0' }}>
              {t('removeAgencyFromSystem')}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => removeAgency()}>{t('removeAgency')}</Button>
            <Button
              onClick={() => {
                setShowDeleteAgencyWarningBlock(false)
              }}
            >
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container spacing={2} style={{ margin: '50px 0' }}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              size='small'
              variant='outlined'
              type='text'
              label={t('agencyName')}
              value={agencyName}
              onChange={(e) => setAgencyName(e.target.value)}
              fullWidth
              error={agencyNameError}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              size='small'
              variant='outlined'
              type='text'
              label={t('agencyAddress')}
              value={agencyAddress}
              onChange={(e) => setAgencyAddress(e.target.value)}
              fullWidth
              error={agencyAddressError}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              required
              variant='outlined'
              fullWidth
              label={t('agencyType')}
              size='small'
              value={agencyType}
              onChange={(e) => setAgencyType(e.target.value)}
              error={agencyTypeError}
            >
              {agencyTypes.map((el, i) => (
                <MenuItem key={i} value={el}>
                  {el}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Add agency button */}
          <Grid item>
            <Button
              variant='contained'
              size='small'
              classes={{
                endIcon: classes.buttonEndIcon,
                root: classes.buttonRoot,
                contained: classes.buttonContained
              }}
              onClick={() => addAgencyToDatabase()}
            >
              {t('addAgency')}
            </Button>
          </Grid>
        </Grid>

        {/* List of agencies */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader} />
              <TableCell className={classes.tableHeader}>
                {t('agencyName')}
              </TableCell>
              <TableCell className={classes.tableHeader}>{t('type')}</TableCell>
              <TableCell className={classes.tableHeader}>
                {t('address')}
              </TableCell>
              <TableCell className={classes.tableHeader}>{t('url')}</TableCell>
              <TableCell className={classes.tableHeader} />
            </TableRow>
          </TableHead>
          <TableBody>
            {agenciesList.map((el, i) => (
              <TableRow key={el.id} hover>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{el.name}</TableCell>
                <TableCell>{el.type}</TableCell>
                <TableCell>{el.address}</TableCell>
                <TableCell>{`https://e.seanor.eu/${el.id}`}</TableCell>
                <TableCell>
                  <Tooltip title={t('edit')} placement='top'>
                    <div
                      className='getProjectsEmployeesList__editButton'
                      onClick={() => {
                        setEditAgencyNameTitle(el.name)
                        setEditAgencyName(el.name)
                        setEditAgencyAddress(el.address)
                        setEditAgencyType(el.type)
                        setEditAgencyId(el.id)
                        setShowEditCompanyBlock(true)
                      }}
                    >
                      <FontAwesomeIcon style={{ fontSize: 12 }} icon={faEdit} />
                    </div>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    )
  } else {
    return <Redirect to={`/${agencyData.id}`} />
  }
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj
  }
}

export default connect(mapStateToProps)(AddAgency)
