const initialState = {
  arr: []
}

const employeesCounterRed = (state = initialState, action) => {
  switch (action.type) {
    case 'EMPLOYEES_COUNTER' : {
      return {
        ...state,
        arr: action.arr
      }
    }
    default: {
      return state
    }
  }
}

export default employeesCounterRed
