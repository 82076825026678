import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Snackbar,
  Alert
} from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import './CandidateManagement.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLink,
  faPen,
  faTrash,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import Hire from './Hire'
import Task from './Task'
import Loader from '../../../../Components/Loader'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

const db = firebase.firestore()

interface Props {
  open: boolean
  onClose: () => void
  candidate: RecruitmentCandidate | null
  profilePhotoUrl: string
  agencyData: AgencyData
  agencyUserData: AgencyUserData
  handleRefreshData: () => void
  adminsOnPanel: SelectedAgencyUser[]
  agencyAgents: AgencyUserData[]
}

const CandidateManagement = ({
  open,
  onClose,
  candidate,
  profilePhotoUrl,
  agencyData,
  agencyUserData,
  handleRefreshData,
  adminsOnPanel,
  agencyAgents
}: Props) => {
  const { t } = useTranslation()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const applicationActionsArray: RecruitmentCandidateActionStatus[] = [
    'applied',
    'inReview',
    'phoneScreen',
    'interview',
    'assessment',
    'backgroudCheck',
    'flagged',
    'disqualified',
    'offered',
    'hired'
  ]

  const [loading, setLoading] = useState<boolean>(false)

  const [selectedApplicationAction, setSelectedApplicationAction] = useState<
    RecruitmentCandidateActionStatus | ''
  >('')

  const [evaluations, setEvaluations] = useState<RecruitmentEvaluation[]>([])

  const [applicationStageList, setApplicationStageList] = useState<
    RecruitmentApplicationStages[]
  >([])
  const [selectedApplicationStage, setSelectedApplicationStage] =
    useState<string>('')

  const [tasksList, setTasksList] = useState<Task[]>([])

  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>({
    status: false,
    duration: 5000,
    severity: 'success',
    message: ''
  })

  const [openHire, setOpenHire] = useState<boolean>(false)

  const [showNewTaskBlock, setShowNewTaskBlock] = useState<boolean>(false)

  const [selectedTask, setSelectedTask] = useState<Task | null>(null)
  const [selectedTaskEditMode, setSelectedTaskEditMode] =
    useState<boolean>(false)

  const [selectedEvaluationAgentId, setSelectedEvaluationAgentId] =
    useState<string>('')

  const getTasks = useCallback(async () => {
    if (candidate !== null) {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('tasks')
        .where('candidateProfileId', '==', candidate.profileId)
        .where('jobId', '==', candidate.jobId)
        .orderBy('date', 'asc')
        .get()
        .then((response) => {
          let tasksArray: Task[] = []

          response.forEach((task) => {
            let data: any = JSON.stringify(task.data())
            data = JSON.parse(data)

            tasksArray = [...tasksArray, data]
          })

          setTasksList(tasksArray)
        })
        .catch((error) => console.error(error))
    }
  }, [agencyData, candidate])

  useEffect(() => {
    let shouldUpdate = true

    getTasks()

    if (shouldUpdate) {
      setOpenDialog(open)
      setSelectedApplicationAction(
        candidate !== null ? candidate.actionStatus : ''
      )
      setSelectedApplicationStage(
        candidate !== null ? candidate.stageStatus : ''
      )
    }

    /** Add current agent to evaluation top */
    if (candidate !== null) {
      let newEvaluations: RecruitmentEvaluation[] = []
      const agentId: string = agencyUserData.uid
      const currentEvaluations: RecruitmentEvaluation[] = candidate.evaluations

      /** Find current agents data */
      const agentIndex: number = candidate.evaluations.findIndex(
        (el) => el.agentUid === agentId
      )

      if (agentIndex > -1) {
        newEvaluations = [...newEvaluations, candidate.evaluations[agentIndex]]
      }

      /** Add others data next */
      const withoutAgentArray: RecruitmentEvaluation[] =
        currentEvaluations.filter((el) => el.agentUid !== agentId)

      newEvaluations = [...newEvaluations, ...withoutAgentArray]

      if (shouldUpdate) {
        /** Update evaluation state */
        setEvaluations(newEvaluations)
        if (newEvaluations.length > 0) {
          setSelectedEvaluationAgentId(newEvaluations[0].agentUid)
        }

        /** Update application statges state */
        if (
          agencyData.settings.companySettings !== undefined &&
          agencyData.settings.companySettings.applicationStages
        ) {
          setApplicationStageList(
            agencyData.settings.companySettings.applicationStages
          )
        }

        /** Update selected application stage state */
        setSelectedApplicationStage(candidate.stageStatus)
      }
    }

    return () => {
      shouldUpdate = false
    }
  }, [open, candidate, agencyUserData, getTasks, agencyData])

  const closeDialog = () => {
    onClose()
  }

  const updateApplicationAction = async (
    value: RecruitmentCandidateActionStatus
  ) => {
    if (candidate !== null) {
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('jobs_candidates')
        .doc(candidate._id)
        .update({
          actionStatus: value
        })
        .then(() => {
          handleRefreshData()
          setSelectedApplicationAction(value)

          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('candidate_info_was_updated')
          })
        })
        .catch((error) => {
          console.error(error)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'error',
            message: t('system_error_try_again_asd54')
          })
        })
    }
  }

  const uppdateApplicationState = async (value: string) => {
    if (candidate !== null) {
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('jobs_candidates')
        .doc(candidate._id)
        .update({
          stageStatus: value
        })
        .then(() => {
          handleRefreshData()
          setSelectedApplicationStage(value)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('candidate_info_was_updated')
          })
        })
        .catch((error) => {
          console.error(error)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'error',
            message: t('system_error_try_again_asd54')
          })
        })
    }
  }

  const closeEvaluation = async () => {
    if (candidate !== null) {
      let value = candidate.closedEvaluation

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('jobs_candidates')
        .doc(candidate._id)
        .update({
          closedEvaluation: !value,
          closeEvaluationDate: moment().format('YYYY-MM-DD')
        })
        .then(() => {
          handleRefreshData()
          closeDialog()
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('candidate_info_was_updated')
          })
        })
        .catch((error) => {
          console.error(error)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'error',
            message: t('system_error_try_again_asd54')
          })
        })
    }
  }

  const openNewTaskBlock = () => {
    setShowNewTaskBlock(true)
  }

  const closeNewTaskBlock = () => {
    setShowNewTaskBlock(false)
    setSelectedTask(null)
    setSelectedTaskEditMode(false)
  }

  const editTask = (task: Task) => {
    setSelectedTask(task)
    setSelectedTaskEditMode(true)
    openNewTaskBlock()
  }

  const removeTask = async (id: string) => {
    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('tasks')
      .doc(id)
      .delete()
      .then(() => getTasks())
      .catch((error) => console.error(error))
  }

  const isCurrentAgent = (id: string) => {
    if (id === agencyUserData.uid) {
      return true
    }

    return false
  }

  const isSelectedAgent = (id: string) => {
    if (selectedEvaluationAgentId === id) {
      return true
    }

    return false
  }

  /** Used for evaluation input change */
  const handleEvaluationChange = (
    agentId: string,
    evaluationId: string,
    name: string,
    val: string | number | null
  ) => {
    const newInput: RecruitmentEvaluation[] = evaluations.map((agent) => {
      if (agentId === agent.agentUid) {
        if (name === 'comment') {
          agent.comment = val as string
        } else {
          agent.evaluation.map((evaluation) => {
            if (evaluationId === evaluation._id) {
              if (name === 'evaluation') {
                evaluation.evaluation = val as string
              }
              if (name === 'rating' && val !== null) {
                evaluation.rating = val as number
              }
            }
            return evaluation
          })
        }
        agent.lastEdit = moment().format('YYYY-MM-DD')
      }
      return agent
    })

    setEvaluations(newInput)
  }

  const saveEvaluation = async () => {
    const agentId: string = agencyUserData.uid
    const currentAgentsEvaluation: RecruitmentEvaluation = evaluations.filter(
      (el) => el.agentUid === agentId
    )[0]
    let rating: number = 0

    /** Get overall rating */
    let ratingsSum: number = 0
    let ratingsCount: number = 0

    currentAgentsEvaluation.evaluation.forEach((evaluation) => {
      if (evaluation.rating !== 0) {
        ratingsSum = ratingsSum + evaluation.rating
        ratingsCount = ratingsCount + 1
      }
    })

    rating = Math.round((ratingsSum / ratingsCount) * 100) / 100

    if (isNaN(rating)) {
      rating = 0
    }

    /** Update data */
    if (currentAgentsEvaluation.comment !== '' && candidate !== null) {
      setLoading(true)

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('jobs_candidates')
        .doc(candidate._id)
        .update({ evaluations, rating })
        .then(() => {
          setLoading(false)

          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('data_updated')
          })

          /**
           * Send notifications to agents who manage this position
           */
          if (adminsOnPanel.length > 0) {
            adminsOnPanel.forEach((manager) => {
              if (manager.uid !== agencyUserData.uid) {
                const id = uuid()
                const capitelize = (name: string) => {
                  let splitName: string[] = name.split(' ')
                  for (let i = 0; i < splitName.length; i++) {
                    splitName[i] =
                      splitName[i].charAt(0).toUpperCase() +
                      splitName[i].substring(1)
                  }
                  return splitName.join(' ')
                }

                const notification: SystemNotification = {
                  _id: id,
                  title: `New evaluation of ${capitelize(candidate.name)} in ${
                    candidate.jobTitle
                  } position`,
                  source: 'recruitment',
                  userUid: manager.uid,
                  readed: false,
                  timestamp: new Date()
                }

                /**
                 * Add notification to database
                 */
                db.collection('agencies')
                  .doc(agencyData.id)
                  .collection('notifications')
                  .doc(id)
                  .set({ data: notification })
                  .catch((error) => console.error(error))
              }
            })
          }
        })
        .catch((error) => console.error(error))
    } else {
      setSnackbarMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('comment_is_required')
      })
    }
  }

  return (
    <>
      {loading && <Loader />}
      {/* Messeges */}
      <Snackbar
        open={snackbarMessage.status}
        autoHideDuration={snackbarMessage.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() =>
          setSnackbarMessage({ ...snackbarMessage, status: false })
        }
      >
        <Alert
          severity={snackbarMessage.severity}
          onClose={() =>
            setSnackbarMessage({ ...snackbarMessage, status: false })
          }
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>

      <Task
        open={showNewTaskBlock}
        onClose={() => closeNewTaskBlock()}
        candidate={candidate}
        refreshTasksList={() => getTasks()}
        editTask={selectedTask}
        editMode={selectedTaskEditMode}
        agencyAgents={agencyAgents}
      />

      <Hire
        open={openHire}
        onClose={() => setOpenHire(false)}
        candidate={candidate}
      />

      {/* MAIN */}
      <Dialog open={openDialog} onClose={() => closeDialog()} fullScreen>
        <DialogTitle style={{ textTransform: 'capitalize' }}>
          {t('manage_1234')} {candidate?.name}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={4} style={{ marginTop: 0 }}>
            {/* Candidate card */}
            <Grid item xs={12} md={6}>
              <Paper variant='outlined' style={{ padding: 20, height: '100%' }}>
                {/* Details */}
                <div style={{ display: 'flex' }}>
                  {/* Profile photo */}
                  <div>
                    <div className='profilePhoto'>
                      {profilePhotoUrl !== '' ? (
                        <img src={profilePhotoUrl} alt='profile' />
                      ) : (
                        <FontAwesomeIcon
                          className='icon'
                          icon={faUser as IconProp}
                        />
                      )}
                    </div>
                  </div>

                  {/* Candidate info */}
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: 20,
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    {/* Info block */}
                    <div>
                      {/* Name */}
                      <div
                        style={{
                          textTransform: 'capitalize',
                          cursor: 'pointer'
                        }}
                        onClick={() =>
                          window.open(
                            `/${agencyData.id}/key-dashboard/employee-view/${candidate?.profileId}`
                          )
                        }
                      >
                        {candidate?.name}

                        <FontAwesomeIcon
                          style={{ marginLeft: 5, fontSize: 13 }}
                          icon={faLink as IconProp}
                        />
                      </div>

                      {/* Positions */}
                      <Table size='small' style={{ margin: '20px 0' }}>
                        <TableBody>
                          {/* Main position */}
                          <TableRow>
                            <TableCell style={{ textAlign: 'right' }}>
                              <b>{t('mainPosition')}</b>
                            </TableCell>
                            <TableCell>
                              {t(candidate?.mainPosition as string)}
                            </TableCell>
                          </TableRow>

                          {/* Second position */}
                          <TableRow>
                            <TableCell>
                              <b>{t('secondPosition')}</b>
                            </TableCell>
                            <TableCell>
                              {t(candidate?.secondPosition as string)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>

                    {/* Rating and hire */}
                    <div style={{ width: 'fit-content' }}>
                      {/* Rating */}
                      <div>
                        <Tooltip
                          title={
                            candidate !== null
                              ? candidate.rating.toString()
                              : ''
                          }
                          placement='top'
                        >
                          <div>
                            <Rating
                              size='small'
                              value={candidate !== null ? candidate.rating : 0}
                              precision={0.5}
                              readOnly
                            />
                          </div>
                        </Tooltip>
                      </div>

                      {/* Hire button */}
                      <div style={{ marginTop: 30, float: 'right' }}>
                        <Button
                          size='small'
                          color='orange'
                          variant='contained'
                          onClick={() => setOpenHire(true)}
                        >
                          {t('hire')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Options */}
                <Grid container spacing={3} style={{ marginTop: 20 }}>
                  {/* Application actions */}
                  <Grid item xs={4}>
                    <TextField
                      size='small'
                      fullWidth
                      label={t('application_action')}
                      select
                      value={selectedApplicationAction}
                      onChange={(event) =>
                        updateApplicationAction(
                          event.target.value as RecruitmentCandidateActionStatus
                        )
                      }
                    >
                      {applicationActionsArray.map((action) => (
                        <MenuItem key={action} value={action}>
                          {t(action)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {/* Application stage */}
                  <Grid item xs={4}>
                    <TextField
                      size='small'
                      fullWidth
                      label={t('application_stage')}
                      select
                      value={selectedApplicationStage}
                      onChange={(event) =>
                        uppdateApplicationState(event.target.value as string)
                      }
                    >
                      {applicationStageList.map((stage) => (
                        <MenuItem key={stage._id} value={stage._id}>
                          {stage.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {/* Close evaluation button */}
                  <Grid item xs={4}>
                    <Button color='error' onClick={() => closeEvaluation()}>
                      {candidate?.closedEvaluation
                        ? t('continue_evaluation')
                        : t('close_evaluation')}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* Tasks */}
            <Grid item xs={12} md={6}>
              <Paper variant='outlined' style={{ padding: 20, height: '100%' }}>
                {/* New task button */}
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    size='small'
                    color='orange'
                    variant='contained'
                    onClick={() => openNewTaskBlock()}
                  >
                    {t('new_task')}
                  </Button>
                </div>

                {/* Tasks list */}
                <div style={{ marginTop: 20 }}>
                  {/* No tasks found */}
                  {tasksList.length === 0 && (
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: 14,
                        opacity: 0.6
                      }}
                    >
                      {t('there_is_no_tasks_with_this_candidate')}
                    </div>
                  )}

                  {/* Tasks list */}
                  {tasksList.length > 0 && (
                    <Table size='small'>
                      <TableBody>
                        {tasksList.map((task) => (
                          <TableRow key={task._id}>
                            {/* Title */}
                            <TableCell>
                              {task.title}

                              {/*
                               * Who created
                               */}
                              <div style={{ fontSize: 12, opacity: 0.8 }}>
                                {t('created_by')} {task.agentName}
                              </div>
                            </TableCell>

                            {/* Date */}
                            <TableCell>{task.date}</TableCell>

                            {/* Time */}
                            <TableCell>{task.time}</TableCell>

                            {/* Options */}
                            <TableCell>
                              <div className='task_icons'>
                                {/* Edit */}
                                <Tooltip title={t('edit')} placement='top'>
                                  <div
                                    className='task_icon'
                                    onClick={() => editTask(task)}
                                  >
                                    <FontAwesomeIcon icon={faPen as IconProp} />
                                  </div>
                                </Tooltip>

                                {/* Remove */}
                                {agencyUserData.uid === task.agentUid && (
                                  <Tooltip title={t('remove')} placement='top'>
                                    <div
                                      className='task_icon'
                                      onClick={() => removeTask(task._id)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash as IconProp}
                                      />
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>

          {/* Scorecards */}
          <Paper
            className='evaluation'
            variant='outlined'
            style={{
              padding: 20,
              position: 'relative',
              width: 'fit-content',
              margin: '50px auto'
            }}
          >
            {/* Agent */}
            <div>
              {evaluations.map((agent) => (
                <div key={agent.agentUid}>
                  <div
                    className={
                      isSelectedAgent(agent.agentUid)
                        ? 'agentBlock__active'
                        : 'agentBlock'
                    }
                    onClick={() => setSelectedEvaluationAgentId(agent.agentUid)}
                  >
                    {agent.agentName}

                    {/* Last edit date */}
                    <div>{agent.lastEdit}</div>
                  </div>
                </div>
              ))}
            </div>

            {/* Evaluation */}
            {evaluations.map((agent) => (
              <div
                key={agent.agentUid}
                className={
                  isSelectedAgent(agent.agentUid)
                    ? 'evaluationBlock__active'
                    : 'evaluationBlock'
                }
              >
                {/* Criteria */}
                {agent.evaluation.map((evaluation) => (
                  <div key={evaluation._id} className='evaluationContainer'>
                    {/* Title and rating */}
                    <div className='evaluationHead'>
                      {/* Title */}
                      <div>{evaluation.title}</div>

                      {/* Rating */}
                      <Rating
                        size='small'
                        disabled={!isCurrentAgent(agent.agentUid)}
                        value={evaluation.rating}
                        onChange={(event, value) =>
                          handleEvaluationChange(
                            agent.agentUid,
                            evaluation._id,
                            'rating',
                            value
                          )
                        }
                      />
                    </div>

                    {/* Evaluation */}
                    <div>
                      <TextField
                        disabled={!isCurrentAgent(agent.agentUid)}
                        fullWidth
                        multiline
                        rows={4}
                        size='small'
                        value={evaluation.evaluation}
                        onChange={(event) =>
                          handleEvaluationChange(
                            agent.agentUid,
                            evaluation._id,
                            'evaluation',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                ))}

                {/* Comment */}
                <div className='evaluationContainer'>
                  {/* Title */}
                  <div className='evaluationHead'>
                    <div>{t('comment')}</div>
                  </div>

                  {/* Input */}
                  <div>
                    <TextField
                      disabled={!isCurrentAgent(agent.agentUid)}
                      fullWidth
                      multiline
                      rows={4}
                      size='small'
                      value={agent.comment}
                      onChange={(event) =>
                        handleEvaluationChange(
                          agent.agentUid,
                          '',
                          'comment',
                          event.target.value
                        )
                      }
                    />
                  </div>
                </div>

                {/* Save button */}
                {isCurrentAgent(agent.agentUid) && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      size='small'
                      variant='contained'
                      color='orange'
                      onClick={() => saveEvaluation()}
                    >
                      {t('save')}
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </Paper>
        </DialogContent>

        <DialogActions>
          {/* Close button */}
          <Button size='small' onClick={() => closeDialog()}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    agencyData: state.agencyDataRed.obj,
    agencyUserData: state.agencyUserDataRed.obj
  }
}

export default connect(mapStateToProps)(CandidateManagement)
