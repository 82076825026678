import React, { useEffect, useState } from 'react'
import {
  Grid,
  Alert,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Snackbar,
  DialogActions,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  Checkbox
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useTranslation } from 'react-i18next'
import Loader from '../../Components/Loader'
import {
  systemColors,
  systemStylingSettings,
  maritalStatusArray,
  englishLevelArr,
  acceptedFileSize,
  height as heightArr,
  weight as weightArr,
  shoesSize as shoesSizeArr,
  clothesSize as clothesSizeArr,
  hairColor as hairColorArr,
  eyeColor as eyeColorArr
} from '../../globalVariables'
import firebase from 'firebase/app'
import { countries } from '../../countries'
import { randomNumber } from '../../globalFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faSyncAlt,
  faTimes,
  faTrash,
  faInfoCircle,
  faCopy
} from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { crewEmployeeConverter } from '../../converters/crewEmployeeConverter'
import crewEmployeeData from '../../classes/crewEmployeeData'
import { useHistory } from 'react-router-dom'
import Header from '../../Modules/Header'
import { vesselsList } from '../../variables/vesselsTypes'
import { v4 as uuid } from 'uuid'
import FileUploadLoader from '../../Components/FileUploadLoader/FileUploadLoader'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: systemColors.inputColor,
      width: '100%',
      borderRadius: systemStylingSettings.borderRadius,
      '&.Mui-focused fieldset': {
        color: systemColors.darkBlue,
        borderColor: systemColors.darkBlue,
        borderWidth: systemStylingSettings.borderWidth
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 8
      }
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  docGridContainer: {
    marginTop: 25,
    marginBottom: 25
  },
  profilePhotoMargin: {
    marginTop: 50
  },
  stepperRoot: {
    backgroundColor: systemColors.background,
    marginTop: 50
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  addDocumentButtonStartIcon: {
    color: systemColors.orange
  },
  addDocumentButtonOutlined: {
    color: systemColors.orange,
    borderColor: systemColors.orange
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonText: {
    color: '#ffffff !important'
  },
  checkbox: {
    color: systemColors.electric
  },
  selectLabelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius,
      backgroundColor: systemColors.inputColor
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    }
  }
})

const CrewEmployeeCreateCV = ({ agencyData, userUID, agencyUserData }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const reader = new FileReader()
  const history = useHistory()
  const browserInfo = navigator.userAgent

  const [loading, setLoading] = useState(false)
  const [selectedStep, setSelectedStep] = useState(0)

  // Change document title
  document.title = `${t('createCV')} - Seanor`

  const [savedData, setSavedData] = useState(false)
  const [documentId, setDocumentId] = useState('')

  // Database data states
  const [positionArr, setPositionArr] = useState([])
  const [nationalityArr, setNationalityArr] = useState([])
  const [certificatesArr, setCertificatesArr] = useState([])
  const [vesselTypesArr, setVesselTypesArr] = useState([])
  const [flagListArr, setFlagListArr] = useState([])
  const [engineTypeArr, setEngineTypeArr] = useState([])
  const [shipOwnerArr, setShipOwnerArr] = useState([])
  const [crewAgencyArr, setCrewAgencyArr] = useState([])

  // Personal data states
  const [availableFrom, setAvailableFrom] = useState('')
  const [availableFromErr, setAvailableFromErr] = useState(false)
  const [positionObj, setPositionObj] = useState(null)
  const [positionObjErr, setPositionObjErr] = useState(false)
  const [positionObjTwo, setPositionObjTwo] = useState(null)
  const [desiredVesselType, setDesiredVesselType] = useState([])

  const [firstName, setFirstName] = useState('')
  const [firstNameErr, setFirstNameErr] = useState(false)
  const [lastName, setLastName] = useState('')
  const [lastNameErr, setLastNameErr] = useState(false)
  const [middleName, setMiddleName] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [birthDateErr, setBirthDateErr] = useState(false)
  const [homeAddress, setHomeAddress] = useState('')
  const [homeAddressErr, setHomeAddressErr] = useState(false)
  const [mobilePhone, setMobilePhone] = useState('')
  const [mobilePhoneErr, setMobilePhoneErr] = useState(false)
  const [email, setEmail] = useState('')
  const [emailErr, setEmailErr] = useState(false)
  const [whatsappViberError, setWhatsappViberError] = useState(false)
  const [cityCountryError, setCityCountryError] = useState(false)
  const [maritalStatus, setMritalStatus] = useState(null)
  const [maritalStatusErr, setMaritalStatusErr] = useState(false)
  const [children, setChildren] = useState('')
  const [nationality, setNationality] = useState(null)
  const [nationalityErr, setNationalityErr] = useState(false)
  const [nationalityOther, setNationalityOther] = useState(false)
  const [birthPlace, setBirthPlace] = useState('')
  const [nearestAirport, setNearestAirport] = useState('')
  const [nearestAirportErr, setNearestAirportErr] = useState(false)
  const [englishLevel, setEnglishLevel] = useState(null)
  const [englishLevelErr, setEnglishLevelErr] = useState(false)
  const [nextToKin, setNextToKin] = useState('')
  const [nextToKinPhone, setNextToKinPhone] = useState('')
  const [nextToKinEmail, setNextToKinEmail] = useState('')
  const [nextToKinAddress, setNextToKinAddress] = useState('')
  const [contractDuration, setContractDuration] = useState('')
  const [contractDurationErr, setContractDurationErr] = useState(false)
  const [profilePhoto, setProfilePhoto] = useState('')
  const [profilePhotoErr, setProfilePhotoErr] = useState(false)
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('')
  const [profilePhotoSizeErr, setProfilePhotoSizeErr] = useState(false)
  const [whatsappViber, setWhatsappViber] = useState('')
  const [cityCountry, setCityCountry] = useState('')

  // Salary states
  const [minSalary, setMinSalary] = useState('')
  const [minSalaryErr, setMinSalaryErr] = useState('')
  const [expectedSalary, setExpectedSalary] = useState('')
  const [expectedSalaryErr, setExpectedSalaryErr] = useState('')
  const [lastSalary, setLastSalary] = useState('')
  const [lastSalaryErr, setLastSalaryErr] = useState('')

  // Education states
  const [educationLicence, setEducationLicence] = useState('')
  const [educationNumber, setEducationNumber] = useState('')
  const [educationIssued, setEducationIssued] = useState('')
  const [educationExpires, setEducationExpires] = useState('')
  const [educationDateErr, setEducationDateErr] = useState(false)
  const [educationCountry, setEducationCountry] = useState(null)

  // Documents states
  const [addedDocsArr, setAddedDocsArr] = useState([
    {
      id: 'passport',
      docId: { id: '11', name: 'Passport' },
      number: '',
      placeIssue: '',
      issued: '',
      expires: '',
      upload: '',
      replaceDocument: false,
      fileSizeError: false,
      numberError: false,
      placeIssueError: false,
      issuedError: false,
      badDateError: false,
      uploadError: false,
      docIdError: false
    },
    {
      id: 'book',
      docId: { id: '21', name: 'National Seamans Book' },
      number: '',
      placeIssue: '',
      issued: '',
      expires: '',
      upload: '',
      replaceDocument: false,
      fileSizeError: false,
      numberError: false,
      placeIssueError: false,
      issuedError: false,
      badDateError: false,
      uploadError: false,
      docIdError: false
    },
    {
      id: 'stcw',
      docId: { id: '121', name: 'Basic Safety training (A-VI/1)' },
      number: '',
      placeIssue: '',
      issued: '',
      expires: '',
      upload: '',
      replaceDocument: false,
      fileSizeError: false,
      numberError: false,
      placeIssueError: false,
      issuedError: false,
      badDateError: false,
      uploadError: false,
      docIdError: false
    },
    {
      id: 'med',
      docId: { id: '90', name: 'National medical' },
      number: '',
      placeIssue: '',
      issued: '',
      expires: '',
      upload: '',
      replaceDocument: false,
      fileSizeError: false,
      numberError: false,
      placeIssueError: false,
      issuedError: false,
      badDateError: false,
      uploadError: false,
      docIdError: false
    }
  ])
  const [documentsLinks, setDocumentsLinks] = useState([])
  const [newDocumentData, setNewDocumentData] = useState()
  const [newDocumentMsg, setNewDocumentMsg] = useState(false)
  const [uploadedFile, setUploadedFile] = useState(false)

  // Experience states
  const [addedExperienceArr, setAddedExperienceArr] = useState([
    {
      id: randomNumber(10, true, false),
      vesselName: '',
      flag: null,
      year: '',
      vesselType: null,
      dwt: '',
      engineType: null,
      hp: '',
      rankId: null,
      fromDate: '',
      tillDate: '',
      shipOwner: null,
      crewAgency: null
    }
  ])
  const [experienceValidation, setExperienceValidation] = useState(null)

  // crew or marine agencies states
  const [addedContactsArr, setAddedContactsArr] = useState([
    {
      id: randomNumber(10, true, false),
      crewAgency: '',
      email: '',
      country: null,
      contactPerson: '',
      phone: ''
    }
  ])

  // Physical states
  const [height, setHeight] = useState(null)
  const [heightErr, setHeightErr] = useState(false)
  const [weight, setWeight] = useState(null)
  const [weightErr, setWeightErr] = useState(false)
  const [shoesSize, setShoesSize] = useState(null)
  const [shoesSizeErr, setShoesSizeErr] = useState(false)
  const [clothesSize, setClothesSize] = useState(null)
  const [clothesSizeErr, setClothesSizeErr] = useState(false)
  const [hairColor, setHairColor] = useState(null)
  const [hairColorErr, setHairColorErr] = useState(false)
  const [eyeColor, seteyeColor] = useState(null)
  const [eyeColorErr, setEyeColorErr] = useState(false)

  // Comment section
  const [comment, setComment] = useState('')

  const [message, setMessage] = useState({
    status: false,
    duration: 1500,
    severity: 'success',
    message: ''
  })

  const [uploadingFile, setUploadingFile] = useState(false)
  const [uploadingFileId, setUploadingFileId] = useState('')

  useEffect(() => {
    setLoading(true)
    // Get list of positions from db
    const getPositions = () => {
      setLoading(true)
      db.collection('lists')
        .doc('positions')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setPositionArr(data.list)
          setLoading(false)
        })
        .catch((err) => {
          console.log('Positions list Error', err)
          setLoading(false)
        })
    }

    // Get nationality list from db
    const getNationality = () => {
      setLoading(true)
      db.collection('lists')
        .doc('nationality')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setNationalityArr(data.list)
          setLoading(false)
        })
        .catch((err) => {
          console.log('Nationality list Error', err)
          setLoading(false)
        })
    }

    // Get list of certificates
    const getCertificates = () => {
      setLoading(true)
      db.collection('certificates')
        .doc('certificates')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setCertificatesArr(data.list)
          setLoading(false)
        })
        .catch((err) => {
          console.log('Certificates list Error', err)
          setLoading(false)
        })
    }

    // Get vessels types
    const getVesselType = () => {
      setLoading(true)
      db.collection('lists')
        .doc('type-of-vessel')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setVesselTypesArr(data.list)
          setLoading(false)
        })
        .catch((err) => {
          console.log("Can't get vessels types", err)
          setLoading(false)
        })
    }

    // Get flag list
    const getFlagList = () => {
      setLoading(true)
      db.collection('lists')
        .doc('flag')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setFlagListArr(data.list)
          setLoading(false)
        })
        .catch((err) => {
          console.log("Can't get list of flags", err)
          setLoading(false)
        })
    }

    // Get engine types from database
    const getEngineTypes = () => {
      setLoading(true)
      db.collection('lists')
        .doc('engin-type')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setEngineTypeArr(data.list)
          setLoading(false)
        })
        .catch((err) => {
          console.log('Get engines types error', err)
          setLoading(false)
        })
    }

    // Get ship owners list
    const getShipOwner = () => {
      setLoading(true)
      db.collection('lists')
        .doc('ship-owner')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setShipOwnerArr(data.list)
          setLoading(false)
        })
        .catch((err) => {
          console.log("can't get ship owner list", err)
          setLoading(false)
        })
    }

    // Get crew agency list
    const getCrewAgency = () => {
      setLoading(true)
      db.collection('lists')
        .doc('crew-agency')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setCrewAgencyArr(data.list)
          setLoading(false)
        })
        .catch((err) => {
          console.log("Can't get crew agency list", err)
          setLoading(false)
        })
    }

    getCertificates()
    getPositions()
    getNationality()
    getVesselType()
    getFlagList()
    getEngineTypes()
    getShipOwner()
    getCrewAgency()
  }, [agencyData, uploadedFile])

  // Check basic info and go to next step
  const checkBasicInfo = () => {
    if (
      firstName !== '' &&
      positionObj !== null &&
      lastName !== '' &&
      birthDate !== '' &&
      homeAddress !== '' &&
      mobilePhone !== '' &&
      email !== '' &&
      whatsappViber !== '' &&
      cityCountry !== '' &&
      maritalStatus !== '' &&
      nationality !== '' &&
      nearestAirport !== '' &&
      englishLevel !== '' &&
      contractDuration !== '' &&
      profilePhoto !== '' &&
      availableFrom !== ''
    ) {
      setSelectedStep(selectedStep + 1)

      // Reset errors
      setPositionObjErr(false)
      setFirstNameErr(false)
      setLastNameErr(false)
      setBirthDateErr(false)
      setHomeAddressErr(false)
      setMobilePhoneErr(false)
      setEmailErr(false)
      setWhatsappViberError(false)
      setCityCountryError(false)
      setMaritalStatusErr(false)
      setNationalityErr(false)
      setNearestAirportErr(false)
      setEnglishLevelErr(false)
      setContractDurationErr(false)
      setProfilePhotoErr(false)
      setAvailableFromErr(false)
    } else {
      if (positionObj === null) {
        setPositionObjErr(true)
      } else {
        setPositionObjErr(false)
      }

      if (firstName === '') {
        setFirstNameErr(true)
      } else {
        setFirstNameErr(false)
      }

      if (lastName === '') {
        setLastNameErr(true)
      } else {
        setLastNameErr(false)
      }

      if (birthDate === '') {
        setBirthDateErr(true)
      } else {
        setBirthDateErr(false)
      }

      if (homeAddress === '') {
        setHomeAddressErr(true)
      } else {
        setHomeAddressErr(false)
      }

      if (mobilePhone === '') {
        setMobilePhoneErr(true)
      } else {
        setMobilePhoneErr(false)
      }

      if (email === '') {
        setEmailErr(true)
      } else {
        setEmailErr(false)
      }

      if (whatsappViber === '') {
        setWhatsappViberError(true)
      } else {
        setWhatsappViberError(false)
      }

      if (cityCountry === '') {
        setCityCountryError(true)
      } else {
        setCityCountryError(false)
      }

      if (maritalStatus === null) {
        setMaritalStatusErr(true)
      } else {
        setMaritalStatusErr(false)
      }

      if (nationality === null) {
        setNationalityErr(true)
      } else {
        setNationalityErr(false)
      }

      if (nearestAirport === '') {
        setNearestAirportErr(true)
      } else {
        setNearestAirportErr(false)
      }

      if (englishLevel === null) {
        setEnglishLevelErr(true)
      } else {
        setEnglishLevelErr(false)
      }

      if (contractDuration === '') {
        setContractDurationErr(true)
      } else {
        setContractDurationErr(false)
      }

      if (profilePhoto === '') {
        setProfilePhotoErr(true)
      } else {
        setProfilePhotoErr(false)
      }

      if (availableFrom === '') {
        setAvailableFromErr(true)
      } else {
        setAvailableFromErr(false)
      }
    }
  }

  // Check salary inputs and go to next step
  const checkSalary = () => {
    if (
      minSalary !== 0 &&
      minSalary !== '0' &&
      minSalary !== '' &&
      expectedSalary !== 0 &&
      expectedSalary !== '0' &&
      expectedSalary !== '' &&
      lastSalary !== 0 &&
      lastSalary !== '0' &&
      lastSalary !== ''
    ) {
      setSelectedStep(selectedStep + 1)

      // Reset errors
      setMinSalaryErr(false)
      setExpectedSalaryErr(false)
      setLastSalaryErr(false)
    } else {
      if (minSalary === 0 || minSalary === '0' || minSalary === '') {
        setMinSalaryErr(true)
      } else {
        setMinSalaryErr(false)
      }

      if (
        expectedSalary === 0 ||
        expectedSalary === '0' ||
        expectedSalary === ''
      ) {
        setExpectedSalaryErr(true)
      } else {
        setExpectedSalaryErr(false)
      }

      if (lastSalary === 0 || lastSalary === '0' || lastSalary === '') {
        setLastSalaryErr(true)
      } else {
        setLastSalaryErr(false)
      }
    }
  }

  // Check education inputs and send to next step
  const checkEducation = () => {
    if (educationIssued !== '' || educationExpires !== '') {
      if (
        educationIssued < educationExpires &&
        educationIssued !== educationExpires
      ) {
        setSelectedStep(selectedStep + 1)

        // Reset errors
        setEducationDateErr(false)
      } else {
        if (
          educationIssued < educationExpires ||
          educationIssued === educationExpires
        ) {
          setEducationDateErr(false)
        } else {
          setEducationDateErr(true)
        }
      }
    } else {
      setSelectedStep(selectedStep + 1)
    }
  }

  // Check documents inputs and send to next step
  const checkDocuments = () => {
    let canGoToTheNextStep = true

    const newInput = addedDocsArr.map((el) => {
      if (el.docId === null) {
        canGoToTheNextStep = false
        el.docIdError = true
      } else {
        el.docIdError = false
      }
      if (el.number === '') {
        canGoToTheNextStep = false
        el.numberError = true
      } else {
        el.numberError = false
      }
      if (el.placeIssue === '') {
        canGoToTheNextStep = false
        el.placeIssueError = true
      } else {
        el.placeIssueError = false
      }
      if (el.issued === '') {
        canGoToTheNextStep = false
        el.issuedError = true
      } else {
        el.issuedError = false
      }
      if (el.upload === '') {
        canGoToTheNextStep = false
        el.uploadError = true
      } else {
        el.uploadError = false
      }
      if (el.badDateError) {
        canGoToTheNextStep = false
      }

      return el
    })

    setAddedDocsArr(newInput)

    if (canGoToTheNextStep) {
      setSelectedStep(selectedStep + 1)
    }
  }

  // Update or add information of added documents inputs
  const handleInputChange = async (id, e, name, val) => {
    const newInput = addedDocsArr.map((el) => {
      if (id === el.id) {
        if (name === 'docId') {
          el[name] = val
        } else if (name === 'upload') {
          el[name] = val[0].name
          el.fileSizeError = false
          el.replaceDocument = false
        } else {
          el[name] = e.target.value
        }
      }
      return el
    })

    setAddedDocsArr(newInput)

    // Upload file to storage and update database
    if (name === 'upload') {
      setUploadingFile(true)
      setUploadingFileId(id)

      await storage
        .ref(
          `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${val[0].name}`
        )
        .put(val[0])
        .then(() => {
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('employees')
            .doc(documentId)
            .update({
              documentsArray: newInput
            })
            .then(() => {
              const getLinks = newInput.map((obj) => {
                if (obj.upload !== null && obj.upload !== '') {
                  // eslint-disable-next-line
                  return new Promise((res) => {
                    storageRef
                      .child(
                        `agencies/${agencyData.id}/users-forms-uploads/${documentId}/${obj.upload}`
                      )
                      .getDownloadURL()
                      .then((url) => {
                        res({
                          id: obj.id,
                          url
                        })
                      })
                  })
                } else {
                  // eslint-disable-next-line
                  return
                }
              })

              Promise.all(getLinks).then((res) => {
                setDocumentsLinks(res)
                setMessage({
                  status: true,
                  duration: 3000,
                  severity: 'success',
                  message: t('fileUploadedSuccessfully')
                })
                setUploadingFile(false)
              })
            })
            .catch((error) => console.error(error))
        })
        .catch((err) => {
          console.log({
            message: "Can't add certificate file to storage",
            error: err
          })
          setMessage({
            status: true,
            duration: 3000,
            severity: 'warning',
            message: t('fileWasntUploaded')
          })
        })
    }
  }

  // Add new document inputs
  const addNewDocument = () => {
    const blockId = randomNumber(10, true, false)
    setAddedDocsArr([
      ...addedDocsArr,
      {
        id: blockId,
        docId: null,
        number: '',
        placeIssue: '',
        issued: '',
        expires: '',
        upload: '',
        replaceDocument: false,
        fileSizeError: false,
        numberError: false,
        placeIssueError: false,
        issuedError: false,
        badDateError: false,
        uploadError: false,
        docIdError: false
      }
    ])
  }

  // Remove added document
  const removeDocument = async (index, file) => {
    if (
      addedDocsArr[index].id !== 'passport' &&
      addedDocsArr[index].id !== 'book' &&
      addedDocsArr[index].id !== 'stcw' &&
      addedDocsArr[index].id !== 'med'
    ) {
      const values = [...addedDocsArr]
      values.splice(index, 1)

      if (file === '') {
        setAddedDocsArr(values)
        setLoading(false)
      } else {
        await storage
          .ref(
            `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${file}`
          )
          .delete()
          .then(() => {
            db.collection('agencies')
              .doc(agencyData.id)
              .collection('employees')
              .doc(documentId)
              .update({
                documentsArray: values
              })
              .then(() => {
                const getLinks = values.map((obj) => {
                  if (obj.upload !== null) {
                    // eslint-disable-next-line
                    return new Promise((res) => {
                      storageRef
                        .child(
                          `agencies/${agencyData.id}/users-forms-uploads/${documentId}/${obj.upload}`
                        )
                        .getDownloadURL()
                        .then((url) => {
                          res({
                            id: obj.id,
                            url
                          })
                        })
                    })
                  } else {
                    // eslint-disable-next-line
                    return
                  }
                })
                Promise.all(getLinks).then((res) => {
                  setAddedDocsArr(values)
                  setDocumentsLinks(res)
                  setMessage({
                    status: true,
                    duration: 3000,
                    severity: 'success',
                    message: t('fileDeletedSuccessfully')
                  })
                })
              })
          })
          .catch((error) => {
            console.log("Can't delete reference file from storage", error)
          })
        setAddedDocsArr(values)
      }
    }
  }

  // Validate added document date
  const validateDocDate = (index) => {
    const obj = addedDocsArr[index]
    const newValue = addedDocsArr

    if (obj.issued !== '' && obj.expires !== '') {
      if (obj.issued > obj.expires || obj.issued === obj.expires) {
        newValue[index].badDateError = true
      } else {
        newValue[index].badDateError = false
      }
    }
    setAddedDocsArr([...newValue])
  }

  // Update or add informations of added experience inputs
  const addExperienceInputs = (id, e, name, val) => {
    const newInput = addedExperienceArr.map((el) => {
      if (id === el.id) {
        if (name === 'vesselType') {
          el[name] = val
        } else if (name === 'engineType') {
          el[name] = val
        } else if (name === 'rankId') {
          el[name] = val
        } else if (name === 'flag') {
          el[name] = val
        } else if (name === 'flagOther') {
          el.flag = e.target.value
        } else if (name === 'shipOwner') {
          el[name] = val
        } else if (name === 'shipOwnerOther') {
          el.shipOwner = e.target.value
        } else if (name === 'crewAgency') {
          el[name] = val
        } else if (name === 'crewAgencyOther') {
          el.crewAgency = e.target.value
        } else {
          el[name] = e.target.value
        }
      }
      return el
    })

    setAddedExperienceArr(newInput)
  }

  // Experience fields validation
  const experienceValidationSet = (id, name, bool) => {
    if (experienceValidation !== null) {
      if (experienceValidation.some((el) => el.id === id)) {
        const newInput = experienceValidation.map((el) => {
          if (id === el.id) {
            if (name === 'flag') {
              el.flag = bool
            } else if (name === 'shipOwner') {
              el.shipOwner = bool
            } else if (name === 'crewAgency') {
              el.crewAgency = bool
            }
          }

          return el
        })

        setExperienceValidation(newInput)
      } else {
        if (name === 'flag') {
          setExperienceValidation([
            ...experienceValidation,
            { id, flag: bool, shipOwner: false, crewAgency: false }
          ])
        } else if (name === 'shipOwner') {
          setExperienceValidation([
            ...experienceValidation,
            { id, flag: false, shipOwner: bool, crewAgency: false }
          ])
        } else if (name === 'crewAgency') {
          setExperienceValidation([
            ...experienceValidation,
            { id, flag: false, shipOwner: false, crewAgency: true }
          ])
        }
      }
    } else {
      if (name === 'flag') {
        setExperienceValidation([
          { id, flag: bool, shipOwner: false, crewAgency: false }
        ])
      } else if (name === 'shipOwner') {
        setExperienceValidation([
          { id, flag: false, shipOwner: bool, crewAgency: false }
        ])
      } else if (name === 'crewAgency') {
        setExperienceValidation([
          { id, flag: false, shipOwner: false, crewAgency: true }
        ])
      }
    }
  }

  // Find element validation status and return boolean
  const findValidation = (id, name) => {
    if (experienceValidation !== null) {
      let result = false
      experienceValidation.map((el) => {
        if (id === el.id) {
          return (result = el[name])
        }
        return el
      })
      return result
    } else {
      return false
    }
  }

  // Validate added experience date
  const validateExperienceDate = (index) => {
    const obj = addedExperienceArr[index]
    if (obj.fromDate !== '' || obj.tillDate !== '') {
      if (obj.fromDate > obj.tillDate || obj.fromDate === obj.tillDate) {
        return true
      } else {
        return false
      }
    }
  }

  // Add new experience inputs
  const addNewExperience = () => {
    const blockId = randomNumber(10, true, false)
    setAddedExperienceArr([
      ...addedExperienceArr,
      {
        id: blockId,
        vesselName: '',
        flag: null,
        year: '',
        vesselType: null,
        dwt: '',
        engineType: null,
        hp: '',
        rankId: null,
        fromDate: '',
        tillDate: '',
        shipOwner: null,
        crewAgency: null
      }
    ])
  }

  // Remove added experience
  const removeExperience = (index) => {
    if (addedExperienceArr.length > 1) {
      const values = [...addedExperienceArr]
      values.splice(index, 1)
      setAddedExperienceArr(values)
    } else if (addedExperienceArr.length === 1) {
      setAddedExperienceArr([
        {
          id: randomNumber(10, true, false),
          vesselName: '',
          flag: null,
          year: '',
          vesselType: null,
          dwt: '',
          engineType: null,
          hp: '',
          rankId: null,
          fromDate: '',
          tillDate: '',
          shipOwner: null,
          crewAgency: null
        }
      ])
    }
  }

  // Check experience inputs and send to next step
  const checkExperience = () => {
    const validateInputs = () => {
      let result
      if (addedExperienceArr.length > 0) {
        for (let i = 0; i < addedExperienceArr.length; i++) {
          const el = addedExperienceArr[i]
          if (
            el.vesselName === '' ||
            el.flag === null ||
            el.year === '' ||
            el.vesselType === null ||
            el.dwt === '' ||
            el.engineType === null ||
            el.hp === '' ||
            el.rankId === null ||
            el.fromDate === '' ||
            el.tillDate === '' ||
            el.shipOwner === null ||
            el.crewAgency === null ||
            el.fromDate > el.tillDate ||
            el.fromDate === el.tillDate
          ) {
            result = false
            break
          } else {
            result = true
          }
        }
      } else {
        result = false
      }
      return result
    }

    if (validateInputs() === true) {
      setSelectedStep(selectedStep + 1)
    }
  }

  // Update or add information of contacts inputs
  const addContactInput = (id, e, name, val) => {
    const newInput = addedContactsArr.map((el) => {
      if (id === el.id) {
        if (name === 'country') {
          el[name] = val
        } else {
          el[name] = e.target.value
        }
      }
      return el
    })

    setAddedContactsArr(newInput)
  }

  // Remove added experience
  const removeContact = (index) => {
    if (addedContactsArr.length > 1) {
      const values = [...addedContactsArr]
      values.splice(index, 1)
      setAddedContactsArr(values)
    }
  }

  // Add new crew or marine agencies inputs
  const addNewContact = () => {
    const blockId = randomNumber(10, true, false)
    setAddedContactsArr([
      ...addedContactsArr,
      {
        id: blockId,
        crewAgency: '',
        email: '',
        country: null,
        contactPerson: '',
        phone: ''
      }
    ])
  }

  // Check contacts inputs and send to next step
  const checkContacts = () => {
    if (!savedData) {
      submit()
    } else {
      setSelectedStep(selectedStep + 1)
    }
  }

  // Check physical state inputs and send to next step
  const checkPhysicalSate = () => {
    if (
      height !== null &&
      weight !== null &&
      shoesSize !== null &&
      clothesSize !== null &&
      hairColor !== null &&
      eyeColor !== null
    ) {
      setSelectedStep(selectedStep + 1)
    } else {
      if (height === null) {
        setHeightErr(true)
      } else {
        setHeightErr(false)
      }

      if (weight === null) {
        setWeightErr(true)
      } else {
        setWeightErr(false)
      }

      if (shoesSize === null) {
        setShoesSizeErr(true)
      } else {
        setShoesSizeErr(false)
      }

      if (clothesSize === null) {
        setClothesSizeErr(true)
      } else {
        setClothesSizeErr(false)
      }

      if (hairColor === null) {
        setHairColorErr(true)
      } else {
        setHairColorErr(false)
      }

      if (eyeColor === null) {
        setEyeColorErr(true)
      } else {
        setEyeColorErr(false)
      }
    }
  }

  // Send data to server
  const submit = () => {
    setLoading(true)

    // Set document number
    const docId = randomNumber(20, true, false)
    setDocumentId(docId)

    // Create custom object of all data to send to database
    const data = new crewEmployeeData(
      agencyData.type,
      docId,
      userUID,
      positionObj.id,
      positionObjTwo !== null ? positionObjTwo.id : null,
      firstName !== null ? firstName.toLowerCase() : '',
      lastName !== null ? lastName.toLowerCase() : '',
      middleName !== null ? middleName.toLowerCase() : '',
      birthDate,
      homeAddress !== null ? homeAddress.toLowerCase() : '',
      mobilePhone,
      nextToKinPhone,
      email !== null ? email.toLowerCase() : '',
      maritalStatus,
      children,
      nationality !== null ? nationality.toLowerCase() : '',
      birthPlace !== null ? birthPlace.toLowerCase() : '',
      nearestAirport !== null ? nearestAirport.toLowerCase() : '',
      englishLevel,
      nextToKin,
      nextToKinAddress,
      nextToKinEmail,
      minSalary,
      expectedSalary,
      lastSalary,
      educationLicence,
      educationNumber,
      educationIssued,
      educationExpires,
      educationCountry,
      [],
      addedExperienceArr,
      addedContactsArr,
      height,
      weight,
      shoesSize,
      clothesSize,
      hairColor,
      eyeColor,
      profilePhoto !== '' ? profilePhoto[0].name : '',
      contractDuration,
      comment,
      new Date(),
      [],
      availableFrom,
      'active',
      [],
      cityCountry,
      whatsappViber,
      new Date(),
      desiredVesselType,
      {
        accountNumber: '',
        bankCode: '',
        bankName: '',
        beneficiaryName: '',
        currency: '',
        note: '',
        swiftCode: ''
      },
      'available',
      [],
      'online',
      [
        {
          _id: uuid(),
          browser: browserInfo !== undefined ? browserInfo : '',
          firstName: 'profileOwner',
          lastName: '',
          message: 'addedProfile',
          timestamp: new Date(),
          uid: agencyUserData.uid
        }
      ]
    )

    const addDataToDatabase = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .withConverter(crewEmployeeConverter)
        .set(data)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          console.log('Add data error', err)
          resolve()
        })
    })

    const addPrfofilePhotoToStorage = new Promise((resolve) => {
      if (profilePhoto !== '') {
        storage
          .ref(
            `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${profilePhoto[0].name}`
          )
          .put(profilePhoto[0])
          .then(() => resolve())
          .catch((error) => {
            console.log(error)
            resolve()
          })
      } else {
        resolve()
      }
    })

    // Check if flag, ship owner and crew agencies exsist in database and if not, add to it
    const addAdditionalFieldsToDatabase = new Promise((resolve) => {
      const promise = addedExperienceArr.forEach((el) => {
        if (flagListArr.includes(el.flag) === false && el.flag !== null) {
          db.collection('lists')
            .doc('flag')
            .set({
              list: flagListArr.concat(el.flag)
            })
            .catch((err) => console.log('Add Flag error:', err))
        }
        if (
          shipOwnerArr.includes(el.shipOwner) === false &&
          el.shipOwner !== null
        ) {
          db.collection('lists')
            .doc('ship-owner')
            .set({
              list: shipOwnerArr.concat(el.shipOwner)
            })
            .catch((err) => console.log('Add ship owner error:', err))
        }

        if (
          crewAgencyArr.includes(el.crewAgency) === false &&
          el.crewAgency !== null
        ) {
          db.collection('lists')
            .doc('crew-agency')
            .set({
              list: crewAgencyArr.concat(el.crewAgency)
            })
            .catch((err) => console.log('Add crew agency error:', err))
        }
      })

      Promise.all([promise]).then(() => resolve())
    })

    const addNewNationalityToDatabase = new Promise((resolve) => {
      // Check if nationality exist in list
      const existNationality = nationalityArr.includes(nationality)
      if (!existNationality) {
        const newNationalityList = [...nationalityArr, nationality]
        db.collection('lists')
          .doc('nationality')
          .update({
            list: newNationalityList
          })
          .then(() => resolve())
          .catch((error) => {
            console.log("Can't update nationality list", error)
            resolve()
          })
      } else {
        resolve()
      }
    })

    Promise.all([
      addDataToDatabase,
      addPrfofilePhotoToStorage,
      addAdditionalFieldsToDatabase,
      addNewNationalityToDatabase
    ])
      .then(() => {
        setSelectedStep(selectedStep + 1)
        setSavedData(true)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        // setSavingDataMsg(false)
        setLoading(false)
      })
  }

  // Let filter position by ID and by name
  const _positionsOptions = createFilterOptions()
  const positionsOptions = (positionArr, state) => {
    const result = _positionsOptions(positionArr, state)

    if (result.length === 0) {
      return _positionsOptions(positionArr, {
        ...state,
        getOptionLabel: (o) => o.id.toString()
      })
    }

    return result
  }

  // Let filter certificates by ID and by name
  const _cerificatesOptions = createFilterOptions({
    stringify: (option) => `${option.id} ${option.name}`
  })
  const certificatesOptions = (certificatesArr, state) => {
    const result = _cerificatesOptions(certificatesArr, state)

    if (result.length === 0) {
      return _cerificatesOptions(certificatesArr, {
        ...state,
        getOptionLabel: (o) => o.id.toString()
      })
    }

    return result
  }

  // Update state to set file size error
  const setFileSizeError = (index, value) => {
    const newValue = addedDocsArr
    newValue[index].fileSizeError = value
    setAddedDocsArr([...newValue])
  }

  const getDocumentFileUrl = (id) => {
    let link = ''
    documentsLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  const uploadNewDocument = () => {
    setLoading(true)
    setUploadingFile(true)
    setUploadingFileId(newDocumentData.id)
    const document = addedDocsArr.find((el) => el.id === newDocumentData.id)

    const addDocument = () => {
      const newUpdatedDocumentsArray = addedDocsArr.map((el) => {
        if (el.id === newDocumentData.id) {
          el.upload = newDocumentData.file[0].name
          el.replaceDocument = false
          el.fileSizeError = false
        }
        return el
      })
      const updateDatabase = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(documentId)
          .update({
            documentsArray: newUpdatedDocumentsArray,
            date: new Date()
          })
          .then(() => resolve())
          .catch((err) => {
            console.log({ message: "Can't update certificates", error: err })
            resolve()
          })
      })

      const uploadFile = new Promise((resolve) => {
        storage
          .ref(
            `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${newDocumentData.file[0].name}`
          )
          .put(newDocumentData.file[0])
          .then(() => {
            const getLinks = newUpdatedDocumentsArray.map((obj) => {
              if (obj.upload !== null && obj.upload !== '') {
                // eslint-disable-next-line
                return new Promise((res) => {
                  storageRef
                    .child(
                      `agencies/${agencyData.id}/users-forms-uploads/${documentId}/${obj.upload}`
                    )
                    .getDownloadURL()
                    .then((url) => {
                      res({
                        id: obj.id,
                        url
                      })
                    })
                    .catch(() => {
                      res({
                        id: obj.id,
                        url: ''
                      })
                    })
                })
              } else {
                // eslint-disable-next-line
                return
              }
            })
            Promise.all(getLinks)
              .then((res) => {
                setDocumentsLinks(res)
                resolve()
              })
              .catch((error) => {
                console.log(error)
                resolve()
              })
          })
          .catch((err) => {
            console.log({ message: "Can't upload file", error: err })
            resolve()
          })
      })

      Promise.all([updateDatabase, uploadFile])
        .then(() => {
          setNewDocumentMsg(false)
          setNewDocumentData({})
          setLoading(false)
          setUploadedFile(!uploadedFile)
          setUploadingFile(false)
          setMessage({
            status: true,
            duration: 5000,
            severity: 'success',
            message: t('documentUploadSuccess')
          })
        })
        .catch((err) => {
          setNewDocumentMsg(false)
          console.log(err.message, err.err)
          setNewDocumentData({})
          setLoading(false)
          setMessage({
            status: true,
            duration: 15000,
            severity: 'warning',
            message: t('cantUploadDocument')
          })
        })
    }

    if (document && document.upload !== '' && document.upload !== null) {
      storageRef
        .child(
          `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${document.upload}`
        )
        .delete()
        .then(() => addDocument())
        .catch((error) => {
          console.log("Can't delete old certificate file", error)
          if (error.code === 'storage/object-not-found') {
            addDocument()
          }
          setNewDocumentData({})
          setNewDocumentMsg(false)
          setLoading(false)
        })
    } else {
      addDocument()
    }
  }

  // Update state to show new file upload input
  const replaceDocumentWithNew = (index) => {
    const newValue = addedDocsArr
    newValue[index].replaceDocument = true
    setAddedDocsArr([...newValue])
  }

  // Update state to hide new file upload input
  const cancelDocumentReplaceWithNew = (index) => {
    const newValue = addedDocsArr
    newValue[index].replaceDocument = false
    setAddedDocsArr([...newValue])
  }

  const updateAndFinishForm = async () => {
    setLoading(true)

    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .doc(documentId)
      .update({
        positionId: positionObj.id,
        positionTwoId: positionObjTwo !== null ? positionObjTwo.id : null,
        firstName: firstName !== null ? firstName.toLowerCase() : '',
        lastName: lastName !== null ? lastName.toLowerCase() : '',
        middleName: middleName !== null ? middleName.toLowerCase() : '',
        birthDate: birthDate,
        homeAddress: homeAddress !== null ? homeAddress.toLowerCase() : '',
        phoneMobile: mobilePhone,
        nextToKinPhone: nextToKinPhone,
        email: email !== null ? email.toLowerCase() : '',
        maritalStatus: maritalStatus,
        children: children,
        nationality: nationality !== null ? nationality.toLowerCase() : '',
        birthPlace: birthPlace !== null ? birthPlace.toLowerCase() : '',
        nearestAirport:
          nearestAirport !== null ? nearestAirport.toLowerCase() : '',
        englishLevel: englishLevel,
        nextToKin: nextToKin,
        nextToKinAddress: nextToKinAddress,
        nextToKinEmail: nextToKinEmail,
        minSalary: minSalary,
        expectedSalary: expectedSalary,
        lastSalary: lastSalary,
        educationLicence: educationLicence,
        educationNumber: educationNumber,
        educationIssued: educationIssued,
        educationExpires: educationExpires,
        educationCountry: educationCountry,
        documentsArray: addedDocsArr,
        experienceArray: addedExperienceArr,
        crewAgenciesArray: addedContactsArr,
        height: height,
        weight: weight,
        shoes: shoesSize,
        clothes: clothesSize,
        hairColor: hairColor,
        eyeColor: eyeColor,
        profilePhoto: profilePhoto !== '' ? profilePhoto[0].name : '',
        contractDuration: contractDuration,
        comment: comment,
        availableFrom: availableFrom,
        cityCountry: cityCountry,
        whatsappViber: whatsappViber,
        desiredVesselType
      })
      .then(() => {
        const addNewNationalityToDatabase = new Promise((resolve) => {
          // Check if nationality exist in list
          const existNationality = nationalityArr.includes(nationality)
          if (!existNationality) {
            const newNationalityList = [...nationalityArr, nationality]
            db.collection('lists')
              .doc('nationality')
              .update({
                list: newNationalityList
              })
              .then(() => resolve())
              .catch((error) => {
                console.log("Can't update nationality list", error)
                resolve()
              })
          } else {
            resolve()
          }
        })
        const addPrfofilePhotoToStorage = new Promise((resolve) => {
          if (profilePhoto !== '') {
            storage
              .ref(
                `/agencies/${agencyData.id}/users-forms-uploads/${documentId}/${profilePhoto[0].name}`
              )
              .put(profilePhoto[0])
              .then(() => resolve())
              .catch((error) => {
                console.log(error)
                resolve()
              })
          } else {
            resolve()
          }
        })

        Promise.all([addNewNationalityToDatabase, addPrfofilePhotoToStorage])
          .then(() => {
            setLoading(false)
            history.push(`/${agencyData.id}/dashboard/${userUID}`)
          })
          .catch((error) => {
            console.log(error)
            setLoading(false)
            history.push(`/${agencyData.id}/dashboard/${userUID}`)
          })
      })
      .catch((err) => {
        setLoading(false)
        console.log({ message: "Can't update form", error: err })
      })
  }

  /**
   * Add new experience line with parent experience data
   */
  const copyExperience = (parent) => {
    const blockId = randomNumber(10, true, false)

    setAddedExperienceArr([
      ...addedExperienceArr,
      {
        id: blockId,
        vesselName: parent.vesselName,
        flag: parent.flag,
        year: parent.year,
        vesselType: parent.vesselType,
        dwt: parent.dwt,
        engineType: parent.engineType,
        hp: parent.hp,
        rankId: parent.rankId,
        fromDate: parent.fromDate,
        tillDate: parent.tillDate,
        shipOwner: parent.shipOwner,
        crewAgency: parent.crewAgency
      }
    ])
  }

  return (
    <div>
      {loading && <Loader />}
      <Header text={t('createCV')} />

      {/* System messages */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      {/* New document upload message */}
      <Dialog open={newDocumentMsg} classes={{ paper: classes.dialogRoot }}>
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>{t('uploadNewDocumentPopupMessage')}</DialogContent>
        <DialogActions>
          <Button onClick={() => uploadNewDocument()}>{t('continue')}</Button>
          <Button
            onClick={() => {
              setNewDocumentMsg(false)
              setNewDocumentData({})
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Stepper */}
      <div>
        <Stepper
          activeStep={selectedStep}
          alternativeLabel
          classes={{ root: classes.stepperRoot }}
        >
          <Step>
            <StepLabel>{t('basicInfo')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('salary')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('education')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('experience')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('crewOrMarineAgencies')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('documents')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('physicalState')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('coverLetterComments')}</StepLabel>
          </Step>
        </Stepper>
      </div>

      {/* Stepper content */}
      <div>
        {/* Basic info */}
        {selectedStep === 0 && (
          <div>
            {/* Ranks */}
            <Grid
              container
              spacing={2}
              classes={{ root: classes.docGridContainer }}
            >
              {/* Available from */}
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='date'
                  label={t('availableFrom')}
                  value={availableFrom}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => setAvailableFrom(e.target.value)}
                  fullWidth
                  helperText={availableFromErr && t('required')}
                  error={!!availableFromErr}
                />
              </Grid>

              {/* Main rank */}
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  options={positionArr}
                  filterOptions={positionsOptions}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size='small'
                      fullWidth
                      label={t('mainRank')}
                      variant='outlined'
                      helperText={positionObjErr && t('required')}
                      error={!!positionObjErr}
                    />
                  )}
                  value={positionObj}
                  onChange={(e, val) => {
                    val && setPositionObj(val)
                  }}
                />
              </Grid>

              {/* Second rank */}
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  filterOptions={positionsOptions}
                  options={positionArr}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      fullWidth
                      label={t('secondRank')}
                      variant='outlined'
                      helperText={t('optional')}
                    />
                  )}
                  value={positionObjTwo}
                  onChange={(e, val) => {
                    setPositionObjTwo(val)
                  }}
                />
              </Grid>

              {/* Desired vessel types */}
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth size='small' variant='outlined'>
                  <InputLabel id='desiredVesselType'>
                    {t('desiredVesselType')}
                  </InputLabel>
                  <Select
                    labelId='desiredVesselType'
                    multiple
                    fullWidth
                    value={desiredVesselType}
                    onChange={(e) => setDesiredVesselType(e.target.value)}
                    input={<OutlinedInput label={t('desiredVesselType')} />}
                    renderValue={(selected) => {
                      return (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 2,
                            width: 300
                          }}
                        >
                          {selected.map((value, index) => (
                            <Chip key={index} label={t(value.name)} />
                          ))}
                        </Box>
                      )
                    }}
                  >
                    {vesselsList.map((type, index) => (
                      <MenuItem
                        key={index}
                        value={type}
                        style={{ marginLeft: type.type !== 'group' && 20 }}
                      >
                        <Checkbox
                          color='default'
                          classes={{ root: classes.checkbox }}
                          checked={desiredVesselType.indexOf(type) > -1}
                        />
                        <div
                          style={{
                            fontWeight: type.type === 'group' && 'bold'
                          }}
                        >
                          {t(type.name)}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('firstName')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  helperText={firstNameErr && t('required')}
                  error={!!firstNameErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('lastName')}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  helperText={lastNameErr && t('required')}
                  error={!!lastNameErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('middleName')}
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='date'
                  label={t('birthDate')}
                  value={birthDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => setBirthDate(e.target.value)}
                  fullWidth
                  helperText={birthDateErr && t('required')}
                  error={!!birthDateErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('homeAddress')}
                  value={homeAddress}
                  onChange={(e) => setHomeAddress(e.target.value)}
                  fullWidth
                  helperText={homeAddressErr && t('required')}
                  error={!!homeAddressErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='tel'
                  label={t('phone')}
                  placeholder='+370 ...'
                  value={mobilePhone}
                  onChange={(e) => setMobilePhone(e.target.value)}
                  fullWidth
                  helperText={mobilePhoneErr && t('required')}
                  error={!!mobilePhoneErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='email'
                  label={t('email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  helperText={emailErr && t('required')}
                  error={!!emailErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('whatsappOrViberNo')}
                  value={whatsappViber}
                  onChange={(e) => setWhatsappViber(e.target.value)}
                  fullWidth
                  helperText={whatsappViberError && t('required')}
                  error={!!whatsappViberError}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('cityCountry')}
                  value={cityCountry}
                  onChange={(e) => setCityCountry(e.target.value)}
                  fullWidth
                  helperText={cityCountryError && t('required')}
                  error={!!cityCountryError}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  options={maritalStatusArray}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size='small'
                      fullWidth
                      label={t('maritalStatus')}
                      variant='outlined'
                      helperText={maritalStatusErr && t('required')}
                      error={!!maritalStatusErr}
                    />
                  )}
                  value={maritalStatus}
                  onChange={(e, val) => {
                    val && setMritalStatus(val)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={1}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='number'
                  label={t('children')}
                  value={children}
                  onChange={(e) => setChildren(e.target.value)}
                  fullWidth
                />
              </Grid>

              {/* nationality */}
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  options={nationalityArr}
                  getOptionLabel={(option) => option}
                  value={nationality}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size='small'
                      fullWidth
                      label={t('nationality')}
                      variant='outlined'
                      helperText={nationalityErr && t('required')}
                      error={!!nationalityErr}
                    />
                  )}
                  onChange={(e, val) => {
                    if (val) {
                      if (val === 'Other') {
                        setNationalityOther(true)
                      } else {
                        setNationalityOther(false)
                        setNationality(val)
                      }
                    }
                  }}
                />
              </Grid>

              {nationalityOther ? (
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    required
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('enterNationality')}
                    // value={nationality !== null ? nationality : ''}
                    onChange={(e) => setNationality(e.target.value)}
                    fullWidth
                    helperText={nationalityErr && t('required')}
                    error={!!nationalityErr}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('birthPlace')}
                  value={birthPlace}
                  onChange={(e) => setBirthPlace(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('nearestAirpot')}
                  value={nearestAirport}
                  onChange={(e) => setNearestAirport(e.target.value)}
                  fullWidth
                  helperText={nearestAirportErr && t('required')}
                  error={!!nearestAirportErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  options={englishLevelArr}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size='small'
                      fullWidth
                      label={t('englishLevel')}
                      variant='outlined'
                      helperText={englishLevelErr && t('required')}
                      error={!!englishLevelErr}
                    />
                  )}
                  value={englishLevel}
                  onChange={(e, val) => {
                    val && setEnglishLevel(val)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='number'
                  label={t('desiredContractDuration')}
                  value={contractDuration}
                  onChange={(e) => setContractDuration(e.target.value)}
                  fullWidth
                  helperText={contractDurationErr && t('required')}
                  error={!!contractDurationErr}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              classes={{ root: classes.docGridContainer }}
            >
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('nextToKin')}
                  value={nextToKin}
                  onChange={(e) => setNextToKin(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='tel'
                  label={t('nextToKinPhone')}
                  placeholder='+370 ...'
                  value={nextToKinPhone}
                  onChange={(e) => setNextToKinPhone(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='email'
                  label={t('nextToKinEmail')}
                  value={nextToKinEmail}
                  onChange={(e) => setNextToKinEmail(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('nextToKinAddress')}
                  value={nextToKinAddress}
                  onChange={(e) => setNextToKinAddress(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>

            {/* Profile photo upload */}
            <Grid
              container
              spacing={2}
              alignItems='center'
              justifyContent='center'
              classes={{ root: classes.profilePhotoMargin }}
            >
              <Grid item>
                {profilePhoto !== '' ? (
                  <img
                    className='vesselCrewForm__upload-photo-img'
                    src={profilePhotoUrl}
                    alt='profile'
                  />
                ) : (
                  <div className='photo-box'>{t('photo')}</div>
                )}
              </Grid>
              <Grid item>
                <TextField
                  size='small'
                  variant='outlined'
                  type='file'
                  label={t('profilePhoto')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      if (e.target.files[0].size > acceptedFileSize) {
                        setProfilePhotoSizeErr(true)
                      } else {
                        reader.onload = (e) => {
                          setProfilePhotoUrl(e.target.result)
                        }
                        reader.readAsDataURL(e.target.files[0])
                        setProfilePhoto(e.target.files)
                        setProfilePhotoSizeErr(false)
                      }
                    }
                  }}
                  fullWidth
                  inputProps={{
                    accept: '.png, .jpg, .jpeg'
                  }}
                  helperText={
                    profilePhotoErr
                      ? t('required')
                      : profilePhotoSizeErr
                      ? t('toBigFile')
                      : t('acceptFormat')
                  }
                  error={!!profilePhotoErr}
                />
                {profilePhoto !== '' && (
                  <div className='addNewEmployee__upload-msg'>
                    {t('selectedFile')}: {profilePhoto[0].name}
                  </div>
                )}
              </Grid>
            </Grid>

            {/* Stepper buttons */}
            <div className='addNewEmployee__stepper-buttons'>
              <div>
                <span
                  onClick={() => {
                    selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                  }}
                >
                  {t('back')}
                </span>
              </div>

              <Button
                className='editEmployee__buttons-button'
                variant='contained'
                size='small'
                color='orange'
                onClick={() => checkBasicInfo()}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        )}

        {/* Salary section */}
        {selectedStep === 1 && (
          <div style={{ marginTop: 50 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  InputProps={{ inputProps: { min: 0 } }}
                  variant='outlined'
                  type='number'
                  label={t('minimum')}
                  value={minSalary}
                  onChange={(e) => setMinSalary(e.target.value)}
                  fullWidth
                  helperText={minSalaryErr ? t('required') : t('perMonth')}
                  error={!!minSalaryErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  InputProps={{ inputProps: { min: 0 } }}
                  variant='outlined'
                  type='number'
                  label={t('expected')}
                  value={expectedSalary}
                  onChange={(e) => setExpectedSalary(e.target.value)}
                  fullWidth
                  helperText={expectedSalaryErr ? t('required') : t('perMonth')}
                  error={!!expectedSalaryErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  size='small'
                  InputProps={{ inputProps: { min: 0 } }}
                  variant='outlined'
                  type='number'
                  label={t('lastSalary')}
                  value={lastSalary}
                  onChange={(e) => setLastSalary(e.target.value)}
                  fullWidth
                  helperText={lastSalaryErr ? t('required') : t('perMonth')}
                  error={!!lastSalaryErr}
                />
              </Grid>
            </Grid>

            {/* Stepper buttons */}
            <div className='addNewEmployee__stepper-buttons'>
              <div>
                <span
                  onClick={() => {
                    selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                  }}
                >
                  {t('back')}
                </span>
              </div>
              <Button
                className='editEmployee__buttons-button'
                variant='contained'
                size='small'
                color='orange'
                onClick={() => checkSalary()}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        )}

        {/* Education section */}
        {selectedStep === 2 && (
          <div style={{ marginTop: 50 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('licence')}
                  value={educationLicence}
                  onChange={(e) => setEducationLicence(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('number')}
                  value={educationNumber}
                  onChange={(e) => setEducationNumber(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant='outlined'
                  type='date'
                  label={t('issued')}
                  onChange={(e) => setEducationIssued(e.target.value)}
                  value={educationIssued}
                  fullWidth
                  helperText={educationDateErr ? t('badDateRange') : null}
                  error={!!educationDateErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  size='small'
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant='outlined'
                  type='date'
                  label={t('expires')}
                  onChange={(e) => setEducationExpires(e.target.value)}
                  value={educationExpires}
                  fullWidth
                  helperText={educationDateErr ? t('badDateRange') : null}
                  error={!!educationDateErr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  options={countries}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      fullWidth
                      label={t('country')}
                      variant='outlined'
                    />
                  )}
                  value={educationCountry}
                  onChange={(e, val) => {
                    val && setEducationCountry(val)
                  }}
                />
              </Grid>
            </Grid>

            <div>
              {/* Stepper buttons */}
              <div className='addNewEmployee__stepper-buttons'>
                <div>
                  <span
                    onClick={() => {
                      selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                    }}
                  >
                    {t('back')}
                  </span>
                </div>

                <Button
                  className='editEmployee__buttons-button'
                  variant='contained'
                  size='small'
                  color='orange'
                  onClick={() => checkEducation()}
                >
                  {t('next')}
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* Experience section */}
        {selectedStep === 3 && (
          <div>
            {addedExperienceArr.map((el, i) => {
              return (
                <Grid
                  container
                  spacing={2}
                  classes={{ root: classes.docGridContainer }}
                  key={el.id}
                >
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      required
                      size='small'
                      variant='outlined'
                      type='text'
                      value={el.vesselName}
                      label={t('vesselName')}
                      onChange={(e) =>
                        addExperienceInputs(el.id, e, 'vesselName')
                      }
                      fullWidth
                      helperText={el.vesselName === '' && t('required')}
                      error={el.vesselName === ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Autocomplete
                      disableClearable
                      options={flagListArr}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size='small'
                          fullWidth
                          label={t('flag')}
                          variant='outlined'
                          helperText={el.flag === null && t('required')}
                          error={el.flag === null}
                        />
                      )}
                      value={el.flag}
                      onChange={(e, val) => {
                        if (val) {
                          if (val === 'Other') {
                            experienceValidationSet(el.id, 'flag', true)
                          } else {
                            addExperienceInputs(el.id, e, 'flag', val)
                            experienceValidationSet(el.id, 'flag', false)
                          }
                        }
                      }}
                    />
                  </Grid>

                  {findValidation(el.id, 'flag') && (
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        required
                        size='small'
                        variant='outlined'
                        type='text'
                        label={t('addFlag')}
                        onChange={(e) =>
                          addExperienceInputs(el.id, e, 'flagOther')
                        }
                        fullWidth
                        helperText={el.flag === null ? t('required') : null}
                        error={el.flag === null}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      required
                      size='small'
                      variant='outlined'
                      type='text'
                      label={t('year')}
                      value={el.year}
                      onChange={(e) => addExperienceInputs(el.id, e, 'year')}
                      fullWidth
                      helperText={el.year === '' ? t('required') : null}
                      error={el.year === ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Autocomplete
                      options={vesselTypesArr}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size='small'
                          fullWidth
                          label={t('vesselType')}
                          variant='outlined'
                          helperText={el.vesselType === null && t('required')}
                          error={el.vesselType === null}
                        />
                      )}
                      value={el.vesselType}
                      onChange={(e, val) => {
                        val && addExperienceInputs(el.id, e, 'vesselType', val)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      required
                      name='expires'
                      size='small'
                      variant='outlined'
                      type='text'
                      value={el.dwt}
                      label={t('dwt')}
                      onChange={(e) => addExperienceInputs(el.id, e, 'dwt')}
                      fullWidth
                      helperText={el.dwt === '' ? t('required') : null}
                      error={el.dwt === ''}
                    />
                  </Grid>

                  {/*
                   * Engine type
                   */}
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      options={engineTypeArr}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size='small'
                          label={t('engineType')}
                          variant='outlined'
                          helperText={el.engineType === null && t('required')}
                          error={el.engineType === null}
                        />
                      )}
                      value={el.engineType}
                      onChange={(e, val) => {
                        val && addExperienceInputs(el.id, e, 'engineType', val)
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      required
                      name='expires'
                      size='small'
                      variant='outlined'
                      type='text'
                      label={t('hp')}
                      onChange={(e) => addExperienceInputs(el.id, e, 'hp')}
                      fullWidth
                      value={el.hp}
                      helperText={el.hp === '' ? t('required') : null}
                      error={el.hp === ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      options={positionArr}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size='small'
                          fullWidth
                          label={t('rank')}
                          variant='outlined'
                          helperText={el.rankId === null && t('required')}
                          error={el.rankId === null}
                        />
                      )}
                      value={el.rankId}
                      onChange={(e, val) => {
                        val && addExperienceInputs(el.id, e, 'rankId', val)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      required
                      name='expires'
                      size='small'
                      variant='outlined'
                      type='date'
                      label={t('from')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) =>
                        addExperienceInputs(el.id, e, 'fromDate')
                      }
                      fullWidth
                      value={el.fromDate}
                      helperText={
                        el.fromDate === ''
                          ? t('required')
                          : validateExperienceDate(i)
                          ? t('badDateRange')
                          : null
                      }
                      error={
                        el.fromDate === '' ? true : !!validateExperienceDate(i)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      required
                      name='expires'
                      size='small'
                      variant='outlined'
                      type='date'
                      label={t('till')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) =>
                        addExperienceInputs(el.id, e, 'tillDate')
                      }
                      fullWidth
                      value={el.tillDate}
                      helperText={
                        el.tillDate === ''
                          ? t('required')
                          : validateExperienceDate(i)
                          ? t('badDateRange')
                          : null
                      }
                      error={
                        el.tillDate === '' ? true : !!validateExperienceDate(i)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Autocomplete
                      options={shipOwnerArr}
                      disableClearable
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size='small'
                          fullWidth
                          label={t('shipOwner')}
                          variant='outlined'
                          helperText={el.shipOwner === null && t('required')}
                          error={el.shipOwner === null}
                        />
                      )}
                      value={el.shipOwner}
                      onChange={(e, val) => {
                        if (val) {
                          if (val === 'Other') {
                            experienceValidationSet(el.id, 'shipOwner', true)
                          } else {
                            addExperienceInputs(el.id, e, 'shipOwner', val)
                            experienceValidationSet(el.id, 'shipOwner', false)
                          }
                        }
                      }}
                    />
                  </Grid>

                  {findValidation(el.id, 'shipOwner') && (
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        required
                        size='small'
                        variant='outlined'
                        type='text'
                        label={t('addShipOwner')}
                        onChange={(e) =>
                          addExperienceInputs(el.id, e, 'shipOwnerOther')
                        }
                        fullWidth
                        helperText={
                          el.shipOwner === null ? t('required') : null
                        }
                        error={el.shipOwner === null}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={2}>
                    <Autocomplete
                      options={crewAgencyArr}
                      disableClearable
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size='small'
                          fullWidth
                          label={t('crewAgency')}
                          variant='outlined'
                          helperText={el.crewAgency === null && t('required')}
                          error={el.crewAgency === null}
                        />
                      )}
                      value={el.crewAgency}
                      onChange={(e, val) => {
                        if (val) {
                          if (val === 'Other') {
                            experienceValidationSet(el.id, 'crewAgency', true)
                          } else {
                            addExperienceInputs(el.id, e, 'crewAgency', val)
                            experienceValidationSet(el.id, 'crewAgency', false)
                          }
                        }
                      }}
                    />
                  </Grid>

                  {/*
                   * Add agency
                   */}
                  {findValidation(el.id, 'crewAgency') && (
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        required
                        size='small'
                        variant='outlined'
                        type='text'
                        label={t('addCrewAgency')}
                        onChange={(e) =>
                          addExperienceInputs(el.id, e, 'crewAgencyOther')
                        }
                        fullWidth
                        helperText={
                          el.crewAgency === null ? t('required') : null
                        }
                        error={el.crewAgency === null}
                      />
                    </Grid>
                  )}

                  {/*
                   * Buttons
                   */}
                  <Grid item xs={12} sm={6} md={1}>
                    <div style={{ display: 'flex' }}>
                      {/*
                       * Remove
                       */}
                      <Tooltip
                        style={{
                          marginLeft: 10,
                          marginRight: 10,
                          marginTop: 8,
                          cursor: 'pointer'
                        }}
                        placement='right-end'
                        title={t('removeExperience')}
                      >
                        <div
                          className='addNewEmployee__addRemove'
                          onClick={() => removeExperience(i)}
                        >
                          <FontAwesomeIcon
                            style={{ color: systemColors.lighGrey }}
                            icon={faTrash}
                          />
                        </div>
                      </Tooltip>

                      {/*
                       * Copy
                       */}
                      <Tooltip
                        style={{
                          marginLeft: 10,
                          marginRight: 10,
                          marginTop: 8,
                          cursor: 'pointer'
                        }}
                        placement='right-end'
                        title={t('copy_experience')}
                      >
                        <div
                          className='addNewEmployee__addRemove'
                          onClick={() => copyExperience(el)}
                        >
                          <FontAwesomeIcon
                            style={{ color: systemColors.lighGrey }}
                            icon={faCopy}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              )
            })}

            <Button
              variant='outlined'
              color='orange'
              startIcon={<FontAwesomeIcon className='icon' icon={faPlus} />}
              onClick={() => addNewExperience()}
            >
              <span>{t('selectAndAddNextExperience')}</span>
            </Button>

            <div>
              {/* Stepper buttons */}
              <div className='addNewEmployee__stepper-buttons'>
                <div>
                  <span
                    onClick={() => {
                      selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                    }}
                  >
                    {t('back')}
                  </span>
                </div>
                <Button
                  className='editEmployee__buttons-button'
                  variant='contained'
                  size='small'
                  color='orange'
                  onClick={() => checkExperience()}
                >
                  {t('next')}
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* Crew or marine agencies section */}
        {selectedStep === 4 && (
          <div>
            {addedContactsArr.map((el, i) => {
              return (
                <Grid
                  container
                  spacing={2}
                  classes={{ root: classes.docGridContainer }}
                  key={el.id}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      // required
                      size='small'
                      variant='outlined'
                      type='text'
                      value={el.crewAgency}
                      label={t('crewAgency')}
                      onChange={(e) => addContactInput(el.id, e, 'crewAgency')}
                      fullWidth
                      // helperText={el.crewAgency === '' && t('required')}
                      // error={el.crewAgency === '' ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Autocomplete
                      disableClearable
                      options={countries}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size='small'
                          fullWidth
                          label={t('country')}
                          variant='outlined'
                          // helperText={el.country === null && t('required')}
                          // error={el.country === null ? true : false}
                        />
                      )}
                      value={el.country}
                      onChange={(e, val) => {
                        val && addContactInput(el.id, e, 'country', val)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      // required
                      size='small'
                      variant='outlined'
                      type='text'
                      value={el.contactPerson}
                      label={t('contactPerson')}
                      onChange={(e) =>
                        addContactInput(el.id, e, 'contactPerson')
                      }
                      fullWidth
                      // helperText={el.contactPerson === '' && t('required')}
                      // error={el.contactPerson === '' ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      size='small'
                      variant='outlined'
                      type='email'
                      value={el.email}
                      label={t('email')}
                      onChange={(e) => addContactInput(el.id, e, 'email')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      // required
                      size='small'
                      variant='outlined'
                      type='tel'
                      value={el.phone}
                      label={t('phone')}
                      onChange={(e) => addContactInput(el.id, e, 'phone')}
                      fullWidth
                      // helperText={el.phone === '' && t('required')}
                      // error={el.phone === '' ? true : false}
                    />
                  </Grid>

                  {/* Remove contact */}
                  <Grid item xs={12} sm={6} md={1}>
                    <Tooltip
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 8,
                        cursor: 'pointer'
                      }}
                      placement='right-end'
                      title={t('removeContact')}
                    >
                      <div
                        className='addNewEmployee__addRemove'
                        onClick={() => removeContact(i)}
                      >
                        <FontAwesomeIcon
                          style={{ color: systemColors.lighGrey }}
                          icon={faTrash}
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            })}

            <Button
              variant='outlined'
              color='orange'
              startIcon={<FontAwesomeIcon className='icon' icon={faPlus} />}
              onClick={() => addNewContact()}
            >
              <span>{t('selectAndAddAgency')}</span>
            </Button>

            <div>
              {/* Stepper buttons */}
              <div className='addNewEmployee__stepper-buttons'>
                <div>
                  <span
                    onClick={() => {
                      selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                    }}
                  >
                    {t('back')}
                  </span>
                </div>
                <Button
                  className='editEmployee__buttons-button'
                  variant='contained'
                  size='small'
                  color='orange'
                  onClick={() => checkContacts()}
                >
                  {t('next')}
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* Documents section */}
        {selectedStep === 5 && (
          <div>
            <div>
              {addedDocsArr.length !== 0
                ? addedDocsArr.map((el, i) => {
                    return (
                      <Grid
                        container
                        spacing={1}
                        classes={{ root: classes.docGridContainer }}
                        key={el.id}
                      >
                        {/* Select document */}
                        <Grid item xs={12} sm={6} md={2}>
                          <Tooltip
                            style={{
                              fontSize: 20
                            }}
                            placement='top'
                            title={el.docId !== null ? el.docId.name : ''}
                          >
                            <Autocomplete
                              disabled={
                                !(
                                  el.id !== 'passport' &&
                                  el.id !== 'book' &&
                                  el.id !== 'stcw' &&
                                  el.id !== 'med'
                                )
                              }
                              filterOptions={certificatesOptions}
                              options={certificatesArr}
                              value={el.docId}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  size='small'
                                  fullWidth
                                  label={t('selectDocument')}
                                  variant='outlined'
                                  helperText={el.docIdError && t('required')}
                                  error={el.docIdError}
                                />
                              )}
                              onChange={(e, val) => {
                                val && handleInputChange(el.id, e, 'docId', val)
                              }}
                            />
                          </Tooltip>
                        </Grid>

                        {/* Number */}
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            required
                            size='small'
                            variant='outlined'
                            type='text'
                            value={el.number}
                            label={t('number')}
                            onChange={(e, val) =>
                              handleInputChange(el.id, e, 'number')
                            }
                            fullWidth
                            helperText={el.numberError && t('required')}
                            error={el.numberError}
                          />
                        </Grid>

                        {/* Place of issue */}
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            required
                            size='small'
                            variant='outlined'
                            type='text'
                            value={el.placeIssue}
                            label={t('placeOfIssue')}
                            onChange={(e) =>
                              handleInputChange(el.id, e, 'placeIssue')
                            }
                            fullWidth
                            helperText={el.placeIssueError && t('required')}
                            error={el.placeIssueError}
                          />
                        </Grid>

                        {/* Issued date */}
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            required
                            size='small'
                            variant='outlined'
                            type='date'
                            value={el.issued}
                            label={t('issued')}
                            InputLabelProps={{
                              shrink: true
                            }}
                            onChange={(e) => {
                              handleInputChange(el.id, e, 'issued')
                              validateDocDate(i)
                            }}
                            fullWidth
                            helperText={
                              el.badDateError
                                ? t('badDateRange')
                                : el.issuedError
                                ? t('required')
                                : null
                            }
                            error={!!(el.issuedError || el.badDateError)}
                          />
                        </Grid>

                        {/* Expires date */}
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={2}
                          style={{ display: 'flex' }}
                        >
                          <TextField
                            name='expires'
                            size='small'
                            variant='outlined'
                            type='date'
                            value={el.expires}
                            label={t('expires')}
                            InputLabelProps={{
                              shrink: true
                            }}
                            onChange={(e) => {
                              handleInputChange(el.id, e, 'expires')
                              validateDocDate(i)
                            }}
                            fullWidth
                            helperText={
                              el.badDateError ? t('badDateRange') : null
                            }
                            error={el.badDateError}
                          />

                          <Tooltip
                            style={{
                              marginLeft: 10,
                              marginRight: 10,
                              marginTop: 8,
                              cursor: 'pointer'
                            }}
                            placement='right-end'
                            title={t('ifExpireDateEmptyThanUnlimited')}
                          >
                            <div>
                              <FontAwesomeIcon
                                style={{ color: systemColors.lighGrey }}
                                icon={faInfoCircle}
                              />
                            </div>
                          </Tooltip>
                        </Grid>

                        {/* Document upload */}
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={2}
                          style={{ display: 'flex' }}
                        >
                          {el.docId !== null &&
                          el.number !== '' &&
                          el.placeIssue !== '' &&
                          el.issued !== '' &&
                          el.upload === '' ? (
                            <TextField
                              required
                              size='small'
                              variant='outlined'
                              type='file'
                              label={t('uploadDocument')}
                              InputLabelProps={{
                                shrink: true
                              }}
                              onChange={(e) => {
                                const file = e.target.files
                                if (file.length > 0) {
                                  if (file[0].size > acceptedFileSize) {
                                    setFileSizeError(i, true)
                                  } else {
                                    setFileSizeError(i, false)
                                    handleInputChange(el.id, e, 'upload', file)
                                  }
                                }
                              }}
                              fullWidth
                              inputProps={{
                                accept: '.png, .jpg, .jpeg, .pdf'
                              }}
                              helperText={
                                el.uploadError
                                  ? t('required')
                                  : el.fileSizeError
                                  ? t('toBigFile')
                                  : t('acceptFormat2')
                              }
                              error={el.uploadError ? true : !!el.fileSizeError}
                            />
                          ) : el.upload !== '' && !el.replaceDocument ? (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              {getDocumentFileUrl(el.id) !== '' ? (
                                <a
                                  href={getDocumentFileUrl(el.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  {t('download')}
                                </a>
                              ) : (
                                <div>
                                  {uploadingFile &&
                                  uploadingFileId === el.id ? (
                                    <FileUploadLoader />
                                  ) : (
                                    <span>{t('noFileAttached')}</span>
                                  )}
                                </div>
                              )}
                            </div>
                          ) : el.replaceDocument === true ? (
                            <TextField
                              required
                              size='small'
                              variant='outlined'
                              type='file'
                              label={t('uploadDocument')}
                              InputLabelProps={{
                                shrink: true
                              }}
                              onChange={(e) => {
                                const file = e.target.files
                                if (file.length > 0) {
                                  if (file[0].size > acceptedFileSize) {
                                    setFileSizeError(i, true)
                                  } else {
                                    setFileSizeError(i, false)
                                    setNewDocumentMsg(true)
                                    setNewDocumentData({
                                      id: el.id,
                                      file
                                    })
                                  }
                                }
                              }}
                              fullWidth
                              inputProps={{
                                accept: '.png, .jpg, .jpeg, .pdf'
                              }}
                              helperText={
                                el.uploadError
                                  ? t('required')
                                  : el.fileSizeError
                                  ? t('toBigFile')
                                  : t('acceptFormat2')
                              }
                              error={el.uploadError ? true : !!el.fileSizeError}
                            />
                          ) : (
                            <div style={{ marginTop: 8 }}>
                              {t('fillRequiredFieldsFirst')}
                            </div>
                          )}

                          {/* Replace file */}
                          {el.upload !== '' && el.replaceDocument !== true ? (
                            <Tooltip
                              style={{
                                marginLeft: 20,
                                marginTop: 8,
                                cursor: 'pointer'
                              }}
                              placement='right-end'
                              title={t('replaceFileWithAnother')}
                            >
                              <div onClick={() => replaceDocumentWithNew(i)}>
                                <FontAwesomeIcon
                                  style={{ color: systemColors.lighGrey }}
                                  icon={faSyncAlt}
                                />
                              </div>
                            </Tooltip>
                          ) : el.docId !== null &&
                            el.number !== '' &&
                            el.placeIssue !== '' &&
                            el.issued !== '' &&
                            el.upload !== '' ? (
                            <Tooltip
                              style={{
                                marginLeft: 20,
                                marginTop: 8,
                                cursor: 'pointer'
                              }}
                              placement='right-end'
                              title={t('cancelReplaceFileWithAnother')}
                            >
                              <div
                                onClick={() => cancelDocumentReplaceWithNew(i)}
                              >
                                <FontAwesomeIcon
                                  style={{ color: systemColors.lighGrey }}
                                  icon={faTimes}
                                />
                              </div>
                            </Tooltip>
                          ) : null}

                          {/* Delete document */}
                          {el.id !== 'passport' &&
                          el.id !== 'book' &&
                          el.id !== 'stcw' &&
                          el.id !== 'med' ? (
                            <Tooltip
                              style={{
                                marginLeft: 20,
                                marginTop: 8,
                                cursor: 'pointer'
                              }}
                              placement='right-end'
                              title={t('deleteDocument')}
                            >
                              <div onClick={() => removeDocument(i, el.upload)}>
                                <FontAwesomeIcon
                                  style={{ color: systemColors.lighGrey }}
                                  icon={faTrash}
                                />
                              </div>
                            </Tooltip>
                          ) : null}
                        </Grid>
                      </Grid>
                    )
                  })
                : null}

              <Button
                variant='outlined'
                color='orange'
                startIcon={<FontAwesomeIcon className='icon' icon={faPlus} />}
                onClick={() => addNewDocument()}
              >
                <span>{t('selectAndAddNextDocument')}</span>
              </Button>
            </div>

            <div>
              {/* Stepper buttons */}
              <div className='addNewEmployee__stepper-buttons'>
                <div>
                  <span
                    onClick={() => {
                      selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                    }}
                  >
                    {t('back')}
                  </span>
                </div>
                <Button
                  className='editEmployee__buttons-button'
                  variant='contained'
                  size='small'
                  color='orange'
                  onClick={() => checkDocuments()}
                >
                  {t('next')}
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* Physical state section */}
        {selectedStep === 6 && (
          <div>
            <Grid
              container
              spacing={2}
              classes={{ root: classes.docGridContainer }}
            >
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  disableClearable
                  options={heightArr}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size='small'
                      fullWidth
                      label={t('heightCm')}
                      variant='outlined'
                      helperText={heightErr && t('required')}
                      error={heightErr}
                    />
                  )}
                  value={height}
                  onChange={(e, val) => {
                    val && setHeight(val)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  disableClearable
                  options={weightArr}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size='small'
                      fullWidth
                      label={t('weightKg')}
                      variant='outlined'
                      helperText={weightErr && t('required')}
                      error={weightErr}
                    />
                  )}
                  value={weight}
                  onChange={(e, val) => {
                    val && setWeight(val)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  disableClearable
                  options={shoesSizeArr}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size='small'
                      fullWidth
                      label={t('shoes')}
                      variant='outlined'
                      helperText={shoesSizeErr && t('required')}
                      error={shoesSizeErr}
                    />
                  )}
                  value={shoesSize}
                  onChange={(e, val) => {
                    val && setShoesSize(val)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  disableClearable
                  options={clothesSizeArr}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size='small'
                      fullWidth
                      label={t('clothes')}
                      variant='outlined'
                      helperText={clothesSizeErr && t('required')}
                      error={clothesSizeErr}
                    />
                  )}
                  value={clothesSize}
                  onChange={(e, val) => {
                    val && setClothesSize(val)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  disableClearable
                  options={hairColorArr}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size='small'
                      fullWidth
                      label={t('hairColor')}
                      variant='outlined'
                      helperText={hairColorErr && t('required')}
                      error={hairColorErr}
                    />
                  )}
                  value={hairColor}
                  onChange={(e, val) => {
                    val && setHairColor(val)
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  disableClearable
                  options={eyeColorArr}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size='small'
                      fullWidth
                      label={t('eyeColor')}
                      variant='outlined'
                      helperText={eyeColorErr && t('required')}
                      error={eyeColorErr}
                    />
                  )}
                  value={eyeColor}
                  onChange={(e, val) => {
                    val && seteyeColor(val)
                  }}
                />
              </Grid>
            </Grid>

            <div>
              {/* Stepper buttons */}
              <div className='addNewEmployee__stepper-buttons'>
                <div>
                  <span
                    onClick={() => {
                      selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                    }}
                  >
                    {t('back')}
                  </span>
                </div>
                <Button
                  className='editEmployee__buttons-button'
                  variant='contained'
                  size='small'
                  color='orange'
                  onClick={() => checkPhysicalSate()}
                >
                  {t('next')}
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* Cover letter section  */}
        {selectedStep === 7 && (
          <div>
            <Grid
              container
              spacing={2}
              classes={{ root: classes.docGridContainer }}
            >
              <TextField
                size='small'
                variant='outlined'
                type='text'
                value={comment}
                label={t('comment')}
                onChange={(e) => setComment(e.target.value)}
                fullWidth
                multiline
                rows={5}
              />
            </Grid>

            <div>
              {/* Stepper buttons */}
              <div className='addNewEmployee__stepper-buttons'>
                <div>
                  <span
                    onClick={() => {
                      selectedStep !== 0 && setSelectedStep(selectedStep - 1)
                    }}
                  >
                    {t('back')}
                  </span>
                </div>
                <Button
                  className='editEmployee__buttons-button'
                  variant='contained'
                  size='small'
                  classes={{
                    contained: classes.buttonContained,
                    sizeSmall: classes.buttonText
                  }}
                  onClick={() => updateAndFinishForm()}
                >
                  {t('submit')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    agencyUserData: state.agencyUserDataRed.obj
  }
}

export default connect(mapStateToProps)(CrewEmployeeCreateCV)
