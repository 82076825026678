import React, { useEffect, useState } from 'react'
import Header from '../Modules/Header'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import Loader from '../Components/Loader'
import { useTranslation } from 'react-i18next'
import CrewEmployeeViewData from '../Modules/employees/crewEmployeeViewData'
import KeyEmployeeViewData from '../Modules/employees/keyEmployeeViewData'
import { Snackbar, Alert } from '@mui/material'
import axios from 'axios'

const db = firebase.firestore()

const EmployeePage = ({ user, userUID, agencyData }) => {
  const { t } = useTranslation()
  const verifyEmail = firebase.auth().currentUser.emailVerified

  const [loading, setLoading] = useState(false)
  const [userExist, setUserExist] = useState(false)
  const [docId, setDocId] = useState('')

  const [message, setMessage] = useState({
    status: false,
    duration: 3000,
    severity: 'success',
    message: ''
  })

  // Chanege document title in tab
  document.title = `${t('dashboard')} - Seanor`

  useEffect(() => {
    let mounted = false

    setLoading(true)
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .where('userId', '==', userUID)
      .get()
      .then((res) => {
        res.forEach((el) => {
          let data = JSON.stringify(el.data())
          data = JSON.parse(data)
          if (!mounted) {
            setDocId(data.docId)
            setUserExist(true)
          }
        })
        if (!mounted) {
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log('There is no results', err)
        if (!mounted) {
          setLoading(false)
        }
      })

    return () => (mounted = true)
  }, [agencyData, userUID])

  const sendVerificationLink = () => {
    setLoading(true)

    axios({
      method: 'POST',
      url: 'https://europe-west1-norspeda.cloudfunctions.net/sendEmailVerificationLink',
      data: {
        email: user.email,
        agencyId: agencyData.id
      }
    })
      .then((response) => {
        if (response.data.status === 'error') {
          setLoading(false)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'warning',
            message: t('thereIsProblemToSendLoginInformation')
          })
          console.log("Can't send email verification link", response.data.error)
        } else if (response.data.status === 'sended') {
          setLoading(false)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'success',
            message: t('emailVerificationSended')
          })
        } else if (response.data.error.code === 'auth/internal-error') {
          console.log(response.data.error.message)
          setLoading(false)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'warning',
            message: t('toManyAttemptsPleaseWaitTenMinutesAndTrayAgain')
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log("Can't send email verification request", error)
      })
  }

  return (
    <div>
      {loading && <Loader />}
      <Header userExist={userExist} />

      {/* warnings */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
        style={{ zIndex: 10000, height: 97 }}
      >
        <Alert
          severity={message.severity}
          style={{ paddingTop: 21, paddingBottom: 21 }}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      {/* Email verification note */}
      <Snackbar
        open={verifyEmail === false}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert severity='warning'>
          <div>{t('yourEmailNotVerified')}</div>
          <div
            className='employeePage__snackbar-link'
            onClick={() => sendVerificationLink()}
          >
            {t('notGotEmailVerifyLink')}
          </div>
        </Alert>
      </Snackbar>

      <div>
        {userExist === false ? (
          <div style={{ textAlign: 'center', marginTop: 50 }}>
            {t('notCreatedCrewCv')}
          </div>
        ) : (
          <div>
            {agencyData.type === 'crew' && (
              <CrewEmployeeViewData docId={docId} />
            )}
            {agencyData.type === 'key' && <KeyEmployeeViewData docId={docId} />}
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.agencyUserDataRed.obj,
    agencyData: state.agencyDataRed.obj
  }
}

export default connect(mapStateToProps)(EmployeePage)
