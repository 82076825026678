import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font
} from '@react-pdf/renderer'
import logo from '../../media/logo/seanor_logo_335x82.png'
import RobotoNormal from '../../media/fonts/Roboto-Regular.ttf'
import RobotoBold from '../../media/fonts/Roboto-Bold.ttf'
import { systemColors } from '../../globalVariables'

Font.register({
  fontFamily: 'Roboto',
  fonts: [
    {
      src: RobotoBold,
      fontWeight: 'bold'
    },
    {
      src: RobotoNormal
    }
  ]
})

// Colors
const orange = '#e03b22'

const styles = StyleSheet.create({
  document: {
    fontFamily: 'Roboto',
    margin: 20,
    fontSize: 10
  },
  dataTitles: {
    fontWeight: 'bold',
    color: systemColors.electric,
    marginRight: 5
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15
  },
  firstLetter: {
    textTransform: 'capitalize'
  }
})

const KeyEvaluationPdf = ({ data, userData, companyLogoURL }) => {
  const { t } = useTranslation()

  return (
    <>
      <Document>
        <Page size='A4' wrap style={{ margin: 20 }}>
          <View style={styles.document}>
            {/* LOGO SECTION */}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginBottom: 20
              }}
            >
              <Image
                style={{ width: 80, marginRight: 25 }}
                src={companyLogoURL !== '' ? companyLogoURL : logo}
              />
            </View>

            {/* Evaluation form title */}
            <View>
              <Text
                style={{
                  fontSize: 18,
                  marginBottom: 30,
                  color: systemColors.electric
                }}
              >
                {t('evaluationOf')}{' '}
                <Text style={styles.firstLetter}>{userData.firstName}</Text>{' '}
                <Text style={styles.firstLetter}>{userData.lastName}</Text>
              </Text>
            </View>

            {/* Main info */}
            <View style={{ marginBottom: 20 }}>
              <Text style={{ color: orange, fontSize: 14 }}>
                {t('mainInfo')}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              {/* Block one */}
              <View style={{ minWidth: '50%', marginRight: 10 }}>
                <View style={styles.textBlock}>
                  <Text style={styles.dataTitles}>{t('company')}:</Text>
                  <Text>{data.mainInfo.company.companyName}</Text>
                </View>
                <View style={styles.textBlock}>
                  <Text style={styles.dataTitles}>{t('project')}:</Text>
                  <Text>{data.mainInfo.project}</Text>
                </View>
                <View style={styles.textBlock}>
                  <Text style={styles.dataTitles}>{t('dateOfReport')}:</Text>
                  <Text>{data.mainInfo.dateOfReport}</Text>
                </View>
                <View style={styles.textBlock}>
                  <Text style={styles.dataTitles}>{t('reportingPeriod')}:</Text>
                  <Text>
                    {data.mainInfo.reportingPeriodFrom}
                    {' - '}
                    {data.mainInfo.reportingPeriodTo}
                  </Text>
                </View>
                <View style={styles.textBlock}>
                  <Text style={styles.dataTitles}>
                    {t('reasonForTheReport')}:
                  </Text>
                  <Text>{t(data.mainInfo.reasonForReport)}</Text>
                </View>
              </View>
              {/* Block two */}
              <View>
                <View style={styles.textBlock}>
                  <Text style={styles.dataTitles}>
                    {t('reasonForSignOff')}:
                  </Text>
                  <Text>{t(data.mainInfo.reasonForSignOff)}</Text>
                </View>
                <View style={styles.textBlock}>
                  <Text style={styles.dataTitles}>{t('dateOfBirth')}:</Text>
                  <Text>{data.mainInfo.dateOfBirth}</Text>
                </View>
                <View style={styles.textBlock}>
                  <Text style={styles.dataTitles}>{t('nationality')}:</Text>
                  <Text>{data.mainInfo.nationality}</Text>
                </View>
                <View style={styles.textBlock}>
                  <Text style={styles.dataTitles}>{t('position')}:</Text>
                  <Text>{t(data.mainInfo.position)}</Text>
                </View>
                <View style={styles.textBlock}>
                  <Text style={styles.dataTitles}>{t('linkedInProfile')}:</Text>
                  <Text>{t(userData.linkedInLink)}</Text>
                </View>
              </View>
            </View>
            <View>
              <Text style={styles.dataTitles}>{t('specialRemarksIfAny')}:</Text>
              <Text style={{ marginRight: 40 }}>
                {data.mainInfo.specialRemarks}
              </Text>
            </View>

            {/* Evaluation */}
            <View style={{ margin: '40px 0 20px' }}>
              <Text style={{ color: orange, fontSize: 14 }}>
                {t('evaluation')}
              </Text>
            </View>

            <View>
              {data.defaultEvaluation.abilityOfJob.evaluation !== '' && (
                <View style={styles.textBlock}>
                  <View style={{ width: 150 }}>
                    <Text style={styles.dataTitles}>
                      {t('abilityKnowledgeOfJob')}:
                    </Text>
                  </View>
                  <View style={{ width: 150 }}>
                    <Text>
                      {t(data.defaultEvaluation.abilityOfJob.evaluation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 300,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text style={styles.dataTitles}>{t('remark')}: </Text>
                    <Text>{t(data.defaultEvaluation.abilityOfJob.remark)}</Text>
                  </View>
                </View>
              )}

              {data.defaultEvaluation.responsibility.evaluation !== '' && (
                <View style={styles.textBlock}>
                  <View style={{ width: 150 }}>
                    <Text style={styles.dataTitles}>
                      {t('responsibility')}:
                    </Text>
                  </View>
                  <View style={{ width: 150 }}>
                    <Text>
                      {t(data.defaultEvaluation.responsibility.evaluation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 300,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text style={styles.dataTitles}>{t('remark')}: </Text>
                    <Text>
                      {t(data.defaultEvaluation.responsibility.remark)}
                    </Text>
                  </View>
                </View>
              )}

              {data.defaultEvaluation.workAttitude.evaluation !== '' && (
                <View style={styles.textBlock}>
                  <View style={{ width: 150 }}>
                    <Text style={styles.dataTitles}>{t('workAttitude')}:</Text>
                  </View>
                  <View style={{ width: 150 }}>
                    <Text>
                      {t(data.defaultEvaluation.workAttitude.evaluation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 300,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text style={styles.dataTitles}>{t('remark')}: </Text>
                    <Text>{t(data.defaultEvaluation.workAttitude.remark)}</Text>
                  </View>
                </View>
              )}

              {data.defaultEvaluation.initiativeHardWork.evaluation !== '' && (
                <View style={styles.textBlock}>
                  <View style={{ width: 150 }}>
                    <Text style={styles.dataTitles}>
                      {t('initiativeHardWork')}:
                    </Text>
                  </View>
                  <View style={{ width: 150 }}>
                    <Text>
                      {t(data.defaultEvaluation.initiativeHardWork.evaluation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 300,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text style={styles.dataTitles}>{t('remark')}: </Text>
                    <Text>
                      {t(data.defaultEvaluation.initiativeHardWork.remark)}
                    </Text>
                  </View>
                </View>
              )}

              {data.defaultEvaluation.conductDiscipline.evaluation !== '' && (
                <View style={styles.textBlock}>
                  <View style={{ width: 150 }}>
                    <Text style={styles.dataTitles}>
                      {t('conductDiscipline')}:
                    </Text>
                  </View>
                  <View style={{ width: 150 }}>
                    <Text>
                      {t(data.defaultEvaluation.conductDiscipline.evaluation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 300,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text style={styles.dataTitles}>{t('remark')}: </Text>
                    <Text>
                      {t(data.defaultEvaluation.conductDiscipline.remark)}
                    </Text>
                  </View>
                </View>
              )}

              {data.defaultEvaluation.abilityGetOnOthers.evaluation !== '' && (
                <View style={styles.textBlock}>
                  <View style={{ width: 150 }}>
                    <Text style={styles.dataTitles}>
                      {t('abilityToGetOnWithOthers')}:
                    </Text>
                  </View>
                  <View style={{ width: 150 }}>
                    <Text>
                      {t(data.defaultEvaluation.abilityGetOnOthers.evaluation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 300,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text style={styles.dataTitles}>{t('remark')}: </Text>
                    <Text>
                      {t(data.defaultEvaluation.abilityGetOnOthers.remark)}
                    </Text>
                  </View>
                </View>
              )}

              {data.defaultEvaluation.conductingSobriety.evaluation !== '' && (
                <View style={styles.textBlock}>
                  <View style={{ width: 150 }}>
                    <Text style={styles.dataTitles}>
                      {t('conductingSobriety')}:
                    </Text>
                  </View>
                  <View style={{ width: 150 }}>
                    <Text>
                      {t(data.defaultEvaluation.conductingSobriety.evaluation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 300,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text style={styles.dataTitles}>{t('remark')}: </Text>
                    <Text>
                      {t(data.defaultEvaluation.conductingSobriety.remark)}
                    </Text>
                  </View>
                </View>
              )}

              {data.defaultEvaluation.learningAbility.evaluation !== '' && (
                <View style={styles.textBlock}>
                  <View style={{ width: 150 }}>
                    <Text style={styles.dataTitles}>
                      {t('learningAbility')}:
                    </Text>
                  </View>
                  <View style={{ width: 150 }}>
                    <Text>
                      {t(data.defaultEvaluation.learningAbility.evaluation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 300,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text style={styles.dataTitles}>{t('remark')}: </Text>
                    <Text>
                      {t(data.defaultEvaluation.learningAbility.remark)}
                    </Text>
                  </View>
                </View>
              )}

              {data.defaultEvaluation.englishLanguage.evaluation !== '' && (
                <View style={styles.textBlock}>
                  <View style={{ width: 150 }}>
                    <Text style={styles.dataTitles}>
                      {t('englishLanguage')}:
                    </Text>
                  </View>
                  <View style={{ width: 150 }}>
                    <Text>
                      {t(data.defaultEvaluation.englishLanguage.evaluation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 300,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text style={styles.dataTitles}>{t('remark')}: </Text>
                    <Text>
                      {t(data.defaultEvaluation.englishLanguage.remark)}
                    </Text>
                  </View>
                </View>
              )}

              {data.defaultEvaluation.leadership.evaluation !== '' && (
                <View style={styles.textBlock}>
                  <View style={{ width: 150 }}>
                    <Text style={styles.dataTitles}>{t('leadership')}:</Text>
                  </View>
                  <View style={{ width: 150 }}>
                    <Text>
                      {t(data.defaultEvaluation.leadership.evaluation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 300,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text style={styles.dataTitles}>{t('remark')}: </Text>
                    <Text>{t(data.defaultEvaluation.leadership.remark)}</Text>
                  </View>
                </View>
              )}

              {data.defaultEvaluation.workAttitude.evaluation !== '' && (
                <View style={styles.textBlock}>
                  <View style={{ width: 150 }}>
                    <Text style={styles.dataTitles}>
                      {t('teamworkSkills')}:
                    </Text>
                  </View>
                  <View style={{ width: 150 }}>
                    <Text>
                      {t(data.defaultEvaluation.workAttitude.evaluation)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: 300,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Text style={styles.dataTitles}>{t('remark')}: </Text>
                    <Text>{t(data.defaultEvaluation.workAttitude.remark)}</Text>
                  </View>
                </View>
              )}

              {data.customEvaluation.length > 0 &&
                data.customEvaluation.map((el) => {
                  if (el.evaluation !== '' && el.evaluationName !== '') {
                    // eslint-disable-next-line
                    return (
                      <View style={styles.textBlock} key={el.id}>
                        <View style={{ width: 150 }}>
                          <Text style={styles.dataTitles}>
                            {el.evaluationName}:
                          </Text>
                        </View>
                        <View style={{ width: 150 }}>
                          <Text>{t(el.evaluation)}</Text>
                        </View>
                        <View
                          style={{
                            width: 300,
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          <Text style={styles.dataTitles}>{t('remark')}: </Text>
                          <Text>{el.remark}</Text>
                        </View>
                      </View>
                    )
                  } else {
                    // eslint-disable-next-line
                    return
                  }
                })}
            </View>

            {/* Rekommendations */}
            <View style={{ margin: '40px 0 20px' }}>
              <Text style={{ color: orange, fontSize: 14 }}>
                {t('rekommendation')}
              </Text>
            </View>

            <View style={styles.textBlock}>
              <Text style={styles.dataTitles}>
                {t('contentsOfEvaluationWithKeyWorker')}:
              </Text>
              <Text>{t(data.rekommendations.evaluationDiskussed)}</Text>
            </View>
            {data.rekommendations.reEmployPosition !== '' && (
              <View style={styles.textBlock}>
                <Text style={styles.dataTitles}>
                  {t('reEmployCurrentPosition')}:
                </Text>
                <Text>{t(data.rekommendations.reEmployPosition)}</Text>
              </View>
            )}
            {data.rekommendations.promote !== '' && (
              <View style={styles.textBlock}>
                <Text style={styles.dataTitles}>{t('promote')}:</Text>
                <Text>{t(data.rekommendations.promote)}</Text>
              </View>
            )}
          </View>
        </Page>
      </Document>
    </>
  )
}

export default KeyEvaluationPdf
