const initialState = {
    str: ''
}

const medCertExpiresRed = (state = initialState, action) => {
    switch(action.type){
        case 'MEDCERT_EXPIRES' : {
            return {
                ...state,
                str: action.str
            }
        }
        default: {
            return state
        }
    }
}

export default medCertExpiresRed