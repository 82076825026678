import { useTranslation } from 'react-i18next'
import BlockCard from './BlockCard'
import firebase from 'firebase/app'
import ComponentLoader from '../../../../../Components/ComponentLoader'
import { v4 as uuid } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTasks } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  TextField,
  Tooltip
} from '@mui/material'

const db = firebase.firestore()

interface Props {
  agencyData: any
}

const EvaluationCriteria = ({ agencyData }: Props) => {
  const { t } = useTranslation()

  const [criteriaList, setCriteriaList] = useState<
    RecruitmentEvaluationCriteria[]
  >([])

  const [loading, setLoading] = useState<boolean>(false)
  const [refreshData, setRefreshData] = useState<boolean>(false)

  const [editMode, setEditMode] = useState<boolean>(false)

  const [showNewEditCriteriaDialog, setShowNewEditCriteriaDialog] =
    useState<boolean>(false)

  const [title, setTitle] = useState<string>('')
  const [titleError, setTitleError] = useState<boolean>(false)

  const [selectedCriteriaId, setSelectedCriteriaId] = useState<string>('')

  useEffect(() => {
    let shouldUpdate = true

    db.collection('agencies')
      .doc(agencyData.id)
      .get()
      .then((snap) => {
        let data: any = JSON.stringify(snap.data())
        data = JSON.parse(data)

        const settingsCrteriaList: RecruitmentEvaluationCriteria[] | undefined =
          data.data.settings.companySettings?.candidateCriteria

        if (settingsCrteriaList !== undefined && shouldUpdate) {
          setCriteriaList(settingsCrteriaList)
        }
      })
      .catch((error) => console.error(error))

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, refreshData])

  const closeNewEditCriteriaDialog = () => {
    setShowNewEditCriteriaDialog(false)
    setEditMode(false)
    setTitle('')
    setTitleError(false)
    setSelectedCriteriaId('')
  }

  const addCriteria = async () => {
    if (title !== '') {
      setTitleError(false)
      setLoading(true)

      const newCriteriaList: RecruitmentEvaluationCriteria[] = [
        ...criteriaList,
        {
          _id: uuid(),
          title,
          evaluation: '',
          rating: 0
        }
      ]

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .update({
          'data.settings.companySettings.candidateCriteria': newCriteriaList
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
          closeNewEditCriteriaDialog()
        })
        .catch((error) => console.error(error))
    } else {
      setTitleError(true)
    }
  }

  const openEditCriteria = (criteria: RecruitmentEvaluationCriteria) => {
    setTitle(criteria.title)
    setEditMode(true)
    setSelectedCriteriaId(criteria._id)
    setShowNewEditCriteriaDialog(true)
  }

  const updateCriteria = async () => {
    if (title !== '') {
      setTitleError(false)
      setLoading(true)

      /** Create new criteria list with updated data */
      let newCriteriaList: RecruitmentEvaluationCriteria[] = criteriaList
      /** Find selected criteria index */
      const index = criteriaList.findIndex(
        (el) => el._id === selectedCriteriaId
      )

      /** Update data */
      newCriteriaList[index].title = title

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .update({
          'data.settings.companySettings.candidateCriteria': newCriteriaList
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
          closeNewEditCriteriaDialog()
        })
        .catch((error) => console.error(error))
    } else {
      setTitleError(true)
    }
  }

  const deleteCriteria = async () => {
    if (title !== '') {
      setTitleError(false)
      setLoading(true)

      /** Create new criteria list with removed criteria */
      let newCriteriaList: RecruitmentEvaluationCriteria[] = criteriaList
      /** Find selected criteria index */
      const index = criteriaList.findIndex(
        (el) => el._id === selectedCriteriaId
      )

      if (index > -1) {
        newCriteriaList.splice(index, 1)
      }

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .update({
          'data.settings.companySettings.candidateCriteria': newCriteriaList
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
          closeNewEditCriteriaDialog()
        })
        .catch((error) => console.error(error))
    } else {
      setTitleError(true)
    }
  }

  return (
    <>
      {/* New or Edit criteria dialog */}
      <Dialog open={showNewEditCriteriaDialog} fullWidth maxWidth='sm'>
        {loading && <ComponentLoader />}

        <DialogTitle>{editMode ? t('edit') : t('add')}</DialogTitle>

        <DialogContent>
          <Grid container style={{ marginTop: 0 }} spacing={2}>
            {/* Title */}
            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label={t('title')}
                type='text'
                variant='outlined'
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                error={titleError}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Delete button */}
          {editMode && (
            <Button size='small' color='error' onClick={() => deleteCriteria()}>
              {t('delete')}
            </Button>
          )}

          {/* Update button */}
          {editMode && (
            <Button
              size='small'
              variant='contained'
              color='orange'
              onClick={() => updateCriteria()}
            >
              {t('update')}
            </Button>
          )}

          {/* Add button */}
          {!editMode && (
            <Button
              size='small'
              variant='contained'
              color='orange'
              onClick={() => addCriteria()}
            >
              {t('add')}
            </Button>
          )}

          {/* Cancel button */}
          <Button size='small' onClick={() => closeNewEditCriteriaDialog()}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <BlockCard title={t('evaluation_criteria')} avatar={faTasks as IconProp}>
        {/* No criteria */}
        {criteriaList.length === 0 && (
          <div
            style={{
              textAlign: 'center',
              fontSize: 14,
              margin: '20px 0 50px',
              opacity: 0.8
            }}
          >
            {t('there_is_no_created_criteria')}
          </div>
        )}

        {/* List of added stages */}
        {criteriaList.length > 0 && (
          <List style={{ marginBottom: 20 }}>
            {criteriaList.map((criteria) => (
              <ListItem
                key={criteria._id}
                style={{ justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* Title */}
                  {criteria.title}
                </div>

                {/* Edit button */}
                <Tooltip title={t('edit')} placement='top'>
                  <div
                    className='icon'
                    onClick={() => openEditCriteria(criteria)}
                  >
                    <FontAwesomeIcon icon={faEdit as IconProp} />
                  </div>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        )}

        {/* Add stage button */}
        <div
          style={{
            textAlign: 'center'
          }}
        >
          <Button
            color='orange'
            variant='contained'
            size='small'
            onClick={() => setShowNewEditCriteriaDialog(true)}
          >
            {t('add')}
          </Button>
        </div>
      </BlockCard>
    </>
  )
}

export default EvaluationCriteria
