import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Snackbar,
  Alert
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComponentLoader from '../../../../Components/ComponentLoader'
import { v4 as uuid } from 'uuid'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import moment from 'moment'
import { createNotification } from '../../../../GlobalFunctions/Notifications/Key/createNotification'

const db = firebase.firestore()

interface Props {
  open: boolean
  onClose: () => void
  candidate: RecruitmentCandidate | null
  agencyData: AgencyData
  agencyUserData: AgencyUserData
  refreshTasksList: () => void
  /** Task which should be editing */
  editTask: Task | null
  editMode: boolean
  agencyAgents: AgencyUserData[]
}

const Task = ({
  open,
  onClose,
  candidate,
  agencyUserData,
  agencyData,
  refreshTasksList,
  editMode,
  editTask,
  agencyAgents
}: Props) => {
  const { t } = useTranslation()
  const reminderList: TaskReminder[] = [
    'onTime',
    'before1hour',
    'before1day',
    'before2days',
    'before3days',
    'before4days',
    'before5days',
    'before1week',
    'before2weeks',
    'before1month'
  ]

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  const [title, setTitle] = useState<string>('')
  const [titleError, setTitleError] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const [date, setDate] = useState<string>('')
  const [dateError, setDateError] = useState<boolean>(false)
  const [time, setTime] = useState<string>('')
  const [remind, setRemind] = useState<boolean>(false)
  const [selectedReminder, setSelectedReminder] =
    useState<TaskReminder>('onTime')
  const [assigned, setAssigned] = useState<string>(agencyUserData.uid)

  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>({
    status: false,
    duration: 5000,
    severity: 'success',
    message: ''
  })

  useEffect(() => {
    let shouldUpdate = true

    if (shouldUpdate) {
      setOpenDialog(open)

      if (editMode && editTask !== null) {
        setTitle(editTask.title)
        setDescription(editTask.description)
        setDate(editTask.date)
        setTime(editTask.time)
        setRemind(editTask.remind)
        setSelectedReminder(editTask?.selectedReminder)
      }
    }

    return () => {
      shouldUpdate = false
    }
  }, [open, editMode, editTask])

  const closeMainDialog = () => {
    onClose()

    setTitle('')
    setTitleError(false)
    setDescription('')
    setDate('')
    setDateError(false)
    setTime('')
    setRemind(false)
    setSelectedReminder('onTime')
  }

  const saveTask = async () => {
    if (title !== '' && date !== '' && candidate !== null) {
      setTitleError(false)
      setDateError(false)
      setLoading(true)

      /**
       * If task assignet to other agent then send notification to him
       */
      const sendToAgentNotificationAboutAssigment = async (
        source: 'tasks' | 'system' | 'jobs_tasks' | 'recruitment'
      ) => {
        if (agencyUserData.uid !== assigned) {
          await createNotification(
            agencyData.id,
            `${agencyUserData.name} ${agencyUserData.lastName} assigned you a task`,
            source,
            assigned
          )
        }
      }

      /**
       * Set reminder date and time
       */
      let reminderDate: string = ''
      let reminderTime: string = ''

      if (remind) {
        if (selectedReminder === 'onTime') {
          reminderDate = date
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before1hour') {
          reminderDate = date
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .subtract(60, 'minutes')
            .format('HH:mm')

          if (
            moment(time, 'HH:mm') <= moment('00:59', 'HH:mm') &&
            moment(time, 'HH:mm') >= moment('00:01', 'HH:mm')
          ) {
            reminderDate = moment(date).subtract(1, 'day').format('YYYY-MM-DD')
          }
        }

        if (selectedReminder === 'before1day') {
          reminderDate = moment(date).subtract(1, 'day').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before2days') {
          reminderDate = moment(date).subtract(2, 'day').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before3days') {
          reminderDate = moment(date).subtract(3, 'day').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before4days') {
          reminderDate = moment(date).subtract(4, 'day').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before5days') {
          reminderDate = moment(date).subtract(5, 'day').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before1week') {
          reminderDate = moment(date).subtract(1, 'week').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before2weeks') {
          reminderDate = moment(date).subtract(2, 'week').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }

        if (selectedReminder === 'before1month') {
          reminderDate = moment(date).subtract(1, 'month').format('YYYY-MM-DD')
          reminderTime = moment(time, 'HH:mm')
            .utcOffset('+0200')
            .format('HH:mm')
        }
      }

      /**
       * Find assigned agents's object
       */
      const assignedAgent: AgencyUserData = agencyAgents.filter(
        (el) => el.uid === assigned
      )[0]

      /**
       * Add new task
       */
      if (!editMode) {
        const taskId: string = uuid()

        /**
         * Task object
         */
        const newTask: Task = {
          _id: taskId,
          timestamp: new Date(),
          title,
          description,
          date,
          time,
          remind,
          reminderDate,
          reminderTime,
          selectedReminder,
          candidateProfileId: candidate.profileId,
          agentUid: assignedAgent.uid,
          agentName: `${assignedAgent.name} ${assignedAgent.lastName}`,
          agentEmail: assignedAgent.email,
          jobId: candidate.jobId,
          done: false,
          taskSource: 'jobs_tasks'
        }

        await db
          .collection('agencies')
          .doc(agencyData.id)
          .collection('tasks')
          .doc(taskId)
          .set(newTask)
          .then(() => {
            sendToAgentNotificationAboutAssigment('jobs_tasks')
            refreshTasksList()
            closeMainDialog()
            setLoading(false)
          })
          .catch((error) => console.error(error))
      }

      /**
       * Update task
       */
      if (editMode && editTask !== null) {
        const newTask = {
          title,
          description,
          date,
          time,
          remind,
          reminderDate,
          reminderTime,
          selectedReminder,
          agentUid: assignedAgent.uid,
          agentName: `${assignedAgent.name} ${assignedAgent.lastName}`,
          agentEmail: assignedAgent.email
        }
        await db
          .collection('agencies')
          .doc(agencyData.id)
          .collection('tasks')
          .doc(editTask._id)
          .update(newTask)
          .then(() => {
            sendToAgentNotificationAboutAssigment('jobs_tasks')
            refreshTasksList()
            closeMainDialog()
            setLoading(false)
          })
          .catch((error) => console.error(error))
      }
    } else {
      if (title === '') {
        setTitleError(true)
      } else {
        setTitleError(false)
      }

      if (date === '') {
        setDateError(true)
      } else {
        setDateError(false)
      }

      setSnackbarMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('pleaseFillAllRequiredFields')
      })
    }
  }

  const isAgentATasksOwner = (uid: string) => {
    if (uid === agencyUserData.uid) {
      return true
    }

    return false
  }

  const disableInput = () => {
    if (
      editMode &&
      editTask !== null &&
      !isAgentATasksOwner(editTask.agentUid)
    ) {
      return true
    }
    return false
  }

  return (
    <>
      {/* Messeges */}
      <Snackbar
        open={snackbarMessage.status}
        autoHideDuration={snackbarMessage.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() =>
          setSnackbarMessage({ ...snackbarMessage, status: false })
        }
      >
        <Alert
          severity={snackbarMessage.severity}
          onClose={() =>
            setSnackbarMessage({ ...snackbarMessage, status: false })
          }
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>

      <Dialog open={openDialog} fullWidth maxWidth='sm'>
        <DialogTitle>{editMode ? t('edit_task') : t('new_task')}</DialogTitle>

        <DialogContent>
          {loading && <ComponentLoader />}

          <Grid container spacing={2} style={{ marginTop: 20 }}>
            {/* Title */}
            <Grid item xs={12}>
              <TextField
                disabled={disableInput()}
                fullWidth
                size='small'
                required
                label={t('title')}
                value={title}
                type='text'
                onChange={(e) => setTitle(e.target.value)}
                error={titleError}
              />
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <TextField
                disabled={disableInput()}
                fullWidth
                size='small'
                label={t('description')}
                value={description}
                type='text'
                multiline
                rows={4}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>

            {/* Assign to */}
            <Grid item xs={12}>
              <TextField
                disabled={disableInput()}
                select
                fullWidth
                size='small'
                label={t('assign_to_agent')}
                value={assigned}
                onChange={(e) => setAssigned(e.target.value)}
              >
                {agencyAgents.map((user) => (
                  <MenuItem key={user.uid} value={user.uid}>
                    {user.name} {user.lastName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Date */}
            <Grid item xs={6}>
              <TextField
                disabled={disableInput()}
                fullWidth
                required
                size='small'
                label={t('date')}
                InputLabelProps={{
                  shrink: true
                }}
                value={date}
                type='date'
                onChange={(e) => setDate(e.target.value)}
                error={dateError}
              />
            </Grid>

            {/* Time */}
            <Grid item xs={6}>
              <TextField
                disabled={disableInput()}
                fullWidth
                size='small'
                label={t('time')}
                InputLabelProps={{
                  shrink: true
                }}
                value={time}
                type='time'
                onChange={(e) => setTime(e.target.value)}
              />
            </Grid>

            {/* Remind */}
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={disableInput()}
                    checked={remind}
                    color='electric'
                    onChange={() => setRemind(!remind)}
                  />
                }
                label={t('remind')}
              />
            </Grid>

            {/* Reminder selection */}
            <Grid item xs={6}>
              <TextField
                select
                disabled={!remind || disableInput()}
                fullWidth
                size='small'
                label={t('title')}
                value={selectedReminder}
                onChange={(e) =>
                  setSelectedReminder(e.target.value as TaskReminder)
                }
              >
                {reminderList.map((reminder) => (
                  <MenuItem key={reminder} value={reminder}>
                    {t(reminder)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Cancel button */}
          <Button onClick={() => closeMainDialog()}>{t('close')}</Button>

          {/* Save button */}
          {!disableInput() && (
            <Button
              size='small'
              color='orange'
              variant='contained'
              onClick={() => saveTask()}
            >
              {t('save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    agencyData: state.agencyDataRed.obj,
    agencyUserData: state.agencyUserDataRed.obj
  }
}

export default connect(mapStateToProps)(Task)
