import React, { useEffect, useState } from 'react'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  TextField,
  MenuItem,
  Grid,
  Divider,
  Snackbar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardHeader,
  CardContent
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faPlus,
  faMinus
} from '@fortawesome/free-solid-svg-icons'
import {
  systemColors,
  systemStylingSettings,
  reportReasons,
  signOffReason as signOffReasons,
  evaluationVariants
} from '../../../globalVariables'
import Loader from '../../../Components/Loader'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import { convertDate } from '../../../globalFunctions'
import { v4 as uuid } from 'uuid'
import KeyEvaluationPdf from '../../../pdfRender/key/keyEvaluationPdf'
import { showGeneratedPdfBox } from '../../../redux/actions/showGeneratedPdfBox'
import { renderPdfModule } from '../../../redux/actions/renderPdfModule'
import axios from 'axios'
import EvaluationPDFViewer from '../../../pdfRender/key/EvaluationPdfViewer'
import ComponentLoader from '../../../Components/ComponentLoader'

const db = firebase.firestore()

const useStyles = makeStyles({
  chipsDisplay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: 5
    },
    marginTop: 20
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonRoot: {
    color: '#ffffff'
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  noteRoot: {
    backgroundColor: systemColors.inputColor
  },
  showHideButton: {
    cursor: 'pointer',
    marginTop: 20,
    width: 'fit-content',
    color: systemColors.lighGrey,
    '&:hover': {
      color: systemColors.orange
    }
  },
  hideDefaultEvaluation: {
    opacity: 0,
    transition: 'opacity 0.5s',
    display: 'none'
  },
  downloadButton: {
    cursor: 'pointer',
    width: 'fit-content',
    color: systemColors.lighGrey,
    '&:hover': {
      color: systemColors.orange
    }
  }
})

const KeyEvaluation = ({
  agencyData,
  docId,
  showGeneratedPdfBox,
  setShowGeneratedPdfBox,
  renderPdfModule,
  setRenderPdfModule,
  userData,
  companyLogoURL
}) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const userId = firebase.auth().currentUser.uid

  const [loading, setLoading] = useState(false)
  const [loadedData, setLoadedData] = useState(true)
  const [refreshData, setRefreshData] = useState(false)

  const [showAddEvaluationBlock, setShowAddEvaluationBlock] = useState(false)
  const [message, setMessage] = useState({
    status: false,
    duration: 1500,
    severity: 'success',
    message: ''
  })

  // Lists
  const [companiesList, setCompaniesList] = useState([])
  const [companyProjectList, setCompanyProjectList] = useState([])
  const [evaluationsList, setevaluationsList] = useState([])

  // Form states
  const [selectedCompany, setSelectedCompany] = useState('')
  const [selectedProject, setSelectedProject] = useState('')
  const [dateOfReport] = useState(convertDate(new Date()))
  const [reportPeriodFrom, setReportPeriodFrom] = useState('')
  const [reportPeriodTo, setReportPeriodTo] = useState('')
  const [reportReason, setReportReason] = useState('')
  const [signOffReason, setSignOffReason] = useState('')
  const [dateOfBirth, setdateOfBirth] = useState(userData.dateOfBirth)
  const [selectedNationality, setSelectedNationality] = useState(
    userData.nationality
  )
  const [selectedPosition, setSelectedPosition] = useState(
    userData.mainPosition
  )
  const [specialRemarks, setSpecialRemarks] = useState('')

  // Evaluation states
  const [abilityKnowledge, setAbilityKnowledge] = useState('')
  const [abilityKnowledgeRemark, setAbilityKnowledgeRemark] = useState('')
  const [responsibility, setResponsibility] = useState('')
  const [responsibilityRemark, setResponsibilityRemark] = useState('')
  const [workAttitude, setWorkAttitude] = useState('')
  const [workAttitudeRemark, setWorkAttitudeRemark] = useState('')
  const [initiativeHardWork, setInitiativeHardWork] = useState('')
  const [initiativeHardWorkRemark, setInitiativeHardWorkRemark] = useState('')
  const [conductDiscipline, setConductDiscipline] = useState('')
  const [conductDisciplineRemark, setConductDisciplineRemark] = useState('')
  const [abilityGetOnOthers, setAbilityGetOnOthers] = useState('')
  const [abilityGetOnOthersRemarks, setAbilityGetOnOthersRemarks] = useState('')
  const [conductingSobriety, setConductingSobriety] = useState('')
  const [conductingSobrietyRemarks, setConductingSobrietyRemarks] = useState('')
  const [learningAbility, setLearningAbility] = useState('')
  const [learningAbilityRemark, setLearningAbilityRemark] = useState('')
  const [englishLanguage, setEnglishLanguage] = useState('')
  const [englishLanguageRemark, setEnglishLanguageRemark] = useState('')
  const [leadership, setLeadership] = useState('')
  const [leadershipRemark, setLeadershipRemark] = useState('')
  const [teamworkSkills, setTeamworkSkills] = useState('')
  const [teamworkSkillsRemark, setTeamworkSkillsRemark] = useState('')
  const [addedEvaluationsFields, setAddedEvaluationsFields] = useState([
    {
      id: uuid(),
      evaluationName: '',
      evaluation: '',
      remark: ''
    }
  ])

  // Rekommendation states
  const [evaluationDiskussed, setEvaluationDiskussed] = useState('')
  const [reEmployPosition, setReEmployPosition] = useState('')
  const [promote, setPromote] = useState('')

  // Form errors
  const [reportPeriodFromErr, setReportPeriodFromErr] = useState(false)
  const [reportPeriodToErr, setReportPeriodToErr] = useState(false)
  const [reportReasonErr, setReportReasonErr] = useState(false)
  const [selectedPositionErr, setSelectedPositionErr] = useState(false)

  const [hideShowDefaultEvaluationForm, sethideShowDefaultEvaluationForm] =
    useState(false)
  const [heideShowEvaluationFormClass, setheideShowEvaluationFormClass] =
    useState({})
  const [rekommendationOptions] = useState(['yes', 'no'])

  const [mergetPositionsList, setMergetPositionsList] = useState([])

  useEffect(() => {
    let shouldUpdate = true

    const getData = () => {
      let defaultPositions = []
      let agencyPositions = []

      const getCompaniesList = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .get()
          .then((res) => {
            const array = []
            res.forEach((el) => {
              let data = JSON.stringify(el.data())
              data = JSON.parse(data)
              array.push({
                companyId: data.companyId,
                companyName: data.companyName
              })
            })
            if (shouldUpdate) {
              setCompaniesList(array)
            }
            resolve()
          })
          .catch((err) => {
            console.log("Can't get companies list", err)
            resolve()
          })
      })

      const getEvaluationslist = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(docId)
          .get()
          .then((snap) => {
            let data = JSON.stringify(snap.data())
            data = JSON.parse(data)
            setevaluationsList(data.evaluations)

            resolve()
          })
          .catch((err) => {
            console.log("Can't get evaluations list", err)
            resolve()
          })
      })

      const getDefaultPositionsList = new Promise((resolve) => {
        db.collection('lists')
          .doc('key_positions')
          .get()
          .then((positions) => {
            let data = JSON.stringify(positions.data())
            data = JSON.parse(data)

            if (shouldUpdate) {
              defaultPositions = data.list
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      const getAgencyPositionsList = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('lists')
          .doc('positions')
          .get()
          .then((positions) => {
            if (positions.exists) {
              let data = JSON.stringify(positions.data())
              data = JSON.parse(data)

              if (shouldUpdate) {
                agencyPositions = data.list
              }
            }
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      Promise.all([
        getCompaniesList,
        getEvaluationslist,
        getDefaultPositionsList,
        getAgencyPositionsList
      ]).then(() => {
        if (shouldUpdate) {
          setMergetPositionsList([...defaultPositions, ...agencyPositions])
          setLoadedData(false)
        }
      })
    }
    getData()

    return () => {
      shouldUpdate = false
    }
  }, [refreshData, agencyData, docId])

  const getCompanyProjectsLits = async (id) => {
    setSelectedProject('')
    setLoading(true)
    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('companies')
      .doc(id)
      .collection('projects')
      .get()
      .then((res) => {
        const array = []
        res.forEach((el) => {
          let data = JSON.stringify(el.data())
          data = JSON.parse(data)
          array.push(data.projectName)
        })
        setCompanyProjectList(array)
        setLoading(false)
      })
      .catch((err) => {
        console.log("Can't get company projects list", err)
        setLoading(false)
      })
  }

  const addEvaluation = async () => {
    if (
      reportPeriodFrom !== '' &&
      reportPeriodTo !== '' &&
      reportReason !== '' &&
      selectedPosition !== ''
    ) {
      setLoading(true)

      const newEvaluationsList = [
        ...evaluationsList,
        {
          id: uuid(),
          timeStamp: new Date(),
          whoCreate: userId,
          mainInfo: {
            company: selectedCompany,
            project: selectedProject,
            dateOfReport,
            dateOfBirth,
            nationality: selectedNationality,
            selectedNationality,
            position: selectedPosition,
            reportingPeriodFrom: reportPeriodFrom,
            reportingPeriodTo: reportPeriodTo,
            reasonForReport: reportReason,
            reasonForSignOff: signOffReason,
            specialRemarks
          },
          defaultEvaluation: {
            abilityOfJob: {
              evaluation: abilityKnowledge,
              remark: abilityKnowledgeRemark
            },
            responsibility: {
              evaluation: responsibility,
              remark: responsibilityRemark
            },
            workAttitude: {
              evaluation: workAttitude,
              remark: workAttitudeRemark
            },
            initiativeHardWork: {
              evaluation: initiativeHardWork,
              remark: initiativeHardWorkRemark
            },
            conductDiscipline: {
              evaluation: conductDiscipline,
              remark: conductDisciplineRemark
            },
            abilityGetOnOthers: {
              evaluation: abilityGetOnOthers,
              remark: abilityGetOnOthersRemarks
            },
            conductingSobriety: {
              evaluation: conductingSobriety,
              remark: conductingSobrietyRemarks
            },
            learningAbility: {
              evaluation: learningAbility,
              remark: learningAbilityRemark
            },
            englishLanguage: {
              evaluation: englishLanguage,
              remark: englishLanguageRemark
            },
            leadership: {
              evaluation: leadership,
              remark: leadershipRemark
            },
            teamworkSkills: {
              evaluation: teamworkSkills,
              remark: teamworkSkillsRemark
            }
          },
          customEvaluation: addedEvaluationsFields,
          rekommendations: {
            evaluationDiskussed,
            reEmployPosition,
            promote
          }
        }
      ]

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .update({
          evaluations: newEvaluationsList
        })
        .then(() => {
          setShowAddEvaluationBlock(false)
          setRefreshData(!refreshData)
          setLoading(false)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'success',
            message: t('evaluationAdded')
          })
          setSelectedCompany('')
          setReportPeriodFromErr(false)
          setReportPeriodToErr(false)
          setReportReasonErr(false)
          setSelectedProject('')
          setReportPeriodFrom('')
          setReportPeriodTo('')
          setReportReason('')
          setSignOffReason('')
          setdateOfBirth('')
          setSelectedNationality('')
          setSelectedPosition('')
          setSelectedPositionErr(false)
          setSpecialRemarks('')
          setAbilityKnowledge('')
          setAbilityKnowledgeRemark('')
          setResponsibility('')
          setResponsibilityRemark('')
          setWorkAttitude('')
          setWorkAttitudeRemark('')
          setInitiativeHardWork('')
          setInitiativeHardWorkRemark('')
          setConductDiscipline('')
          setConductDisciplineRemark('')
          setAbilityGetOnOthers('')
          setAbilityGetOnOthersRemarks('')
          setConductingSobriety('')
          setConductingSobrietyRemarks('')
          setLearningAbility('')
          setLearningAbilityRemark('')
          setEnglishLanguage('')
          setEnglishLanguageRemark('')
          setLeadership('')
          setLeadershipRemark('')
          setTeamworkSkills('')
          setTeamworkSkillsRemark('')
          setAddedEvaluationsFields([
            {
              id: uuid(),
              evaluationName: '',
              evaluation: '',
              remark: ''
            }
          ])
          setReEmployPosition('')
          setEvaluationDiskussed('')
          setPromote('')
        })
        .catch((err) => {
          setLoading(false)
          console.log("Can't add evaluation", err)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'warning',
            message: t('cantAddEvaluation')
          })
        })
    } else {
      setMessage({
        status: true,
        duration: 3000,
        severity: 'warning',
        message: t('mustFillAllRequiredFields')
      })

      if (reportPeriodFrom === '') {
        setReportPeriodFromErr(true)
      } else {
        setReportPeriodFromErr(false)
      }
      if (reportPeriodTo === '') {
        setReportPeriodToErr(true)
      } else {
        setReportPeriodToErr(false)
      }
      if (reportReason === '') {
        setReportReasonErr(true)
      } else {
        setReportReasonErr(false)
      }
      if (selectedPosition === '') {
        setSelectedPositionErr(true)
      } else {
        setSelectedPositionErr(false)
      }
    }
  }

  const addCustomEvaluationFields = (id, val, name) => {
    const newInput = addedEvaluationsFields.map((el) => {
      if (id === el.id) {
        if (name === 'evaluationName') {
          el[name] = val
        } else if (name === 'evaluation') {
          el[name] = val
        } else if (name === 'remark') {
          el[name] = val
        }
      }
      return el
    })
    setAddedEvaluationsFields(newInput)
  }

  const changeHideShowEvaluationClass = () => {
    if (hideShowDefaultEvaluationForm) {
      setheideShowEvaluationFormClass({
        display: 'block',
        opacity: 0
      })
      setTimeout(() => {
        setheideShowEvaluationFormClass({
          opacity: 1,
          transition: 'all 0.5s linear'
        })
      }, 100)
    } else {
      setheideShowEvaluationFormClass({
        opacity: 0,
        transition: 'all 0.5s linear'
      })
      setTimeout(() => {
        setheideShowEvaluationFormClass({ display: 'none' })
      }, 500)
    }
  }

  const addNewRowOnCustomEvaluation = () => {
    setAddedEvaluationsFields([
      ...addedEvaluationsFields,
      {
        id: uuid(),
        evaluationName: '',
        evaluation: '',
        remark: ''
      }
    ])
  }

  const removeRowFromCustomEvaluation = (index) => {
    if (addedEvaluationsFields.length > 1) {
      const values = [...addedEvaluationsFields]
      values.splice(index, 1)
      setAddedEvaluationsFields(values)
    } else {
      setAddedEvaluationsFields([
        {
          id: uuid(),
          evaluationName: '',
          evaluation: '',
          remark: ''
        }
      ])
    }
  }

  const renderEvaluationPdf = (evaluation) => {
    setRenderPdfModule(
      <KeyEvaluationPdf
        data={evaluation}
        userData={userData}
        companyLogoURL={companyLogoURL}
      />
    )
    setShowGeneratedPdfBox(true)
  }

  // Generate pre evaluation form for printing
  const printPreEvaluation = () => {
    setLoading(true)
    let systemLanguage = i18n.language
    if (systemLanguage === 'en-BG') {
      systemLanguage = 'en'
    }
    axios({
      method: 'POST',
      url: 'https://europe-west1-norspeda.cloudfunctions.net/createPreEvaluationFile',
      data: {
        hideFormedUnits: hideShowDefaultEvaluationForm,
        fileName: 'Pre evaluation',
        language: systemLanguage,
        company: selectedCompany.companyName,
        project: selectedProject,
        firstName: userData.firstName,
        lastName: userData.lastName,
        creationDate: dateOfReport,
        dateOfBirth,
        nationality: selectedNationality,
        position: selectedPosition,
        reportingPeriodFrom: reportPeriodFrom,
        reportingPeriodTo: reportPeriodTo,
        specialRemarks,
        customEvaluations: addedEvaluationsFields
      }
    })
      .then((response) => {
        if (
          navigator.userAgent.indexOf('Safari') > -1 &&
          navigator.userAgent.indexOf('Chrome') === -1
        ) {
          window.location.href = response.data.url
        } else {
          window.open(response.data.url, '_blank')
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  return (
    <div id='evaluation'>
      {loading && <Loader />}

      {/* Snackbars */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      {/* Add evaluation block */}
      <Dialog
        open={showAddEvaluationBlock}
        classes={{ paper: classes.dialogRoot }}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('addEvaluation')}
        </DialogTitle>

        <DialogContent>
          <h4 style={{ marginTop: 20 }}>{t('mainInfo')}</h4>

          <Divider />
          {/* Main info */}
          <Grid container spacing={1} style={{ marginTop: 20 }}>
            {/* Company */}
            <Grid item xs={12} md={4}>
              <TextField
                select
                variant='outlined'
                fullWidth
                label={t('company')}
                size='small'
                value={selectedCompany}
                onChange={(e) => {
                  setSelectedCompany(e.target.value)
                  getCompanyProjectsLits(e.target.value.companyId)
                }}
              >
                {companiesList.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {el.companyName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Project */}
            {companyProjectList.length > 0 && (
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('project')}
                  size='small'
                  value={selectedProject}
                  onChange={(e) => {
                    setSelectedProject(e.target.value)
                  }}
                >
                  {companyProjectList.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            {/* Date of report */}
            <Grid item sm={12} md={3}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                type='date'
                label={t('dateOfReport')}
                value={dateOfReport}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{ marginTop: 30 }}>
            {/* Date of birth */}
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                type='date'
                label={t('dateOfBirth')}
                value={dateOfBirth}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => setdateOfBirth(e.target.value)}
              />
            </Grid>

            {/* Nationality */}
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                size='small'
                variant='outlined'
                fullWidth
                label={t('nationality')}
                value={selectedNationality}
                onChange={(e) => {
                  setSelectedNationality(e.target.value)
                }}
              />
            </Grid>

            {/* Position */}
            <Grid item xs={12} md={3}>
              <TextField
                select
                required
                variant='outlined'
                fullWidth
                label={t('position')}
                size='small'
                value={selectedPosition}
                onChange={(e) => {
                  setSelectedPosition(e.target.value)
                }}
                error={selectedPositionErr}
              >
                {mergetPositionsList.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {t(el)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{ marginTop: 30 }}>
            <Grid item xs={12} md={3}>
              <TextField
                required
                size='small'
                variant='outlined'
                type='date'
                label={t('reportingPeriodFrom')}
                value={reportPeriodFrom}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => setReportPeriodFrom(e.target.value)}
                error={reportPeriodFromErr}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                required
                size='small'
                variant='outlined'
                type='date'
                label={t('reportingPeriodTo')}
                value={reportPeriodTo}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => setReportPeriodTo(e.target.value)}
                error={reportPeriodToErr}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <TextField
                select
                required
                variant='outlined'
                fullWidth
                label={t('reasonForTheReport')}
                size='small'
                value={reportReason}
                onChange={(e) => {
                  setReportReason(e.target.value)
                }}
                error={reportReasonErr}
              >
                {reportReasons.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {t(el)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                select
                variant='outlined'
                fullWidth
                label={t('reasonForSignOff')}
                size='small'
                value={signOffReason}
                onChange={(e) => {
                  setSignOffReason(e.target.value)
                }}
              >
                {signOffReasons.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {t(el)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{ marginTop: 30 }}>
            <Grid item xs={12} md={6}>
              <TextField
                size='small'
                variant='outlined'
                value={specialRemarks}
                type='text'
                label={t('specialRemarksIfAny')}
                onChange={(e) => setSpecialRemarks(e.target.value)}
                fullWidth
                multiline
                rows={3}
              />
            </Grid>
          </Grid>

          <h4 style={{ marginTop: 40 }}>{t('evaluation')}</h4>

          <Divider />

          <div
            className={classes.showHideButton}
            onClick={() => {
              sethideShowDefaultEvaluationForm(!hideShowDefaultEvaluationForm)
              changeHideShowEvaluationClass()
            }}
          >
            {hideShowDefaultEvaluationForm ? (
              <span>
                <FontAwesomeIcon
                  style={{ fontSize: 12, color: systemColors.orange }}
                  icon={faPlus}
                />{' '}
                {t('showFormedUnits')}
              </span>
            ) : (
              <span>
                <FontAwesomeIcon
                  style={{ fontSize: 12, color: systemColors.orange }}
                  icon={faMinus}
                />{' '}
                {t('hideformedUnits')}
              </span>
            )}
          </div>
          {/* Evaluation */}
          <div style={heideShowEvaluationFormClass}>
            <Grid container spacing={1} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  csize='small'
                  variant='outlined'
                  fullWidth
                  label={t('abilityKnowledgeOfJob')}
                  size='small'
                  value={abilityKnowledge}
                  onChange={(e) => {
                    setAbilityKnowledge(e.target.value)
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  value={abilityKnowledgeRemark}
                  onChange={(e) => {
                    setAbilityKnowledgeRemark(e.target.value)
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('responsibility')}
                  size='small'
                  value={responsibility}
                  onChange={(e) => {
                    setResponsibility(e.target.value)
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  size='small'
                  value={responsibilityRemark}
                  onChange={(e) => {
                    setResponsibilityRemark(e.target.value)
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('workAttitude')}
                  size='small'
                  value={workAttitude}
                  onChange={(e) => {
                    setWorkAttitude(e.target.value)
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  classes={{ root: classes.labelRoot }}
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  size='small'
                  value={workAttitudeRemark}
                  onChange={(e) => {
                    setWorkAttitudeRemark(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('initiativeHardWork')}
                  size='small'
                  value={initiativeHardWork}
                  onChange={(e) => {
                    setInitiativeHardWork(e.target.value)
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  classes={{ root: classes.labelRoot }}
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  size='small'
                  value={initiativeHardWorkRemark}
                  onChange={(e) => {
                    setInitiativeHardWorkRemark(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('conductDiscipline')}
                  size='small'
                  value={conductDiscipline}
                  onChange={(e) => {
                    setConductDiscipline(e.target.value)
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  size='small'
                  value={conductDisciplineRemark}
                  onChange={(e) => {
                    setConductDisciplineRemark(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('abilityToGetOnWithOthers')}
                  size='small'
                  value={abilityGetOnOthers}
                  onChange={(e) => {
                    setAbilityGetOnOthers(e.target.value)
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  classes={{ root: classes.labelRoot }}
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  size='small'
                  value={abilityGetOnOthersRemarks}
                  onChange={(e) => {
                    setAbilityGetOnOthersRemarks(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('conductingSobriety')}
                  size='small'
                  value={conductingSobriety}
                  onChange={(e) => {
                    setConductingSobriety(e.target.value)
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  size='small'
                  value={conductingSobrietyRemarks}
                  onChange={(e) => {
                    setConductingSobrietyRemarks(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('learningAbility')}
                  size='small'
                  value={learningAbility}
                  onChange={(e) => {
                    setLearningAbility(e.target.value)
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  size='small'
                  value={learningAbilityRemark}
                  onChange={(e) => {
                    setLearningAbilityRemark(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('englishLanguage')}
                  size='small'
                  value={englishLanguage}
                  onChange={(e) => {
                    setEnglishLanguage(e.target.value)
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  classes={{ root: classes.labelRoot }}
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  size='small'
                  value={englishLanguageRemark}
                  onChange={(e) => {
                    setEnglishLanguageRemark(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('leadership')}
                  size='small'
                  value={leadership}
                  onChange={(e) => {
                    setLeadership(e.target.value)
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  size='small'
                  value={leadershipRemark}
                  onChange={(e) => {
                    setLeadershipRemark(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('teamworkSkills')}
                  size='small'
                  value={teamworkSkills}
                  onChange={(e) => {
                    setTeamworkSkills(e.target.value)
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  size='small'
                  value={teamworkSkillsRemark}
                  onChange={(e) => {
                    setTeamworkSkillsRemark(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
          </div>

          {/* Additional fields */}
          {addedEvaluationsFields.map((el, i) => (
            <Grid key={el.id} container spacing={1} style={{ marginTop: 15 }}>
              <Grid item xs={12} md={3}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label={t('customEvaluation')}
                  size='small'
                  value={el.evaluationName}
                  onChange={(e) => {
                    addCustomEvaluationFields(
                      el.id,
                      e.target.value,
                      'evaluationName'
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('evaluation')}
                  size='small'
                  value={el.evaluation}
                  onChange={(e) => {
                    addCustomEvaluationFields(
                      el.id,
                      e.target.value,
                      'evaluation'
                    )
                  }}
                >
                  {evaluationVariants.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label={t('identifyTrainingNeedsRemark')}
                  size='small'
                  value={el.remark}
                  onChange={(e) => {
                    addCustomEvaluationFields(el.id, e.target.value, 'remark')
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  startIcon={
                    <FontAwesomeIcon
                      style={{ fontSize: 12, color: systemColors.orange }}
                      icon={faMinus}
                    />
                  }
                  onClick={() => removeRowFromCustomEvaluation(i)}
                >
                  {t('remove')}
                </Button>
              </Grid>
            </Grid>
          ))}

          {/* Add new row button */}
          <Button
            style={{ marginTop: 20 }}
            startIcon={
              <FontAwesomeIcon
                style={{ fontSize: 12, color: systemColors.orange }}
                icon={faPlus}
              />
            }
            onClick={() => addNewRowOnCustomEvaluation()}
          >
            {t('addNewRow')}
          </Button>

          <h4 style={{ marginTop: 40 }}>{t('rekommendation')}</h4>

          <Divider />

          <Grid container spacing={1} style={{ marginTop: 10 }}>
            <Grid item xs={12} md={6}>
              <TextField
                select
                className={classes.selectStyle}
                classes={{ root: classes.labelRoot }}
                variant='outlined'
                fullWidth
                label={t('contentsOfEvaluationWithKeyWorker')}
                size='small'
                value={evaluationDiskussed}
                onChange={(e) => {
                  setEvaluationDiskussed(e.target.value)
                }}
              >
                {rekommendationOptions.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {t(el)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                className={classes.selectStyle}
                classes={{ root: classes.labelRoot }}
                variant='outlined'
                fullWidth
                label={t('reEmployCurrentPosition')}
                size='small'
                value={reEmployPosition}
                onChange={(e) => {
                  setReEmployPosition(e.target.value)
                }}
              >
                {rekommendationOptions.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {t(el)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                select
                className={classes.selectStyle}
                classes={{ root: classes.labelRoot }}
                variant='outlined'
                fullWidth
                label={t('promote')}
                size='small'
                value={promote}
                onChange={(e) => {
                  setPromote(e.target.value)
                }}
              >
                {rekommendationOptions.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {t(el)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Print button */}
          <Button
            variant='contained'
            size='small'
            color='orange'
            onClick={() => printPreEvaluation()}
          >
            {t('printForm')}
          </Button>

          {/* Add button */}
          <Button
            variant='contained'
            size='small'
            color='orange'
            onClick={() => addEvaluation()}
          >
            {t('addEvaluation')}
          </Button>

          <Button
            onClick={() => {
              setShowAddEvaluationBlock(false)
              setSelectedCompany('')
              setReportPeriodFromErr(false)
              setReportPeriodToErr(false)
              setReportReasonErr(false)
              setSelectedProject('')
              setReportPeriodFrom('')
              setReportPeriodTo('')
              setReportReason('')
              setSignOffReason('')
              setdateOfBirth('')
              setSelectedNationality('')
              setSelectedPosition('')
              setSelectedPositionErr(false)
              setSpecialRemarks('')
              setAbilityKnowledge('')
              setAbilityKnowledgeRemark('')
              setResponsibility('')
              setResponsibilityRemark('')
              setWorkAttitude('')
              setWorkAttitudeRemark('')
              setInitiativeHardWork('')
              setInitiativeHardWorkRemark('')
              setConductDiscipline('')
              setConductDisciplineRemark('')
              setAbilityGetOnOthers('')
              setAbilityGetOnOthersRemarks('')
              setConductingSobriety('')
              setConductingSobrietyRemarks('')
              setLearningAbility('')
              setLearningAbilityRemark('')
              setEnglishLanguage('')
              setEnglishLanguageRemark('')
              setLeadership('')
              setLeadershipRemark('')
              setTeamworkSkills('')
              setTeamworkSkillsRemark('')
              setAddedEvaluationsFields([
                {
                  id: uuid(),
                  evaluationName: '',
                  evaluation: '',
                  remark: ''
                }
              ])
              setReEmployPosition('')
              setEvaluationDiskussed('')
              setPromote('')
            }}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      <Card style={{ minWidth: 300, margin: 20, position: 'relative' }}>
        {loadedData && <ComponentLoader />}

        <CardHeader
          sx={[{ '& .MuiCardHeader-title': { fontSize: 15 } }]}
          title={t('evaluation')}
        />

        <CardContent>
          {/*
           * Chips
           */}
          <div className={classes.chipsDisplay}>
            <Chip
              label={t('addEvaluation')}
              clickable
              size='small'
              variant='outlined'
              onClick={() => setShowAddEvaluationBlock(true)}
              icon={
                <FontAwesomeIcon
                  style={{ marginLeft: 8 }}
                  icon={faPlusCircle}
                />
              }
            />
          </div>

          {/*
           * Evaluations list
           */}
          <Table style={{ maxWidth: 300, margin: 'auto', marginTop: 30 }}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={{ fontWeight: 'bold' }}>
                  {t('created')}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {t('preview')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {evaluationsList.length > 0 ? (
                evaluationsList.map((el, i) => (
                  <TableRow key={el.id} hover>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{el.mainInfo.dateOfReport}</TableCell>
                    <TableCell>
                      <div
                        className={classes.downloadButton}
                        onClick={() => {
                          renderEvaluationPdf(el)
                        }}
                      >
                        {
                          <EvaluationPDFViewer
                            document={
                              <KeyEvaluationPdf
                                data={el}
                                userData={userData}
                                companyLogoURL={companyLogoURL}
                              />
                            }
                            firstName={userData.firstName}
                            lastName={userData.lastName}
                          />
                        }
                      </div>
                    </TableCell>
                    {/* <TableCell>
                  <div
                    className={classes.downloadButton}
                    onClick={() => {
                      renderEvaluationPdf(el)
                    }}
                  >
                    {t('download')}
                  </div>
                </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    style={{
                      textAlign: 'center',
                      color: systemColors.lighGrey
                    }}
                  >
                    {t('thereAreNoEvaluationsCreated')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    showGeneratedPdfBox: state.showGeneratedPdfBoxRed.bool,
    renderPdfModule: state.renderPdfModuleRed.str
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowGeneratedPdfBox: (bool) => dispatch(showGeneratedPdfBox(bool)),
    setRenderPdfModule: (str) => dispatch(renderPdfModule(str))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyEvaluation)
