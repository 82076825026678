export const vesselsList = [
  {
    name: 'fishingVessels',
    type: 'group',
    id: 1
  },
  {
    name: 'fishingBoat',
    type: 'fishingVessels',
    id: 2
  },
  {
    name: 'trawler',
    type: 'fishingVessels',
    id: 3
  },
  {
    name: 'tankers',
    type: 'group',
    id: 4
  },
  {
    name: 'asphalatTanker',
    type: 'tankers',
    id: 5
  },
  {
    name: 'bitumenTanker',
    type: 'tankers',
    id: 6
  },
  {
    name: 'chemicalTanker',
    type: 'tankers',
    id: 7
  },
  {
    name: 'crudeOilTanker',
    type: 'tankers',
    id: 8
  },
  {
    name: 'lng',
    type: 'tankers',
    id: 9
  },
  {
    name: 'lpg',
    type: 'tankers',
    id: 10
  },
  {
    name: 'oilTanker',
    type: 'tankers',
    id: 11
  },
  {
    name: 'oilChemicalTanker',
    type: 'tankers',
    id: 12
  },
  {
    name: 'passengerShips',
    type: 'group',
    id: 13
  },
  {
    name: 'passengerShip',
    type: 'passengerShips',
    id: 14
  },
  {
    name: 'cruiseVessel',
    type: 'passengerShips',
    id: 15
  },
  {
    name: 'ferry',
    type: 'passengerShips',
    id: 16
  },
  {
    name: 'floatingHotel',
    type: 'passengerShips',
    id: 17
  },
  {
    name: 'pleasantBoatYact',
    type: 'passengerShips',
    id: 18
  },
  {
    name: 'cargoVessels',
    type: 'group',
    id: 19
  },
  {
    name: 'dryCargoVessel',
    type: 'cargoVessels',
    id: 20
  },
  {
    name: 'generalCargoVessel',
    type: 'cargoVessels',
    id: 21
  },
  {
    name: 'heavyLiftCarrier',
    type: 'cargoVessels',
    id: 22
  },
  {
    name: 'livestockCarrier',
    type: 'cargoVessels',
    id: 23
  },
  {
    name: 'multiPurposeVessel',
    type: 'cargoVessels',
    id: 24
  },
  {
    name: 'roRo',
    type: 'cargoVessels',
    id: 25
  },
  {
    name: 'reefer',
    type: 'cargoVessels',
    id: 26
  },
  {
    name: 'bulkCarriers',
    type: 'group',
    id: 27
  },
  {
    name: 'bulkCarrier',
    type: 'bulkCarriers',
    id: 28
  },
  {
    name: 'cementCarrier',
    type: 'bulkCarriers',
    id: 29
  },
  {
    name: 'selfUnloadingBulk',
    type: 'bulkCarriers',
    id: 30
  },
  {
    name: 'containerCarriers',
    type: 'group',
    id: 31
  },
  {
    name: 'conBulker',
    type: 'containerCarriers',
    id: 32
  },
  {
    name: 'containerShip',
    type: 'containerCarriers',
    id: 33
  },
  {
    name: 'offshoreFleet',
    type: 'group',
    id: 34
  },
  {
    name: 'accommodationVessel',
    type: 'offshoreFleet',
    id: 35
  },
  {
    name: 'aht',
    type: 'offshoreFleet',
    id: 36
  },
  {
    name: 'barge',
    type: 'offshoreFleet',
    id: 37
  },
  {
    name: 'tug',
    type: 'offshoreFleet',
    id: 38
  },
  {
    name: 'onshoreJobs',
    type: 'group',
    id: 39
  },
  {
    name: 'superindant',
    type: 'onshoreJobs',
    id: 40
  },
  {
    name: 'onshore',
    type: 'onshoreJobs',
    id: 41
  },
  {
    name: 'port',
    type: 'onshoreJobs',
    id: 42
  }
]
