const initialState = {
  str: ''
}

const renderPdfModuleRed = (state = initialState, action) => {
  switch (action.type) {
    case 'RENDER_PDF_MODULE': {
      return {
        ...state,
        str: action.str
      }
    }
    default: {
      return state
    }
  }
}

export default renderPdfModuleRed
