const initialState = {
  id: ''
}

const projectIdSmartPlanningReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PROJECT_ID_SMART_PLANNING' : {
      return {
        ...state,
        id: action.id
      }
    }
    default: {
      return state
    }
  }
}

export default projectIdSmartPlanningReducer

