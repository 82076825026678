import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AgencyMenu from '../../../../Components/AgencyMenu'
import Headline from '../../../../Components/Headline'
import AgentsAddedProfiles from './Modules/agentsAddedProfiles'
import ProfilesByStatus from './Modules/profilesByStatus'

const CompanyStatistics = () => {
  const { t } = useTranslation()

  // Change document title
  document.title = `${t('companyStatistics')} - Seanor`

  return (
    <div>
      <Headline text={t('companyStatistics')} />

      <AgencyMenu type='key' />

      <Grid
        container
        spacing={3}
        style={{ margin: '50px 0' }}
        justifyContent='center'
      >
        <Grid item>
          <ProfilesByStatus />
        </Grid>
        <Grid item>
          <AgentsAddedProfiles />
        </Grid>
      </Grid>
    </div>
  )
}

export default CompanyStatistics
