import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import './footer.scss'

const Footer = ({ agencyData, userData }) => {
  const history = useHistory()

  return (
    <footer className='footer'>
      <div className='footer_content'>
        {userData !== null && (
          <div
            className='footer__version'
            onClick={() => history.push(`/${agencyData.id}/versions-log`)}
          >
            v0.16.2
          </div>
        )}
      </div>
    </footer>
  )
}

const mapsStateToProps = (state) => ({
  agencyData: state.agencyDataRed.obj
})

export default connect(mapsStateToProps)(Footer)
