import { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './TextEditor.scss'

interface TextEditorParams {
  onChange: (value: string) => string | void
  placeholder?: string | undefined
  error?: boolean
  defaultValue?: string | undefined
  required?: boolean
}

const TextEditor = ({
  onChange,
  placeholder,
  error,
  defaultValue,
  required
}: TextEditorParams) => {
  const [errorState, setErrorState] = useState<boolean | undefined>(false)

  useEffect(() => {
    let shouldUpdate = true

    if (shouldUpdate) {
      setErrorState(error)
    }
    return () => {
      shouldUpdate = false
    }
  }, [error])

  const changeValue = (value: string) => {
    onChange(value)
  }

  return (
    <div className='editor'>
      <ReactQuill
        theme='snow'
        defaultValue={defaultValue}
        onChange={(value) => changeValue(value)}
        placeholder={`${placeholder !== undefined ? placeholder : ''} ${
          required ? '*' : ''
        }`}
        className={errorState ? 'textEditor__error' : 'textEditor'}
      />
    </div>
  )
}

export default TextEditor
