import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import '../../../styles/routes/recruitment/jobsAds.scss'
import moment from 'moment'
import { Button, Paper } from '@mui/material'
import { useHistory } from 'react-router-dom'

const db = firebase.firestore()
const storage = firebase.storage()

interface Props {
  agencyData: any
}

const JobsAds = ({ agencyData }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [companyLogoUrl, setCompanyLogoUrl] = useState<string>('')

  const [jobsList, setJobsList] = useState<Job[]>([])

  useEffect(() => {
    let shouldUpdate: boolean = true

    if (agencyData.logoFileName !== undefined) {
      /** Change document title */
      document.title = `${t('open_positions')} - ${agencyData.name}`

      const logoFilename: string = agencyData.logoFileName

      /** Get agency logo URL */
      if (logoFilename !== '' && shouldUpdate) {
        storage
          .ref()
          .child(`/public/agencies/${agencyData.id}/logo/${logoFilename}`)
          .getDownloadURL()
          .then((url) => {
            setCompanyLogoUrl(url)
          })
      }

      /** Get listed jobs */
      const today: string = moment().format('YYYY-MM-DD')

      db.collection('agencies')
        .doc(agencyData.id)
        .collection('jobs')
        .where('data.closedJob.closed', '==', false)
        .where('data.options.activeJob', '==', true)
        .where('data.options.publicJob', '==', true)
        .where('data.publishFrom', '<=', today)
        .get()
        .then((snap) => {
          let jobsArray: Job[] = []

          snap.forEach((doc) => {
            let data: any = JSON.stringify(doc.data())
            data = JSON.parse(data)

            if (data.data.publishUntil >= today) {
              jobsArray = [...jobsArray, data.data as Job]
            }
          })

          if (shouldUpdate) {
            setJobsList(jobsArray)
          }
        })
        .catch((error) => console.log(error))
    }

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, t])

  const reduceDecription = (text: string) => {
    const descriptionLenght: number = 200
    let newText: string = text
    newText = newText.replace(/<\/?[^>]+(>|$)/g, '')
    newText = newText.substring(0, descriptionLenght)

    return `${newText} ${newText.length === descriptionLenght ? ' ...' : ''}`
  }

  const getContractPeriodName = (
    duration: number | undefined,
    period: ContractPeriodTypes | undefined
  ) => {
    let durationNumber: number = 0
    let periodName: string = ''

    if (period !== undefined && duration !== undefined) {
      durationNumber = duration

      if (period === 'days') {
        periodName = t('day')
        if (duration > 1) {
          periodName = t('days')
        }
      }

      if (period === 'week') {
        periodName = t('week')

        if (duration > 1) {
          periodName = t('weeks')
        }
      }

      if (period === 'month') {
        periodName = t('month')

        if (duration > 1) {
          periodName = t('months')
        }
      }

      periodName = periodName.toLocaleLowerCase()
    }

    return `${durationNumber} ${periodName}`
  }

  return (
    <>
      <main>
        {/* Company logo */}
        {companyLogoUrl !== '' && (
          <div className='agencyLogo'>
            <img src={companyLogoUrl} alt='logo' />
          </div>
        )}

        {/* No jobs */}
        {jobsList.length === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {t('agency_didnt_have_any_open_positions')}
          </div>
        )}

        {/* Jobs List */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 50
          }}
        >
          {jobsList.map((job) => (
            <Paper
              key={job._id}
              style={{
                width: '100%',
                maxWidth: 600,
                padding: 20,
                marginBottom: 20
              }}
            >
              {/* Title */}
              <div className='jobTitle'>{job.title}</div>

              {/* Ad details */}
              <div className='jobDetails'>
                {/* ID */}
                <div>ID: {job.jobId}</div>

                {/* Published */}
                <div>
                  {t('published')}: {job.publishFrom}
                </div>

                {/* Published */}
                <div>
                  {t('valid')}: {job.publishUntil}
                </div>
              </div>

              {/* Short description */}
              <div className='jobDescription'>
                {reduceDecription(job.jobDescription)}
              </div>

              {/* Details */}
              <div className='jobDetails' style={{ fontWeight: 700 }}>
                {/* Country */}
                <div>
                  {t('location')}: {job.country}
                </div>

                {/* Contract duration */}
                <div>
                  {t('duration')}:{' '}
                  {getContractPeriodName(
                    job.contractDuration,
                    job.contractPeriod
                  )}
                </div>

                {/* Salary */}
                {job.salary.showSalary === true && (
                  <div>
                    {t('salary')}:{' '}
                    <span style={{ textTransform: 'uppercase' }}>
                      {job.salary.currency}
                    </span>{' '}
                    {job.salary.salaryMin} - {job.salary.salaryMax}{' '}
                    {t(
                      job.salary.salaryRate === 'per-hour'
                        ? 'per_hour'
                        : job.salary.salaryRate === 'per-month'
                        ? 'per_month'
                        : ''
                    )}
                  </div>
                )}
              </div>

              {/* Apply button */}
              <div style={{ float: 'right' }}>
                <Button
                  size='small'
                  color='orange'
                  variant='contained'
                  onClick={() =>
                    history.push(`/${agencyData.id}/key/jobs/${job.jobId}`)
                  }
                >
                  {t('view_job_s56s')}
                </Button>
              </div>
            </Paper>
          ))}
        </div>
      </main>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  agencyData: state.agencyDataRed.obj
})

export default connect(mapStateToProps)(JobsAds)
