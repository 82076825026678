const initialState = {
    bool: false
}

const loadAgenciesRed = (state = initialState, action) => {
    switch(action.type){
        case 'LOAD_AGENCIES' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export default loadAgenciesRed