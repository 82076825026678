import React from 'react'
import { connect } from 'react-redux'
import Header from '../../Modules/Header'

const MainTerm = ({ agencyData }) => {
  return (
    <div>
      <Header />
      <div className='terms'>
        <h2>General terms and conditions of online application form</h2>
        <p>
          I declare that the information given on this form is true and complete
          and that I have not knowingly withheld information. By accepting this
          I fully consent with processing my personal data including personal
          data provided within online application form to {agencyData.name},{' '}
          {agencyData.address} and their group of companies, subsidiaries and
          contracted 3rd parties which are legally authorised to process data on
          behalf of {agencyData.name}.
        </p>
        <p>
          Therefore I fully agree and give permission to {agencyData.name},{' '}
          {agencyData.address} and their group of companies, subsidiaries and 3rd parties which are
          legally authorised to act on behalf of {agencyData.name} to contact me
          by means of communication and send me any type of information related
          to my personal data; previous, current and future employment by post,
          e-mail or mobile phone.
        </p>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj
  }
}
export default connect(mapStateToProps)(MainTerm)
