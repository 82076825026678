import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import '../firebase'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { languages } from '../languages'
import { isAuth } from '../redux/actions/session/isAuth'
import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  MenuList,
  Toolbar,
  Button,
  Switch
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import logo from '../media/logo/seanor_white_logo.svg'
import { systemColors, systemStylingSettings } from '../globalVariables'

const useStyle = makeStyles({
  menuListRoot: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  switchBase: {
    '&.Mui-checked': {
      color: systemColors.white
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.white
    }
  },
  switchPrimary: {
    '&.Mui-checked': {
      color: systemColors.white
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.white
    }
  }
})

const Header = ({
  user,
  setIsAuth,
  isAuth,
  userExist,
  agencyUserData,
  agencyData
}) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const classes = useStyle()

  const [darkThemeStatus, setDarkThemeStatus] = useState(false)

  const [showMyIDBox, setShowMyIDBox] = useState(false)

  // Log out user and redirect to sign in page
  const logOut = () => {
    setIsAuth(false)
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push(`/${agencyData.id}`)
      })
      .catch((err) => {
        console.log('Sign out error', err)
      })
  }

  // Change frontend language
  const selectLanguage = (val) => {
    i18n.changeLanguage(val)
  }

  useEffect(() => {
    let shouldUpdate = true

    /** Check color mode */
    if (isAuth) {
      const darkModeStatus = window.localStorage.getItem('seanorDarkMode')

      if (darkModeStatus === 'true') {
        if (shouldUpdate) {
          document.body.classList.add('darkTheme')
          setDarkThemeStatus(true)
        }
      } else {
        if (shouldUpdate) {
          document.body.classList.remove('darkTheme')
          setDarkThemeStatus(false)
        }
      }
    } else {
      document.body.classList.remove('darkTheme')
    }

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, isAuth])

  // Send user to create new CV by user agency type
  const createCV = () => {
    if (agencyData.type === 'crew') {
      history.push(`/${agencyData.id}/dashboard/create-crew`)
    } else if (agencyData.type === 'key') {
      history.push(`/${agencyData.id}/dashboard/create-key`)
    }
  }

  const changeDarkTheme = () => {
    if (darkThemeStatus) {
      window.localStorage.setItem('seanorDarkMode', 'false')
      document.body.classList.remove('darkTheme')
      setDarkThemeStatus(false)
    } else {
      window.localStorage.setItem('seanorDarkMode', 'true')
      document.body.classList.add('darkTheme')
      setDarkThemeStatus(true)
    }
  }

  return (
    <div style={{ margin: '0 -50px' }}>
      <Dialog open={showMyIDBox} classes={{ paper: classes.dialogRoot }}>
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('myId')}
        </DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 20 }}>
            {t('yourId')}: {agencyUserData.uid}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowMyIDBox(false)
            }}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      <AppBar
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
        position='static'
      >
        {/* Logo */}
        <Toolbar style={{ width: 'fit-content' }}>
          <img className='header__logo' src={logo} alt={logo} />
        </Toolbar>

        <Toolbar style={{ width: 'fit-content' }}>
          <MenuList classes={{ root: classes.menuListRoot }}>
            {/*
             * Open positions link
             */}
            <MenuItem>
              <div className='header__elem'>
                <a
                  href={`/${agencyData.id}/key/jobs`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('open_positions')}
                </a>
              </div>
            </MenuItem>

            {/* Icons */}
            {isAuth && (
              <MenuItem>
                {/* Dark mode button */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {/* Sun icon */}
                  <FontAwesomeIcon
                    className='header__switchIcons'
                    icon={faSun}
                  />

                  {/* Switch */}
                  <Switch
                    size='small'
                    checked={darkThemeStatus}
                    onChange={() => changeDarkTheme()}
                    classes={{
                      switchBase: classes.switchBase,
                      colorPrimary: classes.switchPrimary
                    }}
                  />

                  {/* Moon icon */}
                  <FontAwesomeIcon
                    className='header__switchIcons'
                    style={{ marginLeft: 2 }}
                    icon={faMoon}
                  />
                </div>
              </MenuItem>
            )}

            {user !== undefined && isAuth ? (
              <MenuItem onClick={() => setShowMyIDBox(true)}>
                <div className='header__elem'>{t('myId')}</div>
              </MenuItem>
            ) : null}

            {/* email */}
            {user !== undefined && isAuth ? (
              <MenuItem>
                <div className='header__elem'>{user.email}</div>
              </MenuItem>
            ) : null}

            {/* Register new profile */}
            {!userExist && isAuth && (
              <MenuItem>
                <span className='header__links' onClick={() => createCV()}>
                  {t('makeYourCv')}
                </span>
              </MenuItem>
            )}

            {/* Log out */}
            {isAuth && (
              <MenuItem>
                <div
                  className='header__elem header__logout'
                  onClick={() => logOut()}
                >
                  {t('logOut')}
                </div>
              </MenuItem>
            )}

            {/* Login */}
            {!isAuth && (
              <MenuItem>
                <div
                  className='header__elem header__logout'
                  onClick={() => history.push(`/${agencyData.id}`)}
                >
                  {t('login')}
                </div>
              </MenuItem>
            )}

            {/* Language selctor */}
            {agencyData.type !== 'crew' && (
              <MenuItem>
                <div>
                  <select
                    className='header__language'
                    defaultValue={i18n.language}
                    onChange={(e) => selectLanguage(e.target.value)}
                  >
                    {languages.map((el, i) => {
                      return (
                        <option key={i} value={el}>
                          {el}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </MenuItem>
            )}
          </MenuList>
        </Toolbar>
      </AppBar>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.obj,
    isAuth: state.isAuthRed.bool,
    agencyUserData: state.agencyUserDataRed.obj,
    agencyData: state.agencyDataRed.obj
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsAuth: (bool) => dispatch(isAuth(bool))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
