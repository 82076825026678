import { useEffect, useState, ChangeEvent } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  TextField,
  Snackbar,
  Alert,
  DialogActions,
  Checkbox
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import '../../../styles/routes/recruitment/jobAd.scss'
import TextEditorReader from '../../../Components/TextFormatter/TextEditorReader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPhoneAlt,
  faShare
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { v4 as uuid } from 'uuid'
import { isAuth } from '../../../redux/actions/session/isAuth'
import ComponentLoader from '../../../Components/ComponentLoader'
import axios from 'axios'
import Loader from '../../../Components/Loader'
import { acceptedFileSize } from '../../../globalVariables'

const db = firebase.firestore()
const storage = firebase.storage()

interface Params {
  jobDocumentId: string
  agency: string
}

interface Props {
  agencyData: any
  setIsAuth: any
}

const JobAd = ({ agencyData, setIsAuth }: Props) => {
  const { jobDocumentId, agency } = useParams<Params>()
  const { t } = useTranslation()
  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(false)
  const [fullLoading, setFullLoading] = useState<boolean>(false)

  const [job, setJob] = useState<Job | null>(null)
  const [userAuth, setUserAuth] = useState<boolean>(false)
  const [userUid, setUserUid] = useState<string>('')

  const [showApplyDialog, setShowApplyDialog] = useState<boolean>(false)

  const [showLoginDialog, setShowLoginDialog] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loginError, setLoginError] = useState<boolean>(false)

  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>({
    status: false,
    duration: 5000,
    severity: 'success',
    message: ''
  })

  const [showApplyToPositionDialog, setShowApplyToPositionDialog] =
    useState<boolean>(false)
  const [applyFirstName, setApplyFirstName] = useState<string>('')
  const [applyFirstNameError, setApplyFirstNameError] = useState<boolean>(false)
  const [applyLastName, setApplyLastName] = useState<string>('')
  const [applyLastNameError, setApplyLastNameError] = useState<boolean>(false)
  const [applyPhone, setApplyPhone] = useState<string>('')
  const [applyPhoneError, setApplyPhoneError] = useState<boolean>(false)
  const [applyEmail, setApplyEmail] = useState<string>('')
  const [applyEmailError, setApplyEmailError] = useState<boolean>(false)
  const [mainAgeement, setMainAgeement] = useState<boolean>(false)
  const [gprsAgreement, setGprsAgreement] = useState<boolean>(false)
  const [mustAccept, setMustAccept] = useState<boolean>(false)
  const [file, setFile] = useState<string>('')
  const [fileName, setFileName] = useState<string>('')
  const [fileSizeError, setFileSizeError] = useState<boolean>(false)

  const [showCreateProfileMessage, setShowCreateProfileMessage] =
    useState<boolean>(false)

  const [companyLogoUrl, setCompanyLogoUrl] = useState<string>('')

  useEffect(() => {
    let shouldUpdate: boolean = true

    if (agencyData.name !== undefined) {
      /** Change document title */
      document.title = `${t('open_positions')} - ${agencyData.name}`

      /** Fech job details */
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('jobs')
        .where('data.jobId', '==', Number(jobDocumentId))
        .get()
        .then((snap) => {
          let array: Job[] = []
          snap.forEach((doc) => {
            const data: any = JSON.parse(JSON.stringify(doc.data()))
            array = [...array, data.data]
          })

          if (shouldUpdate && array.length > 0) {
            setJob(array[0])
          }

          /** Change document title */
          document.title = `${array[0].title} - ${agencyData.name}`

          /**
           * Update job ad view count
           */
          const newViewCount: number = array[0].seenAd + 1
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('jobs')
            .doc(array[0]._id)
            .update({ 'data.seenAd': newViewCount })
            .catch((error) => console.error(error))
        })
        .catch((error) => console.error(error))
    }

    /**
     * Get agency logo URL
     */
    if (agencyData.logoFileName !== undefined) {
      const logoFilename: string = agencyData.logoFileName

      if (logoFilename !== '' && shouldUpdate) {
        storage
          .ref()
          .child(`/public/agencies/${agencyData.id}/logo/${logoFilename}`)
          .getDownloadURL()
          .then((url) => {
            setCompanyLogoUrl(url)
          })
      }
    }

    /** Check is user logged in */
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (shouldUpdate) {
          setUserAuth(true)
          setUserUid(user.uid)
        } else {
          setUserAuth(false)
        }
      }
    })

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, jobDocumentId, t])

  const getContractPeriodName = (
    duration: number | undefined,
    period: ContractPeriodTypes | undefined
  ) => {
    let durationNumber: number = 0
    let periodName: string = ''

    if (period !== undefined && duration !== undefined) {
      durationNumber = duration

      if (period === 'days') {
        periodName = t('day')
        if (duration > 1) {
          periodName = t('days')
        }
      }

      if (period === 'week') {
        periodName = t('week')

        if (duration > 1) {
          periodName = t('weeks')
        }
      }

      if (period === 'month') {
        periodName = t('month')

        if (duration > 1) {
          periodName = t('months')
        }
      }

      periodName = periodName.toLocaleLowerCase()
    }

    return `${durationNumber} ${periodName}`
  }

  const openEmailWithShareLink = () => {
    window.open(
      `mailto:?body=https://e.seanor.eu/${agencyData.id}/key/jobs/${jobDocumentId}`
    )
  }

  const applyRegisteredUser = () => {
    if (!userAuth) {
      setShowLoginDialog(true)
    }

    /**
     * Apply user
     */
    if (userAuth && userUid !== '' && job !== null) {
      setLoading(true)
      /**
       * Find user's profile in agency employee db
       */
      db.collection('agencies')
        .doc(agency)
        .collection('employees')
        .where('userId', '==', userUid)
        .get()
        .then((snap) => {
          let employeesArray: KeyEmployeeProfile[] = []

          snap.forEach((document) => {
            let data: any = JSON.stringify(document.data())
            data = JSON.parse(data)
            employeesArray = [...employeesArray, data]
          })

          /**
           * If there is no profile finded in employee database
           */
          if (employeesArray.length === 0) {
            setLoading(false)
            setShowCreateProfileMessage(true)
          }

          /**
           * Add candidate to database
           */
          if (employeesArray.length > 0) {
            /**
             * Get agency agents and make list of evaluations
             */
            db.collection('users')
              .where('data.agencyId', '==', agency)
              .where('data.type', '==', 'keyagency')
              .get()
              .then(async (response) => {
                let agentsArray: RecruitmentEvaluation[] = []
                const evaluations: RecruitmentEvaluationCriteria[] | undefined =
                  agencyData.settings.companySettings.candidateCriteria

                response.forEach((el) => {
                  let data: any = JSON.stringify(el.data())
                  data = JSON.parse(data)

                  const agent: RecruitmentEvaluation = {
                    agentUid: data.data.uid,
                    agentName: `${data.data.name} ${data.data.lastName}`,
                    evaluation: evaluations !== undefined ? evaluations : [],
                    comment: '',
                    lastEdit: ''
                  }

                  agentsArray = [...agentsArray, agent]
                })

                const user: KeyEmployeeProfile = employeesArray[0]

                const id: string = uuid()

                const userApplyData: RecruitmentCandidate = {
                  _id: id,
                  name: `${user.personalData.firstName} ${user.personalData.lastName}`,
                  profileId: user.docId,
                  jobId: jobDocumentId,
                  jobTitle: job.title,
                  jobContractDuration: job.contractDuration,
                  jobContractPeriod: job.contractPeriod,
                  jobIdInAgency: job.jobId,
                  jobPublishFrom: job.publishFrom,
                  jobSalary: {
                    currency: job.salary.currency,
                    salaryMin: job.salary.salaryMin,

                    salaryMax: job.salary.salaryMax,

                    salaryRate: job.salary.salaryRate
                  },
                  actionStatus: 'applied',
                  profilePhoto: user.personalData.profilePhoto,
                  rating: 0,
                  closedEvaluation: false,
                  closeEvaluationDate: '',
                  stageStatus: '',
                  addedBy: {
                    name: '',
                    uid: ''
                  },
                  timestamp: new Date(),
                  mainPosition: user.personalData.mainPosition,
                  secondPosition: user.personalData.secondPosition,
                  evaluations: agentsArray
                }

                /**
                 * Add data to candidates database
                 */
                db.collection('agencies')
                  .doc(agencyData.id)
                  .collection('jobs_candidates')
                  .doc(id)
                  .set(userApplyData)
                  .then(async () => {
                    /**
                     * Increase candidate count in job listing
                     */
                    db.collection('agencies')
                      .doc(agencyData.id)
                      .collection('jobs')
                      .doc(jobDocumentId)
                      .get()
                      .then((snap) => {
                        const job: Job = snap.data()?.data

                        const newCandidatesAmount: number = job.candidates + 1

                        db.collection('agencies')
                          .doc(agencyData.id)
                          .collection('jobs')
                          .doc(job._id)
                          .update({
                            'data.candidates': newCandidatesAmount
                          })
                          .catch((error) => {
                            console.error(error)
                          })
                      })
                      .catch((error) => console.error(error))

                    setShowApplyDialog(false)
                    setLoading(false)
                    setSnackbarMessage({
                      status: true,
                      duration: 5000,
                      severity: 'success',
                      message: t('successfully_applied')
                    })
                  })
                  .catch((error) => {
                    console.error(error)
                  })
              })
              .catch((error) => console.error(error))
          }
        })
        .catch((error) => console.error(error))
    }
  }

  const closeLoginDialog = () => {
    setShowLoginDialog(false)
    setEmail('')
    setPassword('')
    setLoginError(false)
  }

  const login = () => {
    setLoading(true)

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (res) => {
        let resData: any = JSON.stringify(res.user)
        resData = JSON.parse(resData)
        const uid = resData.uid

        /**
         * Set up device id
         */
        const deviceId = uuid()
        /**
         * Add logged device id to local storage
         */
        window.localStorage.setItem('loggedDeviceId', deviceId)

        /**
         * Update logged device ID in DB
         */
        db.collection('users')
          .doc(uid)
          .update({
            'data.loggedDeviceId': deviceId
          })
          .catch((error) => {
            console.error(error)
          })

        /**
         * Check is user belongs to agency
         */
        db.collection('users')
          .doc(uid)
          .get()
          .then((res) => {
            setIsAuth(true)
            let resData: any = JSON.stringify(res.data())
            resData = JSON.parse(resData)

            if (resData.data.agencyId === agency) {
              closeLoginDialog()
            } else {
              firebase.auth().signOut()
            }
          })
          .catch((error) => console.error(error))
      })
      .catch((error) => {
        console.error(error)

        if (error.code === 'auth/user-disabled') {
          setLoginError(true)
        }
        setLoginError(true)

        setLoading(false)
      })
  }

  const closeApplyToPositionDialog = () => {
    setShowApplyToPositionDialog(false)
    setApplyFirstName('')
    setApplyFirstNameError(false)
    setApplyLastName('')
    setApplyLastNameError(false)
    setApplyPhone('')
    setApplyPhoneError(false)
    setApplyEmail('')
    setApplyEmailError(false)
    setMainAgeement(false)
    setGprsAgreement(false)
    setMustAccept(false)
    setFile('')
    setFileName('')
  }

  const sendCandidateDetailsToAgent = () => {
    if (
      applyFirstName !== '' &&
      applyLastName !== '' &&
      applyPhone !== '' &&
      applyEmail !== '' &&
      mainAgeement !== false &&
      gprsAgreement !== false
    ) {
      setApplyFirstNameError(false)
      setApplyLastNameError(false)
      setApplyPhoneError(false)
      setApplyEmailError(false)
      setMustAccept(false)
      setFullLoading(true)

      axios({
        method: 'POST',
        url: 'https://europe-west1-norspeda.cloudfunctions.net/candidateDetailsToAgent',
        data: {
          agentEmail: job?.email,
          firstName: applyFirstName,
          lastName: applyLastName,
          phone: applyPhone,
          email: applyEmail,
          positionTitle: job?.title,
          positionId: job?.jobId,
          file,
          fileName
        },
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          const status: string = response.data.status

          if (status === 'OK') {
            setShowApplyDialog(false)
            closeApplyToPositionDialog()
            setFullLoading(false)

            setSnackbarMessage({
              status: true,
              duration: 5000,
              severity: 'success',
              message: t('thank_you_we_will_contact_you_soon')
            })
          }

          if (status === 'ERROR') {
            console.error(response.data.error)
          }
        })
        .catch((error) => console.error(error))
    } else {
      if (applyFirstName === '') {
        setApplyFirstNameError(true)
      } else {
        setApplyFirstNameError(false)
      }
      if (applyLastName === '') {
        setApplyLastNameError(true)
      } else {
        setApplyLastNameError(false)
      }
      if (applyPhone === '') {
        setApplyPhoneError(true)
      } else {
        setApplyPhoneError(false)
      }
      if (applyEmail === '') {
        setApplyEmailError(true)
      } else {
        setApplyEmailError(false)
      }
      if (mainAgeement === false) {
        setMustAccept(true)
      } else {
        setMustAccept(false)
      }
      if (gprsAgreement === false) {
        setMustAccept(true)
      } else {
        setMustAccept(false)
      }
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      if (event.target.files[0].size > acceptedFileSize) {
        setFileSizeError(true)
        return
      }

      setFileSizeError(false)
      setFileName(event.target.files[0].name)
      const reader = new FileReader()
      reader.onloadend = () => {
        setFile(reader.result as string)
        // console.log(reader.result)
        // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  if (job === null) return <></>

  return (
    <>
      {fullLoading && <Loader />}

      {/*
       * Messeges
       */}
      <Snackbar
        open={snackbarMessage.status}
        autoHideDuration={snackbarMessage.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() =>
          setSnackbarMessage({ ...snackbarMessage, status: false })
        }
      >
        <Alert
          severity={snackbarMessage.severity}
          onClose={() =>
            setSnackbarMessage({ ...snackbarMessage, status: false })
          }
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>

      {/*
       * Create your profile message
       */}
      <Snackbar
        open={showCreateProfileMessage}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setShowCreateProfileMessage(false)}
      >
        <Alert
          severity='warning'
          onClose={() => setShowCreateProfileMessage(false)}
        >
          <div>{t('create_your_profile_first')}.</div>

          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}
          >
            <Button
              size='small'
              variant='contained'
              color='orange'
              onClick={() =>
                history.push(`/${agencyData.id}/dashboard/create-key`)
              }
            >
              {t('create')}
            </Button>
          </div>
        </Alert>
      </Snackbar>

      {/* Apply dialog */}
      <Dialog
        maxWidth='xs'
        open={showApplyDialog}
        onClose={() => setShowApplyDialog(false)}
      >
        {loading && <ComponentLoader />}

        <DialogContent>
          {/* Have account button */}
          {job.options.allowApplyRegisteredUsers && (
            <Button
              fullWidth
              size='small'
              variant='contained'
              color='orange'
              style={{ marginBottom: 20 }}
              onClick={() => applyRegisteredUser()}
            >
              {t('have_an_account_apply')}
            </Button>
          )}

          {/* Apply to this position button */}
          <Button
            fullWidth
            size='small'
            variant='contained'
            color='orange'
            style={{ marginBottom: 20 }}
            onClick={() => setShowApplyToPositionDialog(true)}
          >
            {t('apply_to_this_position')}
          </Button>

          {/* Create profile button */}
          {!userAuth && (
            <Button
              fullWidth
              size='small'
              variant='outlined'
              style={{ marginBottom: 20 }}
              onClick={() => history.push(`/${agencyData.id}/registration`)}
            >
              {t('create_your_profile')}
            </Button>
          )}

          {/* Cancel button */}
          <Button
            fullWidth
            size='small'
            onClick={() => setShowApplyDialog(false)}
          >
            {t('cancel')}
          </Button>
        </DialogContent>
      </Dialog>

      {/* Login dialog */}
      <Dialog open={showLoginDialog} onClose={() => closeLoginDialog()}>
        {loading && <ComponentLoader />}
        <DialogContent className='jobAd_login'>
          <h3>{t('login')}</h3>
          <Grid container spacing={3} style={{ width: 250 }}>
            {/* Email */}
            <Grid item xs={12}>
              <TextField
                type='email'
                size='small'
                fullWidth
                variant='outlined'
                label={t('email')}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={loginError}
              />
            </Grid>

            {/* Password */}
            <Grid item xs={12}>
              <TextField
                type='password'
                size='small'
                fullWidth
                variant='outlined'
                label={t('password')}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                error={loginError}
              />
            </Grid>

            {/* Login button */}
            <Grid item xs={12}>
              <Button
                fullWidth
                size='small'
                variant='contained'
                color='orange'
                onClick={() => login()}
              >
                {t('login')}
              </Button>
            </Grid>

            {/* Forgot password */}
            <Grid item xs={12}>
              <div className='jobAd_passwordReset'>
                <a href={`/${agencyData.id}/reset-password`}>
                  {t('resetPasswordLogin')}
                </a>
              </div>
            </Grid>

            {/* Cancel button */}
            <Grid item xs={12}>
              <Button fullWidth size='small' onClick={() => closeLoginDialog()}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Apply to position dialog */}
      <Dialog
        maxWidth='sm'
        open={showApplyToPositionDialog}
        onClose={() => closeApplyToPositionDialog()}
        fullWidth
      >
        {loading && <ComponentLoader />}

        <DialogContent>
          <Grid container spacing={2}>
            {/* First name */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant='outlined'
                label={t('firstName')}
                type='text'
                size='small'
                value={applyFirstName}
                onChange={(event) => setApplyFirstName(event.target.value)}
                error={applyFirstNameError}
              />
            </Grid>

            {/* Last name */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant='outlined'
                label={t('lastName')}
                type='text'
                size='small'
                value={applyLastName}
                onChange={(event) => setApplyLastName(event.target.value)}
                error={applyLastNameError}
              />
            </Grid>

            {/* Phone */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant='outlined'
                label={t('phone')}
                type='phone'
                size='small'
                value={applyPhone}
                onChange={(event) => setApplyPhone(event.target.value)}
                error={applyPhoneError}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant='outlined'
                label={t('email')}
                type='email'
                size='small'
                value={applyEmail}
                onChange={(event) => setApplyEmail(event.target.value)}
                error={applyEmailError}
              />
            </Grid>

            {/* File */}
            {job.options.allowUploadFiles && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label={t('file')}
                  type='file'
                  size='small'
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    accept: '.pdf, .jpg, .png, .doc, .docx'
                  }}
                  onChange={handleFileChange}
                  helperText={
                    !fileSizeError
                      ? t('accepted_formats_123as54')
                      : t('toBigFile')
                  }
                  error={fileSizeError}
                />
              </Grid>
            )}

            {/* Terms and conditions */}
            <Grid item xs={12}>
              <div>
                <div>
                  <Checkbox
                    required
                    color='electric'
                    onChange={() => setMainAgeement(!mainAgeement)}
                  />
                  <span className={mustAccept ? 'error' : ''}>
                    {t('iAcceptTerms')}{' '}
                    <a href={`/${agencyData.id}/general-terms-condition`}>
                      {t('privacyPolicy')}
                    </a>
                  </span>
                </div>
                <div>
                  <Checkbox
                    required
                    color='electric'
                    onChange={() => setGprsAgreement(!gprsAgreement)}
                  />
                  <span className={mustAccept ? 'error' : ''}>
                    {t('iAcceptTerms')}&nbsp;
                    <a href={`/${agencyData.id}/seanor-term`}>
                      {t('gprsPolicy')}
                    </a>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Send button */}
          <Button
            size='small'
            variant='contained'
            color='orange'
            onClick={() => sendCandidateDetailsToAgent()}
          >
            {t('send')}
          </Button>

          {/* Cancel button */}
          <Button size='small' onClick={() => closeApplyToPositionDialog()}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <main>
        {/* Back button */}
        <div style={{ marginTop: 50 }}>
          <Button
            size='small'
            variant='outlined'
            onClick={() => history.goBack()}
          >
            {t('<_back')}
          </Button>
        </div>

        {/* Job details */}
        <Grid
          container
          spacing={3}
          style={{ marginTop: 20, marginBottom: 100 }}
        >
          {/* Job */}
          <Grid item xs={12} md={8} className='jobAd'>
            <Paper style={{ padding: 20 }}>
              {/* Title */}
              <div className='jobTitle'>{job.title}</div>

              {/* Ad details */}
              <div className='jobDetails'>
                {/* ID */}
                <div>ID: {job.jobId}</div>

                {/* Published */}
                <div>
                  {t('published')}: {job.publishFrom}
                </div>

                {/* Published */}
                <div>
                  {t('valid')}: {job.publishUntil}
                </div>
              </div>

              {/* Details */}
              <Grid container spacing={1} className='details'>
                {/* Employment type */}
                <Grid item>
                  <Chip
                    label={
                      <div>
                        {' '}
                        {t('employment_type')}:{' '}
                        {job.employmentType === 'full-time'
                          ? t('full_time')
                          : job.employmentType === 'part-time'
                          ? t('part_time')
                          : ''}
                      </div>
                    }
                    variant='outlined'
                  />
                </Grid>

                {/* Remote job */}
                <Grid item>
                  <Chip
                    variant='outlined'
                    label={
                      <div>
                        {t('remote_job')}: {job.remoteJob ? t('yes') : t('no')}
                      </div>
                    }
                  />
                </Grid>

                {/* Country */}
                <Grid item>
                  <Chip
                    variant='outlined'
                    label={
                      <div>
                        {t('location')}: {job.country}
                      </div>
                    }
                  />
                </Grid>

                {/* Contract duration */}
                <Grid item>
                  <Chip
                    variant='outlined'
                    label={
                      <div>
                        {t('duration')}:{' '}
                        {getContractPeriodName(
                          job.contractDuration,
                          job.contractPeriod
                        )}
                      </div>
                    }
                  />
                </Grid>

                {/* Salary */}
                {job.salary.showSalary === true && (
                  <Grid item>
                    <Chip
                      variant='outlined'
                      label={
                        <div>
                          {t('salary')}:{' '}
                          <span style={{ textTransform: 'uppercase' }}>
                            {job.salary.currency}
                          </span>{' '}
                          {job.salary.salaryMin} - {job.salary.salaryMax}{' '}
                          {t(
                            job.salary.salaryRate === 'per-hour'
                              ? 'per_hour'
                              : job.salary.salaryRate === 'per-month'
                              ? 'per_month'
                              : ''
                          )}
                        </div>
                      }
                    />
                  </Grid>
                )}
              </Grid>

              {/* Job description */}
              <h3>{t('job_description')}</h3>

              <TextEditorReader content={job.jobDescription} />

              {/* Job requirements */}
              <h3>{t('job_requirements')}</h3>

              <TextEditorReader content={job.jobRequirements} />
            </Paper>
          </Grid>

          {/* Agent */}
          <Grid item xs={12} md={4} className='jobAgent'>
            <Paper style={{ padding: 20 }}>
              <h3 className='agentTitle'>
                {t('do_you_have_any_questions_sd4fg')}
              </h3>

              {/* Company logo */}
              {companyLogoUrl !== '' && (
                <div className='agencyLogo'>
                  <img src={companyLogoUrl} alt='logo' />
                </div>
              )}

              <h4>{t('agent')}</h4>

              {/* Agent name */}
              <h3>
                {job.projectManager?.firstName} {job.projectManager?.lastName}
              </h3>

              {/* Contacts */}
              <div className='jobContacts'>
                {/* Phone numeber */}
                <div className='block'>
                  {/* Icon */}
                  <div className='jobContacts_icon'>
                    <FontAwesomeIcon icon={faPhoneAlt as IconProp} />
                  </div>

                  {/* Phone number */}
                  <div>
                    <a href={`tel:${job.phoneNumber}`}>{job.phoneNumber}</a>
                  </div>
                </div>

                {/* Email */}
                <div className='block'>
                  {/* Icon */}
                  <div className='jobContacts_icon'>
                    <FontAwesomeIcon icon={faEnvelope as IconProp} />
                  </div>

                  {/* Email */}
                  <div>
                    <a href={`mailto:${job.email}`}>{job.email}</a>
                  </div>
                </div>
              </div>

              {/* Buttons */}
              <div className='jobAgent_buttons'>
                {/* Share */}
                <Button
                  size='small'
                  fullWidth
                  style={{ marginBottom: 10 }}
                  startIcon={<FontAwesomeIcon icon={faShare as IconProp} />}
                  onClick={() => openEmailWithShareLink()}
                >
                  {t('share')}
                </Button>

                {/* Apply */}
                <Button
                  size='small'
                  fullWidth
                  variant='contained'
                  color='orange'
                  onClick={() => setShowApplyDialog(true)}
                >
                  {t('apply')}
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </main>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    agencyData: state.agencyDataRed.obj
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setIsAuth: (bool: boolean) => dispatch(isAuth(bool))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobAd)
