import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import AgencyMenu from '../../Components/AgencyMenu'
import Headline from '../../Components/Headline'
import Loader from '../../Components/Loader'
import firebase from 'firebase/app'
import {
  Grid,
  TextField,
  MenuItem,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  systemColors,
  systemStylingSettings,
  userStatus as userStatusArr
} from '../../globalVariables'
// import Button from '../../Components/Button'
import { v4 as uuid } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

const db = firebase.firestore()

const useStyles = makeStyles({
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  selectLabel: {
    marginTop: '-2px'
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  tableHeader: {
    color: systemColors.darkBlue,
    fontSize: 16
  },
  buttonRoot: {
    color: '#ffffff !important'
  },
  expandIcon: {
    fontSize: 15
  },
  accordionRoot: {
    boxShadow: 'none',
    border: `1px solid ${systemColors.darkBlue}`,
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background,
    marginTop: 50
  }
})

const AddUser = ({ agencyData }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const userUID = firebase.auth().currentUser.uid
  const classes = useStyles()
  const userTypes = ['keyagency', 'crewagency']
  const accountType = ['keyagency', 'crewagency']

  // Change document title
  document.title = `${t('addUser')} - Seanor`

  const [loading, setLoading] = useState(false)
  const [refreshData, setRefreshData] = useState(false)
  const [loadedData, setLoadedData] = useState(false)

  const [userRole, setUserRole] = useState(null)
  const [agenciesList, setAgenciesList] = useState([])
  const [usersList, setUsersList] = useState([])
  const [message, setMessage] = useState({
    status: false,
    duration: 3000,
    severity: 'success',
    message: ''
  })

  // User input states
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [userType, setUserType] = useState('')
  const [userStatus, setUserStatus] = useState('')
  const [agency, setAgency] = useState('')
  const [password, setPassword] = useState('')

  // Input errors states
  const [firstNameErr, setFirstNameErr] = useState(false)
  const [lastNameErr, setLastNameErr] = useState(false)
  const [phoneErr, setPhoneErr] = useState(false)
  const [emailErr, setEmailErr] = useState(false)
  const [userTypeErr, setUserTypeErr] = useState(false)
  const [userStatusErr, setUserStatusErr] = useState(false)
  const [agencyErr, setAgencyErr] = useState(false)
  const [userRoleInSystem, setUserRoleInSystem] = useState('agency_admin')

  const [showAddedUserDetailsMsg, setShowAddedUserDetailsMsg] = useState(false)

  const [editUserBlock, setEditUserBlock] = useState(false)

  // Edit user input states
  const [editTitleName, setEditTitleName] = useState('')
  const [editFirstName, setEditFirstName] = useState('')
  const [editLastName, setEditLastName] = useState('')
  const [editPhone, setEditPhone] = useState('')
  const [editEmail, setEditEmail] = useState('')
  const [editUserType, setEditUserType] = useState('')
  const [editUserStatus, setEditUserStatus] = useState('')
  const [editAgency, setEditAgency] = useState('')
  const [editUserUID, setEditUserUID] = useState('')
  const [editUserRoleInSystem, setEditUserRoleInSystem] = useState('')

  // Edit user error states
  const [editFirstNameError, setEditFirstNameError] = useState(false)
  const [editLastNameError, setEditLastNameError] = useState(false)
  const [editPhoneError, setEditPhoneError] = useState(false)
  const [editUserStatusError, setEditUserStatusError] = useState(false)

  const [showDeleteUserWarningBlock, setShowDeleteUserWarningBlock] =
    useState(false)

  const [filterFirstName, setFilterFirstName] = useState('')
  const [filterLastName, setFilterLastName] = useState('')
  const [filterAgency, setFilterAgency] = useState('')
  const [filterStatus, setfilterStatus] = useState('')
  const [filterAgencyType, setfilterAgencyType] = useState('')

  useEffect(() => {
    const getUserData = () => {
      const getUserRole = new Promise((resolve) => {
        db.collection('users')
          .doc(userUID)
          .get()
          .then((res) => {
            let data = JSON.stringify(res.data())
            data = JSON.parse(data)
            setUserRole(data.data.userRole)
            resolve()
          })
          .catch((error) => {
            console.log("Can't get user role", error)
            resolve()
          })
      })

      const getAgenciesList = new Promise((resolve) => {
        db.collection('agencies')
          .get()
          .then((res) => {
            const array = []
            res.forEach((el) => {
              array.push({
                id: el.data().data.id,
                name: el.data().data.name
              })
            })
            setAgenciesList(array)
            resolve()
          })
          .catch((error) => {
            console.log("Can't get agencies list", error)
            resolve()
          })
      })

      const getAgenciesUsersList = new Promise((resolve) => {
        db.collection('users')
          .where('data.type', 'in', ['crewagency', 'keyagency'])
          .orderBy('data.timestamp', 'desc')
          .get()
          .then((snap) => {
            let agenciesUsersArray = []
            snap.forEach((el) => {
              let data = JSON.stringify(el.data())
              data = JSON.parse(data)
              agenciesUsersArray = [...agenciesUsersArray, data.data]
            })
            setUsersList(agenciesUsersArray)
            resolve()
          })
          .catch((error) => {
            console.log("Can't get users list", error)
            resolve()
          })
      })

      Promise.all([getUserRole, getAgenciesList, getAgenciesUsersList]).then(
        () => setLoadedData(true)
      )
    }
    return getUserData()
  }, [userUID, refreshData])

  const addUser = () => {
    setLoading(true)
    if (
      firstName !== '' &&
      lastName !== '' &&
      phone !== '' &&
      email !== '' &&
      userType !== '' &&
      userStatus !== '' &&
      agency !== ''
    ) {
      const newPassword = uuid()
      setPassword(newPassword)

      // Check is user is system or agency administration
      let adminStatus = false

      if (
        userRoleInSystem === 'super_admin' ||
        userRoleInSystem === 'agency_admin'
      ) {
        adminStatus = true
      }

      // Initiate new app
      const secondApp = firebase.initializeApp(
        {
          apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
          authDomain: 'norspeda.firebaseapp.com',
          databaseURL: 'https://norspeda.firebaseio.com'
        },
        'Secondary'
      )

      secondApp
        .auth()
        .createUserWithEmailAndPassword(email, newPassword)
        .then((res) => {
          db.collection('users')
            .doc(res.user.uid)
            .set({
              data: {
                uid: res.user.uid,
                agencyId: agency.id,
                canEditCV: true,
                email,
                lastName,
                name: firstName,
                type: userType,
                userRole: userRoleInSystem,
                userStatus,
                phoneNumber: phone,
                searchTags: {
                  firstName: firstName.toLowerCase(),
                  lastName: lastName.toLowerCase()
                },
                settings: {
                  adminPermissions: {
                    dashboard: {
                      allowLastViewedCompaniesKEY: adminStatus,
                      allowLastViewedProfilesKEY: adminStatus,
                      allowLastViewedCompaniesCREW: adminStatus,
                      allowLastViewedProfilesCREW: adminStatus
                    },
                    agencyMenu: {
                      allowCompanyStatisticsKEY: adminStatus,
                      allowCompanyStatisticsCREW: adminStatus
                    }
                  },
                  userSettings: {
                    dashboard: {
                      allowLastViewedCompaniesKEY: true,
                      allowLastViewedProfilesKEY: true,
                      allowLastAgencyApplicantsKEY: true,
                      allowLastOnlineApplicantsKEY: true,
                      allowLastViewedCompaniesCREW: true,
                      allowLastViewedProfilesCREW: true,
                      allowLastAgencyApplicantsCREW: true,
                      allowLastOnlineApplicantsCREW: true
                    }
                  }
                },
                lastViewedCompanies: [],
                lastViewedCompaniesCREW: [],
                lastViewedProfiles: [],
                lastViewedProfilesCREW: [],
                taggedProfiles: [],
                taggedProfilesCREW: [],
                timestamp: new Date()
              }
            })
            .then(() => {
              setRefreshData(!refreshData)
              setLoading(false)
              setShowAddedUserDetailsMsg(true)
            })
            .catch((err) => {
              console.log("Can't add user details to database", err)
              setLoading(false)
            })
          secondApp.auth().signOut()
          secondApp.delete()
        })
        .catch((err) => {
          if (err.code === 'auth/email-already-in-use') {
            setMessage({
              status: true,
              duration: 3000,
              severity: 'warning',
              message: t('emailTaken')
            })
          } else if (err.code === 'auth/invalid-email') {
            setMessage({
              status: true,
              duration: 3000,
              severity: 'warning',
              message: t('badEmailFormat')
            })
          }
          console.log("Can't create new user", err)
          secondApp.delete()
          setLoading(false)
        })

      // Reset error states
      // setFirstName('')
      // setLastName('')
      // setPhone('')
      // setEmail('')
      // setUserType('')
      // setUserStatus('')
      // setAgency('')
      setFirstNameErr(false)
      setLastNameErr(false)
      setPhoneErr(false)
      setEmailErr(false)
      setUserTypeErr(false)
      setUserStatusErr(false)
      setAgencyErr(false)
    } else {
      setLoading(false)
      if (firstName === '') {
        setFirstNameErr(true)
      } else {
        setFirstNameErr(false)
      }
      if (lastName === '') {
        setLastNameErr(true)
      } else {
        setLastNameErr(false)
      }
      if (phone === '') {
        setPhoneErr(true)
      } else {
        setPhoneErr(false)
      }
      if (email === '') {
        setEmailErr(true)
      } else {
        setEmailErr(false)
      }
      if (userType === '') {
        setUserTypeErr(true)
      } else {
        setUserTypeErr(false)
      }
      if (userStatus === '') {
        setUserStatusErr(true)
      } else {
        setUserStatusErr(false)
      }
      if (agency === '') {
        setAgencyErr(true)
      } else {
        setAgencyErr(false)
      }

      setMessage({
        status: true,
        duration: 5000,
        severity: 'warning',
        message: t('mustFillAllFields')
      })
    }
  }

  const clearInputs = () => {
    setFirstName('')
    setLastName('')
    setPhone('')
    setEmail('')
    setUserType('')
    setUserStatus('')
    setAgency('')
    setShowAddedUserDetailsMsg(false)
  }

  const findAgencyName = (agencyId) => {
    const agency = agenciesList.find((el) => el.id === agencyId)
    if (agency !== undefined) {
      return agency.name
    }
  }

  const updateUser = async () => {
    if (
      editFirstName !== '' &&
      editLastName !== '' &&
      editPhone !== '' &&
      editPhone !== undefined &&
      editUserStatus !== '' &&
      editUserStatus !== undefined
    ) {
      setLoading(true)
      await db
        .collection('users')
        .doc(editUserUID)
        .update({
          'data.name': editFirstName,
          'data.lastName': editLastName,
          'data.phoneNumber': editPhone,
          'data.userStatus': editUserStatus,
          'data.searchTags.firstName': editFirstName.toLowerCase(),
          'data.searchTags.lastName': editLastName.toLowerCase(),
          'data.userRole': editUserRoleInSystem
        })
        .then(() => {
          axios({
            method: 'post',
            url: 'https://europe-west1-norspeda.cloudfunctions.net/disableUser',
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              userUid: editUserUID,
              disableStatus: editUserStatus === 'disabled' ? true : false
            }
          })
            .then((response) => {
              const status = response.data.status
              if (status === 'ERROR') {
                console.error(response.data.error)
                setMessage({
                  status: true,
                  duration: 5000,
                  severity: 'error',
                  message: t('userStatusWasntChanged')
                })
              }
            })
            .catch((error) => {
              console.error(error)
            })

          setRefreshData(!refreshData)
          setEditUserBlock(false)
          setEditTitleName('')
          setEditFirstName('')
          setEditLastName('')
          setEditPhone('')
          setEditEmail('')
          setEditUserType('')
          setEditUserStatus('')
          setEditAgency('')
          setEditUserUID('')
          setEditFirstNameError(false)
          setEditLastNameError(false)
          setEditPhoneError(false)
          setEditUserStatusError(false)
          setEditUserUID('')
          setEditUserRoleInSystem('')
          setLoading(false)
          setMessage({
            status: true,
            duration: 2000,
            severity: 'success',
            message: t('userUpdated')
          })
        })
    } else {
      if (editFirstName === '') {
        setEditFirstNameError(true)
      } else {
        setEditFirstNameError(false)
      }

      if (editLastName === '') {
        setEditLastNameError(true)
      } else {
        setEditLastNameError(false)
      }

      if (editPhone === '' || editPhone === undefined) {
        setEditPhoneError(true)
      } else {
        setEditPhoneError(false)
      }

      if (editUserStatus === '' || editUserStatus === undefined) {
        setEditUserStatusError(true)
      } else {
        setEditUserStatusError(false)
      }
      setMessage({
        status: true,
        duration: 3000,
        severity: 'warning',
        message: t('mustfillAllrequiredFiels')
      })
    }
  }

  const deleteUser = async () => {
    setLoading(true)
    const deleteUserFromsSyste = new Promise((resolve) => {
      axios({
        method: 'DELETE',
        url: 'https://europe-west1-norspeda.cloudfunctions.net/deleteUser',
        data: {
          userUID: editUserUID
        }
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => resolve(error))
    })
    Promise.all([deleteUserFromsSyste]).then((response) => {
      setLoading(false)
      if (response[0].data.status === 'deleted') {
        setShowDeleteUserWarningBlock(false)
        setEditUserBlock(false)
        setRefreshData(!refreshData)
        setMessage({
          status: true,
          duration: 3000,
          severity: 'success',
          message: t('userDeletedFromSystem')
        })
      } else {
        console.log("Can't delete user", response[0].data.error)
      }
    })
  }

  const filter = (filterType) => {
    let query = db.collection('users')
    if (filterType === 'name') {
      if (filterFirstName !== '') {
        query = query
          .where('data.searchTags.firstName', '>=', filterFirstName)
          .where('data.searchTags.firstName', '<=', filterFirstName + '~')
      }
      if (filterLastName !== '') {
        query = query
          .where('data.searchTags.lastName', '>=', filterLastName)
          .where('data.searchTags.lastName', '<=', filterLastName + '~')
      }
    } else if (filterType === 'other') {
      if (filterAgency !== '') {
        query = query.where('data.agencyId', '==', filterAgency.id)
      }
      if (filterStatus !== '') {
        query = query.where('data.userStatus', '==', filterStatus)
      }
      if (filterAgencyType !== '') {
        query = query.where('data.type', '==', filterAgencyType)
      }
    }

    query
      .get()
      .then((snap) => {
        let agenciesUsersArray = []
        snap.forEach((el) => {
          let data = JSON.stringify(el.data())
          data = JSON.parse(data)
          if (data.data.type !== 'employee') {
            agenciesUsersArray = [...agenciesUsersArray, data.data]
          }
        })
        setUsersList(agenciesUsersArray)
      })
      .catch((error) => {
        console.log("Can't filter users list", error)
      })
  }

  const resetFiltration = () => {
    setRefreshData(!refreshData)
    setFilterFirstName('')
    setFilterLastName('')
    setFilterAgency('')
    setfilterStatus('')
    setfilterAgencyType('')
  }

  // Send login information to user
  const sendDetailsToUser = () => {
    setLoading(true)

    axios({
      method: 'POST',
      url: 'https://europe-west1-norspeda.cloudfunctions.net/sendNewAgencyUserCredentials',
      data: {
        firstName,
        agencyId: agency.id,
        email,
        password
      }
    })
      .then((response) => {
        if (response.data.status === 'error') {
          setLoading(false)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'warning',
            message: t('thereIsProblemToSendLoginInformation')
          })
          console.log(
            "Can't send login information to user",
            response.data.error
          )
        } else if (response.data.status === 'sended') {
          clearInputs()
          setLoading(false)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'success',
            message: t('loginInformationSendedSuccessfully')
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log("Can't send login information request", error)
      })
  }

  if (!loadedData) {
    return <Loader />
  }

  if (userRole === 'super_admin') {
    return (
      <>
        {loading && <Loader />}

        <AgencyMenu type={location.state.menuType} />

        <Headline text={t('addUser')} />

        {/* warnings */}
        <Snackbar
          open={message.status}
          autoHideDuration={message.duration}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={() => setMessage({ ...message, status: false })}
        >
          <Alert
            severity={message.severity}
            onClose={() => setMessage({ ...message, status: false })}
          >
            {message.message}
          </Alert>
        </Snackbar>

        {/* User details message */}
        <Dialog
          open={showAddedUserDetailsMsg}
          classes={{ paper: classes.dialogRoot }}
        >
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {t('userAddedSuccessfully')}
          </DialogTitle>

          <DialogContent>
            <div>{t('pleaseSendLoginInfo')}:</div>

            <div>
              <b>{t('email')}:</b> {email}
            </div>

            <div>
              <b>{t('password')}:</b> {password}
            </div>

            <div style={{ marginTop: 10 }}>
              {t('pleaseNoteMessageShowOnlyOnes')}
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => clearInputs()}>{t('continue')}</Button>
            <Button onClick={() => sendDetailsToUser()}>
              {t('sendToUser')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit user */}
        <Dialog
          open={editUserBlock}
          classes={{ paper: classes.dialogRoot }}
          fullWidth
          maxWidth='md'
        >
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {t('edit')} {editTitleName}
          </DialogTitle>

          <DialogContent>
            {/* Delete user button */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 10
              }}
            >
              <div
                style={{
                  color: systemColors.errorColor,
                  fontSize: 12,
                  cursor: 'pointer'
                }}
                onClick={() => setShowDeleteUserWarningBlock(true)}
              >
                {t('deleteUser')}
              </div>
            </div>

            <Grid container spacing={2} style={{ marginTop: 25 }}>
              {/* First name */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('firstName')}
                  value={editFirstName}
                  onChange={(e) => setEditFirstName(e.target.value)}
                  fullWidth
                  error={editFirstNameError}
                />
              </Grid>

              {/* Last name */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='text'
                  label={t('lastName')}
                  value={editLastName}
                  onChange={(e) => setEditLastName(e.target.value)}
                  fullWidth
                  error={editLastNameError}
                />
              </Grid>

              {/* Phone number */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='tel'
                  label={t('phone')}
                  placeholder='+370 ...'
                  value={editPhone}
                  onChange={(e) => setEditPhone(e.target.value)}
                  fullWidth
                  error={editPhoneError}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  disabled
                  size='small'
                  variant='outlined'
                  type='email'
                  label={t('email')}
                  value={editEmail}
                  fullWidth
                />
              </Grid>

              {/* Type */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  select
                  disabled
                  variant='outlined'
                  fullWidth
                  label={t('userType')}
                  size='small'
                  value={editUserType}
                >
                  {userTypes.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Status */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  select
                  required
                  variant='outlined'
                  fullWidth
                  label={t('userStatus')}
                  size='small'
                  value={editUserStatus}
                  onChange={(e) => setEditUserStatus(e.target.value)}
                  error={editUserStatusError}
                >
                  {userStatusArr.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Agency Id */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  disabled
                  variant='outlined'
                  fullWidth
                  label={t('agencyId')}
                  size='small'
                  value={editAgency}
                />
              </Grid>

              {/* User role */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  select
                  required
                  variant='outlined'
                  fullWidth
                  label={t('userRole')}
                  size='small'
                  value={editUserRoleInSystem}
                  onChange={(e) => setEditUserRoleInSystem(e.target.value)}
                  error={agencyErr}
                >
                  <MenuItem value='agency_admin'>
                    {t('agencyAdministrator')}
                  </MenuItem>
                  <MenuItem value='agent'>{t('agent')}</MenuItem>
                  <MenuItem value='super_admin'>{t('superAdmin')}</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            {/* Update */}
            <Button
              variant='contained'
              size='small'
              classes={{
                endIcon: classes.buttonEndIcon,
                root: classes.buttonRoot,
                contained: classes.buttonContained
              }}
              onClick={() => updateUser()}
            >
              {t('update')}
            </Button>

            {/* Cancel */}
            <Button
              onClick={() => {
                setEditUserBlock(false)
                setEditTitleName('')
                setEditFirstName('')
                setEditLastName('')
                setEditPhone('')
                setEditEmail('')
                setEditUserType('')
                setEditUserStatus('')
                setEditAgency('')
                setEditUserUID('')
                setEditUserRoleInSystem('')
              }}
            >
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete agency warning message */}
        <Dialog
          open={showDeleteUserWarningBlock}
          classes={{ paper: classes.dialogRoot }}
        >
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {t('areYouShure')}
          </DialogTitle>
          <DialogContent>
            <div style={{ margin: '20px 0' }}>
              {t('doYouRealyWantToDeleteThisUser')}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteUser()}>{t('deleteUser')}</Button>
            <Button
              onClick={() => {
                setShowDeleteUserWarningBlock(false)
              }}
            >
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add user */}
        <div style={{ marginTop: 50 }}>
          <Grid container spacing={2}>
            {/* First name */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                required
                size='small'
                variant='outlined'
                type='text'
                label={t('firstName')}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                error={firstNameErr}
              />
            </Grid>

            {/* Last name */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                required
                size='small'
                variant='outlined'
                type='text'
                label={t('lastName')}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                error={lastNameErr}
              />
            </Grid>

            {/* Phone number */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                required
                size='small'
                variant='outlined'
                type='tel'
                label={t('phone')}
                placeholder='+370 ...'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                error={phoneErr}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                required
                size='small'
                variant='outlined'
                type='email'
                label={t('email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                error={emailErr}
              />
            </Grid>

            {/* User type */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                required
                variant='outlined'
                fullWidth
                label={t('userType')}
                size='small'
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                error={userTypeErr}
              >
                {userTypes.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* User status */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                required
                variant='outlined'
                fullWidth
                label={t('userStatus')}
                size='small'
                value={userStatus}
                onChange={(e) => setUserStatus(e.target.value)}
                error={userStatusErr}
              >
                {userStatusArr.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {t(el)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Agency */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                required
                variant='outlined'
                fullWidth
                label={t('agency')}
                size='small'
                value={agency}
                onChange={(e) => setAgency(e.target.value)}
                error={agencyErr}
              >
                {agenciesList.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {el.name} ({el.id})
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* User role */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                required
                variant='outlined'
                fullWidth
                label={t('userRole')}
                size='small'
                value={userRoleInSystem}
                onChange={(e) => setUserRoleInSystem(e.target.value)}
                error={agencyErr}
              >
                <MenuItem value='agency_admin'>
                  {t('agencyAdministrator')}
                </MenuItem>
                <MenuItem value='agent'>{t('agent')}</MenuItem>
                <MenuItem value='super_admin'>{t('superAdmin')}</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          {/* Save button */}
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => addUser()}
              >
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </div>

        {/* Filtering */}
        <Accordion classes={{ root: classes.accordionRoot }}>
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
            classes={{ expandIcon: classes.expandIcon }}
          >
            {t('filter')}
          </AccordionSummary>

          <AccordionDetails>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              {/* Filter by name */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginBottom: 20
                }}
              >
                {/* First name */}
                <div style={{ margin: 5 }}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('firstName')}
                    value={filterFirstName}
                    onChange={(e) =>
                      setFilterFirstName(e.target.value.toLowerCase())
                    }
                  />
                </div>

                {/* Last name */}
                <div style={{ margin: 5 }}>
                  <TextField
                    size='small'
                    variant='outlined'
                    type='text'
                    label={t('lastName')}
                    value={filterLastName}
                    onChange={(e) =>
                      setFilterLastName(e.target.value.toLowerCase())
                    }
                  />
                </div>

                {/* Filter button */}
                <div
                  style={{ margin: 5, display: 'flex', alignItems: 'center' }}
                >
                  <Button
                    variant='contained'
                    size='small'
                    color='orange'
                    onClick={() => filter('name')}
                  >
                    {t('filter')}
                  </Button>
                </div>

                {/* Reset button */}
                <div
                  style={{ margin: 5, display: 'flex', alignItems: 'center' }}
                >
                  <Button size='small' onClick={() => resetFiltration()}>
                    {t('reset')}
                  </Button>
                </div>
              </div>

              {/* Filter by parameters */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginBottom: 20
                }}
              >
                {/* Agency */}
                <div style={{ margin: 5 }}>
                  <TextField
                    select
                    style={{ minWidth: 250 }}
                    variant='outlined'
                    fullWidth
                    label={t('agency')}
                    size='small'
                    value={filterAgency}
                    onChange={(e) => setFilterAgency(e.target.value)}
                  >
                    {agenciesList.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {el.name} ({el.id})
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {/* User status */}
                <div style={{ margin: 5 }}>
                  <TextField
                    select
                    style={{ minWidth: 150 }}
                    variant='outlined'
                    fullWidth
                    label={t('userStatus')}
                    size='small'
                    value={filterStatus}
                    onChange={(e) => setfilterStatus(e.target.value)}
                  >
                    {userStatusArr.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {/* Agency type */}
                <div style={{ margin: 5 }}>
                  <TextField
                    select
                    style={{ minWidth: 150 }}
                    variant='outlined'
                    fullWidth
                    label={t('type')}
                    size='small'
                    value={filterAgencyType}
                    onChange={(e) => setfilterAgencyType(e.target.value)}
                  >
                    {accountType.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {/* Filter button */}
                <div
                  style={{ margin: 5, display: 'flex', alignItems: 'center' }}
                >
                  <Button
                    variant='contained'
                    size='small'
                    color='orange'
                    onClick={() => filter('other')}
                  >
                    {t('filter')}
                  </Button>
                </div>

                {/* Reset button */}
                <div
                  style={{ margin: 5, display: 'flex', alignItems: 'center' }}
                >
                  <Button
                    size='small'
                    onClick={() => {
                      resetFiltration()
                    }}
                  >
                    {t('reset')}
                  </Button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {/* Users list */}
        <Table style={{ marginTop: 25 }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader} />
              <TableCell className={classes.tableHeader}>
                {t('firstName')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('lastName')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('phone')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('email')}
              </TableCell>
              <TableCell className={classes.tableHeader}>{t('type')}</TableCell>

              {/*
               * Status
               */}
              <TableCell className={classes.tableHeader}>
                {t('userStatus')}
              </TableCell>

              <TableCell className={classes.tableHeader}>
                {t('agency')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('userRole')}
              </TableCell>
              <TableCell className={classes.tableHeader} />
            </TableRow>
          </TableHead>

          <TableBody>
            {usersList.map((el, i) => (
              <TableRow key={el.uid} hover>
                {/* No. */}
                <TableCell>{i + 1}</TableCell>

                {/* Name */}
                <TableCell>{el.name}</TableCell>

                {/* Last name */}
                <TableCell>{el.lastName}</TableCell>

                {/* Phone number */}
                <TableCell>{el.phoneNumber}</TableCell>

                {/* Email */}
                <TableCell>{el.email}</TableCell>

                {/* Type */}
                <TableCell>{el.type}</TableCell>

                {/* Status */}
                <TableCell>{t(el.userStatus)}</TableCell>

                {/* Agency */}
                <TableCell>{findAgencyName(el.agencyId)}</TableCell>

                {/* User role */}
                <TableCell>
                  {el.userRole === 'agency_admin'
                    ? t('agencyAdministrator')
                    : el.userRole === 'agent'
                    ? t('agent')
                    : el.userRole === 'super_admin'
                    ? t('superAdmin')
                    : null}
                </TableCell>

                {/* Icons */}
                <TableCell>
                  <Tooltip title={t('edit')} placement='top'>
                    <div
                      className='getProjectsEmployeesList__editButton'
                      onClick={() => {
                        setEditTitleName(`${el.name} ${el.lastName}`)
                        setEditFirstName(el.name)
                        setEditLastName(el.lastName)
                        setEditPhone(el.phoneNumber)
                        setEditEmail(el.email)
                        setEditUserType(el.type)
                        setEditUserStatus(el.userStatus)
                        setEditAgency(el.agencyId)
                        setEditUserUID(el.uid)
                        setEditUserBlock(true)
                        setEditUserRoleInSystem(el.userRole)
                      }}
                    >
                      <FontAwesomeIcon style={{ fontSize: 12 }} icon={faEdit} />
                    </div>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    )
  } else {
    return <Redirect to={`/${agencyData.id}`} />
  }
}

const mapStateToProps = (state) => ({
  agencyData: state.agencyDataRed.obj
})

export default connect(mapStateToProps)(AddUser)
