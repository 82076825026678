import React, { useCallback, useEffect, useState } from 'react'
import AgencyMenu from '../../Components/AgencyMenu'
import Loader from '../../Components/Loader'
import firebase from 'firebase/app'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Switch,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useTranslation } from 'react-i18next'
import {
  systemColors,
  systemStylingSettings,
  acceptedFileSize
} from '../../globalVariables'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faTrashAlt,
  faUserEdit,
  faEnvelope,
  faUserTimes,
  faUser,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { showGeneratedPdfBox } from '../../redux/actions/showGeneratedPdfBox'
import GeneratedPDFViewer from '../../Components/GeneratedPdfViewer'
import {
  secondsToDate,
  getDayAndHours,
  convertDate,
  addWeeksToDate
} from '../../globalFunctions'
import Headline from '../../Components/Headline'
import CrewRotationLog from './Modules/crewRotationLog'
import { v4 as uuid } from 'uuid'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

const useStyles = makeStyles({
  tableHeader: {
    color: systemColors.darkBlue,
    fontSize: 16
  },
  tabRoot: {
    maxWidth: 150,
    padding: 0,
    color: systemColors.lightBlue,
    opacity: 1,
    borderTop: `1px solid ${systemColors.lighGrey}`,
    borderBottom: `1px solid ${systemColors.lighGrey}`
  },
  tabsIndicator: {
    backgroundColor: systemColors.orange
  },
  buttonEndIcon: {
    color: '#ffffff',
    '& > *:first-child': {
      fontSize: '1em'
    }
  },
  buttonRoot: {
    color: '#ffffff !important'
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: systemColors.orangeDark
    }
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  cardRoot: {
    marginBottom: 10
  },
  errorColor: {
    color: `${systemColors.errorColor} !important`
  },
  tabsRoot: {
    marginTop: 20,
    justifyContent: 'center'
  },
  tabsScroller: {
    flexGrow: '0'
  },
  chipsDisplay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: 5
    }
  },
  switchBase: {
    '&.Mui-checked': {
      color: systemColors.orange
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.orange
    }
  },
  switchPrimary: {
    '&.Mui-checked': {
      color: systemColors.orange
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.orange
    }
  },
  labelSize: {
    fontSize: 14
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: systemColors.inputColor,
      width: '100%',
      borderRadius: systemStylingSettings.borderRadius,
      '&.Mui-focused fieldset': {
        color: systemColors.darkBlue,
        borderColor: systemColors.darkBlue,
        borderWidth: systemStylingSettings.borderWidth
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 8
      }
  },
  salaryNames: {
    color: systemColors.electric
  },
  salaryTextBlock: {
    margin: 5
  }
})

const CrewEmployeeView = ({ agencyData, agencyUserData }) => {
  const { docId } = useParams()
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [refreshData, setRefreshData] = useState(false)

  // Data from database
  const [userData, setUserData] = useState()
  const [positionsArr, setPositionsArr] = useState([])
  const [vesselTypeArr, setVesselTypeArr] = useState([])
  const [engineTypeArr, setEngineTypeArr] = useState([])

  const [selectedTab, setSelectedTab] = useState(0)
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('')
  const [documentsLinks, setDocumentsLinks] = useState([])
  const [certificatesArr, setCertificatesArr] = useState([])
  const [notesArr, setNotesArr] = useState([])

  const [companyLogoURL, setCompanyLogoURL] = useState('')

  // Inputs states
  const [note, setNote] = useState('')

  const [allowEdit, setAllowEdit] = useState(false)

  const [deleteProfileWarningBox, setDeleteProfileWarningBox] = useState(false)

  const [showQuickAddDocumentDialog, setShowQuickAddDocumentDialog] =
    useState(false)

  // Quick add document states
  const [selectedDocId, setSelectedDocId] = useState(null)
  const [selectedDocumentNumber, setSelectedDocumentNumber] = useState('')
  const [placeOfIssue, setPlaceOfIssue] = useState('')
  const [issueDate, setIssueDate] = useState('')
  const [expireDate, setExpireDate] = useState('')
  const [uploadedDocument, setUploadedDocument] = useState('')
  const [fileSizeError, setFileSizeError] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [selectedDocumentError, setSelectedDocumentError] = useState(false)

  const [tagged, setTagged] = useState(false)

  // Get profile picture
  const getProfilePhoto = useCallback(
    (photoName) => {
      setLoading(true)
      if (photoName !== '') {
        storageRef
          .child(
            `agencies/${agencyData.id}/users-forms-uploads/${docId}/${photoName}`
          )
          .getDownloadURL()
          .then((url) => {
            setProfilePhotoUrl(url)
            setLoading(false)
          })
          .catch((error) => {
            console.log(error)
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    },
    [agencyData, docId]
  )

  // Get documents links
  const getDocumentsLinks = useCallback(
    (arr) => {
      const getLinks = arr.map((obj) => {
        if (obj.upload !== '') {
          return new Promise((resolve) => {
            storageRef
              .child(
                `agencies/${agencyData.id}/users-forms-uploads/${docId}/${obj.upload}`
              )
              .getDownloadURL()
              .then((url) =>
                resolve({
                  id: obj.id,
                  url
                })
              )
              .catch((err) => {
                console.log("Can't get file url", err)
                resolve({
                  id: obj.id,
                  url: ''
                })
              })
          })
        } else {
          // eslint-disable-next-line
          return
        }
      })

      Promise.all(getLinks)
        .then((res) => {
          setDocumentsLinks(res)
        })
        .catch((err) => console.log(err.message, err.error))
    },
    [agencyData, docId]
  )

  // Get user cv from data base
  useEffect(() => {
    let shouldUpdate = true
    if (shouldUpdate) {
      setLoading(true)
    }

    // Get users data
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .where('docId', '==', docId)
      .get()
      .then((res) => {
        res.forEach((doc) => {
          let data = JSON.stringify(doc.data())
          data = JSON.parse(data)
          if (shouldUpdate) {
            setUserData(data)
            setNotesArr(data.notes)
            setLoading(false)
          }

          // Change document title
          document.title = `${
            data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1)
          } 
                ${
                  data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1)
                } - Seanor`

          // set files links
          getProfilePhoto(data.profilePhoto)
          getDocumentsLinks(data.documentsArray)

          // Get company logo URL
          storage
            .ref()
            .child(
              `/public/agencies/${agencyData.id}/logo/${agencyData.logoFileName}`
            )
            .getDownloadURL()
            .then((url) => {
              if (shouldUpdate) {
                setCompanyLogoURL(url)
              }
            })
            .catch((error) => {
              console.log("Can't get logo url", error)
            })

          // Check if user have permission to edit his profile
          if (data.userId !== '') {
            db.collection('users')
              .doc(data.userId)
              .get()
              .then((res) => {
                let data = JSON.stringify(res.data())
                data = JSON.parse(data)
                if (shouldUpdate) {
                  setAllowEdit(data.data.canEditCV)
                }
              })
              .catch((err) => console.log("Can't get user edit cv status", err))
          }

          // Add profile to users last viewed profiles list
          let lastViewedProfilesNewArr = []

          if (agencyUserData.lastViewedProfilesCREW !== undefined) {
            lastViewedProfilesNewArr = agencyUserData.lastViewedProfilesCREW

            const visitedProfilesDataObject = {
              docId,
              firstName: data.firstName,
              lastName: data.lastName,
              mainPosition: data.positionId,
              timestamp: new Date()
            }
            // Check is profile alredy listed in array
            const index = lastViewedProfilesNewArr.findIndex(
              (el) => el.docId === docId
            )

            // Add profile to list
            if (index < 0) {
              lastViewedProfilesNewArr.unshift(visitedProfilesDataObject)
            } else if (index > 0) {
              lastViewedProfilesNewArr.splice(index, 1)
              lastViewedProfilesNewArr.unshift(visitedProfilesDataObject)
            }

            // Remove last list item if list is longer then 10
            if (lastViewedProfilesNewArr.length > 10) {
              lastViewedProfilesNewArr.splice(10, 1)
            }
          }

          db.collection('users')
            .doc(agencyUserData.uid)
            .update({
              'data.lastViewedProfilesCREW': lastViewedProfilesNewArr
            })
            .catch((error) => {
              console.log(
                "Can't add profil to 'Last viewed profile' list",
                error
              )
            })
        })
      })
      .catch((err) => {
        console.log('Get user information err', err)
        if (shouldUpdate) {
          setLoading(false)
        }
      })

    // Get positions data from database
    const getPositionArr = () => {
      db.collection('lists')
        .doc('positions')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          if (shouldUpdate) {
            setPositionsArr(data.list)
          }
        })
        .catch((err) => console.log('Ranks list Error', err))
    }

    // Get vessel types
    const getVesselTypes = () => {
      db.collection('lists')
        .doc('type-of-vessel')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          if (shouldUpdate) {
            setVesselTypeArr(data.list)
          }
        })
        .catch((err) => console.log('Vessel type err', err))
    }

    // Get engine types
    const getEngineTypes = () => {
      db.collection('lists')
        .doc('engin-type')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          if (shouldUpdate) {
            setEngineTypeArr(data.list)
          }
        })
        .catch((err) => console.log('Engin type err', err))
    }

    // Get list of certificates from db
    const getCertificates = () => {
      db.collection('certificates')
        .doc('certificates')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          if (shouldUpdate) {
            setCertificatesArr(data.list)
          }
        })
        .catch((err) => console.log('Certificates list Error', err))
    }

    const checkIfProfileIsTagged = () => {
      if (agencyUserData.taggedProfilesCREW !== undefined) {
        const index = agencyUserData.taggedProfilesCREW.findIndex(
          (el) => el.docId === docId
        )

        if (index >= 0) {
          if (shouldUpdate) {
            setTagged(true)
          }
        }
      }
    }

    getPositionArr()
    getCertificates()
    getVesselTypes()
    getEngineTypes()
    checkIfProfileIsTagged()

    return () => {
      shouldUpdate = false
    }
  }, [
    docId,
    agencyData,
    getDocumentsLinks,
    getProfilePhoto,
    refreshData,
    agencyUserData
  ])

  // Change selected tab value
  const handleTabsChange = (e, newValue) => {
    setSelectedTab(newValue)
  }

  // Find position name by id
  const getPositionName = (id) => {
    let name = ''
    positionsArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  // Get document name
  const getDocumentName = (id) => {
    let name = ''
    certificatesArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  // Get vessel type name
  const getVesselTypeName = (id) => {
    let name = ''
    vesselTypeArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  // Get engine type name
  const getEngineTypeName = (id) => {
    let name = ''
    engineTypeArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  // Find and add document link
  const addDocumentLink = (id) => {
    let link = ''
    documentsLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  // Get data from database
  const getNewData = () => {
    setLoading(true)

    db.collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .where('docId', '==', docId)
      .get()
      .then((res) => {
        res.forEach((doc) => {
          let data = JSON.stringify(doc.data())
          data = JSON.parse(data)

          setUserData(data)
          setNotesArr(data.notes)
          setLoading(false)

          // set files links
          getProfilePhoto(data.profilePhoto)
          getDocumentsLinks(data.documentsArray)
        })
      })
      .catch((err) => {
        console.log('Get user information err', err)
        setLoading(false)
      })
  }

  // Add note to list and data base
  const addNote = () => {
    if (note !== '') {
      setLoading(true)

      // Update database field with new data
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .update({
          notes: [
            ...notesArr,
            {
              note,
              timeStamp: new Date(),
              userName: agencyUserData.name,
              userLastName: agencyUserData.lastName
            }
          ]
        })
        .then(() => {
          setLoading(false)
          getNewData()
        })
        .catch((err) => {
          console.log('There is problem to put notes to database', err)
          setLoading(false)
        })

      setNote('')
    }
  }

  // Delete note and send new data to database
  const deleteNote = (index) => {
    if (notesArr.length > 0) {
      setLoading(true)
      const values = [...notesArr]
      values.splice(index, 1)

      // Update database field with new data
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .update({
          notes: values
        })
        .then(() => {
          setLoading(false)
          getNewData()
        })
        .catch((err) => {
          console.log('There is problem to put notes to database', err)
          setLoading('false')
        })
    }
  }

  const changeAllowEditProfileStatus = () => {
    db.collection('users')
      .doc(userData.userId)
      .update({
        'data.canEditCV': !allowEdit
      })
      .then(() => {
        setAllowEdit(!allowEdit)
      })
  }

  const sendEmail = (email) => {
    const subject = t('profileAccount')
    const body = t('keySubsystemEmployeeViewEmailBody')
    window.location = `mailto:${email}?subject=${subject}&body=${body}`
  }

  const deleteProfile = () => {
    setLoading(true)

    const deleteFiles = new Promise((resolve) => {
      const reference = storageRef.child(
        `agencies/${agencyData.id}/users-forms-uploads/${docId}`
      )
      reference
        .listAll()
        .then((response) => {
          const promise = response.items.forEach((item) => {
            reference.child(`/${item.name}`).delete()
          })
          Promise.all([promise]).then(() => resolve())
        })
        .catch((error) => {
          console.log("Can't get files list", error)
          resolve()
        })
    })

    const deleteData = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .delete()
        .then(() => {
          resolve()
        })
        .catch((error) => {
          console.log("Can't delete profile", error)
          resolve()
        })
    })

    Promise.all([deleteFiles, deleteData]).then(() => {
      setLoading(false)
      history.push(`/${agencyData.id}/crew-dashboard`)
    })
  }

  // Let filter certificates by ID and by name
  const _cerificatesOptions = createFilterOptions({
    stringify: (option) => `${option.id} ${option.name}`
  })
  const certificatesOptions = (certificatesArr, state) => {
    const result = _cerificatesOptions(certificatesArr, state)

    if (result.length === 0) {
      return _cerificatesOptions(certificatesArr, {
        ...state,
        getOptionLabel: (o) => o.id.toString()
      })
    }

    return result
  }

  const addDocument = async () => {
    if (
      selectedDocId !== null &&
      uploadedDocument !== '' &&
      fileSizeError === false
    ) {
      setLoading(true)
      const id = uuid()

      const updatedDocumentsArray = [
        ...userData.documentsArray,
        {
          id,
          docId: selectedDocId,
          expires: expireDate,
          issued: issueDate,
          number: selectedDocumentNumber,
          placeIssue: placeOfIssue,
          upload: uploadedDocument[0].name
        }
      ]

      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .update({
          documentsArray: updatedDocumentsArray
        })
        .then(() => {
          storage
            .ref(
              `/agencies/${agencyData.id}/users-forms-uploads/${docId}/${uploadedDocument[0].name}`
            )
            .put(uploadedDocument[0])
            .then(() => {
              setRefreshData(!refreshData)
              setShowQuickAddDocumentDialog(false)
              setSelectedDocId(null)
              setSelectedDocumentNumber('')
              setPlaceOfIssue('')
              setIssueDate('')
              setExpireDate('')
              setUploadedDocument('')
              setLoading(false)
            })
            .catch((error) => {
              console.log(error)
              setLoading(false)
            })
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })

      // Reset errors
      setFileSizeError(false)
      setUploadError(false)
      setSelectedDocumentError(false)
    } else {
      if (selectedDocId === null) {
        setSelectedDocumentError(true)
      } else {
        setSelectedDocumentError(false)
      }
      if (uploadedDocument === '') {
        setUploadError(true)
      } else {
        setUploadError(false)
      }
    }
  }

  const renderVesselTypeList = (array) => {
    if (array !== undefined) {
      const sorted = array.sort((a, b) => a.id - b.id)
      return sorted.map((type, index) => (
        <div
          key={index}
          style={{
            fontWeight: type.type === 'group' && 'bold'
          }}
        >
          {t(type.name)}
        </div>
      ))
    }
    return null
  }

  const changeProfileTagStatus = () => {
    if (!tagged) {
      addTaggedProfileToUsersList()
    } else {
      removeTaggedProfileFromUsersList()
    }
  }

  const addTaggedProfileToUsersList = () => {
    const profileObject = {
      docId,
      firstName: userData.firstName,
      lastName: userData.lastName,
      nationality: userData.nationality,
      mainPosition: userData.positionId,
      timestamp: new Date()
    }

    let taggedProfilesArray = []
    if (agencyUserData.taggedProfilesCREW !== undefined) {
      taggedProfilesArray = agencyUserData.taggedProfilesCREW
      taggedProfilesArray.push(profileObject)
    } else {
      taggedProfilesArray = [profileObject]
    }

    db.collection('users')
      .doc(agencyUserData.uid)
      .update({
        'data.taggedProfilesCREW': taggedProfilesArray
      })
      .then(() => setTagged(true))
      .catch((error) => {
        console.log("Can't add profile to tagged profiles list", error)
        setTagged(false)
      })
  }

  const removeTaggedProfileFromUsersList = () => {
    let taggedProfilesArray = []
    if (agencyUserData.taggedProfilesCREW !== undefined) {
      taggedProfilesArray = agencyUserData.taggedProfilesCREW

      const index = taggedProfilesArray.findIndex((el) => el.docId === docId)

      if (index >= 0) {
        taggedProfilesArray.splice(index, 1)
      }
    }

    db.collection('users')
      .doc(agencyUserData.uid)
      .update({
        'data.taggedProfilesCREW': taggedProfilesArray
      })
      .then(() => setTagged(false))
      .catch((error) => {
        console.log("Can't remove profile from tagged profiles list", error)
        setTagged(true)
      })
  }

  return (
    <div>
      {loading && <Loader />}

      <AgencyMenu type='crew' />

      <Headline
        text={
          userData &&
          `${
            userData.firstName.charAt(0).toUpperCase() +
            userData.firstName.slice(1)
          } 
                ${
                  userData.lastName.charAt(0).toUpperCase() +
                  userData.lastName.slice(1)
                }`
        }
      />

      {/* Delete profile warning box */}
      <Dialog
        open={deleteProfileWarningBox}
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent style={{ marginTop: 20 }}>
          {t('deleteProfileWarningBoxContent')}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteProfile()}>{t('delete')}</Button>
          <Button
            onClick={() => {
              setDeleteProfileWarningBox(false)
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Quick add document dialog */}
      <Dialog
        open={showQuickAddDocumentDialog}
        classes={{ paper: classes.dialogRoot }}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('quickAddDocument')}
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            spacing={1}
            classes={{ root: classes.docGridContainer }}
            style={{ margin: '20px 0' }}
          >
            {/* Select document */}
            <Grid item xs={12} sm={6} md={2}>
              <Autocomplete
                filterOptions={certificatesOptions}
                options={certificatesArr}
                value={selectedDocId}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    size='small'
                    fullWidth
                    label={t('selectDocument')}
                    variant='outlined'
                    error={selectedDocumentError}
                    helperText={selectedDocumentError && t('required')}
                  />
                )}
                onChange={(e, val) => {
                  val && setSelectedDocId(val)
                }}
              />
            </Grid>

            {/* Number */}
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                required
                size='small'
                variant='outlined'
                type='text'
                value={selectedDocumentNumber}
                label={t('number')}
                onChange={(e) => setSelectedDocumentNumber(e.target.value)}
                fullWidth
              />
            </Grid>

            {/* Place of issue */}
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                required
                size='small'
                variant='outlined'
                type='text'
                value={placeOfIssue}
                label={t('placeOfIssue')}
                onChange={(e) => setPlaceOfIssue(e.target.value)}
                fullWidth
              />
            </Grid>

            {/* Issued date */}
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                required
                size='small'
                variant='outlined'
                type='date'
                value={issueDate}
                label={t('issued')}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  setIssueDate(e.target.value)
                }}
                fullWidth
              />
            </Grid>

            {/* Expires date */}
            <Grid item xs={12} sm={6} md={2} style={{ display: 'flex' }}>
              <TextField
                name='expires'
                size='small'
                variant='outlined'
                type='date'
                value={expireDate}
                label={t('expires')}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  setExpireDate(e.target.value)
                }}
                fullWidth
              />

              <Tooltip
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 8,
                  cursor: 'pointer'
                }}
                placement='right-end'
                title={t('ifExpireDateEmptyThanUnlimited')}
              >
                <div>
                  <FontAwesomeIcon
                    style={{ color: systemColors.lighGrey }}
                    icon={faInfoCircle}
                  />
                </div>
              </Tooltip>
            </Grid>

            {/* Document upload */}
            <Grid item xs={12} sm={6} md={2} style={{ display: 'flex' }}>
              {selectedDocId !== null &&
              selectedDocumentNumber !== '' &&
              placeOfIssue !== '' &&
              issueDate !== '' ? (
                <TextField
                  required
                  size='small'
                  variant='outlined'
                  type='file'
                  label={t('uploadDocument')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    const file = e.target.files
                    if (file.length > 0) {
                      if (file[0].size > acceptedFileSize) {
                        setFileSizeError(true)
                        setUploadError(true)
                      } else {
                        setUploadError(false)
                        setFileSizeError(false)
                        setUploadedDocument(file)
                      }
                    }
                  }}
                  fullWidth
                  inputProps={{
                    accept: '.png, .jpg, .jpeg, .pdf'
                  }}
                  helperText={
                    fileSizeError
                      ? t('toBigFile')
                      : uploadError
                      ? t('required')
                      : t('acceptFormat2')
                  }
                  error={uploadError ? true : !!fileSizeError}
                />
              ) : (
                <div style={{ marginTop: 8 }}>
                  {t('fillRequiredFieldsFirst')}
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => addDocument()}>{t('addDocument')}</Button>

          {/* Close */}
          <Button
            onClick={() => {
              setShowQuickAddDocumentDialog(false)
            }}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {userData && (
        <div>
          {/* Chips */}
          <div style={{ marginTop: 20 }}>
            {/* First row */}
            <Grid
              container
              spacing={1}
              justifyContent='center'
              style={{ marginTop: 10 }}
            >
              {/* status */}
              <Grid item>
                <Chip
                  label={`${t('status')}: ${
                    userData.rotationLogStatus !== undefined
                      ? t(userData.rotationLogStatus)
                      : t('atHome')
                  }`}
                />
              </Grid>

              {/* PDF */}
              <Grid item>
                <Chip
                  label={
                    <GeneratedPDFViewer
                      userArr={userData}
                      profilePhotoUrl={profilePhotoUrl}
                      certificatesArr={certificatesArr}
                      ranksArr={positionsArr}
                      engineTypeArr={engineTypeArr}
                      vesselTypeArr={vesselTypeArr}
                      companyLogoURL={companyLogoURL}
                      type='crew'
                    />
                  }
                  clickable
                  icon={<FontAwesomeIcon icon={faDownload} />}
                />
              </Grid>

              {/* Edit */}
              <Grid item>
                <Chip
                  label={t('edit')}
                  clickable
                  onClick={() =>
                    history.push(
                      `/${agencyData.id}/crew-dashboard/edit-employee/${docId}`
                    )
                  }
                  icon={<FontAwesomeIcon icon={faUserEdit} />}
                />
              </Grid>

              {/* Send email */}
              <Grid item>
                <Chip
                  label={t('sendEmail')}
                  clickable
                  onClick={() => sendEmail(userData.email)}
                  icon={<FontAwesomeIcon icon={faEnvelope} />}
                />
              </Grid>

              {/* Allow edit */}
              {userData.userId !== '' && (
                <Grid item>
                  <FormControlLabel
                    label={t('allowEdit')}
                    labelPlacement='start'
                    classes={{ label: classes.labelSize }}
                    control={
                      <Switch
                        checked={allowEdit}
                        onChange={() => changeAllowEditProfileStatus()}
                        size='small'
                        color='orange'
                      />
                    }
                  />
                </Grid>
              )}
            </Grid>

            {/* Second row */}
            <Grid
              container
              spacing={1}
              justifyContent='center'
              style={{ marginTop: 10 }}
            >
              {/* Delete profile */}
              <Grid item>
                <Chip
                  label={t('deleteProfile')}
                  clickable
                  onClick={() => setDeleteProfileWarningBox(true)}
                  icon={<FontAwesomeIcon icon={faUserTimes} />}
                />
              </Grid>

              {/* Created */}
              <Grid item>
                <Chip
                  label={`${t('created')}: ${secondsToDate(
                    userData.createdAt.seconds
                  )}`}
                />
              </Grid>

              {/* Last update */}
              <Grid item>
                <Chip
                  label={`${t('lastUpdate')}: ${secondsToDate(
                    userData.date.seconds
                  )}`}
                />
              </Grid>

              {/* Tag profile */}
              <Grid item>
                <FormControlLabel
                  label={t('tagProfile')}
                  labelPlacement='start'
                  classes={{ label: classes.labelSize }}
                  control={
                    <Switch
                      checked={tagged}
                      onChange={() => changeProfileTagStatus()}
                      size='small'
                      color='orange'
                    />
                  }
                />
              </Grid>
            </Grid>
          </div>

          {/* Tabs menu */}
          <Tabs
            value={selectedTab}
            onChange={handleTabsChange}
            variant='scrollable'
            scrollButtons='auto'
            style={{ marginTop: 20 }}
          >
            <Tab label={t('basicInfo')} />
            <Tab label={t('salary')} />
            <Tab label={t('education')} />
            <Tab label={t('documents')} />
            <Tab label={t('experience')} />
            <Tab label={t('crewOrMarineAgencies')} />
            <Tab label={t('physicalState')} />
            <Tab label={t('coverLetterComments')} />
            <Tab label={t('agencyNotes')} />
            <Tab label={t('rotationLog')} />
          </Tabs>

          {/* Display information acording selected tab */}
          <div>
            {/* Basic info */}
            {selectedTab === 0 && (
              <div className='crewEmployeeView__selectedContainer'>
                {/* First column */}
                <div className='crewEmployeeView__basicInfo-box'>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('userId')}:</span> {userData.userId}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('availableFrom')}:</span> {userData.availableFrom}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('firstName')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.firstName}
                    </span>
                  </div>
                  {userData.middleName !== '' ? (
                    <div className='crewEmployeeView__basicInfo-el'>
                      <span>{t('middleName')}:</span>{' '}
                      <span className='global__capitalizeFirstLetter'>
                        {userData.middleName}
                      </span>
                    </div>
                  ) : null}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('lastName')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.lastName}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('mainPosition')}:</span>{' '}
                    {getPositionName(userData.positionId)}
                  </div>
                  {userData.positionTwoId !== null &&
                    userData.positionTwoId !== undefined &&
                    userData.positionTwoId !== '' && (
                      <div className='crewEmployeeView__basicInfo-el'>
                        <span>{t('secondPosition')}:</span>{' '}
                        {getPositionName(userData.positionTwoId)}
                      </div>
                    )}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('birthDate')}:</span> {userData.birthDate}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('homeAddress')}: </span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.homeAddress}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('phone')}:</span> {userData.phoneMobile}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('email')}:</span> {userData.email}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('whatsappOrViberNo')}: </span>{' '}
                    <span>{userData.whatsappViber}</span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('cityCountry')}:</span>{' '}
                    <span>{userData.cityCountry}</span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('maritalStatus')}:</span> {userData.maritalStatus}
                  </div>
                  {userData.children !== '' ? (
                    <div className='crewEmployeeView__basicInfo-el'>
                      <span>{t('children')}:</span> {userData.children}
                    </div>
                  ) : null}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nationality')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.nationality}
                    </span>
                  </div>
                  {userData.birthPlace !== '' ? (
                    <div className='crewEmployeeView__basicInfo-el'>
                      <span>{t('birthPlace')}:</span>{' '}
                      <span className='global__capitalizeFirstLetter'>
                        {userData.birthPlace}
                      </span>
                    </div>
                  ) : null}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nearestAirpot')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.nearestAirport}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('englishLevel')}:</span> {userData.englishLevel}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nextToKin')}:</span> {userData.nextToKin}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nextToKinPhone')}:</span>{' '}
                    {userData.nextToKinPhone}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nextToKinEmail')}:</span>{' '}
                    {userData.nextToKinEmail}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nextToKinAddress')}:</span>{' '}
                    {userData.nextToKinAddress}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('desiredContractDuration')}:</span>{' '}
                    {userData.contractDuration}
                  </div>

                  {/* Desired vessel type */}
                  <div
                    className='crewEmployeeView__basicInfo-el'
                    style={{ display: 'flex' }}
                  >
                    <span>{t('desiredVesselType')}:</span>{' '}
                    <div style={{ marginLeft: 20 }}>
                      {renderVesselTypeList(userData.desiredVesselType)}
                    </div>
                  </div>
                </div>

                {/* Second column */}
                <div className='crewEmployeeView__basicInfo-box'>
                  <div>
                    {profilePhotoUrl !== '' && profilePhotoUrl !== null ? (
                      <img
                        className='crewEmployeeView__basicInfo-profilePicture'
                        src={profilePhotoUrl}
                        alt='profile'
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: systemColors.lightElectric,
                          width: 150,
                          height: 150,
                          borderRadius: systemStylingSettings.borderRadius
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            fontSize: 100,
                            color: systemColors.background
                          }}
                          icon={faUser}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Salary */}
            {selectedTab === 1 && (
              <div style={{ marginTop: 30 }}>
                {/* Salary */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  <div className={classes.salaryTextBlock}>
                    <span className={classes.salaryNames}>
                      {t('minimum')}:{' '}
                    </span>
                    {userData.minSalary}
                    <span> $/{t('month')}</span>
                  </div>
                  <div className={classes.salaryTextBlock}>
                    <span className={classes.salaryNames}>
                      {t('expected')}:{' '}
                    </span>
                    {userData.expectedSalary}
                    <span> $/{t('month')}</span>
                  </div>
                  <div className={classes.salaryTextBlock}>
                    <span className={classes.salaryNames}>
                      {t('lastSalary')}:{' '}
                    </span>
                    {userData.lastSalary}
                    <span> $/{t('month')}</span>
                  </div>
                </div>

                {/* Bank information */}
                <div>
                  <div
                    style={{
                      fontSize: 18,
                      marginTop: 50,
                      marginBottom: 20,
                      color: systemColors.electric
                    }}
                  >
                    {t('bank_information')}
                  </div>

                  {/* Beneficiary name */}
                  <div className={classes.salaryTextBlock}>
                    <span className={classes.salaryNames}>
                      {t('beneficiary_name')}:{' '}
                    </span>
                    {userData.bankData !== undefined &&
                      userData.bankData.beneficiaryName}
                  </div>

                  {/* Currency */}
                  <div className={classes.salaryTextBlock}>
                    <span className={classes.salaryNames}>
                      {t('currency')}:{' '}
                    </span>
                    {userData.bankData !== undefined &&
                      userData.bankData.currency}
                  </div>

                  {/* Bank name */}
                  <div className={classes.salaryTextBlock}>
                    <span className={classes.salaryNames}>
                      {t('bank_name')}:{' '}
                    </span>
                    {userData.bankData !== undefined &&
                      userData.bankData.bankName}
                  </div>

                  {/* Account number */}
                  <div className={classes.salaryTextBlock}>
                    <span className={classes.salaryNames}>
                      {t('iban_account_number')}:{' '}
                    </span>
                    {userData.bankData !== undefined &&
                      userData.bankData.accountNumber}
                  </div>

                  {/* Swift code */}
                  <div className={classes.salaryTextBlock}>
                    <span className={classes.salaryNames}>
                      {t('swift_code')}:{' '}
                    </span>
                    {userData.bankData !== undefined &&
                      userData.bankData.swiftCode}
                  </div>

                  {/* Bank code */}
                  <div className={classes.salaryTextBlock}>
                    <span className={classes.salaryNames}>
                      {t('bank_code')}:{' '}
                    </span>
                    {userData.bankData !== undefined &&
                      userData.bankData.bankCode}
                  </div>

                  {/* Note */}
                  <div className={classes.salaryTextBlock}>
                    <span className={classes.salaryNames}>{t('note')}: </span>
                    {userData.bankData !== undefined && userData.bankData.note}
                  </div>
                </div>
              </div>
            )}

            {/* Education */}
            {selectedTab === 2 && (
              <div className='crewEmployeeView__selectedContainer'>
                <div className='crewEmployeeView__education'>
                  <div>
                    <span>{t('licence')}:</span>
                    {userData.educationLicence}
                  </div>
                  <div>
                    <span>{t('number')}:</span>
                    {userData.educationNumber}
                  </div>
                  <div>
                    <span>{t('issued')}:</span>
                    {userData.educationIssued}
                  </div>
                  <div>
                    <span>{t('expires')}:</span>
                    {userData.educationExpires}
                  </div>
                  <div>
                    <span>{t('country')}:</span>
                    {userData.educationCountry}
                  </div>
                </div>
              </div>
            )}

            {/* Documents */}
            {selectedTab === 3 && (
              <div
                className='crewEmployeeView__selectedContainer'
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {/* Quick add document button */}
                <Button
                  variant='contained'
                  size='small'
                  color='orange'
                  style={{
                    width: 'fit-content',
                    margin: '20px auto'
                  }}
                  onClick={() => setShowQuickAddDocumentDialog(true)}
                >
                  {t('quickAddDocument')}
                </Button>

                {/* Documents table */}
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t('document')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('number')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('placeOfIssue')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('issued')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('expires')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('file')}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {/* Passports & visas */}
                    <TableRow>
                      <TableCell colSpan={6} className='documentsSection'>
                        {t('passports&Visas')}
                      </TableCell>
                    </TableRow>

                    {userData.documentsArray.map((doc) => {
                      if (doc.docId.id < 40 || doc.docId.id >= 250) {
                        return (
                          <TableRow hover key={doc.id}>
                            <TableCell>
                              {certificatesArr &&
                                doc.docId !== null &&
                                getDocumentName(doc.docId.id)}
                            </TableCell>
                            <TableCell>{doc.number}</TableCell>
                            <TableCell>{doc.placeIssue}</TableCell>
                            <TableCell>{doc.issued}</TableCell>
                            <TableCell
                              className={
                                doc.expires !== '' && doc.expires !== undefined
                                  ? doc.expires <=
                                    convertDate(addWeeksToDate(2))
                                    ? classes.errorColor
                                    : null
                                  : null
                              }
                            >
                              {doc.expires !== '' && doc.expires !== undefined
                                ? doc.expires
                                : t('unlimited')}
                            </TableCell>
                            <TableCell>
                              {addDocumentLink(doc.id) !== '' && (
                                <a
                                  href={addDocumentLink(doc.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  {t('download')}
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        // eslint-disable-next-line
                        return
                      }
                    })}

                    {/* Diplomas & endorsements */}
                    <TableRow>
                      <TableCell colSpan={6} className='documentsSection'>
                        {t('diplomas&Endorsments')}
                      </TableCell>
                    </TableRow>
                    {userData.documentsArray.map((doc) => {
                      if (doc.docId.id >= 40 && doc.docId.id < 90) {
                        return (
                          <TableRow hover key={doc.id}>
                            <TableCell>
                              {certificatesArr &&
                                doc.docId !== null &&
                                getDocumentName(doc.docId.id)}
                            </TableCell>
                            <TableCell>{doc.number}</TableCell>
                            <TableCell>{doc.placeIssue}</TableCell>
                            <TableCell>{doc.issued}</TableCell>
                            <TableCell
                              className={
                                doc.expires !== '' && doc.expires !== undefined
                                  ? doc.expires <=
                                    convertDate(addWeeksToDate(2))
                                    ? classes.errorColor
                                    : null
                                  : null
                              }
                            >
                              {doc.expires !== '' && doc.expires !== undefined
                                ? doc.expires
                                : t('unlimited')}
                            </TableCell>
                            <TableCell>
                              {addDocumentLink(doc.id) !== '' && (
                                <a
                                  href={addDocumentLink(doc.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  {t('download')}
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        // eslint-disable-next-line
                        return
                      }
                    })}

                    {/* Certificates & licenses */}
                    <TableRow>
                      <TableCell colSpan={6} className='documentsSection'>
                        {t('certificates&Licenses')}
                      </TableCell>
                    </TableRow>
                    {userData.documentsArray.map((doc) => {
                      if (doc.docId.id >= 120 && doc.docId.id < 250) {
                        return (
                          <TableRow hover key={doc.id}>
                            <TableCell>
                              {certificatesArr &&
                                doc.docId !== null &&
                                getDocumentName(doc.docId.id)}
                            </TableCell>
                            <TableCell>{doc.number}</TableCell>
                            <TableCell>{doc.placeIssue}</TableCell>
                            <TableCell>{doc.issued}</TableCell>
                            <TableCell
                              className={
                                doc.expires !== '' && doc.expires !== undefined
                                  ? doc.expires <=
                                    convertDate(addWeeksToDate(2))
                                    ? classes.errorColor
                                    : null
                                  : null
                              }
                            >
                              {doc.expires !== '' && doc.expires !== undefined
                                ? doc.expires
                                : t('unlimited')}
                            </TableCell>
                            <TableCell>
                              {addDocumentLink(doc.id) !== '' && (
                                <a
                                  href={addDocumentLink(doc.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  {t('download')}
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        // eslint-disable-next-line
                        return
                      }
                    })}
                    {userData.documentsArray.map((doc) => {
                      if (doc.docId.id >= 110 && doc.docId.id < 120) {
                        return (
                          <TableRow hover key={doc.id}>
                            <TableCell>
                              {certificatesArr &&
                                doc.docId !== null &&
                                getDocumentName(doc.docId.id)}
                            </TableCell>
                            <TableCell>{doc.number}</TableCell>
                            <TableCell>{doc.placeIssue}</TableCell>
                            <TableCell>{doc.issued}</TableCell>
                            <TableCell
                              className={
                                doc.expires !== '' && doc.expires !== undefined
                                  ? doc.expires <=
                                    convertDate(addWeeksToDate(2))
                                    ? classes.errorColor
                                    : null
                                  : null
                              }
                            >
                              {doc.expires !== '' && doc.expires !== undefined
                                ? doc.expires
                                : t('unlimited')}
                            </TableCell>
                            <TableCell>
                              {addDocumentLink(doc.id) !== '' && (
                                <a
                                  href={addDocumentLink(doc.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  {t('download')}
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        // eslint-disable-next-line
                        return
                      }
                    })}

                    {/* Medical certificates */}
                    <TableRow>
                      <TableCell colSpan={6} className='documentsSection'>
                        {t('medicalCertificates')}
                      </TableCell>
                    </TableRow>
                    {userData.documentsArray.map((doc) => {
                      if (doc.docId.id >= 90 && doc.docId.id < 110) {
                        return (
                          <TableRow hover key={doc.id}>
                            <TableCell>
                              {certificatesArr &&
                                doc.docId !== null &&
                                getDocumentName(doc.docId.id)}
                            </TableCell>
                            <TableCell>{doc.number}</TableCell>
                            <TableCell>{doc.placeIssue}</TableCell>
                            <TableCell>{doc.issued}</TableCell>
                            <TableCell
                              className={
                                doc.expires !== '' && doc.expires !== undefined
                                  ? doc.expires <=
                                    convertDate(addWeeksToDate(2))
                                    ? classes.errorColor
                                    : null
                                  : null
                              }
                            >
                              {doc.expires !== '' && doc.expires !== undefined
                                ? doc.expires
                                : t('unlimited')}
                            </TableCell>
                            <TableCell>
                              {addDocumentLink(doc.id) !== '' && (
                                <a
                                  href={addDocumentLink(doc.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  {t('download')}
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        // eslint-disable-next-line
                        return
                      }
                    })}
                  </TableBody>
                </Table>
              </div>
            )}

            {/* Experience */}
            {selectedTab === 4 && (
              <div className='crewEmployeeView__selectedContainer'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t('vesselName')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('flag')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('year')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('dwt')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('vesselType')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('engineType')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('hp')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('rank')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('from')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('till')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('shipOwner')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('crewAgency')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData.experienceArray.map((el) => {
                      return (
                        <TableRow key={el.id} hover>
                          <TableCell>{el.vesselName}</TableCell>
                          <TableCell>{el.flag}</TableCell>
                          <TableCell>{el.year}</TableCell>
                          <TableCell>{el.dwt}</TableCell>
                          <TableCell>
                            {el.vesselType !== null
                              ? getVesselTypeName(el.vesselType.id)
                              : ''}
                          </TableCell>
                          <TableCell>
                            {el.engineType !== null
                              ? getEngineTypeName(el.engineType.id)
                              : ''}
                          </TableCell>
                          <TableCell>{el.hp}</TableCell>
                          <TableCell>
                            {el.rankId !== null
                              ? getPositionName(el.rankId.id)
                              : ''}
                          </TableCell>
                          <TableCell>{el.fromDate}</TableCell>
                          <TableCell>{el.tillDate}</TableCell>
                          <TableCell>{el.shipOwner}</TableCell>
                          <TableCell>{el.crewAgency}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            )}

            {/* Crew or marine agencies */}
            {selectedTab === 5 && (
              <div className='crewEmployeeView__selectedContainer'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t('crewAgency')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('country')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('contactPerson')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('email')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('phone')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData.crewAgenciesArray.map((el) => {
                      return (
                        <TableRow key={el.id} hover>
                          <TableCell>{el.crewAgency}</TableCell>
                          <TableCell>{el.country}</TableCell>
                          <TableCell>{el.contactPerson}</TableCell>
                          <TableCell>{el.email}</TableCell>
                          <TableCell>{el.phone}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            )}

            {/* Physical state */}
            {selectedTab === 6 && (
              <div className='crewEmployeeView__selectedContainer'>
                <div className='crewEmployeeView__physicalState'>
                  <div>
                    <span>{t('heightCm')}:</span>
                    {userData.height}
                  </div>
                  <div>
                    <span>{t('weightKg')}:</span>
                    {userData.weight}
                  </div>
                  <div>
                    <span>{t('shoes')}:</span>
                    {userData.shoes}
                  </div>
                  <div>
                    <span>{t('clothes')}:</span>
                    {userData.clothes}
                  </div>
                  <div>
                    <span>{t('hairColor')}:</span>
                    {userData.hairColor}
                  </div>
                  <div>
                    <span>{t('eyeColor')}:</span>
                    {userData.eyeColor}
                  </div>
                </div>
              </div>
            )}

            {/* Comment */}
            {selectedTab === 7 && (
              <div className='crewEmployeeView__selectedContainer'>
                {userData.comment}
              </div>
            )}

            {/* Agency notes */}
            {selectedTab === 8 && (
              <div className='crewEmployeeView__notes'>
                {/* Show notes */}
                <div className='crewEmployeeView__notes-cards'>
                  {notesArr.length > 0
                    ? notesArr.map((el, i) => {
                        return (
                          <Card
                            key={i}
                            variant='outlined'
                            classes={{ root: classes.cardRoot }}
                          >
                            <CardHeader
                              subheader={`${t('author')}: ${el.userName} ${
                                el.userLastName
                              } (${t('created')}: ${getDayAndHours(
                                el.timeStamp.seconds
                              )})`}
                            />
                            <CardContent>{el.note}</CardContent>
                            <CardActions>
                              <Tooltip title={`${t('deleteNote')}`}>
                                <IconButton
                                  size='small'
                                  onClick={() => deleteNote(i)}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </IconButton>
                              </Tooltip>
                            </CardActions>
                          </Card>
                        )
                      })
                    : t('noNotes')}
                </div>

                {/* Add new note */}
                <TextField
                  size='small'
                  variant='outlined'
                  value={note}
                  type='text'
                  label={t('addNewNote')}
                  onChange={(e) => setNote(e.target.value)}
                  fullWidth
                  multiline
                  rows={5}
                />
                <div className='crewEmployeeView__notes-button'>
                  <Button
                    variant='contained'
                    size='small'
                    classes={{
                      endIcon: classes.buttonEndIcon,
                      root: classes.buttonRoot,
                      contained: classes.buttonContained
                    }}
                    onClick={() => addNote()}
                  >
                    {t('addNote')}
                  </Button>
                </div>
              </div>
            )}

            {/* Rotation log */}
            {selectedTab === 9 && (
              <div style={{ marginTop: 50 }}>
                <CrewRotationLog docId={docId} positionsList={positionsArr} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    showGeneratedPdfBox: state.showGeneratedPdfBoxRed.bool,
    agencyUserData: state.agencyUserDataRed.obj
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowGeneratedPdfBox: (bool) => dispatch(showGeneratedPdfBox(bool))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrewEmployeeView)
