import { useTranslation } from 'react-i18next'
import BlockCard from './BlockCard'
import { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  TextField,
  Tooltip
} from '@mui/material'
import firebase from 'firebase/app'
import ComponentLoader from '../../../../../Components/ComponentLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEuroSign } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import './ApplicationStages.scss'

const db = firebase.firestore()

interface Props {
  agencyData: AgencyData
}

const Currencies = ({ agencyData }: Props) => {
  const { t } = useTranslation()

  const [currenciesList, setCurrenciesList] = useState<string[]>([])

  const [loading, setLoading] = useState<boolean>(false)
  const [refreshData, setRefreshData] = useState<boolean>(false)

  const [editMode, setEditMode] = useState<boolean>(false)

  const [showNewEditCurrencyDialog, setShowNewEditCurrencyDialog] =
    useState<boolean>(false)

  const [title, setTitle] = useState<string>('')
  const [titleError, setTitleError] = useState<boolean>(false)

  const [selectedCurrency, setSelectedCurrency] = useState<string>('')

  useEffect(() => {
    let shouldUpdate = true

    db.collection('agencies')
      .doc(agencyData.id)
      .get()
      .then((snap) => {
        let data: any = JSON.stringify(snap.data())
        data = JSON.parse(data)

        const settingsCurrenciesList: string[] | undefined =
          data.data.settings.companySettings?.currencies

        if (settingsCurrenciesList !== undefined && shouldUpdate) {
          setCurrenciesList(settingsCurrenciesList)
        }
      })
      .catch((error) => console.error(error))

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, refreshData])

  const closeNewEditCurrencyeDialog = () => {
    setShowNewEditCurrencyDialog(false)
    setEditMode(false)
    setTitle('')
    setTitleError(false)
    setSelectedCurrency('')
  }

  const addCurrency = async () => {
    if (title !== '') {
      setTitleError(false)
      setLoading(true)

      const newCurrenciesList: string[] = [...currenciesList, title]

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .update({
          'data.settings.companySettings.currencies': newCurrenciesList
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
          closeNewEditCurrencyeDialog()
        })
        .catch((error) => console.error(error))
    } else {
      setTitleError(true)
    }
  }

  const openEditCurrency = (currency: string) => {
    setTitle(currency)
    setEditMode(true)
    setSelectedCurrency(currency)
    setShowNewEditCurrencyDialog(true)
  }

  const updateCurrency = async () => {
    if (title !== '') {
      setTitleError(false)
      setLoading(true)

      /**
       * Create new currencies list with updated data
       */
      let newCurrenciesList: string[] = currenciesList

      /**
       * Find selected currency index
       */
      const index = currenciesList.findIndex((el) => el === selectedCurrency)

      /**
       * Update data
       */
      newCurrenciesList[index] = title

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .update({
          'data.settings.companySettings.currencies': newCurrenciesList
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
          closeNewEditCurrencyeDialog()
        })
        .catch((error) => console.error(error))
    } else {
      setTitleError(true)
    }
  }

  const deleteCurrency = async () => {
    if (title !== '') {
      setTitleError(false)
      setLoading(true)

      /**
       * Create currencies list with removed currency
       */
      let newCurrenciesList: string[] = currenciesList
      /**
       * Find selected currency index
       */
      const index = currenciesList.findIndex((el) => el === selectedCurrency)

      if (index > -1) {
        newCurrenciesList.splice(index, 1)
      }

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .update({
          'data.settings.companySettings.currencies': newCurrenciesList
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
          closeNewEditCurrencyeDialog()
        })
        .catch((error) => console.error(error))
    } else {
      setTitleError(true)
    }
  }

  return (
    <>
      {/* New or Edit currency dialog */}
      <Dialog open={showNewEditCurrencyDialog} fullWidth maxWidth='sm'>
        {loading && <ComponentLoader />}

        <DialogTitle>{editMode ? t('edit') : t('add')}</DialogTitle>

        <DialogContent>
          <Grid container style={{ marginTop: 0 }} spacing={2}>
            {/* Title */}
            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label={t('title')}
                type='text'
                variant='outlined'
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                error={titleError}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/*
           * Delete button
           */}
          {editMode && (
            <Button size='small' color='error' onClick={() => deleteCurrency()}>
              {t('delete')}
            </Button>
          )}

          {/*
           * Update button
           */}
          {editMode && (
            <Button
              size='small'
              variant='contained'
              color='orange'
              onClick={() => updateCurrency()}
            >
              {t('update')}
            </Button>
          )}

          {/*
           *Add button
           */}
          {!editMode && (
            <Button
              size='small'
              variant='contained'
              color='orange'
              onClick={() => addCurrency()}
            >
              {t('add')}
            </Button>
          )}

          {/*
           * Cancel button
           */}
          <Button size='small' onClick={() => closeNewEditCurrencyeDialog()}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <BlockCard title={t('currencies')} avatar={faEuroSign as IconProp}>
        {/*
         * Show text when there are no currencies created
         */}
        {currenciesList.length === 0 && (
          <div
            style={{
              textAlign: 'center',
              fontSize: 14,
              margin: '20px 0 50px',
              opacity: 0.8
            }}
          >
            {t('there_are_no_added_currencies')}
          </div>
        )}

        {/*
         * List of added currencies
         */}
        {currenciesList.length > 0 && (
          <List style={{ marginBottom: 20 }}>
            {currenciesList.map((currency) => (
              <ListItem
                key={currency}
                style={{ justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/*
                   * Title
                   */}
                  {currency}
                </div>

                {/*
                 * Edit button
                 */}
                <Tooltip title={t('edit')} placement='top'>
                  <div
                    className='icon'
                    onClick={() => openEditCurrency(currency)}
                  >
                    <FontAwesomeIcon icon={faEdit as IconProp} />
                  </div>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        )}

        {/*
         * Add currency button
         */}
        <div
          style={{
            textAlign: 'center'
          }}
        >
          <Button
            color='orange'
            variant='contained'
            size='small'
            onClick={() => setShowNewEditCurrencyDialog(true)}
          >
            {t('add')}
          </Button>
        </div>
      </BlockCard>
    </>
  )
}

export default Currencies
