import React, { useCallback, useEffect, useState } from 'react'
import Loader from '../../Components/Loader'
import firebase from 'firebase/app'
import {
  Chip,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Snackbar,
  Alert,
  Grid
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { systemColors, systemStylingSettings } from '../../globalVariables'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { showGeneratedPdfBox } from '../../redux/actions/showGeneratedPdfBox'
import GeneratedPDFViewer from '../../Components/GeneratedPdfViewer'
import {
  convertDate,
  secondsToDate,
  addWeeksToDate
} from '../../globalFunctions'
import { useHistory } from 'react-router-dom'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

const useStyles = makeStyles({
  tableHeader: {
    color: systemColors.darkBlue,
    fontSize: 16
  },
  tabRoot: {
    maxWidth: 150,
    padding: 0,
    color: systemColors.lightBlue,
    opacity: 1,
    borderTop: `1px solid ${systemColors.lighGrey}`,
    borderBottom: `1px solid ${systemColors.lighGrey}`
  },
  tabsScroller: {
    flexGrow: 0
  },
  tabsIndicator: {
    backgroundColor: systemColors.orange
  },
  buttonEndIcon: {
    color: '#ffffff',
    '& > *:first-child': {
      fontSize: '1em'
    }
  },
  buttonRoot: {
    color: '#ffffff'
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: systemColors.orangeDark
    }
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  cardRoot: {
    marginBottom: 10
  },
  errorColor: {
    color: `${systemColors.errorColor} !important`
  },
  chipsDisplay: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: 5
    },
    marginTop: 20
  },
  tabsRoot: {
    justifyContent: 'center',
    marginTop: 20
  }
})

const CrewEmployeeView = ({ agencyData, agencyUserData, docId }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  // Data from database
  const [userData, setUserData] = useState()
  const [positionsArr, setPositionsArr] = useState([])
  const [vesselTypeArr, setVesselTypeArr] = useState([])
  const [engineTypeArr, setEngineTypeArr] = useState([])

  const [selectedTab, setSelectedTab] = useState(0)
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('')
  const [documentsLinks, setDocumentsLinks] = useState([])
  const [certificatesArr, setCertificatesArr] = useState([])

  const [canEditCV, setCanEditCV] = useState(false)

  const [companyLogoURL, setCompanyLogoURL] = useState('')

  // Get profile picture
  const getProfilePhoto = useCallback(
    (photoName) => {
      setLoading(true)
      storageRef
        .child(
          `agencies/${agencyData.id}/users-forms-uploads/${docId}/${photoName}`
        )
        .getDownloadURL()
        .then((url) => {
          setProfilePhotoUrl(url)
          setLoading(false)
        })
        .catch((err) => {
          console.log('Profile photo error:', err)
          setLoading(false)
        })
    },
    [agencyData, docId]
  )

  // Get documents links
  const getDocumentsLinks = useCallback(
    (arr) => {
      const getLinks = arr.map((obj) => {
        if (obj.upload !== '') {
          return new Promise((resp, rej) => {
            storageRef
              .child(
                `agencies/${agencyData.id}/users-forms-uploads/${docId}/${obj.upload}`
              )
              .getDownloadURL()
              .then((url) =>
                resp({
                  id: obj.id,
                  url
                })
              )
              .catch((err) =>
                rej({ message: "Can't get file url", error: err })
              )
          })
        } else {
          // eslint-disable-next-line
          return
        }
      })

      Promise.all(getLinks)
        .then((res) => {
          setDocumentsLinks(res)
        })
        .catch((err) => console.log(err.message, err.error))
    },
    [agencyData, docId]
  )

  // Get user cv from data base
  useEffect(() => {
    setLoading(true)
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .where('docId', '==', docId)
      .get()
      .then((res) => {
        res.forEach((doc) => {
          let data = JSON.stringify(doc.data())
          data = JSON.parse(data)

          setUserData(data)
          setLoading(false)

          // Change document title
          document.title = `${
            data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1)
          } 
                ${
                  data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1)
                } - Seanor`

          // set files links
          getProfilePhoto(data.profilePhoto)
          getDocumentsLinks(data.documentsArray)

          // Get company logo URL
          storage
            .ref()
            .child(
              `/public/agencies/${agencyData.id}/logo/${agencyData.logoFileName}`
            )
            .getDownloadURL()
            .then((url) => {
              setCompanyLogoURL(url)
            })
            .catch((error) => {
              console.log("Can't get logo url", error)
            })
          // })
        })
      })
      .catch((err) => {
        console.log('Get user information err', err)
        setLoading(false)
      })

    // Get positions data from database
    const getPositionArr = () => {
      db.collection('lists')
        .doc('positions')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setPositionsArr(data.list)
        })
        .catch((err) => console.log('Ranks list Error', err))
    }

    // Get vessel types
    const getVesselTypes = () => {
      db.collection('lists')
        .doc('type-of-vessel')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setVesselTypeArr(data.list)
        })
        .catch((err) => console.log('Vessel type err', err))
    }

    // Get engine types
    const getEngineTypes = () => {
      db.collection('lists')
        .doc('engin-type')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setEngineTypeArr(data.list)
        })
        .catch((err) => console.log('Engin type err', err))
    }

    // Get list of certificates from db
    const getCertificates = () => {
      db.collection('certificates')
        .doc('certificates')
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)
          setCertificatesArr(data.list)
        })
        .catch((err) => console.log('Certificates list Error', err))
    }

    getPositionArr()
    getCertificates()
    getVesselTypes()
    getEngineTypes()
  }, [docId, agencyData, getDocumentsLinks, getProfilePhoto])

  // Change selected tab value
  const handleTabsChange = (e, newValue) => {
    setSelectedTab(newValue)
  }

  // Find position name by id
  const getPositionName = (id) => {
    let name = ''
    positionsArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  // Get document name
  const getDocumentName = (id) => {
    let name = ''
    certificatesArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  // Get vessel type name
  const getVesselTypeName = (id) => {
    let name = ''
    vesselTypeArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  // Get engine type name
  const getEngineTypeName = (id) => {
    let name = ''
    engineTypeArr.find((el) => {
      if (el.id === parseInt(id)) {
        name = el.name
      }
      return name
    })
    return name
  }

  // Find and add document link
  const addDocumentLink = (id) => {
    let link = ''
    documentsLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  const renderVesselTypeList = (array) => {
    if (array !== undefined) {
      const sorted = array.sort((a, b) => a.id - b.id)
      return sorted.map((type, index) => (
        <div
          key={index}
          style={{
            fontWeight: type.type === 'group' && 'bold'
          }}
        >
          {t(type.name)}
        </div>
      ))
    }
    return null
  }

  return (
    <div>
      {loading && <Loader />}

      {userData && (
        <div>
          <Snackbar
            open={canEditCV}
            autoHideDuration={8000}
            onClose={() => setCanEditCV(!canEditCV)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity='warning' onClose={() => setCanEditCV(!canEditCV)}>
              {t('dontHavePermissionToEditCv')}
            </Alert>
          </Snackbar>

          {/* Chips */}
          <div className={classes.chipsDisplay}>
            <Grid
              container
              spacing={1}
              justifyContent='center'
              style={{ marginTop: 10 }}
            >
              {/*
               * PDF
               */}
              <Grid item>
                <Chip
                  label={
                    <GeneratedPDFViewer
                      userArr={userData}
                      profilePhotoUrl={profilePhotoUrl}
                      certificatesArr={certificatesArr}
                      ranksArr={positionsArr}
                      engineTypeArr={engineTypeArr}
                      vesselTypeArr={vesselTypeArr}
                      companyLogoURL={companyLogoURL}
                      type='crew'
                    />
                  }
                  clickable
                  icon={<FontAwesomeIcon icon={faDownload} />}
                />
              </Grid>

              {/*
               * Edit
               */}
              <Grid item>
                <Chip
                  label={t('edit')}
                  clickable
                  onClick={() =>
                    !agencyUserData.canEditCV
                      ? setCanEditCV(!canEditCV)
                      : history.push(
                          `/${agencyData.id}/dashboard/edit-crew/${userData.docId}`
                        )
                  }
                  icon={<FontAwesomeIcon icon={faUserEdit} />}
                />
              </Grid>

              {/*
               * Last update
               */}
              <Grid item>
                <Chip
                  label={`${t('lastUpdate')}: ${secondsToDate(
                    userData.date.seconds
                  )}`}
                />
              </Grid>
            </Grid>
          </div>

          {/* Tabs menu */}
          <Tabs
            value={selectedTab}
            onChange={handleTabsChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            <Tab label={t('basicInfo')} />
            <Tab label={t('salary')} />
            <Tab label={t('education')} />
            <Tab label={t('documents')} />
            <Tab label={t('experience')} />
            <Tab label={t('crewOrMarineAgencies')} />
            <Tab label={t('physicalState')} />
            <Tab label={t('coverLetterComments')} />
          </Tabs>

          {/* Display information acording selected tab */}
          <div>
            {/* Basic info */}
            {selectedTab === 0 && (
              <div className='crewEmployeeView__selectedContainer'>
                <div className='crewEmployeeView__basicInfo-box'>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('availableFrom')}:</span> {userData.availableFrom}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('firstName')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.firstName}
                    </span>
                  </div>
                  {userData.middleName !== '' ? (
                    <div className='crewEmployeeView__basicInfo-el'>
                      <span>{t('middleName')}:</span>{' '}
                      <span className='global__capitalizeFirstLetter'>
                        {userData.middleName}
                      </span>
                    </div>
                  ) : null}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('lastName')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.lastName}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('mainPosition')}:</span>{' '}
                    {getPositionName(userData.positionId)}
                  </div>
                  {userData.positionTwoId !== null &&
                    userData.positionTwoId !== undefined &&
                    userData.positionTwoId !== '' && (
                      <div className='crewEmployeeView__basicInfo-el'>
                        <span>{t('secondPosition')}:</span>{' '}
                        {getPositionName(userData.positionTwoId)}
                      </div>
                    )}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('birthDate')}:</span> {userData.birthDate}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('homeAddress')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.homeAddress}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('phone')}:</span> {userData.phoneMobile}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('email')}:</span> {userData.email}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('whatsappOrViberNo')}:</span>{' '}
                    <span>{userData.whatsappViber}</span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('cityCountry')}:</span>{' '}
                    <span>{userData.cityCountry}</span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('maritalStatus')}:</span> {userData.maritalStatus}
                  </div>
                  {userData.children !== '' ? (
                    <div className='crewEmployeeView__basicInfo-el'>
                      <span>{t('children')}:</span> {userData.children}
                    </div>
                  ) : null}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nationality')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.nationality}
                    </span>
                  </div>
                  {userData.birthPlace !== '' ? (
                    <div className='crewEmployeeView__basicInfo-el'>
                      <span>{t('birthPlace')}:</span>{' '}
                      <span className='global__capitalizeFirstLetter'>
                        {userData.birthPlace}
                      </span>
                    </div>
                  ) : null}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nearestAirpot')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.nearestAirport}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('englishLevel')}:</span> {userData.englishLevel}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nextToKin')}:</span> {userData.nextToKin}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nextToKinPhone')}:</span>{' '}
                    {userData.nextToKinPhone}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nextToKinEmail')}:</span>{' '}
                    {userData.nextToKinEmail}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nextToKinAddress')}:</span>{' '}
                    {userData.nextToKinAddress}
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('desiredContractDuration')}:</span>{' '}
                    {userData.contractDuration}
                  </div>

                  {/* Desired vessel type */}
                  <div
                    className='crewEmployeeView__basicInfo-el'
                    style={{ display: 'flex' }}
                  >
                    <span>{t('desiredVesselType')}:</span>{' '}
                    <div style={{ marginLeft: 20 }}>
                      {renderVesselTypeList(userData.desiredVesselType)}
                    </div>
                  </div>
                </div>

                {/* Photo */}
                <div className='crewEmployeeView__basicInfo-box'>
                  <div>
                    <img
                      className='crewEmployeeView__basicInfo-profilePicture'
                      src={profilePhotoUrl}
                      alt=''
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Salary */}
            {selectedTab === 1 && (
              <div className='crewEmployeeView__selectedContainer'>
                <div className='crewEmployeeView__salary'>
                  <div>
                    <span>{t('minimum')}:</span>
                    {userData.minSalary}
                    <span>$/{t('month')}</span>
                  </div>
                  <div>
                    <span>{t('expected')}:</span>
                    {userData.expectedSalary}
                    <span>$/{t('month')}</span>
                  </div>
                  <div>
                    <span>{t('lastSalary')}:</span>
                    {userData.lastSalary}
                    <span>$/{t('month')}</span>
                  </div>
                </div>
              </div>
            )}

            {/* Education */}
            {selectedTab === 2 && (
              <div className='crewEmployeeView__selectedContainer'>
                <div className='crewEmployeeView__education'>
                  <div>
                    <span>{t('licence')}:</span>
                    {userData.educationLicence}
                  </div>
                  <div>
                    <span>{t('number')}:</span>
                    {userData.educationNumber}
                  </div>
                  <div>
                    <span>{t('issued')}:</span>
                    {userData.educationIssued}
                  </div>
                  <div>
                    <span>{t('expires')}:</span>
                    {userData.educationExpires}
                  </div>
                  <div>
                    <span>{t('country')}:</span>
                    {userData.educationCountry}
                  </div>
                </div>
              </div>
            )}

            {/* Documents */}
            {selectedTab === 3 && (
              <div className='crewEmployeeView__selectedContainer'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t('document')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('number')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('placeOfIssue')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('issued')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('expires')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('file')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Passports & visas */}
                    <TableRow>
                      <TableCell colSpan={6} className='documentsSection'>
                        {t('passports&Visas')}
                      </TableCell>
                    </TableRow>
                    {userData.documentsArray.map((doc) => {
                      if (doc.docId.id < 40 || doc.docId.id >= 250) {
                        return (
                          <TableRow hover key={doc.id}>
                            <TableCell>
                              {certificatesArr &&
                                doc.docId !== null &&
                                getDocumentName(doc.docId.id)}
                            </TableCell>
                            <TableCell>{doc.number}</TableCell>
                            <TableCell>{doc.placeIssue}</TableCell>
                            <TableCell>{doc.issued}</TableCell>
                            <TableCell
                              className={
                                doc.expires !== '' && doc.expires !== undefined
                                  ? doc.expires <=
                                    convertDate(addWeeksToDate(2))
                                    ? classes.errorColor
                                    : null
                                  : null
                              }
                            >
                              {doc.expires !== '' && doc.expires !== undefined
                                ? doc.expires
                                : t('unlimited')}
                            </TableCell>
                            <TableCell>
                              {addDocumentLink(doc.id) !== '' && (
                                <a
                                  href={addDocumentLink(doc.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  {t('download')}
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        // eslint-disable-next-line
                        return
                      }
                    })}

                    {/* Diplomas & endorsements */}
                    <TableRow>
                      <TableCell colSpan={6} className='documentsSection'>
                        {t('diplomas&Endorsments')}
                      </TableCell>
                    </TableRow>
                    {userData.documentsArray.map((doc) => {
                      if (doc.docId.id >= 40 && doc.docId.id < 90) {
                        return (
                          <TableRow hover key={doc.id}>
                            <TableCell>
                              {certificatesArr &&
                                doc.docId !== null &&
                                getDocumentName(doc.docId.id)}
                            </TableCell>
                            <TableCell>{doc.number}</TableCell>
                            <TableCell>{doc.placeIssue}</TableCell>
                            <TableCell>{doc.issued}</TableCell>
                            <TableCell
                              className={
                                doc.expires !== '' && doc.expires !== undefined
                                  ? doc.expires <=
                                    convertDate(addWeeksToDate(2))
                                    ? classes.errorColor
                                    : null
                                  : null
                              }
                            >
                              {doc.expires !== '' && doc.expires !== undefined
                                ? doc.expires
                                : t('unlimited')}
                            </TableCell>
                            <TableCell>
                              {addDocumentLink(doc.id) !== '' && (
                                <a
                                  href={addDocumentLink(doc.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  {t('download')}
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        // eslint-disable-next-line
                        return
                      }
                    })}

                    {/* Certificates & licenses */}
                    <TableRow>
                      <TableCell colSpan={6} className='documentsSection'>
                        {t('certificates&Licenses')}
                      </TableCell>
                    </TableRow>
                    {userData.documentsArray.map((doc) => {
                      if (doc.docId.id >= 120 && doc.docId.id < 250) {
                        return (
                          <TableRow hover key={doc.id}>
                            <TableCell>
                              {certificatesArr &&
                                doc.docId !== null &&
                                getDocumentName(doc.docId.id)}
                            </TableCell>
                            <TableCell>{doc.number}</TableCell>
                            <TableCell>{doc.placeIssue}</TableCell>
                            <TableCell>{doc.issued}</TableCell>
                            <TableCell
                              className={
                                doc.expires !== '' && doc.expires !== undefined
                                  ? doc.expires <=
                                    convertDate(addWeeksToDate(2))
                                    ? classes.errorColor
                                    : null
                                  : null
                              }
                            >
                              {doc.expires !== '' && doc.expires !== undefined
                                ? doc.expires
                                : t('unlimited')}
                            </TableCell>
                            <TableCell>
                              {addDocumentLink(doc.id) !== '' && (
                                <a
                                  href={addDocumentLink(doc.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  {t('download')}
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        // eslint-disable-next-line
                        return
                      }
                    })}
                    {userData.documentsArray.map((doc) => {
                      if (doc.docId.id >= 110 && doc.docId.id < 120) {
                        return (
                          <TableRow hover key={doc.id}>
                            <TableCell>
                              {certificatesArr &&
                                doc.docId !== null &&
                                getDocumentName(doc.docId.id)}
                            </TableCell>
                            <TableCell>{doc.number}</TableCell>
                            <TableCell>{doc.placeIssue}</TableCell>
                            <TableCell>{doc.issued}</TableCell>
                            <TableCell
                              className={
                                doc.expires !== '' && doc.expires !== undefined
                                  ? doc.expires <=
                                    convertDate(addWeeksToDate(2))
                                    ? classes.errorColor
                                    : null
                                  : null
                              }
                            >
                              {doc.expires !== '' && doc.expires !== undefined
                                ? doc.expires
                                : t('unlimited')}
                            </TableCell>
                            <TableCell>
                              {addDocumentLink(doc.id) !== '' && (
                                <a
                                  href={addDocumentLink(doc.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  {t('download')}
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        // eslint-disable-next-line
                        return
                      }
                    })}

                    {/* Medical certificates */}
                    <TableRow>
                      <TableCell colSpan={6} className='documentsSection'>
                        {t('medicalCertificates')}
                      </TableCell>
                    </TableRow>
                    {userData.documentsArray.map((doc) => {
                      if (doc.docId.id >= 90 && doc.docId.id < 110) {
                        return (
                          <TableRow hover key={doc.id}>
                            <TableCell>
                              {certificatesArr &&
                                doc.docId !== null &&
                                getDocumentName(doc.docId.id)}
                            </TableCell>
                            <TableCell>{doc.number}</TableCell>
                            <TableCell>{doc.placeIssue}</TableCell>
                            <TableCell>{doc.issued}</TableCell>
                            <TableCell
                              className={
                                doc.expires !== '' && doc.expires !== undefined
                                  ? doc.expires <=
                                    convertDate(addWeeksToDate(2))
                                    ? classes.errorColor
                                    : null
                                  : null
                              }
                            >
                              {doc.expires !== '' && doc.expires !== undefined
                                ? doc.expires
                                : t('unlimited')}
                            </TableCell>
                            <TableCell>
                              {addDocumentLink(doc.id) !== '' && (
                                <a
                                  href={addDocumentLink(doc.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                  {t('download')}
                                </a>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        // eslint-disable-next-line
                        return
                      }
                    })}
                    {/* {userData.documentsArray.map((doc) => {
                      return (
                        <TableRow hover key={doc.id}>
                          <TableCell>
                            {(certificatesArr && doc.docId !== null) && getDocumentName(doc.docId.id)}
                          </TableCell>
                          <TableCell>{doc.number}</TableCell>
                          <TableCell>{doc.placeIssue}</TableCell>
                          <TableCell>{doc.issued}</TableCell>
                          <TableCell
                            className={
                              doc.expires !== '' && doc.expires !== undefined
                                ? doc.expires <= convertDate(addWeeksToDate(2))
                                    ? classes.errorColor
                                    : null
                                : null
                            }
                          >
                            {doc.expires !== '' && doc.expires !== undefined
                              ? doc.expires
                              : t('unlimited')}
                          </TableCell>
                          <TableCell>
                            {addDocumentLink(doc.id) !== ''
                              ? <a
                                  href={addDocumentLink(doc.id)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download
                                >
                                {t('download')}
                              </a>
                              : t('noFileAttached')}
                          </TableCell>
                        </TableRow>
                      )
                    })} */}
                  </TableBody>
                </Table>
              </div>
            )}

            {/* Experience */}
            {selectedTab === 4 && (
              <div className='crewEmployeeView__selectedContainer'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t('vesselName')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('flag')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('year')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('dwt')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('vesselType')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('engineType')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('hp')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('rank')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('from')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('till')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('shipOwner')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('crewAgency')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData.experienceArray.map((el) => {
                      return (
                        <TableRow key={el.id} hover>
                          <TableCell>{el.vesselName}</TableCell>
                          <TableCell>{el.flag}</TableCell>
                          <TableCell>{el.year}</TableCell>
                          <TableCell>{el.dwt}</TableCell>
                          <TableCell>
                            {getVesselTypeName(el.vesselType.id)}
                          </TableCell>
                          <TableCell>
                            {getEngineTypeName(el.engineType.id)}
                          </TableCell>
                          <TableCell>{el.hp}</TableCell>
                          <TableCell>{getPositionName(el.rankId.id)}</TableCell>
                          <TableCell>{el.fromDate}</TableCell>
                          <TableCell>{el.tillDate}</TableCell>
                          <TableCell>{el.shipOwner}</TableCell>
                          <TableCell>{el.crewAgency}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            )}

            {/* Crew or marine agencies */}
            {selectedTab === 5 && (
              <div className='crewEmployeeView__selectedContainer'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t('crewAgency')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('country')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('contactPerson')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('email')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('phone')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData.crewAgenciesArray.map((el) => {
                      return (
                        <TableRow key={el.id} hover>
                          <TableCell>{el.crewAgency}</TableCell>
                          <TableCell>{el.country}</TableCell>
                          <TableCell>{el.contactPerson}</TableCell>
                          <TableCell>{el.email}</TableCell>
                          <TableCell>{el.phone}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            )}

            {/* Physical state */}
            {selectedTab === 6 && (
              <div className='crewEmployeeView__selectedContainer'>
                <div className='crewEmployeeView__physicalState'>
                  <div>
                    <span>{t('heightCm')}:</span>
                    {userData.height}
                  </div>
                  <div>
                    <span>{t('weightKg')}:</span>
                    {userData.weight}
                  </div>
                  <div>
                    <span>{t('shoes')}:</span>
                    {userData.shoes}
                  </div>
                  <div>
                    <span>{t('clothes')}:</span>
                    {userData.clothes}
                  </div>
                  <div>
                    <span>{t('hairColor')}:</span>
                    {userData.hairColor}
                  </div>
                  <div>
                    <span>{t('eyeColor')}:</span>
                    {userData.eyeColor}
                  </div>
                </div>
              </div>
            )}

            {/* Comment */}
            {selectedTab === 7 && (
              <div className='crewEmployeeView__selectedContainer'>
                {userData.comment}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    showGeneratedPdfBox: state.showGeneratedPdfBoxRed.bool,
    agencyUserData: state.agencyUserDataRed.obj
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowGeneratedPdfBox: (bool) => dispatch(showGeneratedPdfBox(bool))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrewEmployeeView)
