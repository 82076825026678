import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComponentLoader from '../../../../../Components/ComponentLoader'
import { systemColors } from '../../../../../globalVariables'
import firebase from 'firebase/app'
import { connect } from 'react-redux'

const db = firebase.firestore()

const useStyles = makeStyles({
  card: {
    width: 'fit-content',
    height: '100%'
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: systemColors.darkBlue
  },
  cardHeader: {
    backgroundColor: systemColors.background,
    border: '1px solid #d6d6d6',
    borderBottom: `2px solid ${systemColors.orange}`
  }
})

const ProfilesByStatus = ({ agencyData }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [loadedData, setLoadedData] = useState(false)

  const [available, setAvailable] = useState(0)
  const [planned, setPlanned] = useState(0)
  const [onBoard, setOnBoard] = useState(0)
  const [signedOff, setSignedOff] = useState(0)

  useEffect(() => {
    let shouldUpdate = true

    const getAvailableCount = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .where('rotationLogStatus', '==', 'available')
        .get()
        .then((snap) => {
          if (shouldUpdate) {
            setAvailable(snap.size)
          }
          resolve()
        })
        .catch((error) => {
          console.log(error)
          resolve()
        })
    })

    const getPlannedCount = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .where('rotationLogStatus', '==', 'planned')
        .get()
        .then((snap) => {
          if (shouldUpdate) {
            setPlanned(snap.size)
          }
          resolve()
        })
        .catch((error) => {
          console.log(error)
          resolve()
        })
    })

    const getAtWorkCount = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .where('rotationLogStatus', '==', 'atWork')
        .get()
        .then((snap) => {
          if (shouldUpdate) {
            setOnBoard(snap.size)
          }
          resolve()
        })
        .catch((error) => {
          console.log(error)
          resolve()
        })
    })

    const getSignedOffCount = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .where('rotationLogStatus', 'in', ['atHome', 'medicine', 'courses'])
        .get()
        .then((snap) => {
          if (shouldUpdate) {
            setSignedOff(snap.size)
          }
          resolve()
        })
        .catch((error) => {
          console.log(error)
          resolve()
        })
    })

    Promise.all([
      getAvailableCount,
      getPlannedCount,
      getAtWorkCount,
      getSignedOffCount
    ]).then(() => {
      if (shouldUpdate) {
        setLoadedData(true)
      }
    })

    return () => {
      shouldUpdate = false
    }
  }, [agencyData.id])

  return (
    <div style={{ height: '100%' }}>
      <Card classes={{ root: classes.card }}>
        <CardHeader
          classes={{ root: classes.cardHeader, title: classes.title }}
          title={t('profilesByStatus')}
        />
        <CardContent style={{ position: 'relative' }}>
          {!loadedData && <ComponentLoader />}
          <List>
            {/* Available */}
            <ListItem>
              <ListItemText
                primary={
                  <div>
                    <span style={{ color: systemColors.statusAvailable }}>
                      {t('available')}:
                    </span>{' '}
                    <span>{available}</span>
                  </div>
                }
              />
            </ListItem>

            {/* Planned */}
            <ListItem>
              <ListItemText
                primary={
                  <div>
                    <span style={{ color: systemColors.statusPlanned }}>
                      {t('planned')}:
                    </span>{' '}
                    <span>{planned}</span>
                  </div>
                }
              />
            </ListItem>

            {/* At work */}
            <ListItem>
              <ListItemText
                primary={
                  <div>
                    <span style={{ color: systemColors.statusAtWork }}>
                      {t('atWork')}:
                    </span>{' '}
                    <span>{onBoard}</span>
                  </div>
                }
              />
            </ListItem>

            {/* Signed off */}
            <ListItem>
              <ListItemText
                primary={
                  <div>
                    <span style={{ color: systemColors.statusAtHome }}>
                      {t('signedOff')}:
                    </span>{' '}
                    <span>{signedOff}</span>
                  </div>
                }
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </div>
  )
}

const mapsStateToProps = (state) => ({
  agencyData: state.agencyDataRed.obj
})

export default connect(mapsStateToProps)(ProfilesByStatus)
