import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next/'
import firebase from 'firebase/app'
import {
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Tooltip,
  Grid,
  MenuItem,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Divider,
  Checkbox,
  FormControlLabel,
  Snackbar
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import {
  signOffReason as signOffReasonArr,
  systemColors,
  systemStylingSettings
} from '../../../globalVariables'
import { capitalizeString } from '../../../globalFunctions'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import Loader from '../../../Components/Loader'
import { v4 as uuid } from 'uuid'
import { showEditEmployeeMessage } from '../../../redux/actions/companies/showEditEmployeeMessage'
import { selectedEmployee } from '../../../redux/actions/companies/selectedEmployee'
import { dataFromSmartPlanning } from '../../../redux/actions/companies/dataFromSmartPlanning'
import { projectIdSmartPlanning } from '../../../redux/actions/companies/projectIdSmartPlanning'
import { refreshData } from '../../../redux/actions/refreshData'
import moment from 'moment'

const db = firebase.firestore()

const useStyles = makeStyles({
  chipsDisplay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: 5
    },
    marginTop: 20
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonText: {
    color: '#ffffff'
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  },
  noteRoot: {
    backgroundColor: systemColors.inputColor
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  buttonRoot: {
    color: '#ffffff'
  },
  cardRoot: {
    marginBottom: 10
  },
  cardActionRoot: {
    float: 'right'
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  accordionSummaryRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff',
    borderRadius: systemStylingSettings.borderRadius
  },
  expandIcon: {
    fontSize: 15,
    color: '#ffffff'
  },
  tableHeader: {
    fontWeight: 'bold'
  },
  projectTableHeader: {
    fontWeight: 'bold',
    fontSize: 12
  },
  projectTableCell: {
    fontSize: 12
  },
  projectTableCellDraft: {
    fontSize: 12,
    color: `${systemColors.electric} !important`
  },
  checkbox: {
    color: systemColors.electric
  }
})

const GetProjectEmployeesList = ({
  projectId,
  agencyData,
  companyId,
  userUID,
  refreshDataRedux,
  projectName,
  companyName,
  userData,
  openEmployeeEditBlock,
  selectedEmployeeData,
  setOpenEmployeeEditBlock,
  dataFromSmartPlanning,
  setDataFromSmartPlanning,
  setProjectIdSmartPlanning,
  projectIdSmartPlanning,
  setRefreshDataRedux
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [list, setList] = useState([])
  const [refreshData, setRefreshData] = useState(false)
  const [loading, setLoading] = useState(false)

  const [startOnContract, setStartOnContract] = useState('')
  const [startOnContractTime, setStartOnContractTime] = useState('')
  const [embarkation, setEmbarkation] = useState('')
  const [embarkationTime, setEmbarkationTime] = useState('')
  const [embarkationCity, setEmbarkationCity] = useState('')
  const [disembarkation, setDisembarkation] = useState('')
  const [disembarkationTime, setDisembarkationTime] = useState('')
  const [endOfContract, setEndOfContract] = useState('')
  const [endOfContractTime, setEndOfContractTime] = useState('')
  const [disembarkationCity, setDisembarkationCity] = useState('')
  const [signOffReason, setSignOffReason] = useState('')
  const [employeeData, setEmployeeData] = useState(null)
  const [archive, setArchive] = useState(false)
  const [documentId, setDocumentId] = useState('')

  const [showEditEmployeeMsg, setShowEditEmployeeMsg] = useState(false)

  const [message, setMessage] = useState({
    status: false,
    duration: 5000,
    severity: 'success',
    message: ''
  })

  const [removeTemplateWarningBlock, setRemoveTemplateWarningBlock] =
    useState(false)
  const [signOffWarningBlock, setSignOffWarningBlock] = useState(false)

  useEffect(() => {
    let shouldUpdate = true
    const getData = () => {
      // Update states from redux
      if (shouldUpdate) {
        setShowEditEmployeeMsg(openEmployeeEditBlock)
        setEmployeeData({
          firstName: selectedEmployeeData.firstName,
          lastName: selectedEmployeeData.lastName,
          position: selectedEmployeeData.position,
          status: selectedEmployeeData.status,
          id: selectedEmployeeData.id
        })
        setStartOnContract(selectedEmployeeData.startOnContract)
        setStartOnContractTime(selectedEmployeeData.startOnContractTime)
        setEmbarkation(selectedEmployeeData.embarkation)
        setEmbarkationTime(selectedEmployeeData.embarkationTime)
        setEmbarkationCity(selectedEmployeeData.embarkationCity)
        setDisembarkation(selectedEmployeeData.disembarkation)
        setDisembarkationTime(selectedEmployeeData.disembarkationTime)
        setEndOfContract(selectedEmployeeData.endOfContract)
        setEndOfContractTime(selectedEmployeeData.endOfContractTime)
        setDisembarkationCity(selectedEmployeeData.disembarkationCity)
        setSignOffReason(selectedEmployeeData.signOffReason)
        setArchive(selectedEmployeeData.draft)
        setDocumentId(selectedEmployeeData.documentId)
      }

      db.collection('agencies')
        .doc(agencyData.id)
        .collection('companies')
        .doc(companyId)
        .collection('projects')
        .doc(projectId)
        .collection('employees')
        .orderBy('startOnContract', 'asc')
        .get()
        .then((res) => {
          const arr = []
          res.forEach((el) => {
            let data = JSON.stringify(el.data())
            data = JSON.parse(data)
            arr.push(data)
          })
          if (shouldUpdate) {
            setList(arr)
          }
        })
    }
    getData()

    return () => {
      shouldUpdate = false
    }
  }, [
    agencyData,
    companyId,
    projectId,
    refreshData,
    refreshDataRedux,
    openEmployeeEditBlock,
    selectedEmployeeData
  ])

  const takeAttention = (project) => {
    const message = `${t('plaseTakeYourAttensionTo')} ${project}`
    enqueueSnackbar(message, {
      variant: 'warning',
      preventDuplicate: true
    })
  }

  const checkEndDate = (date) => {
    if (date !== '') {
      const twoWeeksFromNow = new Date()
      const endDate = new Date(date)
      const timeGap = endDate - twoWeeksFromNow
      const twoWeeksInMilliseconds = 1209600000
      if (timeGap < twoWeeksInMilliseconds) {
        setTimeout(() => {
          takeAttention(projectName)
        }, 500)
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const updateEmployee = () => {
    const updateEmployee = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('companies')
        .doc(companyId)
        .collection('projects')
        .doc(dataFromSmartPlanning ? projectIdSmartPlanning : projectId)
        .collection('employees')
        .doc(employeeData.id)
        .update({
          startOnContract: startOnContract,
          startOnContractTime: startOnContractTime,
          embarkationTime: embarkationTime,
          embarkation: embarkation,
          embarkationCity: embarkationCity,
          disembarkation: disembarkation,
          disembarkationTime: disembarkationTime,
          endOfContract: endOfContract,
          endOfContractTime: endOfContractTime,
          disembarkationCity: disembarkationCity,
          signOffReason: signOffReason,
          timeStamp: new Date(),
          whoEdited: userUID,
          draft: archive,
          status: archive ? 'draft' : 'active'
        })
        .then(() => resolve())
        .catch((err) => {
          console.log({
            message: "Can't update employee",
            error: err
          })
          resolve()
        })
    })

    const updateRotationLog = new Promise((resolve) => {
      if (!archive) {
        const rotationLogId = uuid()

        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(documentId)
          .get()
          .then((snap) => {
            let data = JSON.stringify(snap.data())
            data = JSON.parse(data)

            db.collection('agencies')
              .doc(agencyData.id)
              .collection('employees')
              .doc(documentId)
              .update({
                rotationLog: [
                  ...data.rotationLog,
                  {
                    id: rotationLogId,
                    timeStamp: new Date(),
                    createdTimeStamp: new Date(),
                    companyName,
                    projectName,
                    signOnDate: startOnContract,
                    signOnTime: startOnContractTime,
                    signOffDate: endOfContract,
                    signOffTime: endOfContractTime,
                    position: data.personalData.mainPosition,
                    editedBy: `${userData.name} ${userData.lastName}`,
                    status: 'atWork'
                  }
                ]
              })
              .then(() => {
                resolve()
              })
              .catch((error) => {
                console.log("Can't update employee rotationlog", error)
                resolve()
              })
          })
          .catch((error) => {
            console.log("Can't update rotation log", error)
          })
      } else {
        resolve()
      }
    })

    Promise.all([updateEmployee, updateRotationLog])
      .then(() => {
        setShowEditEmployeeMsg(false)
        setOpenEmployeeEditBlock(false)
        setRefreshDataRedux(!refreshDataRedux)
        setRefreshData(!refreshData)
      })
      .catch((err) => {
        console.log(err.message, err.error)
      })
  }

  const signOnEmployeeToProject = async () => {
    const rotationLogId = uuid()
    setLoading(true)

    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .doc(documentId)
      .get()
      .then((snap) => {
        let data = JSON.stringify(snap.data())
        data = JSON.parse(data)
        const employeeDataFromServer = data

        if (employeeDataFromServer.rotationLogStatus !== 'atWork') {
          const addEmployeeToProjectTable = new Promise((resolve) => {
            db.collection('agencies')
              .doc(agencyData.id)
              .collection('companies')
              .doc(companyId)
              .collection('projects')
              .doc(dataFromSmartPlanning ? projectIdSmartPlanning : projectId)
              .collection('employees')
              .doc(employeeData.id)
              .update({
                startOnContract: startOnContract,
                startOnContractTime: startOnContractTime,
                embarkationTime: embarkationTime,
                embarkation: embarkation,
                embarkationCity: embarkationCity,
                disembarkation: disembarkation,
                disembarkationTime: disembarkationTime,
                endOfContract: endOfContract,
                endOfContractTime: endOfContractTime,
                disembarkationCity: disembarkationCity,
                signOffReason: signOffReason,
                timeStamp: new Date(),
                whoEdited: userUID,
                draft: false,
                status: 'active',
                nationality: employeeDataFromServer.personalData.nationality,
                workStatus: 'atWork'
              })
              .then(() => resolve())
              .catch((err) => {
                console.log({
                  message: "Can't update employee",
                  error: err
                })
                resolve()
              })
          })

          const updateRotationLog = new Promise((resolve) => {
            db.collection('agencies')
              .doc(agencyData.id)
              .collection('employees')
              .doc(employeeDataFromServer.docId)
              .update({
                rotationLogStatus: 'atWork',
                rotationLog: [
                  ...employeeDataFromServer.rotationLog,
                  {
                    id: rotationLogId,
                    timeStamp: new Date(),
                    createdTimeStamp: new Date(),
                    companyName,
                    status: 'atWork',
                    projectName,
                    signOnDate: startOnContract,
                    signOnTime: startOnContractTime,
                    signOffDate: endOfContract,
                    signOffTime: endOfContractTime,
                    position: employeeDataFromServer.personalData.mainPosition,
                    editedBy: `${userData.name} ${userData.lastName}`,
                    companyId,
                    projectId,
                    recordIdInProject: employeeData.id,
                    embarkation,
                    embarkationCity,
                    embarkationTime,
                    disembarkation,
                    disembarkationTime,
                    disembarkationCity,
                    signOffReason
                  }
                ]
              })
              .then(() => {
                resolve()
              })
              .catch((error) => {
                console.log("Can't update employee rotationlog", error)
                resolve()
              })
          })

          Promise.all([addEmployeeToProjectTable, updateRotationLog])
            .then(() => {
              setShowEditEmployeeMsg(false)
              setOpenEmployeeEditBlock(false)
              setRefreshDataRedux(!refreshDataRedux)
              setStartOnContract('')
              setStartOnContractTime('')
              setEmbarkation('')
              setEmbarkationTime('')
              setEmbarkationCity('')
              setDisembarkation('')
              setDisembarkationTime('')
              setEndOfContract('')
              setEndOfContractTime('')
              setDisembarkationCity('')
              setSignOffReason('')
              setEmployeeData(null)
              setArchive(false)
              setDocumentId('')
              setRefreshData(!refreshData)
              setLoading(false)
              setMessage({
                status: true,
                duration: 3000,
                severity: 'success',
                message: t('employeeWasEmployed')
              })
            })
            .catch((err) => {
              console.log(err.message, err.error)
              setLoading(false)
            })
        } else {
          setLoading(false)
          setMessage({
            status: true,
            duration: 3000,
            severity: 'warning',
            message: t('thisEmployeeAlreadyisEmployed')
          })
        }
      })
      .catch((error) => {
        console.log("Can't get employee data from server", error)
        setLoading(false)
      })
  }

  const signOffEmployee = () => {
    if (archive === false && endOfContract !== '' && startOnContract !== '') {
      // Get days of sign on duration
      const startDate = moment(startOnContract)
      const endDate = moment(endOfContract)
      const contractDuration = endDate.diff(startDate, 'days') + 1

      const updateEmployee = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .collection('projects')
          .doc(dataFromSmartPlanning ? projectIdSmartPlanning : projectId)
          .collection('employees')
          .doc(employeeData.id)
          .update({
            startOnContract: startOnContract,
            startOnContractTime: startOnContractTime,
            embarkationTime: embarkationTime,
            embarkation: embarkation,
            embarkationCity: embarkationCity,
            disembarkation: disembarkation,
            disembarkationTime: disembarkationTime,
            endOfContract: endOfContract,
            endOfContractTime: endOfContractTime,
            disembarkationCity: disembarkationCity,
            signOffReason: signOffReason,
            timeStamp: new Date(),
            createdTimeStamp: new Date(),
            whoEdited: userUID,
            draft: archive,
            status: 'archive',
            workStatus: 'atHome'
          })
          .then(() => resolve())
          .catch((error) => {
            console.log({
              message: "Can't update employee",
              error
            })
            resolve()
          })
      })

      const updateRotationLog = new Promise((resolve) => {
        const rotationLogId = uuid()

        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(documentId)
          .get()
          .then((snap) => {
            let data = JSON.stringify(snap.data())
            data = JSON.parse(data)

            // Update latest rotationlog values
            const sortedRotationLogByDate = data.rotationLog.sort(
              (a, b) => b.createdTimeStamp.seconds - a.createdTimeStamp.seconds
            )[0].id

            const updatedOldRotationLog = data.rotationLog.map((el) => {
              if (el.id === sortedRotationLogByDate) {
                el.timeStamp = new Date()
                el.signOnDate = startOnContract
                el.signOnTime = startOnContractTime
                el.signOffDate = endOfContract
                el.signOffTime = endOfContractTime
                el.contractDurationInDays = contractDuration
              }
              return el
            })

            // Check if employee planned in ither projects and set status
            let rotationLogStatus = ''
            if (data.plannedOnProjects !== undefined) {
              if (data.plannedOnProjects.length > 0) {
                rotationLogStatus = 'planned'
              } else {
                rotationLogStatus = 'atHome'
              }
            } else {
              rotationLogStatus = 'atHome'
            }

            db.collection('agencies')
              .doc(agencyData.id)
              .collection('employees')
              .doc(documentId)
              .update({
                rotationLogStatus,
                rotationLog: [
                  ...updatedOldRotationLog,
                  {
                    id: rotationLogId,
                    timeStamp: new Date(),
                    createdTimeStamp: new Date(),
                    companyName,
                    projectName,
                    signOnDate: endOfContract,
                    signOnTime: endOfContractTime,
                    signOffDate: '',
                    signOffTime: '',
                    position: data.personalData.mainPosition,
                    editedBy: `${userData.name} ${userData.lastName}`,
                    status: 'atHome',
                    companyId,
                    projectId,
                    recordIdInProject: employeeData.id,
                    embarkation,
                    embarkationCity,
                    embarkationTime,
                    disembarkation,
                    disembarkationTime,
                    disembarkationCity,
                    signOffReason
                  }
                ]
              })
              .then(() => {
                resolve()
              })
              .catch((error) => {
                console.log("Can't update employee rotationlog", error)
                resolve()
              })
          })
          .catch((error) => {
            console.log("Can't update rotation log", error)
          })
      })

      Promise.all([updateEmployee, updateRotationLog])
        .then(() => {
          setSignOffReason('')
          setSignOffWarningBlock(false)
          setShowEditEmployeeMsg(false)
          setOpenEmployeeEditBlock(false)
          setRefreshDataRedux(!refreshDataRedux)
          setRefreshData(!refreshData)
        })
        .catch((err) => {
          console.log(err.message, err.error)
        })
    } else {
      setMessage({
        status: true,
        duration: 8000,
        severity: 'warning',
        message: t('toSignOffUserMustHaveEndContract')
      })
    }
  }

  const draftEmployeeColor = (status) => {
    if (status) {
      return { root: classes.projectTableCellDraft }
    } else {
      return { root: classes.projectTableCell }
    }
  }

  const removeEmployeeFromList = async () => {
    setLoading(true)

    // Fetch employee profile data
    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .doc(documentId)
      .get()
      .then(async (snap) => {
        let data = JSON.stringify(snap.data())
        data = JSON.parse(data)

        // Check if employee ever been in At Work status
        const atWorkStatusIndex = data.rotationLog.findIndex(
          (el) => el.status === 'atWork'
        )

        // Current employee status
        const rotationLogStatus = data.rotationLogStatus

        // Remove project id from plannedOnProjects array
        let plannedOnProcectsArrray = []
        if (data.plannedOnProjects !== undefined) {
          plannedOnProcectsArrray = data.plannedOnProjects
          const projectIndex = plannedOnProcectsArrray.findIndex(
            (id) => id === projectId
          )
          if (projectIndex > -1) {
            plannedOnProcectsArrray.splice(projectIndex, 1)
          }
        }

        // Remove employee from project
        await db
          .collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .collection('projects')
          .doc(dataFromSmartPlanning ? projectIdSmartPlanning : projectId)
          .collection('employees')
          .doc(employeeData.id)
          .delete()
          .then(async () => {
            // Update employee profile with new rotation log status
            const updateRotationLogStatus = new Promise((resolve) => {
              if (rotationLogStatus !== 'atWork') {
                let status = ''
                if (data.plannedOnProjects !== undefined) {
                  if (data.plannedOnProjects.length > 0) {
                    status = 'planned'
                  } else {
                    if (atWorkStatusIndex === -1) {
                      status = 'available'
                    } else {
                      status = 'atHome'
                    }
                  }
                } else {
                  if (atWorkStatusIndex === -1) {
                    status = 'available'
                  } else {
                    status = 'atHome'
                  }
                }

                db.collection('agencies')
                  .doc(agencyData.id)
                  .collection('employees')
                  .doc(documentId)
                  .update({
                    rotationLogStatus: status,
                    plannedOnProjects: plannedOnProcectsArrray
                  })
                  .then(() => resolve())
                  .catch((error) => {
                    console.log("Can't update rotation log status", error)
                    resolve()
                  })
              } else {
                resolve()
              }
            })

            await Promise.all([updateRotationLogStatus]).then(() => {
              setShowEditEmployeeMsg(false)
              setRemoveTemplateWarningBlock(false)
              setLoading(false)
              setRefreshData(!refreshData)
              setStartOnContract('')
              setStartOnContractTime('')
              setEmbarkation('')
              setEmbarkationTime('')
              setEmbarkationCity('')
              setDisembarkation('')
              setDisembarkationTime('')
              setEndOfContract('')
              setEndOfContractTime('')
              setDisembarkationCity('')
              setSignOffReason('')
              setEmployeeData(null)
              setArchive(false)
              setMessage({
                status: true,
                duration: 5000,
                severity: 'success',
                message: t('employeeRemovedFromList')
              })
            })
          })
          .catch((error) => {
            console.log("Can't remove employee from list", error)
            setLoading(false)
          })
      })
      .catch((error) => {
        setLoading(false)
        console.log("Can't fetch employee profile data", error)
        setMessage({
          status: true,
          duration: 5000,
          severity: 'warning',
          message: t('weCantRemoveEmployeeFromList')
        })
      })
  }

  return (
    <>
      {loading && <Loader />}

      {/* Edit employee pop up */}
      {employeeData !== null && (
        <Dialog
          open={showEditEmployeeMsg}
          classes={{ paper: classes.dialogRoot }}
          fullWidth
          maxWidth='md'
        >
          <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
            {capitalizeString(employeeData.firstName)}{' '}
            {capitalizeString(employeeData.lastName)}
            <div
              style={{
                fontSize: 14
              }}
            >
              {t(employeeData.position)}
            </div>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item sm={12} md={3}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='date'
                  label={t('startOnContract')}
                  value={startOnContract}
                  onChange={(e) => {
                    setStartOnContract(e.target.value)
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={t('departureFromHome')}
                />
              </Grid>

              <Grid item sm={12} md={2}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='time'
                  label={t('time')}
                  value={startOnContractTime}
                  onChange={(e) => setStartOnContractTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='date'
                  label={t('embarkation')}
                  value={embarkation}
                  onChange={(e) => setEmbarkation(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item sm={12} md={2}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='time'
                  label={t('time')}
                  value={embarkationTime}
                  onChange={(e) => setEmbarkationTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='text'
                  label={t('embarkationCity')}
                  value={embarkationCity}
                  onChange={(e) => setEmbarkationCity(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Divider style={{ margin: '20px 0' }} />

            <Grid container spacing={2}>
              <Grid item sm={12} md={3}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='date'
                  label={t('disembarkation')}
                  value={disembarkation}
                  onChange={(e) => setDisembarkation(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item sm={12} md={2}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='time'
                  label={t('time')}
                  value={disembarkationTime}
                  onChange={(e) => setDisembarkationTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='date'
                  label={t('endOfTheContract')}
                  value={endOfContract}
                  onChange={(e) => setEndOfContract(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={t('arrivalToHome')}
                />
              </Grid>

              <Grid item sm={12} md={2}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='time'
                  label={t('time')}
                  value={endOfContractTime}
                  onChange={(e) => setEndOfContractTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={t('arrivalToHome')}
                />
              </Grid>

              <Grid item sm={12} md={3}>
                <TextField
                  size='small'
                  style={{
                    marginTop: 15
                  }}
                  variant='outlined'
                  type='text'
                  label={t('disembarkationCity')}
                  value={disembarkationCity}
                  onChange={(e) => setDisembarkationCity(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Divider style={{ margin: '20px 0' }} />

            <Grid container spacing={2}>
              <Grid item sm={12} md={3}>
                <TextField
                  select
                  variant='outlined'
                  fullWidth
                  label={t('signOffReason')}
                  size='small'
                  value={signOffReason}
                  onChange={(e) => setSignOffReason(e.target.value)}
                >
                  {signOffReasonArr.map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {t(el)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {employeeData.status !== 'active' && (
                <Grid item sm={12} md={5}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={archive}
                        color='default'
                        classes={{ root: classes.checkbox }}
                        onChange={() => setArchive(!archive)}
                      />
                    }
                    label={t('planOn')}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>

          <DialogActions>
            {/* Remove template */}
            {archive && (
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => setRemoveTemplateWarningBlock(true)}
              >
                {t('removePlanned')}
              </Button>
            )}

            {/* Update */}
            {archive && (
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => updateEmployee()}
              >
                {t('update')}
              </Button>
            )}

            {/* Sign on */}
            {employeeData.status !== 'active' && (
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => signOnEmployeeToProject()}
              >
                {t('signOn')}
              </Button>
            )}

            {/* Sign off */}
            {employeeData.status === 'active' && (
              <Button
                variant='contained'
                size='small'
                color='orange'
                onClick={() => setSignOffWarningBlock(true)}
              >
                {t('signOff')}
              </Button>
            )}

            {/* Cancel */}
            <Button
              onClick={() => {
                setShowEditEmployeeMsg(false)
                setStartOnContract('')
                setStartOnContractTime('')
                setEmbarkation('')
                setEmbarkationTime('')
                setEmbarkationCity('')
                setDisembarkation('')
                setDisembarkationTime('')
                setEndOfContract('')
                setEndOfContractTime('')
                setDisembarkationCity('')
                setSignOffReason('')
                setEmployeeData(null)
                setArchive(false)
                setDocumentId('')
                setOpenEmployeeEditBlock(false)
                setDataFromSmartPlanning(false)
                setProjectIdSmartPlanning('')
              }}
            >
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Remove template message */}
      <Dialog
        open={removeTemplateWarningBlock}
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>

        <DialogContent>{t('removeTemplateFromProject')}</DialogContent>

        <DialogActions>
          <Button onClick={() => removeEmployeeFromList()}>
            {t('removePlanned')}
          </Button>

          <Button
            onClick={() => {
              setRemoveTemplateWarningBlock(false)
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Sign off employee message */}
      <Dialog
        open={signOffWarningBlock}
        classes={{ paper: classes.dialogRoot }}
      >
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          {t('areYouShure')}
        </DialogTitle>
        <DialogContent>{t('signOffEmployeeFromProject')}</DialogContent>
        <DialogActions>
          <Button onClick={() => signOffEmployee()}>{t('signOff')}</Button>

          <Button
            onClick={() => {
              setSignOffWarningBlock(false)
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbars */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.projectTableHeader }}>
              {t('nameLastName')}
            </TableCell>
            <TableCell classes={{ root: classes.projectTableHeader }}>
              {t('position')}
            </TableCell>
            <TableCell classes={{ root: classes.projectTableHeader }}>
              {t('startOnContract')}
            </TableCell>
            <TableCell classes={{ root: classes.projectTableHeader }}>
              {t('endOfTheContract')}
            </TableCell>
            <TableCell classes={{ root: classes.projectTableHeader }} />
          </TableRow>
        </TableHead>

        <TableBody>
          {list.map((el, i) => {
            if (el.status === 'active' || el.status === 'draft') {
              // eslint-disable-next-line
              return (
                <TableRow key={el.id} hover>
                  <TableCell classes={draftEmployeeColor(el.draft)}>
                    <div
                      className='getProjectsEmployeesList__link'
                      onClick={() =>
                        window.open(
                          `/${agencyData.id}/key-dashboard/employee-view/${el.documentId}`
                        )
                      }
                    >
                      {capitalizeString(el.firstName)}{' '}
                      {capitalizeString(el.lastName)}
                    </div>
                  </TableCell>
                  <TableCell classes={draftEmployeeColor(el.draft)}>
                    {t(el.position)}
                  </TableCell>
                  <TableCell classes={draftEmployeeColor(el.draft)}>
                    {el.startOnContract} {el.startOnContractTime}
                  </TableCell>
                  <TableCell classes={draftEmployeeColor(el.draft)}>
                    {checkEndDate(el.endOfContract) ? (
                      <Tooltip
                        title={t('endInlessThenTwoWeeks')}
                        placement='top'
                      >
                        <div className='error' style={{ fontSize: 12 }}>
                          {el.endOfContract} {el.endOfContractTime}
                        </div>
                      </Tooltip>
                    ) : (
                      <div>
                        {el.endOfContract} {el.endOfContractTime}
                      </div>
                    )}
                  </TableCell>
                  <TableCell classes={draftEmployeeColor(el.draft)}>
                    <Tooltip title={t('edit')} placement='top'>
                      <div
                        className='getProjectsEmployeesList__editButton'
                        onClick={() => {
                          setStartOnContract(el.startOnContract)
                          setStartOnContractTime(el.startOnContractTime)
                          setEmbarkation(el.embarkation)
                          setEmbarkationTime(el.embarkationTime)
                          setEmbarkationCity(el.embarkationCity)
                          setDisembarkation(el.disembarkation)
                          setDisembarkationTime(el.disembarkationTime)
                          setEndOfContract(el.endOfContract)
                          setEndOfContractTime(el.endOfContractTime)
                          setDisembarkationCity(el.disembarkationCity)
                          setSignOffReason(el.signOffReason)
                          setArchive(el.draft)
                          setEmployeeData({
                            firstName: el.firstName,
                            lastName: el.lastName,
                            position: el.position,
                            status: el.status,
                            id: el.id
                          })
                          setDocumentId(el.documentId)
                          setShowEditEmployeeMsg(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </div>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            } else {
              // eslint-disable-next-line
              return
            }
          })}
        </TableBody>
      </Table>
    </>
  )
}

const mapStateToProps = (state) => ({
  refreshDataRedux: state.refreshDataRed.bool,
  userData: state.userDataRed.obj,
  openEmployeeEditBlock: state.showEditEmployeeMessageReducer.bool,
  selectedEmployeeData: state.selectedEmployeeReducer.obj,
  dataFromSmartPlanning: state.dataFromSmartPlanningReducer.bool,
  projectIdSmartPlanning: state.projectIdSmartPlanningReducer.id
})

const dispatchStateToProps = (dispatch) => ({
  setOpenEmployeeEditBlock: (bool) => dispatch(showEditEmployeeMessage(bool)),
  setSelectedEmployeeData: (object) => dispatch(selectedEmployee(object)),
  setDataFromSmartPlanning: (bool) => dispatch(dataFromSmartPlanning(bool)),
  setProjectIdSmartPlanning: (string) =>
    dispatch(projectIdSmartPlanning(string)),
  setRefreshDataRedux: (bool) => dispatch(refreshData(bool))
})

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(GetProjectEmployeesList)
