import { useEffect, useState } from 'react'
import { faMinusSquare, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Card,
  CardContent,
  CardHeader,
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { systemColors } from '../../../../globalVariables'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import { refreshData } from '../../../../redux/actions/refreshData'
import ComponentLoader from '../../../../Components/ComponentLoader'
import { secondsToDate } from '../../../../globalFunctions'

const db = firebase.firestore()

const useStyles = makeStyles({
  card: {
    width: 'fit-content',
    maxWidth: 600,
    height: '100%'
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: systemColors.darkBlue
  },
  cardHeader: {
    backgroundColor: systemColors.background,
    border: '1px solid #d6d6d6',
    borderBottom: `2px solid ${systemColors.orange}`
  },
  removeCardIcon: {
    padding: '10px 6px 0 50px',
    cursor: 'pointer',
    color: systemColors.grey,
    '&:hover': {
      color: systemColors.darkGrey
    }
  },
  tableHeader: {
    fontWeight: 600
  }
})

const LastOnlineApplicants = ({
  agencyData,
  userUid,
  refreshData,
  setRefreshData
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const [loadedData, setLoadedData] = useState(false)

  const [profilesList, setProfilesList] = useState([])

  const [message, setMessage] = useState({
    status: false,
    duration: 3000,
    severity: 'success',
    message: ''
  })

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  useEffect(() => {
    let shouldUpdate = true

    ;(() => {
      // Get 60 days from now
      const seconds = 60 * 24 * 60 * 60
      const nowSeconds = new Date().getTime() / 1000
      const startSeconds = new Date((nowSeconds - seconds) * 1000)

      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .where('addType', '==', 'online')
        .where('timeStamp', '>=', startSeconds)
        .orderBy('timeStamp', 'desc')
        .get()
        .then((response) => {
          const array = []
          response.forEach((item) => {
            let data = JSON.stringify(item.data())
            data = JSON.parse(data)

            array.push(data)
          })

          if (shouldUpdate) {
            setProfilesList(array)
            setLoadedData(true)
          }
        })
        .catch((error) => {
          console.log("Can't get online applicants list", error)
          if (shouldUpdate) {
            setLoadedData(true)
          }
        })
    })()

    return () => {
      shouldUpdate = false
    }
  }, [agencyData])

  const removeBlockFromDashboard = async () => {
    await db
      .collection('users')
      .doc(userUid)
      .update({
        'data.settings.userSettings.dashboard.allowLastOnlineApplicantsKEY': false
      })
      .then(() => {
        setRefreshData(!refreshData)
      })
      .catch((error) => {
        console.log("Can't update users settings", error)
        setMessage({
          status: true,
          duration: 5000,
          severity: 'warning',
          message: t('thereIsProblemWithRemoving1644648')
        })
      })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <div style={{ height: '100%' }}>
      {/* warnings */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      <Card classes={{ root: classes.card }}>
        <CardHeader
          classes={{ root: classes.cardHeader, title: classes.title }}
          title={t('lastOnlineApplicants')}
          action={
            <Tooltip title={t('removeItemFromDashboard1354')} placement='top'>
              <div
                className={classes.removeCardIcon}
                onClick={() => removeBlockFromDashboard()}
              >
                <FontAwesomeIcon icon={faMinusSquare} />
              </div>
            </Tooltip>
          }
        />
        <CardContent style={{ position: 'relative' }}>
          {!loadedData && <ComponentLoader />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>
                  {t('name')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('nationality')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('mainPosition')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('addEditDate')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {profilesList.length > 0 ? (
                profilesList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    let verify = false
                    if (item.addType !== 'agency') {
                      if (item.verified !== undefined) {
                        verify = item.verified.status
                      } else {
                        verify = false
                      }
                    } else {
                      verify = true
                    }
                    return (
                      <TableRow
                        key={item.docId}
                        hover
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          history.push(
                            `/${agencyData.id}/key-dashboard/employee-view/${item.docId}`
                          )
                        }}
                      >
                        {/* Name */}
                        <TableCell className='global__capitalizeFirstLetter'>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Tooltip
                              title={
                                verify
                                  ? t('profileIsVerified')
                                  : t('profileIsNotVerified')
                              }
                              placement='top'
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  marginRight: 10
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCheckDouble}
                                  style={{
                                    color: verify
                                      ? systemColors.green
                                      : systemColors.grey
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <div style={{ display: 'inline-block' }}>
                              {item.personalData.firstName}{' '}
                              {item.personalData.lastName}
                            </div>
                          </div>
                        </TableCell>

                        {/* Nationality */}
                        <TableCell className='global__capitalizeFirstLetter'>
                          {item.personalData.nationality}
                        </TableCell>

                        {/* Main position */}
                        <TableCell>
                          {t(item.personalData.mainPosition)}
                        </TableCell>

                        {/* Date */}
                        <TableCell>
                          <div style={{ minWidth: 75 }}>
                            {secondsToDate(item.timeStamp.seconds)}
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{
                      textAlign: 'center',
                      color: systemColors.lighGrey
                    }}
                  >
                    {t('thereIsNoAddedProfiles')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component='div'
            count={profilesList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('rowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${t('of')} ${
                count !== -1 ? count : t('moreThanTo') + to
              }`
            }
            nextIconButtonProps={{
              'aria-label': t('next'),
              title: t('next')
            }}
            backIconButtonProps={{
              'aria-label': t('back'),
              title: t('back')
            }}
          />
        </CardContent>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  refreshData: state.refreshDataRed.bool
})

const dispatchStateToProps = (dispatch) => ({
  setRefreshData: (bool) => dispatch(refreshData(bool))
})

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(LastOnlineApplicants)
