import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert
} from '@mui/material'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import ComponentLoader from '../../../../Components/ComponentLoader'

const db = firebase.firestore()

interface Props {
  open: boolean
  onClose: () => void
  agencyData: any
  agencyUserData: any
  projectName: string
  projectId: string
  companyId: string
  companyName: string
  candidate: RecruitmentCandidate | null
}

const AddCandidateToProject = ({
  open,
  onClose,
  agencyData,
  agencyUserData,
  projectName,
  projectId,
  companyId,
  companyName,
  candidate
}: Props) => {
  const { t } = useTranslation()

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)

  const [employeeData, setSmployeeData] = useState<KeyEmployeeProfile | null>(
    null
  )

  const [startOnContract, setStartOnContract] = useState<string>('')
  const [startOnContractTime, setstartOnContractTime] = useState<string>('')
  const [embarkation, setEmbarkation] = useState<string>('')
  const [embarkationTime, setEmbarkationTime] = useState<string>('')
  const [embarkationCity, setEmbarkationCity] = useState<string>('')
  const [endOfContract, setEndOfContract] = useState<string>('')
  const [endOfContractTime, setEndOfContractTime] = useState<string>('')
  const [saveTemplate, setSaveTemplate] = useState<boolean>(false)

  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>({
    status: false,
    duration: 5000,
    severity: 'success',
    message: ''
  })

  useEffect(() => {
    let shouldUpdate = true

    if (shouldUpdate) {
      setOpenDialog(open)
    }

    if (candidate !== null) {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .where('docId', '==', candidate.profileId)
        .get()
        .then((response) => {
          let employee: KeyEmployeeProfile | null = null

          response.forEach((el) => {
            let data: any = JSON.stringify(el.data())
            data = JSON.parse(data)

            employee = data
          })

          if (shouldUpdate) {
            setSmployeeData(employee)
            setLoading(false)
          }
        })
        .catch((error) => console.error(error))
    }

    return () => {
      shouldUpdate = false
    }
  }, [open, agencyData, candidate])

  const closeMainDialog = () => {
    onClose()

    setStartOnContract('')
    setstartOnContractTime('')
    setEmbarkation('')
    setEmbarkationTime('')
    setEmbarkationCity('')
    setEndOfContract('')
    setEndOfContractTime('')
    setSaveTemplate(false)
  }

  /** Add when selected "Plan on" */
  const addEmployeeToProject = () => {
    if (candidate !== null && employeeData !== null) {
      setLoading(true)
      const id = uuid()

      const addEmployeeToProjectTable = new Promise((resolve) => {
        const employee: KeyProjectEmployee = {
          id,
          documentId: employeeData.docId,
          firstName: employeeData.personalData.firstName,
          lastName: employeeData.personalData.lastName,
          status: saveTemplate ? 'draft' : 'active',
          startOnContract,
          startOnContractTime,
          embarkation,
          embarkationTime,
          embarkationCity,
          disembarkation: '',
          disembarkationTime: '',
          endOfContract,
          endOfContractTime,
          disembarkationCity: '',
          signOffReason: '',
          timeStamp: new Date(),
          createdTimeStamp: new Date(),
          whoAdded: agencyUserData.uid,
          whoEdited: '',
          position: employeeData.personalData.mainPosition,
          draft: saveTemplate,
          nationality: employeeData.personalData.nationality,
          workStatus: 'planned'
        }

        db.collection('agencies')
          .doc(agencyData.id)
          .collection('companies')
          .doc(companyId)
          .collection('projects')
          .doc(projectId)
          .collection('employees')
          .doc(id)
          .set(employee)
          .then(() => {
            resolve(null)
          })
          .catch((err) =>
            console.log({
              message: "Can't add user to project",
              error: err
            })
          )
      })

      const updateEmployeeData = new Promise((resolve) => {
        // Add project id to plannedOnProjects array
        let newPlannedOnProjectArray: string[] = []
        if (employeeData.plannedOnProjects !== undefined) {
          newPlannedOnProjectArray = [
            ...employeeData.plannedOnProjects,
            projectId
          ]
        } else {
          newPlannedOnProjectArray = [projectId]
        }

        // Change rotationLogStatus if employee not have 'atWork' status
        const updateObject = {
          plannedOnProjects: newPlannedOnProjectArray,
          rotationLogStatus: employeeData.rotationLogStatus
        }

        if (employeeData.rotationLogStatus !== 'atWork') {
          updateObject.rotationLogStatus = 'planned'
        }

        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(employeeData.docId)
          .update(updateObject)
          .then(() => resolve(null))
          .catch((error) => {
            console.error("Can't update rotation log status", error)
            resolve(null)
          })
      })

      Promise.all([addEmployeeToProjectTable, updateEmployeeData])
        .then(() => {
          closeMainDialog()
        })
        .catch((err) => {
          console.log(err.message, err.error)
        })
    }
  }

  /** Add when "Sign on" */
  const signOnEmployeeToProject = () => {
    if (employeeData !== null) {
      if (employeeData.rotationLogStatus !== 'atWork') {
        setLoading(true)
        const id: string = uuid()
        const rotationLogId: string = uuid()

        const employee: KeyProjectEmployee = {
          id,
          documentId: employeeData.docId,
          firstName: employeeData.personalData.firstName,
          lastName: employeeData.personalData.lastName,
          status: saveTemplate ? 'draft' : 'active',
          startOnContract,
          startOnContractTime,
          embarkation,
          embarkationTime,
          embarkationCity,
          disembarkation: '',
          disembarkationTime: '',
          endOfContract,
          endOfContractTime,
          disembarkationCity: '',
          signOffReason: '',
          timeStamp: new Date(),
          createdTimeStamp: new Date(),
          whoAdded: agencyUserData.uid,
          whoEdited: '',
          position: employeeData.personalData.mainPosition,
          draft: saveTemplate,
          nationality: employeeData.personalData.nationality,
          workStatus: 'atWork'
        }

        const addEmployeeToProjectTable = new Promise((resolve) => {
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('companies')
            .doc(companyId)
            .collection('projects')
            .doc(projectId)
            .collection('employees')
            .doc(id)
            .set(employee)
            .then(() => {
              resolve(null)
            })
            .catch((err) =>
              console.error({
                message: "Can't add user to project",
                error: err
              })
            )
        })

        const updateRotationLog = new Promise((resolve) => {
          const newRotationLog: EmployeeRotationLog = {
            id: rotationLogId,
            timeStamp: new Date(),
            createdTimeStamp: new Date(),
            companyName,
            status: 'atWork',
            projectName: projectName,
            signOnDate: startOnContract,
            signOnTime: startOnContractTime,
            signOffDate: endOfContract,
            signOffTime: endOfContractTime,
            position: employeeData.personalData.mainPosition,
            editedBy: '',
            companyId,
            projectId,
            recordIdInProject: id,
            embarkation,
            embarkationCity,
            embarkationTime,
            disembarkation: '',
            disembarkationTime: '',
            disembarkationCity: '',
            signOffReason: '',
            contractDurationInDays: 0
          }
          db.collection('agencies')
            .doc(agencyData.id)
            .collection('employees')
            .doc(employeeData.docId)
            .update({
              rotationLogStatus: 'atWork',
              rotationLog: [...employeeData.rotationLog, newRotationLog]
            })
            .then(() => {
              resolve(null)
            })
            .catch((error) => {
              console.error("Can't update employee rotationlog", error)
              resolve(null)
            })
        })

        Promise.all([addEmployeeToProjectTable, updateRotationLog])
          .then(() => {
            closeMainDialog()
          })
          .catch((err) => {
            console.log(err.message, err.error)
          })
      } else {
        setSnackbarMessage({
          status: true,
          duration: 3000,
          severity: 'warning',
          message: t('thisEmployeeAlreadyisEmployed')
        })
      }
    }
  }

  return (
    <>
      {/* Messeges */}
      <Snackbar
        open={snackbarMessage.status}
        autoHideDuration={snackbarMessage.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() =>
          setSnackbarMessage({ ...snackbarMessage, status: false })
        }
      >
        <Alert
          severity={snackbarMessage.severity}
          onClose={() =>
            setSnackbarMessage({ ...snackbarMessage, status: false })
          }
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>

      <Dialog open={openDialog} fullWidth maxWidth='md'>
        <DialogTitle>
          {t('add_to_project')} {projectName}
        </DialogTitle>

        <DialogContent style={{ position: 'relative' }}>
          {loading && <ComponentLoader />}

          <Grid container spacing={2} style={{ marginTop: 20 }}>
            {/* Start on contract date */}
            <Grid item sm={12} md={3}>
              <TextField
                size='small'
                type='date'
                label={t('startOnContract')}
                value={startOnContract}
                onChange={(e) => setStartOnContract(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                helperText={t('departureFromHome')}
              />
            </Grid>

            {/* Time */}
            <Grid item sm={12} md={2}>
              <TextField
                size='small'
                type='time'
                label={t('time')}
                value={startOnContractTime}
                onChange={(e) => setstartOnContractTime(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            {/* Embarkation */}
            <Grid item sm={12} md={3}>
              <TextField
                size='small'
                type='date'
                label={t('embarkation')}
                value={embarkation}
                onChange={(e) => setEmbarkation(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            {/* Embarkation time */}
            <Grid item sm={12} md={2}>
              <TextField
                size='small'
                type='time'
                label={t('time')}
                value={embarkationTime}
                onChange={(e) => setEmbarkationTime(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            {/* Embarkation city */}
            <Grid item sm={12} md={3}>
              <TextField
                size='small'
                type='text'
                label={t('embarkationCity')}
                value={embarkationCity}
                onChange={(e) => setEmbarkationCity(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          <Divider style={{ margin: '30px 0' }} />

          <Grid container spacing={2}>
            {/* End of contract date */}
            <Grid item sm={12} md={3}>
              <TextField
                size='small'
                type='date'
                label={t('endOfTheContract')}
                value={endOfContract}
                onChange={(e) => setEndOfContract(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                helperText={t('arrivalToHome')}
              />
            </Grid>

            {/* End of contract time */}
            <Grid item sm={12} md={2}>
              <TextField
                size='small'
                type='time'
                label={t('time')}
                value={endOfContractTime}
                onChange={(e) => setEndOfContractTime(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            {/* Plan on */}
            <Grid item sm={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    color='default'
                    onChange={() => setSaveTemplate(!saveTemplate)}
                  />
                }
                label={t('planOn')}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {saveTemplate ? (
            <Button
              variant='containedRed'
              size='small'
              onClick={() => addEmployeeToProject()}
            >
              {t('addToProject')}
            </Button>
          ) : (
            <Button
              variant='containedRed'
              size='small'
              onClick={() => signOnEmployeeToProject()}
            >
              {t('signOn')}
            </Button>
          )}

          {/* Close dialog */}
          <Button onClick={() => closeMainDialog()}>{t('cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    agencyData: state.agencyDataRed.obj,
    agencyUserData: state.agencyUserDataRed.obj
  }
}

export default connect(mapStateToProps)(AddCandidateToProject)
