import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import firebase from 'firebase/app'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Tooltip,
  Autocomplete
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Loader from '../../Components/Loader'
import Button from '../../Components/Button'
import {
  systemColors,
  systemStylingSettings,
  whenCanStartArr,
  educationVariants,
  specialityOptions,
  languagesOptions,
  rotationLogStatus,
  profileStatus
} from '../../globalVariables'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faChevronDown,
  faList,
  faBars,
  faUser,
  faCheckDouble
} from '@fortawesome/free-solid-svg-icons'
import Headline from '../../Components/Headline'
import { countries } from '../../countries'
import StatusChip from '../../Components/StatusChip'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

const useStyles = makeStyles({
  tableHeader: {
    fontSize: 16
  },
  tableRow: {
    cursor: 'pointer'
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: systemColors.inputColor,
      borderRadius: systemStylingSettings.borderRadius,
      '&.Mui-focused fieldset': {
        color: systemColors.darkBlue,
        borderColor: systemColors.darkBlue,
        borderWidth: systemStylingSettings.borderWidth
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 8
      }
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    }
  },
  expandIcon: {
    fontSize: 15
  },
  accordionRoot: {
    boxShadow: 'none',
    border: `1px solid ${systemColors.darkBlue}`,
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background,
    marginTop: 50
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  listIconSelected: {
    color: systemColors.orange
  }
})

const KeyEmployessList = ({ agencyData }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  // Change document title
  document.title = `${t('keyLandWorkersDatabase')} - Seanor`

  // Filter result limit
  const resultLimit = 30

  const [loading, setLoading] = useState(false)

  const [listViewSelected, setListViewSelected] = useState(true)
  const [pictureListViewSelected, setPictureListViewSelected] = useState(false)
  const [mergedPositions, setMergedPositions] = useState([])

  // Lists from database
  // eslint-disable-next-line
  const [lastItemOnList, setLastItemOnList] = useState(null)
  const [profilePhotoUrlList, setProfilePhotoUrlList] = useState([])

  // Filter inputs
  const [filterFirstName, setFilterFirstName] = useState('')
  const [filterLastName, setFilterLastName] = useState('')
  const [filterPosition, setFilterPosition] = useState('')
  const [filterWhenCanStart, setFilterWhenCanStart] = useState('')
  const [filterEducation, setFilterEducation] = useState('')
  const [filterSpeciality, setFilterSpeciality] = useState('')
  const [filterCountry, setFilterCountry] = useState('')
  const [filterLanguage, setFilterLanguage] = useState('')
  const [filterStatus, setFilterStatus] = useState('')
  const [filterProfileStatus, setfilterProfileStatus] = useState('active')

  const [element, setElement] = useState(null)
  const [filtering, setFiltering] = useState(false)

  // References
  const employeeList = useRef([])
  const lastItem = useRef(null)

  const getProfilePhotoUrl = useCallback(() => {
    const urlList = employeeList.current.map((obj) => {
      if (
        obj.personalData.profilePhoto !== null &&
        obj.personalData.profilePhoto !== ''
      ) {
        // eslint-disable-next-line
        return new Promise((res) => {
          storageRef
            .child(
              `agencies/${agencyData.id}/users-forms-uploads/${obj.docId}/${obj.personalData.profilePhoto}`
            )
            .getDownloadURL()
            .then((url) => {
              res({
                id: obj.docId,
                url
              })
            })
        })
      } else {
        // eslint-disable-next-line
        return
      }
    })
    Promise.all(urlList).then((res) => {
      setProfilePhotoUrlList(res)
      setLoading(false)
    })
  }, [agencyData])

  // Get employees list
  const getAllEmployees = useCallback(async () => {
    setLoading(true)
    await db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .orderBy('timeStamp', 'desc')
      .limit(resultLimit)
      .get()
      .then((res) => {
        const arr = []
        res.forEach((el) => {
          let data = JSON.stringify(el.data())
          data = JSON.parse(data)
          if (data.profileStatus === 'active') {
            arr.push(data)
          }
        })
        // setEmployeesList(arr)
        employeeList.current = arr
        lastItem.current = res.docs[res.docs.length - 1]
        getProfilePhotoUrl()
        setLoading(false)
      })
      .catch((err) => console.log('Get user error', err))
  }, [agencyData, getProfilePhotoUrl])

  const resetFilter = () => {
    setLoading(true)
    // Reset states
    setFilterFirstName('')
    setFilterLastName('')
    setFilterPosition('')
    setFiltering(false)
    setFilterWhenCanStart('')
    employeeList.current = []
    setFilterEducation('')
    setFilterSpeciality('')
    setFilterLanguage('')
    setfilterProfileStatus('active')
    setFilterStatus('')

    // Get new eployees list
    getAllEmployees()
  }

  // Filter and show result
  const filterResult = async (type) => {
    setLoading(true)

    let query = db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('employees')

    if (type === 'name') {
      if (filterFirstName !== '' && filterLastName === '') {
        query = query
          .where('personalData.firstName', '>=', filterFirstName)
          .where('personalData.firstName', '<=', filterFirstName + '~')
      } else if (filterFirstName !== '' && filterLastName !== '') {
        query = query
          .where('personalData.firstName', '==', filterFirstName)
          .where('personalData.lastName', '==', filterLastName)
      } else if (filterFirstName === '' && filterLastName !== '') {
        query = query
          .where('personalData.lastName', '>=', filterLastName)
          .where('personalData.lastName', '<=', filterLastName + '~')
      }
    } else if (type === 'position') {
      if (filterProfileStatus !== '') {
        query = query.where('profileStatus', '==', filterProfileStatus)
      }
      if (filterStatus !== '') {
        query = query.where('rotationLogStatus', '==', filterStatus)
      }
      if (filterPosition !== '') {
        query = query.where('personalData.mainPosition', '==', filterPosition)
      }
      if (filterWhenCanStart !== '') {
        query = query.where('remarks.whenCanStart', '==', filterWhenCanStart)
      }
      if (filterEducation !== '') {
        query = query.where(
          'workDisciplineOtherData.additionalRequirements.education',
          '==',
          filterEducation
        )
      }
      if (filterSpeciality !== '') {
        query = query.where(
          'workDisciplineOtherData.additionalRequirements.speciality',
          '==',
          filterSpeciality
        )
      }
      //   if (filterCountry !== '') {
      //     query = query
      //       .where('workDisciplineOtherData.additionalRequirements.speciality', '==', filterCountry)
      //   }
      if (filterLanguage !== '') {
        query = query.where(
          'personalSkills.filterLanguages',
          'array-contains',
          filterLanguage
        )
      }
    } else if (type === 'country') {
      if (filterCountry !== '') {
        query = query.where(
          'searchTags.countries',
          'array-contains',
          filterCountry
        )
      }
    }
    const res = await query.get()
    const arr = []
    res.forEach((el) => {
      let data = JSON.stringify(el.data())
      data = JSON.parse(data)
      arr.push(data)
    })

    employeeList.current = arr
    setFiltering(true)
    setLoading(false)
    getProfilePhotoUrl()
  }

  /**
   * Load more employees
   */
  const loadMore = useCallback(
    async (firstLoad) => {
      setLoading(true)
      if (lastItem.current !== undefined) {
        const query = await db
          .collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .orderBy('timeStamp', 'desc')
        let adaptedQuery
        if (firstLoad === true) {
          adaptedQuery = query.limit(resultLimit).get()
        } else {
          adaptedQuery = query
            .startAfter(lastItem.current)
            .limit(resultLimit)
            .get()
        }
        const getEmployeesList = () => {
          return new Promise((resolve) => {
            adaptedQuery.then((resp) => {
              const arr = []
              resp.forEach((el) => {
                let data = JSON.stringify(el.data())
                data = JSON.parse(data)
                if (data.profileStatus === 'active') {
                  if (
                    employeeList.current.findIndex(
                      (el) => el.docId === data.docId
                    ) === -1
                  ) {
                    arr.push(data)
                  }
                }
              })

              resolve([arr, resp])
            })
          })
        }

        getEmployeesList().then((res) => {
          employeeList.current = employeeList.current.concat(res[0])
          setLastItemOnList(res[1].docs[res[1].docs.length - 1])
          lastItem.current = res[1].docs[res[1].docs.length - 1]
          getProfilePhotoUrl()
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    },
    [agencyData, getProfilePhotoUrl]
  )

  // Get first employees list
  useEffect(() => {
    let shouldUpdate = true
    if (shouldUpdate) {
      setLoading(true)
    }

    if (window.localStorage.getItem('pictureList') === 'true') {
      if (shouldUpdate) {
        setListViewSelected(false)
        setPictureListViewSelected(true)
      }
    }

    let defaultPositions = []
    let agencyPositions = []

    const getDefaultPositionsList = new Promise((resolve) => {
      db.collection('lists')
        .doc('key_positions')
        .get()
        .then((positions) => {
          let data = JSON.stringify(positions.data())
          data = JSON.parse(data)

          if (shouldUpdate) {
            defaultPositions = data.list
          }
          resolve()
        })
        .catch((error) => {
          console.log(error)
          resolve()
        })
    })

    const getAgencyPositionsList = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('lists')
        .doc('positions')
        .get()
        .then((positions) => {
          if (positions.exists) {
            let data = JSON.stringify(positions.data())
            data = JSON.parse(data)

            if (shouldUpdate) {
              agencyPositions = data.list
            }
          }
          resolve()
        })
        .catch((error) => {
          console.log(error)
          resolve()
        })
    })

    Promise.all([getDefaultPositionsList, getAgencyPositionsList]).then(() => {
      if (shouldUpdate) {
        setMergedPositions([...defaultPositions, ...agencyPositions])
        setLoading(false)
      }
    })

    loadMore(true)

    return () => {
      shouldUpdate = false
    }
  }, [loadMore, agencyData])

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0]
        if (first.isIntersecting) {
          loadMore()
        }
      },
      { threshold: 0.1 }
    )
  )

  useEffect(() => {
    if (!filtering) {
      const currentElement = element
      const currentObserver = observer.current

      if (currentElement) {
        currentObserver.observe(currentElement)
      }

      return () => {
        if (currentElement) {
          currentObserver.unobserve(currentElement)
        }
      }
    }
  }, [element, filtering])

  const changeListView = (type) => {
    if (type === 'list') {
      setListViewSelected(true)
      setPictureListViewSelected(false)
      window.localStorage.setItem('pictureList', 'false')
    } else if (type === 'pictureList') {
      setListViewSelected(false)
      setPictureListViewSelected(true)
      window.localStorage.setItem('pictureList', 'true')
    }
  }

  const getprofilePictureUrl = (id) => {
    let link = ''
    profilePhotoUrlList.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  return (
    <div>
      {loading && <Loader />}

      <Headline text={t('keyLandWorkersDatabase')} />

      {/* Filtering */}
      <Accordion classes={{ root: classes.accordionRoot }}>
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
          classes={{ expandIcon: classes.expandIcon }}
        >
          {t('filter')}
        </AccordionSummary>

        <AccordionDetails>
          <div>
            <div className='crewEmployeesList__filter'>
              <div className='crewEmployeesList__filter-el'>
                <div className='crewEmployeesList__filter-inputBox'>
                  <TextField
                    size='small'
                    fullWidth
                    variant='outlined'
                    type='text'
                    label={t('firstName')}
                    value={filterFirstName}
                    onChange={(e) =>
                      setFilterFirstName(e.target.value.toLowerCase())
                    }
                  />
                </div>

                <div className='crewEmployeesList__filter-inputBox'>
                  <TextField
                    size='small'
                    fullWidth
                    variant='outlined'
                    type='text'
                    label={t('lastName')}
                    value={filterLastName}
                    onChange={(e) =>
                      setFilterLastName(e.target.value.toLowerCase())
                    }
                  />
                </div>

                <div className='crewEmployeesList__filter-inputBox'>
                  <Button
                    text={t('filter')}
                    handleClick={() => filterResult('name')}
                  />
                </div>

                <div className='crewEmployeesList__filter-inputBox'>
                  <span
                    className='crewEmployeesList__filter-reset'
                    onClick={() => resetFilter()}
                  >
                    {t('reset')}
                  </span>
                </div>
              </div>

              <div className='crewEmployeesList__filter-el'>
                <div className='crewEmployeesList__filter-inputBox'>
                  <TextField
                    select
                    style={{ width: 150 }}
                    className={classes.selectStyle}
                    classes={{ root: classes.labelRoot }}
                    variant='outlined'
                    fullWidth
                    label={t('profileStatus')}
                    size='small'
                    value={filterProfileStatus}
                    onChange={(e) => {
                      setfilterProfileStatus(e.target.value)
                    }}
                  >
                    {profileStatus.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className='crewEmployeesList__filter-inputBox'>
                  <TextField
                    select
                    style={{ width: 120 }}
                    className={classes.selectStyle}
                    classes={{ root: classes.labelRoot }}
                    variant='outlined'
                    fullWidth
                    label={t('status')}
                    size='small'
                    value={filterStatus}
                    onChange={(e) => {
                      setFilterStatus(e.target.value)
                    }}
                  >
                    {rotationLogStatus.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {/* Position */}
                <div className='crewEmployeesList__filter-inputBox'>
                  <Autocomplete
                    options={mergedPositions}
                    sx={{ width: 300 }}
                    getOptionLabel={(option) => t(option)}
                    value={filterPosition}
                    onChange={(event, newValue) => {
                      setFilterPosition(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        fullWidth
                        label={t('position')}
                        size='small'
                      />
                    )}
                  />
                </div>

                <div className='crewEmployeesList__filter-inputBox'>
                  <TextField
                    select
                    style={{ width: 120 }}
                    className={classes.selectStyle}
                    classes={{ root: classes.labelRoot }}
                    variant='outlined'
                    fullWidth
                    label={t('readiness')}
                    size='small'
                    value={filterWhenCanStart}
                    onChange={(e) => {
                      setFilterWhenCanStart(e.target.value)
                    }}
                  >
                    {whenCanStartArr.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className='crewEmployeesList__filter-inputBox'>
                  <TextField
                    select
                    style={{ width: 120 }}
                    className={classes.selectStyle}
                    classes={{ root: classes.labelRoot }}
                    variant='outlined'
                    fullWidth
                    label={t('education')}
                    size='small'
                    value={filterEducation}
                    onChange={(e) => {
                      setFilterEducation(e.target.value)
                    }}
                  >
                    {educationVariants.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className='crewEmployeesList__filter-inputBox'>
                  <TextField
                    select
                    style={{ width: 120 }}
                    className={classes.selectStyle}
                    classes={{ root: classes.labelRoot }}
                    variant='outlined'
                    fullWidth
                    label={t('speciality')}
                    size='small'
                    value={filterSpeciality}
                    onChange={(e) => {
                      setFilterSpeciality(e.target.value)
                    }}
                  >
                    {specialityOptions.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className='crewEmployeesList__filter-inputBox'>
                  <TextField
                    select
                    style={{ width: 120 }}
                    className={classes.selectStyle}
                    classes={{ root: classes.labelRoot }}
                    variant='outlined'
                    fullWidth
                    label={t('language')}
                    size='small'
                    value={filterLanguage}
                    onChange={(e) => {
                      setFilterLanguage(e.target.value)
                    }}
                  >
                    {languagesOptions.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className='crewEmployeesList__filter-inputBox'>
                  <Button
                    text={t('filter')}
                    handleClick={() => filterResult('position')}
                  />
                </div>
                <div className='crewEmployeesList__filter-inputBox'>
                  <span
                    className='crewEmployeesList__filter-reset'
                    onClick={() => resetFilter()}
                  >
                    {t('reset')}
                  </span>
                </div>
              </div>
            </div>

            <div className='crewEmployeesList__filter'>
              <div className='crewEmployeesList__filter-el'>
                <div className='crewEmployeesList__filter-inputBox'>
                  <TextField
                    select
                    style={{ width: 300 }}
                    className={classes.selectStyle}
                    classes={{ root: classes.labelRoot }}
                    variant='outlined'
                    fullWidth
                    label={t('haveExperienceinCountry')}
                    size='small'
                    value={filterCountry}
                    onChange={(e) => {
                      setFilterCountry(e.target.value)
                    }}
                  >
                    {countries.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {t(el)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className='crewEmployeesList__filter-inputBox'>
                  <Button
                    text={t('filter')}
                    handleClick={() => filterResult('country')}
                  />
                </div>
                <div className='crewEmployeesList__filter-inputBox'>
                  <span
                    className='crewEmployeesList__filter-reset'
                    onClick={() => resetFilter()}
                  >
                    {t('reset')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <div className='crewEmployeesList__filter-el'>
        <div
          className='crewEmployeesList__addNew'
          onClick={() =>
            history.push(`/${agencyData.id}/key-dashboard/add-new-employee`)
          }
        >
          <FontAwesomeIcon
            className='crewEmployeesList__addNew-icon'
            icon={faPlus}
          />{' '}
          <span>{t('addNewEmployee')}</span>
        </div>
      </div>

      {/* Change view icons */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ display: 'flex' }}>
          <div
            style={{ margin: 5, cursor: 'pointer' }}
            className={listViewSelected ? classes.listIconSelected : null}
            onClick={() => changeListView('list')}
          >
            <FontAwesomeIcon icon={faBars} />
          </div>
          <div
            style={{ margin: 5, cursor: 'pointer' }}
            className={
              pictureListViewSelected ? classes.listIconSelected : null
            }
            onClick={() => changeListView('pictureList')}
          >
            <FontAwesomeIcon icon={faList} />
          </div>
        </div>
      </div>

      {/* Display data table */}
      <div>
        {employeeList.current.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} />
                {pictureListViewSelected && (
                  <TableCell className={classes.tableHeader}>
                    {t('profilePhoto')}
                  </TableCell>
                )}
                <TableCell className={classes.tableHeader}>
                  {t('status')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('firstName')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('lastName')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('position')}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  {t('readinessToWork')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeList.current.map((el, i) => {
                let verify = false
                if (el.addType !== 'agency') {
                  if (el.verified !== undefined) {
                    verify = el.verified.status
                  } else {
                    verify = false
                  }
                } else {
                  verify = true
                }

                return (
                  <TableRow
                    ref={
                      employeeList.current.length === i + 1 ? setElement : null
                    }
                    hover
                    key={el.docId}
                    classes={{ hover: classes.tableRow }}
                    onClick={() =>
                      window.open(
                        `/${agencyData.id}/key-dashboard/employee-view/${el.docId}`
                      )
                    }
                  >
                    <TableCell>{i + 1}</TableCell>

                    {/* Profile photo */}
                    {pictureListViewSelected && (
                      <TableCell>
                        {getprofilePictureUrl(el.docId) !== '' ? (
                          <img
                            src={getprofilePictureUrl(el.docId)}
                            alt={t('profilePhoto')}
                            style={{
                              maxWidth: 50
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: systemColors.lightElectric,
                              width: 50,
                              height: 50,
                              borderRadius: systemStylingSettings.borderRadius
                            }}
                          >
                            <FontAwesomeIcon
                              style={{
                                fontSize: 30,
                                color: systemColors.background
                              }}
                              icon={faUser}
                            />
                          </div>
                        )}
                      </TableCell>
                    )}

                    {/* Status */}
                    <TableCell>
                      <StatusChip status={el.rotationLogStatus} />
                    </TableCell>

                    {/* First name */}
                    <TableCell className='global__capitalizeFirstLetter'>
                      <div style={{ display: 'flex' }}>
                        <Tooltip
                          title={
                            verify
                              ? t('profileIsVerified')
                              : t('profileIsNotVerified')
                          }
                          placement='top'
                        >
                          <div
                            style={{ display: 'inline-block', marginRight: 20 }}
                          >
                            <FontAwesomeIcon
                              icon={faCheckDouble}
                              style={{
                                color: verify
                                  ? systemColors.green
                                  : systemColors.grey
                              }}
                            />
                          </div>
                        </Tooltip>
                        {el.personalData.firstName}
                      </div>
                    </TableCell>

                    {/* Last name */}
                    <TableCell className='global__capitalizeFirstLetter'>
                      {el.personalData.lastName}
                    </TableCell>

                    {/* Position */}
                    <TableCell className='global__capitalizeFirstLetter'>
                      {el.personalData.mainPosition !== undefined && (
                        <div>
                          <div>{t(el.personalData.mainPosition)}</div>
                          <div>{t(el.personalData.secondPosition)}</div>
                        </div>
                      )}
                    </TableCell>

                    {/* Readiness to work */}
                    <TableCell className='global__capitalizeFirstLetter'>
                      {t(el.remarks.whenCanStart)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        ) : (
          <div className='crewEmployeesList__noResult'>
            {t('emptyEmployeeList')}
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj
  }
}

export default connect(mapStateToProps)(KeyEmployessList)
