import React from 'react' 
import CrewEmployessList from './crewEmployeeList'
import AgencyMenu from '../../Components/AgencyMenu'

const CrewAgencyPage = () => {

    return (
        <div>
            <AgencyMenu type='crew' />
            <CrewEmployessList />
        </div>
    )
}
 
export default CrewAgencyPage