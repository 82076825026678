import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Tooltip
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AgencyMenu from '../../../Components/AgencyMenu'
import Headline from '../../../Components/Headline'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import './notifications.scss'
import ComponentLoader from '../../../Components/ComponentLoader'

const db = firebase.firestore()

interface Props {
  agencyData: AgencyData
  agencyUserData: AgencyUserData
}

const Notifications = ({ agencyData, agencyUserData }: Props) => {
  const { t } = useTranslation()

  /**
   * Change document title
   */
  document.title = `${t('notifications')} - Seanor`

  const [loading, setLoading] = useState(false)

  const [notificationsList, setNotificationsList] = useState<
    SystemNotification[]
  >([])
  const [refreshData, setRefreshData] = useState<boolean>(false)
  const [totalNotifications, setTotalNotifications] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(20)
  const [page, setPage] = useState<number>(0)
  const [rawDbList, setRawDbList] = useState<Array<any>>([])

  useEffect(() => {
    let shouldUpdate: boolean = true

    /**
     * Fetch notifications
     */
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('notifications')
      .where('data.userUid', '==', agencyUserData.uid)
      .orderBy('data.timestamp', 'desc')
      .limit(20)
      .get()
      .then((snap) => {
        let notificationsArray: SystemNotification[] = []

        snap.forEach((doc) => {
          const data: any = JSON.parse(JSON.stringify(doc.data()))

          notificationsArray = [...notificationsArray, data.data]
        })

        if (shouldUpdate) {
          setNotificationsList(notificationsArray)
          setRawDbList(snap.docs)
        }
      })
      .catch((error) => console.error(error))

    /**
     * Fetch total notifications number
     */
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('notifications')
      .where('data.userUid', '==', agencyUserData.uid)
      .orderBy('data.timestamp', 'desc')
      .get()
      .then((snap) => {
        if (shouldUpdate) {
          setTotalNotifications(snap.size)
        }
      })
      .catch((error) => console.error(error))

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, agencyUserData, refreshData])

  const handleChangePage = async (event: any, newPage: number) => {
    setLoading(true)
    let queryData = db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('notifications')
      .where('data.userUid', '==', agencyUserData.uid)
      .orderBy('data.timestamp', 'desc')

    if (newPage > page) {
      queryData = queryData
        .startAfter(rawDbList[rawDbList.length - 1])
        .limit(rowsPerPage)
    } else if (newPage < page) {
      queryData = queryData.endBefore(rawDbList[0]).limitToLast(rowsPerPage)
    }

    await queryData
      .get()
      .then((snap) => {
        let array: SystemNotification[] = []

        snap.forEach((doc) => {
          const data: any = JSON.parse(JSON.stringify(doc.data()))
          array = [...array, data.data]
        })

        setRawDbList(snap.docs)
        setNotificationsList(array)
        setPage(newPage)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChangeRowsPerPage = async (event: any) => {
    setLoading(true)

    db.collection('agencies')
      .doc(agencyData.id)
      .collection('notifications')
      .where('data.userUid', '==', agencyUserData.uid)
      .orderBy('data.timestamp', 'desc')
      .limit(event.target.value)
      .get()
      .then((snap) => {
        let array: SystemNotification[] = []

        snap.forEach((doc) => {
          const data: any = JSON.parse(JSON.stringify(doc.data()))

          array = [...array, data.data]
        })

        setRawDbList(snap.docs)
        setNotificationsList(array)
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const markAsReadedNotification = (id: string, value: boolean) => {
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('notifications')
      .doc(id)
      .update({ 'data.readed': value })
      .then(() => setRefreshData(!refreshData))
      .catch((error) => console.error(error))
  }

  return (
    <>
      <Headline text={t('notifications')} />

      <AgencyMenu type='key' />

      <main className='notifications_page'>
        <Paper
          style={{
            padding: 20,
            margin: '50px auto',
            width: 'fit-content',
            position: 'relative'
          }}
        >
          {loading && <ComponentLoader />}

          {/**
           * Messege if no notifications
           */}
          {notificationsList.length === 0 && (
            <div style={{ textAlign: 'center' }}>
              {t('there_are_no_notifications')}
            </div>
          )}

          {/**
           * Notifications list
           */}
          {notificationsList.length > 0 && (
            <Table>
              <TableBody>
                {notificationsList.map((notification) => (
                  <TableRow key={notification._id}>
                    {/*
                     * Content
                     */}
                    <TableCell style={{ minWidth: 200, maxWidth: 500 }}>
                      <div
                        style={{ fontWeight: notification.readed ? 400 : 700 }}
                      >
                        {notification.title}
                      </div>
                      <span>{t(notification.source)}</span>
                    </TableCell>

                    {/*
                     * Mark as readed or not readed
                     */}
                    <TableCell>
                      {/*
                       * Mark as readed
                       */}
                      {!notification.readed && (
                        <div>
                          <Tooltip title={t('mark_as_readed')}>
                            <div
                              className='notification_readed'
                              onClick={() =>
                                markAsReadedNotification(notification._id, true)
                              }
                            />
                          </Tooltip>
                        </div>
                      )}

                      {/*
                       * Mark as not readed
                       */}
                      {notification.readed && (
                        <div>
                          <Tooltip title={t('mark_as_not_readed')}>
                            <div
                              className='notification_readed__not'
                              onClick={() =>
                                markAsReadedNotification(
                                  notification._id,
                                  false
                                )
                              }
                            />
                          </Tooltip>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          {/*
           * Pagination
           */}
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component='div'
            count={totalNotifications}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ marginBottom: 50 }}
            labelRowsPerPage={t('results_on_page')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${t('from')} ${
                count !== -1 ? count : t('more_than') + to
              }`
            }
            nextIconButtonProps={{
              'aria-label': t('next'),
              title: t('next')
            }}
            backIconButtonProps={{
              'aria-label': t('back'),
              title: t('back')
            }}
          />
        </Paper>
      </main>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  agencyData: state.agencyDataRed.obj,
  agencyUserData: state.agencyUserDataRed.obj
})

export default connect(mapStateToProps)(Notifications)
