import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'norspeda.firebaseapp.com',
  databaseURL: 'https://norspeda.firebaseio.com',
  projectId: 'norspeda',
  storageBucket: 'norspeda.appspot.com',
  messagingSenderId: '1073190589372',
  appId: '1:1073190589372:web:5302f0b5cf1ad1cd3551b9',
  measurementId: 'G-F7N1T1HPX8'
}

firebase.initializeApp(firebaseConfig)
