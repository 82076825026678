import { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { systemColors } from '../../../../globalVariables'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const db = firebase.firestore()

const useStyles = makeStyles({
  card: {
    width: 'fit-content',
    height: '100%'
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: systemColors.darkBlue
  },
  cardHeader: {
    backgroundColor: systemColors.background,
    border: '1px solid #d6d6d6',
    borderBottom: `2px solid ${systemColors.orange}`
  },
  removeCardIcon: {
    padding: '10px 6px 0 50px',
    cursor: 'pointer',
    color: systemColors.grey,
    '&:hover': {
      color: systemColors.darkGrey
    }
  },
  listPrimaryText: {
    fontSize: 14
  },
  listSecondaryText: {
    fontSize: 13
  }
})

interface Props {
  agencyData: AgencyData
  agencyUserData: AgencyUserData
}

const LastNotifications = ({ agencyData, agencyUserData }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const [notificationsList, setNotificationsList] = useState<
    SystemNotification[]
  >([])

  useEffect(() => {
    let shouldUpdate: boolean = true

    /**
     * Fetch notifications
     */
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('notifications')
      .where('data.userUid', '==', agencyUserData.uid)
      .orderBy('data.timestamp', 'desc')
      .limit(10)
      .get()
      .then((snap) => {
        let notificationsArray: SystemNotification[] = []

        snap.forEach((doc) => {
          const data: any = JSON.parse(JSON.stringify(doc.data()))

          notificationsArray = [...notificationsArray, data.data]
        })

        if (shouldUpdate) {
          setNotificationsList(notificationsArray)
        }
      })
      .catch((error) => console.error(error))

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, agencyUserData])

  return (
    <div style={{ height: '100%' }}>
      <Card classes={{ root: classes.card }}>
        <CardHeader
          classes={{ root: classes.cardHeader, title: classes.title }}
          title={t('last_notifications')}
        />
        <CardContent>
          {/**
           * Messege if no notifications
           */}
          {notificationsList.length === 0 && (
            <div style={{ textAlign: 'center' }}>
              {t('there_are_no_notifications')}
            </div>
          )}

          {/**
           * Notifications list
           */}
          {notificationsList.length > 0 && (
            <Table>
              <TableBody>
                {notificationsList.map((notification) => (
                  <TableRow key={notification._id}>
                    {/*
                     * Content
                     */}
                    <TableCell style={{ minWidth: 300, maxWidth: 500 }}>
                      <div
                        style={{ fontWeight: notification.readed ? 400 : 700 }}
                      >
                        {notification.title}
                      </div>
                      <span>{t(notification.source)}</span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          {/*
           * Link to all notifications
           */}
          <div
            style={{ marginTop: 20 }}
            className='all_notifications'
            onClick={() => history.push(`/${agencyData.id}/key/notifications`)}
          >
            {t('all_notifications')}
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  agencyData: state.agencyDataRed.obj,
  agencyUserData: state.agencyUserDataRed.obj
})

export default connect(mapStateToProps)(LastNotifications)
