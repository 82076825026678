import React, { forwardRef } from 'react';
import './input.scss'

const Input = forwardRef((props, ref) => {
    const inputHandler = e => {
        if(props.type === 'file'){
            props.handleInput(e.target.files)
        }else{
            props.handleInput(e.target.value)
        }
    }

    return ( 
        <input 
            id={props.id}
            className='input'
            type={props.type}
            value={props.value}
            placeholder={props.placeholder}
            onChange={(input) => inputHandler(input)}
            accept={props.accept}
            disabled={props.disabled !== null ? props.disabled : false}
            ref={ref}
        />
    );
})
 
export default Input;