import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { useTranslation } from 'react-i18next'
import Loader from '../../../Components/Loader'
import { v4 as uuid } from 'uuid'
import moment from 'moment'

const db = firebase.firestore()

interface Props {
  /** Profile document ID */
  documentId: string
  /** Agency data */
  agencyData: AgencyData
  /** Agent data */
  agentUserData: AgencyUserData
}

const PreEvaluation = ({ documentId, agencyData, agentUserData }: Props) => {
  const { t } = useTranslation()

  const [preEvaluationsList, setPreEvaluationsList] = useState<
    KeyEmployeePreEvaluation[]
  >([])

  const [refreshData, setRefreshData] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)

  const [showNewEditEvaluationDialog, setShowNewEditEvaluationDialog] =
    useState<boolean>(false)

  const [adminEvaluationCriteria, setAdminEvaluationCriteria] = useState<
    RecruitmentEvaluationCriteria[]
  >([])

  const [selectedEvaluationCriteria, setSelectedEvaluationCriteria] = useState<
    RecruitmentEvaluationCriteria[]
  >([])
  const [selectedEvaluationId, setSelectedEvaluationId] = useState<string>('')
  const [selectedAgentUid, setSelectedAgentUid] = useState<string>('')

  const [
    showDeleteEvaluationWarningDialog,
    setShowDeleteEvaluationWarningDialog
  ] = useState<boolean>(false)

  useEffect(() => {
    let shouldUpdate = true

    /**
     * Set evaluation criterias from agency settings
     */

    if (
      agencyData.settings.companySettings !== undefined &&
      agencyData.settings.companySettings.candidateCriteria !== undefined &&
      shouldUpdate
    ) {
      setAdminEvaluationCriteria(
        agencyData.settings.companySettings.candidateCriteria
      )
    }

    /**
     * Get pre evaluations list
     */
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .doc(documentId)
      .get()
      .then((snap) => {
        let data: any = JSON.stringify(snap.data())
        data = JSON.parse(data)

        if (data.preEvaluations !== undefined && shouldUpdate) {
          setPreEvaluationsList(data.preEvaluations)
        }
      })
      .catch((error) => console.error(error))

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, refreshData, documentId])

  const cloeseNewEditEvaluationDialog = () => {
    setShowNewEditEvaluationDialog(false)
    setEditMode(false)
    setSelectedEvaluationCriteria([])
    setSelectedEvaluationId('')
    setSelectedAgentUid('')
  }

  const openNewEditEvaluationDialog = (
    evaluation: KeyEmployeePreEvaluation | null
  ) => {
    /**
     * When creating new pre evaluation
     */
    if (evaluation === null) {
      const newArray: RecruitmentEvaluationCriteria[] = JSON.parse(
        JSON.stringify(adminEvaluationCriteria)
      )

      setSelectedEvaluationCriteria(newArray)

      setShowNewEditEvaluationDialog(true)
    }

    /**
     * When open edit evaluation
     */
    if (evaluation !== null) {
      setEditMode(true)

      const criteriaCopy: RecruitmentEvaluationCriteria[] = JSON.parse(
        JSON.stringify(evaluation.evaluation)
      )
      setSelectedEvaluationCriteria(criteriaCopy)
      setSelectedEvaluationId(evaluation._id)
      setSelectedAgentUid(evaluation.agentUid)

      setShowNewEditEvaluationDialog(true)
    }
  }

  /**
   * Used for evaluation input change
   * */
  const handleEvaluationChange = (
    evaluationId: string,
    name: string,
    val: string | number | null
  ) => {
    const newInput: RecruitmentEvaluationCriteria[] =
      selectedEvaluationCriteria.map((evaluation) => {
        if (evaluationId === evaluation._id) {
          if (name === 'evaluation') {
            evaluation.evaluation = val as string
          }
          if (name === 'rating' && val !== null) {
            evaluation.rating = val as number
          }
        }
        return evaluation
      })

    setSelectedEvaluationCriteria(newInput)
  }

  const saveNewPreEvaluation = () => {
    setLoading(true)
    /**
     * Average rating number
     */
    let totalRating: number = 0

    let ratingsSum: number = 0
    let ratingsCount: number = 0

    selectedEvaluationCriteria.forEach((evaluation) => {
      if (evaluation.rating !== 0) {
        ratingsSum = ratingsSum + evaluation.rating
        ratingsCount = ratingsCount + 1
      }
    })

    totalRating = Math.round((ratingsSum / ratingsCount) * 100) / 100

    if (isNaN(totalRating)) {
      totalRating = 0
    }

    /**
     * Agent's evaluation data
     */
    const preEvaluation: KeyEmployeePreEvaluation = {
      _id: uuid(),
      agentName: `${agentUserData.name} ${agentUserData.lastName}`,
      agentUid: agentUserData.uid,
      evaluationDate: moment().format('YYYY-MM-DD'),
      totalRating,
      evaluation: selectedEvaluationCriteria,
      timestamp: new Date()
    }

    /**
     * New pre-evaluations array
     */

    const newPreEvaluations: KeyEmployeePreEvaluation[] = [
      preEvaluation,
      ...preEvaluationsList
    ]

    db.collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .doc(documentId)
      .update({
        preEvaluations: newPreEvaluations
      })
      .then(() => {
        setRefreshData(!refreshData)
        cloeseNewEditEvaluationDialog()
        setLoading(false)
      })
      .catch((error) => console.error(error))
  }

  const deletePreEvaluation = () => {
    setLoading(true)
    /**
     * Remove evaluation from list
     */
    const newPreEvaluationArray: KeyEmployeePreEvaluation[] =
      preEvaluationsList.filter((el) => el._id !== selectedEvaluationId)

    /**
     * Update profile with new pre-evaluation list
     */
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .doc(documentId)
      .update({
        preEvaluations: newPreEvaluationArray
      })
      .then(() => {
        setRefreshData(!refreshData)
        setShowDeleteEvaluationWarningDialog(false)
        cloeseNewEditEvaluationDialog()
        setLoading(false)
      })
      .catch((error) => console.error(error))
  }

  /**
   * Check is evaluation belongs to agent and evaluation is in edit mode
   */
  const disableUpdateInputs = () => {
    if (editMode && agentUserData.uid !== selectedAgentUid) {
      return true
    }
    return false
  }

  const updatePreEvaluation = () => {
    setLoading(true)
    /**
     * Average rating number
     */
    let totalRating: number = 0

    let ratingsSum: number = 0
    let ratingsCount: number = 0

    selectedEvaluationCriteria.forEach((evaluation) => {
      if (evaluation.rating !== 0) {
        ratingsSum = ratingsSum + evaluation.rating
        ratingsCount = ratingsCount + 1
      }
    })

    totalRating = Math.round((ratingsSum / ratingsCount) * 100) / 100

    if (isNaN(totalRating)) {
      totalRating = 0
    }

    /**
     * Update pre-evaluation list with new data
     */
    const newList: KeyEmployeePreEvaluation[] = JSON.parse(
      JSON.stringify(preEvaluationsList)
    )

    const index = newList.findIndex((el) => el._id === selectedEvaluationId)
    if (index > -1) {
      newList[index].evaluation = selectedEvaluationCriteria
      newList[index].totalRating = totalRating
    }

    /**
     * Update profile with new pre-evaluation list
     */
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .doc(documentId)
      .update({
        preEvaluations: newList
      })
      .then(() => {
        setRefreshData(!refreshData)
        cloeseNewEditEvaluationDialog()
        setLoading(false)
      })
      .catch((error) => console.error(error))
  }

  return (
    <>
      {loading && <Loader />}

      {/*
       * Evaluation dialog
       */}
      <Dialog fullWidth maxWidth='sm' open={showNewEditEvaluationDialog}>
        <DialogTitle>
          {editMode
            ? t('preview_edit_pre_evaluation')
            : t('create_pre_evaluation')}
        </DialogTitle>

        <DialogContent>
          <div>
            {/* Criteria */}
            {selectedEvaluationCriteria.map((evaluation) => (
              <div key={evaluation._id}>
                {/* Title and rating */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 20,
                    marginBottom: 5
                  }}
                >
                  {/* Title */}
                  <div>{evaluation.title}</div>

                  {/* Rating */}
                  <Rating
                    size='small'
                    disabled={disableUpdateInputs()}
                    value={evaluation.rating}
                    onChange={(event, value) =>
                      handleEvaluationChange(evaluation._id, 'rating', value)
                    }
                  />
                </div>

                {/* Evaluation */}
                <div>
                  <TextField
                    disabled={disableUpdateInputs()}
                    fullWidth
                    multiline
                    rows={4}
                    size='small'
                    value={evaluation.evaluation}
                    onChange={(event) =>
                      handleEvaluationChange(
                        evaluation._id,
                        'evaluation',
                        event.target.value
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </DialogContent>

        <DialogActions>
          {/*
           * Cancel button
           */}
          <Button size='small' onClick={() => cloeseNewEditEvaluationDialog()}>
            {t('cancel')}
          </Button>

          {/*
           * Delete evaluation
           */}
          {editMode &&
            (agentUserData.userRole === 'agency_admin' ||
              agentUserData.userRole === 'super_admin') && (
              <Button
                size='small'
                variant='contained'
                color='error'
                onClick={() => setShowDeleteEvaluationWarningDialog(true)}
              >
                {t('delete')}
              </Button>
            )}

          {/*
           * Update button
           */}
          {editMode && agentUserData.uid === selectedAgentUid && (
            <Button
              size='small'
              variant='contained'
              color='orange'
              onClick={() => updatePreEvaluation()}
            >
              {t('update')}
            </Button>
          )}

          {/*
           * Save button
           */}
          {!editMode && (
            <Button
              size='small'
              variant='contained'
              color='orange'
              onClick={() => saveNewPreEvaluation()}
            >
              {t('save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/*
       * Delete evaluation warning
       */}
      <Dialog
        open={showDeleteEvaluationWarningDialog}
        onClose={() => setShowDeleteEvaluationWarningDialog(false)}
      >
        <DialogContent>{t('are_you_sure_jsyh4687sdf')}</DialogContent>

        <DialogActions>
          {/*
           * Cancel button
           */}
          <Button
            size='small'
            onClick={() => setShowDeleteEvaluationWarningDialog(false)}
          >
            {t('no')}
          </Button>

          {/*
           * Delete button
           */}
          <Button
            size='small'
            variant='contained'
            color='error'
            onClick={() => deletePreEvaluation()}
          >
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <main>
        {/*
         * Create evaluation button
         */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 50
          }}
        >
          <Button
            variant='contained'
            color='orange'
            size='small'
            onClick={() => openNewEditEvaluationDialog(null)}
          >
            {t('create_pre_evaluation')}
          </Button>
        </div>

        {/*
         * No evaluations
         */}
        {preEvaluationsList.length === 0 && (
          <div style={{ textAlign: 'center' }}>
            {t('there_is_no_any_pre_evaluations')}
          </div>
        )}

        {/*
         * Pre evaluations list
         */}
        <Table style={{ maxWidth: 500, margin: '0 auto 50px auto' }}>
          <TableBody>
            {preEvaluationsList.map((evaluation) => (
              <TableRow
                key={evaluation._id}
                hover
                style={{ cursor: 'pointer' }}
                onClick={() => openNewEditEvaluationDialog(evaluation)}
              >
                {/*
                 * Agent name
                 */}
                <TableCell>{evaluation.agentName}</TableCell>

                {/*
                 * Evaluation date
                 */}
                <TableCell>{evaluation.evaluationDate}</TableCell>

                {/*
                 * Rating
                 */}
                <TableCell>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title={evaluation.totalRating} placement='top'>
                      <div>
                        <Rating
                          size='small'
                          readOnly
                          value={evaluation.totalRating}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </main>
    </>
  )
}

export default PreEvaluation
