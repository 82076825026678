import React, { useCallback, useEffect, useState } from 'react'
import AgencyMenu from '../../Components/AgencyMenu'
import Loader from '../../Components/Loader'
import firebase from 'firebase/app'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Alert,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  FormControlLabel,
  Switch,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Snackbar,
  Grid
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { systemColors, systemStylingSettings } from '../../globalVariables'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faTrashAlt,
  faUserEdit,
  faEnvelope,
  faUserTimes,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { showGeneratedPdfBox } from '../../redux/actions/showGeneratedPdfBox'
import GeneratedPDFViewer from '../../Components/GeneratedPdfViewer'
import {
  secondsToDate,
  getDayAndHours,
  convertDate,
  addWeeksToDate
} from '../../globalFunctions'
import Headline from '../../Components/Headline'
import KeyEvaluation from './Modules/KeyEvaluation'
import { renderPdfModule } from '../../redux/actions/renderPdfModule'
import RotationLog from './Modules/keyRotationLog'
import axios from 'axios'
import { v4 as uuid } from 'uuid'
import PreEvaluation from './Modules/PreEvaluation'
import EvaluationHistory from './Modules/EvaluationHistory'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

const useStyles = makeStyles({
  tableHeader: {
    color: systemColors.darkBlue,
    fontSize: 16
  },
  tabRoot: {
    maxWidth: 150,
    padding: 0,
    color: systemColors.lightBlue,
    opacity: 1,
    borderTop: `1px solid ${systemColors.lighGrey}`,
    borderBottom: `1px solid ${systemColors.lighGrey}`
  },
  tabsIndicator: {
    backgroundColor: systemColors.orange
  },
  buttonEndIcon: {
    color: '#ffffff',
    '& > *:first-child': {
      fontSize: '1em'
    }
  },
  buttonRoot: {
    color: '#ffffff'
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: systemColors.orangeDark
    }
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  cardRoot: {
    marginBottom: 10
  },
  errorColor: {
    color: `${systemColors.errorColor} !important`
  },
  tabsRoot: {
    marginTop: 20,
    justifyContent: 'center'
  },
  tabsScroller: {
    flexGrow: '0'
  },
  section: {
    marginTop: 30
  },
  noteRoot: {
    backgroundColor: systemColors.inputColor
  },
  cardActionRoot: {
    float: 'right'
  },
  chipsDisplay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: 5
    }
  },
  switchBase: {
    '&.Mui-checked': {
      color: systemColors.orange
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.orange
    }
  },
  switchPrimary: {
    '&.Mui-checked': {
      color: systemColors.orange
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.orange
    }
  },
  labelSize: {
    fontSize: 14
  },
  dialogRoot: {
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background
  },
  dialogTitleRoot: {
    backgroundColor: systemColors.electric,
    color: '#ffffff'
  }
})

const KeyEmployeeView = ({
  agencyData,
  showGeneratedPdfBox,
  setShowGeneratedPdfBox,
  agencyUserData,
  renderPdfModule,
  setRenderPdfModule
}) => {
  const { docId } = useParams()
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  // Data from database
  const [userData, setUserData] = useState()

  const [selectedTab, setSelectedTab] = useState(0)
  const [profilePhotoUrl, setProfilePhotoUrl] = useState(null)
  const [uploadedCvUrl, setUploadedCvUrl] = useState(null)
  const [documentsLinks, setDocumentsLinks] = useState([])
  const [referenceLinks, setReferenceLinks] = useState([])
  const [notesArr, setNotesArr] = useState([])

  // Inputs states
  const [note, setNote] = useState('')
  const [allowEdit, setAllowEdit] = useState(false)

  const [deleteProfileWarningBox, setDeleteProfileWarningBox] = useState(false)

  const [companyLogoURL, setCompanyLogoURL] = useState('')

  const [tagged, setTagged] = useState(false)

  const [message, setMessage] = useState({
    status: false,
    duration: 1500,
    severity: 'success',
    message: ''
  })

  const [verifyAlert, setVerifyAlert] = useState(true)

  // Get profile picture
  const getProfilePhoto = useCallback(
    (photoName) => {
      setLoading(true)
      if (photoName !== null) {
        storageRef
          .child(
            `agencies/${agencyData.id}/users-forms-uploads/${docId}/${photoName}`
          )
          .getDownloadURL()
          .then((url) => {
            setProfilePhotoUrl(url)
            setLoading(false)
          })
          .catch((err) => {
            console.log('Profile photo error:', err)
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    },
    [agencyData, docId]
  )

  // Get uploaded CV url
  const getUploadedCvUrl = useCallback(
    (cvName) => {
      setLoading(true)
      if (cvName !== null) {
        storageRef
          .child(
            `agencies/${agencyData.id}/users-forms-uploads/${docId}/${cvName}`
          )
          .getDownloadURL()
          .then((url) => {
            setUploadedCvUrl(url)
            setLoading(false)
          })
          .catch((err) => {
            console.log('Uploaded CV error:', err)
            setUploadedCvUrl('')
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    },
    [agencyData, docId]
  )

  // Get cerificates and documents links
  const getDocumentsLinks = useCallback(
    async (arr) => {
      const getLinks = await arr.map((obj) => {
        if (obj.file !== null) {
          // eslint-disable-next-line
          return new Promise((res) => {
            storageRef
              .child(
                `agencies/${agencyData.id}/users-forms-uploads/${docId}/${obj.file}`
              )
              .getDownloadURL()
              .then((url) => {
                res({
                  id: obj.id,
                  url
                })
              })
              .catch(() => {
                res({
                  id: obj.id,
                  url: ''
                })
              })
          })
        } else {
          // eslint-disable-next-line
          return
        }
      })
      Promise.all(getLinks).then((res) => setDocumentsLinks(res))
    },
    [agencyData, docId]
  )

  // Get references links
  const getReferenceLinks = useCallback(
    async (arr) => {
      const getLinks = await arr.map((obj) => {
        if (obj.file !== null) {
          // eslint-disable-next-line
          return new Promise((res) => {
            storageRef
              .child(
                `agencies/${agencyData.id}/users-forms-uploads/${docId}/${obj.file}`
              )
              .getDownloadURL()
              .then((url) => {
                res({
                  id: obj.id,
                  url
                })
              })
              .catch(() => {
                res({
                  id: obj.id,
                  url: ''
                })
              })
          })
        } else {
          // eslint-disable-next-line
          return
        }
      })
      Promise.all(getLinks).then((res) => setReferenceLinks(res))
    },
    [agencyData, docId]
  )

  // Get user data from data base
  useEffect(() => {
    let shouldUpdate = true

    if (shouldUpdate) {
      setLoading(true)
    }
    ;(() => {
      const getProfileData = new Promise((resolve) => {
        db.collection('agencies')
          .doc(agencyData.id)
          .collection('employees')
          .doc(docId)
          .get()
          .then((res) => {
            let data = JSON.stringify(res.data())
            data = JSON.parse(data)

            if (shouldUpdate) {
              setUserData(data)
              getUploadedCvUrl(data.documents.cvFile)
              setNotesArr(data.notes)

              // Set files links
              getProfilePhoto(data.personalData.profilePhoto)
              getDocumentsLinks(data.documents.newCertificatesFilesArr)
              getReferenceLinks(data.documents.newReferenciesFilesArr)

              if (data.addType !== undefined) {
                if (data.addType === 'online') {
                  if (data.verified !== undefined) {
                    setVerifyAlert(!data.verified.status)
                  }
                } else if (data.addType === 'agency') {
                  setVerifyAlert(false)
                }
              }
            }

            // Change document title
            document.title = `${
              data.personalData.firstName.charAt(0).toUpperCase() +
              data.personalData.firstName.slice(1)
            } 
                    ${
                      data.personalData.lastName.charAt(0).toUpperCase() +
                      data.personalData.lastName.slice(1)
                    } - Seanor`

            // Get company logo URL
            const getCompanyLogo = new Promise((resolve) => {
              storage
                .ref()
                .child(
                  `/public/agencies/${agencyData.id}/logo/${agencyData.logoFileName}`
                )
                .getDownloadURL()
                .then((url) => {
                  if (shouldUpdate) {
                    setCompanyLogoURL(url)
                  }
                  resolve()
                })
                .catch((error) => {
                  console.log("Can't get logo url", error)
                  resolve()
                })
            })

            // Check if user have permission to edit his profile
            const checkPermission = new Promise((resolve) => {
              if (data.userId !== '') {
                db.collection('users')
                  .doc(data.userId)
                  .get()
                  .then((res) => {
                    let data = JSON.stringify(res.data())
                    data = JSON.parse(data)
                    if (shouldUpdate) {
                      setAllowEdit(data.data.canEditCV)
                    }
                    resolve()
                  })
                  .catch((error) => {
                    console.log("Can't get permission status", error)
                    resolve()
                  })
              } else {
                resolve()
              }
            })

            // Add profile to users last viewed profiles list
            const updateAgencyUserData = new Promise((resolve) => {
              let lastViewedProfilesNewArr = []

              if (agencyUserData.lastViewedProfiles !== undefined) {
                lastViewedProfilesNewArr = agencyUserData.lastViewedProfiles

                const visitedProfilesDataObject = {
                  docId,
                  firstName: data.personalData.firstName,
                  lastName: data.personalData.lastName,
                  mainPosition: data.personalData.mainPosition,
                  timestamp: new Date()
                }
                // Check is company alredy llisted in array
                const index = lastViewedProfilesNewArr.findIndex(
                  (el) => el.docId === docId
                )

                // Add company to list
                if (index < 0) {
                  lastViewedProfilesNewArr.unshift(visitedProfilesDataObject)
                } else if (index > 0) {
                  lastViewedProfilesNewArr.splice(index, 1)
                  lastViewedProfilesNewArr.unshift(visitedProfilesDataObject)
                }

                // Remove last list item if list is longer then 10
                if (lastViewedProfilesNewArr.length > 10) {
                  lastViewedProfilesNewArr.splice(10, 1)
                }
              }

              db.collection('users')
                .doc(agencyUserData.uid)
                .update({
                  'data.lastViewedProfiles': lastViewedProfilesNewArr
                })
                .then(() => resolve())
                .catch((error) => {
                  console.log(
                    "Can't add profil to 'Last viewed profile' list",
                    error
                  )
                  resolve()
                })
            })

            Promise.all([checkPermission, getCompanyLogo, updateAgencyUserData])
              .then(() => resolve())
              .catch((error) => {
                console.log(error)
                resolve()
              })
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })

      const checkIfProfileIsTagged = new Promise((resolve) => {
        if (agencyUserData.taggedProfiles !== undefined) {
          const index = agencyUserData.taggedProfiles.findIndex(
            (el) => el.docId === docId
          )

          if (index >= 0) {
            if (shouldUpdate) {
              setTagged(true)
            }
          }

          resolve()
        } else {
          resolve()
        }
      })

      Promise.all([getProfileData, checkIfProfileIsTagged])
        .then(() => {
          if (shouldUpdate) {
            setLoading(false)
          }
        })
        .catch((error) => {
          console.log(error)
          if (shouldUpdate) {
            setLoading(false)
          }
        })
    })()

    return () => {
      shouldUpdate = false
    }
  }, [
    docId,
    agencyData,
    getDocumentsLinks,
    getProfilePhoto,
    getReferenceLinks,
    getUploadedCvUrl,
    agencyUserData
  ])

  // Change selected tab value
  const handleTabsChange = (e, newValue) => {
    setSelectedTab(newValue)
  }

  // Find and add document link
  const addDocumentLink = (id) => {
    let link = ''
    documentsLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  // Find and add document link
  const addReferenceLink = (id) => {
    let link = ''
    referenceLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  // Get data from database
  const getNewData = () => {
    setLoading(true)

    db.collection('agencies')
      .doc(agencyData.id)
      .collection('employees')
      .doc(docId)
      .get()
      .then((res) => {
        let data = JSON.stringify(res.data())
        data = JSON.parse(data)

        setUserData(data)
        setNotesArr(data.notes)
        setLoading(false)

        // Set files links
        getProfilePhoto(data.profilePhoto)
        getUploadedCvUrl(data.documents.cvFile)
        getDocumentsLinks(data.documents.newCertificatesFilesArr)
        getReferenceLinks(data.documents.newReferenciesFilesArr)
      })
      .catch((err) => {
        console.log('Get user information err', err)
        setLoading(false)
      })
  }

  // Add note to list and data base
  const addNote = () => {
    if (note !== '') {
      setLoading(true)

      // Update database field with new data
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .update({
          notes: [
            ...notesArr,
            {
              note,
              timeStamp: new Date(),
              userName: agencyUserData.name,
              userLastName: agencyUserData.lastName
            }
          ]
        })
        .then(() => {
          setLoading(false)
          getNewData()
        })
        .catch((err) => {
          console.log('There is problem to put notes to database', err)
          setLoading(false)
        })

      setNote('')
    }
  }

  // Delete note and send new data to database
  const deleteNote = (index) => {
    if (notesArr.length > 0) {
      setLoading(true)
      const values = [...notesArr]
      values.splice(index, 1)

      // Update database field with new data
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .update({
          notes: values
        })
        .then(() => {
          setLoading(false)
          getNewData()
        })
        .catch((err) => {
          console.log('There is problem to put notes to database', err)
          setLoading('false')
        })
    }
  }

  const changeAllowEditProfileStatus = () => {
    db.collection('users')
      .doc(userData.userId)
      .update({
        'data.canEditCV': !allowEdit
      })
      .then(() => {
        setAllowEdit(!allowEdit)
      })
  }

  const sendEmail = (email) => {
    const subject = t('profileAccount')
    const body = t('keySubsystemEmployeeViewEmailBody')
    window.location = `mailto:${email}?subject=${subject}&body=${body}`
  }

  const deleteProfile = () => {
    setLoading(true)

    const deleteFiles = new Promise((resolve) => {
      const reference = storageRef.child(
        `agencies/${agencyData.id}/users-forms-uploads/${docId}`
      )
      reference
        .listAll()
        .then((response) => {
          const promise = response.items.forEach((item) => {
            reference.child(`/${item.name}`).delete()
          })
          Promise.all([promise]).then(() => resolve())
        })
        .catch((error) => {
          console.log("Can't get files list", error)
          resolve()
        })
    })

    const deleteData = new Promise((resolve) => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .delete()
        .then(() => {
          resolve()
        })
        .catch((error) => {
          console.log("Can't delete profile", error)
          resolve()
        })
    })

    Promise.all([deleteFiles, deleteData]).then(() => {
      setLoading(false)
      history.push(`/${agencyData.id}/key-dashboard`)
    })
  }

  const changeProfileTagStatus = () => {
    if (!tagged) {
      addTaggedProfileToUsersList()
    } else {
      removeTaggedProfileFromUsersList()
    }
  }

  const addTaggedProfileToUsersList = () => {
    const profileObject = {
      docId,
      firstName: userData.personalData.firstName,
      lastName: userData.personalData.lastName,
      nationality: userData.personalData.nationality,
      mainPosition: userData.personalData.mainPosition,
      timestamp: new Date()
    }

    let taggedProfilesArray = []
    if (agencyUserData.taggedProfiles !== undefined) {
      taggedProfilesArray = agencyUserData.taggedProfiles
      taggedProfilesArray.push(profileObject)
    } else {
      taggedProfilesArray = [profileObject]
    }

    db.collection('users')
      .doc(agencyUserData.uid)
      .update({
        'data.taggedProfiles': taggedProfilesArray
      })
      .then(() => setTagged(true))
      .catch((error) => {
        console.log("Can't add profile to tagged profiles list", error)
        setTagged(false)
      })
  }

  const removeTaggedProfileFromUsersList = () => {
    let taggedProfilesArray = []
    if (agencyUserData.taggedProfiles !== undefined) {
      taggedProfilesArray = agencyUserData.taggedProfiles

      const index = taggedProfilesArray.findIndex((el) => el.docId === docId)

      if (index >= 0) {
        taggedProfilesArray.splice(index, 1)
      }
    }

    db.collection('users')
      .doc(agencyUserData.uid)
      .update({
        'data.taggedProfiles': taggedProfilesArray
      })
      .then(() => setTagged(false))
      .catch((error) => {
        console.log("Can't remove profile from tagged profiles list", error)
        setTagged(true)
      })
  }

  const verifyProfile = () => {
    if (userData.personalData.email !== '') {
      setLoading(true)
      // Adapt language to api language format
      const systemLanguage = i18n.language

      axios({
        method: 'POST',
        url: 'https://europe-west1-norspeda.cloudfunctions.net/verifyMessageToEmloyee',
        data: {
          agencyName: agencyData.name,
          email: userData.personalData.email,
          language: systemLanguage,
          firstName: userData.personalData.firstName
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          const status = response.data.status
          if (status === 'OK') {
            // Add data to log
            const newLog = {
              _id: uuid(),
              firstName: agencyUserData.name,
              lastName: agencyUserData.lastName,
              message: 'verifiedProfile',
              timestamp: new Date(),
              uid: agencyUserData.uid
            }
            let newLogArray = []
            if (userData.log !== undefined) {
              newLogArray = userData.log
              newLogArray.unshift(newLog)
            } else {
              newLogArray.push(newLog)
            }

            // Update profile with new data
            await db
              .collection('agencies')
              .doc(agencyData.id)
              .collection('employees')
              .doc(docId)
              .update({
                verified: {
                  firstName: agencyUserData.name,
                  lastName: agencyUserData.lastName,
                  status: true,
                  timestamp: new Date(),
                  uid: agencyUserData.uid
                },
                log: newLogArray
              })
              .then(() => {
                setVerifyAlert(false)
                setLoading(false)
              })
              .catch((error) => {
                console.log("Can't update profile", error)
                setLoading(false)
              })
          } else if (status === 'ERROR') {
            console.log('Server error', status.error)
            setLoading(false)
          } else {
            setLoading(false)
          }
        })
        .catch((error) => {
          console.log("Can't send email", error)
          setLoading(false)
        })
    } else {
      setMessage({
        status: true,
        duration: 10000,
        severity: 'error',
        message: t('profileHasNoEmail657')
      })
    }
  }

  return (
    <div>
      {loading && <Loader />}

      <AgencyMenu type='key' />

      <Headline
        text={
          userData &&
          `${
            userData.personalData.firstName.charAt(0).toUpperCase() +
            userData.personalData.firstName.slice(1)
          } 
                ${
                  userData.personalData.lastName.charAt(0).toUpperCase() +
                  userData.personalData.lastName.slice(1)
                }`
        }
      />

      {/*
       * Main
       */}
      {userData && (
        <div>
          {/* Verify message */}
          <Snackbar
            open={verifyAlert}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            <Alert
              severity='warning'
              action={
                <div>
                  <Button onClick={() => verifyProfile()}>{t('verify')}</Button>
                  <Button onClick={() => setVerifyAlert(false)}>
                    {t('hide')}
                  </Button>
                </div>
              }
            >
              {t('profileIsNewlyCreated16854')}
            </Alert>
          </Snackbar>

          {/* Alerts */}
          <Snackbar
            open={message.status}
            autoHideDuration={message.duration}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            onClose={() => setMessage({ ...message, status: false })}
          >
            <Alert
              severity={message.severity}
              onClose={() => setMessage({ ...message, status: false })}
            >
              {message.message}
            </Alert>
          </Snackbar>

          {/* Delete profile warning box */}
          <Dialog
            open={deleteProfileWarningBox}
            classes={{ paper: classes.dialogRoot }}
          >
            <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
              {t('areYouShure')}
            </DialogTitle>

            <DialogContent style={{ marginTop: 20 }}>
              {t('deleteProfileWarningBoxContent')}
            </DialogContent>

            <DialogActions>
              <Button onClick={() => deleteProfile()}>{t('delete')}</Button>
              <Button
                onClick={() => {
                  setDeleteProfileWarningBox(false)
                }}
              >
                {t('cancel')}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Chips */}
          <div style={{ marginTop: 20 }}>
            {/* First row */}
            <Grid container spacing={1} justifyContent='center'>
              {/* status */}
              <Grid item>
                <Chip
                  label={`${t('status')}: ${t(userData.rotationLogStatus)}`}
                />
              </Grid>

              {/* PDF */}
              <Grid item>
                <Chip
                  label={
                    <GeneratedPDFViewer
                      userData={userData}
                      profilePhotoUrl={profilePhotoUrl}
                      companyLogoURL={companyLogoURL}
                      type='key'
                    />
                  }
                  clickable
                  icon={<FontAwesomeIcon icon={faDownload} />}
                />
              </Grid>

              {/* Edit */}
              <Grid item>
                <Chip
                  label={<div>{t('edit')}</div>}
                  clickable
                  onClick={() =>
                    history.push(
                      `/${agencyData.id}/key-dashboard/edit-employee/${docId}`
                    )
                  }
                  icon={<FontAwesomeIcon icon={faUserEdit} />}
                />
              </Grid>

              {/* Send email */}
              <Grid item>
                <Chip
                  label={t('sendEmail')}
                  clickable
                  onClick={() => sendEmail(userData.personalData.email)}
                  icon={<FontAwesomeIcon icon={faEnvelope} />}
                />
              </Grid>

              {userData.userId !== '' && (
                <Grid item>
                  <FormControlLabel
                    label={t('allowEdit')}
                    labelPlacement='start'
                    classes={{ label: classes.labelSize }}
                    control={
                      <Switch
                        checked={allowEdit}
                        onChange={() => changeAllowEditProfileStatus()}
                        size='small'
                        color='orange'
                      />
                    }
                  />
                </Grid>
              )}
            </Grid>

            {/* Second row */}
            <Grid
              container
              spacing={1}
              justifyContent='center'
              style={{ marginTop: 10 }}
            >
              {/* Delete profile */}
              <Grid item>
                <Chip
                  label={<div>{t('deleteProfile')}</div>}
                  clickable
                  onClick={() => setDeleteProfileWarningBox(true)}
                  icon={<FontAwesomeIcon icon={faUserTimes} />}
                />
              </Grid>

              {/* Created */}
              <Grid item>
                <Chip
                  label={`${t('created')}: ${secondsToDate(
                    userData.createdDate.seconds
                  )}`}
                />
              </Grid>

              {/* Updated */}
              <Grid item>
                <Chip
                  label={`${t('lastUpdate')}: ${secondsToDate(
                    userData.timeStamp.seconds
                  )}`}
                />
              </Grid>

              {/* Tag profile */}
              <Grid item>
                <div>
                  <FormControlLabel
                    label={t('tagProfile')}
                    labelPlacement='start'
                    classes={{ label: classes.labelSize }}
                    control={
                      <Switch
                        checked={tagged}
                        onChange={() => changeProfileTagStatus()}
                        size='small'
                        color='orange'
                      />
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          {/* Tabs menu */}
          <Tabs
            value={selectedTab}
            onChange={handleTabsChange}
            variant='scrollable'
            scrollButtons='auto'
            style={{ margin: '50px auto', maxWidth: 1500 }}
          >
            <Tab label={t('basicInfo')} />
            <Tab label={t('pre_evaluation')} />
            <Tab label={t('workSkills')} />
            <Tab label={t('personalSkills')} />
            <Tab label={t('experience')} />
            <Tab label={t('documents')} />
            <Tab label={t('coverLetterComments')} />
            <Tab label={t('agencyNotes')} />
            <Tab label={t('evaluation')} />
            <Tab label={t('rotationLog')} />
          </Tabs>

          {/* Display information acording selected tab */}
          <div>
            {/* Personal data */}
            {selectedTab === 0 && (
              <div className='crewEmployeeView__selectedContainer'>
                <div className='crewEmployeeView__basicInfo-box'>
                  {userData.userId !== '' && (
                    <div className='crewEmployeeView__basicInfo-el'>
                      <span>{t('userId')}:</span>{' '}
                      <span className='global__capitalizeFirstLetter'>
                        {t(userData.userId)}
                      </span>
                    </div>
                  )}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('mainPosition')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.mainPosition !== undefined &&
                        t(userData.personalData.mainPosition)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('secondPosition')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.secondPosition !== undefined &&
                        t(userData.personalData.secondPosition)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('firstName')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.firstName}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('lastName')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.lastName}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('address')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.address}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('phoneNumber')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.mobilePhone}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('email')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.email}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('familyStatus')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalData.familyStatus)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('healthStatus')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalData.healthStatus)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nationality')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalData.nationality)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('dateOfBirth')}:</span>{' '}
                    <span>{t(userData.personalData.dateOfBirth)}</span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('linkedInProfile')}:</span>{' '}
                    <span>{t(userData.personalData.linkedInLink)}</span>
                  </div>
                </div>
                <div className='crewEmployeeView__basicInfo-box'>
                  <div>
                    {profilePhotoUrl !== '' && profilePhotoUrl !== null ? (
                      <img
                        className='crewEmployeeView__basicInfo-profilePicture'
                        src={profilePhotoUrl}
                        alt='profile'
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: systemColors.lightElectric,
                          width: 150,
                          height: 150,
                          borderRadius: systemStylingSettings.borderRadius
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            fontSize: 100,
                            color: systemColors.background
                          }}
                          icon={faUser}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Pre Evaluation */}
            {selectedTab === 1 && (
              <PreEvaluation
                documentId={userData.docId}
                agencyData={agencyData}
                agentUserData={agencyUserData}
              />
            )}

            {/* Work skills */}
            {selectedTab === 2 && (
              <div
                className='crewEmployeeView__selectedContainer'
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t('workSkills')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('type')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData.workDiscipline.length > 0 ? (
                      userData.workDiscipline.length === 1 &&
                      userData.workDiscipline[0].discipline === '' &&
                      userData.workDiscipline[0].type === '' ? (
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{ textAlign: 'center' }}
                          >
                            {t('thereIsNoSkillsAdded')}
                          </TableCell>
                        </TableRow>
                      ) : (
                        userData.workDiscipline.map((el) => (
                          <TableRow hover key={el.id}>
                            <TableCell>{t(el.discipline)}</TableCell>
                            <TableCell>{t(el.type)}</TableCell>
                          </TableRow>
                        ))
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                          {t('thereIsNoSkillsAdded')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                <div
                  style={{ marginTop: 50 }}
                  className='crewEmployeeView__basicInfo-el'
                >
                  <span>{t('whichOurSevicesYouAreInterested')}?</span>{' '}
                  {t(userData.workDisciplineOtherData.servicesInterestedIn)}
                </div>

                {/* Additional requirements */}
                <div
                  style={{
                    marginTop: 40,
                    color: systemColors.lighGrey,
                    fontSize: 17
                  }}
                >
                  {t('additionalRequirements')}
                </div>
                <Divider />

                <div
                  className='keyEmployeeView__personalSkills-block'
                  style={{ marginTop: 20 }}
                >
                  {/* Education */}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('education')}:</span>{' '}
                    {t(
                      userData.workDisciplineOtherData.additionalRequirements
                        .education
                    )}
                  </div>

                  {/* Speciality */}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('speciality')}:</span>{' '}
                    {t(
                      userData.workDisciplineOtherData.additionalRequirements
                        .speciality
                    )}
                  </div>

                  {/* Certificates */}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('certificates')}:</span>{' '}
                    {userData.workDisciplineOtherData.additionalRequirements
                      .certificates !== undefined &&
                      userData.workDisciplineOtherData.additionalRequirements.certificates.map(
                        (certificate, index, array) => (
                          <span key={index}>
                            {t(certificate)}
                            {array.length - 1 !== index && <span>, </span>}
                          </span>
                        )
                      )}
                  </div>
                </div>
              </div>
            )}

            {/* Personal skils */}
            {selectedTab === 3 && (
              <div className='keyEmployeeView__personalSkills'>
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          {t('knowledgeOflanguage')}
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          {t('level')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.personalSkills.languageKnowlegeArr.length >
                      0 ? (
                        userData.personalSkills.languageKnowlegeArr.length ===
                          1 &&
                        userData.personalSkills.languageKnowlegeArr[0]
                          .language === '' &&
                        userData.personalSkills.languageKnowlegeArr[0].level ===
                          '' ? (
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              style={{ textAlign: 'center' }}
                            >
                              {t('thereIsNoSkillsAdded')}
                            </TableCell>
                          </TableRow>
                        ) : (
                          userData.personalSkills.languageKnowlegeArr.map(
                            (el) => (
                              <TableRow hover key={el.id}>
                                <TableCell>{el.language}</TableCell>
                                <TableCell>{t(el.level)}</TableCell>
                              </TableRow>
                            )
                          )
                        )
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{ textAlign: 'center' }}
                          >
                            {t('thereIsNoSkillsAdded')}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>

                <div className='keyEmployeeView__personalSkills-block'>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('driverLicence')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalSkills.driverLicence)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('canYouDriveToWork')}</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalSkills.canDrive)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('planToCintinueToLearn')}</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalSkills.continueLearn)}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* Experience */}
            {selectedTab === 4 && (
              <div className='crewEmployeeView__selectedContainer'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t('workplaceNameCity')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('country')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('position')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('from')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('till')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('mainResponsibilities')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData.experienceArr.length > 0 ? (
                      userData.experienceArr.length === 1 &&
                      userData.experienceArr[0].workplace === '' &&
                      userData.experienceArr[0].country === '' &&
                      userData.experienceArr[0].position === '' &&
                      userData.experienceArr[0].dateFrom === '' &&
                      userData.experienceArr[0].dateTill === '' ? (
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            style={{ textAlign: 'center' }}
                          >
                            {t('thereIsNoExperienceAdded')}
                          </TableCell>
                        </TableRow>
                      ) : (
                        userData.experienceArr.map((el) => (
                          <TableRow hover key={el.id}>
                            <TableCell>{el.workplace}</TableCell>
                            <TableCell>{el.country}</TableCell>
                            <TableCell>{t(el.position)}</TableCell>
                            <TableCell>{el.dateFrom}</TableCell>
                            <TableCell>
                              {el.stillWorking
                                ? t('stillWorking')
                                : el.dateTill}
                            </TableCell>
                            <TableCell>{el.workOccupation}</TableCell>
                          </TableRow>
                        ))
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                          {t('thereIsNoExperienceAdded')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            )}

            {/* Ddocuments */}
            {selectedTab === 5 && (
              <div className='keyEmployeeView__personalSkills'>
                <div className='keyEmployeeView__personalSkills-block'>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('uploadedCv')}: </span>
                    {uploadedCvUrl !== null && uploadedCvUrl !== '' ? (
                      <span className='global__capitalizeFirstLetter'>
                        <a
                          href={uploadedCvUrl}
                          target='_blank'
                          rel='noopener noreferrer'
                          download
                        >
                          {t('download')}
                        </a>
                      </span>
                    ) : (
                      <span className='global__capitalizeFirstLetter'>
                        {t('noFileAttached')}
                      </span>
                    )}
                  </div>
                </div>

                {/* Certificates and diplomas */}
                <div className='keyEmployeeView__personalSkills-block'>
                  <div
                    className='addNewKeyEmployee__list-title'
                    style={{ marginTop: 80 }}
                  >
                    {t('cerificatesAndDiplomas')}
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          {t('fileName')}
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          {t('issueDate')}
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          {t('expireDate')}
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          {t('file')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.documents.newCertificatesFilesArr.length > 0 ? (
                        userData.documents.newCertificatesFilesArr.length ===
                          1 &&
                        userData.documents.newCertificatesFilesArr[0]
                          .fileName === '' &&
                        userData.documents.newCertificatesFilesArr[0]
                          .issueDate === '' &&
                        userData.documents.newCertificatesFilesArr[0]
                          .expireDate === '' ? (
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              style={{ textAlign: 'center' }}
                            >
                              {t('thereIsNoDocumentsAdded')}
                            </TableCell>
                          </TableRow>
                        ) : (
                          userData.documents.newCertificatesFilesArr.map(
                            (el) => (
                              <TableRow hover key={el.id}>
                                <TableCell>{el.fileName}</TableCell>
                                <TableCell>
                                  {el.issueDate !== '' ? el.issueDate : '-'}
                                </TableCell>
                                <TableCell
                                  className={
                                    el.expireDate !== '' &&
                                    el.expireDate !== undefined
                                      ? el.expireDate <=
                                        convertDate(addWeeksToDate(2))
                                        ? classes.errorColor
                                        : null
                                      : null
                                  }
                                >
                                  {el.expireDate !== '' &&
                                  el.expireDate !== undefined
                                    ? el.expireDate
                                    : t('unlimited')}
                                </TableCell>
                                <TableCell>
                                  {addDocumentLink(el.id) !== '' && (
                                    <a
                                      href={addDocumentLink(el.id)}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      download
                                    >
                                      {t('download')}
                                    </a>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            style={{ textAlign: 'center' }}
                          >
                            {t('thereIsNoDocumentsAdded')}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>

                {/* Referencies */}
                <div className='keyEmployeeView__personalSkills-block'>
                  <div
                    className='addNewKeyEmployee__list-title'
                    style={{ marginTop: 80 }}
                  >
                    {t('referencies')}
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          {t('fileName')}
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          {t('file')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.documents.newReferenciesFilesArr.length > 0 ? (
                        userData.documents.newReferenciesFilesArr.length ===
                          1 &&
                        userData.documents.newReferenciesFilesArr[0]
                          .fileName === '' &&
                        userData.documents.newReferenciesFilesArr[0].file ===
                          '' ? (
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              style={{ textAlign: 'center' }}
                            >
                              {t('thereIsNoDocumentsAdded')}
                            </TableCell>
                          </TableRow>
                        ) : (
                          userData.documents.newReferenciesFilesArr.map(
                            (el) => (
                              <TableRow hover key={el.id}>
                                <TableCell>{el.fileName}</TableCell>
                                <TableCell>
                                  {addReferenceLink(el.id) !== '' && (
                                    <a
                                      href={addReferenceLink(el.id)}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      download
                                    >
                                      {t('download')}
                                    </a>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            style={{ textAlign: 'center' }}
                          >
                            {t('thereIsNoDocumentsAdded')}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}

            {/* Remarks */}
            {selectedTab === 6 && (
              <div className='keyEmployeeView__personalSkills'>
                <div className='keyEmployeeView__personalSkills-block'>
                  <div
                    className='addNewKeyEmployee__list-title'
                    style={{ marginTop: 80 }}
                  >
                    {t('comment')}:
                  </div>
                  <p>{userData.remarks.comment}</p>
                </div>
                <div className='keyEmployeeView__personalSkills-block'>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('howFastYouCanStart')}</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.remarks.whenCanStart)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('howFindOutAboutUs')}</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.remarks.howFindOut)}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* Agency notes */}
            {selectedTab === 7 && (
              <div className='crewEmployeeView__notes'>
                {/* Show notes */}
                <div className='crewEmployeeView__notes-cards'>
                  {notesArr.length > 0
                    ? notesArr.map((el, i) => {
                        return (
                          <Card
                            key={i}
                            variant='outlined'
                            classes={{ root: classes.cardRoot }}
                          >
                            <CardHeader
                              subheader={`${t('author')}: ${el.userName} ${
                                el.userLastName
                              } (${t('created')}: ${getDayAndHours(
                                el.timeStamp.seconds
                              )})`}
                            />
                            <CardContent>{el.note}</CardContent>
                            <CardActions
                              classes={{ root: classes.cardActionRoot }}
                            >
                              <Tooltip title={`${t('deleteNote')}`}>
                                <IconButton
                                  size='small'
                                  onClick={() => deleteNote(i)}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </IconButton>
                              </Tooltip>
                            </CardActions>
                          </Card>
                        )
                      })
                    : t('noNotes')}
                </div>

                {/* Add new note */}
                <TextField
                  size='small'
                  variant='outlined'
                  value={note}
                  type='text'
                  label={t('addNewNote')}
                  onChange={(e) => setNote(e.target.value)}
                  fullWidth
                  multiline
                  rows={5}
                />

                {/* Add button */}
                <div className='crewEmployeeView__notes-button'>
                  <Button
                    variant='contained'
                    size='small'
                    color='orange'
                    onClick={() => addNote()}
                  >
                    {t('addNote')}
                  </Button>
                </div>
              </div>
            )}

            {/*
             * Evaluation
             */}
            {selectedTab === 8 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  marginTop: 30
                }}
              >
                {/*
                 * History
                 */}
                <EvaluationHistory
                  profileId={userData.docId}
                  agencyId={agencyData.id}
                  agentRole={agencyUserData.userRole}
                />

                {/*
                 * Evaluation
                 */}
                <KeyEvaluation
                  docId={docId}
                  companyLogoURL={companyLogoURL}
                  userData={{
                    firstName: userData.personalData.firstName,
                    lastName: userData.personalData.lastName,
                    nationality: userData.personalData.nationality,
                    dateOfBirth: userData.personalData.dateOfBirth,
                    linkedInLink: userData.personalData.linkedInLink,
                    mainPosition: userData.personalData.mainPosition
                  }}
                />
              </div>
            )}

            {/* Rotation log */}
            {selectedTab === 9 && (
              <div style={{ marginTop: 50 }}>
                <RotationLog docId={docId} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    showGeneratedPdfBox: state.showGeneratedPdfBoxRed.bool,
    agencyUserData: state.agencyUserDataRed.obj,
    renderPdfModule: state.renderPdfModuleRed.str
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowGeneratedPdfBox: (bool) => dispatch(showGeneratedPdfBox(bool)),
    setRenderPdfModule: (str) => dispatch(renderPdfModule(str))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyEmployeeView)
