import React, { useState } from 'react'
import './generatedPdfViever.scss'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { KeyCvPdf } from '../pdfRender/keyCvPdf'
import { CrewCvPdf } from '../pdfRender/crewCvPdf'

interface Props {
  type: 'key' | 'crew'
  profilePhotoUrl: string
  companyLogoURL: string
  /** Key props */
  userData?: any
  /** Crew props */
  userArr?: any
  certificatesArr?: any
  ranksArr?: any
  engineTypeArr?: any
  vesselTypeArr?: any
}

const GeneratedPDFViewer = ({
  userData,
  profilePhotoUrl,
  companyLogoURL,
  type,
  userArr,
  certificatesArr,
  ranksArr,
  engineTypeArr,
  vesselTypeArr
}: Props) => {
  const { t } = useTranslation()

  const [download, setDownload] = useState<boolean>(false)

  if (!download) {
    return <div onClick={() => setDownload(true)}>{t('downloadUserCv')}</div>
  }

  if (download) {
    return (
      <PDFDownloadLink
        document={
          type === 'key' ? (
            <KeyCvPdf
              userData={userData}
              profilePhotoUrl={profilePhotoUrl}
              companyLogoURL={companyLogoURL}
            />
          ) : (
            <CrewCvPdf
              userArr={userArr}
              certificatesArr={certificatesArr}
              ranksArr={ranksArr}
              engineTypeArr={engineTypeArr}
              vesselTypeArr={vesselTypeArr}
              profilePhotoUrl={profilePhotoUrl}
              companyLogoURL={companyLogoURL}
            />
          )
        }
        fileName={
          type === 'key'
            ? `${userData.personalData.firstName} ${userData.personalData.lastName} CV.pdf`
            : `${userArr.firstName} ${userArr.lastName} CV.pdf`
        }
      >
        {({ blob, url, loading, error }) =>
          loading ? `${t('generating')}...` : t('download')
        }
      </PDFDownloadLink>
    )
  }
}

export default GeneratedPDFViewer
