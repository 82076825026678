const initialState = {
    arr: []
}

const stcwUploadRed = (state = initialState, action) => {
    switch(action.type){
        case 'STCW_UPLOAD' : {
            return {
                ...state,
                arr: action.arr
            }
        }
        default: {
            return state
        }
    }
}

export default stcwUploadRed