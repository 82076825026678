import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import firebase from 'firebase/app'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AgencyMenu from '../../../Components/AgencyMenu'
import Headline from '../../../Components/Headline'
import Loader from '../../../Components/Loader'
import { systemColors } from '../../../globalVariables'
import LastAgencyApplicants from './Modules/lastAgencyApplicants'
import LastNotifications from './Modules/lastNotifications'
import LastOnlineApplicants from './Modules/lastOnlineApplicants'
import LastViewedCompanies from './Modules/lastViewedCompanies'
import LastViewedProfiles from './Modules/lastViewedProfiles'
import TaggedProfiles from './Modules/taggedProfiles'

const db = firebase.firestore()

const useStyles = makeStyles({
  settingsIcon: {
    width: 'fit-content',
    float: 'right',
    color: systemColors.grey,
    cursor: 'pointer',
    fontSize: 20,
    marginRight: 30,
    '&:hover': {
      color: systemColors.lighGrey
    }
  }
})

const Dashboard = ({ userUID, agencyData, refreshDataRedux }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  // Change document title
  document.title = `${t('dashboard')} - Seanor`

  const [loadingData, setLoadingData] = useState(true)
  const [userRole, setUserRole] = useState('')
  const [settings, setSettings] = useState({})
  const [lastViewedCompanies, setLastViewedCompanies] = useState([])
  const [lastViewedProfiles, setLastViewedProfiles] = useState([])
  const [taggedProfiles, setTaggedProfiles] = useState([])

  useEffect(() => {
    let shouldUpdate = true

    ;(() => {
      db.collection('users')
        .doc(userUID)
        .get()
        .then((response) => {
          let data = JSON.stringify(response.data())
          data = JSON.parse(data)

          if (shouldUpdate) {
            setUserRole(data.data.userRole)
            if (data.data.settings !== undefined) {
              setSettings(data.data.settings)
            }
            if (data.data.lastViewedCompanies !== undefined) {
              setLastViewedCompanies(data.data.lastViewedCompanies)
            }
            if (data.data.lastViewedProfiles !== undefined) {
              setLastViewedProfiles(data.data.lastViewedProfiles)
            }
            if (data.data.taggedProfiles !== undefined) {
              setTaggedProfiles(data.data.taggedProfiles)
            }
            setLoadingData(false)
          }
        })
        .catch((error) => {
          console.log("Can't get users data", error)
          setLoadingData(false)
        })
    })()

    return () => {
      shouldUpdate = false
    }
  }, [refreshDataRedux, userUID])

  if (loadingData) {
    return <Loader />
  }

  return (
    <div id='dashboard'>
      <AgencyMenu type='key' />

      <Headline />

      {/* Settings icon */}
      <div style={{ marginTop: 20 }}>
        <Tooltip title={t('dashboardSettings')} placement='top'>
          <div
            className={classes.settingsIcon}
            onClick={() =>
              history.push(`/${agencyData.id}/settings/dashboard-settings`)
            }
          >
            <FontAwesomeIcon icon={faCog} />
          </div>
        </Tooltip>
      </div>

      {/* Dashboard items */}
      <div style={{ marginTop: 60 }}>
        <Grid
          container
          spacing={3}
          direction='row'
          justifyContent='center'
          alignItems='stretch'
        >
          {/*
           * Last notifications
           */}
          <Grid item>
            <LastNotifications />
          </Grid>

          {/* Last visited companies */}
          {userRole === 'agency_admin' ||
          userRole === 'super_admin' ||
          settings.adminPermissions.dashboard.allowLastViewedCompaniesKEY ===
            true ? (
            settings.userSettings !== undefined &&
            settings.userSettings.dashboard.allowLastViewedCompaniesKEY ===
              true ? (
              <Grid item>
                <LastViewedCompanies
                  companiesList={lastViewedCompanies}
                  agencyData={agencyData}
                  userUid={userUID}
                />
              </Grid>
            ) : null
          ) : null}

          {/* Last visited employees */}
          {userRole === 'agency_admin' ||
          userRole === 'super_admin' ||
          settings.adminPermissions.dashboard.allowLastViewedProfilesKEY ===
            true ? (
            settings.userSettings !== undefined &&
            settings.userSettings.dashboard.allowLastViewedProfilesKEY ===
              true ? (
              <Grid item>
                <LastViewedProfiles
                  profilesList={lastViewedProfiles}
                  agencyData={agencyData}
                  userUid={userUID}
                />
              </Grid>
            ) : null
          ) : null}

          {/* Tagged employees */}
          {settings.userSettings !== undefined &&
          settings.userSettings.dashboard.allowTaggedProfilesKEY === true ? (
            <Grid item>
              <TaggedProfiles
                taggedProfiles={taggedProfiles}
                agencyData={agencyData}
                userUid={userUID}
              />
            </Grid>
          ) : null}

          {/* Last agency applicants */}
          {settings.userSettings !== undefined &&
          settings.userSettings.dashboard.allowLastAgencyApplicantsKEY ===
            true ? (
            <Grid item>
              <LastAgencyApplicants agencyData={agencyData} userUid={userUID} />
            </Grid>
          ) : null}

          {/* Last online applicants  */}
          {settings.userSettings !== undefined &&
          settings.userSettings.dashboard.allowLastOnlineApplicantsKEY ===
            true ? (
            <Grid item>
              <LastOnlineApplicants agencyData={agencyData} userUid={userUID} />
            </Grid>
          ) : null}
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  agencyData: state.agencyDataRed.obj,
  refreshDataRedux: state.refreshDataRed.bool
})

export default connect(mapStateToProps)(Dashboard)
