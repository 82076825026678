import React from 'react'
import { connect } from 'react-redux'
import Header from '../../Modules/Header'

const SeanorTerm = ({ agencyData }) => {
  return (
    <div>
      <Header />
      <div className='terms'>
        <div className='terms__company'>
          <div>
            Limited liability company <b>“Norspeda Logistics”</b> - hereafter:{' '}
            <b>"Seanor"</b>
          </div>
          <div>Registration code: 300867887</div>
          <div>
            Legal address: Liepu str. 24-2, Klaipeda LT-92113 , Lithuania
          </div>
        </div>
        <h2>
          Terms of use of the database https://e.seanor.eu/{agencyData.id}
        </h2>
        <ol>
          <li>
            <b>Terms:</b>
            <ol>
              <li>
                <b>Database</b> – a database on the public website
                https://e.seanor.eu/{agencyData.id};
              </li>
              <li>
                <b>User</b> – a natural person who is registered in the
                Database;
              </li>
              <li>
                <b>Personal Data</b> - all personal data entered by the user in
                the Database, including, but not limited to, to a Database
                registration questionnaire, a CV questionnaire, name and
                surname, date of birth, place of birth, nationality, marital
                status, gender, phone number, email, address, personal photo,
                sea service and previous employment and experience data,
                document and certificate data (including scanned copies),
                biodata (including height, weight, size of clothes and boots,
                eye and hair color, blood type, and religion), education, bank
                details;
              </li>
              <li>
                <b>Controller</b> – {agencyData.name}, {agencyData.address} –
                the limited liability company <b>“Norspeda Logistics”</b> -
                hereafter: <b>“Seanor”</b> Registration code: 300867887 VAT
                code: LT100003349011 Address: Liepu str. 24-2, Klaipeda LT-92113
                , Lithuania, email: support@seanor.eu.{' '}
              </li>
              <li>
                <b>Recipient</b> - natural and legal persons, who use the
                Database for the search and selection of personnel;
              </li>
              <li>
                <b>Processor:</b>
                <ol>
                  <li>
                    Company registered in the Register of Enterprises of the
                    Republic of the Lithuania - the limited liability company{' '}
                    <b>“Norspeda Logistics”</b> - hereafter: <b>“Seanor”</b>{' '}
                    Registration code: 300867887 VAT code: LT100003349011
                    Address: Liepu str. 24-2, Klaipeda LT-92113 , Lithuania,
                    email: support@seanor.eu.
                  </li>
                </ol>
              </li>
              <li>
                <b>Services</b> – the Processor’s provided services to
                employment seekers, including the ability to add the User’s
                Personal data to the Database, receive information in e-mail
                about employment opportunities, add an advertisement for
                employment search, etc.
              </li>
              <li>
                <b>Purpose of data processing</b> - Your personal data is stored
                in the data basis with the purpose that you could contact
                possible employers in your professional sector. You are free to
                amend or delete your information any time. Your data are stored
                and process as long as you have an interest in being in this
                data basis.
              </li>
            </ol>
          </li>
          <li>
            <b>Rights and obligations of the User:</b>
            <ol>
              <li>
                The User, by entering the Personal data in the Database
                registration questionnaire, according to these terms of the use
                of the Database, is entitled, to access the Database to use the
                Services.
              </li>
              <li>
                The Users accesses the Database, by using the personal e-mail,
                where the User undertakes to store the access data in a secure
                place and manner that is unavailable to third parties. The user
                is obliged to immediately inform the Data processor about cases,
                when there is suspicious that the access might be used by
                another person, by sending a written notification to
                support@seanor.eu.
              </li>
              <li>
                The User is obliged to ensure that his submitted Personal data
                is accurate and verified. They may not contain false or
                misleading information regarding any information about the User.
                The User shall be responsible for the damage caused or the
                damage that might be caused ta any person due to the lack of
                accuracy, errors or failure of this submitted Personal data.
              </li>
              <li>
                The User does not obtain copyright to the Database or any of its
                parts when pacing his personal data.
              </li>
              <li>
                The User has the right to add, edit, change or delete Personal
                data placed in the Database at any time.
              </li>
              <li>
                The User shall be responsible for the damage caused or the
                damage that might be cause to any person as a result of any of
                these activities, in whole or in part by copying the Database,
                damaging or disrupting the operation of the Database, including,
                but not limited to the use of various computer software.
              </li>
              <li>
                The User agrees that all Personal data placed by the User in the
                Database may be transferred to the Data recipients both in the
                Member States of the European Union and the recipients outside
                the European Union.
              </li>
              <li>
                If the User fails to comply with these terms, he shall be
                responsible in accordance with the laws of the Republic of
                Lithuania.
              </li>
            </ol>
          </li>
          <li>
            <b>Rights and obligations of the Data processor:</b>
            <ol>
              <li>
                After the User is registered in the Database, the Processor
                undertakes to provide the User with access to the Database by
                sending and electronic encrypted link to the User’s email
                registered in the Database, with which the User can access the
                Database.
              </li>
              <li>
                Each time the e-mail address is entered, the user receives a new
                encrypted link, which the user accesses the Database.
              </li>
              <li>
                The Processor processes the User’s Personal data to make it
                available to the Recipients.
              </li>
              <li>
                In the event that the Processor has an objective reason to
                believe that the personal data entered in the Database is
                erroneous or inconsistent with generally accepted rules of
                decency or ethics, the Processor shall be entitled to delete the
                data in whole or in part by notifying the User.
              </li>
              <li>
                The processor is not an authorized representative, agent or
                executor of the Data recipient. Therefore, the Processor is not
                responsible for the damage or loss that the User is experiencing
                or might arise in seeking employment or applying for employment
                places with the help of the Database. The User concludes a
                contract with the recipient of the Data without the Processor.
                The User shall not be responsible for the promises, offers,
                content and consequences of the data received by the data
                recipients.
              </li>
              <li>
                The Processor has not undertaken and shall not be responsible
                for finding an employment for the User or entering into a
                contract with person requiring staff.
              </li>
              <li>
                The Processor has the right to notify the User via e-mail about
                new vacancies, the Database’s new, or changes, the User’s
                collaboration partners and client’s services and opportunities.
              </li>
              <li>
                The controller, the processor and the recipients will process
                the User’s personal data and Health data subject to privacy
                requirements and ensure the security of the User’s personal
                data.
              </li>
            </ol>
          </li>
        </ol>
        <h2>Notification of personal data protection and privacy protection</h2>
        <h3>Information on personal data processor</h3>
        <p>
          Our company – a limited liability company <b>“Norspeda Logistics”</b>{' '}
          – hereafter: <b>"Seanor"</b>, registered in the Register of
          Enterprises of the Republic of the Lithuania - the limited liability
          company <b>“Norspeda Logistics”</b> - hereafter: <b>"Seanor"</b>{' '}
          Registration code: 300867887 VAT code: LT100003349011 Address: Liepu
          str. 24-2, Klaipeda LT-92113 , Lithuania, email: support@seanor.eu –
          is your data <b>processor</b>.
        </p>
        <h3>General characterisation of the processing of personal data</h3>
        <p>
          The purpose of this personal data processing notification is to inform
          you of the data processing activities and purposes of personal data
          processed by our company.
        </p>
        <p>
          A personal data shall be any information that may be attributed
          directly or indirectly to you.
        </p>
        <p>
          Our company respects EU Regulation 2016/679 (hereafter: GDPR) and of
          the Council of April 27 , 2016, on the protection of personal data
          relating to the processing of personal data and the free movement of
          such data and cancels Directive 95/46/EC.
        </p>
        <p>
          <b>“Norspeda Logistics”</b> – hereafter: <b>"Seanor"</b> is committed
          to ensure that Your information remains secure. In order to prevent
          unauthorized access or disclosure, <b>“Norspeda Logistics”</b> –
          hereafter: <b>"Seanor"</b> has put in place necessary physical,
          electronic, and managerial procedures to secure and safeguard the
          collected information.
        </p>
        <h3>WHAT DATA IS BEING COLLECTED</h3>
        <p>
          We may collect the following data about You: name and surname, date of
          birth, place of birth, nationality, marital status, gender, phone
          number, email, address, personal photo, sea service and previous
          employment and experience data, document and certificate data
          (including scanned copies), biodata (including height, weight, size of
          clothes and boots, eye and hair color, blood type, and religion),
          education, bank details.
        </p>
        <p>
          We may also collect the following data about Your next of kin: name
          and surname, date of birth, relationship to You, gender, phone number,
          email, address.
        </p>
        <p>
          We may also collect the following data about Your children: name and
          surname, date of birth, relationship to you, gender.
        </p>
        <h3>FOR WHAT PURPOSE IS THE DATA BEING COLLECTED</h3>
        <p>
          We requires all the aforementioned data in order to process Your
          application, present to a possible company of Your employment
          (hereafter: employer), and use Your provided personal data for
          employment, as it is a requirement necessary to enter into a contract.
        </p>
        <p>
          The data of Your next of kin is collected in order to contact Your
          next of kin in emergency situations, information checking, or any
          other urgent cases when You cannot be contacted directly.
        </p>
        <p>
          Furthermore, any data collected about Your children is required for
          liability insurance in case of your decease, or if it is for
          insurance, mandatory for employment agreement.
        </p>
        <p>
          Finally, the data is needed in order to provide services, necessary to
          ensure that You reach and return from employment destination.
        </p>
        <h3>WITH WHOM IS YOUR DATA BEING SHARED</h3>
        <p>
          With Your consent, <b>Processor</b> is sharing—not necessarily
          all—Your personal data with possible employers, shipping and
          travelling agents, and <b>Processor</b> employees when it is crucial
          to provide employment services. The employers and agents may be from
          third countries or international organizations, therefore we will
          employ all means possible and enforce appropriate safeguards to ensure
          that Your data is handled securely.
        </p>
        <p>
          <b>Processor</b> may share Your personal data, in accordance to GDPR,
          with local authorities if it would be required by law.
        </p>
        <h3>FOR HOW LONG IS YOUR DATA BEING STORED</h3>
        <b>Processor</b> has the following rules for data and document storing:
        <ul>
          <li>
            all the personal data that You have provided in the online
            application form is stored in online and local electronic database
            indefinitely, unless it has expired or no longer applicable, or it
            was updated with new data;
          </li>
          <li>
            employment agreements are stored indefinitely in local electronic
            database and paper archive;
          </li>
          <li>
            all bank account data is stored indefinitely in local electronic and
            online database, paper copy is destroyed if no longer applicable or
            updated with new data;
          </li>
          <li>
            valid scanned copies of certificates are stored in online and local
            electronic database, paper copy and is destroyed upon expiry;
          </li>
          <li>
            relevant performance reports and questionnaires are kept
            indefinitely in local electronic database, paper copy is destroyed
            after 5 years;
          </li>
          <li>
            statement for expenses, excluding scanned copies of cheques, are
            stored indefinitely in electronic database, paper copy is destroyed
            after a year;
          </li>
          <li>
            interview information is stored indefinitely in electronic and
            online database, paper copy is destroyed if no longer applicable or
            updated with new data;
          </li>
          <li>
            presentation emails sent to companies are stored in online and local
            electronic database indefinitely, paper copy is destroyed after 3
            years.
          </li>
          <li>
            sea service data is stored indefinitely in online database, paper
            copy is destroyed upon being updated with new information.
          </li>
          <li>
            statement of sea service confirmation is stored in online and local
            electronic database, and paper copy is destroyed after 3 years.
          </li>
          All other data may be stored as per data protection requirement
          regulations defined by the local authority.
        </ul>
        <h3>WHAT RIGHTS DO YOU HAVE</h3>
        As per GDPR Articles 15-25, You have the following rights regarding the
        data You provide:
        <ul>
          <li>
            right of access — the ability to get access to Your personal data
            that is being processed. This allows to see or view Your own
            personal data, as well as request copies of the personal data (GDPR
            art. 15);
          </li>
          <li>
            right of rectification — the ability to ask for modifications on
            Your personal data in case it might be inaccurate or outdated (GDPR
            art. 16);
          </li>
          <li>
            right of erasure — the ability to ask to delete Your personal data
            that is not required to keep by the applicable laws (GDPR art. 17);
          </li>
          <li>
            right to restriction of processing — the ability to limit the
            processing of Your data as according to GDPR rules and regulations
            (GDPR art. 18);
          </li>
          <li>
            right to be informed — the ability to ask for information about what
            personal data is being processed and why is it being processed (GDPR
            art. 19);
          </li>
          <li>
            right to data portability — the ability to ask for transfer of Your
            personal data to You or otherdata controller (GDPR art. 20);
          </li>
          <li>
            right to object — the ability to object Your data being processed
            any further (GDPR art. 21);
          </li>
          <li>
            right to not be subject for automatisation — the ability to ask for
            manual data processing rather than automated data processing (GDPR
            art. 22).
          </li>
          All above mentioned rights and any other rights enforced by local
          authority may be a subject to restrictions as per GPDR Article 23.
        </ul>
        <h3>HOW CAN YOU RAISE A COMPLAINT?</h3>
        <p>
          In case of any requests, complains or questions regarding personal
          data management, You can always contact <b>Processor</b> Data
          Protection Officer (DPO) using contact information provided
          beforehand. As per GDPR Article 12-3, <b>Processor</b> is required to
          provide information on any action taken on a request within 1 month of
          receipt of the request. This period may be extended up to 3 months
          where necessary, previously informing about the extension within 1
          month of receipt of the request.
        </p>
        <h4>Revision of privacy protection rules</h4>
        <p>
          Our company regularly revises and updates personal data protection
          rules. Last revision- January 5th , 2021.
        </p>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj
  }
}

export default connect(mapStateToProps)(SeanorTerm)
