import keyEmployeeDataClass from '../classes/keyEmployeeDataClass'

// Convert data to firestore data
export const keyEmployeeDataConverter = {
  toFirestore: (user) => {
    return {
      docId: user.docId,
      userId: user.userId,
      timeStamp: user.timeStamp,
      personalData: user.personalData,
      workDiscipline: user.workDiscipline,
      personalSkills: user.personalSkills,
      experienceArr: user.experienceArr,
      documents: user.documents,
      remarks: user.remarks,
      notes: user.notes,
      evaluations: user.evaluations,
      workDisciplineOtherData: user.workDisciplineOtherData,
      rotationLog: user.rotationLog,
      searchTags: user.searchTags,
      rotationLogStatus: user.rotationLogStatus,
      profileStatus: user.profileStatus,
      createdDate: user.createdDate,
      plannedOnProjects: user.plannedOnProjects,
      addType: user.addType,
      createdBy: user.createdBy,
      log: user.log,
      verified: user.verified
    }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return new keyEmployeeDataClass(
      data.docId,
      data.userId,
      data.timeStamp,
      data.personalData,
      data.workDiscipline,
      data.personalSkills,
      data.experienceArr,
      data.documents,
      data.remarks,
      data.notes,
      data.evaluations,
      data.workDisciplineOtherData,
      data.rotationLog,
      data.searchTags,
      data.rotationLogStatus,
      data.profileStatus,
      data.createdDate,
      data.plannedOnProjects,
      data.addType,
      data.createdBy,
      data.log,
      data.verified
    )
  }
}
