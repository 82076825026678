import React, { useEffect, useState } from 'react'
import './headline.scss'
import logo from '../media/logo/seanor_white_logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { Badge, Switch, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { systemColors } from '../globalVariables'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const db = firebase.firestore()

const useStyles = makeStyles({
  switchBase: {
    '&.Mui-checked': {
      color: systemColors.white
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.white
    }
  },
  switchPrimary: {
    '&.Mui-checked': {
      color: systemColors.white
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: systemColors.white
    }
  }
})

interface Props {
  text: string
  agencyData: AgencyData
  agencyUserData: AgencyUserData
}

const Headline = ({ text, agencyData, agencyUserData }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const [darkThemeStatus, setDarkThemeStatus] = useState<boolean>(false)

  const [refreshData, setRefreshData] = useState<boolean>(false)

  const [notifications, setNotifications] = useState<SystemNotification[]>([])

  useEffect(() => {
    let shouldUpdate = true

    /**
     * Set dark mode
     */
    const darkModeStatus = window.localStorage.getItem('seanorDarkMode')

    if (darkModeStatus === 'true') {
      if (shouldUpdate) {
        document.body.classList.add('darkTheme')
        setDarkThemeStatus(true)
      }
    } else {
      if (shouldUpdate) {
        document.body.classList.remove('darkTheme')
        setDarkThemeStatus(false)
      }
    }

    /**
     * Fetch notifications
     */
    if (agencyUserData.uid !== undefined) {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('notifications')
        .where('data.userUid', '==', agencyUserData.uid)
        .where('data.readed', '==', false)
        .orderBy('data.timestamp', 'desc')
        .get()
        .then((snap) => {
          let notificationsArray: SystemNotification[] = []

          snap.forEach((doc) => {
            const data: any = JSON.parse(JSON.stringify(doc.data()))

            notificationsArray = [...notificationsArray, data.data]
          })

          if (shouldUpdate) {
            setNotifications(notificationsArray)
          }
        })
        .catch((error) => console.error(error))
    }

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, agencyUserData, refreshData])

  const changeDarkTheme = () => {
    if (darkThemeStatus) {
      window.localStorage.setItem('seanorDarkMode', 'false')
      document.body.classList.remove('darkTheme')
      setDarkThemeStatus(false)
    } else {
      window.localStorage.setItem('seanorDarkMode', 'true')
      document.body.classList.add('darkTheme')
      setDarkThemeStatus(true)
    }
  }

  const markAsReadedNotification = (id: string) => {
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('notifications')
      .doc(id)
      .update({ 'data.readed': true })
      .then(() => setRefreshData(!refreshData))
      .catch((error) => console.error(error))
  }

  return (
    <div className='headline'>
      {/* Logo */}
      <div className='headline_block'>
        <img className='headline__logo' src={logo} alt='logo' />
      </div>

      <div
        className='headline_block'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {/* Icons */}
        <div style={{ display: 'flex', padding: 0 }}>
          {/*
           * Notofications
           */}
          {agencyUserData.uid !== undefined && (
            <div className='notification' style={{ marginRight: 40 }}>
              {/*
               * Icon
               */}
              <Badge
                color='orange'
                invisible={notifications.length > 0 ? false : true}
                variant='dot'
                className='notification_icon'
              >
                <FontAwesomeIcon icon={faBell as IconProp} />
              </Badge>

              {/*
               * List of notifications
               */}

              <div className='notification_list'>
                <div className='notifications'>
                  {notifications.map((notification) => (
                    <div
                      className='notification_list_block'
                      key={notification._id}
                    >
                      {/*
                       * Title and source
                       */}
                      <div>
                        <h4>{notification.title}</h4>
                        <span>{t(notification.source)}</span>
                      </div>

                      {/*
                       * Mark as readed button
                       */}
                      <div>
                        <Tooltip title={t('mark_as_readed')}>
                          <div
                            className='notification_readed'
                            onClick={() =>
                              markAsReadedNotification(notification._id)
                            }
                          />
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>

                {/*
                 * Link to all notifications
                 */}
                <div
                  className='all_notifications'
                  onClick={() =>
                    history.push(`/${agencyData.id}/key/notifications`)
                  }
                >
                  {t('all_notifications')}
                </div>
              </div>
            </div>
          )}

          {/* Dark mode button */}
          <div style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
            {/* Sun icon */}
            <FontAwesomeIcon icon={faSun as IconProp} />

            {/* Switch */}
            <Switch
              size='small'
              checked={darkThemeStatus}
              onChange={() => changeDarkTheme()}
              classes={{
                switchBase: classes.switchBase,
                colorPrimary: classes.switchPrimary
              }}
            />

            {/* Moon icon */}
            <FontAwesomeIcon
              style={{ marginLeft: 2 }}
              icon={faMoon as IconProp}
            />
          </div>
        </div>

        {/* Headline text */}
        <div style={{ marginLeft: 40 }}>
          <h2>{text}</h2>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  agencyData: state.agencyDataRed.obj,
  agencyUserData: state.agencyUserDataRed.obj
})

export default connect(mapStateToProps)(Headline)
