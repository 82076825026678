import React, { useCallback, useEffect, useState } from 'react'
import Loader from '../../Components/Loader'
import firebase from 'firebase/app'
import {
  Chip,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Divider
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { systemColors, systemStylingSettings } from '../../globalVariables'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { showGeneratedPdfBox } from '../../redux/actions/showGeneratedPdfBox'
import GeneratedPDFViewer from '../../Components/GeneratedPdfViewer'
import { KeyCvPdf } from '../../pdfRender/keyCvPdf'
import {
  secondsToDate,
  convertDate,
  addWeeksToDate
} from '../../globalFunctions'
import { useHistory } from 'react-router-dom'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

const useStyles = makeStyles({
  tableHeader: {
    color: systemColors.darkBlue,
    fontSize: 16
  },
  tabRoot: {
    maxWidth: 150,
    padding: 0,
    color: systemColors.lightBlue,
    opacity: 1,
    borderTop: `1px solid ${systemColors.lighGrey}`,
    borderBottom: `1px solid ${systemColors.lighGrey}`
  },
  tabsIndicator: {
    backgroundColor: systemColors.orange
  },
  buttonEndIcon: {
    color: '#ffffff',
    '& > *:first-child': {
      fontSize: '1em'
    }
  },
  buttonRoot: {
    color: '#ffffff'
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: systemColors.orangeDark
    }
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  cardRoot: {
    marginBottom: 10
  },
  errorColor: {
    color: `${systemColors.errorColor} !important`
  },
  tabsRoot: {
    justifyContent: 'center',
    marginTop: 20
  },
  tabsScroller: {
    flexGrow: 0
  },
  section: {
    marginTop: 30
  },
  noteRoot: {
    backgroundColor: systemColors.inputColor
  },
  cardActionRoot: {
    float: 'right'
  },
  chipsDisplay: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: 5
    },
    marginTop: 20
  }
})

const KeyEmployeeViewData = ({
  agencyData,
  showGeneratedPdfBox,
  setShowGeneratedPdfBox,
  agencyUserData,
  docId
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  // Data from database
  const [userData, setUserData] = useState()

  const [selectedTab, setSelectedTab] = useState(0)
  const [profilePhotoUrl, setProfilePhotoUrl] = useState(null)
  const [uploadedCvUrl, setUploadedCvUrl] = useState(null)
  const [documentsLinks, setDocumentsLinks] = useState([])
  const [referenceLinks, setReferenceLinks] = useState([])

  const [canEditCV, setCanEditCV] = useState(false)
  const [companyLogoURL, setCompanyLogoURL] = useState('')

  // Get profile picture
  const getProfilePhoto = useCallback(
    (photoName) => {
      setLoading(true)
      if (photoName !== null) {
        storageRef
          .child(
            `agencies/${agencyData.id}/users-forms-uploads/${docId}/${photoName}`
          )
          .getDownloadURL()
          .then((url) => {
            setProfilePhotoUrl(url)
            setLoading(false)
          })
          .catch((err) => {
            console.log('Profile photo error:', err)
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    },
    [agencyData, docId]
  )

  // Get uploaded CV url
  const getUploadedCvUrl = useCallback(
    (cvName) => {
      setLoading(true)
      if (cvName !== null) {
        storageRef
          .child(
            `agencies/${agencyData.id}/users-forms-uploads/${docId}/${cvName}`
          )
          .getDownloadURL()
          .then((url) => {
            setUploadedCvUrl(url)
            setLoading(false)
          })
          .catch((err) => {
            console.log('Uploaded CV error:', err)
            setUploadedCvUrl('')
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    },
    [agencyData, docId]
  )

  // Get cerificates and documents links
  const getDocumentsLinks = useCallback(
    async (arr) => {
      const getLinks = await arr.map((obj) => {
        if (obj.file !== null && obj.file !== '') {
          // eslint-disable-next-line
          return new Promise((res) => {
            storageRef
              .child(
                `agencies/${agencyData.id}/users-forms-uploads/${docId}/${obj.file}`
              )
              .getDownloadURL()
              .then((url) => {
                res({
                  id: obj.id,
                  url
                })
              })
              .catch((error) => {
                console.log("Can't download file", error)
                res({
                  id: obj.id,
                  url: ''
                })
              })
          })
        } else {
          // eslint-disable-next-line
          return
        }
      })
      Promise.all(getLinks).then((res) => setDocumentsLinks(res))
    },
    [agencyData, docId]
  )

  // Get references links
  const getReferenceLinks = useCallback(
    async (arr) => {
      const getLinks = await arr.map((obj) => {
        if (obj.file !== null && obj.file !== '') {
          // eslint-disable-next-line
          return new Promise((res) => {
            storageRef
              .child(
                `agencies/${agencyData.id}/users-forms-uploads/${docId}/${obj.file}`
              )
              .getDownloadURL()
              .then((url) => {
                res({
                  id: obj.id,
                  url
                })
              })
              .catch((error) => {
                console.log("Can't download file", error)
                res({
                  id: obj.id,
                  url: ''
                })
              })
          })
        } else {
          // eslint-disable-next-line
          return
        }
      })
      Promise.all(getLinks).then((res) => setReferenceLinks(res))
    },
    [agencyData, docId]
  )

  // Get user cv from data base
  useEffect(() => {
    let shouldUpdate = true
    if (shouldUpdate) {
      setLoading(true)
    }

    ;(async () => {
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('employees')
        .doc(docId)
        .get()
        .then((res) => {
          let data = JSON.stringify(res.data())
          data = JSON.parse(data)

          if (shouldUpdate) {
            setUserData(data)
            getUploadedCvUrl(data.documents.cvFile)
            setLoading(false)

            // Set files links
            getProfilePhoto(data.personalData.profilePhoto)
            getDocumentsLinks(data.documents.newCertificatesFilesArr)
            getReferenceLinks(data.documents.newReferenciesFilesArr)
          }

          // Change document title
          document.title = `${
            data.personalData.firstName.charAt(0).toUpperCase() +
            data.personalData.firstName.slice(1)
          } 
                  ${
                    data.personalData.lastName.charAt(0).toUpperCase() +
                    data.personalData.lastName.slice(1)
                  } - Seanor`

          // Get company logo URL
          storage
            .ref()
            .child(
              `/public/agencies/${agencyData.id}/logo/${agencyData.logoFileName}`
            )
            .getDownloadURL()
            .then((url) => {
              if (shouldUpdate) {
                setCompanyLogoURL(url)
              }
            })
            .catch((error) => {
              console.log("Can't get logo url", error)
            })
        })
        .catch((err) => {
          console.log('Get user information err', err)
          if (shouldUpdate) {
            if (shouldUpdate) {
              setLoading(false)
            }
          }
        })
    })()

    return () => {
      shouldUpdate = false
    }
  }, [
    docId,
    agencyData,
    getDocumentsLinks,
    getProfilePhoto,
    getReferenceLinks,
    getUploadedCvUrl
  ])

  // Change selected tab value
  const handleTabsChange = (e, newValue) => {
    setSelectedTab(newValue)
  }

  // Find and add document link
  const addDocumentLink = (id) => {
    let link = ''
    documentsLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  // Find and add document link
  const addReferenceLink = (id) => {
    let link = ''
    referenceLinks.find((el) => {
      if (el !== undefined && el.id === id) {
        link = el.url
      }
      return link
    })
    return link
  }

  return (
    <div>
      {loading && <Loader />}

      {userData && (
        <div>
          {/* PDF viewer */}
          {showGeneratedPdfBox && (
            <GeneratedPDFViewer>
              <KeyCvPdf
                userData={userData}
                profilePhotoUrl={profilePhotoUrl}
                companyLogoURL={companyLogoURL}
              />
            </GeneratedPDFViewer>
          )}

          {/* Chips */}
          <div className={classes.chipsDisplay}>
            <Chip
              label={t('downloadCv')}
              clickable
              onClick={() => setShowGeneratedPdfBox(true)}
              icon={<FontAwesomeIcon icon={faDownload} />}
            />
            <Chip
              label={t('edit')}
              clickable
              onClick={() =>
                !agencyUserData.canEditCV
                  ? setCanEditCV(!canEditCV)
                  : history.push(
                      `/${agencyData.id}/dashboard/edit-key/${userData.docId}`
                    )
              }
              icon={<FontAwesomeIcon icon={faUserEdit} />}
            />
            <Chip
              label={`${t('lastUpdate')}: ${secondsToDate(
                userData.timeStamp.seconds
              )}`}
            />
          </div>

          {/* Tabs menu */}
          <Tabs
            value={selectedTab}
            onChange={handleTabsChange}
            variant='scrollable'
            scrollButtons='auto'
            style={{ margin: '50px auto', maxWidth: 1500 }}
          >
            <Tab label={t('basicInfo')} />
            <Tab label={t('workSkills')} />
            <Tab label={t('personalSkills')} />
            <Tab label={t('experience')} />
            <Tab label={t('documents')} />
            <Tab label={t('coverLetterComments')} />
          </Tabs>

          {/* Display information acording selected tab */}
          <div>
            {/* Personal data */}
            {selectedTab === 0 && (
              <div className='crewEmployeeView__selectedContainer'>
                <div className='crewEmployeeView__basicInfo-box'>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('mainPosition')}:</span>
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.mainPosition !== undefined &&
                        t(userData.personalData.mainPosition)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('secondPosition')}:</span>
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.secondPosition !== undefined &&
                        t(userData.personalData.secondPosition)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('firstName')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.firstName}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('lastName')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.lastName}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('address')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.address}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('phoneNumber')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.mobilePhone}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('email')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {userData.personalData.email}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('familyStatus')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalData.familyStatus)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('healthStatus')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalData.healthStatus)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('nationality')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalData.nationality)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('dateOfBirth')}:</span>{' '}
                    <span>{t(userData.personalData.dateOfBirth)}</span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('linkedInProfile')}:</span>{' '}
                    <span>{t(userData.personalData.linkedInLink)}</span>
                  </div>
                </div>
                <div className='crewEmployeeView__basicInfo-box'>
                  <div>
                    <img
                      className='crewEmployeeView__basicInfo-profilePicture'
                      src={profilePhotoUrl}
                      alt=''
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Work skills */}
            {selectedTab === 1 && (
              <div
                className='crewEmployeeView__selectedContainer'
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t('workSkills')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('type')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData.workDiscipline.length > 0 ? (
                      userData.workDiscipline.length === 1 &&
                      userData.workDiscipline[0].discipline === '' &&
                      userData.workDiscipline[0].type === '' ? (
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{ textAlign: 'center' }}
                          >
                            {t('thereIsNoSkillsAdded')}
                          </TableCell>
                        </TableRow>
                      ) : (
                        userData.workDiscipline.map((el) => (
                          <TableRow hover key={el.id}>
                            <TableCell>{t(el.discipline)}</TableCell>
                            <TableCell>{t(el.type)}</TableCell>
                          </TableRow>
                        ))
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                          {t('thereIsNoSkillsAdded')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                <div
                  style={{ marginTop: 50 }}
                  className='crewEmployeeView__basicInfo-el'
                >
                  <span>{t('whichOurSevicesYouAreInterested')}?</span>{' '}
                  {t(userData.workDisciplineOtherData.servicesInterestedIn)}
                </div>

                {/* Additional requirements */}
                <div
                  style={{
                    marginTop: 40,
                    color: systemColors.lighGrey,
                    fontSize: 17
                  }}
                >
                  {t('additionalRequirements')}
                </div>
                <Divider />

                <div
                  className='keyEmployeeView__personalSkills-block'
                  style={{ marginTop: 20 }}
                >
                  {/* Education */}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('education')}:</span>{' '}
                    {t(
                      userData.workDisciplineOtherData.additionalRequirements
                        .education
                    )}
                  </div>

                  {/* Speciality */}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('speciality')}:</span>{' '}
                    {t(
                      userData.workDisciplineOtherData.additionalRequirements
                        .speciality
                    )}
                  </div>

                  {/* Certificates */}
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('certificates')}:</span>{' '}
                    {userData.workDisciplineOtherData.additionalRequirements.certificates.map(
                      (certificate, index, array) => (
                        <span key={index}>
                          {t(certificate)}
                          {array.length - 1 !== index && <span>, </span>}
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Personal skills */}
            {selectedTab === 2 && (
              <div className='keyEmployeeView__personalSkills'>
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          {t('knowledgeOflanguage')}
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          {t('level')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.personalSkills.languageKnowlegeArr.length >
                      0 ? (
                        userData.personalSkills.languageKnowlegeArr.length ===
                          1 &&
                        userData.personalSkills.languageKnowlegeArr[0]
                          .language === '' &&
                        userData.personalSkills.languageKnowlegeArr[0].level ===
                          '' ? (
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              style={{ textAlign: 'center' }}
                            >
                              {t('thereIsNoSkillsAdded')}
                            </TableCell>
                          </TableRow>
                        ) : (
                          userData.personalSkills.languageKnowlegeArr.map(
                            (el) => (
                              <TableRow hover key={el.id}>
                                <TableCell>{el.language}</TableCell>
                                <TableCell>{t(el.level)}</TableCell>
                              </TableRow>
                            )
                          )
                        )
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{ textAlign: 'center' }}
                          >
                            {t('thereIsNoSkillsAdded')}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>

                <div className='keyEmployeeView__personalSkills-block'>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('driverLicence')}:</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalSkills.driverLicence)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('canYouDriveToWork')}</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalSkills.canDrive)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('planToCintinueToLearn')}</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.personalSkills.continueLearn)}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* Experience */}
            {selectedTab === 3 && (
              <div className='crewEmployeeView__selectedContainer'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t('workplaceNameCity')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('country')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('position')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('from')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('till')}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t('mainResponsibilities')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData.experienceArr.length > 0 ? (
                      userData.experienceArr.length === 1 &&
                      userData.experienceArr[0].workplace === '' &&
                      userData.experienceArr[0].country === '' &&
                      userData.experienceArr[0].position === '' &&
                      userData.experienceArr[0].dateFrom === '' &&
                      userData.experienceArr[0].dateTill === '' ? (
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            style={{ textAlign: 'center' }}
                          >
                            {t('thereIsNoExperienceAdded')}
                          </TableCell>
                        </TableRow>
                      ) : (
                        userData.experienceArr.map((el) => (
                          <TableRow hover key={el.id}>
                            <TableCell>{el.workplace}</TableCell>
                            <TableCell>{el.country}</TableCell>
                            <TableCell>{t(el.position)}</TableCell>
                            <TableCell>{el.dateFrom}</TableCell>
                            <TableCell>
                              {el.stillWorking
                                ? t('stillWorking')
                                : el.dateTill}
                            </TableCell>
                            <TableCell>{el.workOccupation}</TableCell>
                          </TableRow>
                        ))
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                          {t('thereIsNoExperienceAdded')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            )}

            {/* Ddocuments */}
            {selectedTab === 4 && (
              <div className='keyEmployeeView__personalSkills'>
                <div className='keyEmployeeView__personalSkills-block'>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('uploadedCv')}: </span>
                    {uploadedCvUrl !== null && uploadedCvUrl !== '' ? (
                      <span className='global__capitalizeFirstLetter'>
                        <a
                          href={uploadedCvUrl}
                          target='_blank'
                          rel='noopener noreferrer'
                          download
                        >
                          {t('download')}
                        </a>
                      </span>
                    ) : (
                      <span className='global__capitalizeFirstLetter'>
                        {t('noFileAttached')}
                      </span>
                    )}
                  </div>
                </div>

                {/* Certificates and diplomas */}
                <div className='keyEmployeeView__personalSkills-block'>
                  <div
                    className='addNewKeyEmployee__list-title'
                    style={{ marginTop: 80 }}
                  >
                    {t('cerificatesAndDiplomas')}
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          {t('fileName')}
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          {t('issueDate')}
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          {t('expireDate')}
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          {t('file')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.documents.newCertificatesFilesArr.length > 0 ? (
                        userData.documents.newCertificatesFilesArr.length ===
                          1 &&
                        userData.documents.newCertificatesFilesArr[0]
                          .fileName === '' &&
                        userData.documents.newCertificatesFilesArr[0]
                          .issueDate === '' &&
                        userData.documents.newCertificatesFilesArr[0]
                          .expireDate === '' ? (
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              style={{ textAlign: 'center' }}
                            >
                              {t('thereIsNoDocumentsAdded')}
                            </TableCell>
                          </TableRow>
                        ) : (
                          userData.documents.newCertificatesFilesArr.map(
                            (el) => (
                              <TableRow hover key={el.id}>
                                <TableCell>{el.fileName}</TableCell>
                                <TableCell>
                                  {el.issueDate !== '' ? el.issueDate : '-'}
                                </TableCell>
                                <TableCell
                                  className={
                                    el.expireDate !== '' &&
                                    el.expireDate !== undefined
                                      ? el.expireDate <=
                                        convertDate(addWeeksToDate(2))
                                        ? classes.errorColor
                                        : null
                                      : null
                                  }
                                >
                                  {el.expireDate !== '' &&
                                  el.expireDate !== undefined
                                    ? el.expireDate
                                    : t('unlimited')}
                                </TableCell>
                                <TableCell>
                                  {addDocumentLink(el.id) !== '' && (
                                    <a
                                      href={addDocumentLink(el.id)}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      download
                                    >
                                      {t('download')}
                                    </a>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            style={{ textAlign: 'center' }}
                          >
                            {t('thereIsNoDocumentsAdded')}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>

                {/* Referencies */}
                <div className='keyEmployeeView__personalSkills-block'>
                  <div
                    className='addNewKeyEmployee__list-title'
                    style={{ marginTop: 80 }}
                  >
                    {t('referencies')}
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          {t('fileName')}
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          {t('file')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.documents.newReferenciesFilesArr.length > 0 ? (
                        userData.documents.newReferenciesFilesArr.length ===
                          1 &&
                        userData.documents.newReferenciesFilesArr[0]
                          .fileName === '' &&
                        userData.documents.newReferenciesFilesArr[0].file ===
                          '' ? (
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              style={{ textAlign: 'center' }}
                            >
                              {t('thereIsNoDocumentsAdded')}
                            </TableCell>
                          </TableRow>
                        ) : (
                          userData.documents.newReferenciesFilesArr.map(
                            (el) => (
                              <TableRow hover key={el.id}>
                                <TableCell>{el.fileName}</TableCell>
                                <TableCell>
                                  {addReferenceLink(el.id) !== '' && (
                                    <a
                                      href={addReferenceLink(el.id)}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      download
                                    >
                                      {t('download')}
                                    </a>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            style={{ textAlign: 'center' }}
                          >
                            {t('thereIsNoDocumentsAdded')}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}

            {/* Remarks */}
            {selectedTab === 5 && (
              <div className='keyEmployeeView__personalSkills'>
                <div className='keyEmployeeView__personalSkills-block'>
                  <div
                    className='addNewKeyEmployee__list-title'
                    style={{ marginTop: 80 }}
                  >
                    {t('comment')}:
                  </div>
                  <p>{userData.remarks.comment}</p>
                </div>
                <div className='keyEmployeeView__personalSkills-block'>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('howFastYouCanStart')}</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.remarks.whenCanStart)}
                    </span>
                  </div>
                  <div className='crewEmployeeView__basicInfo-el'>
                    <span>{t('howFindOutAboutUs')}</span>{' '}
                    <span className='global__capitalizeFirstLetter'>
                      {t(userData.remarks.howFindOut)}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj,
    showGeneratedPdfBox: state.showGeneratedPdfBoxRed.bool,
    agencyUserData: state.agencyUserDataRed.obj
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowGeneratedPdfBox: (bool) => dispatch(showGeneratedPdfBox(bool))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyEmployeeViewData)
