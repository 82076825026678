import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Snackbar,
  Alert,
  Rating,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './Management.scss'
import moment from 'moment'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import Loader from '../../../../Components/Loader'
import { v4 as uuid } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import CandidateManagement from './CandidateManagement'

const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()

interface ProfilePhotoUrl {
  /** Profile ID */
  id: string
  /** Profile photo url */
  url: string
}

interface Props {
  open: boolean
  onClose: () => void
  job: Job | null
  agencyData: any
  agencyUserData: any
  agencyAgentsList: AgencyUserData[]
}

const Management = ({
  open,
  job,
  onClose,
  agencyData,
  agencyUserData,
  agencyAgentsList
}: Props) => {
  const { t } = useTranslation()

  const [refreshData, setRefreshData] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [agencyAgents, setAgencyAgents] = useState<RecruitmentEvaluation[]>([])

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const [addCandidateDialog, setAddCandidateDialog] = useState<boolean>(false)
  const [filterFirstName, setFilterFirstName] = useState<string>('')
  const [filterLastName, setFilterLastName] = useState<string>('')
  const [filteredCandidates, setFilteredCandidates] = useState<
    KeyEmployeeProfile[]
  >([])

  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>({
    status: false,
    duration: 5000,
    severity: 'success',
    message: ''
  })

  const [candidatesPrifileIdList, setCandidatesPrifileIdList] = useState<
    string[]
  >([])

  const [appliedCandidates, setAppliedCandidates] = useState<
    RecruitmentCandidate[]
  >([])
  const [inteviewCandidates, setInteviewCandidates] = useState<
    RecruitmentCandidate[]
  >([])
  const [assessmentCandidates, setAssessmentCandidates] = useState<
    RecruitmentCandidate[]
  >([])
  const [jobOfferCandidates, setJobOfferCandidates] = useState<
    RecruitmentCandidate[]
  >([])
  const [profilePhotoUrls, setProfilePhotoUrls] = useState<ProfilePhotoUrl[]>(
    []
  )

  const [dragCandidateId, setDragCandidateId] = useState<string>('')
  const [dropId, setDropId] = useState<string>('')
  const [showMoveCandidateWarningDialog, setShowMoveCandidateWarningDialog] =
    useState<boolean>(false)

  const [openCandidateManagementBlock, setOpenCandidateManagementBlock] =
    useState<boolean>(false)
  const [selectedCandidate, setSelectedCandidate] =
    useState<RecruitmentCandidate | null>(null)
  const [
    selectedCandidateProfilePhotoUrl,
    setSelectedCandidateProfilePhotoUrl
  ] = useState<string>('')

  useEffect(() => {
    let shouldUpdate = true

    if (shouldUpdate) {
      setOpenDialog(open)
    }

    if (job !== null) {
      const getProfilePhotos = async (
        docId: string,
        filename: string | null
      ) => {
        let newArray: ProfilePhotoUrl[] = []
        if (filename !== '' && filename !== null) {
          await storageRef
            .child(
              `agencies/${agencyData.id}/users-forms-uploads/${docId}/${filename}`
            )
            .getDownloadURL()
            .then((url) => {
              newArray = [...profilePhotoUrls, { id: docId, url }]

              if (shouldUpdate) {
                setProfilePhotoUrls(newArray)
              }
            })
            .catch((error) => {
              console.error(error)
            })
        } else {
          newArray = [...profilePhotoUrls, { id: docId, url: '' }]

          if (shouldUpdate) {
            setProfilePhotoUrls(newArray)
          }
        }
      }

      /** Get all candidates who belongs to this job */
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('jobs_candidates')
        .where('jobId', '==', job?._id)
        .get()
        .then(async (response) => {
          let appliedArray: RecruitmentCandidate[] = []
          let inteviewArray: RecruitmentCandidate[] = []
          let assessmentArray: RecruitmentCandidate[] = []
          let jobOfferArray: RecruitmentCandidate[] = []
          let profilesIds: string[] = []

          response.forEach((el) => {
            let data: any = JSON.stringify(el.data())
            data = JSON.parse(data)

            const actionStatus: RecruitmentCandidateActionStatus =
              data.actionStatus

            profilesIds = [...profilesIds, data.profileId]

            if (actionStatus === 'applied') {
              appliedArray = [...appliedArray, data]
            }

            if (
              actionStatus === 'inReview' ||
              actionStatus === 'phoneScreen' ||
              actionStatus === 'interview'
            ) {
              inteviewArray = [...inteviewArray, data]
            }

            if (
              actionStatus === 'assessment' ||
              actionStatus === 'backgroudCheck' ||
              actionStatus === 'flagged' ||
              actionStatus === 'disqualified'
            ) {
              assessmentArray = [...assessmentArray, data]
            }

            if (actionStatus === 'offered' || actionStatus === 'hired') {
              jobOfferArray = [...jobOfferArray, data]
            }

            getProfilePhotos(data.profileId, data.profilePhoto)
          })

          if (shouldUpdate) {
            setAppliedCandidates(appliedArray)
            setInteviewCandidates(inteviewArray)
            setAssessmentCandidates(assessmentArray)
            setJobOfferCandidates(jobOfferArray)
            setCandidatesPrifileIdList(profilesIds)
          }
        })
        .catch((error) => console.error(error))

      /** Get all agents */
      db.collection('users')
        .where('data.agencyId', '==', agencyData.id)
        .where('data.type', '==', 'keyagency')
        .get()
        .then(async (response) => {
          let agentsArray: RecruitmentEvaluation[] = []
          const evaluations: RecruitmentEvaluationCriteria[] | undefined =
            agencyData.settings.companySettings?.candidateCriteria

          response.forEach((el) => {
            let data: any = JSON.stringify(el.data())
            data = JSON.parse(data)

            const agent: RecruitmentEvaluation = {
              agentUid: data.data.uid,
              agentName: `${data.data.name} ${data.data.lastName}`,
              evaluation: evaluations !== undefined ? evaluations : [],
              comment: '',
              lastEdit: ''
            }

            agentsArray = [...agentsArray, agent]
          })

          if (shouldUpdate) {
            setAgencyAgents(agentsArray)
          }
        })
        .catch((error) => console.error(error))
    }

    return () => {
      shouldUpdate = false
    }
  }, [open, refreshData, agencyData, job]) // eslint-disable-line react-hooks/exhaustive-deps

  const closeDialog = () => {
    setOpenDialog(false)
    onClose()
    setAppliedCandidates([])
    setInteviewCandidates([])
    setAssessmentCandidates([])
    setJobOfferCandidates([])
    setProfilePhotoUrls([])
  }

  const getContractPeriodName = (
    duration: number | undefined,
    period: ContractPeriodTypes | undefined
  ) => {
    let durationNumber: number = 0
    let periodName: string = ''

    if (period !== undefined && duration !== undefined) {
      durationNumber = duration

      if (period === 'days') {
        periodName = t('day')
        if (duration > 1) {
          periodName = t('days')
        }
      }

      if (period === 'week') {
        periodName = t('week')

        if (duration > 1) {
          periodName = t('weeks')
        }
      }

      if (period === 'month') {
        periodName = t('month')

        if (duration > 1) {
          periodName = t('months')
        }
      }

      periodName = periodName.toLocaleLowerCase()
    }

    return `${durationNumber} ${periodName}`
  }

  const getJobCreateDate = (timestamp: any) => {
    if (job !== null) {
      const newDate: Date = new Date(timestamp.seconds * 1000)

      const convertedDate: string = moment(newDate).format('YYYY-MM-DD')

      return convertedDate
    }
    return ''
  }

  const searchEmployee = async () => {
    setLoading(true)

    const query: any = db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('employees')

    let filterQuery = query

    if (filterFirstName !== '' && filterLastName === '') {
      filterQuery = query
        .where(
          'personalData.firstName',
          '>=',
          filterFirstName.toLocaleLowerCase()
        )
        .where(
          'personalData.firstName',
          '<=',
          filterFirstName.toLocaleLowerCase() + '~'
        )
    } else if (filterFirstName !== '' && filterLastName !== '') {
      filterQuery = query
        .where(
          'personalData.firstName',
          '==',
          filterFirstName.toLocaleLowerCase()
        )
        .where(
          'personalData.lastName',
          '==',
          filterLastName.toLocaleLowerCase()
        )
    } else if (filterFirstName === '' && filterLastName !== '') {
      filterQuery = query
        .where(
          'personalData.lastName',
          '>=',
          filterLastName.toLocaleLowerCase()
        )
        .where(
          'personalData.lastName',
          '<=',
          filterLastName.toLocaleLowerCase() + '~'
        )
    } else if (filterFirstName === '' && filterLastName === '') {
      filterQuery = query
    }

    await filterQuery
      .get()
      .then((response: any) => {
        const array: KeyEmployeeProfile[] = []
        response.forEach((el: any) => {
          let data: any = JSON.stringify(el.data())
          data = JSON.parse(data)

          /** Check is candidate already added to job */
          const index: number = candidatesPrifileIdList.findIndex(
            (el) => el === data.docId
          )

          if (index === -1) {
            array.push(data)
          }
        })
        setFilteredCandidates(array)
        setLoading(false)
      })
      .catch((error: any) => {
        console.error(error)
        setLoading(false)
      })
  }

  const addCandidate = async (candidate: KeyEmployeeProfile) => {
    if (job !== null) {
      const id: string = uuid()

      const candidateData: RecruitmentCandidate = {
        _id: id,
        name: `${candidate.personalData.firstName} ${candidate.personalData.lastName}`,
        profileId: candidate.docId,
        jobId: job._id,
        jobIdInAgency: job.jobId,
        jobPublishFrom: job.publishFrom,
        jobTitle: job.title,
        jobContractDuration: job.contractDuration,
        jobContractPeriod: job.contractPeriod,
        jobSalary: {
          currency: job.salary.currency,
          salaryMin: job.salary.salaryMin,

          salaryMax: job.salary.salaryMax,

          salaryRate: job.salary.salaryRate
        },
        actionStatus: 'applied',
        profilePhoto: candidate.personalData.profilePhoto,
        rating: 0,
        closedEvaluation: false,
        closeEvaluationDate: '',
        stageStatus: '',
        addedBy: {
          name: `${agencyUserData.name} ${agencyUserData.lastName}`,
          uid: agencyUserData.uid
        },
        timestamp: new Date(),
        mainPosition: candidate.personalData.mainPosition,
        secondPosition: candidate.personalData.secondPosition,
        evaluations: agencyAgents
      }

      /** Add candidate to database */
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('jobs_candidates')
        .doc(id)
        .set(candidateData)
        .then(async () => {
          /** Increase candidate count in job listing */
          const newCandidatesAmount: number = job.candidates + 1

          await db
            .collection('agencies')
            .doc(agencyData.id)
            .collection('jobs')
            .doc(job._id)
            .update({
              'data.candidates': newCandidatesAmount
            })
            .then(() => {
              setRefreshData(!refreshData)
              setAddCandidateDialog(false)
              setFilteredCandidates([])
              setFilterFirstName('')
              setFilterLastName('')
              setLoading(false)
              setSnackbarMessage({
                status: true,
                duration: 5000,
                severity: 'success',
                message: t('candidate_added')
              })
            })
            .catch((error) => {
              console.error(error)
              setLoading(false)
              setSnackbarMessage({
                status: true,
                duration: 5000,
                severity: 'error',
                message: t('system_error_try_again_asd54')
              })
            })
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'error',
            message: t('system_error_try_again_asd54')
          })
        })
    }
  }

  const getProfilePhotoUrl = (id: string) => {
    const index: number = profilePhotoUrls.findIndex((el) => el.id === id)
    console.log()

    if (index > -1) {
      return profilePhotoUrls[index].url
    }

    return ''
  }

  const onDragOver = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handleDrop = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    const blockId: string | null = event.target.getAttribute('id')

    if (blockId !== null) {
      setDropId(blockId)
      setShowMoveCandidateWarningDialog(true)
    }
  }

  const closeMoveCandidateDialog = () => {
    setShowMoveCandidateWarningDialog(false)
    setDropId('')
    setDragCandidateId('')
  }

  const moveCandidate = async () => {
    if (dragCandidateId !== '' && dropId !== '') {
      setLoading(true)

      /** Set new action status */
      let newActionStatus: RecruitmentCandidateActionStatus = 'applied'

      if (dropId === 'applied') {
        newActionStatus = 'applied'
      }

      if (dropId === 'interview') {
        newActionStatus = 'interview'
      }

      if (dropId === 'assessment') {
        newActionStatus = 'assessment'
      }
      if (dropId === 'jobOffer') {
        newActionStatus = 'hired'
      }

      /** Update candidate's action status in database*/
      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('jobs_candidates')
        .doc(dragCandidateId)
        .update({
          actionStatus: newActionStatus
        })
        .then(() => {
          setRefreshData(!refreshData)
          closeMoveCandidateDialog()
          setLoading(false)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setSnackbarMessage({
            status: true,
            duration: 5000,
            severity: 'error',
            message: t('system_error_try_again_asd54')
          })
        })
    }
  }

  const closeAddCandidateDialog = () => {
    setAddCandidateDialog(false)
    setFilterFirstName('')
    setFilterLastName('')
    setFilteredCandidates([])
  }

  const openCandidateManagement = (
    candidate: RecruitmentCandidate,
    profilePhotoUrl: string
  ) => {
    setSelectedCandidate(candidate)
    setSelectedCandidateProfilePhotoUrl(profilePhotoUrl)
    setOpenCandidateManagementBlock(true)
  }

  const closeCandidateManagement = () => {
    setOpenCandidateManagementBlock(false)
    setSelectedCandidate(null)
    setSelectedCandidateProfilePhotoUrl('')
  }

  const getApplicationStageName = (id: string) => {
    const stagesList: RecruitmentApplicationStages[] =
      agencyData.settings.companySettings?.applicationStages
    if (stagesList !== undefined) {
      const index: number = stagesList.findIndex((el) => el._id === id)

      let title: string = ''
      let color: string = ''

      if (index > -1) {
        title = stagesList[index].title
        color = stagesList[index].color
      }

      return <span style={{ color }}>{title}</span>
    }
  }

  return (
    <>
      {loading && <Loader />}

      {/* Messeges */}
      <Snackbar
        open={snackbarMessage.status}
        autoHideDuration={snackbarMessage.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() =>
          setSnackbarMessage({ ...snackbarMessage, status: false })
        }
      >
        <Alert
          severity={snackbarMessage.severity}
          onClose={() =>
            setSnackbarMessage({ ...snackbarMessage, status: false })
          }
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>

      <CandidateManagement
        open={openCandidateManagementBlock}
        onClose={() => closeCandidateManagement()}
        candidate={selectedCandidate}
        profilePhotoUrl={selectedCandidateProfilePhotoUrl}
        handleRefreshData={() => setRefreshData(!refreshData)}
        adminsOnPanel={job !== null ? job.adminsOnPanel : []}
        agencyAgents={agencyAgentsList}
      />

      {/* Add candidate dialog */}
      <Dialog open={addCandidateDialog} maxWidth='sm' fullWidth>
        <DialogTitle>{t('add_candidate_to_list')}</DialogTitle>

        <DialogContent>
          {/* Inputs */}
          <Grid container spacing={2} style={{ marginTop: 5 }}>
            {/* First name */}
            <Grid item md={5}>
              <TextField
                size='small'
                fullWidth
                label={t('firstName')}
                value={filterFirstName}
                onChange={(e) => setFilterFirstName(e.target.value)}
              />
            </Grid>

            {/* Last name */}
            <Grid item md={5}>
              <TextField
                size='small'
                fullWidth
                label={t('lastName')}
                value={filterLastName}
                onChange={(e) => setFilterLastName(e.target.value)}
              />
            </Grid>

            {/* Search button */}
            <Grid item md={2}>
              <Button variant='containedRed' onClick={() => searchEmployee()}>
                {t('search')}
              </Button>
            </Grid>
          </Grid>

          {/* Search results */}
          <List>
            {filteredCandidates.map((candidate: KeyEmployeeProfile) => (
              <ListItem key={candidate.docId}>
                <ListItemButton onClick={() => addCandidate(candidate)}>
                  <ListItemText
                    style={{ textTransform: 'capitalize' }}
                    primary={`${candidate.personalData.firstName} ${candidate.personalData.lastName}`}
                    secondary={t(candidate.personalData.mainPosition)}
                  />

                  {/* Add button */}
                  <ListItemIcon>{t('add')}</ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>

        <DialogActions>
          {/* Close button */}
          <Button size='small' onClick={() => closeAddCandidateDialog()}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Candidate move warning */}
      <Dialog
        open={showMoveCandidateWarningDialog}
        onClose={() => closeMoveCandidateDialog()}
      >
        <DialogContent>{t('are_you_sure_to_move_sdf588')}</DialogContent>

        <DialogActions>
          {/* No */}
          <Button onClick={() => closeMoveCandidateDialog()}>{t('no')}</Button>

          {/* Yes */}
          <Button variant='containedRed' onClick={() => moveCandidate()}>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Management main dialog */}
      <Dialog fullScreen open={openDialog}>
        <DialogTitle>{t('manage_1234')}</DialogTitle>

        <DialogContent>
          <div className='management_container'>
            <h2>{job?.title}</h2>

            {/* Ad statuses */}
            <Table
              size='small'
              style={{ margin: '30px auto 50px auto', maxWidth: 700 }}
            >
              <TableBody>
                <TableRow>
                  {/* ID */}
                  <TableCell style={{ textAlign: 'end' }}>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>{job?.jobId}</TableCell>

                  {/* Gap */}
                  <TableCell style={{ borderBottom: 'unset' }}></TableCell>

                  {/* Contract duration */}
                  <TableCell style={{ textAlign: 'end' }}>
                    <b>{t('contract_duration')}</b>
                  </TableCell>
                  <TableCell>
                    {getContractPeriodName(
                      job?.contractDuration,
                      job?.contractPeriod
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  {/* Status */}
                  <TableCell style={{ textAlign: 'end' }}>
                    <b>{t('status')}</b>
                  </TableCell>
                  <TableCell>
                    {job?.options.publicJob
                      ? t('published')
                      : t('not_published')}
                  </TableCell>

                  {/* Gap */}
                  <TableCell style={{ borderBottom: 'unset' }}></TableCell>

                  {/* Candidate quantity */}
                  <TableCell style={{ textAlign: 'end' }}>
                    <b>{t('candidate_quantity')}</b>
                  </TableCell>
                  <TableCell>{job?.candidateQuantity}</TableCell>
                </TableRow>

                <TableRow>
                  {/* Seen */}
                  <TableCell style={{ textAlign: 'end' }}>
                    <b>{t('seen')}</b>
                  </TableCell>
                  <TableCell>{job?.seenAd}</TableCell>

                  {/* Gap */}
                  <TableCell style={{ borderBottom: 'unset' }}></TableCell>

                  {/* Job created */}
                  <TableCell style={{ textAlign: 'end' }}>
                    <b>{t('job_created')}</b>
                  </TableCell>
                  <TableCell>{getJobCreateDate(job?.timestamp)}</TableCell>
                </TableRow>

                <TableRow>
                  {/* Applicators */}
                  <TableCell style={{ textAlign: 'end' }}>
                    <b>{t('applicators')}</b>
                  </TableCell>
                  <TableCell>{job?.candidates}</TableCell>

                  {/* Gap */}
                  <TableCell style={{ borderBottom: 'unset' }}></TableCell>

                  {/* Closed job*/}
                  <TableCell
                    style={{
                      textAlign: 'end',
                      borderBottom:
                        job !== null && job.closedJob.closed ? '' : 'unset'
                    }}
                  >
                    <b>
                      {job !== null && job.closedJob.closed && t('job_closed')}
                    </b>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom:
                        job !== null && job.closedJob.closed ? '' : 'unset'
                    }}
                  >
                    {job !== null &&
                      job.closedJob.closed &&
                      job?.closedJob.date}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {/* Add candidate button */}
            <Button
              variant='containedRed'
              onClick={() => setAddCandidateDialog(true)}
            >
              {t('add_candidate_to_list')}
            </Button>

            <h3 style={{ margin: '40px 0 20px 0' }}>
              {t('application_action')}
            </h3>

            {/* Application action block */}
            <div className='application_action'>
              {/* Applied */}
              <div
                className='block'
                id='applied'
                onDragOver={onDragOver}
                onDrop={handleDrop}
              >
                {/* Header */}
                <div className='headerLine'>{t('applied')}</div>

                {/* Body */}
                <div className='body'>
                  {appliedCandidates.map((candidate) => (
                    <div
                      key={candidate._id}
                      className={
                        candidate.closedEvaluation
                          ? 'candidate__closed'
                          : 'candidate'
                      }
                      draggable
                      onDragStart={() => setDragCandidateId(candidate._id)}
                      onClick={() =>
                        openCandidateManagement(
                          candidate,
                          getProfilePhotoUrl(candidate.profileId)
                        )
                      }
                    >
                      {/* Rating */}
                      <div className='rating'>
                        <Rating
                          readOnly
                          defaultValue={candidate.rating}
                          precision={0.5}
                          size='small'
                        />
                      </div>

                      {/* Header */}
                      <div className='candidate_header'>
                        {/* Profile photo */}
                        <div className='profilePhoto'>
                          {getProfilePhotoUrl(candidate.profileId) !== '' ? (
                            <img
                              src={getProfilePhotoUrl(candidate.profileId)}
                              alt='profile'
                            />
                          ) : (
                            <FontAwesomeIcon
                              className='icon'
                              icon={faUser as IconProp}
                            />
                          )}
                        </div>

                        {/* Name */}
                        <div className='name'>{candidate.name}</div>
                      </div>

                      {/* Status */}
                      <div className='status'>
                        {/* Action status */}
                        <div>{t(candidate.actionStatus)}</div>

                        {/* Stage status */}
                        <div>
                          {getApplicationStageName(candidate.stageStatus)}
                        </div>

                        {candidate.closedEvaluation && (
                          <Tooltip
                            title={candidate.closeEvaluationDate}
                            placement='top'
                          >
                            <div>{t('closed_evaluation')}</div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Interview */}
              <div
                className='block'
                id='interview'
                onDragOver={onDragOver}
                onDrop={handleDrop}
              >
                {/* Header */}
                <div className='headerLine'>{t('interview')}</div>

                {/* Body */}
                <div className='body'>
                  {inteviewCandidates.map((candidate) => (
                    <div
                      key={candidate._id}
                      className={
                        candidate.closedEvaluation
                          ? 'candidate__closed'
                          : 'candidate'
                      }
                      draggable
                      onDragStart={() => setDragCandidateId(candidate._id)}
                      onClick={() =>
                        openCandidateManagement(
                          candidate,
                          getProfilePhotoUrl(candidate.profileId)
                        )
                      }
                    >
                      {/* Rating */}
                      <div className='rating'>
                        <Rating
                          readOnly
                          defaultValue={candidate.rating}
                          precision={0.5}
                          size='small'
                        />
                      </div>

                      {/* Header */}
                      <div className='candidate_header'>
                        {/* Profile photo */}
                        <div className='profilePhoto'>
                          {getProfilePhotoUrl(candidate.profileId) !== '' ? (
                            <img
                              src={getProfilePhotoUrl(candidate.profileId)}
                              alt='profile'
                            />
                          ) : (
                            <FontAwesomeIcon
                              className='icon'
                              icon={faUser as IconProp}
                            />
                          )}
                        </div>

                        {/* Name */}
                        <div className='name'>{candidate.name}</div>
                      </div>

                      {/* Status */}
                      <div className='status'>
                        {/* Action status */}
                        <div>{t(candidate.actionStatus)}</div>

                        {/* Stage status */}
                        <div>
                          {getApplicationStageName(candidate.stageStatus)}
                        </div>

                        {candidate.closedEvaluation && (
                          <Tooltip
                            title={candidate.closeEvaluationDate}
                            placement='top'
                          >
                            <div>{t('closed_evaluation')}</div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Assessment */}
              <div
                className='block'
                id='assessment'
                onDragOver={onDragOver}
                onDrop={handleDrop}
              >
                {/* Header */}
                <div className='headerLine'>{t('assessment')}</div>

                {/* Body */}
                <div className='body'>
                  {assessmentCandidates.map((candidate) => (
                    <div
                      key={candidate._id}
                      className={
                        candidate.closedEvaluation
                          ? 'candidate__closed'
                          : 'candidate'
                      }
                      draggable
                      onDragStart={() => setDragCandidateId(candidate._id)}
                      onClick={() =>
                        openCandidateManagement(
                          candidate,
                          getProfilePhotoUrl(candidate.profileId)
                        )
                      }
                    >
                      {/* Rating */}
                      <div className='rating'>
                        <Rating
                          readOnly
                          defaultValue={candidate.rating}
                          precision={0.5}
                          size='small'
                        />
                      </div>

                      {/* Header */}
                      <div className='candidate_header'>
                        {/* Profile photo */}
                        <div className='profilePhoto'>
                          {getProfilePhotoUrl(candidate.profileId) !== '' ? (
                            <img
                              src={getProfilePhotoUrl(candidate.profileId)}
                              alt='profile'
                            />
                          ) : (
                            <FontAwesomeIcon
                              className='icon'
                              icon={faUser as IconProp}
                            />
                          )}
                        </div>

                        {/* Name */}
                        <div className='name'>{candidate.name}</div>
                      </div>

                      {/* Status */}
                      <div className='status'>
                        {/* Action status */}
                        <div>{t(candidate.actionStatus)}</div>

                        {/* Stage status */}
                        <div>
                          {getApplicationStageName(candidate.stageStatus)}
                        </div>

                        {candidate.closedEvaluation && (
                          <Tooltip
                            title={candidate.closeEvaluationDate}
                            placement='top'
                          >
                            <div>{t('closed_evaluation')}</div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Job offer */}
              <div
                className='block'
                id='jobOffer'
                onDragOver={onDragOver}
                onDrop={handleDrop}
              >
                {/* Header */}
                <div className='headerLine'>{t('job_offer')}</div>

                {/* Body */}
                <div className='body'>
                  {jobOfferCandidates.map((candidate) => (
                    <div
                      key={candidate._id}
                      className={
                        candidate.closedEvaluation
                          ? 'candidate__closed'
                          : 'candidate'
                      }
                      draggable
                      onDragStart={() => setDragCandidateId(candidate._id)}
                      onClick={() =>
                        openCandidateManagement(
                          candidate,
                          getProfilePhotoUrl(candidate.profileId)
                        )
                      }
                    >
                      {/* Rating */}
                      <div className='rating'>
                        <Rating
                          readOnly
                          defaultValue={candidate.rating}
                          precision={0.5}
                          size='small'
                        />
                      </div>

                      {/* Header */}
                      <div className='candidate_header'>
                        {/* Profile photo */}
                        <div className='profilePhoto'>
                          {getProfilePhotoUrl(candidate.profileId) !== '' ? (
                            <img
                              src={getProfilePhotoUrl(candidate.profileId)}
                              alt='profile'
                            />
                          ) : (
                            <FontAwesomeIcon
                              className='icon'
                              icon={faUser as IconProp}
                            />
                          )}
                        </div>

                        {/* Name */}
                        <div className='name'>{candidate.name}</div>
                      </div>

                      {/* Status */}
                      <div className='status'>
                        {/* Action status */}
                        <div>{t(candidate.actionStatus)}</div>

                        {/* Stage status */}
                        <div>
                          {getApplicationStageName(candidate.stageStatus)}
                        </div>

                        {candidate.closedEvaluation && (
                          <Tooltip
                            title={candidate.closeEvaluationDate}
                            placement='top'
                          >
                            <div>{t('closed_evaluation')}</div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          {/* Close button */}
          <Button size='small' onClick={() => closeDialog()}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    agencyData: state.agencyDataRed.obj,
    agencyUserData: state.agencyUserDataRed.obj
  }
}

export default connect(mapStateToProps)(Management)
