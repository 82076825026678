import { Checkbox, Alert, Snackbar, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Button from '../Components/Button'
import Loader from '../Components/Loader'
import Header from '../Modules/Header'
import { systemColors, systemStylingSettings } from '../globalVariables'
import firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'
import logo from '../media/logo/seanor_logo.svg'

const db = firebase.firestore()
const storage = firebase.storage()

const useStyles = makeStyles({
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  checkbox: {
    color: systemColors.electric
  }
})

const Registration = ({ agencyData }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  // Change document title
  document.title = `${t('registration')} - Seanor`

  const [loadedData, setLoadedData] = useState(false)

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [email, setEmail] = useState('')
  const [mainAgeement, setMainAgeement] = useState(false)
  const [gprsAgreement, setGprsAgreement] = useState(false)
  // Errors states
  const [emptyPassword, setEmptyPassword] = useState(false)
  const [emptyEmail, setEmptyEmail] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(false)
  const [mustAccept, setMustAccept] = useState(false)
  const [takenEmail, setTakenEmail] = useState({
    status: false,
    message: t('emailTaken')
  })
  const [badFormatEmail, setBadFormatEmail] = useState({
    status: false,
    message: t('badEmailFormat')
  })
  const [companyLogoUrl, setcompanyLogoUrl] = useState('')

  useEffect(() => {
    let shouldUpdate = true
    if (shouldUpdate) {
      setLoadedData(false)
    }

    const getData = () => {
      storage
        .ref()
        .child(
          `/public/agencies/${agencyData.id}/logo/${agencyData.logoFileName}`
        )
        .getDownloadURL()
        .then((url) => {
          if (shouldUpdate) {
            setcompanyLogoUrl(url)
            setLoadedData(true)
          }
        })
        .catch((error) => {
          console.log("Can't get logo url", error)
          if (shouldUpdate) {
            setLoadedData(true)
          }
        })
    }
    getData()
    return () => {
      shouldUpdate = false
    }
  }, [agencyData])

  const submit = () => {
    setLoading(true)
    if (
      email !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      mainAgeement === true &&
      password === confirmPassword &&
      gprsAgreement === true
    ) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          let data = JSON.stringify(user.user)
          data = JSON.parse(data)
          db.collection('users')
            .doc(data.uid)
            .set({
              data: {
                agencyId: agencyData.id,
                canEditCV: false,
                email: data.email,
                name: '',
                lastName: '',
                type: 'employee',
                uid: data.uid,
                phoneNumber: '',
                userStatus: 'active',
                searchTags: {
                  firstName: '',
                  lastName: ''
                }
              }
            })
            .then(() => {
              firebase
                .auth()
                .currentUser.sendEmailVerification()
                .then(() => {
                  history.push(`/${agencyData.id}/dashboard/${data.uid}`)
                })
                .catch((err) => {
                  console.log("Can't send email verification", err)
                })
            })
            .catch((err) => console.log('Add user data error', err))
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          if (err.code === 'auth/email-already-in-use') {
            setTakenEmail({
              ...takenEmail,
              status: true
            })
          } else if (err.code === 'auth/invalid-email') {
            setBadFormatEmail({ ...badFormatEmail, status: true })
          }
          setLoading(false)
        })

      // Reset states
      setEmptyPassword(false)
      setPasswordMatch(false)
      setMustAccept(false)
      setEmptyEmail(false)
    } else {
      if (password === '' || confirmPassword === '') {
        setEmptyPassword(true)
      } else {
        setEmptyPassword(false)
      }

      if (password !== passwordMatch) {
        setPasswordMatch(true)
      } else {
        setPasswordMatch(false)
      }

      if (mainAgeement === false) {
        setMustAccept(true)
      } else {
        setMustAccept(false)
      }

      if (email === '') {
        setEmptyEmail(true)
      } else {
        setEmptyEmail(false)
      }

      setLoading(false)
    }
  }

  if (!loadedData) return <Loader />

  return (
    <>
      {loading && <Loader />}
      <Header />
      <Snackbar
        open={takenEmail.status}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setTakenEmail({ ...takenEmail, status: false })}
      >
        <Alert
          severity='warning'
          onClose={() => setTakenEmail({ ...takenEmail, status: false })}
        >
          {takenEmail.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={badFormatEmail.status}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setBadFormatEmail({ ...badFormatEmail, status: false })}
      >
        <Alert
          severity='warning'
          onClose={() =>
            setBadFormatEmail({ ...badFormatEmail, status: false })
          }
        >
          {badFormatEmail.message}
        </Alert>
      </Snackbar>

      <div className='registration'>
        <div className='registration__box'>
          {/* Form */}
          <div className='registration__form'>
            <div className='registration__logo'>
              <img
                src={companyLogoUrl !== '' ? companyLogoUrl : logo}
                alt='logo'
              />
            </div>
            <div className='registration__inputs'>
              {agencyData.type === 'crew' ? (
                <h2>{t('marineCrewRegistration')}</h2>
              ) : agencyData.type === 'key' ? (
                <h2>{t('landWorkersRegistration')}</h2>
              ) : (
                <h2>{t('registration')}</h2>
              )}
            </div>
            <div className='registration__inputs'>
              <TextField
                required
                type='email'
                label={t('email')}
                variant='outlined'
                size='small'
                value={email}
                classes={{ root: classes.labelRoot }}
                onChange={(e) => setEmail(e.target.value)}
                helperText={emptyEmail && t('mustFillEmail')}
                error={emptyEmail}
              />
            </div>
            <div className='registration__inputs'>
              <TextField
                required
                type='password'
                label={t('password')}
                variant='outlined'
                size='small'
                value={password}
                classes={{ root: classes.labelRoot }}
                onChange={(e) => setPassword(e.target.value)}
                helperText={
                  emptyPassword
                    ? t('emptyPassword')
                    : passwordMatch
                    ? t('passwordsNotMatch')
                    : null
                }
                error={emptyPassword ? true : !!passwordMatch}
              />
            </div>
            <div className='registration__inputs'>
              <TextField
                required
                type='password'
                label={t('confirmPassword')}
                variant='outlined'
                size='small'
                value={confirmPassword}
                classes={{ root: classes.labelRoot }}
                onChange={(e) => setConfirmPassword(e.target.value)}
                helperText={
                  emptyPassword
                    ? t('emptyPassword')
                    : passwordMatch
                    ? t('passwordsNotMatch')
                    : null
                }
                error={emptyPassword ? true : !!passwordMatch}
              />
            </div>
            <div className='registration__inputs'>
              <div>
                <Checkbox
                  required
                  color='default'
                  classes={{ root: classes.checkbox }}
                  onChange={() => setMainAgeement(!mainAgeement)}
                />
                <span className={mustAccept ? 'error' : null}>
                  {t('iAcceptTerms')}{' '}
                  <a href={`/${agencyData.id}/general-terms-condition`}>
                    {t('privacyPolicy')}
                  </a>
                </span>
              </div>
              <div>
                <Checkbox
                  required
                  color='default'
                  classes={{ root: classes.checkbox }}
                  onChange={() => setGprsAgreement(!gprsAgreement)}
                />
                <span className={mustAccept ? 'error' : null}>
                  {t('iAcceptTerms')}&nbsp;
                  <a href={`/${agencyData.id}/seanor-term`}>
                    {t('gprsPolicy')}
                  </a>
                </span>
              </div>
            </div>
            <div className='login__submit'>
              <Button text='register' handleClick={() => submit()} />
            </div>
          </div>
          {/* Text */}
          <div className='registration_text'>
            <h2>{t('reasonToRegisterOnlineDatabase')}</h2>
            <p>
              {t('zcsdf48tyjh4984yt55546')} {agencyData.name},{' '}
              {t('jhvhgiujiodhbj21684')}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj
  }
}

export default connect(mapStateToProps)(Registration)
