import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Snackbar,
  Alert
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import AgencyMenu from '../../../../Components/AgencyMenu'
import Headline from '../../../../Components/Headline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { acceptedFileSize, systemColors } from '../../../../globalVariables'
import firebase from 'firebase/app'
import Loader from '../../../../Components/Loader'

const db = firebase.firestore()
const storage = firebase.storage()

const useStyles = makeStyles({
  cardTitle: {
    fontSize: 16
  }
})

const CompanySettingsCrew = ({ agencyData }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  // Change document title
  document.title = `${t('myCompany')} - Seanor`

  const [refreshData, setRefreshData] = useState(false)
  const [loadedData, setLoadedData] = useState(false)
  const [loading, setLoading] = useState(false)

  const [companyLogoURL, setCompanyLogoURL] = useState('')
  const [profilePhotoSizeErr, setProfilePhotoSizeErr] = useState(false)
  const [message, setMessage] = useState({
    status: false,
    duration: 3000,
    severity: 'success',
    message: ''
  })

  useEffect(() => {
    setLoadedData(false)

    const getAllData = () => {
      const getLogoUrl = new Promise((resolve) => {
        if (
          agencyData.logoFileName !== undefined &&
          agencyData.logoFileName !== ''
        ) {
          storage
            .ref()
            .child(
              `/public/agencies/${agencyData.id}/logo/${agencyData.logoFileName}`
            )
            .getDownloadURL()
            .then((url) => {
              setCompanyLogoURL(url)
              resolve()
            })
            .catch((error) => {
              console.log("Can't get logo url", error)
              resolve()
            })
        } else {
          resolve()
        }
      })

      Promise.all([getLogoUrl]).then(() => {
        setLoadedData(true)
      })
    }

    return getAllData()
  }, [refreshData, agencyData, companyLogoURL])

  const uploadCompanyLogo = async (file) => {
    setLoading(true)
    await storage
      .ref(`/public/agencies/${agencyData.id}/logo/${file[0].name}`)
      .put(file[0])
      .then(() => {
        db.collection('agencies')
          .doc(agencyData.id)
          .update({
            'data.logoFileName': file[0].name
          })
          .then(() => {
            setProfilePhotoSizeErr(false)
            setRefreshData(!refreshData)
            setLoading(false)
            setMessage({
              status: true,
              duration: 3000,
              severity: 'success',
              message: t('logoSuccessfullySaved')
            })
          })
          .catch((error) => {
            console.log("Can't add logo name to database", error)
            setLoading(false)
            setMessage({
              status: true,
              duration: 3000,
              severity: 'warning',
              message: t('cantSaveLogo')
            })
          })
      })
      .catch((error) => {
        console.log("Can't add logo to storage", error)
        setLoading(false)
        setMessage({
          status: true,
          duration: 3000,
          severity: 'warning',
          message: t('cantSaveLogo')
        })
      })
  }

  const deleteLogo = async () => {
    setLoading(true)
    await storage
      .ref()
      .child(
        `/public/agencies/${agencyData.id}/logo/${agencyData.logoFileName}`
      )
      .delete()
      .then(() => {
        db.collection('agencies')
          .doc(agencyData.id)
          .update({
            'data.logoFileName': ''
          })
          .then(() => {
            setCompanyLogoURL('')
            setRefreshData(!refreshData)
            setLoading(false)
            setMessage({
              status: true,
              duration: 3000,
              severity: 'success',
              message: t('logoSuccessfullyDeleted')
            })
          })
          .catch((error) => {
            console.log("Can't update database", error)
            setLoading(false)
            setMessage({
              status: true,
              duration: 3000,
              severity: 'warning',
              message: t('cantDeletelogo')
            })
          })
      })
      .catch((error) => {
        console.log("Can't delete logo from storage", error)
        setLoading(false)
        setMessage({
          status: true,
          duration: 3000,
          severity: 'warning',
          message: t('cantDeletelogo')
        })
      })
  }

  if (!loadedData) return <Loader />

  return (
    <>
      {loading && <Loader />}

      <AgencyMenu type={agencyData.type} />

      <Headline text={t('myCompany')} />

      {/* Messages */}
      <Snackbar
        open={message.status}
        autoHideDuration={message.duration}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setMessage({ ...message, status: false })}
      >
        <Alert
          severity={message.severity}
          onClose={() => setMessage({ ...message, status: false })}
        >
          {message.message}
        </Alert>
      </Snackbar>

      {/* Content */}
      <Grid container spacing={2} style={{ marginTop: 50 }}>
        {/* Company logo upload  */}
        <Grid item xs={12} sm={12} md={4} xl={3}>
          <Card>
            <CardHeader
              classes={{ title: classes.cardTitle }}
              title={t('uploadCompanyLogo')}
              avatar={<FontAwesomeIcon icon={faImage} />}
            />
            <CardContent>
              <div>
                {companyLogoURL === '' ? (
                  <TextField
                    size='small'
                    variant='outlined'
                    type='file'
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        if (e.target.files[0].size > acceptedFileSize) {
                          setProfilePhotoSizeErr(true)
                        } else {
                          uploadCompanyLogo(e.target.files)
                        }
                      }
                    }}
                    fullWidth
                    inputProps={{
                      accept: '.png, .jpg, .jpeg'
                    }}
                    helperText={
                      profilePhotoSizeErr ? t('toBigFile') : t('acceptFormat')
                    }
                    error={profilePhotoSizeErr}
                  />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      style={{ width: 150 }}
                      src={companyLogoURL}
                      alt='company logo'
                    />
                    <div
                      style={{
                        cursor: 'pointer',
                        color: systemColors.electric,
                        marginTop: 20,
                        fontSize: 14
                      }}
                      onClick={() => deleteLogo()}
                    >
                      <span className='global__onHover'>{t('deleteLogo')}</span>
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => ({
  agencyData: state.agencyDataRed.obj
})

export default connect(mapStateToProps)(CompanySettingsCrew)
