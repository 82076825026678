import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next/'
import AgencyMenu from '../../Components/AgencyMenu'
import Headline from '../../Components/Headline'
import Loader from '../../Components/Loader'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  MenuItem,
  Button,
  Autocomplete
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  systemColors,
  systemStylingSettings,
  companyModes
} from '../../globalVariables'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const db = firebase.firestore()

const useStyles = makeStyles({
  tableHeader: {
    color: systemColors.darkBlue,
    fontSize: 16
  },
  tableRow: {
    cursor: 'pointer'
  },
  modeColorNegotiation: {
    color: '#f39237 !important'
  },
  modeColorTarget: {
    color: '#ef3054 !important'
  },
  modeColorLost: {
    color: '#f72525 !important'
  },
  modeColorCustomer: {
    color: '#479dba !important'
  },
  expandIcon: {
    fontSize: 15
  },
  accordionRoot: {
    boxShadow: 'none',
    border: `1px solid ${systemColors.darkBlue}`,
    borderRadius: systemStylingSettings.borderRadius,
    backgroundColor: systemColors.background,
    marginTop: 50
  },
  labelRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: systemStylingSettings.borderRadius
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue,
      borderWidth: systemStylingSettings.borderWidth
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: systemColors.darkBlue
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  selectStyle: {
    '& .MuiOutlinedInput-inputMarginDense': {
      backgroundColor: systemColors.inputColor,
      padding: 8
    },
    borderRadius: systemStylingSettings.borderRadius,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      margin: '-2px'
    }
  },
  buttonContained: {
    backgroundColor: systemColors.orange,
    borderRadius: systemStylingSettings.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: systemColors.orangeDark,
      boxShadow: 'none'
    }
  },
  buttonText: {
    color: '#ffffff'
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: systemColors.inputColor,
      width: '100%',
      borderRadius: systemStylingSettings.borderRadius,
      '&.Mui-focused fieldset': {
        color: systemColors.darkBlue,
        borderColor: systemColors.darkBlue,
        borderWidth: systemStylingSettings.borderWidth
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 8
      }
  }
})

const AllCompanies = ({ agencyData }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  // Change document title
  document.title = `${t('allCompanies')} - Seanor`

  const [loadedData, setLoadedData] = useState(true)
  const [loading, setLoading] = useState(false)
  const [refreshData, setRefreshData] = useState(true)

  const [companiesList, setCompaniesList] = useState([])
  const [companiesListForSearch, setCompaniesListForSearch] = useState([])

  // Filter states
  const [selectedCompanyMode, setSelectedCompanyMode] = useState('')
  const [filterCompanyName, setFilterCompanyName] = useState(null)

  useEffect(() => {
    let shouldUpdate = true
    if (shouldUpdate) {
      setLoadedData(true)
    }

    const getCompaniesList = () => {
      db.collection('agencies')
        .doc(agencyData.id)
        .collection('companies')
        .orderBy('createdTime', 'asc')
        .get()
        .then((res) => {
          let array = []
          const customerModeList = []
          const negotiationModeList = []
          const targetModeList = []
          res.forEach((el) => {
            let data = JSON.stringify(el.data())
            data = JSON.parse(data)

            if (data.companyMode === 'customer') {
              customerModeList.push(data)
            } else if (data.companyMode === 'negotiation') {
              negotiationModeList.push(data)
            } else if (data.companyMode === 'target') {
              targetModeList.push(data)
            }
          })
          array = [
            ...customerModeList,
            ...negotiationModeList,
            ...targetModeList
          ]
          if (shouldUpdate) {
            setCompaniesList(array)
            setCompaniesListForSearch(array)
            setLoadedData(false)
          }
        })
        .catch((err) => {
          if (shouldUpdate) {
            setLoadedData(false)
          }
          console.log("Can't get companies list", err)
        })
    }
    getCompaniesList()

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, refreshData])

  const modeColor = (mode) => {
    if (mode === 'negotiation') {
      return { root: classes.modeColorNegotiation }
    } else if (mode === 'target') {
      return { root: classes.modeColorTarget }
    } else if (mode === 'lost') {
      return { root: classes.modeColorLost }
    } else if (mode === 'customer') {
      return { root: classes.modeColorCustomer }
    }
  }

  const search = async () => {
    setLoading(true)

    let query = db
      .collection('agencies')
      .doc(agencyData.id)
      .collection('companies')

    if (filterCompanyName !== '' && filterCompanyName !== null) {
      query = query.where('companyName', '==', filterCompanyName.companyName)
    }

    if (selectedCompanyMode !== '') {
      query = query.where('companyMode', '==', selectedCompanyMode)
    }
    if (
      (filterCompanyName !== '' && filterCompanyName !== null) ||
      selectedCompanyMode !== ''
    ) {
      await query
        .get()
        .then((res) => {
          let status = false
          let array = []
          const customerModeArray = []
          const negotiationModeArray = []
          const targetModeArray = []
          const lostModeArray = []
          res.forEach((el) => {
            status = true
            let data = JSON.stringify(el.data())
            data = JSON.parse(data)
            if (data.companyMode === 'customer') {
              customerModeArray.push(data)
            } else if (data.companyMode === 'negotiation') {
              negotiationModeArray.push(data)
            } else if (data.companyMode === 'target') {
              targetModeArray.push(data)
            } else if (data.companyMode === 'lost') {
              lostModeArray.push(data)
            }
            array = [
              ...customerModeArray,
              ...negotiationModeArray,
              ...targetModeArray,
              ...lostModeArray
            ]
            setCompaniesList(array)
          })
          if (!status) {
            setCompaniesList([])
          }
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log("Can't find any result", err)
        })
    } else {
      setLoading(false)
      setCompaniesList([])
    }
  }

  const resetFilter = () => {
    setSelectedCompanyMode('')
    setFilterCompanyName(null)
    setRefreshData(!refreshData)
  }

  if (loadedData) {
    return <Loader />
  }

  return (
    <>
      {loading && <Loader />}

      <Headline text={t('allCompanies')} />

      <AgencyMenu type='key' />

      {/* Filtering */}
      <Accordion classes={{ root: classes.accordionRoot }}>
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
          classes={{ expandIcon: classes.expandIcon }}
        >
          {t('filter')}
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={3}>
            {/* Company name */}
            <Grid item sm={12} md={2}>
              <Autocomplete
                options={companiesListForSearch}
                value={filterCompanyName}
                getOptionLabel={(option) => option.companyName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size='small'
                    label={t('companyName')}
                    variant='outlined'
                  />
                )}
                onChange={(e, val) => {
                  val && setFilterCompanyName(val)
                }}
              />
            </Grid>

            {/* Mode */}
            <Grid item sm={12} md={2}>
              <TextField
                select
                variant='outlined'
                fullWidth
                label={t('mode')}
                size='small'
                value={selectedCompanyMode}
                onChange={(e) => setSelectedCompanyMode(e.target.value)}
              >
                {companyModes.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {t(el)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item sm={12} md={2}>
              <div>
                <Button
                  className='editEmployee__buttons-button'
                  variant='contained'
                  size='small'
                  classes={{
                    contained: classes.buttonContained,
                    sizeSmall: classes.buttonText
                  }}
                  onClick={() => search()}
                >
                  {t('search')}
                </Button>
                <Button
                  className='editEmployee__buttons-button'
                  onClick={() => resetFilter()}
                >
                  {t('reset')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <div style={{ overflowX: 'scroll' }}>
        <Table style={{ marginTop: 50 }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader} />
              <TableCell className={classes.tableHeader}>{t('mode')}</TableCell>
              <TableCell className={classes.tableHeader}>
                {t('companyName')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('address')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('country')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('contactPerson')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('phone')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('email')}
              </TableCell>
              <TableCell className={classes.tableHeader}>
                {t('internetSite')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companiesList.map((el, i) => (
              <TableRow
                key={el.companyId}
                classes={{ hover: classes.tableRow }}
                hover
                onClick={() =>
                  history.push(
                    `/${agencyData.id}/companies/company/${el.companyId}`,
                    { data: el.companyName }
                  )
                }
              >
                <TableCell>{i + 1}</TableCell>
                <TableCell classes={modeColor(el.companyMode)}>
                  {t(el.companyMode)}
                </TableCell>
                <TableCell>{el.companyName}</TableCell>
                <TableCell>{el.companyAddress}</TableCell>
                <TableCell>{el.companyCountry}</TableCell>
                <TableCell>
                  {el.contactPerson.firstName} {el.contactPerson.lastName}
                </TableCell>
                <TableCell>{el.contactPerson.companyPhone}</TableCell>
                <TableCell>{el.contactPerson.companyEmail}</TableCell>
                <TableCell>{el.internetSite}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {companiesList.length < 1 && (
          <div className='global__emptyList'>{t('thereIsNoCompanies')}</div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    agencyData: state.agencyDataRed.obj
  }
}

export default connect(mapStateToProps)(AllCompanies)
