import {
  faChevronLeft,
  faChevronRight,
  faRedo
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import './navigation.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

interface Props {
  /** Handle Next button */
  nextHandler: () => void
  /** Handle Back button */
  backHandler: () => void
  /** Return selected period */
  period: (value: CalendarPeriod) => void
  /** Return selected year and month (YYYY-MM) */
  dateHandler: (value: string) => void
  /** To update selected month and year when going to nex to previous date */
  currentDate: string
  /** Handle reset button */
  resetHandler: () => void
}

const Navigation = ({
  nextHandler,
  backHandler,
  period,
  dateHandler,
  currentDate,
  resetHandler
}: Props) => {
  const { t } = useTranslation()
  const today: string = moment().format('YYYY-MM')

  const [selectedPeriod, setSelectedPeriod] = useState<CalendarPeriod>('week')
  const [showPeriodMenu, setShowPeriodMenu] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date>(new Date(currentDate))
  const [selectedDate, setSelectedDate] = useState<string>(today)

  const handleMenuClick = (value: CalendarPeriod) => {
    setSelectedPeriod(value)
    setShowPeriodMenu(false)
    period(value)
  }

  const handleDatePick = (date: Date) => {
    setStartDate(date)
    const formatedDate: string = moment(date).format('YYYY-MM')
    setSelectedDate(formatedDate)
    dateHandler(formatedDate)
  }

  useEffect(() => {
    let shouldUpdate = true

    if (shouldUpdate) {
      setSelectedDate(moment(currentDate).format('YYYY-MM'))
    }

    if (typeof window !== 'undefined' && shouldUpdate) {
      // Detect if is clicked outside menu block and then close block
      window.document.addEventListener('mousedown', (event) => {
        setShowPeriodMenu(false)
      })
    }

    return () => {
      shouldUpdate = false
    }
  }, [currentDate])

  const handleBack = () => {
    backHandler()
  }

  const handleNext = () => {
    nextHandler()
  }

  const reset = () => {
    setSelectedPeriod('week')
    resetHandler()
  }

  return (
    <div className='navigation'>
      {/* Arrows */}
      <div className='arrows'>
        {/* Left arrow */}
        <div onClick={() => handleBack()}>
          <FontAwesomeIcon icon={faChevronLeft as IconProp} />
        </div>

        {/* Right arrow */}
        <div onClick={() => handleNext()}>
          <FontAwesomeIcon icon={faChevronRight as IconProp} />
        </div>
      </div>

      {/* Period selection */}
      <div className='period'>
        {/* Menu block */}
        <div className={showPeriodMenu ? 'menu' : 'menu__hide'}>
          <ul>
            <li onClick={() => handleMenuClick('week')}>{t('week')}</li>
            <li onClick={() => handleMenuClick('month')}>{t('month')}</li>
            <li onClick={() => handleMenuClick('3months')}>{t('3months')}</li>
          </ul>
        </div>

        {/* Button */}
        <div
          className='menuButton'
          onClick={() => setShowPeriodMenu(!showPeriodMenu)}
        >
          {t(selectedPeriod)}
        </div>
      </div>

      {/* Date picker */}
      <div className='datePicker'>
        <DatePicker
          selected={startDate}
          onChange={handleDatePick}
          dateFormat='yyyy/MM'
          showMonthYearPicker
          customInput={<div className='date'>{selectedDate}</div>}
        />
      </div>

      {/* Reset */}
      <div className='reset' onClick={() => reset()}>
        <FontAwesomeIcon icon={faRedo as IconProp} />
      </div>
    </div>
  )
}

export default Navigation
