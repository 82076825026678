const initialState = {
    bool: false
}

// Passport
export const passNumberErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'PASS_NUMBER_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const passPlaceIssueErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'PASS_PLACE_ISSUE_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const passIssuedErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'PASS_ISSUED_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const passExpiresErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'PASS_EXPIRES_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const passUploadErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'PASS_UPLOAD_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

// Seaman book
export const seamanNumberErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'SEAMAN_NUMBER_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const seamanPlaceIssueErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'SEAMAN_PLACE_ISSUE_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const seamanIssuedErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'SEAMAN_ISSUED_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const seamanExpiresErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'SEAMAN_EXPIRES_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const seamanUploadErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'SEAMAN_UPLOAD_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

// STCW
export const stcwNumberErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'STCW_NUMBER_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const stcwPlaceIssueErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'STCW_PLACE_ISSUE_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const stcwIssuedErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'STCW_ISSUED_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const stcwExpiresErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'STCW_EXPIRES_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const stcwUploadErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'STCW_UPLOAD_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

// Medical certificate
export const medCertNumberErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'MEDCERT_NUMBER_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const medCertPlaceIssueErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'MEDCERT_PLACE_ISSUE_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const medCertIssuedErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'MEDCERT_ISSUED_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const medCertExpiresErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'MEDCERT_EXPIRES_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export const medCertUploadErrRed = (state = initialState, action) => {
    switch(action.type){
        case 'MEDCERT_UPLOAD_ERR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}