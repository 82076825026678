import { createTheme } from '@mui/material/styles'
import { systemColors, systemStylingSettings } from '../globalVariables'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    containedRed: true
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string
    }
  }
  /** allow configuration using `createTheme` */
  interface ThemeOptions {
    status?: {
      electric?: string
      orange?: string
    }
  }
  interface PaletteColor {
    electric?: string
    orange?: string
  }
  interface SimplePaletteColorOptions {
    electric?: string
    orange?: string
  }
  interface PaletteOptions {
    electric: PaletteOptions['primary']
    orange: PaletteOptions['primary']
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    electric: true
    orange: true
  }
}
declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    electric: true
    orange: true
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    electric: true
    orange: true
  }
}
declare module '@mui/material/Tabs' {
  interface TabPropsColorOverrides {
    electric: true
    orange: true
  }
}
declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    electric: true
    orange: true
  }
}
declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    electric: true
    orange: true
  }
}

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'containedRed' },
          style: {
            backgroundColor: systemColors.orange,
            borderRadius: systemStylingSettings.borderRadius,
            color: '#ffffff !important',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: systemColors.orangeDark,
              boxShadow: 'none'
            }
          }
        }
      ],
      styleOverrides: {
        contained: {
          borderRadius: systemStylingSettings.borderRadius,
          color: '#ffffff !important'
        },
        outlined: {
          borderRadius: systemStylingSettings.borderRadius
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: systemColors.orange
          }
        }
      }
    }
  },
  status: {
    electric: systemColors.electric,
    orange: systemColors.orange
  },
  palette: {
    electric: {
      main: systemColors.electric
    },
    orange: { main: systemColors.orange }
  }
})

export default theme
