import { connect } from 'react-redux'
import firebase from 'firebase/app'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete
} from '@mui/material'
import ComponentLoader from '../../../../Components/ComponentLoader'
import AddCandidateToProject from './AddCandidateToProject'

const db = firebase.firestore()

interface Props {
  open: boolean
  onClose: () => void
  agencyData: any
  candidate: RecruitmentCandidate | null
}

interface Company {
  companyName: string
  id: string
}

interface Project {
  projectName: string
  id: string
}

const Hire = ({ open, onClose, agencyData, candidate }: Props) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(true)

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const [companiesList, setCompaniesList] = useState<Company[]>([])
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('')
  const [projectsList, setProjectsList] = useState<Project[]>([])
  const [disableProjectsSelect, setDisableProjectsSelect] =
    useState<boolean>(true)
  const [selectedProject, setSelectedProject] = useState<Project | null>(null)
  const [selectedProjectName, setSelectedProjectName] = useState<string>('')

  const [openAddToProjectBlock, setOpenAddToProjectBlock] =
    useState<boolean>(false)

  useEffect(() => {
    let shouldUpdate = true

    if (shouldUpdate) {
      setOpenDialog(open)
    }

    /** Get all companies */
    db.collection('agencies')
      .doc(agencyData.id)
      .collection('companies')
      .get()
      .then(async (response) => {
        let array: Company[] = []

        response.forEach((el) => {
          let data: any = JSON.stringify(el.data())
          data = JSON.parse(data)

          array = [
            ...array,
            {
              companyName: data.companyName as string,
              id: data.companyId as string
            }
          ]
        })

        if (shouldUpdate) {
          setCompaniesList(array)
          setLoading(false)
        }
      })
      .catch((error) => console.error(error))

    return () => {
      shouldUpdate = false
    }
  }, [open, agencyData])

  const closeDialog = () => {
    setOpenDialog(false)
    onClose()
    setCompaniesList([])
    setSelectedCompany(null)
    setProjectsList([])
    setDisableProjectsSelect(true)
    setSelectedProject(null)
  }

  const getProjects = async (company: Company | null) => {
    if (company !== null) {
      setSelectedCompany(company)
      setLoading(true)

      await db
        .collection('agencies')
        .doc(agencyData.id)
        .collection('companies')
        .doc(company.id)
        .collection('projects')
        .where('status', '==', 'active')
        .get()
        .then(async (response) => {
          let array: Project[] = []

          response.forEach((el) => {
            let data: any = JSON.stringify(el.data())
            data = JSON.parse(data)

            array = [
              ...array,
              {
                projectName: data.projectName as string,
                id: data.projectId as string
              }
            ]
          })

          setProjectsList(array)
          setDisableProjectsSelect(false)
          setLoading(false)
        })
        .catch((error) => console.error(error))
    }
  }

  const openHire = (project: Project | null) => {
    if (project !== null) {
      setSelectedProject(project)

      /** Find company name */
      const comapnyIndex = companiesList.findIndex(
        (el) => el.id === selectedCompany?.id
      )
      if (comapnyIndex > -1) {
        setSelectedCompanyName(companiesList[comapnyIndex].companyName)
      }

      /** Find project name */
      const projectIndex = projectsList.findIndex((el) => el.id === project?.id)
      if (projectIndex > -1) {
        setSelectedProjectName(projectsList[projectIndex].projectName)
      }

      setOpenAddToProjectBlock(true)
    }
  }

  const closeAfterAddToProject = () => {
    setOpenAddToProjectBlock(false)
    closeDialog()
  }

  return (
    <>
      <AddCandidateToProject
        open={openAddToProjectBlock}
        onClose={() => closeAfterAddToProject()}
        projectId={selectedProject !== null ? selectedProject.id : ''}
        projectName={selectedProjectName}
        companyId={selectedCompany !== null ? selectedCompany.id : ''}
        candidate={candidate}
        companyName={selectedCompanyName}
      />

      <Dialog open={openDialog} fullWidth maxWidth='sm'>
        <DialogTitle>{t('select_project')}</DialogTitle>

        <DialogContent style={{ position: 'relative' }}>
          {loading && <ComponentLoader />}

          <Grid container spacing={3} style={{ marginTop: 0 }}>
            {/* Select company */}
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={companiesList}
                getOptionLabel={(option) => option.companyName}
                value={selectedCompany}
                onChange={(event, newValue) => {
                  getProjects(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    fullWidth
                    label={t('selectCompany')}
                    size='small'
                  />
                )}
              />
            </Grid>

            {/* Select project */}
            <Grid item xs={12} md={6}>
              <Autocomplete
                disabled={disableProjectsSelect}
                options={projectsList}
                getOptionLabel={(option) => option.projectName}
                value={selectedProject}
                onChange={(event, newValue) => {
                  openHire(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    disabled={disableProjectsSelect}
                    fullWidth
                    label={t('select_project')}
                    size='small'
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* Close button */}
          <Button onClick={() => closeDialog()}>{t('close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    agencyData: state.agencyDataRed.obj,
    agencyUserData: state.agencyUserDataRed.obj
  }
}
export default connect(mapStateToProps)(Hire)
