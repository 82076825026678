import { useTranslation } from 'react-i18next'
import BlockCard from './BlockCard'
import { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  TextField,
  Tooltip
} from '@mui/material'
import firebase from 'firebase/app'
import ComponentLoader from '../../../../../Components/ComponentLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTasks } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import './AgentsColors.scss'

const db = firebase.firestore()

interface Props {
  agencyData: AgencyData
}

const AgentsColors = ({ agencyData }: Props) => {
  const { t } = useTranslation()

  const [agentsList, setAgentsList] = useState<AgencyUserData[]>([])

  const [loading, setLoading] = useState<boolean>(false)
  const [refreshData, setRefreshData] = useState<boolean>(false)

  const [showColorChangeDialog, setShowColorChangeDialog] =
    useState<boolean>(false)

  const [color, setColor] = useState<string>('#215384')

  const [selectedAgent, setSelectedAgent] = useState<AgencyUserData | null>(
    null
  )

  useEffect(() => {
    let shouldUpdate = true

    db.collection('users')
      .where('data.agencyId', '==', agencyData.id)
      .where('data.type', '==', 'keyagency')
      .get()
      .then((snap) => {
        let list: AgencyUserData[] = []

        snap.forEach((doc) => {
          const data: any = JSON.parse(JSON.stringify(doc.data()))

          list = [...list, data.data]
        })

        if (shouldUpdate) {
          setAgentsList(list)
        }
      })
      .catch((error) => console.error(error))

    return () => {
      shouldUpdate = false
    }
  }, [agencyData, refreshData])

  const closeColorDialog = () => {
    setShowColorChangeDialog(false)
    setColor('#215384')
    setSelectedAgent(null)
  }

  const openEditColor = (agent: AgencyUserData) => {
    if (agent.color !== undefined) {
      setColor(agent.color)
    }
    setSelectedAgent(agent)
    setShowColorChangeDialog(true)
  }

  const updateColor = async () => {
    if (selectedAgent !== null) {
      setLoading(true)

      await db
        .collection('users')
        .doc(selectedAgent.uid)
        .update({
          'data.color': color
        })
        .then(() => {
          setRefreshData(!refreshData)
          setLoading(false)
          closeColorDialog()
        })
        .catch((error) => console.error(error))
    }
  }

  return (
    <>
      {/*
       * Color dialog
       */}
      <Dialog open={showColorChangeDialog} maxWidth='sm'>
        {loading && <ComponentLoader />}

        <DialogTitle>{t('edit')}</DialogTitle>

        <DialogContent>
          <Grid container style={{ marginTop: 0 }} spacing={2}>
            {/*
             * Color
             */}
            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label={t('color')}
                type='color'
                variant='outlined'
                value={color}
                onChange={(event) => setColor(event.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {/*
           * Update button
           */}
          <Button
            size='small'
            variant='contained'
            color='orange'
            onClick={() => updateColor()}
          >
            {t('update')}
          </Button>

          {/* Cancel button */}
          <Button size='small' onClick={() => closeColorDialog()}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <BlockCard title={t('agents_colors')} avatar={faTasks as IconProp}>
        {/*
         * List of agents
         */}
        <List style={{ marginBottom: 20 }}>
          {agentsList.map((agent) => (
            <ListItem
              key={agent.uid}
              style={{ justifyContent: 'space-between' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/*
                 * Color
                 */}
                <div
                  style={{
                    backgroundColor: agent.color,
                    width: 20,
                    height: 15,
                    marginRight: 10,
                    borderRadius: 5,
                    border: '1px solid #dddddd'
                  }}
                />
                {/*
                 * Agents first and last names
                 */}
                {agent.name} {agent.lastName}
              </div>

              {/* Edit button */}
              <Tooltip title={t('edit')} placement='top'>
                <div className='icon' onClick={() => openEditColor(agent)}>
                  <FontAwesomeIcon icon={faEdit as IconProp} />
                </div>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </BlockCard>
    </>
  )
}

export default AgentsColors
