import { createStore, combineReducers } from 'redux'

import userReducer from './reducers/userReducer'
import docArrayReducer from './reducers/docArrayReducer'
import expArrayReducer from './reducers/expArrayReducer'
import crewAgenciesReducer from './reducers/crewAgenciesReducer'
import passportNumberRed from './reducers/documents/passportNumberRed'
import passportPlaceIssueRed from './reducers/documents/passportPlaceIssueRed'
import passportIssuedRed from './reducers/documents/passportIssuedRed'
import passportExpiresRed from './reducers/documents/passportExpiresRed'
import seaBookNumberRed from './reducers/documents/seaBookNumberRed'
import seaBookPlaceIssueRed from './reducers/documents/seaBookPlaceIssueRed'
import seaBookIssuedRed from './reducers/documents/seaBookIssuedRed'
import seaBookExpiresRed from './reducers/documents/seaBookExpiresRed'
import stcwNumberRed from './reducers/documents/stcwNumberRed'
import stcwPlaceIssueRed from './reducers/documents/stcwPlaceIssueRed'
import stcwIssuedRed from './reducers/documents/stcwIssuedRed'
import stcwExpireRed from './reducers/documents/stcwExpiresRed'
import passportUploadRed from './reducers/documents/passportUploadRed'
import seaBookUploadRed from './reducers/documents/seaBookUploadRed'
import stcwUploadRed from './reducers/documents/stcwUploadRed'
import documentsDateErrorRed from './reducers/documents/documentsDateErrorRed'
import medCertNumberRed from './reducers/documents/medCertNumberRed'
import medCertPlaceIssueRed from './reducers/documents/medCertPlaceIssueRed'
import medCertIssuedRed from './reducers/documents/medCertIssuedRed'
import medCertExpiresRed from './reducers/documents/medCertExpiresRed'
import medCertUploadRed from './reducers/documents/medCertUploadRed'
import uploadFilesRed from './reducers/documents/uploadFilesRed'
import showGeneratedPdfBoxRed from './reducers/showGeneratedPdfBoxRed'
import isAuthRed from './reducers/session/isAuthRed'
import {
  passNumberErrRed,
  passPlaceIssueErrRed,
  passIssuedErrRed,
  passExpiresErrRed,
  passUploadErrRed,
  seamanNumberErrRed,
  seamanPlaceIssueErrRed,
  seamanIssuedErrRed,
  seamanExpiresErrRed,
  seamanUploadErrRed,
  stcwNumberErrRed,
  stcwPlaceIssueErrRed,
  stcwIssuedErrRed,
  stcwExpiresErrRed,
  stcwUploadErrRed,
  medCertNumberErrRed,
  medCertPlaceIssueErrRed,
  medCertIssuedErrRed,
  medCertExpiresErrRed,
  medCertUploadErrRed
} from './reducers/documents/documentsValidationErrorsRed'
import experienceInputsErrRed from './reducers/experience/experienceInputsErrRed'
import crewAgenciesInputsErrRed from './reducers/crewAgencies/crewAgenciesInputErrRed'
import agencyMatchRed from './reducers/agencyMatchRed'
import loadingDataRed from './reducers/session/loadingDataRed'
import loadAgenciesRed from './reducers/session/loadAgenciesRed'
import agencyDataRed from './reducers/session/agencyDataRed'
import agencyUserDataRed from './reducers/session/agencyUserDataRed'
import employeesCounterRed from './reducers/companies/employeesCounterRed'
import refreshDataRed from './reducers/refreshDataRed'
import renderPdfModuleRed from './reducers/renderPdfModuleRed'
import userDataRed from './reducers/session/userDataRed'
import showEditEmployeeMessageReducer from './reducers/companies/showEditEmployeeMessageReducer'
import selectedEmployeeReducer from './reducers/companies/selectedEmployeeReducer'
import dataFromSmartPlanningReducer from './reducers/companies/dataFromSmartPlanningReducer'
import projectIdSmartPlanningReducer from './reducers/companies/projectIdSmartPlanningReducer'

const rootReducer = combineReducers({
  userReducer,
  docArrayReducer,
  expArrayReducer,
  crewAgenciesReducer,
  passportNumberRed,
  passportPlaceIssueRed,
  passportIssuedRed,
  passportExpiresRed,
  seaBookNumberRed,
  seaBookPlaceIssueRed,
  seaBookIssuedRed,
  seaBookExpiresRed,
  stcwNumberRed,
  stcwPlaceIssueRed,
  stcwIssuedRed,
  stcwExpireRed,
  passportUploadRed,
  seaBookUploadRed,
  stcwUploadRed,
  documentsDateErrorRed,
  medCertNumberRed,
  medCertPlaceIssueRed,
  medCertIssuedRed,
  medCertExpiresRed,
  medCertUploadRed,
  uploadFilesRed,
  showGeneratedPdfBoxRed,
  isAuthRed,
  passNumberErrRed,
  passPlaceIssueErrRed,
  passIssuedErrRed,
  passExpiresErrRed,
  passUploadErrRed,
  seamanNumberErrRed,
  seamanPlaceIssueErrRed,
  seamanIssuedErrRed,
  seamanExpiresErrRed,
  seamanUploadErrRed,
  stcwNumberErrRed,
  stcwPlaceIssueErrRed,
  stcwIssuedErrRed,
  stcwExpiresErrRed,
  stcwUploadErrRed,
  medCertNumberErrRed,
  medCertPlaceIssueErrRed,
  medCertIssuedErrRed,
  medCertExpiresErrRed,
  medCertUploadErrRed,
  experienceInputsErrRed,
  crewAgenciesInputsErrRed,
  agencyMatchRed,
  loadingDataRed,
  loadAgenciesRed,
  agencyDataRed,
  agencyUserDataRed,
  employeesCounterRed,
  refreshDataRed,
  renderPdfModuleRed,
  userDataRed,
  showEditEmployeeMessageReducer,
  selectedEmployeeReducer,
  dataFromSmartPlanningReducer,
  projectIdSmartPlanningReducer
})

const store = createStore(rootReducer)

export default store
