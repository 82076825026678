const initialState = {
    bool: false
}

const documentsDateErrorRed = (state = initialState, action) => {
    switch(action.type){
        case 'DOCUMENTS_DATE_ERROR' : {
            return {
                ...state,
                bool: action.bool
            }
        }
        default: {
            return state
        }
    }
}

export default documentsDateErrorRed