import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import Loader from '../Components/Loader'

function ProtectedRoute({component: Component, isAuth, loadingData, ...rest}){
    if (loadingData === false) {
        return <Loader />
    } 
    return (
        <Route 
            {...rest}
            render={(props) => {
                if(isAuth){
                    return <Component {...props} />
                } else {
                    return <Redirect to={{
                        pathname: '/',
                        state: {from: props.location}
                        }}
                    />
                }}
            }
        />
    )
}

const mapStateToProps = state => {
    return {
        isAuth: state.isAuthRed.bool,
        loadingData: state.loadingDataRed.bool,
    }
}

export default connect(mapStateToProps)(ProtectedRoute)